import React from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

const CustomProgressBar = ({ progress }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',         // Adjust width as needed
                height: '40px',        // Adjust height to match button size
                backgroundColor: 'grey', // Grey background for the container
                borderRadius: '4px',   // Rounded corners for the box
                overflow: 'hidden',    // Ensure content doesn't overflow
            }}
        >
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    height: '100%',
                    backgroundColor: 'grey', // Background color for the progress bar
                    borderRadius: '4px',      // Rounded corners
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 1,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'darkgrey', // Change to the desired color for the progress
                    },
                }}
            />
            <Typography
                variant="body2"
                align="center"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    color: 'white',   // Text color
                }}
            >
                {`${progress}%`}
            </Typography>
        </Box>
    );
};

export default CustomProgressBar;
