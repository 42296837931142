import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import QR from './QR.js'

const steps = ['Select QR Type', 'Create QR'];

export default function HorizontalNonLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const displayStep = (step) => {
        switch (step) {
            case 0:
                return <QR />;

            default:
        }

    };

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <Box sx={{ width: '100%' }}>

            <div className="col-12">
                <div className="ms-panel">
                    <div className="ms-panel-body">
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        {/*       {displayStep(activeStep)} */}


                        <div>
                            {allStepsCompleted() ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/*  <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                        Step {activeStep + 1}
                                    </Typography> */}

                                    {activeStep == 0 &&
                                        <Grid container>
                                            <Grid item lg={3}>
                                                <Box sx={{ marginTop: '50px' }}>
                                                    <FormControl fullWidth >
                                                        <InputLabel id="demo-simple-select-label">Select QR Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            label="Select QR Type"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Room</MenuItem>
                                                            <MenuItem value={20}>Table</MenuItem>
                                                            <MenuItem value={30}>Property</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    {activeStep == 1 &&
                                        <Grid container spacing={1}>
                                            <Grid item lg={4}>
                                                <Box sx={{ marginTop: '50px' }}>
                                                    <FormControl fullWidth >
                                                        <TextField
                                                            required
                                                            id="outlined-required"
                                                            label="Name of your QR code"
                                                            defaultValue="Hello World"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <Box sx={{ marginTop: '50px' }}>
                                                    <FormControl fullWidth >
                                                        <TextField
                                                            required
                                                            id="outlined-required"
                                                            label="Enter link to connect with your code"
                                                            defaultValue="Hello World"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Button  sx={{float: "right"}} variant='contained'> Submit </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                                            Next
                                        </Button>
                                        {activeStep !== steps.length &&
                                            (completed[activeStep] ? (
                                                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                    Step {activeStep + 1} already completed
                                                </Typography>
                                            ) : (
                                                <Button onClick={handleComplete}>
                                                    {completedSteps() === totalSteps() - 1
                                                        ? 'Finish'
                                                        : 'Complete Step'}
                                                </Button>
                                            ))}
                                    </Box>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}