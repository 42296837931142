import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import happy from "../../../assets/img/feedback/happy.png";
import angry from "../../../assets/img/feedback/angry.png";
import normal from "../../../assets/img/feedback/normal.png";
import soon from "../../../assets/img/icons8-coming-soon-96.png";
import Avatar from "@mui/material/Avatar";
import ReactGA from "react-ga";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { getCurrencySymbol } from "../../../config";
class OrderTable extends Component {
  table_columns = [
    {
      name: "ORDER #",
      selector: "order_number",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: "bold",
          }} /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {row.order_number}
        </div>
      ),
    },
    {
      name: "PAYMENT STATUS",
      selector: "payment_status",
      sortable: false,
      cell: (row) => (
        <div>
          <div>{row.payment_status}</div>
        </div>
      ),
    },
    {
      name: "CARD NUMBER",
      selector: "card_number",
      sortable: false,
      cell: (row) => (
        <div>{row.card_number ? <div>**{row.card_number}</div> : ""}</div>
      ),
    },
    {
      name: "DATE & TIME",
      selector: "order_date_time",
      sortable: true,
      width: "200px",
    },
    {
      name: "ORDER TYPE",
      selector: "order_type",
      width: "150px",
      cell: (row) => (
        <div className="type-margin">
          <div /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */>
            {row.order_type === "TAKE AWAY" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-take-away badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "BUFFET" || row.order_type === "DINE IN" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-buffet badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "DELIVERY" ||
              row.order_type === "DELIVERY\n" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-delivery badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "DELIVERY TO PARK" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-delivery-to-park badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "DONATE" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-delivery-to-park badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "KAYPAY" ? (
              <p
                style={{ width: "120px" }}
                className="badge badge-kaypay badge-size"
              >
                {row.order_type}
              </p>
            ) : row.order_type === "TERMINAL" ? (
              <p
                style={{ width: "120px", padding: "7px" }}
                className="badge badge-info badge-size"
              >
                {row.order_type}
              </p>
            ) : (
              <p
                style={{ width: "120px", padding: "7px" }}
                className="badge badge-light badge-size"
              >
                {row.order_type}
              </p>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "TOTAL",
      selector: "sub_total",
      sortable: true,
      sortFunction: this.sortByColumn,
      cell: (row) => (
        <div
          className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {getCurrencySymbol(localStorage.getItem("currency_code")) +
            row.sub_total.toFixed(2)}
        </div>
      ),
    },
    {
      name: "REFERENCE NO",
      selector: "refrence_number",
      sortable: true,
      cell: (row) => (
        <div
          className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {row.refrence_number}
        </div>
      ),
    },
    {
      name: "ORDER SOURCE",
      selector: "order_source",
      sortable: true,
      cell: (row) => (
        <div
          className="" /* onClick={() => this.fetchOrderDetailsByOrderId(row)} */
        >
          {row.order_source}
        </div>
      ),
    },
    {
      name: "RATING",
      selector: "order_rating",
      sortable: false,
      width: "70px",
      cell: (row) => (
        <div className="">
          {row.order_rating == 1 && (
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt="Remy Sharp"
              src={angry}
            />
          )}
          {row.order_rating == 3 && (
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt="Remy Sharp"
              src={normal}
            />
          )}
          {row.order_rating == 5 && (
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt="Remy Sharp"
              src={happy}
            />
          )}
        </div>
      ),
    },
    {
      name: "ACTIONS",
      selector: "actions",
      cell: (row) => (
        <div>
          <div>
            {/* <IconButton
              color="primary"
              aria-label="view"
              onClick={() => this.fetchOrderDetailsByOrderId(row)}
            >
              <VisibilityIcon />
            </IconButton> */}
            <Button
              startIcon={<VisibilityIcon color="primary" />}
              variant="outlined"
              onClick={() => this.fetchOrderDetailsByOrderId(row)}
              sx={{ alignItems: "flex-start" }}
            >
              View
            </Button>

            {/* <i className="fas fa-pencil-alt text-secondary" /> */}
            {/* <i className="far fa-trash-alt ms-text-danger" onClick={() => this.rejectTableRequest(row)} style={{ marginRight: '15px', cursor: 'pointer', fontSize: '15px' }} /> */}
          </div>
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  state = {
    page: 0,
    data: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: true,
    count: 0,
    isLoadingExcel: false,
    cardNumber: "",
    searchPayload: "",
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    if (!localStorage.getItem("current_page")) {
      localStorage.setItem("current_page", this.state.current_page);
    }
    if (!localStorage.getItem("number_of_elements")) {
      localStorage.setItem(
        "number_of_elements",
        parseInt(this.state.number_of_elements)
      );
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    let prop_id = this.props.propertyId;
    if (prop_id && !this.props?.history?.location?.state?.order) {
      this.interval = setInterval(() => window.location.reload(), 600000);
      let today = new Date();
      let startOfMonth = dayjs().startOf("month");
      this.setState(
        { from_date: startOfMonth, to_date: today, property_id: prop_id },
        this.filterAllOrders
      );
    } else {
      this.setState({ isLoading: false });
    }

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      if (currentPage !== "payout" || currentPage !== "transactions") {
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }

    if (this.props?.history?.location?.state?.order) {
      this.filterOrders();
    }

    // this.fetchAllOrders(payload);
  };

  filterAllOrders = () => {
    if (
      this.state.from_date != null &&
      this.state.to_date != null &&
      this.state.from_date != "" &&
      this.state.to_date != "" &&
      this.state.property_id
    ) {
      const payload = {
        page: localStorage.getItem("current_page") || this.state.page,
        size:
          localStorage.getItem("number_of_elements") ||
          this.state.number_of_elements,
        from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
        to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),

        property_id: this.state.property_id,
      };

      OrderServiceAPI.filterAllOrdersByDate(payload)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem(
              "current_page",
              response.data.data.current_page
            );
            //  localStorage.setItem("number_of_elements", parseInt(this.state.number_of_elements));
            this.setState({
              data: response.data.data.order_Details,
              total_elements: response.data.data.total_elements,
              total_pages: response.data.data.total_pages,
              current_page: response.data.data.current_page + 1,
              page_size: response.data.data.page_size,
              isLoading: false,
              count: response.data.data.order_Details.length,
            });
          } else {
            this.setState({
              data: [],
              total_elements: 0,
              total_pages: 0,
              current_page: 0,
              page_size: 0,
              isLoading: false,
              count: 0,
            });
          }
        })
        .catch((err) => {
          toast.warning("Error : ", err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      ///   toast.warning("Please select appropriate date values!");
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    if (
      window.location.pathname !== "/orders-delivery/delivery" &&
      window.location.pathname !== "/orderdetails"
    ) {
      localStorage.setItem("current_page", 0);
      localStorage.setItem("number_of_elements", 15);
    }
  }

  handleExportCSV = () => {
    if (this.state.zReport) {
      const fileExtension = ".csv";
      var link = document.createElement("a");
      link.href = this.state.zReport;
      //  link.download = "zReport";
      link.download = `Order${fileExtension}`;
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  downloadExcel = () => {
    this.setState({ isLoadingExcel: true });

    let payload = {
      from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
      to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),
      property_id: this.props.propertyId,
      search_text: this.state.search_text || "",
    };

    OrderServiceAPI.downloadExcel(payload)
      .then((response) => {
        if (response) {
          const mimeType = "text/csv";
          const file = new Blob([response.data], { type: mimeType });
          const fileURL = URL.createObjectURL(file); //Open the URL on new Window
          // window.open(fileURL);
          //    this.downloadFile(fileURL);

          this.setState({ isLoadingExcel: false, zReport: fileURL }, () => {
            this.handleExportCSV();
          });
        } else {
          toast.warn("Report not generated.");
          this.setState({
            iisLoadingExcel: false,
            trendingItemsSalesReport: [],
            zReport: "",
          });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false })
        this.setState({ isLoadingExcel: false, zReport: "" });
        toast.error(error);
      });
  };

  searchOrder = (event) => {
    event.preventDefault();
    const payload = {
      page: 0,
      size: this.state.number_of_elements,
      search_text: event.target.value,
    };
    this.fetchAllOrders(payload);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ number_of_elements: parseInt(event.target.value, 10) });
  };

  handlePagination = (e) => {
    localStorage.setItem("current_page", 0);
    localStorage.setItem("number_of_elements", parseInt(e.target.value));
    this.setState(
      { number_of_elements: parseInt(e.target.value) },
      this.filterAllOrders
    );
  };

  fetchAllOrders = (payload) => {
    OrderServiceAPI.filterAllOrdersByDate(payload).then((response) => {
      if (response.data.status) {
        if (response.data.data.order_Details) {
          this.setState({
            data: response.data.data.order_Details,
            total_elements: response.data.data.total_elements,
            total_order_pages: response.data.data.total_pages,
            current_orders_page: response.data.data.current_page + 1,
            page_size: response.data.data.page_size,
            isLoading: false,
            count: response.data.data.order_Details.length,
          });
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  handleCardNumberChange = (e) => {
    this.setState({ cardNumber: e.target.value.toString() });
  };
  handleSearchPayload = (e) => {
    this.setState({ searchPayload: e.target.value.toString() });
  };
  filterByCardNumber = () => {
    const { cardNumber } = this.state;
    const payload = {
      card_number: cardNumber,
      page: 0,
      size: 15,
      property_id: this.state.property_id,
    };

    OrderServiceAPI.filterAllOrdersByDate(payload).then((response) => {
      if (response.data.data && response.data.status) {
        this.setState({
          data: response.data.data.order_Details,
          total_elements: response.data.data.total_elements,
          total_pages: response.data.data.total_pages,
          current_page: response.data.data.current_page,
          page_size: response.data.data.page_size,
          count: response.data.data.order_Details.length,
        });
      } else {
        console.log("some error occured...");
      }
    });
  };

  filterOrders = (event = null) => {
    const { searchPayload } = this.state;
    try {
      this.setState({ isLoading: true });
      if (event) event.preventDefault();

      const searchText = searchPayload
        ? searchPayload
        : this.props?.history?.location?.state?.order?.description;

      const payload = {
        search_text: searchText,
        page: 0,
        size: 15,
        property_id: this.state.property_id || this.props.propertyId,
      };

      OrderServiceAPI.filterAllOrdersByDate(payload).then((response) => {
        if (response.data.data && response.data.status) {
          this.setState(
            {
              data: response.data.data.order_Details,
              total_elements: response.data.data.total_elements,
              total_pages: response.data.data.total_pages,
              current_page: response.data.data.current_page,
              page_size: response.data.data.page_size,
              count: response.data.data.order_Details.length,
            },
            () => {
              let order = {
                username: localStorage.getItem("username"),
                order_id: response.data.data.order_Details[0]?.order_id,
              };

              if (this.props?.history?.location?.state?.order) {
                this.fetchOrderDetailsByOrderId(order);
              }
              this.setState({ isLoading: false });
            }
          );
        } else {
          this.setState({ isLoading: false });
          toast.warn(response?.data?.message);
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error("Failed to fetch orders. Please try again.");
      console.error("Error fetching orders:", error);
    }
  };

  fetchOrderDetailsByOrderId = (order) => {
    this.props.history.push({
      pathname: "/orderdetails",
      state: {
        order: order,
      },
    });
  };

  ordersHandleChange = (event, value) => {
    event.preventDefault();
    const page = value - 1;
    this.setState({ current_page: value });
    localStorage.setItem("current_page", page);
    const payload = {
      page: page,
      size:
        localStorage.getItem("number_of_elements") ||
        this.state.number_of_elements,
      property_id: this.state.property_id,
      from_date: this.state.from_date
        ? dayjs(this.state.from_date).format("DD-MM-YYYY")
        : "",
      to_date: this.state.to_date
        ? dayjs(this.state.to_date).format("DD-MM-YYYY")
        : "",
      sort_value: this.state.columnName,
      sort: this.state.sortOrder?.toUpperCase(),
    };

    if (this.state.search_text) {
      payload = {
        page: page,
        size: 15,
        property_id: this.state.property_id,
        from_date: this.state.from_date
          ? dayjs(this.state.from_date).format("DD-MM-YYYY")
          : "",
        to_date: this.state.to_date
          ? dayjs(this.state.to_date).format("DD-MM-YYYY")
          : "",
        search_text: this.state.search_text ? this.state.search_text : "",
      };
    }
    this.fetchAllOrders(payload);
  };

  /*     onChangeHandler(event) {
            const ctrl_name = event.target?.name;
            if (ctrl_name === "from_date") {
                this.setState({ changeBool: 1, from_date: event.target.value }, this.fnCallback);
            } else if (ctrl_name === "to_date") {
                this.setState({ changeBool: 2, to_date: event.target.value }, this.fnCallback);
            } else if (ctrl_name === "date_options_dd") {
                let dd_val = event.target.value;
                if (dd_val === "1") {
                    this.setState({ changeBool: 3 }, this.fnCallback);
                } else if (dd_val === "2") {
                    this.setState({ changeBool: 4 }, this.fnCallback);
                } else if (dd_val === "3") {
                    this.setState({ changeBool: 5 }, this.fnCallback);
                } else if (dd_val === "4") {
                    this.setState({ changeBool: 6 }, this.fnCallback);
                }
            }
        } */

  handleToDate = (e) => {
    //       const ctrl_name = e.target?.name;
    let toDat = dayjs(e);
    this.setState({ to_date: toDat }, this.fnCallback);
  };

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;
    let toDat = dayjs(e);
    this.setState({ from_date: toDat }, this.fnCallback);
  };

  reformatDate = (dateStr) => {
    let dArr = dateStr.split("-"); // ex input "2010-01-18"
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0]; //ex out: "18/01/10"
  };

  fnCallback = (e) => {
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      var today = dayjs(new Date());
      this.setState({ from_date: today, to_date: today }, this.filterAllOrders);
    } else if (e?.target?.value == 2) {
      // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      var yesterday = dayjs(new Date()).subtract(1, "day");
      this.setState(
        { from_date: yesterday, to_date: yesterday },
        this.filterAllOrders
      );
    } else if (e?.target?.value == 3) {
      // Last 7 Days
      var today = dayjs(new Date());
      var last7 = dayjs(new Date()).subtract(1, "week");
      this.setState({ from_date: last7, to_date: today }, this.filterAllOrders);
    } else if (e?.target?.value == 4) {
      // Last 30 Days
      var today = dayjs(new Date());
      var last30 = dayjs(new Date()).subtract(1, "month");

      this.setState(
        { from_date: last30, to_date: today },
        this.filterAllOrders
      );
    }
  };

  sortByColumn = (column, sortDirection) => {
    this.setState({
      columnName: column.selector,
      sortOrder: sortDirection,
      current_page: 0,
    });
    const payload = {
      page: 0,
      size: this.state.number_of_elements,
      sort: sortDirection?.toUpperCase(),
      sort_value: column.selector,
      property_id: this.state.property_id,
      from_date: dayjs(this.state.from_date).format("DD-MM-YYYY"),
      to_date: dayjs(this.state.to_date).format("DD-MM-YYYY"),
    };
    this.fetchAllOrders(payload);
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb />
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-body">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                      <div className="row m-2">
                        <div className="col-md-2">
                          <div className="mt-4">
                            {/* <label htmlFor="validationCustom05">Filter By</label> */}
                            <select
                              style={{ height: "54px" }}
                              className="form-control"
                              name="date_options_dd"
                              id="validationCustom05"
                              onChange={this.fnCallback}
                            >
                              <option defaultValue="">- - Filter By - -</option>
                              <option value="1">Today</option>
                              <option value="2">Yesterday</option>
                              <option value="3">Week</option>
                              <option value="4">Month</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mt-4">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <MobileDatePicker
                                  label="From"
                                  value={this.state.from_date || new Date()}
                                  inputFormat="DD-MM-YYYY"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  maxDate={new Date()}
                                  onChange={this.handleFromDate}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mt-4">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <MobileDatePicker
                                  label="To"
                                  value={this.state.to_date || new Date()}
                                  inputFormat="DD-MM-YYYY"
                                  onChange={this.handleToDate}
                                  maxDate={new Date()}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="mt-4">
                            <TextField
                              label="Search Orders"
                              onChange={this.handleSearchPayload}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={this.filterOrders}
                                      sx={{
                                        color: "#000",
                                        "&:hover": {
                                          color: "#007BFF",
                                          backgroundColor:
                                            "rgba(0, 123, 255, 0.1)",
                                        },
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="mt-4">
                            <TextField
                              label="Card Number"
                              onChange={this.handleCardNumberChange}
                              fullWidth
                              inputProps={{
                                maxLength: 4,
                                pattern: "[0-9]*",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={this.filterByCardNumber}
                                      sx={{
                                        color: "#000",
                                        "&:hover": {
                                          color: "#007BFF",
                                          backgroundColor:
                                            "rgba(0, 123, 255, 0.1)",
                                        },
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-2" style={{ display: "flex", alignItems: "center" }}>
                                                    <div className="mt-4">
                                                        No of Rows :  {this.state.count}
                                                    </div>

                                                </div> */}
                        <div
                          className="col-md-2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Button
                            className="mt-4"
                            variant="contained"
                            style={{ float: "right" }}
                            color="primary"
                            onClick={this.downloadExcel}
                            disabled={this.state.isLoadingExcel} // Disable the button when loading is true
                          >
                            {this.state.isLoadingExcel
                              ? "Downloading..."
                              : "Download CSV"}
                          </Button>
                        </div>
                      </div>

                      {/* {
                                             (this.state.data.length > 0) ? 
                                                :
                                                null
                                        } */}

                      {this.state.isLoading ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "40px",
                          }}
                        >
                          {" "}
                          <Loader size={50} />{" "}
                        </div>
                      ) : (
                        <React.Fragment>
                          <DataTableComponet
                            searchOrder={this.searchOrder}
                            state={this.state.data}
                            data={this.state.data}
                            columns={this.table_columns}
                            customStyles={this.customStyles}
                            is_expandable_row={false}
                            onSort={this.sortByColumn}
                            handleChangeRowsPerPage={
                              this.handleChangeRowsPerPage
                            }
                            handleChange={this.changeCurrentPage}
                            dense
                          />

                          <div
                            className="row"
                            style={{ float: "right", margin: "25px" }}
                          >
                            {/*  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={this.state.number_of_elements}
                                                            onChange={this.handlePagination}
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                            label="Age"

                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl> */}

                            {this.state.total_pages > 0 && (
                              <React.Fragment>
                                <span
                                  style={{
                                    position: "relative",
                                    right: "10px",
                                    top: "5px",
                                  }}
                                >
                                  {" "}
                                  Rows Per Page
                                </span>
                                <Grid container spacing={1}>
                                  {/* First Grid Item */}
                                  <Grid item xs={12} lg={4}>
                                    <FormControl fullWidth>
                                      <NativeSelect
                                        defaultValue={
                                          localStorage.getItem(
                                            "number_of_elements"
                                          ) || this.state.number_of_elements
                                        }
                                        onChange={this.handlePagination}
                                        inputProps={{
                                          name: "Pagination",
                                          id: "uncontrolled-native",
                                        }}
                                      >
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={500}>500</option>
                                        <option value={1000}>1000</option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Grid>

                                  {/* Second Grid Item */}
                                  <Grid item xs={12} lg={8}>
                                    <Pagination
                                      currentPage={this.state.current_page}
                                      total_pages={this.state.total_pages}
                                      sibCount={0}
                                      state={this.state.data}
                                      handleChange={this.ordersHandleChange}
                                    />
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(OrderTable);
