import React from "react";
import { TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { settings } from "../../config";

function App({ onPlaceSelected, googlePlacesProps, ...props }) {
  const { ref: materialRef } = usePlacesWidget({
    
    apiKey: settings?.GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options:{
      types: [],
    },
    ...googlePlacesProps,
  });

  return <TextField inputRef={materialRef} {...props} />;
}

export default App;
