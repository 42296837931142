import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useStepperContext } from "../../contexts/UseContextProvider";
import Typography from "@mui/material/Typography";
import * as SecurityServiceAPI from "../../../../../../services/user/SecurityServiceAPI";
import { useDispatch } from "react-redux";
import "../css/setup.css";
import { setPropertyId } from "../../../../../../redux/user/userSlice";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import StepperControl from "../StepperControl";
import ReactSelect from "react-select";
import { FlapperSpinner } from "react-spinners-kit";
import BusinessPlaceForm from "../forms/businessForm";
import { Form, Formik } from "formik";
import Loader from "../../../../../../shared/Loader";
import Geocode from "react-geocode";
import FormHelperText from "@mui/material/FormHelperText";
import GoogleMaps from "../../../../../commonComps/GooglePlacesAutoComplete";

const accountSchema = Yup.object().shape({
  contact_name: Yup.string().required("Contact Name is required"),
  contact_number: Yup.string().required("Contact No is required"),
  property_name: Yup.string().required("Business legal name is required"),
  property_trading_name: Yup.string().required(
    "Business trading name is required"
  ),
  business_type: Yup.string().required("Business Type  is required"),
  property_address: Yup.string().required("Property address is required"),

  city: Yup.string().required("City is required"),
  postal_code: Yup.string().required("Postal code is required"),
  /* .matches(
      /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2}|\d{5}(-\d{4})?)$/i,
      "Invalid postal code"
    ) */ country: Yup.string().required("Country is required"),
});

export default function Account(props) {
  const { userData, setUserData } = useStepperContext();

  const [formErrors, setFormErrors] = useState({});

  const [initialValues, setInitialValues] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);

  const propDetails = useSelector((state) => state.property.property);

  const dispatch = useDispatch();

  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey("AIzaSyAbquQkju85Ho0VUlMI_TgWP6hUQlzZ7UI");

  // set response language. Defaults to english.
  Geocode.setLanguage("en");

  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  //Geocode.setRegion("EU");

  // set location_type filter . Its optional.
  // google geocoder returns more that one address for given lat/lng.
  // In some case we need one address as response for which google itself provides a location_type filter.
  // So we can easily parse the result for fetching address components
  // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  Geocode.setLocationType("ROOFTOP");

  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 56,
      minHeight: 56,
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleCountryOnChange = (value) => {
    let cont = {
      country: value,
      country: value.label,
      country_code: value.value,
    };

    setUserData({ ...userData, ...cont });
  };

  useEffect(() => {
    const property_details = JSON.parse(
      localStorage.getItem("property_details")
    );
    if (property_details && property_details.property_id) {
      const addInitialValues = {
        property_id: propDetails.property_id,
        username: propDetails.username,
        property_name: propDetails.property_name,
        contact_name: propDetails.contact_name,
        contact_number: property_details.contact_number,
        property_trading_name: propDetails.property_trading_name,
        business_type: propDetails.business_type,
        property_address: propDetails.property_address,
        second_line_address: propDetails.second_line_address,
        property_country: propDetails.country,
        property_country_code: propDetails.country_code,
        city: propDetails.city,
        postal_code: propDetails.postal_code,
        country: propDetails.country,
        country_code: propDetails.country_code,
        currency_code: propDetails.currency_code,
      };

      setInitialValues(addInitialValues);

      localStorage.setItem("currency_code", property_details.currency_code);

      if (userData.business_types == null) {
        SecurityServiceAPI.fetchBusinessType()
          .then((response) => {
            if (response.data.status) {
              //     this.setState({ loading: false, show: false });
              //    toast.success("Message Sent Successfully");

              const keysArray = Object.keys(
                response.data.data.business_type_map
              );
              const total = { business_types: keysArray };
              //  initialValues.business_types = response.data.data.business_types;
              const con = { country_options: countryList().getData() };
              setUserData({
                ...userData,
                ...addInitialValues,
                ...total,
                ...con,
                ...response.data.data,
              });
              setIsLoading(false);
            } else {
              //     toast.error(response.data.message);
              //     this.setState({ loading: false });
              setUserData({ ...userData, ...addInitialValues });
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }

      setIsLoading(false);

    } else {

      let name = props.initialValues.property_details.contact_name
        ? props.initialValues.property_details.contact_name
        : localStorage.getItem("name");
      let phone = props.initialValues.property_details.contact_number
        ? props.initialValues.property_details.contact_number
        : localStorage.getItem("phone");
      const addInitialValues = {
        property_id: "",
        username: "",
        property_name: "",
        contact_name: name ? name : user.user.contact_name,
        contact_number: phone ? phone : user.user.contact_number,
        property_trading_name: "",
        business_type: "",
        property_address: "",
        second_line_address: "",
        property_country: "",
        property_country_code: "",
        city: "",
        postal_code: "",
        country: "",
        country_code: "",
        currency_code: "",
      };

      setInitialValues(addInitialValues);

      if (userData.business_types == null) {
        SecurityServiceAPI.fetchBusinessType()
          .then((response) => {
            if (response.data.status) {
              //     this.setState({ loading: false, show: false });
              //    toast.success("Message Sent Successfully");

              const keysArray = Object.keys(
                response.data.data.business_type_map
              );
              const total = { business_types: keysArray };
              //  initialValues.business_types = response.data.data.business_types;

              const con = { country_options: countryList().getData() };
              setUserData({
                ...userData,
                ...props.initialValues,
                ...total,
                ...con,
                ...response.data.data,
              });

              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            //  this.setState({ loading: false, show: false });
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setIsLoading(false);
      }
    }

    /*     if (Object.keys(formErrors).length === 0 && isSubmitting) {
          if (userData.property_id) {
            handleUpdateBusiness();
          } else {
            businessPlaceFormHandler();
          }
    
        } */
  }, [formErrors, props.initialValues]);

  /*  */
  async function handleSubmit(values, resetForm) {
    if (propDetails?.property_id) {
      handleUpdateBusiness(values, resetForm);
    } else {
      businessPlaceFormHandler(values, resetForm);
    }
  }

  async function validate(userData) {
    let errors = {};

    if (!userData.contact_name) {
      errors.contact_name = "Cannot be blank";
    }

    if (!userData.property_name) {
      errors.property_name = "Cannot be blank";
    }

    if (!userData.property_trading_name) {
      errors.property_trading_name = "Cannot be blank";
    }

    if (!userData.business_type) {
      errors.business_type = "Cannot be blank";
    }

    if (!userData.property_address) {
      errors.property_address = "Cannot be blank";
      // Get latitude & longitude from address.
    } else {
      try {
        const response = await Geocode.fromAddress(userData.property_address);
        const { lat, lng } = response.results[0].geometry.location;
      } catch (error) {
        errors.property_address = "Please enter a valid address.";
        console.error(error);
      }
    }

    if (!userData.contact_number) {
      errors.contact_number = "Cannot be blank";
    } else if (userData.contact_number.length < 10) {
      errors.contact_number = "Must be exactly 10 digits";
    }

    if (!userData.city) {
      errors.city = "Cannot be blank";
    }

    if (!userData.country) {
      errors.country = "Cannot be blank";
    }

    if (!userData.postal_code) {
      errors.postal_code = "Cannot be blank";
    } else if (!valid_postcode(userData.postal_code)) {
      errors.postal_code =
        "Invalid format. Expected format: AA99 9AA or A99 9AA or A9 9AA ";
    }

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    }

    return errors;
  }

  const businessPlaceFormHandler = (values, resetForm) => {
    //this.setState({ loading: true });
    setLoading(true);
    var getCountry = require("country-currency-map").getCountry;
    const currency = getCountry(values.country);
    //  this.setState({ currency_code: currency.abbreviation });
    const getCurrency = require("country-currency-map").getCurrency;
    const tmp_username = localStorage.getItem("username");

    const payload = {
      property_name: values.property_name,
      property_trading_name: values.property_trading_name,
      business_type: values.business_type,
      property_address: values.property_address,
      property_country_code: countryList().getValue(values.country),
      property_country: values.country,
      property_city: values.city,
      country: values.country,
      country_code: countryList().getValue(values.country),
      contact_name: values.contact_name
        ? values.contact_name
        : props.initialValues.property_details.contact_name,
      contact_number: userData.contact_number?.startsWith("+")
        ? userData.contact_number
        : "+" + userData.contact_number,
      postal_code: values.postal_code?.toUpperCase(),
      currency_code: currency.currency,
      username: tmp_username,
    };

    localStorage.setItem(
      "industryCode",
      userData.business_type_map[values.business_type]
    );

    props.setIndCode(userData?.business_type_map[values?.business_type]);

    SecurityServiceAPI.addBusinessPlace(payload)
      .then((response) => {
        if (response.data.status) {
          if (
            response.data.data.property_id != "" &&
            response.data.data.username != ""
          ) {
            localStorage.setItem("property_id", response.data.data.property_id);
            localStorage.setItem("username", response.data.data.username);
            localStorage.setItem(
              "business_trading_name",
              userData.property_trading_name
            );
            dispatch(setPropertyId(response.data.data.property_id));

            let propId = {
              property_id: response.data.data.property_id,
            };

            setUserData({ ...userData, ...propId });
          }
          toast.success("Account Details Added Successfully");
          setLoading(false);
          setIsSubmitting(false);
          props.handleClick("next");
          //       this.setState({ loading: false });
          // this.props.handleActivePage(1);
          //       window.location.reload(true);
        } else {
          setLoading(false);
          setIsSubmitting(false);
          toast.error(response.data.message);
          //      this.setState({ loading: false });
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const handleUpdateBusiness = (values, resetForm) => {
    //  this.setState({ loading: true });
    var getCountry = require("country-currency-map").getCountry;
    setLoading(true);
    const currency = getCountry(values?.country);
    const businessPlaceForm = {
      property_id: propDetails.property_id,
      username: propDetails.username,
      property_name: values.property_name,
      property_trading_name: values.property_trading_name,
      business_type: values.business_type,
      property_address: values.property_address,
      property_country_code:
        values?.country && countryList().getValue(values?.country),
      property_country: values.country,
      currency_code: currency?.currency,
      property_city: values.city,
      contact_name: values.contact_name,
      contact_number: values.contact_number,
      country: values.country,
      country_code: countryList().getValue(values.country),
      postal_code: values.postal_code.toUpperCase(),
    };

    let formData = new FormData();
    formData.append("property_details ", JSON.stringify(businessPlaceForm));

    localStorage.setItem(
      "industryCode",
      userData?.business_type_map[values?.business_type]
    );

    props.setIndCode(userData?.business_type_map[values?.business_type]);

    SecurityServiceAPI.updateBusinessPlace(formData)
      .then((response) => {
        if (response.data.status) {
          toast.success("Account Details Updated Successfully.");
          setLoading(false);

          setIsSubmitting(false);

          props.handleClick("next");

          /*  if (props.initialValues.adyen.account_status == "ACCOUNT NOT INITIATED" || props.initialValues.adyen.account_status == "ACCOUNT CREATED") {
           
           } else {
             props.setActiveStep(2);
           } */
          //     this.props.handleActivePage(1);
          //    this.setState({ loading: false });
        } else {
          toast.warn(response.data.message);
          setLoading(false);
          setIsSubmitting(false);
          //     this.setState({ loading: false });
        }
      })
      .catch((error) => {
        props.setLoading(false);
        setIsSubmitting(false);
        toast.error("Oops! Something went wrong");
        //  this.setState({ loading: false });
      });
  };

  const handleoPhoneChangeHandler = (value) => {
    const cont = { contact_number: value };

    setUserData({ ...userData, ...cont });
  };


  const handleLocationSelect = (
    selectedLocation,
    setFieldValue,
    setUserData
  ) => {
    const { address_components, formatted_address } = selectedLocation;
    let city = "";
    let state = "";
    let country = "";
    let postalCode = "";
 
    for (let i = 0; i < address_components.length; i++) {
      const component = address_components[i];
      const types = component.types;

      if (
        types.includes("locality") ||
        types.includes("postal_town") ||
        types.includes("sublocality")) {
        city = component.long_name;
        setUserData({ ...userData, city: city });
      }

      if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }

      if (types.includes("country")) {
        country = component.long_name;
        setUserData({ ...userData, country: country });
      }

      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    }

    setFieldValue("city", city);
    setFieldValue("state", state);
    setFieldValue("country", country);
    setFieldValue("postal_code", postalCode);
    setFieldValue("property_address", formatted_address);

    setUserData({
      ...userData,
      city: city,
      state: state,
      country: country,
      postal_code: postalCode,
      property_address: formatted_address,
    });
  };

  function valid_postcode(postcode) {
    postcode = postcode.replace(/\s/g, "");

    const ukRegex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;
    const usRegex = /^\d{5}(-\d{4})?$/;

    return ukRegex.test(postcode) || usRegex.test(postcode);
  }

  function getCountryCode(countryName) {
    const countries = countryList().getData();
    const selectedCountry = countries.find(
      (country) => country.label === countryName
    );

    // Check if the country is found
    if (selectedCountry) {
      return selectedCountry.value; // Return the country code
    } else {
      return null; // Country not found
    }
  }

  const getBusinessType = (addInitialValues) => {
    SecurityServiceAPI.fetchBusinessType()
      .then((response) => {
        if (response.data.status) {
          const keysArray = Object.keys(response.data.data.business_type_map);
          const total = { business_types: keysArray };
          const con = { country_options: countryList().getData() };
          setUserData({
            ...userData,
            ...total,
            ...con,
            ...response.data.data,
          });
          setIsLoading(false);
        } else {
          setUserData({ ...userData, ...addInitialValues });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="spinner cardBody card-body">
          <FlapperSpinner size={100} color="#233cad" loading={loading} />
        </div>
      ) : (
        <Grid container spacing={3} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }}>
              <Typography variant="h5" gutterBottom>
                Setup your Kayana account
              </Typography>

              <Divider variant="middle" />
            </Grid>

            <br></br>

            {isLoading ? (
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "40px",
                }}
              >
                {" "}
                <Loader size={50} />{" "}
              </div>
            ) : (
              <Formik
                enableReinitialize="true"
                initialValues={initialValues}
                validationSchema={accountSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={12} md={4}>
                        <TextField
                          error={
                            touched.contact_name && errors.contact_name
                              ? true
                              : false
                          }
                          id="name-input"
                          name="contact_name"
                          fullWidth
                          label="Contact Name"
                          type="text"
                          value={values.contact_name || ""}
                          onChange={handleChange}
                        />

                        <FormHelperText error>
                          {touched.contact_name && errors.contact_name
                            ? errors.contact_name
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <ReactPhoneInput
                          inputExtrathis={{
                            name: "contact_number",
                            required: true,
                            autoFocus: false,
                          }}
                          inputStyle={{
                            width: "100%",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            height: "56px",
                          }}
                          country={"gb"}
                          name="contact_number"
                          placeholder=""
                          specialLabel="Contact Number"
                          enableSearchField
                          value={values.contact_number}
                          onChange={(value) => {
                            setFieldValue("contact_number", value);
                            const cont = { contact_number: value };
                            setUserData({ ...userData, ...cont });
                          }}
                        />
                        <FormHelperText error>
                          {touched.contact_number && errors.contact_number
                            ? errors.contact_number
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="name-input"
                          error={
                            touched.property_name && errors.property_name
                              ? true
                              : false
                          }
                          name="property_name"
                          fullWidth
                          label="Business Legal Name"
                          type="text"
                          value={values.property_name}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("property_name", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                        />

                        <FormHelperText error>
                          {touched.property_name && errors.property_name
                            ? errors.property_name
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="name-input"
                          error={
                            touched.property_trading_name &&
                            errors.property_trading_name
                              ? true
                              : false
                          }
                          name="property_trading_name"
                          fullWidth
                          label="Business Trading Name"
                          type="text"
                          value={values.property_trading_name}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("property_trading_name", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                        />

                        <FormHelperText error>
                          {touched.property_trading_name &&
                          errors.property_trading_name
                            ? errors.property_trading_name
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Business Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            error={
                              touched.business_type && errors.business_type
                                ? true
                                : false
                            }
                            id="demo-simple-select"
                            name="business_type"
                            value={values.business_type}
                            label="Company Type"
                            onChange={handleChange}
                          >
                            {userData.business_types &&
                              userData.business_types.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item}>
                                    <span>{item}</span>
                                  </MenuItem>
                                );
                              })}
                          </Select>

                          <FormHelperText error>
                            {touched.business_type && errors.business_type
                              ? errors.business_type
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <GoogleMaps
                          id="name-input"
                          name="property_address"
                          error={
                            touched.property_address && errors.property_address
                              ? true
                              : false
                          }
                          value={values.property_address}
                          fullWidth
                          label="Business Address"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("property_address", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                          onPlaceSelected={(selectedLocation) => {
                            getBusinessType();
                            handleLocationSelect(
                              selectedLocation,
                              setFieldValue,
                              setUserData
                            );
                          }}
                        />
                        <FormHelperText>
                          Enter address (e.g., Street, City, State)
                        </FormHelperText>
                      </Grid>

                      {/* <Grid item xs={12} md={4}>
                        <TextField
                          id="name-input"
                          error={
                            touched.property_address && errors.property_address
                              ? true
                              : false
                          }
                          name="property_address"
                          fullWidth
                          label="Business Address"
                          type="text"
                          value={values.property_address}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("property_address", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                          onBlur={async (e) => {
                            await getFullAddress(setFieldValue);
                          }}
                        />

                        <FormHelperText error>
                          {touched.property_address && errors.property_address
                            ? errors.property_address
                            : null}
                        </FormHelperText>
                      </Grid> */}

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="name-input"
                          error={touched.city && errors.city ? true : false}
                          name="city"
                          fullWidth
                          label="City"
                          type="text"
                          value={values.city}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("city", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                          onBlur={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("city", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                        />

                        <FormHelperText error>
                          {touched.city && errors.city ? errors.city : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="name-input"
                          name="postal_code"
                          error={
                            touched.postal_code && errors.postal_code
                              ? true
                              : false
                          }
                          fullWidth
                          label="Post Code"
                          type="text"
                          value={values.postal_code}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setFieldValue("postal_code", value);
                            setUserData({ ...userData, [name]: value });
                          }}
                        />
                        <FormHelperText error>
                          {touched.postal_code && errors.postal_code
                            ? errors.postal_code
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <ReactSelect
                          id="country"
                          name="country"
                          styles={customStyles}
                          className="lab"
                          classNamePrefix="react-select"
                          options={userData.country_options}
                          //        onChange={handleCountryOnChange}
                          onChange={(value) => {
                            let cont = {
                              country: value,
                              country: value.label,
                              country_code: value.value,
                            };
                            setFieldValue("country", value.label);

                          
                            setUserData({ ...userData, ...cont });
                          }}
                          value={{
                            label: values.country || "",
                            value: values.country_code || "",
                          }}
                        />

                        <FormHelperText error>
                          {touched.country_code && errors.country_code
                            ? errors.country_code
                            : null}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={12} style={{ paddingTop: 5 }}>
                        {/*   {props.activeStep !== props.steps.length - 1 && (
                    <StepperControl
                      handleClick={props.handleClick}
                      currentStep={props.activeStep}
                      steps={props.steps}
                    />
                  )} */}

                        <Button
                          className="float-right"
                          type="submit"
                          variant="contained"
                          disabled={isLoading}
                          sx={{ mt: 2, mb: 2 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
