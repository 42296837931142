import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "./phoneInput.css";
import "react-phone-input-2/lib/material.css";
import PropTypes from "prop-types";
import { getIn, ErrorMessage } from "formik";

const PhoneInputField = (props) => {
  const {
    field: { name, value },
    form: {
      errors,
      handleBlur,
      setFieldValue,
      touched,
      setFieldError,
      setFieldTouched,
    },
    country,
    inputClass,
    disabled,
    inputStyle,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const isError = getIn(touched, name) && getIn(errors, name);
  const errorStyle = isError ? "error" : "";
  const filledStyle = isFocused || value ? "filled" : "";
  const disabledStyle = disabled ? "disabled" : "";

  const phoneStyle = {
    ...inputStyle,
  };

  const handleInputBlur = (e) => {
    setFocused(false);
    setFieldTouched(name, true, true);
    handleBlur(e);
  };

  // const handleInputFocus = () => setFocused(true);

  const onValueChange = (phoneNumber, country, e, formattedValue) => {
    setFieldValue(name, phoneNumber, true);
    setFieldValue("dialCode", country.dialCode, true);
    if (phoneNumber === "") {
      setFieldError(name, "Phone number is required");
    }
    let dialCode = "+" + country.dialCode;
    let formatted_value = formattedValue;
    let phone = formatted_value.replace(dialCode, "");

    let val = { dialCode, phone };
    console.log(val)
    props.setPhone(val);
  };

  return (
    <div className={`${errorStyle} ${filledStyle} ${disabledStyle}`}>
      <PhoneInput
        specialLabel="Phone *"
        name={name}
        value={value}
        autoFormat={false}
        inputClass={inputClass}
        placeholder="+44 7947 111111"
        onChange={(phone, country, e, formattedValue) =>
          onValueChange(phone, country, e, formattedValue)
        }
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
        }}
        inputProps={{
          maxLength: '13',
        }}
        country={country}
        onBlur={handleInputBlur}
        inputStyle={phoneStyle}
      />
       {/* Add an indicator under or near the field */}
       <i style={{ color: 'grey', fontSize: '12px' }}>
        Example: +44 7947 111111
      </i>
      {/* <ErrorMessage name={name} className="invalid-feedback" /> */}
    </div>
  );
};

PhoneInputField.propTypes = {
  className: PropTypes.string,
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  country: PropTypes.string,
  disabled: PropTypes.bool,
};

// PhoneInputField.defaultProps = {
//   className: '',
//   label: '',
//   onChange: null,
//   country: '',
//   disabled: false,
// };

export default PhoneInputField;
