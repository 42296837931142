import React, { useState, useEffect } from 'react';
import { FileLibraryListItem, ReactMediaLibrary, FileMeta } from 'react-media-library';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Typography from '@mui/material/Typography';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';


//Image upload modules
import { Upload, Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import 'antd/dist/reset.css';

import Box from '@mui/material/Box';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    );
}

const imageList = [
    {
        "_id": 1,
        "thumbnailUrl": "https://picsum.photos/200/200"
    },
    {
        "_id": 2,
        "thumbnailUrl": "https://picsum.photos/200/200"
    },
    {
        "_id": 3,
        "thumbnailUrl": "https://picsum.photos/200/200"
    },
    {
        "_id": 4,
        "thumbnailUrl": "https://picsum.photos/200/200"
    }
];


class ReactMediaLibraryWrapper extends React.Component {



    state = {
        display: false,
        fileLibraryList: '',
        tab: 0,
        fileList: [],
        imageList: imageList
    };





    /*     useEffect(() => {
           
            setFileLibraryList([
                {
                    "_id": 1,
                    "title": "Me and my dog",
                    "size": 294880,
                    "fileName": "img_3549.jpg",
                    "type": "image/jpeg",
                    "createdAt": new Date("2019-10-17T03:12:29.866Z"),
                    "thumbnailUrl": "https://mycustomcdn.com/mg_3549.jpg"
                },
                {
                    "_id": 2,
                    "title": "2019 Summer Vacation",
                    "size": 864483,
                    "fileName": "201702.jpg",
                    "type": "image/jpeg",
                    "createdAt": new Date("2019-10-17T03:12:45.018Z"),
                    "thumbnailUrl": "https://mycustomcdn.com/201702.jpg"
                },
                {
                    "_id": 3,
                    "title": "Our new baby",
                    "size": 586458,
                    "fileName": "271092.jpg",
                    "type": "image/jpeg",
                    "createdAt": new Date("2019-10-17T03:19:33.498Z"),
                    "thumbnailUrl": "https://mycustomcdn.com/271092.jpg"
                },
                {
                    "_id": 4,
                    "title": "My new car",
                    "size": 894665,
                    "fileName": "photo-107.jpg",
                    "type": "image/jpeg",
                    "createdAt": new Date("2019-10-17T03:28:39.723Z"),
                    "thumbnailUrl": "https://mycustomcdn.com/photo-107.jpg"
                }
            ]);
        }, []);
     */


    handleTabChange = (event, newValue) => {
        this.setState({ tab: newValue });
    };

    pushImagestoGallery = (img) => {
        this.setState( { imageList: [...imageList, ...img]});
    };


    render() {



        //Uploaded url
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                //reader.onerror = error => reject(error);
            });
        }


        class PicturesWall extends React.Component {
            state = {
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                fileList: [],
            };

            handleCancel = () => this.setState({ previewVisible: false });


            //Image Preview
            handlePreview = async file => {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }

                this.setState({
                    previewImage: file.url || file.preview,
                    previewVisible: true,
                    previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
                });
            };

            handleChange = ({ fileList }) => {
                this.setState({ fileList })
                console.log(fileList)

               let  img =   [{ _id: imageList.length + 1, thumbnailUrl: fileList[0].name }];

            //    this.setState({imageList:   imgLists });

            this.props.pushImagestoGallery(img);

                console.log(img)
          //      console.log(this.state)
                
            };

            handleSubmit = event => {
                event.preventDefault();

                let formData = new FormData();
                // add one or more of your files in FormData
                // again, the original file is located at the `originFileObj` key

                //Files Storage Looping
                for (var a = 0; a < this.state.fileList.length; a++) {
                    formData.append("file[]", this.state.fileList[a].originFileObj);

                }

                //File saving API call
                axios
                    .post("http://localhost/save.php", formData)
                    .then(res => {
                        alert("Files uploaded.");

                    })
                    .catch(err => {
                        console.log("err", err);
                    });
            }

            render() {
                const { previewVisible, previewImage, fileList, previewTitle } = this.state;
                const uploadButton = (
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                );
                return (
                    <>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            beforeUpload={() => false}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        <Modal
                            open={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={this.handleCancel}
                        >

                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                        <Button className="btn btn-primary" color="primary" variant="contained" type="button" onClick={this.handleSubmit} // this button click will trigger the manual upload
                        >
                            Submit
                        </Button>
                    </>
                );
            }
        }











        return (
            <React.Fragment>
                <Box sx={{ width: '100%' }}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{ style: { background: "#5773ff" } }}
                        >
                            <Tab label="Upload" />
                            <Tab label="Library" />
                        </Tabs>
                        <TabPanel value={this.state.tab} index={0} className="tabPanel">

                            <PicturesWall imageList={imageList}  pushImagestoGallery={this.pushImagestoGallery} />

                            <br></br>
                            <Grid container spacing={3} style={{ padding: 10 }}>
                                <Grid item xs={12} sm={12}>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        alignItems="center"
                                    >



                                        {this.state.imageList.map((image, index) => (
                                            <Grid key={index} className="image-item" item xs={3} sm={3}>

                                                <img src={image.thumbnailUrl} alt="" width="200px" height="200px" />


                                            </Grid>
                                        ))}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tab} index={1}>
                            <div className="ms-panel">
                                <div className="ms-panel-body">

                                    gfdgfdzggfh
                                </div>
                            </div>
                        </TabPanel>

                    </AppBar>
                </Box>

            </React.Fragment>);
    };
}


export default ReactMediaLibraryWrapper;