import React, { useMemo, useState } from "react";
import { TimePicker } from "antd";
import { Box } from "@mui/material";
import { InputLabel } from "@mui/material";

function TimePickerComp(props) {
  const [isFocused, setIsFocused] = useState();

  const styles = {
    "& .ant-picker": {
      border: "none",
      height: "47px",
      borderBottom: "1px solid grey",
      borderRadius: 0,
      width: "100%",
    },
    "& .ant-picker-focused": {
      boxShadow: "none",
      borderBottom: "1px solid #5773ff",
    },
  };

  const isShrinked = useMemo(() => {
    return isFocused || props?.defaultValue || props?.value;
  }, [isFocused, props]);

  return (
    <Box sx={styles}>
      <InputLabel
        sx={{
          color: isShrinked ? "#5773ff" : "grey",
          marginLeft: isShrinked ? "-11px" : "0",
        }}
        shrink={isShrinked && true}
      >
        {props.label}
      </InputLabel>
      <TimePicker
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        placeholder=""
        {...props}
      />
    </Box>
  );
}

export default TimePickerComp;
