import React, { Component } from "react";
import { toast } from "react-toastify";
import * as DiscountServiceAPI from "../../services/discount/DiscountServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormControl } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import isEqual from "lodash/isEqual";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  TableSortLabel,
} from "@mui/material";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
//import Checkbox from '@mui/material/Checkbox';
import DataTable from "react-data-table-component";
import moment from "moment";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  fetchMenuNames,
  fetchMenu,
} from "../../services/product/menu/MenuServiceAPI";
import "@arco-design/web-react/dist/css/arco.css";
import { TreeSelect, Checkbox } from "@arco-design/web-react";
import {
  fetchCategoryNames,
  fetchCategory,
} from "../../services/product/category/CategoryServiceAPI";

import {
  fetchUsers,
  addUser,
  updateUser,
  recreateUser,
  updateUsers,
} from "../../services/user/SecurityServiceAPI";
import ReactPhoneInput from "react-phone-input-2";
import {
  addItem,
  updateItem,
  fetchItem,
  addExtrasOptions,
  deleteExtrasOptions,
  updateExtrasOptions,
  fetchAddonByAddonId,
  fetchUpsellItems,
} from "../../services/product/item/ItemServiceAPI";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DatePicker, Space, Alert } from "antd";
import { Card, CardContent, Typography } from "@mui/material";
const { RangePicker } = DatePicker;
const userStatusOptions = [
  /* { value: "FIXED", label: "Fixed" }, */
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "INACTIVE", label: "INACTIVE" },
  //{ value: "FORCE PASSWORD CHANGE", label: "FORCE PASSWORD CHANGE" },
  { value: "DELETED", label: "DELETED" },
  { value: "TEMP SUSPENDED", label: "TEMP SUSPENDED" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    users: [],
    selectedDiscount: null,
    open: false,
    dId: "",
    menuNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedMenu: "",
    user: null,
    defaultRange: [
      moment(new Date(), "DD-MM-YYYY HH:mm:ss"),
      moment(new Date(), "DD-MM-YYYY HH:mm:ss"),
    ],
    dialCode: "44",
    showForm: false,
    userStatus: "",
    row: "",
    showFormDialog: false,
  };

  columns = [
    /*   {
        name: "Username",
        selector: "username",
        sortable: true,
        justifyContent: 'center',
        cell: row => <div ><div>{row.username}</div></div>
      }, */

    {
      name: "Email",
      selector: "email",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.email}</div>
        </div>
      ),
    },

    {
      name: "Name",
      selector: "name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.name}</div>,
    },

    {
      name: "Pin",
      selector: "name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.user_pin}</div>,
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.status}</div>,
    },

    /*   {
        name: "Status",
        selector: "status",
        sortable: true,
        justifyContent: 'center',
        cell: row => <div style={{ margin: "10px" }} >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={row.status}
              label="Status"
              size="small"
              onChange={(e) => {
                this.updateStatus(row.username, e.target.value);
              }}
            >
              {userStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      }, */

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        <Stack spacing={1} sx={{padding:"4px"}}>
          <Stack direction="row" spacing={2}>
        
            <Button variant="outlined" startIcon={ <EditIcon color="primary"/>} onClick={() => this.editUser(row)}>Edit</Button>
          
            {row.status !== "DELETED" && (
              <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={() => this.handleClickOpen(row)}>Delete</Button>
            )}

          </Stack>

          {row.status === "FORCE PASSWORD CHANGE" && (
            <Button
              variant="outlined"
              sx={{ marginBottom: "5px" }}
              color="primary"
              onClick={() => this.reset(row.email)}
            >
              Reset Password
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  editUser = (user) => {
    this.setState({ user: user, showForm: true }, () => {
      this.setInitalValues();
      console.log(this.state);
    });
  };

  handleClickOpen = (row) => {
    this.setState({ open: true, row: row, showFormDialog: true });
  };

  componentDidMount = () => {
    console.log("MOUNT");
    this.fetchAllUsers();
    this.setInitalValues();
  };

  handleCancel = () => {
    this.setState(
      {
        user: null,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, user: "" });
  };

  setInitalValues = () => {
    const { user } = this.state;

    let initialValues = {};
    initialValues = {
      name: user?.name || "",
      email: user?.email || "",
      user_pin: user?.user_pin || "",
      username: user?.username || "",
      status:user?.status || "",
      //   phone_number: user?.phone_number || '',
    };

    this.setState({ initialValues });
  };

  fetchAllUsers = () => {
    this.setState({ isLoading: true });
    let propertId = this.props.propertyId;
    fetchUsers(propertId)
      .then((response) => {
        if (response.data.status) {
          console.log(
            response.data.data.business_users[0].status,
            "response users"
          );
          this.setState({
            users: response.data.data.business_users,
            isLoading: false,
          });
        } else {
          this.setState({ users: [], isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not fetch discounts.. ");
      });
  };

  addUser = (values, resetForm) => {
    addUser(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("User added.");
          resetForm({ values: "" });
          this.setState({ user: null, showForm: false }, () => {});
          this.fetchAllUsers();
        } else {
          this.setState({ user: null }, () => {
            this.setInitalValues();
          });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add User.");
      });
  };
  updateUser = (values, resetForm) => {
    // values["status"] = values["status"] || this.state.userStatus;
    console.log(values, "update values");
    updateUsers(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("User Updated.");
          resetForm({ values: "" });
          this.setState({ user: null, showForm: false, }, () => {
            this.setInitalValues();
          });
          this.fetchAllUsers();
        } else {
          this.setState({}, () => {
            this.setInitalValues();
          });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add User.. ");
      });
  };

  updateStatus = (row) => {
    const payload = {
      username: row?.username,
      status: "DELETED",
      user_pin: row?.user_pin,
      property_id: this.props.propertyId,
    };

    updateUser(payload)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          toast.success("User deleted.");
          this.setState({ user: null, row: null, open: false }, () => {
            this.setInitalValues();
          });
          this.fetchAllUsers();
        } else {
          toast.warn(response.data.message);
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, row: null, open: false });
        toast.warn("Could not delete user. ");
      });
  };

  reset = (email) => {
    recreateUser(email)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          this.setState({ user: null }, () => {
            this.setInitalValues();
            toast.success("User reset successful.");
          });
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not reset user. ");
      });
  };

  deleteUser = (row) => {
    this.updateStatus(row, "DELETED");
  };

  handleUserSubmit = (values, resetForm) => {
    if (this.state.user) {
      //    values['status'] = values['status'] || this.user.status;
      this.updateUser(values, resetForm);
    } else {
      this.addUser(values, resetForm);
    }
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
        user: null,
        row: null,
      });
    } else {
      this.setState(
        {
          showForm: false,
          user: null,
          row: null,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  removePrefixvalues(str) {
    if (str.startsWith("44")) {
      return str.substring(2); // Remove the first two characters
    }
    return str; // No change if it doesn't start with '44'
  }

  render() {
    const {
      submitting,
      initialValues,
      showForm,
      showFormDialog,
      row,
      user,
      userStatus,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                {showFormDialog && (
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete User?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>No</Button>
                      <Button onClick={() => this.deleteUser(row)}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                )}
                <div className="ms-panel-body">
                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Users</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.user ? "Update" : "Create"} User
                          </Button>
                        </Grid>
                      </Grid>
                      <br />
                      <Divider />
                    </React.Fragment>
                  )}
                </div>

                {showForm && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      <div style={{ margin: "10px" }}>
                        {this.state.user ? "Update" : "Add"} user.
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details to{" "}
                      {this.state.user ? "Update" : "Add"} user.
                    </p>
                    <Divider />
                    <br /> <br />
                    <Grid container>
                      <Grid xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize="true"
                          initialValues={initialValues}
                          validationSchema={schema.userSchema}
                          onSubmit={(values, { resetForm }) => {
                            console.log(values);
                            values["property_id"] = this.props.propertyId;
                            //   values['dial_code'] = "+" + this.state.dialCode;
                            //   values['phone_number'] =   this.removePrefixvalues(values['phone_number']);
                            this.handleUserSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              {showForm && (
                                <Box sx={{ margin: "15px" }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={
                                            touched.name && errors.name
                                              ? true
                                              : false
                                          }
                                          name="name"
                                          label="Name"
                                          autoComplete="name"
                                          type="text"
                                          inputProps={{ maxLength: 20, minLength : 2 }}
                                          variant="standard"
                                          pattern="\s*\S+.*"
                                          value={values.name || ""}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "name",
                                              e.target.value
                                            );
                                          }}
                                          aria-describedby="component-error-text"
                                        />
                                        <FormHelperText error>
                                          {touched.name && errors.name
                                            ? errors.name
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={
                                            touched.email && errors.email
                                              ? true
                                              : false
                                          }
                                          disabled={user}
                                          name="email"
                                          label="Email"
                                          autoComplete="email"
                                          type="text"
                                          variant="standard"
                                          pattern="\s*\S+.*"
                                          value={values.email || ""}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "email",
                                              e.target.value
                                            );
                                          }}
                                          aria-describedby="component-error-text"
                                        />
                                        <FormHelperText error>
                                          {touched.email && errors.email
                                            ? errors.email
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={
                                            touched.user_pin && errors.user_pin
                                              ? true
                                              : false
                                          }
                                          name="user_pin"
                                          label="Pin"
                                          autoComplete="user_pin"
                                          type="text"
                                          variant="standard"
                                          value={values.user_pin || ""}
                                          inputProps={{ maxLength: 4 }}
                                          onInput={(event) => {
                                            if (!isNaN(event.target.value)) {
                                              event.target.value = !isNaN(
                                                event.target.value
                                              )
                                                ? event.target.value
                                                : "";
                                            } else {
                                              event.target.value = "";
                                            }
                                          }}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "user_pin",
                                              e.target.value
                                            );
                                          }}
                                          aria-describedby="component-error-text"
                                        />
                                        <FormHelperText error>
                                          {touched.name && errors.user_pin
                                            ? errors.user_pin
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    {user && (
                                      <Grid item xs={12} md={6} lg={6}>
                                        <FormControl fullWidth>
                                          <InputLabel id="status-select-label">
                                            Status
                                          </InputLabel>
                                          <Select
                                            labelId="status-select-label"
                                            id="status-select"
                                            value={
                                              values.status|| ""
                                            }
                                            label="Status"
                                          
                                            onChange={(e) => {
                                              setFieldValue(
                                                "status",
                                                e.target.value
                                              );
                                            }}
                                         /*    disabled={
                                              user.status ===
                                              "FORCE PASSWORD CHANGE"
                                            } */
                                          >
                                            {userStatusOptions.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    )}

                                    {/*       <Grid item xs={6} md={3} lg={3} >
                                <ReactPhoneInput
                                  inputExtrathis={{
                                    name: 'phone_number',
                                    required: true,
                                    autoFocus: false,
                                  }}
                                  inputStyle={{
                                    width: "100%",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    height: "56px"
                                  }}
                                  ref={(ref) => (this.phoneInputRef = ref)}
                                  country={"gb"}
                                  name='phone_number'
                                  placeholder=""
                                  specialLabel="Contact Number"
                                  enableSearchField
                                  // placeholder="0207*******"
                                  value={values.phone_number}
                                  //  onChange={handleoPhoneChangeHandler}
                                  onChange={(value, country) => {
                                    setFieldValue("phone_number", value);
                                    if (country) {
                                      const dialCode = country.dialCode;
                                      this.setState({ dialCode })
                                      console.log('Dial Code:', dialCode);
                                    }
                                  }}

                                />
                                <FormHelperText error>
                                  {touched.phone_number && errors.phone_number
                                    ? errors.phone_number
                                    : null}
                                </FormHelperText>
                              </Grid>
 */}
                                  </Grid>

                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                                  >
                                    {this.state?.user ? "Update" : "Submit"}
                                  </Button>
                                  {this.state?.discount && (
                                    <Button
                                      type="button"
                                      color="primary"
                                      variant="contained"
                                      block
                                      onClick={() => this.handleCancel()}
                                      sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                      <Grid xs={12} md={4} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Box sx={{ padding: "20px", paddingTop: "0px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>You can create users.</b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                          // color: "#5773ff",
                                        }}
                                      >
                                        <b> Name</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Full Name of the person.
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                          // color: "#5773ff",
                                        }}
                                      >
                                        <b> Email .</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Email Id of user.
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>PIN</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Pin used to access the account.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-1">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.users}
                      /*  expandableRows
                     expandableRowsComponent={<ExpandableComponent />} */
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
