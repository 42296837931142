import React, { Component } from 'react';
import { Typography } from '@mui/material';
import { Button, Result } from 'antd';
import "../css/setup.css";
export default function Final(props) {
//  console.log(props);

  const handleChange = (event) => {

    props.setActiveStep(2);

  };

  return (
    <div className="col-12">
      <div className="ms-panel">
        <div className="ms-panel-body">
{/* 
          <Typography variant="h4" align="center" gutterBottom>
            {props.initialValues?.adyen?.account_status === "ACCOUNT COMPLETED" ? "Your account is now ready to accept payments." : "Your account is now ready to accept payments."}
          </Typography>

          {props.initialValues?.adyen?.account_status !== "ACCOUNT COMPLETED" ? <Typography variant="h5" align="center" gutterBottom>
            {props.initialValues?.mes ? "If you need to update any information, please click the button below." : "If you need to update any information, please click the button below."}
          </Typography> : ""}

          {props.initialValues?.adyen?.account_status !== "ACCOUNT COMPLETED" ? <button className="btn btn-primary" onClick={() => handleChange()} style={{ margin: "auto", display: "block" }}>
            Update
          </button> : ""} */}

          <Result   stye={{  color: "#5773ff" }}
           status="success"
            title={props.initialValues?.adyen?.account_status === "ACCOUNT COMPLETED" ? "Your account is now ready to accept payments." : "Your account is now ready to accept payments."}
           
          />

        </div>
      </div>
    </div>
  );
}