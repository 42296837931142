import React, { Component } from 'react';
import { createContext, useContext, useState } from "react";




export const StepperContext = createContext({ userData: [], setUserData: null, actStep: 0, setActStep: null });

export function UseContextProvider({ children }) {
    const [userData, setUserData] = useState([]);

    const [actStep, setActStep] = useState(0);

    return (
        <StepperContext.Provider value={{ userData, setUserData, actStep, setActStep }}>
            {children}
        </StepperContext.Provider>
    );
}

export function useStepperContext() {
    const { userData, setUserData, actStep, setActStep } = useContext(StepperContext);

    return { userData, setUserData, actStep, setActStep };
}

export function UseContextConsumer({ children }) {
    return (<StepperContext.Consumer>
        {children}
    </StepperContext.Consumer>
    );
}