import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import $ from "jquery";
import Breadcrumb from './Breadcrumb';
import { Jumbotron, Container } from 'react-bootstrap';
import ApiIcon from '@mui/icons-material/Api';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
class Content extends Component {


  componentDidMount = () => {
    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }

    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  }



  render() {
    const tabs = [


      {
        title: 'Manage Room',
        link: '/room',
        description: 'Add, Delete Room.',
        icon_name: 'building'
      },
      {
        title: 'Manage Table',
        link: '/table',
        description: 'Add, Update, Delete Table.',
        icon_name: 'circle'
      },
      {
        title: 'Generate QR.',
        link: '/generate-qr-code',
        description: 'Generate QR.',
        icon_name: 'center_focus_weak'
      },


    ]


    return (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="ms-panel">
            <div className="ms-panel-body">
              <div className="row">
                {tabs.map((tab, i) => (
                  <div key={i} className="col-xl-6 col-md-6">
                    <Link to={tab.link}> <div className="ms-panel-body media ms-panel-hoverable has-border ms-widget ms-has-new-msg ms-notification-widget">
                      {tab.icon_name == "building" ? <i class="fa fa-building"></i> : tab.icon_name == "circle" ? <i class="fa fa-circle"></i> : <i className="material-icons">{tab.icon_name}</i>}
                      <div className="media-body" >
                        <h6>{tab.title}</h6>
                        <span>{tab.description}</span>
                      </div>
                    </div></Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default Content;