import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Payout from "./BillingPayout";
import Web from "../../components/sections/web/Content";
import Qr from "../../components/sections/qr/Content";
import Nash from "./Nash";
import EposNow from "./EposNow";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          padding: "20px",
          alignItems: "flex-start",
          width: "200px",
        }}
      >
        <Tab label="Payouts" {...a11yProps(0)} />
        <Tab label="Qr Invoice" {...a11yProps(1)} />
        <Tab label="Web Invoice" {...a11yProps(2)} />
        <Tab label="Epos Now" {...a11yProps(3)} />
        <Tab label="Delivery" {...a11yProps(4)} />

      </Tabs>
      <TabPanel value={value} index={0}>
        <Payout />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Qr />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Web />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EposNow />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Nash />
      </TabPanel>
    </Box>
  );
}
