import axios from "axios";
import * as APIUrl from "../../APIUrl";

export const addAddonCategory = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_ADDON_CATEGORY,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addCombo = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_COMBO,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteAddonCategory = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.DELETE_ADDON_CATEGORY + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteComboGroup = (payload) => {
  try {
    return axios({
      method: "delete",
      url: APIUrl.DELETE_COMBO_GROUP + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const comboGroup = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.DELETE_ADDON_CATEGORY + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllAddonCategory = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ALL_ADDON_CATEGORY + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllCombos = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ALL_COMBOS + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAllComboItems = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ALL_COMBO_ITEMS + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateAddonCategory = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_ADDON_CATEGORY,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const updateCombo = (payload) => {
  try {
    return axios({
      method: "put",
      url: APIUrl.UPDATE_COMBO,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

