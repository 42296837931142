import React, { Component, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Addproduct from "../../components/sections/Addproduct/Addproductcontent";

// Routes of component
import Homecontent from "../../components/sections/Dashboard/Homecontent";
import Setup from "../../components/sections/Dashboard/Step/Main";
import Menu from "../../components/sections/Menu";
// import Menu from "../../components/sections/Menucatelogue/Menu";
import Menugrid from "../../components/sections/Menugrid/Gridcontent";
import Menulist from "../../components/sections/Menulist/Listcontent";
import OrderDetails from "../../components/sections/OrderDetails/OrderDetails";
import Orders from "../../components/sections/Orders/Ordertable";
import Disputes from "../../components/sections/disputes/disputes";
import DisputeDetails from "../../components/sections/disputes/disputeDetails/DisputeDetails";
import Payout from "../../components/sections/Payout/Content";
import Payoutdetails from "../../components/sections/Payoutdetails/Detailcontent";
import TransactionDetails from "../../components/sections/Tansactiondetails/Detailcontent";
import Transaction from "../../components/sections/Transaction/Content";
import Item from "../../components/sections/items/Content";
import UnSold from "../../components/sections/sales/Content";
import topSell from "../../components/sections/topsales/Content";
import zReport from "../../components/sections/zreport/Content";
import dataReport from "../../components/sections/reports/dataReport/Content";
import dogDollarReport from "../../components/sections/reports/dogDollarCorrection/Content";
import grossSalesStoreReport from "../../components/sections/reports/SalesStore/Content";
import paymentMethodReport from "../../components/sections/reports/PaymentMethod/Content";
import totalReport from "../../components/sections/reports/total/Content";
import charlieReport from "../../components/sections/reports/charlie/Content";
import itemReport from "../../components/sections/itemReport/Content";
import WeeklyBusinessReport from "../../components/sections/weeklyBusinessReport/Content";
import PLUSalesReport from "../../components/sections/pluReport/Content";
import ActivityReport from "../../components/sections/activityReport/Content";
import SummaryReport from "../../components/sections/summaryReport/Content";
import trending from "../../components/sections/trending/Content";
import Tax from "../../container/tax/index";
import General from "../general/index";
import TableManagement from "../../components/sections/TableManagement/TableManagement";
import Notification from "../../container/notifications/index";
import Theme from "../../container/themes/index";
import NotificationSetting from "../../container/notificationSetting/index";
import Promotions from "../../container/promotions/index";
import TakeAwayOrder from "../../container/TakeAwayOrder";
import DineinOrder from "../../container/DineinOrder";
import DeliveryToPark from "../../container/DeliveryToPark";
import DonationOrder from "../../container/DonationOrder";
import shift from "../../container/shift";
import suite from "../../container/suite";
import Bookedsuite from "../../container/booSuite";
//PUBLIC ROUTE
import Login from "../login";
import TermsAndContions from "../../container/terms-and-condtions/index";
import SignUp from "../SignUp/";
import Payment from "../payment/index";
import Grants from "../cashAdvance/index";
import GrantTerms from "../cashAdvance/terms-and-condtions/index";
import GrantAccept from "../cashAdvance/accept/index";
import GrantDetails from "../cashAdvance/details/index";
import Receipt from "../receipt/index";
import Product from "../../components/sections/product/index";
import Shop from "../../components/sections/shop/index";
import ShopItemDetails from "../../components/sections/shop/ShopItemDetails";
import Cart from "../../components/sections/shop/Cart";
import Discount from "../discount/index";
import eposDiscount from "../eposDiscounts/index";
import DeliveryCharges from "../deliveryCharges/index";
import ManageUser from "../manageUsers/index";
import HappyHourDiscount from "../happyHourDiscount/index";
import Room from "../room/index";
import Table from "../table/index";
import GenerateQRCode from "../generateQR/index";
import ManageQR from "../manage-qr/index";
import QRFlow from "../manage-qr-flow/index";
import Pricing from "../Pricing/index";
import Pay from "../pay/index";
import Approved from "../../container/cashAdvance/approved/index";
import EnableWebOrdering from "../webOrdering/index";
import EnableWebOrderingAccept from "../webOrdering/accept/index";
import CREATE_QR_FLOW from "../../components/sections/steps/HorizontalNonLinearStepper";
import PayByLink from "../../components/sections/payByLink/Content";
import PayByLinkTranasctionDetails from "../../components/sections/payByLinkTransactionDetails/Detailcontent";
import PayByLinkTranasctions from "../../components/sections//payByLinkTransactionDetails/Tansactiondetails/Detailcontent";
import SplitPayment from "../SplitPayment/index";
import Userprofile from "../Profile/index";
import Settings from "../settings/Content";
import AllDiscounts from "../settings/discounts/Content";
import Guide from "../guide/Content";
import CreateNewPassword from "../../container/ForcePasswordChange";
import PrivateRoutes from "../_PrivateRoutes";
import PublicRoutes from "../_PublicRoute";
import { AppSetting } from "../AppSettings/AppSetting";
import AppTiming from "../AppTiming/index";
import TableReservationUpdatePage from "../TableReservationUpdatePage/index";
import Gallery from "../../components/sections/Menu/dialogs/ReactMediaLibraryWrapper";
import index from "../../container/finance/Index";
import Nash from "../NashOrdering/Nash";
import nashHome from "../NashOrdering/Index";
import DeliverictActivate from "../eposNow/deliverictActivate/index";
import DeliverictTerms from "../eposNow/deliverictActivate/terms/index";
import NashOrder from "../NashOrdering/NashOrder";
import EposNow from "../eposNow/index";
import Tip from "../tip/index";
import EposActivate from "../eposNow/accept/index";
import InventoryReason from "../../container/inventory/reason/AddBatchReason";
import Inventory from "../../container/inventory/Inventory";
import InventorygAccept from "../../container/inventory/accept/index";
import EnableInventory from "../../container/inventory/index";
import ScreenSaverTerms from "../../container/tip/accept/index";
import ScreenSaverPay from "../../container/tip/pay/index";
import tagging from "../ItemTagging/index";
import FloorPlannerWrapper from "../../container/floor-table/FloorPlannerWrapper";

function Routes(props) {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  return (
    <Switch>
      {isLoggedIn && (
        <PrivateRoutes
          path="/dashboard"
          isLoggedIn={isLoggedIn}
          comp={Homecontent}
        />
      )}
      {isLoggedIn && <PrivateRoutes path="/setup" component={Setup} />}

      {isLoggedIn && (
        <PrivateRoutes path="/table-management" component={TableManagement} />
      )}

      {isLoggedIn && <PrivateRoutes path="/setting" component={Settings} />}
      {isLoggedIn && <PrivateRoutes path="/item-tags" component={tagging} />}


      {isLoggedIn && <PrivateRoutes path="/all-discounts" component={AllDiscounts} />}

      {isLoggedIn && <PrivateRoutes path="/guide" component={Guide} />}

      {isLoggedIn && <PrivateRoutes path="/general" component={General} />}

      {isLoggedIn && <PrivateRoutes path="/general" component={General} />}

      {isLoggedIn && <PrivateRoutes path="/payment" component={Payment} />}

      {isLoggedIn && <PrivateRoutes path="/grants" component={Grants} />}

      {isLoggedIn && (
        <PrivateRoutes path="/capital-terms-conditions" component={GrantTerms}/>
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/grant-accept" component={GrantAccept} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/grant-details" component={GrantDetails} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/discount/:platformName" component={Discount} />
      )}

      {isLoggedIn && <PrivateRoutes path="/epos-mpos-discounts" component={eposDiscount} />}

      {isLoggedIn && (
        <PrivateRoutes path="/delivery-charges" component={DeliveryCharges} />
      )}
      {isLoggedIn && <PrivateRoutes path="/shift" component={shift} />}

      {isLoggedIn && <PrivateRoutes path="/suite" component={suite} />}

      {isLoggedIn && <PrivateRoutes path="/booked-suite" component={Bookedsuite} />}

      {isLoggedIn && (
        <PrivateRoutes path="/manage-user" component={ManageUser} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/happy-hour-discount"
          component={HappyHourDiscount}
        />
      )}

      {isLoggedIn && <PrivateRoutes path="/room" component={Room} />}

      {isLoggedIn && <PrivateRoutes path="/table" component={Table} />}

      {isLoggedIn && (
        <PrivateRoutes path="/generate-qr-code" component={GenerateQRCode} />
      )}

      {isLoggedIn && <PrivateRoutes path="/manage-qr" component={ManageQR} />}

      {isLoggedIn && (
        <PrivateRoutes path="/manage-qr-flow" component={QRFlow} />
      )}

      {isLoggedIn && <PrivateRoutes path="/pricing" component={Pricing} />}

      {isLoggedIn && (
        <PrivateRoutes path="/create-qr-flow" component={CREATE_QR_FLOW} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/pay-by-link" component={PayByLink} />
      )}

      {isLoggedIn && <PrivateRoutes path="/pay" component={Pay} />}

      {isLoggedIn && <PrivateRoutes path="/approved" component={Approved} />}

      {isLoggedIn && (
        <PrivateRoutes path="/web-order" component={EnableWebOrdering} />
      )}
      {isLoggedIn && (
        <PrivateRoutes path="/nash-order" component={NashOrder} />
      )}
      {isLoggedIn && (
        <PrivateRoutes path="/nash" component={Nash} />
      )}
         {isLoggedIn && (
        <PrivateRoutes path="/nashHome" component={nashHome} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/epos-now" component={EposNow} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/epos-activate" component={EposActivate} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/web-order-accept"
          component={EnableWebOrderingAccept}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/inventory-accept"
          component={InventorygAccept}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/screen-saver-terms-conditions"
          component={ScreenSaverTerms}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/screensaver-pay" component={ScreenSaverPay} />
      )}

       {isLoggedIn && (
        <PrivateRoutes path="/enable-inventory" component={EnableInventory} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/pay-by-link-transactions"
          component={PayByLinkTranasctionDetails}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/pay-by-link-transaction-details"
          component={PayByLinkTranasctions}
        />
      )}

      {isLoggedIn && <PrivateRoutes path="/receipt" component={Receipt} />}

      {isLoggedIn && <PrivateRoutes path="/products" component={Product} />}

      {isLoggedIn && <PrivateRoutes path="/shop" component={Shop} />}

      {isLoggedIn && (
        <PrivateRoutes path="/shop-details" component={ShopItemDetails} />
      )}

      {isLoggedIn && <PrivateRoutes path="/cart" component={Cart} />}

      {isLoggedIn && (
        <PrivateRoutes path="/splitpayment" component={SplitPayment} />
      )}

      {isLoggedIn && <PrivateRoutes path="/billing" component={index} />}

      {isLoggedIn && (
        <PrivateRoutes path="/transactions" component={Transaction} />
      )}

      {isLoggedIn && <PrivateRoutes path="/sales" component={Item} />}

      {isLoggedIn && (
        <PrivateRoutes path="/non-selling-items" component={UnSold} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/top-selling-items" component={topSell} />
      )}

      {isLoggedIn && <PrivateRoutes path="/zreport" component={zReport} />}

      {isLoggedIn && (
        <PrivateRoutes path="/item-report" component={itemReport} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/weekly-business-report"
          component={WeeklyBusinessReport}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/plu-sales-report" component={PLUSalesReport} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/activity-report" component={ActivityReport} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/summary-report" component={SummaryReport} />
      )}

      {isLoggedIn && <PrivateRoutes path="/xreport" component={trending} />}

      {isLoggedIn && (
        <PrivateRoutes path="/payoutdetails" component={Payoutdetails} />
      )}
       {isLoggedIn && (
        <PrivateRoutes path="/payout" component={Payout} />
      )}
      {isLoggedIn && (
        <PrivateRoutes
          path="/transactiontdetails"
          component={TransactionDetails}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/orders-dine-in" component={DineinOrder} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/orders-takeaway" component={TakeAwayOrder} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/orders-delivery/:type" component={Orders} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/orderdetails" component={OrderDetails} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/donation-order" components={DonationOrder} />
      )}

      {isLoggedIn && <PrivateRoutes path="/disputes" component={Disputes} />}

      {isLoggedIn && (
        <PrivateRoutes path="/dispute-details" component={DisputeDetails} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/orders-delivery-to-park"
          component={DeliveryToPark}
        />
      )}

      {isLoggedIn && <PrivateRoutes path="/tax" component={Tax} />}

      {isLoggedIn && (
        <PrivateRoutes path="/notification" component={Notification} />
      )}

      {isLoggedIn && <PrivateRoutes path="/theme" component={Theme} />}

      {isLoggedIn && <PrivateRoutes path="/tip" component={Tip} />}

      {isLoggedIn && (
        <PrivateRoutes
          path="/notification-setting"
          component={NotificationSetting}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/user-profiles" component={Userprofile} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/add-product/:category_id"
          component={Addproduct}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/addproduct/:menu_id/:category_id/:is_buffet"
          component={Addproduct}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/editProduct/:menu_id/:category_id/:item_id/:is_buffet"
          component={Addproduct}
        />
      )}

      {isLoggedIn && <PrivateRoutes path="/menu" component={Menu} />}

      {/* <PrivateRoutes path="/menu-catalogue" component={Menu} /> */}

      {isLoggedIn && (
        <PrivateRoutes path="/menu-grid/:category_id" component={Menugrid} />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/menuGrid/:menu_id/:category_id"
          component={Menugrid}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes
          path="/menuGrid/:menu_id/:category_id"
          component={Menugrid}
        />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/menu-list/:menu_id" component={Menulist} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/promotions" component={Promotions} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/app-setting" component={AppSetting} />
      )}

      {isLoggedIn && <PrivateRoutes path="/app-timing" component={AppTiming} />}

      {isLoggedIn && (
        <PrivateRoutes
          path="/table-reservation-update-page"
          component={TableReservationUpdatePage}
        />
      )}
       {isLoggedIn && (
        <PrivateRoutes path="/inventory" component={Inventory} />
      )}

      {isLoggedIn && (
        <PrivateRoutes path="/inventory-reasons" component={InventoryReason} />
      )}

      {isLoggedIn && <PrivateRoutes path="/gal" component={Gallery} />}

      {isLoggedIn && <PrivateRoutes path="/reports/data-report" component={dataReport} />}

      {isLoggedIn && <PrivateRoutes path="/reports/dog-dollar-correction" component={dogDollarReport} />}

      {isLoggedIn && <PrivateRoutes path="/reports/total" component={totalReport} />}

      {isLoggedIn && <PrivateRoutes path="/reports/charlie" component={charlieReport} />}

      {isLoggedIn && <PrivateRoutes path="/reports/gross-sales-by-store" component={grossSalesStoreReport} />}

      {isLoggedIn && <PrivateRoutes path="/reports/payment-method" component={paymentMethodReport} />}

      {isLoggedIn && (<PrivateRoutes path="/delevirict-activate" component={DeliverictActivate} />)}

      {isLoggedIn && (
        <PrivateRoutes path="/delevirict-terms-and-conditions" component={DeliverictTerms} /> 
      )}

      {isLoggedIn && (<PrivateRoutes path="/floor-layout" component={FloorPlannerWrapper} />)}

      <PublicRoutes isLoggedIn={isLoggedIn} exact path="/" component={Login} />
      <PublicRoutes isLoggedIn={isLoggedIn} path="/login" component={Login} />
      <PublicRoutes path="/pbl-tnc" component={TermsAndContions} />
      <PublicRoutes path="/sign-up" component={SignUp} />
      <PublicRoutes path="/create-new-password" component={CreateNewPassword} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default withRouter(Routes);
