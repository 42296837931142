import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Typography, Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import AddIcon from '@mui/icons-material/Add'; // Import the AddIcon
import layout from "../../assets/img/layout/layout.png";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters'),
  capacity: Yup.number()
    .required('Capacity is required')
    .positive('Capacity should be a positive number')
    .integer('Capacity should be an integer'),
});


const ItemTypes = {
  SHAPE: 'shape',
  TABLE: 'table',
};

const SidebarItem = ({ shape }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SHAPE,
    item: { shape },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  let shapeStyle = {};

  switch (shape) {
    case 'Circle':
      shapeStyle = {
        borderRadius: '50%',
        aspectRatio: '1 / 1',
      };
      break;
    case 'Square':
      shapeStyle = {
        aspectRatio: '1 / 1',
      };
      break;
    case 'Rectangle':
      shapeStyle = {
        aspectRatio: '2 / 1',
      };
      break;
    default:
      break;
  }

  return (
    <div
      ref={drag}
      style={{
        ...shapeStyle,
        width: '100%',
        opacity: isDragging ? 0.5 : 1,
        margin: '10px 0',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        cursor: 'move',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
      }}
    >   {/* {shape} */}
    </div>
  );
};

const DraggableTable = ({ table, index, floorIndex, onEditTable, onDeleteTable, moveTable }) => {
   const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TABLE,
    item: { table, index, floorIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        const dropResult = monitor.getDropResult();
        if (dropResult) {
          moveTable(item, dropResult);
        }
      }
    },
  }), [table, index, floorIndex, moveTable]);

  return (
    <div
      ref={drag}
      style={{
        ...getTableStyle(table.shape),
        border: '1px solid #999',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: table.position?.top || 0,
        left: table.position?.left || 0,
        margin: '0',
        padding: '10px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
        textAlign: 'center',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <div style={{ marginBottom: '4px' }}>{table.name} (Guests: {table.capacity})</div>
      <div style={{ position: 'absolute', bottom: '4px', display: 'flex', gap: '4px' }}>
        <IconButton onClick={() => onEditTable(floorIndex, index)} size="small">
          <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton onClick={() => onDeleteTable(floorIndex, index)} size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
};



const Dropzone = ({ floor, onDrop, index, onEditFloor, onDropTable, onDeleteFloor, onEditTable, onDeleteTable, moveTable }) => {
  const drop = React.useRef(null);

  const [, dropRef] = useDrop(() => ({
    accept: [ItemTypes.SHAPE, ItemTypes.TABLE],
    drop: (item, monitor) => {
      if (item.shape) {
        onDrop(item, index);
      } else {
        const clientOffset = monitor.getClientOffset();
        if (clientOffset && drop.current) {
          const dropzoneRect = drop.current.getBoundingClientRect();
          const top = clientOffset.y - dropzoneRect.top;
          const left = clientOffset.x - dropzoneRect.left;
          console.log('Drop coordinates:', { top, left });
          
          // Return the drop result with the coordinates
          return { top, left };
        }
      }
    },
  }));

  return (
    <div
      ref={(node) => {
        drop.current = node;
        dropRef(node);
      }}
      style={{
        padding: '10px',
        margin: '20px 0',
        border: '1px solid #000',
        minHeight: '250px',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        resize: 'both',
        overflow: 'auto',
      }}
    >
      <h4 style={{ margin: '0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>{floor.name} (Guests: {floor.capacity})</span>
        <div>
          <IconButton onClick={() => onEditFloor(index)} size="small" style={{ marginRight: '4px' }}>
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton onClick={() => onDeleteFloor(index)} size="small">
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </div>
      </h4>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'flex-start',
          minHeight: '150px',
          position: 'relative',
        }}
      >
        {floor.tables.map((table, i) => (
          <DraggableTable
            key={i}
            table={table}
            index={i}
            floorIndex={index}
            onEditTable={onEditTable}
            onDeleteTable={onDeleteTable}
            moveTable={moveTable}
          />
        ))}
      </div>
      {floor.tables.length === 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: '#888',
          }}
        >
          <div
            style={{
              fontSize: '60px',
              marginBottom: '8px',
            }}
          >
            <OpenWithIcon style={{ fontSize: 'inherit' }} />
          </div>
          <div>
            Drag and drop the tables into this area.
          </div>
        </div>
      )}
    </div>
  );
};






// Utility function to get table style based on shape
const getTableStyle = (shape) => {
  const commonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  };

  switch (shape) {
    case 'Circle':
      return {
        ...commonStyle,
        borderRadius: '50%',
        width: '150px',
        height: '150px',
        backgroundColor: '#e0e0e0',
      };
    case 'Square':
      return {
        ...commonStyle,
        width: '150px',
        height: '150px',
        backgroundColor: '#e0e0e0',
      };
    case 'Rectangle':
      return {
        ...commonStyle,
        width: '250px',
        height: '150px',
        backgroundColor: '#e0e0e0',
      };
    default:
      return commonStyle;
  }
};

const MAX_HISTORY_SIZE = 20;

const FloorPlanner = () => {
  const [layoutVisible, setLayoutVisible] = useState(false);
  const [floors, setFloors] = useState([]);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ name: '', capacity: '', shape: '', type: '', floorIndex: null, tableIndex: null });

  useEffect(() => {
    console.log('Saving floors to localStorage:', floors);
    localStorage.setItem('floors', JSON.stringify(floors));
  }, [floors]);

  useEffect(() => {
    console.log('Saving layoutVisible to localStorage:', layoutVisible);
    localStorage.setItem('layoutVisible', JSON.stringify(layoutVisible));
  }, [layoutVisible]);

  useEffect(() => {
    const savedFloors = localStorage.getItem('floors');
    const savedLayoutVisible = localStorage.getItem('layoutVisible');
    console.log('Loaded floors from localStorage:', savedFloors);
    console.log('Loaded layoutVisible from localStorage:', savedLayoutVisible);

    if (savedFloors) {
      setFloors(JSON.parse(savedFloors));
    }

    if (savedLayoutVisible) {
      setLayoutVisible(JSON.parse(savedLayoutVisible));
    }
  }, []);

  const addActionToHistory = (action) => {
    setHistory((prevHistory) => {
      const newHistory = [...prevHistory, action];
      return newHistory.slice(-MAX_HISTORY_SIZE);
    });
    setRedoStack([]); // Clear redo stack on new action
  };

  const handleDialogSubmit = (values) => {
    const { name, capacity, shape, type, floorIndex, tableIndex } = values;

    if (type === 'floor') {
      if (floorIndex !== null) {
        // Update existing floor
        const updatedFloors = [...floors];
        const oldFloor = updatedFloors[floorIndex];
        updatedFloors[floorIndex] = { ...updatedFloors[floorIndex], name, capacity };
        addActionToHistory({
          type: 'UPDATE_FLOOR',
          data: { floorIndex, oldFloor, newFloor: updatedFloors[floorIndex] },
        });
        setFloors(updatedFloors);
      } else {
        // Add new floor
        const newFloor = { name, capacity, tables: [] };
        addActionToHistory({
          type: 'ADD_FLOOR',
          data: { newFloor }
        });
        setFloors([...floors, newFloor]);
      }
    } else if (type === 'table' && floorIndex !== null) {
      const newTable = { name, capacity, shape };
      const updatedFloors = [...floors];
      const oldTable = updatedFloors[floorIndex].tables[tableIndex] || null;
      if (tableIndex !== null) {
        updatedFloors[floorIndex].tables[tableIndex] = newTable;
      } else {
        updatedFloors[floorIndex].tables.push(newTable);
      }
      addActionToHistory({
        type: oldTable ? 'UPDATE_TABLE' : 'ADD_TABLE',
        data: { floorIndex, tableIndex, oldTable, newTable }
      });
      setFloors(updatedFloors);
    }

    handleDialogClose();
  };



 const handleUndo = () => {
    if (history.length > 0) {
      const lastAction = history[history.length - 1];
      const newHistory = history.slice(0, -1);
      setRedoStack([lastAction, ...redoStack]);

      switch (lastAction.type) {
        case 'ADD_FLOOR':
          setFloors((prev) => prev.slice(0, -1));
          break;
        case 'UPDATE_FLOOR':
          setFloors((prev) => {
            const updated = [...prev];
            updated[lastAction.data.floorIndex] = lastAction.data.oldFloor;
            return updated;
          });
          break;
        case 'DELETE_FLOOR':
          setFloors((prev) => {
            const updated = [...prev];
            updated.splice(lastAction.data.index, 0, lastAction.data.deletedFloor);
            return updated;
          });
          break;
        case 'ADD_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[lastAction.data.floorIndex].tables.pop();
            return updated;
          });
          break;
        case 'UPDATE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[lastAction.data.floorIndex].tables[lastAction.data.tableIndex] = lastAction.data.oldTable;
            return updated;
          });
          break;
        case 'DELETE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[lastAction.data.floorIndex].tables.splice(lastAction.data.index, 0, lastAction.data.table);
            return updated;
          });
          break;
        case 'MOVE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[lastAction.data.floorIndex].tables[lastAction.data.tableIndex] = lastAction.data.oldTable;
            return updated;
          });
          break;
        default:
          break;
      }

      setHistory(newHistory);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const redoAction = redoStack[0];
      const newRedoStack = redoStack.slice(1);
      setHistory([...history, redoAction]);

      switch (redoAction.type) {
        case 'ADD_FLOOR':
          setFloors([...floors, redoAction.data.newFloor]);
          break;
        case 'UPDATE_FLOOR':
          setFloors((prev) => {
            const updated = [...prev];
            updated[redoAction.data.floorIndex] = redoAction.data.newFloor;
            return updated;
          });
          break;
        case 'DELETE_FLOOR':
          setFloors((prev) => {
            const updated = [...prev];
            updated.splice(redoAction.data.index, 1);
            return updated;
          });
          break;
        case 'ADD_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[redoAction.data.floorIndex].tables.push(redoAction.data.newTable);
            return updated;
          });
          break;
        case 'UPDATE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[redoAction.data.floorIndex].tables[redoAction.data.tableIndex] = redoAction.data.newTable;
            return updated;
          });
          break;
        case 'DELETE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[redoAction.data.floorIndex].tables.splice(redoAction.data.index, 1);
            return updated;
          });
          break;
        case 'MOVE_TABLE':
          setFloors((prev) => {
            const updated = [...prev];
            updated[redoAction.data.floorIndex].tables[redoAction.data.tableIndex] = redoAction.data.newTable;
            return updated;
          });
          break;
        default:
          break;
      }

      setRedoStack(newRedoStack);
    }
  };


  const canUndo = history.length > 0;
  const canRedo = redoStack.length > 0;

  const handleDialogOpen = (type, floorIndex = null, tableIndex = null, shape = '') => {
    const selectedFloor = floorIndex !== null ? floors[floorIndex] : null;
    const selectedTable = tableIndex !== null ? selectedFloor?.tables[tableIndex] : null;

    if (type === "table") {
      setDialogData({
        name: selectedTable?.name || '',
        capacity: selectedTable?.capacity || '',
        shape: selectedTable?.shape || shape,
        type,
        floorIndex,
        tableIndex,
      });
    } else if (type === "floor") {
      setDialogData({
        name: selectedFloor?.name || '',
        capacity: selectedFloor?.capacity || '',
        shape: selectedFloor?.shape || shape,
        type,
        floorIndex,
        tableIndex,
      });
    }

    setDialogOpen(true);
  };



  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleShapeDrop = (item, floorIndex) => {
    handleDialogOpen('table', floorIndex, null, item.shape);
  };

  const handleTableDrop = (item, dropResult) => {
    const { table, index, floorIndex } = item;
    const updatedFloors = [...floors];
  
    // Get the new position
    const { top, left } = dropResult;

    console.log("dropResult " +  dropResult);
  
    console.log('Dropping table:', item, 'to position:', { top, left });
    const oldTable = updatedFloors[floorIndex].tables[index];
    // Update the position of the table in the corresponding floor
    updatedFloors[floorIndex].tables[index] = {
      ...table,
      position: { top, left },
    };
  
    // Update state with the new positions
    setFloors(updatedFloors);
    
    // Add to history for undo/redo
    addActionToHistory({
      type: 'MOVE_TABLE',
      data: { floorIndex, tableIndex: index, oldTable, newTable: updatedFloors[floorIndex].tables[index] }
    });
   // setRedoStack([]);
  };
  

  const handleEditFloor = (index) => {
    handleDialogOpen('floor', index);
  };

  const handleDeleteFloor = (index) => {
    const newFloors = [...floors];
    const deletedFloor = newFloors.splice(index, 1)[0];
    addActionToHistory({
      type: 'DELETE_FLOOR',
      data: deletedFloor,
      index
    });
    setFloors(newFloors);
  };

  const handleEditTable = (floorIndex, tableIndex) => {
    handleDialogOpen('table', floorIndex, tableIndex);
  };

  const handleDeleteTable = (floorIndex, tableIndex) => {
    const newFloors = [...floors];
    const deletedTable = newFloors[floorIndex].tables.splice(tableIndex, 1)[0];
    addActionToHistory({
      type: 'DELETE_TABLE',
      data: { floorIndex, table: deletedTable },
      index: tableIndex
    });
    setFloors(newFloors);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {!layoutVisible && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={layout} alt="Your Image" style={{ marginBottom: '20px', width: '100px' }} /> {/* Adjust the src and styling as needed */}
              <Typography variant="h6" style={{ marginBottom: '20px', textAlign: 'center' }}>
                Currently, there is no existing layout.
              </Typography>
              <Button
                variant="contained"
                onClick={() => setLayoutVisible(true)}
                sx={{
                  '& .MuiButton-startIcon': {
                    color: 'white', // Icon color
                  },
                }}
                startIcon={<AddIcon />} // Add the icon here
              >
                Add Floor Layout
              </Button>
            </div>
          </div>

        )}
        {layoutVisible && (
          <div style={{ display: 'flex', padding: '20px', width: '100%' }}>
            {/* Sidebar */}
            <div style={{ width: '200px', padding: '10px', border: '1px solid #ccc', display: 'flex', flexDirection: 'column' }}>
              <Button
                fullWidth
                onClick={() => handleDialogOpen('floor')}
                sx={{
                  mt: 2, // marginTop: '10px'
                  backgroundColor: '#5773ff', // Primary button color
                  color: '#fff', // Text color
                  borderRadius: 1, // Rounded corners
                  '&:hover': {
                    backgroundColor: '#5773ff', // Darker shade on hover
                  },
                }}
              >
                Add Floor
              </Button>

              {['Circle', 'Square', 'Rectangle'].map((shape) => (
                <SidebarItem key={shape} shape={shape} />
              ))}


              <Button
                fullWidth
                onClick={handleUndo}
                style={{ marginTop: '10px' }}
                disabled={!canUndo}
                sx={{
                  '& .MuiButton-startIcon': {
                    color: 'white', // Icon color
                  },
                }}
                variant="contained"
                startIcon={<UndoIcon />}
              >
                Undo
              </Button>

              <Button
                fullWidth
                onClick={handleRedo}
                style={{ marginTop: '10px' }}
                disabled={!canRedo}
                sx={{
                  '& .MuiButton-startIcon': {
                    color: 'white', // Icon color
                  },
                }}
                variant="contained"
                startIcon={<RedoIcon />}
              >
                Redo
              </Button>

              <Button
                fullWidth
                onClick={() => setFloors([])}
                sx={{
                  mt: 2, // marginTop: '10px'
                  color: 'red', // Text color
                  borderColor: 'red', // Border color if needed
                  '&:hover': {
                    borderColor: 'darkred', // Change border color on hover
                  },
                  '& .MuiButton-startIcon': {
                    color: 'red', // Icon color
                  },
                }}
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>


            </div>



            {/* Dropzone */}
            <div style={{ flex: 1, padding: '10px', border: '1px solid #ccc' }}>
              {floors.map((floor, index) => (
                <Dropzone
                  key={index}
                  floor={floor}
                  onDrop={handleShapeDrop}
                  onDropTable={handleTableDrop}
                  index={index}
                  onEditFloor={handleEditFloor}
                  onDeleteFloor={handleDeleteFloor}
                  onEditTable={handleEditTable}
                  onDeleteTable={handleDeleteTable}
                  moveTable={handleTableDrop} // Handle moveTable if needed
                />
              ))}
              {floors.length === 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: '#888',
                  }}
                >
                  <div
                    style={{
                      fontSize: '60px', // Adjust size as needed
                      marginBottom: '8px',
                    }}
                  >
                    <OpenWithIcon style={{ fontSize: 'inherit' }} />
                  </div>
                  <div>
                    Drag and drop the tables into this area to create your floor layout.
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Dialog for Floor/Table */}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} size="md">
        <DialogTitle>{dialogData.type === 'floor' ? 'Add/Edit Floor' : 'Add/Edit Table'}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={dialogData}
            validationSchema={validationSchema}
            onSubmit={handleDialogSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="name" as={TextField} label="Name" fullWidth margin="normal"
                  helperText={<ErrorMessage name="name" component="div"  style={{ color: 'red' }} />} />
                
                <Field name="capacity" as={TextField} label="Capacity" type="number" fullWidth margin="normal"
                  helperText={<ErrorMessage name="capacity" component="div"  style={{ color: 'red' }} />} />
                 

                {/* Additional fields for shape and type if needed */}
                
                <DialogActions>
                  <Button onClick={() => setDialogOpen(false)} color="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
       
        {layoutVisible && floors.length > 0 && (
        <Box
        sx={{
          
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setLayoutVisible(false)}
              sx={{
                borderRadius: 0,
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 0,
              }}
            >
              Save Layout
            </Button>
          </Grid>
        </Grid>
      </Box>
        )}



      </div>
    </DndProvider>
  );
};

export default FloorPlanner;
