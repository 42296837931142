import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableFooter, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Button } from "@mui/material";
import {getCurrencySymbol} from "../../config";
const PricingCard = ({
  plan_name,
  qr_plan_id,
  qr_code_number,
  qr_code_number_annually,
  users,
  isAnnually,
  monthly_charges,
  annual_charges,
  propertyId,
  activePlan,
  qr_fee,
  qr_description,
  buttonText,
}) => {
  const history = useHistory();

  const handleCardClick = () => {
    if (
      activePlan?.qr_plan_id !== qr_plan_id ||
      (activePlan?.subscription_status === "EXPIRED")
    ) {
      if (monthly_charges == 0 && annual_charges == 0) {
        let payload = {
          property_id: propertyId,
          plan_id: qr_plan_id,
        };
        selectPlan(payload);
      } else {
        let payload = {
          property_id: propertyId,
          type: isAnnually ? "ANNUAL" : "MONTHLY",
          plan_id: qr_plan_id,
        };
        selectPlan(payload);
      }
    } else {
    }
  };

  const selectPlan = async (payload) => {
    if (monthly_charges == 0 && annual_charges == 0) {
      try {
        const response = await PlanServiceAPI.selectPlan(payload);

        if (response.data.status) {
          if (monthly_charges == 0 && annual_charges == 0) {
            history.push({
              pathname: "/manage-qr-flow",
            });
          }
        } else {
        }
      } catch (error) {
        throw error;
      }
    } else {
      setTimeout(() => {
        let charges = isAnnually ? annual_charges : monthly_charges;
        history.push({
          pathname: "/pay",
          state: {
            order: { plan_name, charges, qr_code_number, users },
            payload: payload,
          },
        });
      }, 500);
    }

    /*         try {
                    const response = await PlanServiceAPI.selectPlan(payload);
        
                    if (response.data.status) {
                        if (monthly_charges == 0 && annual_charges == 0) {
                            history.push({
                                pathname: "/manage-qr-flow",
                            });
                        } else if (response.data.data) {
                            let charges = isAnnually ? annual_charges : monthly_charges;
                            history.push({
                                pathname: "/pay",
                                state: {
                                    session: response.data.data,
                                    order: { plan_name, charges, qr_code_number },
                                },
                            });
                        }
                    } else {
                    }
                } catch (error) {
                    throw error;
                } */
  };

  return (
    <React.Fragment>
      <Link to="#" onClick={handleCardClick}>
        <Card
          sx={{
            position: "relative",
            border:
              activePlan?.qr_plan_id === qr_plan_id
                ? "2px solid #5773ff"
                : "2px solid transparent",
            "&:hover": {
              border: "2px solid #5773ff",
            },
            borderRadius: "16px",
            height: "700px",
            mb: 5,
          }}
        >
          {plan_name === "Advanced" && (
            <Box
              style={{
                position: "absolute",
                background: "#5773ff",
                color: "white",
                padding: "3px",
                borderRadius: "3px",
                right: 0,
              }}
            >
              Most Popular
            </Box>
          )}
          <CardContent>
            <Typography
              sx={{ fontWeight: 600, mb: 3 }}
              variant="h5"
              component="div"
            >
              {plan_name}
            </Typography>
            <Typography
              sx={{ fontWeight: 600 }}
              variant="h4"
              color="text.primary"
            >
             {isAnnually ? getCurrencySymbol(localStorage.getItem('currency_code')) + annual_charges :  getCurrencySymbol(localStorage.getItem('currency_code'))+ monthly_charges}
              <Box
                sx={{
                  color: "text.secondary",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
                component="span"
              >
                /{isAnnually ? "Annually" : "Monthly"}
              </Box>
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {isAnnually ? "Billed Annually" : "Billed Monthly"}
            </Typography>
            <Grid container spacing={2} alignItems="center" sx={{ my: 1 }}>
              <Grid
                item
                xs={
                  12
                } /* style={{visibility: activePlan?.qr_plan_id === qr_plan_id ? "hidden" : "visible"}} */
              >
                <Button
                  variant={activePlan?.qr_plan_id === qr_plan_id ? "contained" : "outlined"}
                  color="primary"
                 /*  disabled={activePlan?.qr_plan_id === qr_plan_id ||
                    activePlan?.qr_remaining == "0" ? true : false} */
                  disabled={
                    (activePlan?.qr_plan_id === qr_plan_id && (activePlan?.subscription_status !== "EXPIRED"))
                  }
                  style={{ marginTop: "20px", marginBottom: "20px", borderRadius: "20px", width: '170px' }}
                  onMouseOver={(e) => {e.currentTarget.style.backgroundColor = 'rgb(87, 115, 255)'; e.currentTarget.style.color = 'white' }} // Change background color on hover
                  onMouseOut={(e) => {e.currentTarget.style.backgroundColor =  'white' ;  e.currentTarget.style.color = 'rgb(87, 115, 255)' }} // Reset background color on mouse out
                >
                  {activePlan.subscription_status==="EXPIRED"&& activePlan?.qr_plan_id === qr_plan_id?plan_name="Renew":plan_name==="Starter Plan Free"?"Activate":buttonText}
                  {/* {plan_name==="Starter Plan Free"?"Activate":buttonText} */}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    background: "#ededed",
                    padding: "5px",
                    borderRadius: "8px",
                    fontWeight: 500,
                  }}
                >
                  {isAnnually ? qr_code_number_annually : qr_code_number}

                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  color="text.primary"
                >
                  Dynamic QR Codes
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    background: "#ededed",
                    padding: "5px",
                    borderRadius: "8px",
                    fontWeight: 500,
                  }}
                >
                  {users}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  color="text.primary"
                >
                  Users
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title="Infinite">
                  <Typography
                    sx={{
                      background: "#ededed",
                      padding: "5px",
                      borderRadius: "8px",
                      fontWeight: 500,
                    }}
                  >
                    <b> ∞</b>
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  color="text.primary"
                >
                  Unlimited scans
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} md={12} sx={{ paddingLeft: "0px !important" }}>
                <TableContainer fullWidth component={Paper} style={{paddingLeft: "0px !important", background: "#f0f0fa", width: "103%", position: "relative", /* margin-left: 16px; */ }}>
                  <Table aria-label="simple table" size="small" dense style={{ paddingLeft: "0px  !important" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" colSpan={6}>
                         <b> Pricing</b>
                        </TableCell>

                      </TableRow>
                      <TableRow >
                        <TableCell align="center" >Local</TableCell>
                        <TableCell align="center" > {qr_fee?.local_card_fee} %  &nbsp; + &nbsp; {qr_fee?.additional_merchant_fee || Number(0).toFixed(2)} p</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell align="center" >International </TableCell>
                        <TableCell align="center" > {qr_fee?.international_commercial_card_fee} % &nbsp; + &nbsp;  {qr_fee?.additional_merchant_fee || Number(0).toFixed(2)} p</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell align="center" >Other </TableCell>
                        <TableCell align="center" > {qr_fee?.other_card_fee} % &nbsp; + &nbsp; {qr_fee?.additional_merchant_fee || Number(0).toFixed(2)} p</TableCell>
                      </TableRow>
                    </TableBody> </Table> </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  color="text.secondary"
                >
                  <i>
                    {qr_description
                      ? qr_description
                      : "Suitable for small businesses who want to try QR Codes."}{" "}
                  </i>
                </Typography>
              </Grid>

              {/*  <Grid item xs={12}>
                                <Typography
                                    sx={{ textAlign: "left", fontWeight: 500 }}
                                    variant="body2"
                                    color="text.primary"
                                >
                                    <i>Industries supported - Hotels, Hospitality Bars, Clubs. </i>
                                </Typography>
                            </Grid> */}
            </Grid>
            <Divider />
            <Typography
              component="p"
              sx={{
                fontWeight: 500,
                mt: "10px",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {plan_name === "Professional" || plan_name === "Advanced"
                ? "Premium Support"
                : "Limited Support"}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </React.Fragment>
  );
};

export default PricingCard;
