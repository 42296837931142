import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import Content from "./Content";
import "react-image-crop/dist/ReactCrop.css";
import default_img from "../../assets/img/upload_image_2.jpg";
import { Alert } from "@mui/material";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
class index extends Component {
  state = {
    property_name: "",
    property_trading_name: "",
    property_country_code: "",
    business_type: "",
    property_address: "",
    property_city: "",
    contact_name: "",
    contact_number: "",
    property_country: "",
    loading: false,
    postal_code: "",
    logo_image: "",
    logo_preview_url: "",
    dine_image: "",
    dine_preview_url: "",
    takeaway_image: "",
    takeaway_preview_url: "",
    collection_image: "",
    collection_preview_url: "",
    cover_images: [],
    cover_preview_url: null,
    file: null,
    dine: null,
    takeaway: null,
    collection: null,
    business_types: [],
    files: [null],
    main_image: null,
    cost_estimation: "100",
    parking_details: "",
    applicationFeeType: "",
    hasCoverImage: false,
    no_of_people: "2",
    rating: "",
    property_status: "",
    is_take_away_active: false,
    is_dine_in_active: false,
    is_collection_active: false,
    is_table_reservation_active: false,
    is_business_type_not_editable: false,
    is_delivery_to_park: false,
    is_delivery: false,
    property: JSON.parse(localStorage.getItem("property_details")),
    excerpt: "",
    description: "",
    url: "",
    show: false,
    open: false,
    crop: {
      x: 130,
      y: 50,
      width: 200,
      height: 200,
      aspect: 16 / 9,
    },
    phoneNoWithoutDialCode: {}
  };

  fileArray = [];
  fileObj = null;

  componentDidMount = () => {
    const username = localStorage.getItem("username");
    this.setState({ username: username });
    SecurityServiceAPI.fetchBusinessType().then((response) => {
      if (response.data.status) {
        const keysArray = Object.keys(response.data.data.business_type_map);
        this.setState({ business_types: keysArray });
      }
    });
    this.fetchBusinessPlace(username);
  };

  fetchBusinessPlace = (username) => {
    SecurityServiceAPI.fetchBusinessPlace(username).then((response) => {
      if (response.data.status) {
        const property_summary_details =
          response.data.data.property_details[0].property_summary_details;
        if (property_summary_details) {
          this.setState({
            cost_estimation: property_summary_details.cost_estimation,
            parking_details: property_summary_details.parking_details,
            no_of_people: property_summary_details.number_of_person,
          });
        }
        if (response.data.data.property_details[0].business_type) {
          this.setState({ is_business_type_not_editable: true });
        }
        this.setState({
          property_name: response.data.data.property_details[0].property_name,
          property_trading_name:
            response.data.data.property_details[0].property_trading_name,
          business_type: response.data.data.property_details[0].business_type,
          property_address:
            response.data.data.property_details[0].property_address,
          property_country: response.data.data.property_details[0].country,
          property_country_code:
            response.data.data.property_details[0].country_code,
          currency_code: response.data.data.property_details[0].currency_code,
          property_city: response.data.data.property_details[0].city,
          contact_name: response.data.data.property_details[0].contact_name,
          contact_number: response.data.data.property_details[0].contact_number,
          rating: response.data.data.property_details[0].rating,
          postal_code: response.data.data.property_details[0].postal_code,
          property_status:
            response.data.data.property_details[0].property_status,
          logo_image: response.data.data.property_details[0].logo_image,
          dine_image: response.data.data.property_details[0].dine_in_image,
          takeaway_image:
            response.data.data.property_details[0].take_away_image,
          collection_image:  response.data.data.property_details[0].collection_image,
          applicationFeeType:
            response.data.data.property_details[0].application_fee_value,
          cover_images: response.data.data.property_details[0].cover_images,
          is_take_away_active:
            response.data.data.property_details[0].is_take_away_active,
          is_dine_in_active:
            response.data.data.property_details[0].is_dine_in_active,
          is_collection_active:
            response.data.data.property_details[0].is_collection_active,
          is_table_reservation_active:
            response.data.data.property_details[0].is_table_reservation_active,
          excerpt: response.data.data.property_details[0].excerpt,
          description: response.data.data.property_details[0].description,
          is_delivery_to_park:
            response.data.data.property_details[0].is_delivery_to_park,
          is_delivery: response.data.data.property_details[0].is_delivery
        });
      }
    });
  };

  onChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  toggleHandler = (current) => {
    this.setState({
      [current]: !this.state[current],
    });
  };

/*   handleOnChange = (value) => {
    this.setState({
      contact_number: "+" + value,
    });
  }; */

  handleOnChange = (phoneNumber, country, e, formattedValue) => {
    let dialCode = "+" + country.dialCode;
    let formatted_value = formattedValue;
    let phone = formatted_value.replace(dialCode, "");
    console.log(phone)
    console.log( phoneNumber.replace(country.dialCode, ""))
    this.setState({
      contact_number:    phoneNumber ,
      phoneNoWithoutDialCode: phoneNumber.replace(country.dialCode, "")
    });
  };

  handleLogoImageChange = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "logo") {
      console.log(file);
      console.log(imageArray[0]);
      this.setState({
        file: file[0],
        logo_preview_url: imageArray[0],
        logo_image: null,
        show: true,
      });

      console.log(this.state.logo_preview_url);
    } else {
      console.log("No cropper key returned!");
    }
  };

  handleDineImageChange = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "dine") {
      console.log(file);
      console.log(imageArray[0]);
      this.setState({
        dine: file[0],
        dine_preview_url: imageArray[0],
        dine_image: null,
        show: true,
      });

      console.log(this.state.dine_preview_url);
    } else {
      console.log("No cropper key returned!");
    }
  };

  handleTakeAwayImageChange = (
    croppedImagesFormData,
    key,
    imageArray,
    file
  ) => {
    if (key === "takeaway") {
      console.log(file);
      console.log(imageArray[0]);
      this.setState({
        takeaway: file[0],
        takeaway_preview_url: imageArray[0],
        takeaway_image: null,
        show: true,
      });

      console.log(this.state.dine_preview_url);
    } else {
      console.log("No cropper key returned!");
    }
  };
  handleCollectionImageChange = (
    croppedImagesFormData,
    key,
    imageArray,
    file
  ) => {
    if (key === "collection") {
      console.log(file);
      console.log(imageArray[0]);
      this.setState({
        collection: file[0],
        collection_preview_url: imageArray[0],
        collection_image: null,
        show: true,
      });

      console.log(this.state.dine_preview_url);
    } else {
      console.log("No cropper key returned!");
    }
  };

  handleMultipleImageChange = (
    croppedImagesFormData,
    key,
    imageArray,
    files
  ) => {
    this.fileObj = [];
    if (key === "cover") {
      //this.fileArray.push(files);
      this.fileObj.push(...imageArray);

      console.log(files);
      console.log(imageArray);

      console.log(this.fileArray);
      console.log(this.fileObj);

      files.forEach((file) => {
        // Perform operations with the filtered files
        console.log(file); // Accessing file properties (e.g., name)
        this.fileArray.push(file);
      });

      this.setState({
        files: this.fileObj,
        hasCoverImage: true,
      });
    } else {
      console.log("No cropper key returned!");
    }
  };
  //handleMultipleImageChange = e => {
  //     e.preventDefault();
  //     this.fileObj = []

  //     // FileList to Array
  //     const files = Array.from(e.target.files);
  //     files.forEach((file, i) => {
  //         this.fileArray.push(file)
  //         const multipleImageReader = new FileReader();

  //         multipleImageReader.onloadend = () => {
  //             this.fileObj.push(multipleImageReader.result)
  //             this.setState({
  //                 files: file,
  //                 hasCoverImage: true
  //             });
  //         };

  //         multipleImageReader.readAsDataURL(file);
  //     });
  // }

  onSubmitHandler = (event) => {
    this.setState({ loading: true });
    const property = JSON.parse(localStorage.getItem("property_details"));
    if (!this.props.propertyId) {
      toast.warn("Please complete your account set up. ");
      this.setState({ loading: false });
      return;
    }
    const property_summary_details = {
      parking_details: this.state.parking_details,
      cost_estimation: this.state.cost_estimation,
      number_of_person: this.state.no_of_people,
    };
    const businessPlaceForm = {
      property_name: this.state.property_name,
      property_trading_name: this.state.property_trading_name,
      property_country_code: this.state.property_country_code,
      business_type: this.state.business_type,
      property_address: this.state.property_address,
      property_country: this.state.property_country,
      currency_code: this.state.currency_code,
      property_city: this.state.property_city,
      contact_name: this.state.contact_name,
      contact_number: this.state.contact_number,
      postal_code: this.state.postal_code.replace(/ /g, ""),
      property_rating: this.state.rating,
      property_id: this.props.propertyId,
      username: this.state.username,
      property_summary_details: property_summary_details,
      property_status: this.state.property_status,
      is_take_away_active: this.state.is_take_away_active,
      is_dine_in_active: this.state.is_dine_in_active,
      is_collection_active: this.state.is_collection_active,
      is_table_reservation_active: this.state.is_table_reservation_active,
      excerpt: this.state.excerpt,
      description: this.state.description,
      is_delivery_to_park: this.state.is_delivery_to_park,
      is_delivery: this.state.is_delivery
    };
    let formData = new FormData();

    if (this.state.file == null) {
      formData.append("property_details ", JSON.stringify(businessPlaceForm));
    } else {
      formData.append("logo_image", this.state.file);
      formData.append("property_details ", JSON.stringify(businessPlaceForm));
    }
    if (this.state.hasCoverImage) {
      if (this.fileArray[0]) {
        formData.append("cover_image", this.fileArray[0]);
      }
    }
    if (this.state.dine !== null) {
      formData.append("dine_in_image", this.state.dine);
    }
    if (this.state.takeaway !== null) {
      formData.append("take_away_image", this.state.takeaway);
    }
    if (this.state.collection !== null) {
      formData.append("collection_image", this.state.collection);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    SecurityServiceAPI.updateBusinessPlace(formData)
      .then((response) => {
        if (response.data.status) {
          // this.fetchBusinessPlace(this.state.username)
          const property_summary_details =
            response.data.data.property_details[0].property_summary_details;
          if (property_summary_details) {
            this.setState({
              cost_estimation: property_summary_details.cost_estimation,
              parking_details: property_summary_details.parking_details,
              no_of_people: property_summary_details.number_of_person,
            });
          }
          if (response.data.data.property_details[0].business_type) {
            this.setState({ is_business_type_not_editable: true });
          }
          this.setState({
            property_name: response.data.data.property_details[0].property_name,
            property_trading_name:
              response.data.data.property_details[0].property_trading_name,
            business_type: response.data.data.property_details[0].business_type,
            property_address:
              response.data.data.property_details[0].property_address,
            property_country: response.data.data.property_details[0].country,
            property_country_code:
              response.data.data.property_details[0].country_code,
            currency_code: response.data.data.property_details[0].currency_code,
            property_city: response.data.data.property_details[0].city,
            contact_name: response.data.data.property_details[0].contact_name,
            contact_number:
              response.data.data.property_details[0].contact_number,
            rating: response.data.data.property_details[0].rating,
            postal_code: response.data.data.property_details[0].postal_code,
            property_status:
              response.data.data.property_details[0].property_status,
            logo_image: response.data.data.property_details[0].logo_image,
            applicationFeeType:
              response.data.data.property_details[0].application_fee_value,
            cover_images: response.data.data.property_details[0].cover_images,
            is_take_away_active:
              response.data.data.property_details[0].is_take_away_active,
            is_dine_in_active:
              response.data.data.property_details[0].is_dine_in_active,
            is_collection_active:
              response.data.data.property_details[0].is_collection_active,
            is_table_reservation_active:
              response.data.data.property_details[0]
                .is_table_reservation_active,
            excerpt: response.data.data.property_details[0].excerpt,
            description: response.data.data.property_details[0].description,
            is_delivery_to_park:
              response.data.data.property_details[0].is_delivery_to_park,
          });
          toast.success("Business info updated successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.setState({ loading: false });
        } else {
          toast.warn(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("Update business info ===> ", error);
        toast.error("Oops! Something went wrong");
        this.setState({
          loading: false,
        });
      });
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  handleOnCrop = (crop) => {
    this.setState({ crop });
  };

  onImageLoaded = (image) => {
    console.log("onCropComplete", image);
  };

  onCropComplete = (crop, pixelCrop) => {
    console.log("onCropComplete", crop, pixelCrop);
    const canvasRef = this.imagesrc.current;
  };

  handleDeleteImage = (url) => {
    this.setState({ open: true, url: url });
  };

  deleteImage = (url) => {
    console.log(this.fileObj);
    console.log(url);

    this.fileObj = this.fileObj && this.fileObj.filter((e) => e !== url);

    let coverImages = this.state.cover_images;
    coverImages = coverImages && coverImages.filter((e) => e !== url);
    this.fileArray.length = 0;
    this.setState({ cover_images: coverImages, open: false });

    if (url.startsWith("http")) {
      const payload = {
        property_id:
          this.props.propertyId || localStorage.getItem("property_id"),
        cover_image_url: url,
      };
      SecurityServiceAPI.deleteCoverImage(payload)
        .then((response) => {
          if (response.data.status) {
            toast.success("Image deleted. ");
            this.setState({ url: "" });
            window.location.reload();
          } else {
            toast.warn("Couldn't delte the image.  ");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, url: "" });
          toast.warn("Something went wrong. ");
        });
    }
  };

  handleClose = () => {
    this.setState({ open: false, url: "" });
  };

  render() {
    const { property } = this.state;
    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Image?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>No</Button>
            <Button onClick={() => this.deleteImage(this.state.url)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.propertyId ? (
          <Content
            state={this.state}
            handleLogoImageChange={this.handleLogoImageChange}
            handleDineImageChange={this.handleDineImageChange}
            handleTakeAwayImageChange={this.handleTakeAwayImageChange}
            handleMultipleImageChange={this.handleMultipleImageChange}
            toggleHandler={this.toggleHandler}
            fileArray={this.fileArray}
            fileObj={this.fileObj}
            handleOnChange={this.handleOnChange}
            handleModal={this.handleModal}
            onSubmitHandler={this.onSubmitHandler}
            onChangeHandler={this.onChangeHandler}
            propertyId={this.props.propertyId}
            handleDeleteImage={this.handleDeleteImage}
            handleCollectionImageChange={this.handleCollectionImageChange}
          />
        ) : (
          <Alert severity="warning">
            To access this page please fill the "Let's get you started" form on
            dashboard pop up.
          </Alert>
        )}

        {/* <SimpleModal
                    state={this.state}
                    extraLarge
                    handleClose={this.handleModal}
                    Heading="CROPPER">
                    <div>
                        <ReactCrop
                            crop={this.state.crop}
                            src={this.state.logo_preview_url}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.handleOnCrop}
                        />
                        <canvas ref={this.imagesrc}></canvas>
                    </div>
                </SimpleModal> */}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
