import React, { Component, useEffect, useState } from "react";
import { useStepperContext } from "../../contexts/UseContextProvider";

import { StepperContext } from "../../contexts/UseContextProvider";

import Typography from "@mui/material/Typography";

import { setAccountHoldId } from "../../../../../../redux/user/userSlice";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StepperControl from "../StepperControl";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";

import { Link } from "@mui/material";

import "../css/setup.css";

import * as SecurityServiceAPI from "../../../../../../services/user/SecurityServiceAPI";

import ReactSelect from "react-select";
import ReactPhoneInput from "react-phone-input-2";

import { FlapperSpinner } from "react-spinners-kit";
import countryList from "react-select-country-list";
import { propertySlice } from "../../../../../../redux/property";

import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";

export default function Payment(props) {
  const { userData, setUserData } = useStepperContext();

  const [checked, setChecked] = React.useState(true);

  const [accountHolderId, setAccountHolderId] = React.useState("");

  const [formErrors, setFormErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(true);

  const property = useSelector((state) => state.property.property);

  const stateLegalEntityId = useSelector((state) => state.user.legalEntityId);

  const stateAccountHolderId = useSelector(
    (state) => state.user.accountHolderId
  );

  const dispatch = useDispatch();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 56,
      minHeight: 56,
    }),
  };

  useEffect(() => {
    console.log(props.initialValues.adyen?.adyen_info?.account_holder_id);
    //  props.userData.individualResidentialAddress=""
    //     props.userData.checked = checked;
    handleCopyAddress();
    //console.log(property);
    //props.handleStep();

    // console.log(userData);

    if (props.initialValues.adyen?.adyen_info?.account_holder_id) {
      setShow(true);
      // fetchAdyenURL();
    }

    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      console.log("Submit");
      if (!props.initialValues.adyen?.adyen_info?.account_holder_id) {
        createAccount();
      } else {
        updateAccount();
      }
    }
  }, [checked, formErrors]);

  const handleoPhoneChangeHandler = (value) => {
    const cont = { accountPhone: "+" + value };

    setUserData({ ...userData, ...cont });
  };

  const handleCountryOnChange = (value) => {
    let cont = {
      accountCountry: value,
      accountCountry: value.label,
      country_code: value.value,
    };

    setUserData({ ...userData, ...cont });
  };

  const createAccount = () => {
    // let currencyCode = props.initialValues.property_details.currency_code;
    // console.log(currencyCode)
    //console.log(localStorage.getItem("currency_code"));

    /*  var phone;
 
     if (userData.organisationPhone) {
       phone = userData.organisationPhone?.startsWith("+") ? userData.organisationPhone : "+" + userData.organisationPhone;
     } else if (userData.contact_number) {
       phone = userData.contact_number?.startsWith("+") ? userData.contact_number : "+" + userData.contact_number;
     } else {
       phone = props.initialValues?.property_details?.contact_number ? props.initialValues.property_details.contact_number : localStorage.getItem("contact_number");
     }
 
     if (phone) {
       phone = phone?.startsWith("+") ? phone : "+" + phone
     } */

    //  let email = userData.accountEmail ? userData.accountEmail : localStorage.getItem("email");

    //  console.log(props.initialValues.property_details.contact_number);

    let accHolderId = "";
    const storedData = localStorage.getItem("property_details");

    const propertyDetails = JSON.parse(storedData);
    let timeZone = propertyDetails.time_zone;
    console.log(timeZone, "time zone");

    const payload = {
      property_id: userData.property_id
        ? userData.property_id
        : localStorage.getItem("property_id"),
      payload: {
        legalEntityId: userData.legalEntityId
          ? userData.legalEntityId
          : props.initialValues.adyen?.adyen_info?.legal_entity_id ||
            stateLegalEntityId,
        description: userData.property_name
          ? "AH - " + userData.property_name
          : "AH - " + props.initialValues.property_details.property_name ||
            "AH - " + property.property_name,
        reference: userData.property_name
          ? "AH - " + userData.property_name
          : "AH - " + props.initialValues.property_details.property_name ||
            "AH - " + property.property_name,
        timeZone: timeZone,
      },
    };

    console.log(payload);

    setLoading(true);

    if (!props.initialValues.adyen?.adyen_info?.account_holder_id) {
      SecurityServiceAPI.createAccountHolder(payload)
        .then((response) => {
          setLoading(true);
          console.log(response.data.status);
          if (response.data.status) {
            console.log(response);
            localStorage.setItem("accountHolder", response.data.data);
            let accountHolderId = { accountHolderId: response.data.data };
            setAccountHolderId(accHolderId);
            accHolderId = response.data.data;
            dispatch(setAccountHoldId(response.data.data));
            setUserData({ ...userData, ...accountHolderId });
            setLoading(false);
            setIsSubmitting(false);

            //   this.setState({ summary: response[0].data.data });
          } else {
            toast.error(response.data.message);
            setLoading(false);
            setIsSubmitting(false);
          }
        })
        .then(() => {
          let currencyCode = props.initialValues.property_details.currency_code
            ? props.initialValues.property_details.currency_code
            : localStorage.getItem("currency_code");

          console.log(currencyCode);
          const payload = {
            property_id: userData.property_id
              ? userData.property_id
              : localStorage.getItem("property_id"),
            payload: {
              accountHolderId: userData.accountHolderId
                ? userData.accountHolderId
                : localStorage.getItem("accountHolder") ||
                  accountHolderId ||
                  stateAccountHolderId ||
                  accHolderId,
              defaultCurrencyCode: currencyCode ? currencyCode : "GBP",
              description: userData.property_name
                ? "BA - " + userData.property_name
                : "BA - " +
                    props.initialValues?.property_details?.property_name ||
                  "BA - " + property.property_name,
              reference: userData.property_name
                ? "BA - " + userData.property_name
                : "BA - " +
                    props.initialValues?.property_details?.property_name ||
                  "BA- " + property.property_name,
              timeZone: timeZone,
            },
          };

          console.log(payload);
          SecurityServiceAPI.createBalanceAccount(payload)
            .then((response) => {
              console.log(response);
              localStorage.setItem("balanceAccount", response.data.data);
              let balanceAccount = { balanceAccount: response.data.data };
              if (response.data.status) {
                console.log(balanceAccount);
                setUserData({ ...userData, ...balanceAccount });
                setIsSubmitting(false);
              } else {
                console.log(response.data.message);
                toast.error(response.data.message);
                setLoading(false);
                setIsSubmitting(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .then(() => {
          let legalEntityId = userData.legalEntityId
            ? userData.legalEntityId
            : props.initialValues.adyen?.adyen_info?.legal_entity_id ||
              stateLegalEntityId;

          console.log(legalEntityId);

          SecurityServiceAPI.generateOnboardingLink(legalEntityId)
            .then((response) => {
              console.log(response);
              localStorage.setItem("onboardingLink", response.data.data);
              let ayden = { ayden: response.data.data };
              if (response.data.status) {
                setLoading(false);
                setIsSubmitting(false);
                //   window.location.href = response.data.data;

                console.log(ayden);
                setUserData({ ...userData, ...ayden });

                let windowProperty = window.open(response.data.data, "_blank");
                console.log(windowProperty);
                if (windowProperty == null) {
                  toast.warn(
                    "Please consider enabling pop up on your browser!"
                  );
                }

                props.handleStep();
                props.fetchProperty();

                //    this.props.history.push({ pathname: response.data.data });
              } else {
                console.log(response.data.message);
                toast.error(response.data.message);
                setLoading(false);
                setIsSubmitting(false);
              }
            })
            .catch((error) => {
              //  this.setState({ loading: false, show: false });
              console.log(error);
              setLoading(false);
              setIsSubmitting(false);
            });
        })

        .catch((error) => {
          //  this.setState({ loading: false, show: false });
          console.log(error);
          setLoading(false);
        });
    } else {
      console.log("ACCOUNT HOLDER");
      let legalEntityId = userData.legalEntityId
        ? userData.legalEntityId
        : props.initialValues.adyen?.adyen_info?.legal_entity_id;

      console.log(legalEntityId);

      SecurityServiceAPI.generateOnboardingLink(legalEntityId)
        .then((response) => {
          console.log(response);
          localStorage.setItem("onboardingLink", response.data.data);
          let ayden = { ayden: response.data.data };
          if (response.data.status) {
            setLoading(false);
            setIsSubmitting(false);

            console.log(ayden);
            setUserData({ ...userData, ...ayden });

            //       this.props.history.push({ pathname: response.data.data });

            let windowProperty = window.open(response.data.data, "_blank");
            console.log(windowProperty);
            if (windowProperty == null) {
              toast.warn("Please consider enabling po pup  on your browser!");
            }

            props.handleStep();
          } else {
            console.log(response.data.message);
            toast.warn(response.data.message);
            setLoading(false);
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          //  this.setState({ loading: false, show: false });
          console.log(error);
          setLoading(false);
          setIsSubmitting(false);
          toast.error("Oops! Something went wrong");
        });
    }

    /* .then(() => {
      console.log(userData);
      let payload = userData.property_id;
      console.log(payload);
      SecurityServiceAPI.createStore(payload).then((response) => {
        console.log(response)
        if (!response.data.status) { setLoading(false); }
      }).catch((error) => {
        setLoading(false);
        console.log(error)
      })
    }). */
  };

  const updateAccount = () => {
    console.log(props.initialValues.property_details.contact_number);
    const storedData = localStorage.getItem("property_details");

    // Parse the JSON data
    const propertyDetails = JSON.parse(storedData);
    let timeZone = propertyDetails.time_zone;
    console.log(timeZone, "time zone");
    const payload = {
      property_id: userData.property_id
        ? userData.property_id
        : localStorage.getItem("property_id"),
      payload: {
        legalEntityId: userData.legalEntityId
          ? userData.legalEntityId
          : props.initialValues.adyen?.adyen_info?.legal_entity_id,
        description: property.property_name
          ? "AH - " + property.property_name
          : "AH - " + props.initialValues.property_details.property_name,
        reference: property.property_name
          ? "AH - " + property.property_name
          : "AH - " + props.initialValues.property_details.property_name,
        timeZone: timeZone,
      },
    };

    console.log(payload);

    setLoading(true);

    if (props.initialValues.adyen?.adyen_info?.account_holder_id) {
      SecurityServiceAPI.updateAccountHolder(payload)
        .then((response) => {
          setLoading(true);
          console.log(response.data.status);
          if (response.data.status) {
            //     this.setState({ loading: false, show: false });
            //    toast.success("Message Sent Successfully");
            console.log(response);
            localStorage.setItem(
              "accountHolder",
              props.initialValues.adyen?.adyen_info?.account_holder_id
            );
            let accountHolderId = {
              accountHolderId:
                props.initialValues.adyen?.adyen_info?.account_holder_id,
            };
            setUserData({ ...userData, ...accountHolderId });
            setLoading(false);
            setIsSubmitting(false);

            //   this.setState({ summary: response[0].data.data });
          } else {
            toast.error(response.data.message);
            //     this.setState({ loading: false });
            setLoading(false);
            setIsSubmitting(false);
          }
        })
        .then(() => {
          let currencyCode = props.initialValues.property_details.currency_code
            ? props.initialValues.property_details.currency_code
            : localStorage.getItem("currency_code");

          console.log(currencyCode);
          const payload = {
            property_id: userData.property_id
              ? userData.property_id
              : localStorage.getItem("property_id"),
            payload: {
              accountHolderId: userData.accountHolderId
                ? userData.accountHolderId
                : localStorage.getItem("accountHolder") ||
                  props.initialValues.adyen?.adyen_info?.account_holder_id,
              defaultCurrencyCode: currencyCode ? currencyCode : "GBP",
              description: property.property_name
                ? "BA - " + property.property_name
                : "BA - " +
                  props.initialValues?.property_details?.property_name,
              reference: property.property_name
                ? "BA - " + property.property_name
                : "BA - " +
                  props.initialValues?.property_details?.property_name,
              timeZone: timeZone,
            },
          };

          console.log(payload);
          SecurityServiceAPI.updateBalanceAccount(payload)
            .then((response) => {
              console.log(response);
              localStorage.setItem("balanceAccount", response.data.data);
              let balanceAccount = { balanceAccount: response.data.data };
              if (response.data.status) {
                console.log(response);
                setIsSubmitting(false);
              } else {
                console.log(response.data.message);
                toast.error(response.data.message);
                setLoading(false);
                setIsSubmitting(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsSubmitting(false);
            });
        })
        .then(() => {
          let legalEntityId = userData.legalEntityId
            ? userData.legalEntityId
            : props.initialValues.adyen?.adyen_info?.legal_entity_id;

          console.log(legalEntityId);

          SecurityServiceAPI.generateOnboardingLink(legalEntityId)
            .then((response) => {
              console.log(response);
              localStorage.setItem("onboardingLink", response.data.data);
              let ayden = { ayden: response.data.data };
              if (response.data.status) {
                setLoading(false);
                setIsSubmitting(false);

                console.log(ayden);
                setUserData({ ...userData, ...ayden });

                let windowProperty = window.open(response.data.data, "_blank");
                console.log(windowProperty);
                if (windowProperty == null) {
                  toast.warn(
                    "Please consider enabling pop up on your browser!"
                  );
                }

                //    this.props.history.push({ pathname: response.data.data });
              } else {
                console.log(response.data.message);
                toast.error(response.data.message);
                setLoading(false);
                setIsSubmitting(false);
              }
            })
            .catch((error) => {
              //  this.setState({ loading: false, show: false });
              console.log(error);
              setLoading(false);
              setIsSubmitting(false);
            });
        })

        .catch((error) => {
          //  this.setState({ loading: false, show: false });
          console.log(error);
          setLoading(false);
          setIsSubmitting(false);
        });
    } else {
      console.log("ACCOUNT HOLDER");
      let legalEntityId = userData.legalEntityId
        ? userData.legalEntityId
        : props.initialValues.adyen?.adyen_info?.legal_entity_id;

      console.log(legalEntityId);

      SecurityServiceAPI.fetchLegalEntity(legalEntityId)
        .then((response) => {
          console.log(response);
          localStorage.setItem("onboardingLink", response.data.data);
          let ayden = { ayden: response.data.data };
          if (response.data.status) {
            setLoading(false);
            setIsSubmitting(false);

            console.log(ayden);
            setUserData({ ...userData, ...ayden });

            //       this.props.history.push({ pathname: response.data.data });

            let windowProperty = window.open(response.data.data, "_blank");
            console.log(windowProperty);
            if (windowProperty == null) {
              toast.warn("Please consider enabling pop on your browser!");
            }
          } else {
            console.log(response.data.message);
            toast.warn(response.data.message);
            setLoading(false);
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          //  this.setState({ loading: false, show: false });
          console.log(error);
          setLoading(false);
          setIsSubmitting(false);
          toast.error("Oops! Something went wrong");
        });
    }
  };

  const fetchAdyenURL = () => {
    setLoading(true);

    let legalEntityId = userData.legalEntityId
      ? userData.legalEntityId
      : props.initialValues.adyen?.adyen_info?.legal_entity_id;

    console.log(legalEntityId);

    SecurityServiceAPI.fetchLegalEntity(legalEntityId)
      .then((response) => {
        console.log(response);
        localStorage.setItem("onboardingLink", response.data.data);
        let ayden = { ayden: response.data.data };
        if (response.data.status) {
          setLoading(false);
          //    window.location.href = response.data.data;

          console.log(ayden);
          setUserData({ ...userData, ...ayden });
          //
          //     this.props.history.push({ pathname: response.data.data });

          window.open(response.data.data, "_blank");
        } else {
          console.log(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    console.log(event.target.checked);
    setChecked(event.target.checked);
    //     props.userData.checked = checked;
    //  props.userData.checked = event.target.checked;
    handleCopyAddress();
  };

  const handleCopyAddress = () => {
    console.log(checked);
    if (checked === true) {
      var phone;
      if (userData.contact_number) {
        var phone = userData.contact_number?.startsWith("+")
          ? userData.contact_number
          : "+" + userData.contact_number;
      }

      const total = {
        accountCountry: userData.country
          ? userData.country
          : props.initialValues.country,
        accountCity: userData.city,
        accountPostalCode: userData.postal_code,
        accountPhone: phone ? phone : props.initialValues.contact_number,
        accountEmail: localStorage.getItem("email"),
      };

      console.log(props.initialValues.contact_number);

      setUserData({ ...userData, ...total });
    } else {
      let dial_code = userData.dial_code
        ? userData.dial_code
        : props.initialValues.property_details.dial_code;

      const total = {
        accountCountry: "",
        accountCity: "",
        accountPostalCode: "",
        accountPhone: dial_code ? dial_code : localStorage.getItem("dial_code"),
        accountEmail: "",
      };

      setUserData({ ...userData, ...total });
    }
  };

  const value = React.useContext(StepperContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    console.log(userData);
  };

  //console.log(value)

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(userData);

    var phone;

    if (userData.accountPhone) {
      phone = userData.accountPhone?.startsWith("+")
        ? userData.accountPhone
        : "+" + userData.accountPhone;
    } else if (userData.contact_number) {
      phone = userData.contact_number?.startsWith("+")
        ? userData.contact_number
        : "+" + userData.contact_number;
    } else {
      phone = props.initialValues?.property_details?.contact_number
        ? props.initialValues.property_details.contact_number
        : localStorage.getItem("contact_number");
    }

    let data = {
      property_id: userData.property_id,
      payload: {
        legalEntityId: userData.legalEntityId
          ? userData.legalEntityId
          : props.initialValues.adyen?.adyen_info?.legal_entity_id,
        contactDetails: {
          email: userData.accountEmail,
          phone: {
            number: phone,
            type: "mobile",
          },
          address: {
            houseNumberOrName: userData.houseNumberOrName,
            street: userData.organisationStreet,
            city: userData.organisationCity
              ? userData.organisationCity
              : props.initialValues.property_details.city,
            postalCode: userData.organisationPostalCode
              ? userData.organisationPostalCode
              : props.initialValues.property_details.postal_code,
            country: userData.country
              ? countryList().getValue(userData.country)
              : countryList().getValue(props.initialValues.country),
          },
        },
        description: userData.legalName,
        reference: userData.legalName,
      },
    };

    setFormErrors(validate(userData));

    setIsSubmitting(true);

    console.log(data);

    // props.handleClick("next");
  };

  const validate = (userData) => {
    let errors = {};

    console.log(props.initialValues.adyen?.adyen_info?.account_holder_id);
    if (!props.initialValues.adyen?.adyen_info?.account_holder_id) {
      /*   if (!userData.accountPhone) {
          errors.accountPhone = "Cannot be blank";
        }
  
        if (!userData.accountEmail) {
          errors.accountEmail = "Cannot be blank";
        } */
      /*    if (!userData.accountDescription) {
           errors.accountDescription = "Cannot be blank";
         }
   
         if (!userData.accountReference) {
           errors.accountReference = "Cannot be blank";
         }
    */
      /*    if (!userData.accountCountry) {
           errors.accountCountry = "Cannot be blank";
         }
   
         if (!userData.accountPostalCode) {
           errors.accountPostalCode = "Cannot be blank";
         }
   
         if (!userData.houseNumberOrName) {
           errors.houseNumberOrName = "Cannot be blank";
         }
   
         if (!userData.accountCity) {
           errors.accountCity = "Cannot be blank";
         }
   
         if (!userData.accountStreet) {
           errors.accountStreet = "Cannot be blank";
         } */
    }

    console.log(errors);
    console.log(errors);
    return errors;
  };

  const handleCopyLink = () => {
    // Copy the link to the clipboard
    const link = localStorage.getItem("onboardingLink");
    navigator.clipboard.writeText(link).then(
      () => {
        // Optionally, show a success message to the user
        console.log("Link copied to clipboard!");
      },
      (error) => {
        // Handle any error that might occur while copying
        console.error("Failed to copy link: ", error);
      }
    );
  };
  return (
    <React.Fragment>
      {loading ? (
        <div className="spinner cardBody card-body">
          <FlapperSpinner size={100} color="#233cad" loading={loading} />
        </div>
      ) : (
        <Grid container spacing={3} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} direction="row">
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingTop: 5, marginTop: 10 }}
                >
                  <Typography variant="h6" gutterBottom>
                    Complete KYC by clicking the button below to complete
                    verification or to update any details.
                  </Typography>

                  <Divider variant="middle" />
                </Grid>

                <Grid item xs={12} md={8}>
                  <button
                    className="btn btn-primary float-right"
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Click here
                  </button>
                </Grid>
                {localStorage.getItem("onboardingLink") && (
                  <React.Fragment>
                    {/*       <Grid item xs={12} md={10} lg={10} >
                      <div>

                        <TextField
                          id="link"
                          label="Link"
                          value={localStorage.getItem("onboardingLink")}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                      </div>

                    </Grid>

                    <Grid item xs={12} md={2} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                      <Button variant="contained" onClick={handleCopyLink}>
                        Copy Link
                      </Button>


                    </Grid> */}

                    <Grid item xs={12} md={12} lg={12}>
                      <h6>
                        or If the above click button doesn't work click on the
                        below link.
                      </h6>
                      <Typography
                        style={{ wordWrap: "break-word", textAlign: "justify" }}
                      >
                        <Link to={localStorage.getItem("onboardingLink")}>
                          <b>
                            {" "}
                            <a
                              href={localStorage.getItem("onboardingLink")}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Onboarding link.
                            </a>{" "}
                          </b>
                        </Link>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}

                {/*   {userData.ayden ?
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="info"><a href={userData.ayden}>Click here to complete KYC! </a></Alert>
                  </Stack> : ""}
 */}
              </Grid>
            </form>

            {props.activeStep !== props.steps.length - 1 && (
              <StepperControl
                handleClick={props.handleClick}
                currentStep={props.activeStep}
                steps={props.steps}
              />
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
