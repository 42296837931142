import React, { Component, Fragment } from "react";
import * as OrderServiceAPI from "../../../../services/order/OrderServiceAPI";
import * as DashboardServiceAPI from "../../../../services/dashboard/DashboardServiceAPI";
import * as ReportServiceAPI from "../../../../services/reports/ReportServiceAPI";
import DataTableComponet from "react-data-table-component";
import Pagination from "../../../../shared/Pagination/Pagination";
import Breadcrumb from "./Breadcrumb";
import "../../../../index.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Loader from "../../../../shared/Loader";
import $ from "jquery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadingIcon from "@mui/icons-material/Downloading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Papa from "papaparse";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import ReactGA from "react-ga";
import { DatePicker, Space } from "antd";
import * as PaymentServiceAPI from "../../../../services/payment/PaymentServiceAPI";

const { RangePicker } = DatePicker;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


class Content extends Component {
  state = {
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: false,
    isPdfLoading: false,
    count: 0,
    hideFilter: false,
    start: dayjs(new Date()).startOf("week"),
    end: dayjs(new Date()),
    data: null,
    topSellingItems: [],
    trendingItemsSalesReport: [],
    tab: 0,
    max: new Date(),
    min: dayjs(new Date()).subtract(3, "year"),
    zReport: "",
    filterBy: 0,
    propertyId: "",
    filter: 0,
    defaultRange: [dayjs(new Date()).startOf("week"), dayjs()],
    tempRange: [dayjs(new Date()).startOf("week"), dayjs()],
    selectedProperties: [],
    franchises: [],
    open: false,
    dateKeys: [],
    salesData: [],
    selectedProperties: [],
    year: dayjs(),
  };

  constructor(props) {
    super(props);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({
      propertyId: this.props.propertyId,
      selectedProperties: [this.props.propertyId],
    });
    this.fetchFranchiseInformation();
    // this.getTrendingReport();
    //this.getDataReport();

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#reports").addClass("active");
    }
  };
  handleExportCSV = () => {
    if (this.state.zReport) {
      var link = document.createElement("a");
      link.href = this.state.zReport;
      link.download = "zReport";
      link.click();
    } else {
      toast.warn("No report found.");
    }
  };

  fnCallback = (e) => {
    this.setState({ hideFilter: false, filter: e?.target?.value });
    if (!e?.target?.value) {
      this.filterAllOrders();
    } else if (e?.target?.value == 1) {
      // Today
      let today = dayjs(new Date());

      this.setState(
        {
          start:  dayjs().startOf('year').format("DD-MM-YYYY") + " 00:00:00",
          end:  dayjs().endOf('year').format("DD-MM-YYYY") + " 23:59:59",
          year: dayjs().startOf('year'),
        },
        () => { }
      );
    } else if (e?.target?.value == 2) {
      this.setState(
        {
          start: dayjs().subtract(1, 'year').startOf('year') + " 00:00:00",
          end: dayjs().subtract(1, 'year').endOf('year') + " 23:59:59",
          year: dayjs().subtract(1, 'year').endOf('year'),
        },
        () => {}
      );
    }
  };

  getTrendingReport = () => {
    this.setState({ isLoading: true });
    const property_id = (this.state.selectedProperties && this.state.selectedProperties.length > 0)
      ? this.state.selectedProperties
      : this.props.propertyId;
    let payload = {
      start_date:
        this.state.filter == 1 || this.state.filter == 2
          ? this.state?.start?.format("DD-MM-YYYY") + " 00:00:00"
          : this.state?.start?.format("DD-MM-YYYY HH:mm:ss"),
      end_date:
        (this.state.filter == 2 &&
          this.state.end?.format("DD-MM-YYYY") + " 23:59:59") ||
        (this.state.filter == 1 &&
          this.state.end?.format("DD-MM-YYYY HH:mm:ss")) ||
        this.state.end?.format("DD-MM-YYYY HH:mm:ss"),
      property_id: property_id,
    };

    ReportServiceAPI.getReport(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false, data: response.data.data });
        } else {
          toast.warn("No data retrieved.");
          this.setState({ isLoading: false, data: null });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false, data: null });
        toast.error(error);
      });
  };

  getDataReport = () => {
    this.setState({ isLoading: true });
    const property_id = (this.state.selectedProperties && this.state.selectedProperties.length > 0)
      ? this.state.selectedProperties
      : this.props.propertyId;
    let payload = {
      start_date:
    this.state.filter === 1 || this.state.filter === 2
      ? dayjs(this.state.year).startOf('year').format("DD-MM-YYYY") + " 00:00:00"
      : dayjs(this.state.year).startOf('year').format("DD-MM-YYYY") + " 00:00:00",
  end_date:
    this.state.filter === 2
      ? dayjs(this.state.year).endOf('year').format("DD-MM-YYYY") + " 23:59:59"
      : this.state.filter === 1
      ? dayjs(this.state.year).endOf('year').format("DD-MM-YYYY") + " 23:59:59"
      : dayjs(this.state.year).endOf('year').format("DD-MM-YYYY") + " 23:59:59",
      property_id: property_id,
    };

    ReportServiceAPI.getTotalSalesYearlyReport(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState({ isLoading: false, data: response.data.data, });
          /*  const b = {
             "Los Pollos Hermanos": {
               " Badnaam Qadri": {
                 "totalCost": "£2010.00"
               },
               "halaal Kathe": {
                 "totalCost": "£7.95"
               },
               "FOOD": {
                 "totalCost": "£60.50"
               },
               "Total Los Pollos Hermanos": "£2078.45"
             },
             "TAJ": {
               " Badnaam Qadri": {
                 "totalCost": "£2010.00"
               },
               "halaal Kathe": {
                 "totalCost": "£7.95"
               },
               "FOOD": {
                 "totalCost": "£60.50"
               },
               "Total Los Pollos Hermanos": "£2078.45"
             }
           } */
          this.convertToSalesData(response.data.data);
        } else {
          toast.warn("No data retrieved.");
          this.setState({ isLoading: false, data: null });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false, data: null });
        toast.error(error);
      });
  };



  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleZreport = () => {
    // Your handler logic here
    this.getDataReport();
  };

  fetchFranchiseInformation() {
    const dump = {
      property_id: this.props.propertyId,
    };

    PaymentServiceAPI.fetchFranchiseInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            franchises: [
              ...response.data.data,
              {
                property_id: this.props.propertyId,
                property_name: this.props.user.user.business_trading_name,
              },
            ],
          });
        } else {
          this.setState({ franchises: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ franchises: [] });
      });
  }

  cleanTag = (tag) => {
    if (typeof tag !== 'string') {
      return '';
    }
    return tag.replace(/^\[|\]$/g, '');
  };

  convertToSalesData = (data) => {
    const salesData = [];

    for (const [restaurant, items] of Object.entries(data)) {
      const rows = [];
      let total = '';

      for (const [item, details] of Object.entries(items)) {
        if (item.startsWith('Total')) {
          total = details;
        } else {
          rows.push({
            label: item,
            amount: details.totalCost,
            percentage:details.percent,
          });
        }
      }

      rows.push({ label: '', amount: total });

      salesData.push({
        title: `${restaurant} total sales breakdown`,
        rows: rows,
      });
    }
    this.setState({ salesData: salesData });
    return salesData;
  };

  exportToCSV = (salesData) => {
    const csvData = [];
    csvData.push([, 'Amount', 'Percent']);

    salesData.forEach((section) => {
      csvData.push([section.title,'','']);

      section.rows.forEach((row) => {
        csvData.push([row.label, row.amount,row.percentage]);
      });

      csvData.push([]); // Empty row for separation
    });

    const csv = Papa.unparse(csvData, { header: false });
    const bom = '\uFEFF';
    const csvWithBom = bom + csv;
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

    // Create a link element, set its href to a URL created from the Blob, and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sales_report.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleYearChange = (date, dateString) => {
    this.setState({ year: date });
  };

  render() {
    const {
      tab,
      data,
      propertyId,
      defaultRange,
      selectedProperties,
      franchises,
      dateKeys,
      salesData,
      year
    } = this.state;

console.log(salesData,"sales data")
    

    return (
      <Fragment>
        <Breadcrumb />
        {propertyId ? (
          <React.Fragment>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tab}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{ style: { background: "#5773ff" } }}
                    >
                      <Tab label="Total Sales Report" />
                      {/* <Tab label="Unsold Items" /> */}
                    </Tabs>
                    <TabPanel value={tab} index={0} className="tabPanel">
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="row">
                            <div className="col-xl-12 col-md-12 col-sm-12">
                              <div className="row m-2">
                                <div className="col-md-2">
                                  <div className="mt-4">
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Filter By
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label" /* style={{ height: 40 }} */
                                        id="demo-simple-select"
                                        value={this.state.filter}
                                        label="Filter By"
                                        onChange={this.fnCallback}
                                        sx={{height:"49px"}}
                                      >
                                        <MenuItem value={0}>
                                          {" "}
                                          Filter By
                                        </MenuItem>
                                        <MenuItem value={1}>This year</MenuItem>
                                        <MenuItem value={2}>Last year</MenuItem>
                                        {/*  <MenuItem value={9}>Any Single Day</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>



                                <div className="col-md-2">
                                  <div className="mt-4">
                                    {/* <Space style={{}}> */}
                                      {/* <RangePicker
                                        id="zReport"
                                        name="zReport"
                                        size={"mini"}
                                        placement={"bottomLeft"}
                                        allowClear={false}
                                       // disabledTime={this.disabledRangeTime}
                                        onOpenChange={this.handleOpenChange}
                                        onCalendarChange={(value, dateStrings) => {
                                          // Handle the selected range here
                                          if (value && value.length === 2) {
                                              this.setState({
                                                tempRange: value,
                                              });
                                          }
                                        }}
                                        changeOnBlur={true}
                                        showOk={false}
                                        format="YYYY"
                                        value={defaultRange}
                                        style={{ height: "50px" }}
                                      /> */}
                                      <DatePicker
                                        id="yearPicker"
                                        name="yearPicker"
                                        picker="year"
                                        allowClear={false}
                                        onChange={this.handleYearChange}
                                        format="YYYY"
                                        value={year}
                                        style={{ height: "50px" }}
                                        fullWidth
                                      />
                                    {/* </Space> */}
                                    {/*   <i>Note: Please select a range within one week. Ranges longer than seven days are not allowed.</i> */}

                                  </div>
                                </div>

                                {/* {this.props.settings.is_master_franchise && (
                                  <div className="col-md-3">

                                    <div className="mt-3">
                                      <FormControl
                                        style={{
                                          margin: "8px",
                                          width: "300px",
                                        }}
                                      >
                                        <InputLabel id="demo-multiple-checkbox-label">
                                          Franchise
                                        </InputLabel>
                                        <Select
                                          labelId="demo-multiple-checkbox-label"
                                          id="demo-multiple-checkbox"
                                          multiple
                                          value={selectedProperties} 
                                          onChange={this.handlePropChange}
                                          input={
                                            <OutlinedInput label="Franchise" />
                                          }
                                          renderValue={(selected) =>
                                            selected
                                              .map((id) => {
                                                const property =
                                                  franchises.find(
                                                    (item) =>
                                                      item.property_id === id
                                                  );
                                                return property
                                                  ? property.property_name
                                                  : "";
                                              })
                                              .join(", ")
                                          }
                                          MenuProps={MenuProps}
                                        >
                                          {franchises.map((item) => (
                                            <MenuItem
                                              key={item.property_id}
                                              value={item.property_id}
                                            >
                                              <Checkbox
                                                checked={
                                                  selectedProperties.indexOf(
                                                    item.property_id
                                                  ) > -1
                                                }
                                              />
                                              <ListItemText
                                                primary={item.property_name}
                                              />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>

                                  </div>
                                )} */}



                                <div className="col-md-3">
                                  <div className="mt-4" >
                                    <Button type="submit" sx={{height:"40px",mt:"3px" }} variant="contained" onClick={this.handleZreport} color="primary">
                                      GENERATE
                                    </Button>
                                  </div>
                                </div>


                              </div>

                              <div
                                className=""
                                style={{ marginTop: "100px !important" }}
                              >
                                {this.state.isLoading ? (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      marginBottom: "40px",
                                    }}
                                  >
                                    {" "}
                                    <Loader size={50} />{" "}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <br></br>
                                    {this.state.salesData?.length > 0 && (
                                      <React.Fragment>
                                        {this.state.isPdfLoading ? (
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                              marginRight: "auto",
                                              marginBottom: "40px",
                                            }}
                                          >
                                            {" "}
                                            <Loader size={50} />{" "}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              style={{ float: "right" }}
                                              color="primary"
                                              onClick={
                                                () => { this.exportToCSV(this.state.salesData) }
                                              }
                                            >
                                              Download Report
                                            </Button>
                                          </div>
                                        )}
                                        <br></br>

                                        <Paper
                                          elevation={3}
                                          style={{
                                            padding: "20px",
                                            margin: "0px",
                                          }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} lg={6}>
                                              <Typography variant="body1">
                                                Total Sales Report
                                              </Typography>
                                            </Grid>



                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              <Divider />
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              style={{ textAlign: "center" }}
                                            >
                                              {/*  <Typography variant="h6">
                                                DATA REPORT BREAKDOWN{" "}
                                              </Typography> */}
                                              <Divider />
                                            </Grid>
                                            <Box p={3}>
                                              {salesData?.map((section, index) => (
                                                <Box mb={4} key={index}>
                                                  <Typography variant="h6" gutterBottom>{section.title}</Typography>
                                                  <TableContainer component={Paper}>
                                                    <Table size="small">
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell  sx={{ width: '40%',}}>Item</TableCell>
                                                          <TableCell  sx={{ width: '30%', textAlign: 'right' }}>Amount</TableCell>
                                                          <TableCell  sx={{ width: '30%',}}>Percentage</TableCell>

                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                      {section.rows.map((row, idx) => (
                                                          <TableRow key={idx}>
                                                            <TableCell sx={{ width: '40%' }}>{row.label}</TableCell>
                                                            <TableCell sx={{ width: '30%', textAlign: 'right' }}>{row.amount}</TableCell>
                                                            <TableCell sx={{ width: '30%' }}>{row.percentage}</TableCell>
                                                          </TableRow>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </Box>
                                              ))}
                                            </Box>




                                          </Grid>
                                        </Paper>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <div className="ms-panel">
                        <div className="ms-panel-body">
                          <div className="col-md-12">
                            <Typography variant="h5" gutterBottom style={{}}>
                              Inventory of Unsold Items
                            </Typography>

                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </AppBar>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <Alert severity="warning">
            To access this feature please complete your account setup.
          </Alert>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  settings: state.user.settings,
});

export default connect(mapStateToProps, {})(Content);
