import React, { Component } from "react";
import { QRCode } from "antd";
import { toast } from "react-toastify";
import * as DiscountServiceAPI from "../../services/discount/DiscountServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormControl } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import isEqual from "lodash/isEqual";
import InputAdornment from "@mui/material/InputAdornment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  TableSortLabel,
} from "@mui/material";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
//import Checkbox from '@mui/material/Checkbox';
import DataTable from "react-data-table-component";
import moment from "moment";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  fetchMenuNames,
  fetchMenu,
} from "../../services/product/menu/MenuServiceAPI";
import "@arco-design/web-react/dist/css/arco.css";
import { TreeSelect, Checkbox } from "@arco-design/web-react";
import {
  fetchCategoryNames,
  fetchCategory,
} from "../../services/product/category/CategoryServiceAPI";
import * as RoomServiceAPI from "../../services/room/RoomServiceAPI";
import * as TableServiceAPI from "../../services/table/TableServiceAPI";
import * as QRServiceAPI from "../../services/qr/QRServiceAPI";
import {
  addItem,
  updateItem,
  fetchItem,
  addExtrasOptions,
  deleteExtrasOptions,
  updateExtrasOptions,
  fetchAddonByAddonId,
  fetchUpsellItems,
} from "../../services/product/item/ItemServiceAPI";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, Space, TimePicker, Modal } from "antd";
import { Alert, AlertTitle, Collapse } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PricingCard from "./PricingCard";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import CloseIcon from "@mui/icons-material/Close";
import { withRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import JSZip from "jszip";
import QRCodeGenerator from "qrcode";
import { saveAs } from "file-saver";

const slotUnitOptions = [
  {
    value: "MINUTES",
    label: "MINUTES",
  },
  {
    value: "HOURS",
    label: "HOURS",
  },
  {
    value: "DAYS",
    label: "DAYS",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    discounts: [],
    discount: null,
    rooms: [],
    room: null,
    tables: [],
    tableList: [],
    table: null,
    selectedDiscount: null,
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortDirection: "asc",
    open: false,
    dId: "",
    tableId: "",
    menuNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedMenu: "",
    itemNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedItem: [],
    selectedItems: [],
    categoryNames: [],
    itemIdentifiers: [],
    itemIds: [],
    itemMap: {},
    selectedCategory: "",
    item_list: [],
    treeCheckStrictly: false,
    value: [],
    treeData: [],
    error: "",
    startDate: null,
    endDate: null,
    start_time: dayjs(),
    end_time: dayjs(),
    submit: false,
    tableRow: null,
    openAlert: true,
    isActive: true,
    qr: {},
    openDialog: false,
    confirmLoading: false,
    isBulk: false,
    table_identifier: "",
    edit: false,
    showForm: false,
    //defaultRange: [moment(new Date(), 'HH:mm:ss'), moment(new Date(), 'HH:mm:ss')]
  };

  columns = [
    {
      name: "Table",
      selector: "table_identifier",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.table_identifier}</div>
        </div>
      ),
    },

    {
      name: "Start Time",
      selector: "start_time",
      sortable: false,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{row.start_time}</div>
        </div>
      ),
    },

    {
      name: "End Time",
      selector: "end_time",
      sortable: false,
      justifyContent: "center",
      cell: (row) => <div> {row.end_time}</div>,
    },

    {
      name: "No of persons",
      selector: "number_of_person_per_table",
      sortable: false,
      justifyContent: "center",
      cell: (row) => <div> {row.number_of_person_per_table}</div>,
    },

    {
      name: "Reserve Time Slot",
      selector: "reserve_time_slot",
      sortable: false,
      justifyContent: "center",
      cell: (row) => <div> {row.reserve_time_slot}</div>,
    },
    {
      name: "Reserve Time Slot Unit",
      selector: "reserve_time_slot_unit",
      sortable: false,
      justifyContent: "center",
      cell: (row) => <div> {row.reserve_time_slot_unit}</div>,
    },
    {
      name: "Room Status",
      selector: "table_status",
      sortable: false,
      justifyContent: "center",
      cell: (row) => <div> {row.table_status}</div>,
    },
    {
      name: "QR",
      selector: "table_dynamic_link",
      sortable: false,
      justifyContent: "center",
      cell: (row) => (
        <React.Fragment>
          {/*  <div id={row.table_identifier} style={{ display: 'flex', alignItems: 'center' }}>
          <QRCode  size={100} value={row.table_dynamic_link} bgColor="#fff" style={{ marginBottom: 16 }} />

        </div> */}
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                this.handleTableOpen(row);
              }}
            >
              VIEW QR
            </Button>
          </Grid>
        </React.Fragment>
      ),
    },
    {
      name: "Action",
      selector: "action",
      width: "250px",
      cell: (row) => (
        <div>
          <Tooltip title="Edit / Update Table." placement="top">
            {/* <IconButton color="primary" onClick={() => this.editDiscount(row)} aria-label="Edit">
            <EditIcon />
          </IconButton> */}
            <Button
              variant="outlined"
              startIcon={<EditIcon color="primary" />}
              onClick={() => this.editDiscount(row)}
            >
              Edit
            </Button>
          </Tooltip>
          <Tooltip title="Delete Table." placement="top">
            {/* <IconButton color="primary" onClick={() => this.handleClickOpen(row.table_id)} aria-label="delete" size="large">
            <DeleteIcon fontSize="inherit" />
          </IconButton> */}
            <Button
              variant="outlined"
              startIcon={<DeleteIcon color="primary" />}
              onClick={() => this.handleClickOpen(row.table_id)}
              sx={{ marginLeft: "4px" }}
            >
              Delete
            </Button>
          </Tooltip>
          <Tooltip title="Generate QR." placement="top">
            {!row.table_dynamic_link && (
              // <IconButton color="primary" onClick={() => this.generateTableLink(row.table_id)} aria-label="delete" size="large">
              //   <AddBoxIcon fontSize="inherit" />
              // </IconButton>
              <Button
                variant="outlined"
                startIcon={<AddBoxIcon color="primary" />}
                onClick={() => this.generateTableLink(row.table_id)}
              >
                Add
              </Button>
            )}
          </Tooltip>
          {/*  <Tooltip
          title="Download QR."
          placement="top"
        >
          <Button type="primary" onClick={() => {
            this.downloadQRCode(row.table_identifier);
          }}>
            < DownloadForOfflineIcon />
          </Button>
        </Tooltip> */}
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.fetchAllDiscounts();
    this.setInitalValues();
    this.fetchPlansStatus();
    console.log(this.props, "property props");
  };

  handleButtonClick = () => {
    // Add logic for button click here
  };

  fetchPlansStatus = async () => {
    try {
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchPlansStatus(propertId);

      if (response.data.status) {
        this.setState({ qr: response.data.data });
        if (!response.data.data) {
          /*  this.props.history.push({
             pathname: "/manage-qr-flow",
           }); */
        }
      } else {
        this.setState({ qr: response.data.data });
        // this.setState({ isActive: false });
      }
    } catch (error) {
      //  this.setState({ isActive: false });
      throw error;
    }
  };

  generateTableLink = (tableId) => {
    const payload = {
      table_id: tableId,
      property_id: this.props.propertyId,
    };
    QRServiceAPI.generateTableDynamicLink(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success("Table QR link generated.");
          setTimeout(() => {
            this.fetchAllDiscounts();
          }, 700);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not generate a table QR link. ");
      });
  };

  filterOrders = (event) => {
    event.preventDefault();
    const searchValue = event.target.value.toLowerCase();

    // Use the original tableList for resetting the filter
    if (!searchValue) {
      this.setState({ tables: this.state.tableList });
      return;
    }

    // Filter the data based on the search value
    const filteredData = this.state.tableList.filter((item) =>
      item.table_identifier.toLowerCase().includes(searchValue)
    );

    this.setState({ tables: filteredData });
  };

  handleClickOpen = (tableId) => {
    this.setState({ open: true, tableId: tableId });
  };

  handleCancel = () => {
    this.setState(
      {
        table: null,
        edit: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, openDialog: false, tableId: "" });
  };

  setInitalValues = () => {
    const dateFormat = "HH:mm:ss";
    const { table, isBulk } = this.state;
    let initialValues = {};
    initialValues = {
      start_time: table?.start_time
        ? dayjs(table?.start_time, dateFormat)
        : dayjs(), // Use the current time if start_time is not available
      end_time: table?.end_time ? dayjs(table?.end_time, dateFormat) : dayjs(), // Use the current time if end_time is not available
      reserve_time_slot: table?.reserve_time_slot || "",
      reserve_time_slot_unit: table?.reserve_time_slot_unit || "MINUTES",
      table_count: table?.table_count || 1,
      number_of_person_per_table: table?.number_of_person_per_table || "",
      minimum_spend_on_table_reservation:
        table?.minimum_spend_on_table_reservation || "",
      is_bulk: isBulk,
      table_names: table?.table_identifier || "",
    };

    this.setState({
      initialValues,
      start_time: table?.start_time
        ? dayjs(table?.start_time, dateFormat)
        : dayjs(),
      end_time: table?.end_time ? dayjs(table?.end_time, dateFormat) : dayjs(),
    });
  };

  fetchAllDiscounts = () => {
    this.setState({ isLoading: true });
    let propertId = this.props.propertyId;
    TableServiceAPI.fetchAllTable(propertId)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            tables: response.data.data.table_info,
            tableList: response.data.data.table_info,
            isLoading: false,
          });
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          //  toast.success("Discount added. ")
        } else {
          this.setState({ tables: [], isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not fetch tables.. ");
      });
  };

  addTable = (values, resetForm) => {
    this.setState({ submit: true });
    if (this.state.isBulk) {
      delete values["table_names"];
      TableServiceAPI.addTable(values)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Table added. ");
            this.setState({ isLoading: false });

            resetForm({ values: "" });
            //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
            this.setState(
              { table: null, submit: false, isBulk: false, showForm: false },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchPlansStatus();
            this.fetchAllDiscounts();
          } else {
            this.setState(
              {
                table: null,
                isLoading: false,
                submit: false,
                isLoading: false,
              },
              () => {
                this.setInitalValues();
              }
            );
            if (response.data?.message == "No active plan found.") {
              this.setState({ openDialog: true, isLoading: false });
            } else {
              this.setState({ isLoading: false });
              toast.warn(response.data?.message);
            }
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, submit: false });
          toast.warn("Could not add table.. ");
        });
    } else {
      delete values["table_count"];
      TableServiceAPI.addTable(values)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Table added. ");
            this.setState({ isLoading: false });
            resetForm({ values: "" });
            //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
            this.setState(
              {
                table: null,
                submit: false,
                isBulk: false,
                showForm: false,
                isLoading: false,
              },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchPlansStatus();
            this.fetchAllDiscounts();
          } else {
            this.setState(
              { table: null, submit: false, isLoading: false },
              () => {
                this.setInitalValues();
              }
            );
            if (response.data?.message == "No active plan found.") {
              this.setState({ openDialog: true, isLoading: false });
            } else {
              this.setState({ isLoading: false });
              toast.warn(response.data?.message);
            }
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, submit: false });
          toast.warn("Could not add table.. ");
        });
    }
  };

  updateDiscount = (values, resetForm) => {
    if (this.state.isBulk) {
      const payload = {
        property_id: this.props.propertyId,
        table_info: [
          {
            end_time: values["end_time"],
            minimum_spend_on_table_reservation:
              values["minimum_spend_on_table_reservation"],
            number_of_person_per_table: values["number_of_person_per_table"],
            reserve_time_slot: values["reserve_time_slot"],
            reserve_time_slot_unit: values["reserve_time_slot_unit"],
            start_time: values["start_time"],
            table_id: this.state.table.table_id,
            table_status: values["table_status"],
            table_identifier: this.state.table_identifier,
            is_bulk: this.state.isBulk,
            table_names: [this.state.table_identifier],
          },
        ],
      };
      TableServiceAPI.updateTable(payload)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Table  updated. ");
            resetForm({ values: "" });
            this.setState(
              { table: null, edit: false, showForm: false, isLoading: false },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchAllDiscounts();
          } else {
            toast.warn(response.data.message);
            resetForm({ values: "" });
            this.setInitalValues();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not update table.. ");
        });
    } else {
      const payload = {
        property_id: this.props.propertyId,
        table_info: [
          {
            end_time: values["end_time"],
            minimum_spend_on_table_reservation:
              values["minimum_spend_on_table_reservation"],
            number_of_person_per_table: values["number_of_person_per_table"],
            reserve_time_slot: values["reserve_time_slot"],
            reserve_time_slot_unit: values["reserve_time_slot_unit"],
            start_time: values["start_time"],
            table_id: this.state.table.table_id,
            table_status: values["table_status"],
            table_identifier: this.state.table_identifier,
            is_bulk: this.state.isBulk,
            table_names: [this.state.table_identifier],
          },
        ],
      };
      TableServiceAPI.updateTable(payload)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Table  updated. ");
            resetForm({ values: "" });
            this.setState(
              { table: null, showForm: false, isLoading: false },
              () => {
                this.setInitalValues();
              }
            );
            this.fetchAllDiscounts();
          } else {
            this.setState({ isLoading: false });
            toast.warn(response.data.message);
            resetForm({ values: "" });
            this.setInitalValues();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not update table.. ");
        });
    }
  };

  editDiscount = (row) => {
    this.setState({ table: row, edit: true, showForm: true }, () => {
      this.setInitalValues();
      toast.success("Table Retrieved.");
    });
  };

  disabledDate = (current) => {
    // Get the current date and time
    const currentDate = new Date();
    // Disable if the current date is after the selected date
    return current && current < currentDate;
  };

  disabledRangeTime = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const currentSecond = new Date().getSeconds();

    // Disable hours that are earlier than the current hour
    const disabledHours = () => {
      const hours = [];
      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
      return hours;
    };

    // Disable minutes that are earlier than the current minute when the current hour is selected
    const disabledMinutes = (selectedHour) => {
      if (selectedHour === currentHour) {
        const minutes = [];
        for (let i = 0; i < currentMinute; i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    };

    // Disable seconds that are earlier than the current second when the current hour and minute are selected
    const disabledSeconds = (selectedHour, selectedMinute) => {
      if (selectedHour === currentHour && selectedMinute === currentMinute) {
        const seconds = [];
        for (let i = 0; i < currentSecond; i++) {
          seconds.push(i);
        }
        return seconds;
      }
      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };

  handleDiscountSubmit = (values, resetForm) => {
    if (!this.state.table) {
      this.addTable(values, resetForm);
      if (this.state.isBulk) {
        toast.success("QR codes are being created. Please wait", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      this.setState({ isLoading: true });
    } else {
      this.updateDiscount(values, resetForm);
      this.setState({ isLoading: true });
    }
  };

  deleteDiscount = (table_id) => {
    let del = {
      /*  property_id: this.props.propertyId, */
      table_id: table_id,
    };
    TableServiceAPI.deleteTable(del)
      .then((response) => {
        if (response.data.status) {
          toast.success("Table  deleted. ");
          this.setState({ open: false });
          this.fetchAllDiscounts();
          this.setState({ table: null, showForm: false }, () => {
            this.setInitalValues();
          });
        } else {
          toast.warn("Couldn't delete. ");
          this.setState({ table: null }, () => {
            this.setInitalValues();
          });
          this.setState({ isLoading: false, open: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, open: false });
        toast.error("Something went wrong. ");
      });
  };

  handleRangeChange = (value) => {
    // Handle the selected range here
    if (value && value.length === 2) {
      const [startDate, endDate] = value.map((date) =>
        moment(date).format("HH:mm")
      );

      this.setState({
        error: "",
        startDate: moment(startDate),
        endDate: moment(endDate),
        defaultRange: [moment(startDate), moment(endDate)],
      });
    }
  };

  handleStartTimeChange = (time) => {
    this.setState({ start_time: time });
  };

  handleEndTimeChange = (time) => {
    this.setState({ end_time: time });
  };

  downloadQRCode = (name) => {
    const canvas = document.getElementById(name)?.querySelector("canvas");
    if (canvas) {
      // Get the current size of the QR code
      const qrCodeSize = canvas.width;

      // Create a new canvas with a larger size to accommodate the border
      const borderedCanvas = document.createElement("canvas");
      const borderedCtx = borderedCanvas.getContext("2d");

      // Set the size of the new canvas including the border
      borderedCanvas.width = qrCodeSize + 20; // 20 is the width of the white border
      borderedCanvas.height = qrCodeSize + 20; // 20 is the height of the white border

      // Draw a white border
      borderedCtx.fillStyle = "#ffffff"; // Set the color of the white border
      borderedCtx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

      // Draw the original QR code on top of the white border
      borderedCtx.drawImage(canvas, 10, 10, qrCodeSize, qrCodeSize); // 10 is the border width

      // Create a download link
      const url = borderedCanvas.toDataURL();
      const a = document.createElement("a");
      a.download = name + ".jpg";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  handleTableOpen = (row) => {
    this.setState({ openTable: true, tableRow: row });
  };

  handleTableClose = () => {
    this.setState({ openTable: false, tableRow: null });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          table: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };
  downloadZipFile = async () => {
    this.setState({ isLoading: true });
    const { tables } = this.state;
    const zip = new JSZip();
    const businessTradingName = localStorage
      .getItem("business_name")
      .replace(/\s+/g, "");
    const folder = zip.folder(`${businessTradingName}TableQrCodes`);

    try {
      const generateQRCodeBase64 = async (url) => {
        try {
          const base64QR = await QRCodeGenerator.toDataURL(url, {
            width: 256,
            margin: 1,
          });
          return base64QR;
        } catch (error) {
          return null;
        }
      };

      await Promise.all(
        tables.map(async (table) => {
          const qrBase64 = await generateQRCodeBase64(table.table_dynamic_link);
          folder.file(`${table.table_identifier}.png`, qrBase64.split(",")[1], {
            base64: true,
          });
        })
      );

      const content = await zip.generateAsync({ type: "blob" });

      saveAs(content, `${businessTradingName}TableQrCodes.zip`);
      toast.success("ZIP file generated and downloaded.");
    } catch (error) {
      toast.error("Failed to generate ZIP file:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      error,
      confirmLoading,
      defaultRange,
      start_time,
      end_time,
      openDialog,
      tables,
      openTable,
      tableRow,
      qr,
      isActive,
      isBulk,
      edit,
      showForm,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                {!showForm && (
                  <React.Fragment>
                    <div className="ms-panel-header">
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Table</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.table ? "Update" : "Add"} Table
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </React.Fragment>
                )}
                <div className="ms-panel-body"></div>
                <div>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Table?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose}>No</Button>
                      <Button
                        onClick={() => this.deleteDiscount(this.state.tableId)}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                    >
                      Plan Expired.
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={this.handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                      <Typography gutterBottom>
                        Your current plan has expired. Please renew your
                        existing plan or upgrade to a new plan.
                      </Typography>
                      {/*  <Typography gutterBottom>
                        You may create upto {qr?.qr_available} dynamic codes using your account. if you require more please upgrade.
                      </Typography> */}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          this.setState({ openDialog: false });
                        }}
                      >
                        No Thanks.
                      </Button>
                      <Button
                        variant="contained"
                        autoFocus
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/pricing",
                          });
                        }}
                      >
                        See Plans and Pricing.
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </div>

                <Dialog
                  open={this.state.openTable}
                  onClose={this.handleTableClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"TABLE QR"}
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body2" color="primary">
                      <a
                        href={tableRow?.table_dynamic_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tableRow?.table_dynamic_link}
                      </a>
                    </Typography>
                    <DialogContentText id="alert-dialog-description">
                      <div id={tableRow?.table_identifier}>
                        <QRCode
                          size={350}
                          /* color="#5773ff" */ errorLevel="H"
                          value={tableRow?.table_dynamic_link}
                          /* bgColor="#fff" */ style={{ marginRight: 16 }}
                        />
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleTableClose}>Close</Button>
                    <Button
                      variant="contained"
                      autoFocus
                      color="primary"
                      onClick={() => {
                        this.downloadQRCode(tableRow?.table_identifier);
                      }}
                    >
                      Download
                    </Button>
                  </DialogActions>
                </Dialog>
                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ margin: "10px" }}
                    >
                      <div style={{}}>
                        Create Table
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details to create a Table.
                    </p>
                    <Divider />
                    <br /> <br />
                    <Formik
                      enableReinitialize="true"
                      initialValues={initialValues}
                      validateOnChange={true}
                      validationSchema={
                        isBulk ? schema.tableSchema : schema.tableBulkSchema
                      }
                      onSubmit={(values, { resetForm }) => {
                        if (start_time && end_time) {
                          if (start_time.isAfter(end_time)) {
                            this.setState({
                              error: "Start time cannot be after end time",
                            });
                            return false;
                          } else {
                            this.setState({ error: "" });
                          }
                        }
                        values["property_id"] = this.props.propertyId;
                        values["start_time"] =
                          this.state.start_time.format("HH:mm:ss");
                        values["end_time"] =
                          this.state.end_time.format("HH:mm:ss");

                        this.handleDiscountSubmit(values, resetForm);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Box sx={{ flexGrow: 1, margin: "15px" }}>
                            <Grid container spacing={3} direction="row">
                              <Grid item xs={6} md={3} lg={3}>
                                <label htmlFor="startTime">
                                  Start Time: &nbsp; &nbsp; &nbsp;{" "}
                                </label>

                                <TimePicker
                                  size="large"
                                  changeOnBlur={true}
                                  showOk={false}
                                  showNow={false}
                                  style={{ height: "50px" }}
                                  value={start_time}
                                  format="HH:mm:ss"
                                  className="custom-time-picker"
                                  allowClear={false}
                                  onCalendarChange={this.handleStartTimeChange}
                                />
                                <FormHelperText error>{error}</FormHelperText>
                              </Grid>

                              <Grid item xs={6} md={3} lg={3}>
                                <label htmlFor="endTime">
                                  End Time: &nbsp; &nbsp; &nbsp;{" "}
                                </label>
                                <TimePicker
                                  size="large"
                                  style={{ height: "50px" }}
                                  changeOnBlur={true}
                                  showOk={false}
                                  showNow={false}
                                  className="custom-time-picker"
                                  format="HH:mm:ss"
                                  onCalendarChange={this.handleEndTimeChange}
                                  value={end_time}
                                />
                              </Grid>

                              <Grid item xs={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                  <TextField
                                    error={
                                      touched.reserve_time_slot &&
                                      errors.reserve_time_slot
                                        ? true
                                        : false
                                    }
                                    name="reserve_time_slot"
                                    label="Reserve Time Slot"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    type="number"
                                    variant="standard"
                                    value={values.reserve_time_slot || ""}
                                    onInput={(event) => {
                                      if (!isNaN(event.target.value)) {
                                        event.target.value = !isNaN(
                                          event.target.value
                                        )
                                          ? event.target.value
                                          : "";
                                      } else {
                                        event.target.value = "";
                                      }
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <FormHelperText error>
                                    {touched.reserve_time_slot &&
                                    errors.reserve_time_slot
                                      ? errors.reserve_time_slot
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                  <InputLabel id="reserve_time_slot_unit">
                                    Time Slot Unit
                                  </InputLabel>
                                  <Select
                                    labelId="reserve_time_slot_unit"
                                    id="reserve_time_slot_unit"
                                    value={values.reserve_time_slot_unit}
                                    label="Time Slot Unit"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "reserve_time_slot_unit",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {slotUnitOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText error>
                                    {touched.reserve_time_slot_unit &&
                                    errors.reserve_time_slot_unit
                                      ? errors.reserve_time_slot_unit
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              {!edit && (
                                <Grid item xs={6} md={3} lg={3}>
                                  <FormControl fullWidth>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={isBulk}
                                          onChange={(e) => {
                                            this.setState({
                                              isBulk: e.target.checked,
                                            });
                                            // handleChange(); // Uncomment if needed
                                            setFieldValue(
                                              "is_bulk",
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      }
                                      label="Is Bulk"
                                    />
                                  </FormControl>
                                </Grid>
                              )}

                              {isBulk && !edit && (
                                <Grid item xs={6} md={3} lg={3}>
                                  <FormControl fullWidth>
                                    <TextField
                                      error={
                                        touched.table_count &&
                                        errors.table_count
                                          ? true
                                          : false
                                      }
                                      name="table_count"
                                      label="Table Count"
                                      type="number"
                                      variant="standard"
                                      InputProps={{ inputProps: { min: 1 } }}
                                      value={values.table_count || ""}
                                      onInput={(event) => {
                                        if (!isNaN(event.target.value)) {
                                          event.target.value = !isNaN(
                                            event.target.value
                                          )
                                            ? event.target.value
                                            : "";
                                        } else {
                                          event.target.value = "";
                                        }
                                      }}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <FormHelperText error>
                                      {touched.table_count && errors.table_count
                                        ? errors.table_count
                                        : null}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}

                              {!isBulk || edit ? (
                                <Grid item xs={6} md={3} lg={3}>
                                  <FormControl fullWidth>
                                    <TextField
                                      error={
                                        touched.table_names &&
                                        errors.table_names
                                          ? true
                                          : false
                                      }
                                      name="table_names"
                                      label="Table Name"
                                      autoComplete="table_names"
                                      type="text"
                                      variant="standard"
                                      pattern="\s*\S+.*"
                                      value={values.table_names || ""}
                                      onChange={(e) => {
                                        setFieldValue("table_names", [
                                          e.target.value,
                                        ]);
                                        this.setState({
                                          table_identifier: e.target.value,
                                        });
                                      }}
                                      aria-describedby="component-error-text"
                                    />
                                    <FormHelperText error>
                                      {touched.table_names && errors.table_names
                                        ? errors.table_names
                                        : null}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              ) : (
                                ""
                              )}

                              <Grid item xs={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                  <TextField
                                    error={
                                      touched.minimum_spend_on_table_reservation &&
                                      errors.minimum_spend_on_table_reservation
                                        ? true
                                        : false
                                    }
                                    name="minimum_spend_on_table_reservation"
                                    label="Minimum Spend on Table Reservation."
                                    type="text"
                                    variant="standard"
                                    value={
                                      values.minimum_spend_on_table_reservation
                                    }
                                    onInput={(event) => {
                                      if (!isNaN(event.target.value)) {
                                        event.target.value = !isNaN(
                                          event.target.value
                                        )
                                          ? event.target.value
                                          : "";
                                      } else {
                                        event.target.value = "";
                                      }
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <FormHelperText error>
                                    {touched.minimum_spend_on_table_reservation &&
                                    errors.minimum_spend_on_table_reservation
                                      ? errors.minimum_spend_on_table_reservation
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6} md={3} lg={3}>
                                <FormControl fullWidth>
                                  <TextField
                                    error={
                                      touched.number_of_person_per_table &&
                                      errors.number_of_person_per_table
                                        ? true
                                        : false
                                    }
                                    name="number_of_person_per_table"
                                    label="No of person per table"
                                    type="number"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="standard"
                                    value={
                                      values.number_of_person_per_table || ""
                                    }
                                    onInput={(event) => {
                                      if (!isNaN(event.target.value)) {
                                        event.target.value = !isNaN(
                                          event.target.value
                                        )
                                          ? event.target.value
                                          : "";
                                      } else {
                                        event.target.value = "";
                                      }
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <FormHelperText error>
                                    {touched.number_of_person_per_table &&
                                    errors.number_of_person_per_table
                                      ? errors.number_of_person_per_table
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              // disabled={this.state.submit}
                              disabled={qr.subscription_status === "EXPIRED"}
                              sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                            >
                              {this.state?.table ? "Update" : "Submit"}
                            </Button>
                            {this.state?.table && (
                              <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                block
                                onClick={() => this.handleCancel()}
                                sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                              >
                                Cancel
                              </Button>
                            )}
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </React.Fragment>
                )}

                {!showForm && (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Grid item lg={3} xs={12}></Grid>
                      <Grid item lg={3} xs={12}></Grid>

                      <Grid
                        item
                        lg={3}
                        xs={12}
                        style={{ marginTop: "4px", textAlign: "right" }}
                      >
                        <TextField
                          label="Search Table"
                          onChange={this.filterOrders}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={1} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            height: "50px",
                            width: "150px",
                            float: "right",
                          }}
                          onClick={this.downloadZipFile}
                        >
                          Download All{" "}
                        </Button>
                      </Grid>
                    </Grid>

                    <div className="ms-invoice-table table-responsive mt-5">
                      <DataTable
                        columns={this.columns}
                        customStyles={this.customStyles}
                        pagination
                        paginationRowsPerPageOptions={[
                          10, 25, 50, 100, 500, 1000,
                        ]}
                        data={tables}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
        <br /> <br />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
