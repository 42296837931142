import axios from 'axios';
import * as APIUrl from '../APIUrl';

export const fetchDashboardSummary = (payload) => {
    try {
        return Promise.all ([
            axios({
            method: 'get',
            url: APIUrl.FETCH_DASHBOARD_SUMMARY + `start_date=${payload.from}&end_date=${payload.to}&property_id=${payload.property_id}`,
        }) ,
        axios({
            method: 'get',
            url: APIUrl.FETCH_ORDER_PROCESSONG_TIME + `start_date=${payload.from}&end_date=${payload.to}&property_id=${payload.property_id}`,
        }) ,
    ])
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


export const fetchItemReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_ITEMS_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const fetchTotalSalesItemReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_TOTAL_ITEMS_SALES_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const topSellingItemReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_TOP_SELLING_ITEMS_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const trendingItemSalesReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_TRENDING_SALES_ITEM_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}&time_interval=${payload.time_interval}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getZReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_Z_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getWeeklyBusinessReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_WWEKLY_BUSINESS_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const downloadWeeklyBusinessReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DOWNLOAD_WWEKLY_BUSINESS_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getPLUSalesReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_PLU_SALES_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const downloadPLUSalesReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DOWNLOAD_PLU_SALES_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const downloadActivityReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DOWNLOAD_ACTIVITY_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}
export const fetchActivityReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_ACTIVITY_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const downloadSummaryReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DOWNLOAD_SUMMARY_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}
export const fetchSummaryReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_SUMMARY_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const downloadItemCustomisedReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DOWNLOAD_ITEM_CUSTOMISED_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const fetchItemCustomisedReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_ITEM_CUSTOMISED_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


export const fetchSoldOutItems = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_SOLD_OUT_ITEMS  + `property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


export const fetchUnSoldItems = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_NON_SELLING_ITEMS_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}
export const fetchdarItems = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.DAR_REPORT + `end_date=${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getDataReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_DATA_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getExcelDataReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_EXCEL_DATA_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
            responseType: 'blob',
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getDogDollarCorrectionReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_DOG_DOLLAR_CORRECTION_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getGrossSalesByStoreReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_GROSS_SALES_BY_STORE_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getPaymentMethdoReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_PAYMENT_METHOD_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}&payment_method=${payload.payment_method}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}

export const getTotalSalesYearlyReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_TOTAL_SALES_YEARLY_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}


export const getCharlieEmailReport = (payload) => {
    try {
       return axios({
            method: 'get',
            url: APIUrl.FETCH_CHARLIE_EMAIL_REPORT + `${payload.end_date}&start_date=${payload.start_date}&property_id=${payload.property_id}`,
        }) 
    } catch (error) {
        console.log("Error in All Order ::", error)
        throw new Error(error)
    }
}