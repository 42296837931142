import React, { Component } from 'react';
import {
    Paper,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
    IconButton,
    Collapse,
    Typography,
    ListItemIcon,
    Box,
    InputLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Edit, Delete, Reorder, ExpandMore, ExpandLess, AddCircle } from '@mui/icons-material';
import { createReason, getReasons, updateReason, deleteReason } from '../../../services/inventory/batchService';
import { Formik, Form, Field } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as InventoryrServiceAPI from '../../../services/inventory/inventoryService';
import Alert from '@mui/material/Alert';
import Breadcrumb from "./Breadcrumb";
import { Divider } from 'antd';
import Loader from "../../../shared/Loader";
const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Reason  is required')
});

class AddBatchReasons extends Component {
    state = {
        reasonInput: '',
        reason: null,
        reasons: [],
        adjustementReasons: [],
        isReasonsOpen: false,
        initialBatchValues: { reason: '', type: 'BATCH' },
        initialAdjustmentValues: { reason: '', type: 'ADJUSTMENT' },
        inventoryPlan: { subscription_status: "ACTIVE" },
        isLoading: false,
        dialogOpen: false,
        deleteReason: null,
        deleteIndex: null,
        deleteType: null
    };

    componentDidMount() {
        this.fetchReasons();
        this.fetchAdjustmentReasons();
        this.fetchInventoryPlan();
    }

    handleReasonChange = (event) => {
        this.setState({ reasonInput: event.target.value });
    };

    fetchReasons = async () => {
        try {
            let payload = {
                property_id: this.props.propertyId,
                type: "BATCH"
            };
            const response = await getReasons(payload);
            if (response && response.status) {
                this.setState({ reasons: response.data?.data });
            } else {
                this.setState({ reasons: [] });
            }
        } catch (error) {
            console.error('Error fetching reasons:', error);
            this.setState({ reasons: [] });
        }
    };

    fetchAdjustmentReasons = async () => {
        try {
            let payload = {
                property_id: this.props.propertyId,
                type: "ADJUSTMENT"
            };
            const response = await getReasons(payload);
            if (response && response.status) {
                this.setState({ adjustementReasons: response.data?.data });
            } else {
                this.setState({ adjustementReasons: [] });
            }
        } catch (error) {
            console.error('Error fetching reasons:', error);
            this.setState({ adjustementReasons: [] });
        }
    };

    handleReasonSubmit = (values, { setSubmitting, resetForm }) => {
        console.log(values);
        setSubmitting(true);
        if (!this.state.initialBatchValues.reason) {
            const payload = { reason: values.reason, property_id: this.props.propertyId, type: "BATCH" };
            createReason(payload).then((response) => {
                if (response && response.data && response.data.status) {
                    toast.success('Reason created successfully.');
                    this.fetchReasons();
                    resetForm();
                } else {
                    toast.warn(`Failed to create reason: ${response.error}`);
                }
                setSubmitting(false);
            }).catch((error) => {
                console.error(error);
                setSubmitting(false);
                toast.error(`Failed to create reason: ${error}`);
            });
        } else {
            const payload = { reason: values.reason, reason_id: this.state.reason?.reason_id };
            updateReason(payload).then((response) => {
                if (response && response.data && response.data.status) {
                    toast.success('Reason updated successfully.');
                    this.fetchReasons();
                    resetForm();
                    this.setState({ initialBatchValues: { reason: '', type: 'BATCH' } });
                    this.setState({ reason: null });
                } else {
                    toast.warn(`Failed to create reason: ${response.error}`);
                }
                setSubmitting(false);
            }).catch((error) => {
                console.error(error);
                setSubmitting(false);
                toast.error(`Failed to create reason: ${error}`);
            });

        }

    };

    handleAdjustmentReasonSubmit = (values, { setSubmitting, resetForm }) => {
        console.log(values);
        setSubmitting(true);
        if (!this.state.initialAdjustmentValues.reason) {
            const payload = { reason: values.reason, property_id: this.props.propertyId, type: "ADJUSTMENT" };
            createReason(payload).then((response) => {
                if (response && response.data && response.data.status) {
                    toast.success('Reason created successfully.');
                    this.fetchAdjustmentReasons();
                    resetForm();
                } else {
                    toast.warn(`Failed to create reason: ${response.error}`);
                }
                setSubmitting(false);
            }).catch((error) => {
                console.error(error);
                setSubmitting(false);
                toast.error(`Failed to create reason: ${error}`);
            });
        } else {
            const payload = { reason: values.reason, reason_id: this.state.reason?.reason_id };
            updateReason(payload).then((response) => {
                if (response && response.data && response.data.status) {
                    toast.success('Reason updated successfully.');
                    this.fetchAdjustmentReasons();
                    resetForm();
                    this.setState({ initialAdjustmentValues: { reason: '', type: 'ADJUSTMENT' } });
                    this.setState({ reason: null });
                } else {
                    toast.warn(`Failed to create reason: ${response.error}`);
                }
                setSubmitting(false);
            }).catch((error) => {
                console.error(error);
                setSubmitting(false);
                toast.error(`Failed to create reason: ${error}`);
            });

        }

    };

    toggleReasons = () => {
        this.setState((prevState) => ({
            isReasonsOpen: !prevState.isReasonsOpen,
        }));
    };

    handleEdit = (reason, index, type) => {
        console.log(`Edit ${type} reason at index ${index}:`, reason);
        this.setState({ reason: reason });
        if (type === "reasons") {
            this.setState(prevState => ({
                initialBatchValues: { reason: reason.reason, type: "BATCH" }
            }));
        }

        if (type === "adjustementReasons") {
            this.setState(prevState => ({
                initialAdjustmentValues: { reason: reason.reason, type: "ADJUSTMENT" }
            }));
        }

    };

    handleDelete = () => {
        const {deleteType, deleteIndex} = this.state;
        console.log(`Delete ${deleteType} reason at index ${deleteIndex}:`, deleteReason);
        /*       this.setState(prevState => {
                  const newReasons = [...prevState[type]];
                  newReasons.splice(index, 1);
                  return { [type]: newReasons };
              }); */

        deleteReason(this.state.deleteReason?.reason_id).then((response) => {
            if (response && response.data && response.data.status) {
                toast.success('Reason deleted successfully.');
                this.fetchReasons();
                this.fetchAdjustmentReasons();
                this.setState({ dialogOpen: false,  deleteReason: null, deleteIndex: null, deleteType: null });
            } else {
                toast.warn(`Failed to delete reason: ${response.error}`);
            }
        }).catch((error) => {
            console.error(error);
            toast.error(`Failed to delete reason: ${error}`);
        });
    };

    fetchInventoryPlan = async () => {
        this.setState({ isLoading: true });
        const dump = this.props.propertyId;
        try {
            const response = await InventoryrServiceAPI.fetchInventoryPlan(dump);
            if (response.data.status) {
                if (response.data?.data) {
                    this.setState(
                        {
                            inventoryPlan: response.data.data,
                        },
                        () => {
                            this.setState({ isLoading: false });
                        }
                    );
                }
            } else {
                const err = response.data?.message || "Something went wrong.";
                this.setState({ inventoryPlan: {}, isLoading: false });
            }
        } catch (error) {
            const err = "Something went wrong.";
            this.setState({ inventoryPlan: {}, isLoading: false });
            toast.warn(err);
            throw error;
        }
    };

    handleDialogOpen = (reason, index, type) => {
        this.setState({ dialogOpen: true, deleteReason: reason, deleteIndex: index, deleteType: type});
    };

    handleDialogClose = () => {
        this.setState({ dialogOpen: false,  deleteReason: null, deleteIndex: null, deleteType: null });
    };

    render() {
        const { reasons, adjustementReasons, isReasonsOpen, initialBatchValues, initialAdjustmentValues, inventoryPlan, dialogOpen } = this.state;

        return (
            this.state.isLoading ? (
                <div
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "40px",
                    }}
                >
                    {" "}
                    <Loader size={50} />{" "}
                </div>
            ) : (
                <React.Fragment>
                    {(inventoryPlan?.subscription_status === "ACTIVE" || inventoryPlan?.subscription_status === "CANCELLED") ?
                        (<React.Fragment>
                            <Breadcrumb />
                            <Paper sx={{ padding: 3 }}>
                                <ListItem button onClick={this.toggleReasons} sx={{ mt: 5 }}>
                                    <ListItemIcon>
                                        <AddCircle sx={{ fontSize: 40, color: 'primary.main' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Typography variant="body1" fontWeight="bold">
                                            Add Batch Reasons
                                        </Typography>
                                    } />
                                    {isReasonsOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Typography variant="body1" sx={{ ml: 9 }}>
                                    From this setting, you can enter the reasons for adjusting and adding batch in inventory management.
                                </Typography>
                                <Collapse in={isReasonsOpen} sx={{ mt: 5 }}>

                                    <Box >
                                        <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
                                            Add Batch Reasons
                                        </Typography>
                                        <Formik
                                            enableReinitialize
                                            initialValues={initialBatchValues}
                                            validationSchema={validationSchema}
                                            onSubmit={this.handleReasonSubmit}
                                        >
                                            {({ errors, touched, values, resetForm }) => (
                                                <Form>
                                                    <Grid container spacing={3} alignItems="center">
                                                        {/*  <Grid item xs={3}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="type-label">Type</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        name="type"
                                                        labelId="type-label"
                                                        id="type"
                                                        fullWidth
                                                        label="Type"                                            >
                                                        <MenuItem value="BATCH">BATCH</MenuItem>
                                                        <MenuItem value="ADJUSTMENT">ADJUSTMENT</MenuItem>
                                                    </Field>
                                                </FormControl>

                                            </Grid> */}
                                                        <Grid item lg={10} xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                name="reason"
                                                                label="Enter reason description"
                                                                fullWidth
                                                                error={touched.reason && Boolean(errors.reason)}
                                                                helperText={touched.reason && errors.reason}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={2} xs={12}>
                                                            <Button type="submit" sx={{ width: 180, padding: 2 }} variant="contained" color="primary">
                                                                {this.state.initialBatchValues?.reason ? 'Update' : 'Add'}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                        {reasons?.length > 0 &&
                                            <Box mb={3} sx={{ mt: 3, border: '2px solid #e0e0e0', padding: 2 }}>
                                                <Typography variant="h6" component="div" gutterBottom>
                                                    Batch Reasons
                                                </Typography>
                                                <List>
                                                    {reasons?.map((reason, index) => (
                                                        <React.Fragment>
                                                            <ListItem
                                                                key={index}
                                                                secondaryAction={
                                                                    <>
                                                                        <IconButton edge="end" aria-label="edit" sx={{ fontSize: 25, color: 'primary.main' }} onClick={() => this.handleEdit(reason, index, 'reasons')}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        &nbsp; &nbsp;
                                                                        <IconButton edge="end" aria-label="delete" sx={{ fontSize: 25, color: 'red' }} onClick={() => this.handleDialogOpen(reason, index, 'reasons')}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            >
                                                                <ListItemIcon>
                                                                    <div style={{
                                                                        width: 12,
                                                                        height: 12,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: 'black',
                                                                        marginRight: 8,
                                                                    }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={reason?.reason} />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>

                                                    ))}
                                                </List>

                                            </Box>
                                        }


                                        <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
                                            Add Adjustment Reasons
                                        </Typography>
                                        <Formik
                                            enableReinitialize
                                            initialValues={initialAdjustmentValues}
                                            validationSchema={validationSchema}
                                            onSubmit={this.handleAdjustmentReasonSubmit}
                                        >
                                            {({ errors, touched, values, resetForm }) => (
                                                <Form>
                                                    <Grid container spacing={3} alignItems="center">
                                                        {/*  <Grid item xs={3}>
                                                <FormControl margin="normal" fullWidth>
                                                    <InputLabel id="type-label">Type</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        name="type"
                                                        labelId="type-label"
                                                        id="type"
                                                        fullWidth
                                                        label="Type"                                            >
                                                        <MenuItem value="BATCH">BATCH</MenuItem>
                                                        <MenuItem value="ADJUSTMENT">ADJUSTMENT</MenuItem>
                                                    </Field>
                                                </FormControl>

                                            </Grid> */}
                                                        <Grid item lg={10} xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                name="reason"
                                                                label="Enter reason description"
                                                                fullWidth
                                                                error={touched.reason && Boolean(errors.reason)}
                                                                helperText={touched.reason && errors.reason}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={2} xs={12}>
                                                            <Button type="submit" sx={{ width: 180, padding: 2 }} variant="contained" color="primary">
                                                                {this.state.initialAdjustmentValues?.reason ? 'Update' : 'Add'}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                        {adjustementReasons?.length > 0 &&
                                            <Box mb={3} sx={{ mt: 3, border: '2px solid #e0e0e0', padding: 2 }}>
                                                <Typography variant="h6" component="div" gutterBottom>
                                                    Adjustment Reasons
                                                </Typography>
                                                <List>
                                                    {adjustementReasons?.map((reason, index) => (
                                                        <React.Fragment>
                                                            <ListItem
                                                                key={index}
                                                                secondaryAction={
                                                                    <>
                                                                        <IconButton edge="end" aria-label="edit" sx={{ fontSize: 25, color: 'primary.main' }} onClick={() => this.handleEdit(reason, index, 'adjustementReasons')}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        &nbsp; &nbsp;
                                                                        <IconButton edge="end" aria-label="delete" sx={{ fontSize: 25, color: 'red' }} onClick={() => this.handleDialogOpen(reason, index, 'adjustementReasons')}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            >
                                                                <ListItemIcon>
                                                                    <div style={{
                                                                        width: 12,
                                                                        height: 12,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: 'black',
                                                                        marginRight: 8,
                                                                    }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={reason?.reason} />
                                                            </ListItem>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}
                                                </List>

                                            </Box>
                                        }
                                    </Box>
                                </Collapse>
                            </Paper>

                            <Dialog
                                open={dialogOpen}
                                onClose={this.handleDialogClose}
                            >
                                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this item?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleDelete} color="primary" autoFocus>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>)
                        : <Alert severity="warning">Please activate inventory service.</Alert>}
                </React.Fragment>)



        );
    }
}


const mapStateToProps = (state) => ({
    propertyId: state.user.propertyId,
});


const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(AddBatchReasons);