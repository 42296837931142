import * as React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    CardActions,
    TextField,
    Grid
} from '@mui/material';
import {  makeStyles } from "@mui/styles";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Jumbo from '../../../assets/img/apex.jpg'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import mpos from '../../../assets/img/mpos.png';
const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    },
    bronzeCard: {
        borderRadius: '10px', // Adjust the value to achieve the desired shape
        //   border: '2px solid #5773ff', // Bronze color
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
    },
    model: {
        color: "grey",
        fontSize: "14px"
    },
    media: {
        width: '350px',
        height: '350px',
        backgroundSize: "contain",
        padding: "5px"
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    quantityField: {
        // marginBottom: theme.spacing(1),
        width: "100px"
    },
    addButton: {
        marginTop: 'auto',
        //  marginLeft: theme.spacing(5),
        //  color: "white",
        borderRadius: '10px'
    },
    price: {
        marginTop: '20px;',
        padding: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "20px",
        fontWeight: 600,
        marginTop: "40px"
    },
    block_price__currency: {
        padding: 0,
        margin: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600
    },
    title: {
        position: "relative", // Set the parent element to a relative position
        padding: "10px", // Add some padding to the element

        "&::before": {
            content: "''", // Add content to the pseudo-element
            position: "absolute",
            bottom: "0",
            width: "40%", // Adjust the width as needed
            borderBottom: "5px solid #5773ff",
        }
    }

}));

const price = {
    marginTop: '20px;',
    padding: 0,
    verticalAlign: "top",
    color: "#5773ff",
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "40px"
};

const title = {
    position: "relative", // Set the parent element to a relative position
    padding: "10px", // Add some padding to the element

    "&::before": {
        content: "''", // Add content to the pseudo-element
        position: "absolute",
        bottom: "0",
        width: "40%", // Adjust the width as needed
        borderBottom: "5px solid #5773ff",
    }
};

const ShopItemDetails = ({ product }) => {
    const classes = useStyles();
    return (

        <div>

            <Grid container spacing={4}>
                {/* Main Image and Small Images */}
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item lg={4}>
                            <Card style={{ padding: "20px" }} >
                                <CardMedia component="img" alt="Main Product Image" src={mpos} />
                            </Card>
                            <br />

                            <Grid container spacing={2} style={{ padding: "10px" }}>
                                {[1, 2, 3, 4].map((index) => (
                                    <Grid key={index} item lg={3} md={3} sm={6} xs={6} >
                                        <Card style={{ padding: "10px" }}>
                                            <CardMedia component="img" alt={`Small Image ${index}`} src={mpos} />
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        </Grid>
                        <Grid item lg={8}>
                            <Typography gutterBottom variant="h4" component="div" className={title}>
                                16 inch wall mount kiosk
                            </Typography>
                            <p>
                                Peak performance with active noise cancelation. Sennheisers new MOMENTUM Wireless - Closed circumauralheadphone featuring Bluetooth® wireless technology and NoiseGard Hybrid active noise cancelation
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Product Description on the Right */}
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card>
                        <CardContent>
                            <Grid display="flex" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography gutterBottom variant="h5" component="div" style={{ color: "#5773ff" }} >
                                        <b>Offer Price</b>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        gutterBottom variant="h5" component="div" style={{ color: "#5773ff" }}
                                    >
                                        <b> £  100 </b>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <br />

                            <Grid display="flex" justifyContent="space-between" alignItems="center" style={{ background: "rgb(87 115 255 / 18%)", }}>
                                <Grid item lg={12}>
                                    <Typography gutterBottom variant="h6" component="div" style={{ color: "#5773ff", textAlign: "center", padding: "4px" }} >
                                        <b>Standard delivery by 27 November</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br /><br />
                            <Grid container display="flex" justifyContent="space-between" alignItems="center">
                                <Grid item lg={12}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        value={4}
                                        onChange={(e) => console.log("")}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        sx={{
                                                            borderRadius: '50%',
                                                            backgroundColor: '#5773ff', // Adjust the background color as needed
                                                            color: 'white'
                                                        }}

                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        sx={{
                                                            borderRadius: '50%',
                                                            backgroundColor: '#5773ff', // Adjust the background color as needed
                                                            color: 'white'
                                                        }}

                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            "& input": {
                                                textAlign: 'center',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <br /><br />
                            <Grid container display="flex" justifyContent="space-between" alignItems="center">
                                <Grid item lg={12}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Add to Cart
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </div>

    );
}
export default ShopItemDetails;