import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../shared/Loader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FormDialog from "./dialogs/CopyDialog";
import Avatar from "@mui/material/Avatar";
import {
  setMenuNames,
  setItems,
  setCombos,
  setComboItems,
  setCategoryNames,
  setSelectedCategory,
  setSelectedMenu,
  setCurrentItemExtras,
  setCurrentItemOptions,
  setCategoriesForExtras,
  setCategoriesForOptions,
} from "../../../redux/menu/menuSlice";
import {
  fetchMenuNames,
  deleteMenu,
  fetchMenu,
  syncMenu
} from "../../../services/product/menu/MenuServiceAPI";
import {
  fetchCategoryNames,
  deleteCategory,
  fetchCategory,
} from "../../../services/product/category/CategoryServiceAPI";
import {
  fetchItem,
  deleteItem,
  fetchItemByItemId,
  fetchExtrasOptionsByItemId,
  fetchUpsellItems,
} from "../../../services/product/item/ItemServiceAPI";
import { fetchAccountStatus } from "../../../services/payment/PaymentServiceAPI";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import "./menu.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import AddEditMenu from "./AddEditMenu";
import AddEditItem from "./AddEditItem";
import AddEditCategory from "./AddEditCategory";
import { deleteConfirm } from "../../../shared/DeleteConfirm";
import {
  fetchAllAddonCategory,
  fetchAllCombos,
  fetchAllComboItems,
} from "../../../services/product/addonCategory/AddonCategoryServiceAPI";
import { toast } from "react-toastify";
import $ from "jquery";
import ReactGA from "react-ga";
import OutlinedInput from "@mui/material/OutlinedInput";
import LinkIcon from "@mui/icons-material/Link";
import { getCurrencySymbol } from "../../../config";
import MenuSyncComponent from "./MenuSyncComponent";
const avatarStyle = {
  color: "grey",
};

class MenuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    /*    if (props.index == 0) {
         this.props.editItem(this.props.item_id)
       } */
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <>
        <div className="root">
          <Grid item xs={12} md={12}>
            <Paper className="paper" style={{ padding: 5 }}>
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                <Grid item xs={2} md={2} lg={1}>
                  <Avatar sx={{ width: 32, height: 32, fontSize: "1rem" }}>
                    {this.props.item_sort_order}
                  </Avatar>
                </Grid>
                <Grid item xs={2} md={2} lg={1}>
                  <img
                    src={this.props.image}
                    style={{ objectFit: "contain" }}
                    className="image"
                  />
                </Grid>
                <Grid
                  xs={8} md={7} lg={5}
                  item
                  container
                  alignItems="center"
                  sx={{ display: "flex" }}
                >
                  <Typography>{this.props.identifier}</Typography>
                  <Tooltip title="Linked Item">
                    <Typography sx={{ ml: 1 }}>
                      {this.props.linked ? (
                        <LinkIcon color="primary" fontSize="medium" />
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid
                  xs={6} md={6} lg={2}
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Chip
                    label={
                      getCurrencySymbol(localStorage.getItem("currency_code")) +
                      this.props.price
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={6} md={6} lg={3}
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    variant="text"
                    aria-label="horizontal outlined primary button group"
                  >
                    {!this.props.settings?.is_eposnow_enabled &&
                      <Tooltip title="Delete item" placement="top">
                        <Button
                          color="action"
                          size="small"
                          onClick={() =>
                            deleteConfirm({
                              name: this.props.identifier,
                              id: this.props.item_id,
                              message: "Item deleted.",
                              deleteFunc: deleteItem,
                              setSelectedCategory: this.props.setSelectedCategory,
                              categoryId: this.props.categoryId,
                              func: [
                                this.props.fetchCategoryNames,
                                this.props.fetchItems,
                              ],
                            })
                          }
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip title="Edit item" placement="top">
                      <Button
                        color="action"
                        size="small"
                        onClick={() => this.props.editItem(this.props.item_id)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    {!this.props.settings?.is_eposnow_enabled &&
                      <FormDialog
                        itemId={this.props.item_id}
                        item={this.props.item}
                        fetchCategoryNames={this.props.fetchCategoryNames}
                        fetchItems={this.props.fetchItems}
                      />
                    }
                    
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </>
    );
  }
}

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };

    if (this.props.selectedCategory) {
      //this.props.fetchItemsIndividually(this.props.selectedCategory);
      this.state = {
        expanded: this.props.selectedCategory
          ? this.props.selectedCategory
          : false,
      };
    }

    if (this.props.expanded == this.props.item.category_id) {
      this.props.fetchItemsIndividually(this.props.selectedCategory);
    }

  }

  /*  handleAccordionChange = (panel) => (event, isExpanded) => {
     if (isExpanded) {
       this.setState({ expanded: panel });
     } else {
       this.setState({ expanded: false });
     }
     this.props.fetchItemsIndividually(panel);
   }; */

  render() {
    const { expanded } = this.state;
    return (
      <>
        <Accordion
          square
          key={this.props.item.category_id}
          expanded={this.props.expanded == this.props.item.category_id}
          onChange={this.props.handleAccordionChange(
            this.props.item.category_id
          )}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={this.props.item.category_id}
            id={this.props.item.category_id}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6} md={6} lg={4} style={{ zIndex: 100 }}>
              { !this.props.settings?.is_eposnow_enabled &&
                <ButtonGroup
                  size="small"
                  orientation="horizontal"
                  color="primary"
                  variant="text"
                  aria-label="horizontal outlined primary button group"
                >
                  <Tooltip title="Delete catagory" placement="top">
                    <Button
                      color="action"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (this.props.categoryNames) {
                          let catNames = this.props.categoryNames.filter(
                            (category) => {
                              return (
                                category.category_id ==
                                this.props.item.category_id
                              );
                            }
                          );
                          if (catNames[0].total_item_count > 0) {
                            toast.warn(
                              "Please remove the items inside category first."
                            );
                            return;
                          }
                        }
                        deleteConfirm({
                          name: this.props.item.category_identifier,
                          id: this.props.item.category_id,
                          deleteFunc: deleteCategory,
                          text: "You won't be able to revert this.",
                          message: "Category deleted.",
                          func: [
                            () => this.props.fetchMenuNames(this.props.selectedMenu),
                            this.props.fetchCategoryNames,
                          ],
                        });
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit catagory" placement="top">
                    <Button
                      color="action"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.editCategory(this.props.item);
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add item" placement="top">
                    <Button
                      color="action"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.setSelectedCategory(
                          this.props.item.category_id,
                          "Add Item"
                        );
                      }}
                    >
                      <AddBoxIcon />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
                }
              </Grid>
              <Grid item xs={6} md={6} lg={!this.props.settings?.is_eposnow_enabled ? 8 : 12}>
                <Typography
                  className="heading"
                  style={{ wordWrap: "break-word" }}
                >
                  {this.props.item.category_identifier}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container direction="column" alignItems="stretch">
              {this.props.isLoading ? (
                <Loader size={50} />
              ) : this.props.items ? (
                this.props.items.map((menuObject, index) => (
                  <div key={index}>
                    {Object.keys(menuObject).map(
                      (categoryId, categoryIndex) => (
                        <div key={categoryIndex}>
                          {/* Check if menuObject[categoryId] is an array before mapping */}
                          {Array.isArray(menuObject[categoryId]) &&
                            menuObject[categoryId].map(
                              (menuItem, menuItemIndex) => (
                                <MenuList
                                  key={menuItem.item_id}
                                  identifier={menuItem.item_identifier}
                                  image={menuItem.item_image}
                                  price={menuItem.item_cost}
                                  item_id={menuItem.item_id}
                                  fetchItems={this.props.fetchItems}
                                  editItem={this.props.editItem}
                                  fetchCategoryNames={
                                    this.props.fetchCategoryNames
                                  }
                                  item_sort_order={menuItem.item_sort_order}
                                  categoryId={this.props.item.category_id}
                                  setSelectedCategory={
                                    this.props.setSelectedCate
                                  }
                                  item={menuItem}
                                  index={index}
                                  fetchMenuNames={this.props.fetchMenuNames}
                                  linked={menuItem.is_linked}
                                />
                              )
                            )}
                        </div>
                      )
                    )}
                  </div>
                ))
              ) : (
                <Alert severity="warning">
                  No item found for this category. Click on Add to add new
                </Alert>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isItemLoading: true,
      isFetching: false,
      selectedMenu: "",
      openAddMenu: false,
      openEditMenu: false,
      openAddCategory: false,
      openEditCategory: false,
      openAddItem: false,
      openEditItem: false,
      menuToEdit: null,
      categoryToEdit: null,
      itemToEdit: null,
      tab: 0,
      selectedCategory: null,
      accNotConfirm: false,
      personNames: [],
      upSellItemMap: {},
      expanded: null,
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.getAccountStatus();
    this.fetchMenuNames();
    this.fetchUpsellItems(this.props.property_id);

    let pathArray = window.location.pathname.split("/");
    let currentPage = pathArray[1];
    if (currentPage !== "payout" || currentPage !== "transactions") {
    }

    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
  }

  fetchUpsellItems = async (propId) => {
    try {
      let res = await fetchUpsellItems(propId);
      let responseUpsellItems = [];
      if (res.status && res.data.data) {
        this.setState({ personNames: res.data.data.item_response_bean_list });

        const upSellItemMap = new Map();
        this.state.personNames.map((item) => {
          upSellItemMap.set(item.item_id, item.item_identifier);
        });


        this.setState({
          upSellItemMap: upSellItemMap,
        });

        /*   this.state.names.map(item => {
            this.setState({
              personNames: item.item_identifier,
            }
            );
          }) */
      }

      if (!res.status) {
        toast.warn("Could not get item filters");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  getAccountStatus = async () => {
    if (!this.props.property_id) {
      this.setState({ accNotConfirm: true });
    } else {
      this.setState({ accNotConfirm: false });
    }
  };

  fetchMenuNames = async (menu) => {
    try {
      let menus = await fetchMenu(this.props.property_id);
      if (menus.data.status) {
        let sorted_menus = menus.data.data.menu_response_bean_list.sort(
          (a, b) => a.menu_sort_order - b.menu_sort_order
        );
        this.props.setMenuNames(sorted_menus);
        const result = menu
          ? this.props.menuNames.filter(
              (menuId) => menuId.menu_identifier === menu || menuId.menu_id === menu
            )
          : this.props.menuNames;
        let selMenu = this.props?.history?.location?.state?.order?.menu_id
          ? this.props?.history?.location?.state?.order?.menu_id
          : this.props.selectedMenu || result[0]?.menu_id;
        // Check if selectedMenu exists in sorted_menus
        if (!sorted_menus.some(menu => menu.menu_id === selMenu)) {
          selMenu = null; // If not found, set selectedMenu to null
        }
        this.setState(
          { selectedMenu: selMenu || sorted_menus[0]?.menu_id },
          () => {
            this.fetchCategoryNames();
            this.props.setSelectedMenu(selMenu);
            if (this.props?.history?.location?.state?.order?.item_id) {
              this.setState({
                itemToEdit: this.props?.history?.location?.state?.order?.item_id,
              });
              this.editItem(
                this.props?.history?.location?.state?.order?.item_id
              );
            }
          }
        );
      } else {
        this.props.setMenuNames(null);
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log("error getting menu names", e);
    }
  };
  

  handleQuickAdd = (section) => {
    switch (section) {
      case "Add Menu":
        this.setState({
          openAddMenu: true,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: false,
        });
        break;
      case "Edit Menu":
        this.setState({
          openAddMenu: false,
          openEditMenu: true,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: false,
        });
        break;
      case "Add Category":
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: true,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: false,
        });
        break;
      case "Edit Category":
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: true,
          openAddItem: false,
          openEditItem: false,
        });
        break;
      case "Add Item":
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: true,
          openEditItem: false,
        });
        break;
      case "Edit Item":
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: true,
        });
        break;
      case "Close":
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: false,
          selectedCategory: null,
          menuToEdit: null,
          categoryToEdit: null,
          itemToEdit: null,
        });
      default:
        this.setState({
          openAddMenu: false,
          openEditMenu: false,
          openAddCategory: false,
          openEditCategory: false,
          openAddItem: false,
          openEditItem: false,
          selectedCategory: null,
          menuToEdit: null,
          categoryToEdit: null,
          itemToEdit: null,
        });
    }
  };

  fetchCategoryNames = async (menuId) => {
    try {
      this.setState({ isLoading: true });
      this.props.setCategoryNames(null);
      this.props.setItems(null);
      let categories = await fetchCategory(
        menuId ? menuId : this.state.selectedMenu
      );

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        this.setState({ isLoading: false });
        return;
      } else {
        let sorted_categories =
          categories.data.data.category_response_bean_list.sort(
            (a, b) => a.item_sort_order - b.item_sort_order
          );
        if (menuId) {
          this.setState({ selectedMenu: menuId });
          this.props.setSelectedMenu(menuId);
        }
        this.props.setCategoryNames(sorted_categories);
        this.setState({ isLoading: false });
        //this.fetchItems();
        //this.fetchItemsIndividually(this.state.selectedCategory);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log("error", e);
    }
  };

  fetchItems = async () => {
    try {
      let allItems = [];
      this.setState({ isLoading: true });
      for (const [key, value] of Object.entries(this.props.categoryNames)) {
        let items = await fetchItem(value.category_id);
        let item = {};
        item["category_identifier"] = value.category_identifier;
        item["category_id"] = value.category_id;
        if (items.data.data.item_response_bean_list) {
          let sorted = items.data.data.item_response_bean_list.sort(
            (a, b) => a.item_sort_order - b.item_sort_order
          );
          item["menu"] = sorted;
        } else {
          item["menu"] = null;
        }

        allItems.push(item);
      }
      this.props.setItems(allItems);
      this.setState({ isLoading: false });
      if (!this.props?.history?.location?.state?.order) {
        this.handleQuickAdd("Close");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  handleMenuChange = (event) => {
    this.setState({ isLoading: true, selectedMenu: event.target.value }, () => {
      this.props.setItems(null);
      this.props.setCategoryNames(null);
      this.fetchCategoryNames();
      this.props.setSelectedMenu(event.target.value);
      this.handleQuickAdd("close");
    });
  };

  fetchItemsIndividually = async (categoryId) => {
    try {
      this.props.setItems(null);
      this.setState({ isItemLoading: true });
      let items = await fetchItem(categoryId);
      let item = items.data.data.item_response_bean_list || null;

      if (item) {
        item.sort((a, b) => a.item_sort_order - b.item_sort_order);
      }

      let itemsByCategory =
        item?.reduce((acc, item) => {
          const { category_id } = item;
          acc[category_id] = acc[category_id] || [];
          acc[category_id].push(item);
          return acc;
        }, {}) || null;

      if (!itemsByCategory) {
        this.props.setItems(null);
      } else {
        this.props.setItems([itemsByCategory]);
      }

      this.setState({ isItemLoading: false });

      if (!this.props?.history?.location?.state?.order) {
        this.handleQuickAdd("Close");
      }
    } catch (e) {
      console.log("error", e);
      this.props.setItems(null);
      this.setState({ isItemLoading: false });
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  editMenu = async () => {
    this.handleQuickAdd("Close");
    this.setState({ isFetching: true });
    let allMenu = await fetchMenu(this.props.property_id);
    let currentMenu = allMenu.data.data.menu_response_bean_list.filter(
      (menu) => {
        return menu.menu_id == this.state.selectedMenu;
      }
    );
    this.setState({ menuToEdit: currentMenu[0], isFetching: false }, () => {
      this.handleQuickAdd("Edit Menu");
    });
  };

  editCategory = async (item) => {
    this.handleQuickAdd("Close");
    this.setState({ isFetching: true });
    let category = await fetchCategory(this.state.selectedMenu);
    this.props.setSelectedMenu(this.state.selectedMenu);
    let currentCategory = category.data.data.category_response_bean_list.filter(
      (category) => {
        return category.category_id == item.category_id;
      }
    );
    this.setState(
      { categoryToEdit: currentCategory[0], isFetching: false },
      () => {
        this.handleQuickAdd("Edit Category");
      }
    );
  };

  editItem = async (itemID) => {
    this.handleQuickAdd("Close");
    this.setState({ isFetching: true }, () => {});
    let item = await fetchItemByItemId(itemID);
    this.setState({ itemToEdit: item.data.data, isFetching: false }, () => {
      this.fetchAllAddons(this.state.itemToEdit?.item_id);
      this.fetchAllCombos(this.state.itemToEdit?.item_id);
      this.handleQuickAdd("Edit Item");
    });
  };

  flattenData = (data) => {
    let flat = [];
    for (let category of data) {
      if (category["addons"]) {
        for (let addon of category["addons"]) {
          // addon["addon_category_identifier"] = category.category_identifier;
          flat = [...flat, addon];
        }
      }
    }

    return flat;
  };

  fetchAllAddons = async (item_id = this.state.itemToEdit?.item_id) => {
    try {
      let res = await fetchAllAddonCategory(item_id);
      if (res.data.status) {
        if (res.data.data) {
          let filteredOptions = res.data.data.filter((category) => {
            return category.category_type === "OPTIONS";
          });
          let filteredExtras = res.data.data.filter((category) => {
            return category.category_type === "EXTRAS";
          });
          this.props.setCategoriesForExtras(filteredExtras);
          this.props.setCategoriesForOptions(filteredOptions);
        } else {
          this.props.setCategoriesForExtras(null);
          this.props.setCategoriesForOptions(null);
        }
      } else {
        toast.warn("Could not fetch addon categories");
        this.setState({ fetchingData: false });
      }

      let allAddonsRes = await fetchExtrasOptionsByItemId(item_id);
      if (allAddonsRes.data.status) {
        let extras = allAddonsRes.data.data.filter(
          (addon) => addon.addon_type == "EXTRAS"
        );
        let options = allAddonsRes.data.data.filter(
          (addon) => addon.addon_type == "OPTIONS"
        );

        let allExtras = [];
        let allOptions = [];

        if (extras.length > 0 && extras[0]["addon_category"].length > 0) {
          allExtras = this.flattenData(extras[0]["addon_category"]);
        }
        if (options.length > 0 && options[0]["addon_category"].length > 0) {
          allOptions = this.flattenData(options[0]["addon_category"]);
        }

        if (allExtras.length > 0) {
          this.props.setCurrentItemExtras(allExtras);
        } else {
          this.props.setCurrentItemExtras([]);
        }

        if (allOptions.length > 0) {
          this.props.setCurrentItemOptions(allOptions);
        } else {
          this.props.setCurrentItemOptions([]);
        }
      } else {
        this.props.setCurrentItemExtras([]);
        this.props.setCurrentItemOptions([]);
      }
    } catch (e) {
      console.log(e);
      toast.error("Error fetching addons or addon categories");
    }
  };

  fetchAllCombos = async (item_id = this.state.itemToEdit?.item_id) => {
    try {
      let res = await fetchAllCombos(item_id);
      if (res.data.status) {
        if (res.data.data) {
          this.props.setCombos(res.data.data);
          //   this.props.setCategoriesForOptions(filteredOptions);
        } else {
          this.props.setCombos(null);
          //  this.props.setCategoriesForOptions(null);
        }
      } else {
        // toast.warn("Could not fetch combos");
        this.props.setCombos(null);
        this.setState({ fetchingData: false });
      }

      let allComboItems = await fetchAllComboItems(item_id);
      if (allComboItems.data.status) {
        const outputArray = allComboItems.data.data.flatMap(
          ({ combo_item_list, combo_group_id }) =>
            (combo_item_list || []).map((item) => ({ ...item, combo_group_id }))
        );

        this.props.setComboItems(outputArray);
      } else {
        this.props.setComboItems([]);
      }
    } catch (e) {
      console.log(e);
      //  toast.error("Error fetching Combos");
    }
  };

  setSelectedCategory = (id, disp) => {
    this.handleQuickAdd("Close");
    this.setState({ selectedCategory: id }, () => {
      this.handleQuickAdd(disp);
    });
  };

  handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
    this.props.setSelectedCategory(panel);
    this.fetchItemsIndividually(panel);
  };

  checkShowSyncComponnet = () => {
    console.log(this?.props?.menuNames)
    return !this?.props?.menuNames  && this.props.settings?.is_eposnow_enabled;
  }

  handleSyncMenu = async () => {
    this.setState({isLoading: true});
    try {

      const response = await syncMenu(this.props.property_id);
      if(response.status){
        console.log('Menu sync in progress:', response);
        toast.success("Menu sync in progress");
        setTimeout(()=> {window.location.reload();  this.setState({isLoading: false });}, 1500);
      } else{
        this.setState({isLoading: false });
        toast.warn("Menu sync couldn't be completed. Try again.");
      }
   

      // window.location.reload();
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Failed to synchronize menu:', error);
      toast.error("Menu sync couldn't be completed. Try again.");
    }
};

  render() {
    const {
      isLoading,
      isFetching,
      selectedMenu,
      menuToEdit,
      categoryToEdit,
      itemToEdit,
      openAddMenu,
      openEditMenu,
      openAddCategory,
      openEditCategory,
      openAddItem,
      openEditItem,
      tab,
      selectedCategory,
      accNotConfirm,
      expanded,
    } = this.state;
    return (
      <>
        {accNotConfirm && (
          <Alert severity="warning">
            To access this feature please complete your account setup.
          </Alert>
        )}
        {!accNotConfirm && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
              {!this.checkShowSyncComponnet() &&
                (<AppBar position="static" color="default">
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#5773ff" } }}
                  >
                    <Tab label="Overview" />
                    <Tab label="Menus" disabled={this.props.settings?.is_eposnow_enabled} />
                    <Tab label="Categories" disabled={this.props.settings?.is_eposnow_enabled} />
                    <Tab label="Items" disabled={this.props.settings?.is_eposnow_enabled} />
                  </Tabs>
                  <TabPanel value={tab} index={0} className="tabPanel">
                    <>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                          <Grid
                            container
                            // spacing={3}
                            style={{
                              paddingTop: 20,
                              paddingLeft: 20,
                              alignItems: "center",
                            }}
                            direction="row"
                            alignItems="flex-end"
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Grid item xs={6} sm={5}>
                              <FormControl fullWidth>
                                <InputLabel id="select-menu">Menu</InputLabel>
                                <Select
                                  labelId="select-menu"
                                  id="select-menu"
                                  value={selectedMenu}
                                  defaultValue={selectedMenu}
                                  input={
                                    <OutlinedInput
                                      id="select-menu"
                                      label="Menu"
                                    />
                                  }
                                  className="formControl"
                                  disabled={!this.props.menuNames}
                                  onChange={this.handleMenuChange}
                                >
                                  {this.props.menuNames &&
                                    this.props.menuNames.map((item) => {
                                      return (
                                        <MenuItem
                                          sx={{
                                            alignItems: "baseline",
                                            justifyContent: "flex-start",
                                          }}
                                          key={item.menu_id}
                                          value={item.menu_id}
                                        >
                                          ({item.menu_sort_order})
                                          <span style={{ whiteSpace: "normal" }}>
                                            {item.menu_identifier}
                                          </span>
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              {this.props.menuNames && (
                                <ButtonGroup
                                  size="small"
                                  orientation="horizontal"
                                  color="primary"
                                  variant="text"
                                  aria-label="horizontal outlined primary button group"
                                >
                                  <Tooltip title="Delete Menu" placement="top">
                                    <Button
                                      color="action"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (this.props.menuNames) {
                                          let menNames =
                                            this.props.menuNames.filter(
                                              (menu) => {
                                                return (
                                                  menu.menu_id == selectedMenu
                                                );
                                              }
                                            );
                                          if (
                                            menNames[0].total_category_count > 0
                                          ) {
                                            toast.warn(
                                              "Please remove the categories inside menu first."
                                            );
                                            return;
                                          }
                                        }
                                        deleteConfirm({
                                          name: "Menu",
                                          id: selectedMenu,
                                          message: "Menu deleted.",
                                          text: "You won't be able to revert this. ",
                                          deleteFunc: deleteMenu,
                                          func: [this.fetchMenuNames],
                                        });
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Edit Menu" placement="top">
                                    <Button
                                      color="action"
                                      size="small"
                                      onClick={() => this.editMenu()}
                                    >
                                      <EditIcon />
                                    </Button>
                                  </Tooltip>
                                </ButtonGroup>
                              )}
                            </Grid>

                            <Grid item xs={6} sm={3}>
                            {!this.props.settings?.is_eposnow_enabled && <Dropdown
                              onSelect={this.handleQuickAdd}
                              size="small"
                            >
                              <Dropdown.Toggle
                                color="primary"
                                id="dropdown-basic"
                              >
                                + Add
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  key="Add Menu"
                                  eventKey="Add Menu"
                                >
                                  Menu
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key="Add Category"
                                  eventKey="Add Category"
                                >
                                  Category
                                </Dropdown.Item>
                                <Dropdown.Item
                                  key="Add Item"
                                  eventKey="Add Item"
                                >
                                  Item
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>}
                            {this.props.settings?.is_eposnow_enabled && <Button disabled={this.state.isLoading} variant="contained" onClick={ () => {this.handleSyncMenu();}}> Sync </Button>}
                          </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ padding: 20 }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <div style={{ marginTop: 5 }}>
                              {isLoading ? (
                                <Loader size={50} />
                              ) : (
                                <>
                                  {this.props.categoryNames ? (
                                    this.props.categoryNames.map(
                                      (item, index) => {
                                        return (
                                          <CategoryList
                                            key={item.category_id}
                                            item={item}
                                            fetchCategoryNames={
                                              this.fetchCategoryNames
                                            }
                                            fetchMenuNames={this.fetchMenuNames}
                                            fetchItems={this.fetchItems}
                                            editCategory={this.editCategory}
                                            editItem={this.editItem}
                                            handleQuickAdd={this.handleQuickAdd}
                                            selectedCategory={
                                              this.props.selectedCategory
                                            }
                                            index={index}
                                            items={this.props.items}
                                            categoryNames={
                                              this.props.categoryNames
                                            }
                                            menuNames={this.props.menuNames}
                                            setSelectedCate={
                                              this.props.setSelectedCategory
                                            }
                                            setSelectedCategory={
                                              this.setSelectedCategory
                                            }
                                            fetchItemsIndividually={
                                              this.fetchItemsIndividually
                                            }
                                            handleAccordionChange={
                                              this.handleAccordionChange
                                            }
                                            expanded={expanded}
                                            isLoading={this.state.isItemLoading}
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <>
                                      <Alert severity="warning">
                                        No data found. Please click on Add to add
                                        new
                                      </Alert>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={12}
                          style={{ paddingLeft: 10 }}
                        >
                          {openAddMenu && (
                            <AddEditMenu
                              title="Add Menu"
                              handleQuickAdd={this.handleQuickAdd}
                              showClose={true}
                              fetchMenuNames={this.fetchMenuNames}
                            />
                          )}
                          {openEditMenu &&
                            (isFetching ? (
                              <div className="p20">
                                <Loader />
                              </div>
                            ) : (
                              <AddEditMenu
                                title="Edit Menu"
                                handleQuickAdd={this.handleQuickAdd}
                                showClose={true}
                                fetchMenuNames={this.fetchMenuNames}
                                menuToEdit={menuToEdit}
                              />
                            ))}
                          {openAddCategory && (
                            <AddEditCategory
                              title="Add Category"
                              handleQuickAdd={this.handleQuickAdd}
                              showClose={true}
                              fetchCategoryNames={this.fetchCategoryNames}
                              fetchMenuNames={this.fetchMenuNames}
                            />
                          )}
                          {openEditCategory &&
                            (isFetching ? (
                              <div className="p20">
                                <Loader />
                              </div>
                            ) : (
                              <AddEditCategory
                                title="Edit Category"
                                handleQuickAdd={this.handleQuickAdd}
                                showClose={true}
                                fetchCategoryNames={this.fetchCategoryNames}
                                fetchMenuNames={this.fetchMenuNames}
                                categoryToEdit={categoryToEdit}
                                fetchItemsIndividually={
                                  this.fetchItemsIndividually
                                }
                              />
                            ))}
                          {openAddItem && (
                            <AddEditItem
                              title="Add Item"
                              handleQuickAdd={this.handleQuickAdd}
                              showClose={true}
                              fetchItems={this.fetchItems}
                              fetchItemsIndividually={this.fetchItemsIndividually}
                              selectedCategory={selectedCategory}
                              fetchUpsellItems={this.fetchUpsellItems}
                              fetchMenuNames={this.fetchMenuNames}
                            />
                          )}

                          {openEditItem &&
                            (isFetching ? (
                              <div className="p20">
                                <Loader />
                              </div>
                            ) : (
                              <AddEditItem
                                title="Edit Item"
                                handleQuickAdd={this.handleQuickAdd}
                                showClose={true}
                                fetchItems={this.fetchItems}
                                itemToEdit={itemToEdit}
                                fetchAllAddons={this.fetchAllAddons}
                                fetchAllCombos={this.fetchAllCombos}
                                selectedCategory={itemToEdit?.category_id}
                                personNames={this.state.personNames}
                                upSellItemMap={this.state.upSellItemMap}
                                upSellMenuNames={this.props.menuNames}
                                upSellSelectedMenu={selectedMenu}
                                fetchUpsellItems={this.fetchUpsellItems}
                                fetchMenuNames={this.fetchMenuNames}
                              />
                            ))}
                        </Grid>
                      </Grid>
                    </>
                  </TabPanel>
                  <TabPanel value={tab} index={1} className="tabPanel">
                    <AddEditMenu
                      title="Add Menu"
                      handleQuickAdd={this.handleQuickAdd}
                      fetchMenuNames={this.fetchMenuNames}
                    />
                  </TabPanel>
                  <TabPanel value={tab} index={2} className="tabPanel">
                    <AddEditCategory
                      title="Add Category"
                      handleQuickAdd={this.handleQuickAdd}
                      fetchCategoryNames={this.fetchCategoryNames}
                      fetchMenuNames={this.fetchMenuNames}
                    />
                  </TabPanel>
                  <TabPanel value={tab} index={3} className="tabPanel">
                    <AddEditItem
                      title="Add Item"
                      showClose={false}
                      fetchItems={this.fetchItems}
                      fetchUpsellItems={this.fetchUpsellItems}
                      fetchAllAddons={this.fetchAllAddons}
                      fetchMenuNames={this.fetchMenuNames}
                    />
                  </TabPanel>
                </AppBar>)
              }
              { this.checkShowSyncComponnet() && <MenuSyncComponent /> }
              
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  property_id: state.user.propertyId,
  username: state.user.username,
  menuNames: state.menu.menuNames,
  items: state.menu.items,
  categoryNames: state.menu.categoryNames,
  selectedCategory: state.menu.selectedCategory,
  selectedMenu: state.menu.selectedMenu,
  settings: state.user.settings,
});

const mapDispatchToProps = {
  setMenuNames,
  setItems,
  setCategoryNames,
  setSelectedMenu,
  setSelectedCategory,
  setCurrentItemExtras,
  setCurrentItemOptions,
  setCategoriesForExtras,
  setCategoriesForOptions,
  setCombos,
  setComboItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
