export const CATCH_ERROR_MESSAGE =
  "Please enter a valid password as per the following policies:\n" +
  "1. Minimum 8 characters.\n" +
  "2. One capital letter.\n" +
  "3. One small letter.\n" +
  "4. One number.\n" +
  "5. One special character.\n" +
  "6. Allowed special characters: @#$%^&+=_`*!|.,:;'()?\\\"/-";
export const NEED_PASSWORD_CHANGE_SUCCESS =
  "This account has been created by Kayana Admin. Please reset your password";
export const LOGIN_SUCCESS = "Login successful";
export const PASSWORD_CHANGE_SUCCESS =
  "Your password has been changed successfully";
export const SIGNUP_SUCCESS =
  "You have signed up successfully. Please sign in again.";
export const OTP_SUCCESS =
  "OTP has been sent successfully to your registered email";
export const OTP_VALIDATION_MESSAGE = "OTP is of 6 digit";
export const SIGN_UP_FIRST_STEP = "One more step to go";
export const BUSINESS_PLACE_SUCCESS_MESSAGE =
  "Business place added successfully, Please sign in to continue";
export const COUNTRY_VALIDATION = "Country is required";
export const OTP_FAILURE =
  "You have exceeded the limit, Please try again later";
export const DELIVERY = "";
export const UPDATE_TABLE_RESERVATION_DETAILS_SUCCESS_MESSAGE =
  "Your table details are updated successfully";
export const SOMETHING_WENT_WRONG = "Something went wrong, please try again";
export const INVALID_CREDENTIALS = "Invalid credentials";
// export const LOGIN_FAILURE=''
// export const LOGIN_FAILURE=''
// export const LOGIN_FAILURE=''
