import $ from "jquery";
import React from "react";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
import logo from "../../assets/img/kayana/main-logo/logo.svg";
import { BrowserRouter } from "react-router-dom";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import { setSettings } from "../../redux/user/userSlice";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class Sidenavigation extends React.PureComponent {
  state = {
    is_charity: false,
    delivery: "delivery",
    is_cash_advance: false,
    is_pay_by_link: false,
    is_qr_ordering: false,
  };

  removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };

  componentDidMount() {
    window.addEventListener("popstate", function (event) {
      window.location.reload();
    });
    this.fetchAccountSetting();
    const username = localStorage.getItem("username");
    if (username) {
      // SecurityServiceAPI.fetchBusinessPlace(username).then(response => {
      //     if (response.data.status) {
      //         this.setState({ is_charity: response.data.data.property_details[0].is_charity})
      //     }
      // })
    }

    // For keeping the track of active navigation link we store its id in localstorage
    // So that even when we refresh the page we can still keep the current menu item active
    // It is advised when adding any new nav item do add an id to its <Link>
    const setActiveMenuItem = () => {
      $(".ms-main-aside .menu-item a").click(function () {
        let pathArray = window.location.pathname.split("/");
        let currentPage = pathArray[1];
        // get elements that are active and remove their active class
        var activeItem = $(".active");
        //   activeItem.find("a").addClass("active");
        $(this).removeAttr("href");
        var listitems = $(this).parent("li");
        var element = listitems.children();
        localStorage.setItem("activeNavItem", element.attr("id"));
        if (element[0].classList.contains("active")) {
          // if ($("#financeMenu").hasClass("active")) {
          //   element.removeClass("active");
          // }

          //     element.find("li").removeClass("active");
          listitems.find(".collapse").slideToggle();
          //     activeItem.removeClass("active");
        } else {
          let pathArray = window.location.pathname.split("/");
          currentPage = pathArray[1];
          if (currentPage != "payout" || currentPage != "transactions") {
            element.addClass("active");
            listitems.children(".collapse").slideToggle();
            //  listitems.siblings("li").children(".collapse").slideUp();
            element.siblings("li").removeClass("active");
            element.siblings("li").find("li").removeClass("active");
            //     listitems.siblings("li").find(".collapse").slideUp();
            activeItem.removeClass("active");
          }
        }
        if (window.location.hash !== "#rep") {
          $("#" + currentPage)
            .parents("ul")
            .addClass("active");
        } else {
          //$("#" + currentPage).toggleClass("active");
        }
        //
        //$("#" + currentPage).parents("active").addClass("active");
      });
    };

    this.setActive();
    //  setActiveMenuItem();
    setTimeout(() => {
      this.setActive();
      setActiveMenuItem();
    }, 1500);
  }

  setActive() {
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      //     $("#" + currentPage + " ul").slideDown();
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      if (currentPage == "payout") {
        $("#" + "payout").addClass("active");
        $("#reports").addClass("active");
        $("#" + "payout")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "transactions") {
        $("#" + "transactions").addClass("active");
        $("#" + "transaction")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "sales") {
        $("#" + "sales").addClass("active");
        $("#reports").addClass("active");
        $("#" + "sales")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "non-selling-items") {
        $("#" + "non-selling-items").addClass("active");
        $("#" + "non-selling-items")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "top-selling-items") {
        $("#" + "top-selling-items").addClass("active");
        $("#" + "top-selling-items")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "xreport") {
        $("#" + "xreport").addClass("active");
        $("#" + "xreport")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "zreport") {
        $("#" + "zreport").addClass("active");
        $("#" + "zreport")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "plu-sales-report") {
        $("#" + "zreport").addClass("active");
        $("#" + "zreport")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "weekly-business-report") {
        $("#" + "zreport").addClass("active");
        $("#" + "zreport")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "activity-report") {
        $("#" + "zreport").addClass("active");
        $("#reports").addClass("active");
        $("#" + "zreport")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "summary-report") {
        $("#" + "zreport").addClass("active");
        $("#" + "zreport")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "item-report") {
        $("#" + "item-report").addClass("active");
        $("#" + "item-report")
          .parent()
          .addClass("active");
        $("#reports").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "discount") {
        $("#" + "discount").addClass("active");
        $("#" + "discount")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "epos-discounts") {
        $("#" + "epos-discounts").addClass("active");
        $("#" + "epos-discounts")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "guide") {
        $("#" + "guide").addClass("active");
        $("#" + "guide")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "grant-details") {
        $("#" + "grant-details").addClass("active");
        $("#" + "grant-details")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "grants") {
        $("#" + "grants").addClass("active");
        $("#" + "grants")
          .parent()
          .addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "grant-accept") {
        $("#" + "grant-accept").addClass("active");
        $("#" + "grant-accept")
          .parent()
          .addClass("active");
        $("#capitalMenu").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else if (currentPage == "capital-terms-conditions") {
        $("#" + "capital-terms-conditions").addClass("active");
        $("#" + "capital-terms-conditions")
          .parent()
          .addClass("active");
        $("#capitalMenu").addClass("active");

        //     listitems.children(".collapse").slideDown();
        //  listitems.siblings("li").children(".collapse").slideUp();
        $("#" + currentPage)
          .siblings("li")
          .removeClass("active");
      } else {
        $(".collapse").slideUp();
      }
    }
  }

  fetchAccountSetting() {
    const dump = {
      property_id: this.props.propertyId,
      //  username: this.props.user.username ? this.props.user.username : localStorage.getItem("username")
    };

    PaymentServiceAPI.fetchAccountSetting(dump)
      .then((response) => {
        if (response.data && response.data.status) {
          this.setState({
            is_cash_advance: response.data?.data?.is_cash_advance,
            is_pay_by_link: response.data?.data?.is_pay_by_link,
            is_qr_ordering: response.data?.data?.is_qr_ordering,
            is_master_franchise: response.data?.data?.is_master_franchise,
          });
          this.props.setSettings(response.data.data);
        } else {
          this.setState({
            is_cash_advance: false,
            is_pay_by_link: false,
            is_qr_ordering: false,
          });
          this.props.setSettings({});
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({
          isLoading: false,
          is_cash_advance: false,
          is_pay_by_link: false,
          is_qr_ordering: false,
        });
      });
  }

  render() {
    const {
      is_cash_advance,
      is_pay_by_link,
      is_qr_ordering,
      is_master_franchise,
    } = this.state;
    return (
      <div>
        <div
          className="ms-aside-overlay ms-overlay-left ms-toggler"
          onClick={this.removeoverlay}
        ></div>
        <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
        <Scrollbar
          id="ms-side-nav"
          className="side-nav fixed ms-aside-scrollable ms-aside-left"
        >
          {/* Logo */}
          <div className="logo-sn ms-d-block-lg">
            <Link
              className="pl-0  text-center "
              to="/dashboard"
              style={{ cursor: "pointer" }}
            >
              <img style={{ margin: "auto" }} src={logo} />
            </Link>
          </div>

          {/* Navigation */}
          <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
            {/* Dashboard */}
            <li className="menu-item">
              <Link
                to="/dashboard"
                id="dashboard"
                style={{ cursor: "pointer" }}
              >
                <span>
                  <i className="material-icons fs-16">dashboard</i>Dashboard{" "}
                </span>
              </Link>
            </li>
            {/* /Dashboard */}

            {/* product */}
            <li className="menu-item">
              <Link to="/menu" id="menu" style={{ cursor: "pointer" }}>
                {" "}
                <span>
                  <i className="fa fa-archive fs-16" />
                  Menu{" "}
                </span>
              </Link>
            </li>

            <li className="menu-item">
              <Link
                to={`/orders-delivery/${this.state.delivery}`}
                id="orders-delivery"
                style={{ cursor: "pointer" }}
              >
                {" "}
                <span>
                  <i className="fas fa-clipboard-list fs-16"></i>
                  Orders{" "}
                </span>
              </Link>
            </li>

            {is_cash_advance && (
              <li className="menu-item" style={{ cursor: "pointer" }}>
                <Link to="#rep" id="capitalMenu" className="has-chevron">
                  {" "}
                  <i className="material-icons" style={{ fontWeight: "bold" }}>
                    center_focus_weak
                  </i>{" "}
                  Capital
                </Link>
                <ul
                  id="capitalMenu"
                  className="collapse"
                  aria-labelledby="capitalMenu"
                  data-parent="#side-nav-accordion"
                >
                  <li>
                    <Link
                      id={
                        window.location.pathname == "/grant-details"
                          ? "grant-details"
                          : window.location.pathname == "/grants"
                          ? "grants"
                          : "grant-accept"
                      }
                      to="/grant-details"
                    >
                      <i
                        className="material-icons"
                        style={{ fontWeight: "bold" }}
                      >
                        beenhere
                      </i>{" "}
                      Apply for Grant
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="capital-terms-conditions"
                      to="/capital-terms-conditions"
                    >
                      <i
                        className="material-icons"
                        style={{ fontWeight: "bold" }}
                      >
                        assessment
                      </i>{" "}
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {is_qr_ordering && (
              <li className="menu-item">
                <Link
                  style={{ cursor: "pointer" }}
                  id={
                    window.location.pathname == "/manage-qr-flow"
                      ? "manage-qr-flow"
                      : window.location.pathname == "/pricing"
                      ? "pricing"
                      : window.location.pathname == "/table"
                      ? "table"
                      : window.location.pathname == "/room"
                      ? "room"
                      : "generate-qr-code"
                  }
                  to="/manage-qr-flow"
                >
                  {" "}
                  <span>
                    {" "}
                    <i className="fa-solid fa  fa-qrcode"></i> QR{" "}
                  </span>
                </Link>
              </li>
            )}

            {is_pay_by_link && (
              <li className="menu-item">
                <Link
                  style={{ cursor: "pointer" }}
                  id={
                    window.location.pathname == "/pay-by-link"
                      ? "pay-by-link"
                      : window.location.pathname == "/pay-by-link-transactions"
                      ? "pay-by-link-transactions"
                      : "pay-by-link-transaction-details"
                  }
                  to="/pay-by-link"
                >
                  {" "}
                  <span>
                    <i className="fa-solid fa fa-link"></i> Payment links{" "}
                  </span>
                </Link>
              </li>
            )}
            <li className="menu-item">
              <Link to="/disputes" id="disputes" style={{ cursor: "pointer" }}>
                {" "}
                <span>
                  <i className="material-icons">account_balance</i>
                  Disputes{" "}
                </span>
              </Link>
            </li>

            <li className="menu-item" style={{ cursor: "pointer" }}>
              <Link to="#rep" id="reports" className="has-chevron">
                {" "}
                <span>
                  <i className="fa fa-file-invoice fs-16" />
                  Reports
                </span>
              </Link>
              <ul
                id="reports"
                className="collapse"
                aria-labelledby="reports"
                data-parent="#side-nav-accordion"
                /*  style={{
                  maxHeight: "25vh",
                  overflowY: "scroll", // Ensure scrollbar is always visible
                  WebkitOverflowScrolling: "touch",
                  scrollBehavior: "smooth", // Enable smooth scrolling
                  "@media screen and (maxWidth: 768px)": {
                    maxHeight: "calc(100vh - 150px)",
                    overflowY: "scroll", // Adjust for responsive view
                    WebkitOverflowScrolling: "touch",
                    scrollBehavior: "smooth", // Enable smooth scrolling
                  },
                }} */
              >
                <li>
                  {" "}
                  <Link id="payout" to="/payout">
                    {" "}
                    <i className="material-icons">account_balance</i>Payouts
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="transactions" to="/transactions">
                    {" "}
                    <i className="fa fa-credit-card fa-xs"></i> Transactions
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="sales" to="/sales">
                    {" "}
                    <i className="fa fa-briefcase fs-16"></i> Sales
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="top-selling-items" to="/top-selling-items">
                    {" "}
                    <i className="fa   fa-angle-double-up fs-16"></i>{" "}
                    Top-selling items
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="non-selling-items" to="/non-selling-items">
                    <i className="fa fa-angle-double-down fs-16"></i>{" "}
                    Non-selling items
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="xreport" to="/xreport">
                    <span>
                      <i className="material-icons fs-16">widgets</i>
                    </span>{" "}
                    X Report
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="zreport" to="/zreport">
                    <span>
                      <i className="fa fa-copy  fs-16"></i>
                    </span>{" "}
                    Z Report
                  </Link>
                </li>

                <li>
                  {" "}
                  <Link
                    id="weekly-business-report"
                    to="/weekly-business-report"
                  >
                    <span>
                      <i className="fa fa fa-window-maximize  fs-16"></i>
                    </span>{" "}
                    Weekly Report
                  </Link>
                </li>

                <li>
                  {" "}
                  <Link id="plu-sales-report" to="/plu-sales-report">
                    <span>
                      <i className="fa  fa-info-circle  fs-16"></i>
                    </span>{" "}
                    PLU Sales Report
                  </Link>
                </li>

                <li>
                  {" "}
                  <Link id="activity-report" to="/activity-report">
                    <span>
                      <i className="fa  fa-list  fs-16"></i>
                    </span>{" "}
                    Activity Report
                  </Link>
                </li>

                <li>
                  {" "}
                  <Link id="summary-report" to="/summary-report">
                    <span>
                      <i className="fa  fa-th  fs-16"></i>
                    </span>{" "}
                    Summary Report
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="item-report" to="/item-report">
                    <span>
                      <i className="fa   fa-paper-plane  fs-16"></i>
                    </span>{" "}
                    Item Report
                  </Link>
                </li>
                {this.props.mainPropertyId == this.props.propertyId && <>
                {is_master_franchise && (
                  <React.Fragment>
                  <li>
                    {" "}
                    <Link id="reports/data-report" to="/reports/data-report">
                      <span>
                        <i className="fa   fa-database  fs-16"></i>
                      </span>{" "}
                      Data Report
                    </Link>
                  </li>
               
                <li>
                  {" "}
                  <Link
                    id="reports/dog-dollar-correction"
                    to="/reports/dog-dollar-correction"
                  >
                    <span>
                      <i className="fa   fa-database  fs-16"></i>
                    </span>{" "}
                    DogDollar Report
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="reports/total" to="/reports/total">
                    <span>
                      <i className="fa fa-list-alt  fs-16"></i>
                    </span>{" "}
                    Sales Report
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="reports/charlie" to="/reports/charlie">
                    <span>
                      <i className="fa fa-envelope  fs-16"></i>
                    </span>{" "}
                    Charlie Report
                  </Link>
                </li>
                </React.Fragment>
                 )} </> }
                <li>
                  {" "}
                  <Link id="reports/gross-sales-by-store" to="/reports/gross-sales-by-store">
                    <span>
                      <i className="fa fa-rocket  fs-16"></i>
                    </span>{" "}
                    Sales Store
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link id="reports/payment-method" to="/reports/payment-method">
                    <span>
                      <i className="fa fa-random  fs-16"></i>
                    </span>{" "}
                    Payment Method
                  </Link>
                </li>
              </ul>
            </li>

            {/* 
            <li className="menu-item" style={{ cursor: "pointer" }}>
              <Link to="#rep" id="discountMenu" className="has-chevron">
                {" "}
                <span>
                  {" "}
                  <i class="material-icons fs-16">discount</i>Discounts
                </span>
              </Link>
              <ul
                id="discountMenu"
                className="collapse"
                aria-labelledby="discountMenu"
                data-parent="#side-nav-accordion"
                style={{ paddingLeft: "14px" }}
              >
                <li className="menu-item">
                  <Link
                    style={{ cursor: "pointer" }}
                    id="discount"
                    to="/discount"
                  >
                    <i class="material-icons fs-16">smartphone</i>Kiosk
                    Discounts
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    style={{ cursor: "pointer" }}
                    id="epos-discounts"
                    to="epos-discounts"
                  >
                    <i class="material-icons fs-16">monitor</i> Epos Discounts
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    style={{ cursor: "pointer" }}
                    id="happy-hour-discount"
                    to="/happy-hour-discount"
                  >
                    <i className="material-icons fs-16">autorenew</i>
                    Happy Hour Discount
                  </Link>
                </li>
              </ul>
            </li> */}

            {/*  <li className="menu-item">
              <Link
                to={`/products/`}
                id="products"
              >
                {" "}
                <span>
                  <i className="fas fa-clipboard-list fs-16"></i>
                  Product{" "}
                </span>
              </Link>
              
            </li> */}
            {/* <li className="menu-item" style={{ cursor: "pointer" }}>
              <Link to="#fin" id="financeMenu" className="has-chevron">
                <span>
                  {" "}
                  <i class="material-icons fs-16" style={{ fontSize: '1.6em' }}>attach_money</i>Finance
                </span>
              </Link>
              <ul
                id="financeMenu"
                className="collapse"
                aria-labelledby="financeMenu"
                data-parent="#side-nav-accordion"
                style={{ paddingLeft: "14px" }}
              >
                <li>
                  <Link id="payout" to="/payout">
                    <i className="material-icons">account_balance</i>Payouts
                  </Link>
                </li>
                <li>
                  <Link id="qr" to="/qr-invoice">
                    <i className="fa-solid fa  fa-qrcode"></i> QR
                  </Link>
                </li>
                <li>
                  <Link id="web-invoice" to="/web-invoice">
                  <i className="material-icons" style={{ fontSize: '1.5em' }}>language</i>
                    Web
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* <li className="menu-item">
              <Link
                style={{ cursor: "pointer" }}
                id="delivery-charges"
                to="/delivery-charges"
              >
                {" "}
                <span>
                  {" "}
                  <i className="fa-solid fa  fa-bullseye"></i>Delivery Charges
                </span>
              </Link>
            </li> */}
            {/*   <li className="menu-item">
              <Link style={{ cursor: "pointer" }} id="shift" to="/shift">
                {" "}
                <span>
                  {" "}
                  <i className="fa-solid fa  fa-clock"></i>Shift
                </span>
              </Link>
            </li> */}

            {/* product end */}
            {/* orders */}
            {/* 
                        {this.state.is_charity ? <li className="menu-item">
                            <Link to="/donation-order"> <span><i className="fas fa-clipboard-list fs-16" />Orders</span>
                            </Link>
                        </li > :
                            <li className="menu-item">
                                <Link to="#" className="has-chevron"> <span><i className="fas fa-user-friends fs-16" />Orders </span>
                                </Link>
                                <ul id="customer" className="collapse" aria-labelledby="customer" data-parent="#side-nav-accordion">
                                    <li> <Link to={`/orders-takeaway`} >Take Away Orders</Link>
                                    </li>
                                    <li> <Link to={`/orders-dine-in`} >Dine In Orders</Link>
                                    </li>

                                    <li><Link to={`/orders-delivery/${this.state.delivery}`}>Delivery Orders</Link>
                                    </li>
                                    
                                    <li> <Link to={`/orders-delivery-to-park`} >Delivery To Park Orders</Link>
                                    </li>
                                </ul >
                            </li >} */}

            {/* orders end */}

            {/* restaurants */}

            {/*    <li className="menu-item" style={{ position: "relative" }}>
              <Link to="#shp" id="shops" className="has-chevron" >
                {" "}
                <span>
                  <i className="fa fa-file-invoice fs-16" />
                  Shop
                </span>
              </Link>
              <ul id="shops" className="collapse" aria-labelledby="reports" data-parent="#side-nav-accordion" style={{ maxHeight: "25vh", overflow: "auto" }}>
                <li>  <Link id="kiosks" to="/shop"  > <i className="material-icons">account_balance</i>Self service Kiosks</Link>
                </li>
                <li>   <Link id="epos" to="/shop"> <i className="fa fa-credit-card fa-xs"></i> EPOS</Link>
                </li>
                <li>   <Link id="mpos" to="/shop"> <i className="fa fa-briefcase fs-16"></i> MPOS</Link>
                </li>
              </ul >
            </li> */}

            {/*   <li className="menu-item">
              <Link to="/notification" id="notification">
                {" "}
                <span>
                  <i className="fa flaticon-bell fs-16" />
                  Notification
                </span>
              </Link>
            </li> */}

            <li className="menu-item" style={{ cursor: "pointer" }}>
              <Link to="#rep" id="inventory" className="has-chevron">
                {" "}
                <span>
                  {/* <i className="fa fa-file-invoice fs-16" /> */}
                  <i className="material-icons">inventory</i>
                  Inventory
                </span>
              </Link>
              <ul
                id="inventory"
                className="collapse"
                aria-labelledby="inventory"
                data-parent="#side-nav-accordion"
              >
                <li>
                  <Link id="inventory-accept" to="/inventory-accept">
                    <i className="fa fa-store"></i>
                    Manage Inventory
                  </Link>
                </li>
                <li>
                  <Link id="inventory-reasons" to="/inventory-reasons">
                    <i className="fa fa-wrench"></i>
                    Inventory Settings
                  </Link>
                </li>
              </ul>
            </li>

            {/* Settings  start */}
            <li className="menu-item" style={{ cursor: "pointer" }}>
              <Link to="#rep" id="guides" className="has-chevron">
                {" "}
                <span>
                  {/* <i className="fa fa-file-invoice fs-16" /> */}
                  <i className="fa fa-book-open" />
                  Platform Guides
                </span>
              </Link>
              <ul
                id="guides"
                className="collapse"
                aria-labelledby="guides"
                data-parent="#side-nav-accordion"
              >
                <li>
                  <Link id="guide" to="/guide">
                    <i className="fa fa-handshake"></i>
                    Onboarding
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-item " style={{ zIndex: "-9" }}>
              <Link to="/floor-layout" id="floor-layout" style={{ cursor: "pointer" }}>
                {" "}
                <span>
                  <b>
                    {" "}
                    <i className="fa fa-window-maximize" />
                  </b>
                  Floor Layout
                </span>
              </Link>
            </li>
            <li className="menu-item " style={{ zIndex: "-9" }}>
              <Link to="/setting" id="setting" style={{ cursor: "pointer" }}>
                {" "}
                <span>
                  <b>
                    {" "}
                    <i className="fa fa-cog" />
                  </b>
                  Account Settings
                </span>
              </Link>
            </li>
            {/* Settings  end */}
          </ul>
        </Scrollbar>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  mainPropertyId: state.user.mainPropertyId,
});

const mapDispatchToProps = {
  setSettings,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidenavigation)
);
