import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FloorPlanner from './FloorPlanner';

const FloorPlannerWrapper = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <FloorPlanner />
    </DndProvider>
  );
};

export default FloorPlannerWrapper;
