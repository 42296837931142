import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import SimpleModal from "../../shared/Modal/SimpleModal";
import { Fragment } from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from '@mui/material/Button';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
class index extends Component {


  state = {
    isLoading: false,
    payment_method_type: [],
    item: ""
  };

  componentDidMount = () => {
    this.fetchReceipt();
  };


  submitReceipt = (values, resetForm) => {


    let propId = this.props.propertyId;

    let payload = { "property_id": propId, "receipt_note": values.receipt_note }
    if (payload) {
      PaymentServiceAPI.updateReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Receipt note added. ");
            resetForm();
            this.fetchReceipt();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not add receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false });
      toast.error("Something went wrong. ")
    }

  };

  removeReceipt = () => {


    let propId =  this.props.propertyId;

    let payload = { "property_id": propId, "receipt_note": "" }
    if (payload) {
      PaymentServiceAPI.updateReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Receipt note removed. ");
            this.fetchReceipt();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not remove receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false });
      toast.error("Something went wrong. ")
    }

  };

  fetchReceipt = (values, resetForm) => {

    let propId =  this.props.propertyId;

    let payload = { "property_id": propId }
    if (payload) {
      PaymentServiceAPI.fetchReceipt(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ isLoading: false, item: response.data.data.receiptNote });
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          } else {
            this.setState({ isLoading: false, item: "" });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, item: "" });
          toast.warn("Could not add receipt note.. ")
        });
    } else {
      this.setState({ isLoading: false, item: "" });
      toast.error("Something went wrong. ")
    }



  };





  render() {

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Add Receipt Note</h6>
              </div>
              <div className="ms-panel-body"></div>

              <Formik
                enableReinitialize
                initialValues={{ receipt_note: '' }}
                validationSchema={schema.receiptSchema}
                onSubmit={(values, { resetForm }) => {
                  this.submitReceipt(values, resetForm);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>


                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >

                      <Grid item xs={10} md={6}  >
                        <FormControl fullWidth sx={{ marginLeft: "20px" }}>
                          <TextField
                            error={
                              touched.receipt_note &&
                                errors.receipt_note
                                ? true
                                : false
                            }
                            autoFocus
                            margin="dense"
                            id="receipt_noter"
                            label="Receipt Note"
                            type="text"
                            name="receipt_note"
                            fullWidth
                            variant="standard"
                            value={values.receipt_note}
                            onChange={handleChange}

                          />
                          <FormHelperText error>
                            {touched.receipt_note && errors.receipt_note
                              ? errors.receipt_note
                              : null}
                          </FormHelperText>

                        </FormControl>

                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{ mt: 2, mb: 2, marginLeft: "20px" }}
                    >
                      Submit
                    </Button>


                  </Form>

                )}
              </Formik>

              {this.state.item &&

                <React.Fragment>
                  <Typography variant="h5" gutterBottom style={{ marginLeft: "50px", marginTop: "50px" }}>
                    Receipt Note.

                  </Typography>
                  <Grid
                    container
                    alignItems="center"
                    style={{ padding: '12px' }} >
                    <Grid item  xs ={2} lg={1}>

                      <Avatar>&clubs;</Avatar>

                    </Grid>
                    <Grid item  xs ={8} lg={4} style={{wordBreak: "break-word"}}>
                      {this.state.item || ''}

                    </Grid>
                    <Grid item xs ={2} lg={1}>

                      {/* <IconButton
                        color="primary"
                        onClick={this.removeReceipt}
                        aria-label="delete"
                        size="large"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton> */}
                      <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={this.removeReceipt}>Delete</Button>
                    </Grid>

                  </Grid>
                </React.Fragment>
              }

            </div> </div> </div>
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId
});

export default connect(mapStateToProps, {}) (index)
