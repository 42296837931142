import React, { Component, Fragment } from "react";
import Breadcrumb from "./Breadcrumb";
import Loader from "../../../../shared/Loader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles, createStyles } from "@mui/styles";
import {
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Paper,
  RadioGroup,
  Stack,
} from "@mui/material";
import { getCurrencySymbol } from "../../../../config";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import { Modal } from "antd";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import * as OrderServiceAPI from "../../../../services/order/OrderServiceAPI";
import { toast } from "react-toastify";

const styles = (theme) =>
  createStyles({
    timeline: {
      transform: "rotate(-90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(-90deg)",
      textAlign: "center",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
  });
class Detailcontent extends Component {
  state = {
    dispute_Details: [],
    isLoading: true,
    isAcceptModal: false,
    isDefendModal: false,
    selectedValue: "",
    isModalVisible: false,
    file: null,
    progress: 0,
    disputeReasons: [],
  };

  componentDidMount = () => {
    const dispute_Details = this.props.location?.state?.rowData;
    this.setState({ dispute_Details: dispute_Details, isLoading: false });
    this.getDefendReasons(this.props.location?.state.rowData.order_id);
  };
  handleFileChange = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  handleSubmit = () => {
    this.setState({ isModalVisible: false ,isLoading:true });
    const formData = new FormData();
    formData.append("defense_document", this.state.file);
    formData.append("order_id", this.state.dispute_Details.order_id);
    this.uploadDefendDocs(formData);
  };

  handleRadioChange = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };
  handleAccept = () => {
    this.setState({ isAcceptModal: true });
  };

  handleDefend = () => {
    // this.defendDisputedOrder(this.state.dispute_Details.order_id);
    this.setState({ isDefendModal: true });
  };
  handleDefendCancel = () => {
    this.setState({ isDefendModal: false });
  };
  handleCancel = () => {
    this.setState({ isAcceptModal: false });
  };
  handleFileModal = () => {
    this.setState({ isDefendModal: true });
  };
  handleModalClose = () => {
    this.setState({ isModalVisible: false ,file:null});
  };
  handleConfirmClick = () => {
    this.setState({ isLoading: true, isAcceptModal: false });
    this.acceptDisputedOrder(this.state.dispute_Details.order_id);
  };
  handleNextClick = () => {
    this.setState({ isModalVisible: true, isDefendModal: false });
  };

  acceptDisputedOrder = (payload) => {
    OrderServiceAPI.acceptDisputedOrder(payload).then((response) => {
      if (response.data.status) {
        this.setState({ isLoading: false });
        toast.success(response.data.message || "Dispute Accepted");
        this.props.history.push("/disputes");
      } else {
        this.setState({
          isLoading: false,
        });
        toast.warn(response.data.message);
      }
    });
  };

  uploadDefendDocs = (payload) => {
    OrderServiceAPI.defendDocs(payload).then((response) => {
      if (response.data.status) {
        this.setState({ isLoading: false });
        this.defendDisputedOrder(this.state.dispute_Details.order_id);
        toast.success(response.data.message || "Documents uploaded");
        this.props.history.push("/disputes");
      } else {
        this.setState({
          isLoading: false,
        });
        toast.warn(response.data.message);
      }
    });
  };

  defendDisputedOrder = (payload) => {
    OrderServiceAPI.defendDisputedOrder(payload).then((response) => {
      if (response.data.status) {
        this.getDefendReasons(payload);
        this.setState({ isLoading: false });
        toast.success(response.data.message);
      } else {
        this.setState({
          isLoading: false,
        });
        toast.warn(response.data.message);
      }
    });
  };

  getDefendReasons = (payload) => {
    OrderServiceAPI.defendReasons(payload).then((response) => {
      if (response.data.status) {
        this.setState({
          disputeReasons: response?.data?.data[0].defenseDocumentTypes,
          isLoading: false,
        });
        toast.success(response.data.message);
      } else {
        this.setState({
          isLoading: false,
        });
        toast.warn(response.data.message);
      }
    });
  };

  render() {
    const {
      dispute_Details,
      isAcceptModal,
      isDefendModal,
      selectedValue,
      isModalVisible,
      disputeReasons,
      file,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb />
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h4" component="h6" gutterBottom>
                    Dispute Details
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{ color: "grey", fontWeight: 500 }}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.disputed_status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        PSP Reference
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.disputed_psp_reference}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Auto Defended
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.auto_defended}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Reason
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.reason}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Disputed Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {getCurrencySymbol(
                          localStorage.getItem("currency_code")
                        ) + dispute_Details.disputed_amount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Dispute Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dayjs(dispute_Details.dispute_date).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Defend Period Ends
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dayjs(dispute_Details.disputed_expiry_date).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h4" component="h6" gutterBottom>
                    Payment Details
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Merchant Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.merchant_account}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Card Number
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.card_summary}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Card Expiry Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.card_expiry_date}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Card Type
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.payment_method}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        PSP Reference
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dispute_Details.payment_psp_reference}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Payment Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {dayjs(dispute_Details.payment_date).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "grey", fontWeight: 500 }}
                        gutterBottom
                      >
                        Order Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ color: "black", fontWeight: "600" }}
                        gutterBottom
                      >
                        {getCurrencySymbol(
                          localStorage.getItem("currency_code")
                        ) + dispute_Details.disputed_amount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {dispute_Details.disputed_status === "Undefended" && (
                <Grid item xs={12}>
                  <Paper
                    style={{
                      padding: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="h6" component="h6">
                        What action do you want to take with this dispute ?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          backgroundColor: "red",
                          width: "250px",
                          height: "50px",
                          padding: "10px",
                          textTransform: "none",
                          fontSize: "16px",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        onClick={this.handleDefend}
                      >
                        <b>Defend</b>
                      </Button>

                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          backgroundColor: "green",
                          width: "250px",
                          height: "50px",
                          padding: "10px",
                          textTransform: "none",
                          fontSize: "16px",
                          alignItems: "center",
                          marginLeft: { xs: "130px !important", md: "none" },
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                        onClick={this.handleAccept}
                      >
                        <b>Accept</b>
                      </Button>
                    </Grid>
                  </Paper>
                </Grid>
              )}
              <Dialog
                open={isAcceptModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  {
                    "The amount in this dispute will be refunded to the customer.Are you sure you want to proceed with the refund?"
                  }
                </DialogTitle>

                <Stack
                  direction="row"
                  sx={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: "180px", height: "50px" }}
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: "180px",
                      backgroundColor: "red",
                      height: "50px",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    onClick={this.handleConfirmClick}
                  >
                    Confirm{" "}
                    {getCurrencySymbol(localStorage.getItem("currency_code")) +
                      dispute_Details.disputed_amount}
                  </Button>
                </Stack>
              </Dialog>
              <Dialog
                open={isDefendModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={this.handleDefendCancel}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    textAlign: "center",
                    marginTop: "20px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    width: "90%",
                  }}
                >
                  {"Upload defence document"}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={this.handleDefendCancel}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    zIndex: 9999,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <FormControl
                  sx={{
                    paddingLeft: "40px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <RadioGroup
                    column
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedValue}
                    onChange={this.handleRadioChange}
                  >
                    {disputeReasons.map((reason, index) => (
                      <FormControlLabel
                        key={index}
                        value={reason?.defenseDocumentTypeCode}
                        control={<Radio />}
                        label={
                          <Typography variant="h6">
                            {reason?.defenseDocumentTypeCode}{" "}
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ width: "280px", height: "50px", fontSize: "14px" }}
                    onClick={this.handleNextClick}
                    disabled={!selectedValue}
                  >
                    next
                  </Button>
                </Stack>
              </Dialog>
              <Modal
                title=""
                open={isModalVisible}
                onCancel={this.handleModalClose}
              >
                <Container>
                  <Grid container sx={{ mb: 3, mt: 2 }}>
                    <Grid item xs={6}>
                      <Typography variant="h5" align="left">
                        Attach
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={12}>
                      <div
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                        style={{
                          border: "2px dashed #ccc",
                          padding: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                          height: "300px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <React.Fragment>
                          <IconButton style={{ marginBottom: "10px" }}>
                            <FileUploadIcon
                              style={{ fontSize: 60, color: "#5773ff" }}
                            />
                          </IconButton>
                          {!file ? (
                            <>
                              <Typography variant="body1">
                                <b> Drag and drop files here </b>
                              </Typography>
                              <Typography>Or</Typography>
                              <Typography
                                color={"rgb(87, 115, 255)"}
                                variant="body1"
                              >
                                <b>Browse Files</b>
                              </Typography>
                              <input
                                type="file"
                                id="fileInput"
                                multiple
                                accept="application/pdf"
                                style={{ display: "none" }}
                                onChange={this.handleFileChange}
                              />
                            </>
                          ) : (
                            <Typography
                              color={"rgb(87, 115, 255)"}
                              variant="body1"
                            >
                              <b>{file.name}</b>
                            </Typography>
                          )}
                        </React.Fragment>
                        <React.Fragment>
                          <IconButton style={{ marginBottom: "10px" }}>
                            {/* <Videocam style={{ fontSize: 60 }} /> */}
                          </IconButton>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            {/* <b> {file[0]?.name} </b> */}
                          </Typography>
                        </React.Fragment>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={this.handleSubmit}
                        disabled={!file}
                      >
                        Upload File
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Modal>
              {!(
                dispute_Details.disputed_status === "Undefended" ||
                dispute_Details.disputed_status === "PAYMENT DISPUTE ACCEPTED"
              ) && (
                <Grid container sx={{ marginLeft: "15px", marginTop: "10px",visibility:"hidden" }}>
                  <TableContainer component={Paper}>
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={6}>
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={{ padding: "20px" }}
                        >
                          Upload Documents
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleFileModal}
                          startIcon={<AddIcon style={{ color: "white" }} />}
                          sx={{ float: "right" }}
                        >
                          Upload Documents
                        </Button>
                      </Grid>
                    </Grid>

                    <Table aria-label="simple table">
                      <TableHead sx={{ padding: "10px" }}>
                        <TableRow>
                          <TableCell
                            style={{ fontSize: "1.3em", color: "grey" }}
                          >
                            File Name
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.3em", color: "grey" }}
                          >
                            Type
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "1.3em", color: "grey" }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* {files?.length === 0 ? ( */}
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography variant="body1">
                            No items found
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {/* ) : ( */}
                      {/* files?.map((file, index) => ( */}
                      <TableRow
                      // key={index}
                      >
                        <TableCell component="th" scope="row">
                          {/* {file?.name} */}
                        </TableCell>
                        {/* <TableCell> <VideocamIcon sx={{fontSize:"32px"}}/> </TableCell> */}

                        <TableCell>
                          <IconButton aria-label="delete" color="error">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {/* )) */}
                      {/* )} */}
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Detailcontent);
