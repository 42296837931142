import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const deleteConfirm = (props) => {
  console.log(props)
  Swal.fire({
    title: `Delete ${props.name}`, // Name you want to display on pop up
    text: props.text ?  props.text:  "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete",
    customClass: {
      container: "my-swal",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      props
        .deleteFunc(props.id) // ID of content you want to delete
        .then((response) => {
          console.log("ee", response);
          if (response.data.status) {
            toast.success(props.message);
            props.setSelectedCategory && props.setSelectedCategory(props.categoryId);
            for (const func of props.func) {
              //List of all the functions you want to call after delete is successful
              func();
            }
          } else {
            if(response && response.data && response.data.message){
              toast.warn(response.data.message);
            } else {
              toast.error("Something went wrong")
            }         
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Error deleting", error);
        });
    }
  });
};
