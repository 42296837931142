import React, { Component } from "react";
import * as NotificationServiceAPI from "../../services/notification/NotificationServiceAPI";
import Breadcrumb from "./Breadcrumb";
import TableSetting from "./TableSetting";
import OrderSetting from "./OrderSetting";
import ServiceSetting from "./ServiceSetting";
import { toast } from "react-toastify";
import Loader from "../../shared/Loader";
import { connect } from "react-redux";
import ChargebackSetting from "./ChargebackSettings";
class index extends Component {
  state = {
    table_email: "",
    is_table_email_active: false,
    is_table_in_software_active: true,
    is_table_push_notification_active: true,
    is_table_sms_active: false,
    table_phone_number: "",

    order_email: "",
    is_order_email_active: false,
    is_order_in_software_active: true,
    is_order_push_notification_active: true,
    is_order_sms_active: false,
    order_phone_number: "",

    service_email: "",
    is_service_email_active: false,
    is_service_in_software_active: true,
    is_service_push_notification_active: true,
    is_service_sms_active: false,
    service_phone_number: "",

    chargeback_email: "",
    is_chargeback_email_active: false,
    is_chargeback_in_software_active: true,
    is_chargeback_push_notification_active: true,
    is_chargeback_sms_active: false,
    chargeback_phone_number: "",

    property_id: "",
    type: "",
    isLoading: true,
  };

  componentDidMount = () => {
    const property = this.props.propertyId;
    this.setState({ property_id: property });
    if (property) {
      this.getNotificationSetting(property);
    } else {
      this.setState({ isLoading: false });
    }
  };

  updateNotificationSetting = (payload) => {
    this.setState({ isLoading: true });
    NotificationServiceAPI.updateNotificationSetting(payload).then(
      (response) => {
        if (response.data.status) {
          this.getNotificationSetting(this.state.property_id);
          this.setState({ isLoading: false });
          toast.success("Setting Updated Successfully");
        } else {
          toast.error(response.message);
          this.setState({ isLoading: false });
        }
      }
    );
  };

  getNotificationSetting = (property_id) => {
    NotificationServiceAPI.fetchNotificationSettings(property_id)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.table_reservation_notification_setting) {
            const table_reservation_notification_setting =
              response.data.data?.table_reservation_notification_setting;
            this.setState({
              table_email: table_reservation_notification_setting?.email,
              is_table_email_active:
                table_reservation_notification_setting?.is_email_active,
              is_table_in_software_active:
                table_reservation_notification_setting?.is_in_software_active,
              is_table_push_notification_active:
                table_reservation_notification_setting?.is_push_notification_active,
              is_table_sms_active:
                table_reservation_notification_setting?.is_sms_active,
              table_phone_number:
                table_reservation_notification_setting?.phone_number?.replace(
                  "+",
                  ""
                ),
            });
          }
          if (response.data.data.order_notification_setting) {
            const order_notification_setting =
              response.data.data?.order_notification_setting;
            this.setState({
              order_email: order_notification_setting?.email,
              is_order_email_active:
                order_notification_setting?.is_email_active,
              is_order_sms_active: order_notification_setting?.is_sms_active,
              is_order_in_software_active:
                order_notification_setting?.is_in_software_active,
              is_order_push_notification_active:
                order_notification_setting?.is_push_notification_active,
              order_phone_number:
                order_notification_setting?.phone_number?.replace("+", ""),
            });
          }
          if (response.data.data.service_notification_setting) {
            const service_notification_setting =
              response.data.data?.service_notification_setting;
            this.setState({
              service_email: service_notification_setting?.email,
              is_service_email_active:
                service_notification_setting?.is_email_active,
              is_service_sms_active:
                service_notification_setting?.is_sms_active,
              is_service_in_software_active:
                service_notification_setting?.is_in_software_active,
              is_service_push_notification_active:
                service_notification_setting?.is_push_notification_active,
              service_phone_number:
                service_notification_setting?.phone_number?.replace("+", ""),
            });
          }
          if (response.data.data.chargeback_notification_setting) {
            const chargeback_notification_setting =
              response.data.data?.chargeback_notification_setting;
            this.setState({
              chargeback_email: chargeback_notification_setting?.email,
              is_chargeback_email_active:
                chargeback_notification_setting?.is_email_active,
              is_chargeback_sms_active:
                chargeback_notification_setting?.is_sms_active,
              is_chargeback_in_software_active:
                chargeback_notification_setting?.is_in_software_active,
              is_chargeback_push_notification_active:
                chargeback_notification_setting?.is_push_notification_active,
              chargeback_phone_number:
                chargeback_notification_setting?.phone_number?.replace("+", ""),
            });
          }
          this.setState({ isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  toggleHandler = (current) => {
    this.setState({
      [current]: !this.state[current],
    });
  };

  render() {
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
        </div>

        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <React.Fragment>
            <div className="ms-content-wrapper pb-0 pt-0">
              <OrderSetting
                state={this.state}
                toggleHandler={this.toggleHandler}
                propertyId={this.props.propertyId}
                updateNotificationSetting={this.updateNotificationSetting}
              />
            </div>

            <div className="ms-content-wrapper pb-0 pt-0">
              <ServiceSetting
                state={this.state}
                toggleHandler={this.toggleHandler}
                propertyId={this.props.propertyId}
                updateNotificationSetting={this.updateNotificationSetting}
              />
            </div>
            <div className="ms-content-wrapper pb-0 pt-0">
              <ChargebackSetting
                state={this.state}
                toggleHandler={this.toggleHandler}
                propertyId={this.props.propertyId}
                updateNotificationSetting={this.updateNotificationSetting}
              />
            </div>
            <div className="ms-content-wrapper pb-0 pt-0">
              <TableSetting
                state={this.state}
                toggleHandler={this.toggleHandler}
                propertyId={this.props.propertyId}
                updateNotificationSetting={this.updateNotificationSetting}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
