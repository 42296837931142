import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';
import * as schema from "../../../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import {
    duplicateAddOn,
    fetchExtrasOptionsByItemId,
} from "../../../../services/product/item/ItemServiceAPI";
import { fetchAllAddonCategory } from "../../../../services/product/addonCategory/AddonCategoryServiceAPI";
import {
    fetchCategoryNames,
    fetchCategory,
} from "../../../../services/product/category/CategoryServiceAPI";
export default function CopyExtrasOptions(props) {

    let initialVals = {
        sort_order: '',
        category_id: '',
        menu_id: '',
        item_id: '',
        addon_id: '',
        //   addon_category: ''
    }

    const [open, setOpen] = React.useState(false);

    const [initialValues, setInitialValues] = useState(initialVals);
    const [selectedMenu, setSelectedMenu] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [extras, setExtras] = useState([]);
    const [options, setOptions] = useState([]);
    const [addons, setAddons] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [addOnId, setAddOnId] = useState(props.addonId);
    const [cateId, setCateId] = useState("");
    const [selectedAddOnCategory, setSelectedAddOnCategory] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCategoryNames(null);
        setSelectedCategory("");
        setSelectedMenu("");
        setSelectedItem("");
        setAddons(null);

    };

    const handleMenuChange = (e, setFieldValue) => {
        console.log("Handle Upsell Menu")
        console.log(e)
        setSelectedMenu(e.target.value);
        setFieldValue("menu_id", e.target.value);
        fetchCategoryNames(e.target.value);
        // this.props.setSelectedMenu(event.target.value);
    }

    const  fetchCategoryNames = async (selectedUpsellMenu) => {
        try {

            let categories = await fetchCategory(selectedUpsellMenu);

            if (
                categories.data.status == false ||
                categories.data.message == "No details found."
            ) {
               // setLoading(false);
                setCategoryNames([]);
                setSelectedCategory(null);
                return;
            } else {
                setCategoryNames(categories.data.data.category_response_bean_list);
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const handleCategoryChange = (e, setFieldValue) => {
        console.log("Handle Upsell Category")
        console.log(e)
        setSelectedCategory(e.target.value)
        setFieldValue("category_id", e.target.value);
        props.fetchItems(e.target.value);
        // this.props.setSelectedMenu(event.target.value);
    }

    const handleItemChange = async (e, setFieldValue) => {
        console.log("Handle Upsell Category")
        console.log(e)
        setSelectedItem(e.target.value)
        setFieldValue("item_id", e.target.value);
        await fetchAllAddons(e.target.value)

        // this.props.setSelectedMenu(event.target.value);
    }


    const handleAddOnCategoryChange = (e, setFieldValue) => {
        console.log("Handle Upsell Category")
        console.log(e)
        setSelectedAddOnCategory(e.target.value)
        setFieldValue("addon_category", e.target.value);


    }

    const handleAddonChange = (e, setFieldValue) => {
        console.log("Handle Upsell Category")
        console.log(e)
        setCateId(e.target.value)
        setFieldValue("addon_id", e.target.value);

    }






    const submitCopy = (values, resetForm) => {




        if (!cateId) {
            console.log(cateId)
            toast.error("No target addon category was found. Item can't be copied.");
            return false;

        }

        const payload = {
            source_addon_id: addOnId,
            sort_order: values.sort_order,
            target_addon_category_id: cateId
        }

        console.log(payload)

        duplicateAddOn(payload)
            .then((response) => {
                if (response.data.status) {

                    toast.success("Addon copied!");
                    resetForm({ values: '' })
                    props.fetchAllItems();
                    setOpen(false);
                    setSelectedCategory("");
                    setSelectedMenu("");
                    setSelectedItem("");
                    setAddons(null);
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("something went wrong.");
            });


    }

    const fetchAllAddons = async (item_id) => {
        try {

            let allAddonsRes = await fetchAllAddonCategory(item_id);
            if (allAddonsRes.data.status) {

                console.log("OPTOPMS")
                console.log(props.addOnType);

                const addOns = allAddonsRes.data.data.filter(item => item.category_type === props.addOnType); // filter out items with addon_type = EXTRAS

                //      const categories = extras.flatMap(item => item.addon_category); // get an array of addon_category objects

                const categoryIds = addOns.map(cat => ({ // map category objects to category_id and category_identifier
                    category_id: cat.addon_category_id,
                    category_identifier: cat.addon_category_identifier
                }));


                console.log(categoryIds);

                setAddons(categoryIds);

                /*       const options = allAddonsRes.data.data.filter(item => item.category_type === 'OPTIONS'); // filter out items with addon_type = EXTRAS
      
                      //   const optionsCategories =  options.flatMap(item => item.addon_category); // get an array of addon_category objects
      
                      const optionCategoryIds = options.map(cat => ({ // map category objects to category_id and category_identifier
                          category_id: cat.addon_category_id,
                          category_identifier: cat.addon_category_identifier
                      })); */


                /*   setExtras(categoryIds)
                  setOptions(optionCategoryIds)
                  console.log(categoryIds);
                  console.log(optionCategoryIds); */

                /*  if (e.target.value == "EXTRAS") {
                     setAddons(extras);
                     console.log(extras)
                 } else if (e.target.value == "OPTIONS") {
                     setAddons(options);
                     console.log(options)
                 } */


                /* let optionsObject = allAddonsRes.data.data.filter(obj => obj.addon_type === props.addOnType);
                console.log(optionsObject)
                let categoryId = optionsObject.map(obj => obj.addon_category[0].category_id);
                console.log(categoryId)
                setCateId(categoryId); */

            }
        } catch (e) {
            console.log(e);
            setAddons([]);
            toast.error("No addon category found. Please create addon category first.");
        }
    };







    return (
        <React.Fragment>


            <Tooltip title="Copy Item" placement="right">
                <Button size="small" onClick={handleClickOpen} >
                    <FileCopyIcon />
                </Button>
            </Tooltip>


            {/*       {Object.keys(formErrors).length === 0 && isSubmitting && (
                <span className="success-msg">Signed in successfully</span>
            )} */}

            <Grid container spacing={1} style={{}}>
                <Dialog open={open} onClose={handleClose} maxWidth="md">

                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={schema.copyOptionsSchema}
                        onSubmit={(values, { resetForm }) => {
                            console.log(values)
                            submitCopy(values, resetForm);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <DialogContent sx={{ width: 600 }}>
                                    <DialogContentText>
                                        COPY {props.addOnType}
                                        <Divider />
                                        {/* <br></br>  {props.itemId} */}

                                        <br></br> <br></br>
                                        {/* {props.item.category_id} */}
                                    </DialogContentText>

                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6} md={6}>

                                            <FormControl fullWidth>
                                                <InputLabel id="menu_id">Menu</InputLabel>
                                                <Select
                                                    error={
                                                        touched.menu_id &&
                                                            errors.menu_id
                                                            ? true
                                                            : false
                                                    }
                                                    labelId="menu_id"
                                                    id="menu_id"
                                                    name="menu_id"
                                                    value={selectedMenu}
                                                    defaultValue={selectedMenu}
                                                    input={<OutlinedInput id="menu_id" label="Menu" />}
                                                    className="formControl"
                                                    disabled={!props.menuNames}
                                                    MenuProps={{
                                                        disableScrollLock: true
                                                    }}

                                                    onChange={(e, val) =>
                                                        handleMenuChange(e, setFieldValue)
                                                    }
                                                >
                                                    {props.menuNames &&
                                                        props.menuNames.map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.menu_id}
                                                                    value={item.menu_id}
                                                                >
                                                                    <span>{item.menu_identifier}</span>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                                <FormHelperText error>
                                                    {touched.menu_id && errors.menu_id
                                                        ? errors.menu_id
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>


                                        </Grid>


                                        <Grid item xs={6} md={6}  >
                                            <FormControl fullWidth>

                                                <InputLabel id="demo-dialog-select-label">Category</InputLabel>

                                                <Select
                                                    labelId="select-category"
                                                    id="category_id"
                                                    error={
                                                        touched.category_id &&
                                                            errors.category_id
                                                            ? true
                                                            : false
                                                    }
                                                    name="category_id"
                                                    value={selectedCategory}
                                                    onChange={(e, val) =>
                                                        handleCategoryChange(e, setFieldValue)
                                                    }
                                                    input={<OutlinedInput id="category_id" label="Category" />}
                                                    defaultValue={selectedCategory}
                                                    className="formControl" >
                                                    {categoryNames &&
                                                        categoryNames.map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    fullWidth
                                                                    key={item.category_id}
                                                                    value={item.category_id}
                                                                >
                                                                    {item.category_identifier}
                                                                </MenuItem>
                                                            );
                                                        })}

                                                </Select>
                                                <FormHelperText error>
                                                    {touched.category_id && errors.category_id
                                                        ? errors.category_id
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>


                                        </Grid>

                                        <Grid item xs={6} md={6}  >
                                            <FormControl fullWidth>

                                                <InputLabel id="item_id">Item</InputLabel>

                                                <Select
                                                    labelId="item_id"
                                                    id="item_id"
                                                    error={
                                                        touched.item_id &&
                                                            errors.item_id
                                                            ? true
                                                            : false
                                                    }
                                                    name="item_id"
                                                    value={selectedItem}
                                                    onChange={(e, val) =>
                                                        handleItemChange(e, setFieldValue)
                                                    }
                                                    input={<OutlinedInput id="item_id" label="Item" />}
                                                    defaultValue={selectedItem}
                                                    className="formControl" >
                                                    {props.itemNames &&
                                                        props.itemNames.map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    fullWidth
                                                                    key={item.item_id}
                                                                    value={item.item_id}
                                                                >
                                                                    {item.item_identifier}
                                                                </MenuItem>
                                                            );
                                                        })}

                                                </Select>
                                                <FormHelperText error>
                                                    {touched.item_id && errors.item_id
                                                        ? errors.item_id
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>


                                        </Grid>

                                        {/* 
                                        <Grid item xs={6} md={6} >
                                            <FormControl fullWidth>
                                                <InputLabel id="addon_category">Addon Category</InputLabel>
                                                <Select
                                                    labelId="addon_category"
                                                    id="addon_category"
                                                    error={
                                                        touched.addon_category &&
                                                            errors.addon_category
                                                            ? true
                                                            : false
                                                    }
                                                    name="addon_category"
                                                    value={selectedAddOnCategory}
                                                    defaultValue={selectedAddOnCategory}
                                                    label="Addon Category"
                                                    onChange={(e, val) =>
                                                        handleAddOnCategoryChange(e, setFieldValue)
                                                    }
                                                >
                                                    <MenuItem value={"Extras"}>Extras</MenuItem>
                                                    <MenuItem value={"Options"}>Options</MenuItem>

                                                </Select>

                                                <FormHelperText error>
                                                    {touched.addon_category && errors.addon_category
                                                        ? errors.addon_category
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>



                                        </Grid> */}
                                        <Grid item xs={6} md={6}>
                                            <FormControl fullWidth>

                                                <InputLabel id="addon_id">{props.addOnType}</InputLabel>

                                                <Select
                                                    labelId="addon_id"
                                                    id="addon_id"
                                                    error={
                                                        touched.addon_id &&
                                                            errors.addon_id
                                                            ? true
                                                            : false
                                                    }
                                                    name="addon_id"
                                                    value={values.addon_id}
                                                    onChange={(e, val) =>
                                                        handleAddonChange(e, setFieldValue)
                                                    }
                                                    input={<OutlinedInput id="addon_id" label={props.addOnType} />}
                                                    defaultValue={selectedItem}
                                                    className="formControl" >
                                                    {addons &&
                                                        addons.map((category) => {
                                                            return (
                                                                <MenuItem
                                                                    fullWidth
                                                                    key={category.category_id}
                                                                    value={category.category_id}
                                                                >
                                                                    {category.category_identifier}
                                                                </MenuItem>
                                                            );
                                                        })}

                                                </Select>
                                                <FormHelperText error>
                                                    {touched.addon_id && errors.addon_id
                                                        ? errors.addon_id
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>

                                        </Grid>


                                        <Grid item xs={6} md={6} >
                                            <FormControl fullWidth>
                                                <TextField
                                                    error={
                                                        touched.sort_order &&
                                                            errors.sort_order
                                                            ? true
                                                            : false
                                                    }
                                                    autoFocus
                                                    margin="dense"
                                                    id="sort_order"
                                                    label="Sort Order"
                                                    type="text"
                                                    name="sort_order"
                                                    fullWidth
                                                    variant="standard"
                                                    value={values.sort_order}
                                                    onChange={handleChange}

                                                />
                                                <FormHelperText error>
                                                    {touched.sort_order && errors.sort_order
                                                        ? errors.sort_order
                                                        : null}
                                                </FormHelperText>

                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        block
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Copy
                                    </Button>
                                </DialogActions>

                            </Form>

                        )}
                    </Formik>

                </Dialog>
            </Grid>
        </React.Fragment >
    );
}
