import React from 'react';



//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';


//Image upload modules
import { Upload, Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import 'antd/dist/reset.css';

class Uploader extends React.Component {



  render() {

    //Uploaded url
    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        //reader.onerror = error => reject(error);
      });
    }


    class PicturesWall extends React.Component {
      state = {
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: [

        ],
      };

      handleCancel = () => this.setState({ previewVisible: false });


      //Image Preview
      handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
          previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
      };

      handleChange = ({ fileList }) => this.setState({ fileList });

      handleSubmit = event => {
        event.preventDefault();

        let formData = new FormData();
        // add one or more of your files in FormData
        // again, the original file is located at the `originFileObj` key

        //Files Storage Looping
        for (var a = 0; a < this.state.fileList.length; a++) {
          formData.append("file[]", this.state.fileList[a].originFileObj);

        }

        //File saving API call
        axios
          .post("http://localhost/save.php", formData)
          .then(res => {
            alert("Files uploaded.");

          })
          .catch(err => {
            console.log("err", err);
          });
      }

      render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
        return (
          <>
            <Upload

              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              beforeUpload={() => false}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >

              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Button className="btn btn-primary" color="primary" variant="contained" type="button" onClick={this.handleSubmit} // this button click will trigger the manual upload
            >
              Submit
            </Button>
          </>
        );
      }
    }


    return (
      <Grid container spacing={3} style={{ padding: 10 }}>


        <Grid item xs={12} sm={12}>

          <PicturesWall />
        </Grid>


      </Grid>
    );
  }
}

export default Uploader;