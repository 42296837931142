import React, { Component, useContext, useEffect } from 'react';
import { useState } from "react";

import { useSelector, useDispatch } from 'react-redux'

import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/UseContextProvider";

import { StepperContext } from "./contexts/UseContextProvider";

import Account from "./components/steps/Account";
import Details from "./components/steps/Details";
import Payment from "./components/steps/Payment";
import Final from "./components/steps/Final";
import Uploader from "./components/steps/Uploader";




import * as PaymentServiceAPI from "../../../../services/payment/PaymentServiceAPI";

import * as ProfileServiceAPI from "../../../../services/profile/ProfileServiceAPI";


import * as SecurityServiceAPI from "../../../../services/user/SecurityServiceAPI";

import countryList from "react-select-country-list";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import StepLabel from '@mui/material/StepLabel';

import { useLocation } from "react-router-dom"

import Loader from "../../../../shared/Loader";
import { setProperty } from "../../../../redux/property/index";



function Main() {
    //  const [currentStep, setCurrentStep] = useState(1);

    const initialVals = {
        name: "",
        phone_number: "",
        email: "",
        property_name: "",
        property_trading_name: "",
        property_address: "",
        second_line_address: "",
        property_city: "",
        property_country: "",
        property_country_code: "",
        country: "",
        postal_code: "",
        contact_name: "",
        contact_number: "",
        dial_code: "",
        phone: "",
        password: "",
        confirm_password: "",

        city: "",
        loading: false,
        country_options: countryList().getData(),
        country: "United Kingdom",
        country_code: "GB",
        business_type: "",
        business_types: [],
        username: "",
        currency_code: "",
        currency_symbol: "",
        currency_details: [],
        files: [null],
        cover_image: "",
        logoFile: null,
        imagesPreviewUrl: "",
        main_image: null,
        loading: false,
        individualCity: "",
        adyen: {},
        property_details: {},
        mes: "",
    };



    const { actStep, setActStep } = React.useContext(StepperContext);


    const [activeStep, setActiveStep] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [industryCode, setIndustryCode] = useState("");

    const [initialValues, setInitialValues] = useState(initialVals);

    const propertyId = useSelector(state => state.user.propertyId);
    const username = useSelector(state => state.user.username);

    const dispatch = useDispatch()

    const [adyen, setAdyen] = useState({});

    const location = useLocation()
    const params = new URLSearchParams(location.search)



    const steps = [
        "Kayana Setup",
        "Payment Account",
        "KYC",
        "Complete",
    ];

    const displayStep = (step) => {
        switch (step) {
            case 0:
                return <Account handleClick={handleClick} fetchProperty={fetchProperty} setIndCode={setIndCode} handleStep={handleStep} activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} initialValues={initialValues} />;
            case 1:
                return <Details fetchProperty={fetchProperty} handleClick={handleClick} industryCode={industryCode} handleStep={handleStep} activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} initialValues={initialValues} />;
            case 2:
                return <Payment fetchProperty={fetchProperty} handleClick={handleClick} handleStep={handleStep} activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} initialValues={initialValues} />;
            case 3:
                return <Final handleClick={handleClick} handleStep={handleStep} activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} initialValues={initialValues} />;
            default:
        }

    };


    useEffect(() => {

        setIsLoading(true);

        /*  if (params.get("step") == 4) {
             setActiveStep(3);
         } */

        fetchProperty();


        fetchAccount();




        handleStep();




    }, [initialValues]);


    const setIndCode = (industryCode) => {
        setIndustryCode(industryCode);
    }

    const fetchProperty = () => {

        const payload = username || localStorage.getItem("username");
        SecurityServiceAPI.fetchBusinessPlace(payload).then((response) => {
      //      console.log(response.data?.data?.property_details[0]);


            if (response.data.status) {
                const resp_len = response.data?.data?.property_details?.length;
                const property_details = response.data?.data?.property_details[0];

                if (resp_len !== 0) {
                    let tmp_property_id = property_details.property_id;

                    localStorage.setItem("property_id", tmp_property_id);
                    localStorage.setItem("property_details", JSON.stringify(property_details));
                    initialValues.property_details = property_details;
                    localStorage.setItem("currency_code", property_details?.currency_code);
                    localStorage.setItem("contact_number", property_details?.contact_number);
                    localStorage.setItem("business_code", property_details?.business_code);
                    setIndustryCode(property_details?.business_code);
          //          console.log(property_details)
                    setInitialValues(initialValues);
                    dispatch(setProperty(property_details));

                }
            }

        })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                console.log(error);

            });

    }





    const fetchAccount = () => {

        const payload = username || localStorage.getItem("username");

        ProfileServiceAPI.fetchProfile(payload)
            .then((response) => {
                console.log(response.data.status);
                if (response.data.status) {
                    //     this.setState({ loading: false, show: false });
                    //    toast.success("Message Sent Successfully");
         //           console.log(response.data.data);
                    initialValues.email = response.data.data.email;
                    initialValues.contact_name = response.data.data.name;
                    initialValues.contact_number = response.data.data.dial_code + response.data.data.phone;
                    initialValues.dial_code = response.data.data.dial_code;
                    setInitialValues(initialValues);
                    localStorage.setItem("email", response.data.data.email);
                    localStorage.setItem("name", response.data.data.name);
                    localStorage.setItem("phone", response.data.data.dial_code + response.data.data.phone);
                    localStorage.setItem("dial_code", response.data.data.dial_code);
        //            console.log(initialValues)
                    //   this.setState({ summary: response[0].data.data });
                } else {
                    //     toast.error(response.data.message);
                    //     this.setState({ loading: false });
                }


            })
            .catch((error) => {
                //  this.setState({ loading: false, show: false });
                console.log(error);
            });
    }

    const handleStep = () => {
        setIsLoading(true);
        if (localStorage.getItem("property_id") !== null) {

            const dump = {
                property_id: propertyId || localStorage.getItem("property_id"),
                username: username || localStorage.getItem("username")
            }

            PaymentServiceAPI.fetchAccountStatus(dump)
                .then((response) => {
     //               console.log(response.data.status);
                    if (response.data.status) {
      //                  console.log(response.data.data);
                        initialValues.adyen = response.data.data;
        //                console.log(params.get("step"))

                        if (params.get("step") == 4 && response.data.data.account_status == "ACCOUNT COMPLETED") {
                            console.log("if you need to update any information, please click the button below.")
                            initialValues.mes = "If you need to update any information, please click the button below."
                            setInitialValues(initialValues);
                            setIsLoading(false);
                            setActiveStep(3);

                        } else {
                            if (params.get("step") == 4 && response.data.data.account_status == "IN REVIEW") {
                                initialValues.mes = "You have completed the onboaring process. Your account will be activated after review is done. ";
                                setInitialValues(initialValues);
                                setActiveStep(3);
                                setIsLoading(false);
                            } else if (response.data.data.account_status == "ACCOUNT COMPLETED") {
                                console.log("if you need to update any information, please click the button below.")
                                initialValues.mes = "If you need to update any information, please click the button below."
                                setActiveStep(3);
                                setIsLoading(false);
                            }

                            else if (!params.get("step") && response.data.data.account_status == "IN REVIEW") {
                                initialValues.mes = "Complete KYC by clicking the button below to complete Verification. "
                                setActiveStep(2);
                                setIsLoading(false);
                            } else if (response.data.data.account_status == "ACCOUNT REVIEW FAILED") {
                                initialValues.mes = "Please complete onboarding process. "
                                setActiveStep(2);
                                setIsLoading(false);
                            }
                            else if (response.data.data.account_status == "ACCOUNT NOT INITIATED") {
                                setActiveStep(0);
                                setIsLoading(false);
                            }
                            else if (response.data.data.account_status == "LEGAL ENTITY UPDATED" && response.data.data.adyen_info?.legal_entity_id && response.data.data.adyen_info?.sole_proprieter_legal_entity_id && Object.keys(response.data.data.adyen_info?.business_lines).length > 0 ) {
                                setActiveStep(2);
                                setIsLoading(false);
                            }  
                            else if (response.data.data.account_status == "LEGAL ENTITY UPDATED" && response.data.data.adyen_info?.legal_entity_id && (response.data.data.adyen_info?.business_lines && Object.keys(response.data.data.adyen_info?.business_lines))?.length > 0) {
                                setActiveStep(2);
                                setIsLoading(false);
                            }  
                            else if (response.data.data.account_status == "LEGAL ENTITY UPDATED" && response.data.data.adyen_info?.legal_entity_id) {
                                setActiveStep(1);
                                setIsLoading(false);
                            }
                            else if (response.data.data.account_status == "ACCOUNT CREATED" || !(response.data.data.adyen_info?.legal_entity_id || Object.keys(response.data.data.adyen_info?.business_lines).length > 0)) {
                                initialValues.mes = "Please setup your payment account. "
                                setActiveStep(1);
                                setIsLoading(false);

                            } else if (response.data.data.adyen_info?.legal_entity_id && Object.keys(response.data.data.adyen_info?.business_lines).length > 0 && !response.data.data.adyen_info?.account_holder_id) {
                                initialValues.mes = "Complete KYC by clicking the button below to complete Verification. "
                                setActiveStep(2);
                                setIsLoading(false);
                            }
                            else if (response.data.data.adyen_info?.account_holder_id && Object.keys(response.data.data.adyen_info?.business_lines).length > 0 && response.data.data.adyen_info?.legal_entity_id) {
                                initialValues.mes = "Complete KYC by clicking the button below to complete Verification. "
                                setActiveStep(2);
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                            }

                        }

                        //   this.setState({ summary: response[0].data.data });
                    } else {
                        setIsLoading(false);
                    }


                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });

        }
    }

    const handleClick = (direction) => {
        let newStep = activeStep;

        direction === "next" ? newStep++ : newStep--;
        // check if steps are within bounds
        newStep >= 0 && newStep < steps.length && setActiveStep(newStep);

        fetchProperty();

        //   if (newStep === 4)
        handleSumbit();


    };


    const handleSumbit = () => {
        console.log("Submit")

    };

    const handleSteps = (step) => () => {

  //      console.log(initialValues.adyen);
  //      console.log(step);

        if (localStorage.getItem("property_id") !== null) {

            const dump = {
                property_id: propertyId || localStorage.getItem("property_id"),
                username: username || localStorage.getItem("username")
            }

            PaymentServiceAPI.fetchAccountStatus(dump).then((response) => {
                if (response.data.status) {
                    initialValues.adyen = response.data.data;
                    setInitialValues(initialValues);
                }
            })
                .catch((err) => {
                    console.log(err)
                });


        }

        if (initialValues.adyen.account_status == "ACCOUNT NOT INITIATED") {
            setActiveStep(0);
        } else if (initialValues.adyen.account_status == "LEGAL ENTITY UPDATED") {
            if (step == 0 || step == 1 || step == 2) {
                setActiveStep(step);
            }
        }
        else if (Object.keys(initialValues.adyen).length === 0 && step == 3) { }
        else if (Object.keys(initialValues.adyen).length === 0 && step == 2) { }
        else if (Object.keys(initialValues.adyen).length === 0 && step == 1) { }
        else if (Object.keys(initialValues.adyen).length === 0) {
            setActiveStep(0);
        }
        else if (initialValues.adyen.account_status == "ACCOUNT COMPLETED") {
            if (step == 3) {
                setActiveStep(step);
            }
        }
        else if (initialValues.adyen.account_status == "IN REVIEW") {
            if (step == 2) {
                setActiveStep(step);
            }
        } else if (initialValues.adyen.account_status == "ACCOUNT REVIEW FAILED") {
            if (step == 0 || step == 1 || step == 2) {
                setActiveStep(step);
            }
        }
        else if (initialValues.adyen.account_status == "ACCOUNT CREATED" || !(initialValues.adyen.adyen_info?.legal_entity_id || Object.keys(initialValues.adyen.adyen_info?.business_lines).length > 0)) {
            if (step == 0 || step == 1) {
                setActiveStep(step);
            }

        } else if (initialValues.adyen.adyen_info?.legal_entity_id && Object.keys(initialValues.adyen.adyen_info?.business_lines).length > 0 && !initialValues.adyen.adyen_info?.account_holder_id) {
            if (step == 0 || step == 1 || step == 2) {
                setActiveStep(step);
            }
        }
        else if (initialValues.adyen.adyen_info?.account_holder_id && Object.keys(initialValues.adyen.adyen_info?.business_lines).length > 0 && initialValues.adyen.adyen_info?.legal_entity_id) {
            if (step == 0 || step == 1 || step == 1 || step == 2) {
                setActiveStep(step);
            }
        }

        //  setActiveStep(step);
    };


    //md:w-1/2

    return (
        <Box sx={{ width: '100%' }}>

            <div className="col-12">
                <div className="ms-panel">
                    <div className="ms-panel-body">


                        {isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div> :


                            (<React.Fragment>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step key={label} completed={activeStep > index || activeStep == 3}>
                                            <StepButton color="inherit" onClick={handleSteps(index)}>
                                                <StepLabel>{label}</StepLabel>
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>




                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 2 }}>
                                    <UseContextProvider>

                                        {displayStep(activeStep)}


                                    </UseContextProvider>
                                </Box>

                                {/*   {activeStep !== steps.length - 1 && (
                                    <StepperControl
                                        handleClick={handleClick}
                                        currentStep={activeStep}
                                        steps={steps}
                                    />
                                )} */}

                            </React.Fragment>)}












                    </div>
                </div>
            </div>
        </Box>

    );
}

export default Main;