import axios from 'axios';
import * as APIUrl from '../APIUrl';


export const fetchDelevirictPlan = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_DELEVERICT_PLAN+ `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchEposNowTerms = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_EPOS_NOW_TERMS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchDeliverectTerms = (payload) => {

    try {
        return axios({
            method: 'get',  
            url: APIUrl.FETCH_DELEVIRECT_TERMS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const selectPlan = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.INTIATE_PAYMENT_DELEVERICT ,
            data: payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const saveDelivrect = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.SAVE_DELIVRECT_NOW,
            data: payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const subscribeNashPlan = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.NASH_SUBSCRIBE ,
            data: payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const applyDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.APPLY_DELEVIRECT_DISCOUNT + `discount_code=${payload.discount_code}&integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const removeDiscount = (payload) => {
    try {
        return axios({
            method: "post",
            url: APIUrl.REMOVE_DISCOUNT_DELEVIRECT + `integration_id=${payload.integration_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};





export const cancelEposPlan = (payload) => {
    try {
        return axios({
            method: 'post',  
            url: APIUrl.CANCEL_EPOS_PLAN + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}




export const generatePropertyDynamicLink = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.GENERATE_PROPERTY_DYNAMIC_LINK + `${payload.property_id}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const generateRoomDynamicLink = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.GENERATE_ROOM_DYNAMIC_LINK + `${payload.property_id}&room_id=${payload.room_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const generateTableDynamicLink = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.GENERATE_TABLE_DYNAMIC_LINK + `${payload.property_id}&table_id=${payload.table_id}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const generateTakeAway = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.GENERATE_TAKEAWAY_DYNAMIC_LINK+ `${payload.property_id}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const generateDining = (payload) => {

    try {
        return axios({
            method: 'post',  
            url: APIUrl.GENERATE_DINING_DYNAMIC_LINK+ `${payload.property_id}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchRoom = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl. EDIT_ROOM,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchAllGrants = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_GRANTS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetcchGrants = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_GRANTS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const fetchActiveGrant = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ACTIVE_GRANT + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const fetchGrantRepayments = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_GRANT_REPAYMENTS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const requestGrant = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ACCEPT_GRANT  + `${payload.property_id}&grant_offer_id=${payload.grant_offer_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteRoom = (payload) => {
    try {
        return axios({
            method: "delete",
            url: APIUrl.DELETE_ROOM + `${payload.room_id}`,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const updateHappyHourDiscount = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_HAPPY_HOUR_DISCOUNT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchHappyHourDiscount = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FIND_HAPPY_HOUR_DISCOUNT + `${payload}`,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAllHappyHourDiscountsItems = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.RETRIEVE_HAPPY_HOUR_DISCOUNT_ITEMS + `${payload}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const deleteDiscount = (payload) => {
    try {
        return axios({
            method: "delete",
            url: APIUrl.DELETE_DISCOUNT + payload,
        });
    } catch (error) {
        throw new Error(error);
    }
};

export const addDiscountCode = (payload) => {
    try {
        return axios({
            method: "get",
            url: APIUrl.ADD_DISCOUNT_CODE ,
        });
    } catch (error) {
        throw new Error(error);
    }
};
export const updateRoom= (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_ROOM,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}
