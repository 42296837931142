import React, { Component } from "react";
import { toast } from "react-toastify";
import * as CashServiceAPI from "../../services/cash/CashServiceAPI";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import { setSettings } from "../../redux/user/userSlice";
import * as SettingServiceAPI from "../../services/setting/SettingServiceAPI";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Switch } from "antd";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { connect } from "react-redux";
import FolderIcon from "@mui/icons-material/Folder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CheckCircleOutline } from "@mui/icons-material";
import Breadcrumb from "./Breadcrumb";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Divider } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Modal } from "antd";
import { Result } from "antd";
import tip from "../../assets/img/tips.svg";
import screenSaver from "../../assets/img/screenSaver.svg";
import vatSvg from "../../assets/img/vat.svg";
import { setWebOrderUserInfo } from "../../redux/misc/miscSlice";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Input from "@mui/material/Input";
import CreateIcon from "@mui/icons-material/Create";
import AntSwitch from "../../muiComponents/Switch/AntSwith";
import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import VideocamIcon from "@mui/icons-material/Videocam";
import InfoIcon from "@mui/icons-material/Info";
import {
  CardActionArea,
  InputAdornment,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Chip,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Container,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { DeleteOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { VideoLibrary, CloudUpload, Videocam } from "@mui/icons-material"; // Importing Material-UI icon
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Progress } from "antd";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";

const { confirm } = Modal;
const formGroup = {
  width: "100%", // Set the width to 100% to occupy the full row
  display: "flex", // Make the form group a flex container
  justifyContent: "left", // Align items with equal space between them
};

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const files = [
  { fileName: "video1.mp4", fileType: "video", status: "Uploaded" },
  { fileName: "video2.mp4", fileType: "video", status: "Uploaded" },
  { fileName: "document.pdf", fileType: "pdf", status: "Uploaded" },
];

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

const vatValidationSchema = Yup.object().shape({
  value: Yup.string().required("Percentage is required"),
  description: Yup.string().required("Description is required"),
});
const orderVatValidationSchema = Yup.object().shape({
  value: Yup.string().required("Percentage is required"),
  description: Yup.string().required("Description is required"),
});

class index extends Component {
  state = {
    isLoading: false,
    terms: {},
    eposPlan: {},
    eposInfo: {},
    formData: {
      title: "",
    },
    vatFormData: {
      value: "",
      description: "",
      is_default: false,
    },
    orderVatFormData: {
      value: "",
      description: "",
      is_required: true,
    },
    selectedCheckboxes: {
      KIOSK: false,
      EPOS: false,
      MPOS: false,
      WEB: false,
      QR: false,
      MOBILE: false,
    },
    errors: {},
    showEdit: false,
    isLoading: true,
    isTipActive: false,
    isTipSettingsActive: false,
    isCustomActive: false,
    settings: {},
    tipOptions: [],
    percentage: "",
    showTip: false,
    showKioskScreenSaver: false,
    showVat: false,
    enableVat: false,
    isScreenSaver: false,
    isModalVisible: false,
    files: [],
    filus: [],
    vats: [],
    vat: null,
    orderVats: [],
    vatOrder: null,
    progress: 0,
    uploading: false,
    muteCheckbox: false,
    is_kiosk_screen_saver: false,
    file: null,
    newUploadedFiles: [], // New uploaded files during the current upload proces
    isLoading: true,
    addVat: false,
    enableAdd: false,
    updateIndex: -1,
    addOrderVat: false,
    enableOrderAdd: false,
    updateOrderIndex: -1,
    open: false,
    deleteIndex: null,
    deleteOrderIndex: null,
    orderVatDelete: false,
    saverOpen: false,
    deleteFile: null,
    vatType: "item",
    taxPreference: "",
    isTaxExclusive: false,
    isTaxInclusive: false,
    vatNumber: "",
    vatOpen: false,
    showVatNumber: false,
    screenSaverPlan: {},
    screenTime:""
  };

  componentDidMount = () => {
    this.fetchAccountSetting();
    this.fetchScreenSaverPlan();
    this.setState(
      {
        isTipActive: this.props.settings?.is_tip,
        isCustomActive: this.props.settings?.tip_details?.custom_value,
        tipOptions: this.props.settings?.tip_details?.tip_options,
        selectedCheckboxes: { ...this.props.settings?.tip_details?.platforms },
        filus: [],
        is_kiosk_screen_saver:
          this.props.settings?.screensaver_detail?.is_kiosk_screen_saver,
        vatType:
          /* this.props.settings?.vat_details?.is_order_level ? "order" : */ "item",
        isTaxExclusive: this.props.settings?.vat_details?.is_tax_exclusive,
      },
      () => {
        if (this.state.isTaxExclusive) {
          this.setState({
            taxPreference: "isTaxExclusive",
            isTaxExclusive: true,
          });
        } else {
          this.setState({
            taxPreference: "isTaxInclusive",
            isTaxInclusive: true,
          });
        }
      }
    );
    this.setState(
      { vatNumber: this.props.settings?.vat_details?.vat_number },
      () => {
        if (this.state.vatNumber) {
          this.setState({ showVatNumber: true });
        }
      }
    );
    this.unlisten = this.props.history.listen((location, action) => {
      if (
        location.pathname !== "epos-now" &&
        location.pathname !== "epos-activate"
      ) {
        this.props.setWebOrderUserInfo(null);
      }
    });
  };

  fetchScreenSaverPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await SettingServiceAPI.fetchScreenSaverPlan(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              screenSaverPlan: response.data.data,
            },
            () => {
              if (
                response.data.data.subscription_status === "EXPIRED" ||
                response.data.data.subscription_status === "CANCELLED"
              ) {
                //this.props.history.push({ pathname: "/inventory-accept" });
                // this.setState({ url: {} });
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ inventoryPlan: {} });
        //this.props.history.push({ pathname: "/inventory-accept" });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ inventoryPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
      this.props.history.push({ pathname: "/inventory-accept" });
    }
  };

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    this.unlisten();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    validationSchema
      .validate(this.state.formData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.payload,
          ...this.state.formData,
          property_id: this.props.propertyId,
        };
        this.uploadFile();
      })
      .catch((errors) => {
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails });
      });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the entered value is a valid number or decimal within the range of 0 to 100
    if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
      const floatValue = parseFloat(value);
      if (value === "" || (floatValue >= 0 && floatValue <= 100)) {
        this.setState({
          [name]: value,
        });
      }
    }
  };

  handleClick = () => {
    // Your function logic here
    this.setState({ showEdit: false });
  };
  handleOpen = (id) => {
    this.setState({ open: true, deleteIndex: id });
  };
  handleOrderOpen = (id) => {
    this.setState({ open: true, orderVatDelete: true, deleteOrderIndex: id });
  };
  handleClose = () => {
    this.setState({ open: false, vatOpen: false });
  };
  handleSaverClose = () => {
    this.setState({ saverOpen: false });
  };

  handleTipActiveChange = (checked) => {
    if (!this.state.isTipActive)
      this.setState({ isTipActive: checked, showTip: checked }, () => {
        this.handleTipSubmit(true);
      });
    else {
      this.setState({ isTipActive: false, showTip: false }, () => {
        this.handleTipSubmit(true);
      });
    }
  };

  handleScreenSaverChange = (checked) => {
    if (!this.state.is_kiosk_screen_saver)
      this.setState(
        { is_kiosk_screen_saver: checked, showKioskScreenSaver: checked },
        () => {
          this.saveScreenSaver();
        }
      );
    else {
      this.setState(
        { is_kiosk_screen_saver: false, showKioskScreenSaver: checked },
        () => {
          this.saveScreenSaver();
        }
      );
    }
  };
  handleScreenSaver = (checked) => {
    this.setState((prevState) => ({
      showKioskScreenSaver: !prevState.showKioskScreenSaver,
    }));
  };

  handleScreenSaverSubscribe = () => {
    this.props.history.push({ pathname: "/screen-saver-terms-conditions" });
  };

  handleCancelSubscription = async () => {
    const dump = this.state.screenSaverPlan.integration_Id;
    try {
      const response = await SettingServiceAPI.cancelScreenSaverPlan(dump);

      if (response.data.status) {
        toast.success("Screensaver plan is cancelled.");
        this.fetchScreenSaverPlan();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        this.setState({ isModalOpen: false });
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ inventoryPlan: {}, isModalOpen: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ inventoryPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };

  handleVat = (checked) => {
    this.setState({ showVat: checked, enableVat: checked });
  };

  handleCutomChange = (checked) => {
    this.setState({ isCustomActive: checked });
  };

  handlePlatformChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      selectedCheckboxes: {
        ...this.state.selectedCheckboxes,
        [name]: checked,
      },
    });
  };

  handleDelete = (option) => {
    const newTipOptions = this.state.tipOptions.filter(
      (item) => item !== option
    );
    this.setState({ tipOptions: newTipOptions });
  };

  handleAddTipOption = () => {
    const { percentage, tipOptions } = this.state;

    if (tipOptions.length < 4) {
      if (percentage !== "") {
        const percentageValue = parseFloat(percentage, 10);
        // Round percentageValue to two decimal places
        const roundedPercentage = Math.round(percentageValue * 100) / 100;
        if (
          !tipOptions.some(
            (option) => Math.round(option * 100) / 100 === roundedPercentage
          )
        ) {
          this.setState({
            tipOptions: [...tipOptions, roundedPercentage],
            percentage: "",
          });
        } else {
          toast.warn("This percentage already exists");
        }
      }
    } else {
      toast.warn("Maximum number of tip values already added.");
    }
  };

  handleTipSubmit = (bool) => {
    const { isTipActive, isCustomActive, tipOptions, selectedCheckboxes } =
      this.state;
    const payload = {
      is_tip: isTipActive,
      property_id: this.props.propertyId,
      tip_details: {
        custom_value: isCustomActive,
        platforms: { ...selectedCheckboxes },
        tip_options: tipOptions,
      },
    };
    this.saveTip(payload, bool);
  };

  saveTip = async (data, bool) => {
    try {
      this.setState({ isLoading: true });
      const response = await SettingServiceAPI.saveTip(data);

      if (response.data && response.data.status) {
        if (bool) {
          const message = data?.is_tip ? "Tip activated." : "Tip deactivated.";
          toast.success(message);
        } else {
          toast.success("Tip settings saved.");
        }
        this.fetchAccountSetting();
        this.setState({ isLoading: false, showTip: false });
        //setTimeout(() => { window.location.reload() }, 2000)
      } else {
        this.setState({ isLoading: false });
        const err =
          response.data.message || "Couldn't save tip setting information.";
        toast.warn(err);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  handleCancel = () => {
    this.setState({ showTip: false });
    window.location.reload();
  };

  fetchAccountSetting() {
    const dump = {
      property_id: this.props.propertyId,
    };

    PaymentServiceAPI.fetchAccountSetting(dump)
      .then((response) => {
        if (response.data && response.data.status) {
          this.props.setSettings && this.props.setSettings(response.data.data);
          this.setState({
            isLoading: false,
            is_kiosk_screen_saver:
              this.props.settings?.screensaver_detail?.is_kiosk_screen_saver,
            muteCheckbox:
              this.props.settings?.screensaver_detail?.mute_video || false,
            screenTime:this.props.settings?.screensaver_detail?.screensaver_time_out || "",
            isTipActive: this.props.settings?.is_tip,
            isCustomActive: this.props.settings?.tip_details?.custom_value,
            tipOptions: this.props.settings?.tip_details?.tip_options,
            selectedCheckboxes: {
              ...this.props.settings?.tip_details?.platforms,
            },
            files: [...this.props.settings?.screensaver_detail?.path],
            vats: [...this.props.settings?.vat_details?.item_vat_details],
            orderVats: [...this.props.settings?.vat_details?.order_vat_details],
            showVat: this.props.settings?.vat_details?.is_vat,
          });
          if (this.props.settings?.screensaver_detail?.path) {
            //this.handleFetchVideo(this.props.settings?.screensaver_detail?.path, "screen.mp4");
          }
        } else {
          this.props.setSettings && this.props.setSettings({});
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  handleFetchVideo = async (videoUrl) => {
    try {
      const response = await fetch(videoUrl, {
        mode: "no-cors",
      });

      if (!response) {
        throw new Error("Failed to fetch the video.");
      }

      const blob = await response.blob();

      const reader = new FileReader();

      reader.onloadend = () => {
        // Convert the video to a data URL
        const dataURL = reader.result;
        // If you want to create a File object, you can do it here
        const file = new File([blob], "screen.mp4", { type: blob.type });
        this.setState({ file: file });
      };

      reader.onerror = (error) => {
        console.error("Error occurred while reading the video:", error);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error occurred while fetching the video:", error);
    }
  };

  handleTipSettingsToggle = () => {
    this.setState((prevState) => ({
      showTip: !prevState.showTip,
    }));
  };

  handleUploadClick = () => {
    this.setState({ isModalVisible: true });
  };

  handleModalOk = () => {
    this.setState({ isModalVisible: false });
    // Add logic to handle file upload here
  };

  handleModalCancel = () => {
    this.setState({ isModalVisible: false });
  };

  uploadFile = () => {
    this.setState({ isModalVisible: false, files: [...this.state.filus] },()=>{this.saveScreenSaver()});
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Iterate over each file to validate
    files.forEach((file) => {
      // Check file size
      /* if (file.size > 25 * 1024 * 1024) { // 20 MB in bytes
        alert('File size must be less than 20 MB.');
        return;
      } */

      const video = document.createElement("video");
      video.preload = "metadata";

      if (file.type.startsWith("video/")) {
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);

          // Check video duration
          if (video.duration < 10 || video.duration > 60) {
            alert("Video duration must be between 10 and 60 seconds.");
            return;
          }

          // Check video resolution
          if (video.videoWidth > 1080 || video.videoHeight > 1920) {
            alert("Video resolution must be 1080x1920 or lower.");
            return;
          }

          // If all checks pass, proceed with adding the file to state
          this.setState(
            //{ filus: [...this.state.files, ...files], file: files },
            (prevState) => ({ filus: [...prevState.files, file], file: files }),
            () => {
              // Call handleUpload when files are selected
              this.handleUpload([...this.state.filus]);
            }
          );
        };

        video.onerror = () => {
          alert("Invalid video file.");
        };

        video.src = URL.createObjectURL(file);
      } else if (file.type.startsWith("image/") || file.type === "image/gif") {
        // If the file is an image or GIF, no additional checks are needed
        this.setState(
          //{ filus: [...this.state.files, ...files], file: files },
          (prevState) => ({ filus: [...prevState.files, file], file: files }),
          () => {
            // Call handleUpload when files are selected
            this.handleUpload(this.state.filus);
          }
        );
      } else {
        alert("Unsupported file type.");
      }
    });
  };

  handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.target.files);

    // Iterate over each file to validate
    files.forEach((file) => {
      // Check file size
      /*  if (file.size > 25 * 1024 * 1024) { // 20 MB in bytes
        alert('File size must be less than 20 MB.');
        return;
      } */

      const video = document.createElement("video");
      video.preload = "metadata";

      if (file.type.startsWith("video/")) {
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);

          // Check video duration
          if (video.duration < 10 || video.duration > 60) {
            alert("Video duration must be between 10 and 60 seconds.");
            return;
          }

          // Check video resolution
          if (video.videoWidth > 1080 || video.videoHeight > 1920) {
            alert("Video resolution must be 1080x1920 or lower.");
            return;
          }

          // If all checks pass, proceed with adding the file to state
          this.setState(
            { filus: [...this.state.files, ...files], file: files },
            () => {
              // Call handleUpload when files are selected
              this.handleUpload([...this.state.filus, ...files]);
            }
          );
        };

        video.onerror = () => {
          alert("Invalid video file.");
        };

        video.src = URL.createObjectURL(file);
      } else if (file.type.startsWith("image/") || file.type === "image/gif") {
        // If the file is an image or GIF, no additional checks are needed
        this.setState(
          { filus: [...this.state.files, ...files], file: files },
          () => {
            // Call handleUpload when files are selected
            this.handleUpload(this.state.files);
          }
        );
      } else {
        alert("Unsupported file type.");
      }
    });
  };

  handleDragOver = (event) => {
    event.preventDefault();
  };
  handleEnterClick = () => {
    this.setState({ showVatNumber: true });
  };
  handleEditClick = () => {
    this.setState({ showVatNumber: false });
  };

  handleUpload = (files) => {
    this.setState({ uploading: true });
    const totalFiles = this.state.files.length;
    const startTime = Date.now();
    const uploadDuration = 5000; // 5 seconds for demonstration

    const simulateUpload = () => {
      const elapsedTime = Date.now() - startTime;
      let progress = Math.min((elapsedTime / uploadDuration) * 100, 100); // Ensure progress doesn't exceed 100

      this.setState({ progress });

      if (progress < 100) {
        // Continue simulating upload until progress reaches 100%
        setTimeout(simulateUpload, 100); // Update progress every 100 milliseconds
      } else {
        // Upload completed
        this.setState(
          (prevState) => ({
            uploading: false,
            //files: files,
            progress: 0,
          }),
          () => {
            //  this.setState({ isModalVisible: false });
            //  this.saveScreenSaver();
          }
        );
      }
    };

    // Start simulating upload
    setTimeout(simulateUpload, 100);
  };

  saveScreenSaver = async () => {
    this.setState({ isLoading: true });
    const { is_kiosk_screen_saver, muteCheckbox, file, title, formData,screenTime } =
      this.state;
    const screenSaverData = new FormData();

    const payload = {
      screen_saver_details: {
        propertyId: this.props.propertyId,
        screensaver_detail: {
          is_kiosk_screen_saver: is_kiosk_screen_saver,
          mute_video: muteCheckbox,
        },
      },
      screensaver_image_or_video: file ? file[0] : " ",
      title: formData.title || " ",
      timeout_in_seconds:screenTime||"",
    };
    if (file) {
      screenSaverData.append("title", payload.title);
      screenSaverData.append(
        "screensaver_image_or_video",
        payload.screensaver_image_or_video
      );
    }
    screenSaverData.append("timeout_in_seconds", payload.timeout_in_seconds);
    screenSaverData.append(
      "screen_saver_details",
      JSON.stringify(payload.screen_saver_details)
    );
    // screenSaverData.append("title", payload.title);
    const response = await SettingServiceAPI.saveScreenSaver(screenSaverData);

    if (response && response.data && response.data?.status) {
      toast.success("Screensaver settings submitted.");
      this.fetchAccountSetting();
      this.setState({
        isModalVisible: false,
        isLoading: false,
        filus: [],
        file: null,
        // showKioskScreenSaver: false,
      });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          title: "", // Set 'title' to an empty string
        },
      }));
    } else {
      toast.warn("Coudln't add screensaver.");
      this.setState({
        isModalVisible: false,
        isLoading: false,
        filus: [],
        file: null,
      });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          title: "", // Set 'title' to an empty string
        },
      }));
    }
  };

  handleMuteCheckboxChange = () => {
    this.setState((prevState) => ({
      muteCheckbox: !prevState.muteCheckbox,
    }));
  };

  handleScreenSaverStatus = (checked) => {
    this.setState(
      (prevState) => ({
        is_kiosk_screen_saver: !prevState.is_kiosk_screen_saver,
      }),
      () => {
        this.saveScreenSaver();
      }
    );
  };
  handlescreenTimeChange=(event)=>{
    const value = event.target.value;
    if (!isNaN(value)) {
      this.setState({ screenTime: value });
    }
  }

  handleSaverDelete = (file) => {
    this.setState({
      saverOpen: true,
      deleteFile: file.image_or_video_url || file,
    });
  };
  handleDeleteScreenSaver = (deleteFile) => {
    //this.setState({ isLoading: true, files: [] });
    this.setState((prevState) => ({
      files: prevState.files.filter((file) => file !== deleteFile),
      filus: prevState.filus.filter((file) => file !== deleteFile),
    }));
    this.deleteScreenSaver(deleteFile);
  };

  deleteScreenSaver = async (fileToBeDeleted) => {
    const payload = {
      property_id: this.props.propertyId,
      image_or_video_url: fileToBeDeleted,
    };

    const response = await SettingServiceAPI.deleteScreenSaver(payload);

    if (response && response.status) {
      toast.success("Screensaver deleted.");
      this.setState({
        isModalVisible: false,
        isLoading: false,
        saverOpen: false,
      },()=>{
        this.fetchAccountSetting();
      });
    } else {
      toast.warn("Coudln't delete screensaver.");
      this.setState({ isModalVisible: false, isLoading: false });
    }
  };

  handleDeleteFile = (fileToDelete) => {
    // Your logic to delete the file goes here
    this.setState({ file: null });
    this.setState((prevState) => ({
      files: prevState.files.filter((file) => file !== fileToDelete),
      filus: prevState.files.filter((file) => file !== fileToDelete),
    }));
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        title: "", // Set 'title' to an empty string
      },
    }));
  };

  areAllValuesFalse = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== false) {
        return false;
      }
    }
    return true;
  };

  handleTitleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleVatChange = (event) => {
    const { name, value } = event.target;
    if (value === "" || /^\d+(\.\d{0,4})?$/.test(value)) {
      const floatValue = parseFloat(value);
      if (value === "" || (floatValue >= 0 && floatValue <= 100)) {
        this.setState((prevState) => ({
          vatFormData: {
            ...prevState.vatFormData,
            [name]: value,
          },
        }));
      }
    }
  };
  handleVatTypeChange = () => {
    this.setState((prevState) => ({
      vatType: prevState?.vatType === "order" ? "item" : "order",
      vatOpen: !prevState.vatOpen,
    }));
  };
  handleVatTypeChangeDialog = () => {
    this.setState({ vatOpen: true });
  };
  handleVatNumberChange = (event) => {
    const newValue = event.target.value
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, "");
    this.setState({ vatNumber: newValue });
  };
  handleOrderVatChange = (event) => {
    const { name, value } = event.target;
    if (value === "" || /^\d+(\.\d{0,4})?$/.test(value)) {
      const floatValue = parseFloat(value);
      if (value === "" || (floatValue >= 0 && floatValue <= 100)) {
        this.setState((prevState) => ({
          orderVatFormData: {
            ...prevState.orderVatFormData,
            [name]: value,
          },
        }));
      }
    }
  };

  handleVatDescChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      vatFormData: {
        ...prevState.vatFormData,
        [name]: value,
      },
    }));
  };

  handleVatOrderDescChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      orderVatFormData: {
        ...prevState.orderVatFormData,
        [name]: value,
      },
    }));
  };

  handleVatCancel = () => {
    this.setState({
      enableVat: false,
      addVat: false,
      enableAdd: false,
      addOrderVat: false,
      enableOrderAdd: false,
      errors: {},
    });
    this.setState((prevState) => ({
      vatFormData: {
        ...prevState.vatFormData,
        value: "",
        description: "",
        is_default: false,
        updateIndex: -1,
      },
      vatOrderFormData: {
        ...prevState.vatOrderFormData,
        value: "",
        description: "",
        is_required: false,
        updateOrderIndex: -1,
      },
    }));
    // window.location.reload();
  };
  handleChange = (event) => {
    const newValue = event.target.value;
    this.setState({ taxPreference: newValue });

    if (newValue === "isTaxInclusive") {
      this.setState({ isTaxInclusive: true, isTaxExclusive: false });
    } else {
      this.setState({ isTaxInclusive: false, isTaxExclusive: true });
    }
  };
  handleAddVatClick = () => {
    this.setState({
      addVat: true,
      enableVat: false,
      enableAdd: true,
      errors: {},
      vat: null,
    });
    this.setState((prevState) => ({
      vatFormData: {
        ...prevState.vatFormData,
        value: "",
        description: "",
        is_default: false,
      },
    }));
  };
  handleAddOrderVatClick = () => {
    this.setState({
      addOrderVat: true,
      enableVat: false,
      enableOrderAdd: true,
      errors: {},
    });
    this.setState((prevState) => ({
      orderVatFormData: {
        ...prevState.orderVatFormData,
        value: "",
        description: "",
        is_required: false,
      },
    }));
  };

  handleVatSubmit = (event) => {
    event.preventDefault();
    this.saveVat();
  };

  handleVatAdd = (event) => {
    event.preventDefault();
    vatValidationSchema
      .validate(this.state.vatFormData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.vatFormData,
          property_id: this.props.propertyId,
        };
        if (this.state.updateIndex == -1) {
          this.addVat(data);
        } else {
          this.updateVat();
        }
      })
      .catch((errors) => {
        const errorDetails = {};
        errors.inner.forEach((error) => {
          errorDetails[error?.path] = error?.message;
        });
        this.setState({ errors: errorDetails, updateIndex: -1 });
      });
  };

  handleVatOrderAdd = (event) => {
    event.preventDefault();
    orderVatValidationSchema
      .validate(this.state.orderVatFormData, { abortEarly: false })
      .then(() => {
        let data = {
          ...this.state.orderVatFormData,
          property_id: this.props.propertyId,
        };
        if (this.state.updateOrderIndex == -1) {
          this.addOrderdVat(data);
        } else {
          this.updateOrderVat();
        }
      })
      .catch((errors) => {
        const errorDetails = {};
        if (errors && errors.inner) {
          errors.inner.forEach((error) => {
            if (error.path && error.message) {
              errorDetails[error.path] = error.message;
            }
          });
        }
        this.setState({ errors: errorDetails, updateIndex: -1 });
      });
  };

  addOrderdVat = (data) => {
    const { orderVats } = this.state;

    // Assuming vat is an object that you want to add to vats array
    const updatedVats = [...orderVats, data];

    this.setState({
      orderVats: updatedVats,
      enableVat: true,
      addOrderVat: false,
      enableOrderAdd: false,
      updateOrderIndex: -1,
    });
  };

  addVat = (data) => {
    this.setState((prevState) => {
      let vats = [...prevState.vats];

      // If the new item has is_default set to true, set is_default to false for all existing items
      if (data.is_default) {
        vats = vats.map((vat) => ({ ...vat, is_default: false }));
      }

      const vatIndex = vats.findIndex(
        (vat) =>
          vat.description === data.description &&
          vat.value === data.value &&
          vat.is_default === data.is_default
      );

      if (vatIndex !== -1) {
        // VAT exists, update it
        vats[vatIndex] = data;
      } else {
        // VAT does not exist, add it
        vats.push(data);
      }

      return {
        vats: vats,
        enableVat: true,
        addVat: false,
        enableAdd: false,
        updateIndex: -1,
      };
    });
  };

  addOrderdVat = (data) => {
    const { orderVats } = this.state;

    // Assuming vat is an object that you want to add to vats array
    const updatedVats = [...orderVats, { ...data, is_required: true }];

    this.setState({
      orderVats: updatedVats,
      enableVat: true,
      addOrderVat: false,
      enableOrderAdd: false,
      updateIndex: -1,
    });
  };

  updateVat = () => {
    this.setState((prevState) => ({
      vats: prevState.vats.map((vat, i) =>
        i === this.state.updateIndex
          ? { ...this.state.vatFormData }
          : {
              ...vat,
              is_default: this.state.vatFormData?.is_default
                ? false
                : vat.is_default,
            }
      ),
      enableVat: true,
      addVat: false,
      enableAdd: false,
    }));
  };

  deleteVat = (deleteIndex) => {
    this.setState(
      (prevState) => ({
        vats: prevState.vats.filter((vat, i) => i !== deleteIndex),
        showVat: true,
        addVat: false,
        open: false,
      }),
      () => {
        this.saveVat();
      }
    );
  };

  deleteOrderVat = (deleteIndex) => {
    this.setState(
      (prevState) => ({
        orderVats: prevState.orderVats.filter((vat, i) => i !== deleteIndex),
        showVat: true,
        addVat: false,
        open: false,
        orderVatDelete: false,
      }),
      () => {
        this.saveVat();
      }
    );
  };

  saveVat = async (data) => {
    try {
      // Update the state with the modified orderVats
      let payload = {
        is_vat: this.state.showVat,
        item_vat_details: this.state.vats,
        order_vat_details: this.state.orderVats,
        is_item_level: this.state.vatType === "item",
        // is_order_level: this.state.vatType === "order",
        is_tax_exclusive: this.state.isTaxExclusive,
        is_tax_inclusive: this.state.isTaxInclusive,
        vat_number: this.state.vatNumber,
      };

      const vatData = new FormData();
      vatData.append("vat", JSON.stringify(payload));
      vatData.append("property_id", this.props.propertyId);
      const username = this.props.user.username;
      this.setState({ isLoading: true });
      const response = await SettingServiceAPI.saveVat(vatData, username);
      if (response.data && response.data.status) {
        const message = "Vat settings submitted.";
        toast.success(message);
        this.fetchAccountSetting();
        this.setState({
          isLoading: false,
          showTip: false,
          enableAdd: false,
          addOrderVat: false,
          enableOrderAdd: false,
          addVat: false,
          enableVat: false,
          updateIndex: -1,
        });
        //setTimeout(() => { window.location.reload() }, 2000)
      } else {
        this.setState({ isLoading: false, updateIndex: -1 });
        const err =
          response?.data?.message || "Couldn't save tip setting information.";
        toast.warn(err);
      }
    } catch (error) {
      this.setState({ isLoading: false, updateIndex: -1 });
      const err = "Something went wrong.";
      toast.warn(err);
      throw error;
    }
  };

  handleEditVat = (vatIndex) => {
    this.setState({
      enableVat: false,
      addVat: true,
      enableAdd: true,
      vat: this.state.vats[vatIndex],
      updateIndex: vatIndex,
      errors: {},
    });
    this.setState((prevState) => ({
      vatFormData: {
        ...prevState.vatFormData,
        value: this.state.vats[vatIndex].value,
        description: this.state.vats[vatIndex].description,
        is_default: this.state.vats[vatIndex].is_default,
      },
    }));
    //this.deleteScreenSaver(file);
  };

  handleOrderEditVat = (vatIndex) => {
    this.setState({
      enableVat: false,
      addVat: false,
      enableOrderAdd: true,
      vatOrder: this.state.orderVats[vatIndex],
      updateOrderIndex: vatIndex,
      errors: {},
    });
    this.setState((prevState) => ({
      orderVatFormData: {
        ...prevState.orderVatFormData,
        value: this.state.orderVats[vatIndex].value,
        description: this.state.orderVats[vatIndex].description,
        is_required: this.state.orderVats[vatIndex].is_required,
      },
    }));
    //this.deleteScreenSaver(file);
  };

  handleCheckboxVat = (vatIndex) => {
    this.setState((prevState) => {
      const updatedVats = prevState.vats.map((vat, index) => {
        if (index === vatIndex) {
          // Set the is_default property of the selected VAT to true
          return { ...vat, is_default: true };
        } else {
          // Set the is_default property of other VATs to false
          return { ...vat, is_default: false };
        }
      });

      return {
        vats: updatedVats,
        vat: updatedVats[vatIndex], // Update the selected vat
        updateIndex: vatIndex,
      };
    });
  };
  handleOrderCheckboxVat = (vatIndex) => {
    this.setState((prevState) => {
      const updatedVats = prevState.orderVats.map((vat, index) => {
        if (index === vatIndex) {
          return { ...vat, is_required: !vat.is_required };
        } else {
          return vat;
        }
      });

      return {
        orderVats: updatedVats,
        vatOrder: updatedVats[vatIndex], // Update the selected vat
        updateOrderIndex: vatIndex,
      };
    });
  };

  handleDefaultVat = (event) => {
    const { checked } = event.target;
    this.setState((prevState) => ({
      vatFormData: {
        ...prevState.vatFormData,
        is_default: checked,
      },
    }));
  };

  handleOrderDefaultVat = (checked) => {
    this.setState((prevState) => ({
      orderVatFormData: {
        ...prevState.orderVatFormData,
        is_required: checked,
      },
    }));
  };

  arraysAreEqual = (arr1, arr2) => {
    // Check if the arrays have the same length
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    // Sort the arrays to ensure elements are in the same order
    const sortedArr1 = arr1?.slice().sort();
    const sortedArr2 = arr2?.slice().sort();

    // Check if each object in the arrays is equal
    for (let i = 0; i < sortedArr1.length; i++) {
      const obj1 = sortedArr1[i];
      const obj2 = sortedArr2[i];

      // Convert objects to strings and compare them
      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return false;
      }
    }

    // If all objects are equal, return true
    return true;
  };

  handleVatSettingsToggle = () => {
    this.setState((prevState) => ({
      enableVat: !prevState.enableVat,
      enableAdd: false,
      addVat: false,
      addOrderVat: false,
      enableOrderAdd: false,
    }));
  };

  handleVatActiveChange = (checked) => {
    if (checked) {
      if (
        this.props.settings.vat_details.item_vat_details.length === 0 &&
        this.props.settings.vat_details.order_vat_details.length === 0
      ) {
        this.setState({ enableVat: checked, showVat: checked });
      } else if (
        this.props.settings.vat_details.item_vat_details.length > 0 ||
        this.props.settings.vat_details.order_vat_details.length > 0
      )
        this.setState({ enableVat: checked, showVat: checked }, () => {
          this.saveVat();
        });
    } else {
      this.setState({ enableVat: false, showVat: false }, () => {
        this.saveVat();
      });
    }
  };

  updateOrderVat = () => {
    this.setState((prevState) => ({
      orderVats: prevState.orderVats.map((vat, i) =>
        i === this.state.updateOrderIndex
          ? { ...this.state.orderVatFormData }
          : vat
      ),
      enableVat: true,
      addOrderVat: false,
      enableOrderAdd: false,
    }));
  };

  render() {
    const {
      selectedCheckboxes,
      isCustomActive,
      tipOptions,
      percentage,
      isTipActive,
      isTipSettingsActive,
      addOrderVat,
      enableOrderAdd,
      showTip,
      isScreenSaver,
      isModalVisible,
      muteCheckbox,
      is_kiosk_screen_saver,
      showKioskScreenSaver,
      orderVats,
      orderVatFormData,
      files,
      progress,
      uploading,
      filus,
      file,
      errors,
      formData,
      showVat,
      vats,
      addVat,
      vatFormData,
      enableVat,
      enableAdd,
      orderVatDelete,
      deleteFile,
      taxPreference,
      vatNumber,
      showVatNumber,
      screenSaverPlan,
      vatType,
      screenTime,
    } = this.state;
    const toggleButtons = [
      { value: "order", label: "Order Level VAT" },
      { value: "item", label: "Item Level VAT" },
    ];
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
                <div className="col-md-12">
                  <Breadcrumb />
                </div>

                <br />
                {this.state.isLoading ? (
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "40px",
                    }}
                  >
                    {" "}
                    <Loader size={50} />{" "}
                  </div>
                ) : (
                  <Grid container>
                    {true && (
                      <Grid item lg={12}>
                        <Paper
                          elevation={3}
                          sx={{ mb: 0, mt: 0, pl: 4, pr: 4, ml: 5, mr: 5 }}
                        >
                          <Grid spacing={3} container alignItems="center">
                            {/* Image */}
                            <Grid item lg={1}>
                              <img
                                src={tip}
                                style={{
                                  width: "100px",
                                  margin: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </Grid>

                            {/* Text and Buttons */}
                            <Grid item lg={6}>
                              <Grid container>
                                {/* Text */}
                                <Grid item style={{ marginBottom: "8px" }}>
                                  <Typography variant="h6">
                                    <b>Tip</b>
                                  </Typography>
                                  <Typography>
                                    From this setting, you can enable and
                                    customize the tip option within your system
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Buttons */}
                            <Grid item lg={4}>
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end"
                              >
                                <Grid item lg={12}>
                                  <Box sx={{ my: 4, float: "right" }}>
                                    <Switch
                                      checked={isTipActive}
                                      onChange={this.handleTipActiveChange}
                                    />
                                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;
                                    {this.props.settings?.is_tip && (
                                      <IconButton
                                        onClick={this.handleTipSettingsToggle}
                                        color="primary"
                                        aria-label="settings"
                                      >
                                        <SettingsIcon
                                          style={{ fontSize: "32px" }}
                                        />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Divider />
                          </Grid>

                          {showTip && (
                            <React.Fragment>
                              <Grid item lg={12} sx={{ pt: 3 }}>
                                <Typography variant="h6">
                                  <b>Platforms</b>
                                </Typography>
                                <p>Select platforms to enable tips on</p>
                              </Grid>
                              <Grid item lg={12}>
                                <FormGroup row style={formGroup}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.KIOSK}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        KIOSK
                                      </Typography>
                                    }
                                    name="KIOSK"
                                  />
                                  <FormControlLabel
                                    sx={{ ml: 5 }}
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.EPOS}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        EPOS
                                      </Typography>
                                    }
                                    name="EPOS"
                                  />
                                  <FormControlLabel
                                    sx={{ ml: 5 }}
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.MPOS}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        MPOS
                                      </Typography>
                                    }
                                    name="MPOS"
                                  />
                                  <FormControlLabel
                                    sx={{ ml: 5 }}
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.WEB}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        WEB ORDER
                                      </Typography>
                                    }
                                    name="WEB"
                                  />
                                  <FormControlLabel
                                    sx={{ ml: 5 }}
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.QR}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        QR ORDER
                                      </Typography>
                                    }
                                    name="QR"
                                  />

                                  <FormControlLabel
                                    sx={{ ml: 5 }}
                                    control={
                                      <Checkbox
                                        checked={selectedCheckboxes.MOBILE}
                                        onChange={this.handlePlatformChange}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                      >
                                        MOBILE
                                      </Typography>
                                    }
                                    name="MOBILE"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid container sx={{ pt: 5 }}>
                                <Grid item lg={4}>
                                  <Typography variant="h6">
                                    <b>Tip Options</b>
                                  </Typography>
                                  <p>Customise tipping options</p>
                                </Grid>
                                <Grid item lg={6}>
                                  {tipOptions?.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={<b> {option}% </b>}
                                      onDelete={() => this.handleDelete(option)}
                                      style={{
                                        height: "42px",
                                        borderRadius: "8px",
                                        marginLeft: "20px",
                                        marginTop: "10px",
                                      }}
                                      deleteIcon={
                                        <IconButton
                                          onClick={() =>
                                            this.handleDelete(option)
                                          }
                                          style={{
                                            position: "relative",
                                            top: -12,
                                            right: -15,
                                            color: "black",
                                          }}
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      }
                                    />
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item lg={6}>
                                  <div>
                                    <FormControl fullWidth>
                                      <InputLabel>Percentage</InputLabel>
                                      <Input
                                        name="percentage"
                                        value={percentage}
                                        onChange={this.handleInputChange}
                                        onKeyPress={(event) => {
                                          if (event.key === "Enter") {
                                            this.handleAddTipOption();
                                          }
                                        }}
                                      />
                                    </FormControl>
                                  </div>
                                </Grid>

                                <Grid item lg={6}>
                                  <Button
                                    disabled={!percentage}
                                    fullWidth
                                    variant="contained"
                                    onClick={this.handleAddTipOption}
                                  >
                                    Add Value
                                  </Button>
                                </Grid>
                              </Grid>
                              <Divider />
                              <Grid
                                spacing={3}
                                sx={{ pt: 5 }}
                                container
                                alignItems="center"
                              >
                                {/* Image */}-{/* Text and Buttons */}
                                <Grid item lg={7}>
                                  <Grid container>
                                    {/* Text */}
                                    <Grid item style={{ marginBottom: "8px" }}>
                                      <Typography variant="h6">
                                        <b>Customer Values</b>
                                      </Typography>
                                      <Typography>
                                        Let customers add a custom tip.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Buttons */}
                                <Grid item lg={4}>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    alignItems="right"
                                  >
                                    <Grid item lg={10}>
                                      <Box sx={{ my: 4, float: "right" }}>
                                        <Switch
                                          checked={isCustomActive}
                                          onChange={this.handleCutomChange}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ pt: 5 }}
                              >
                                <Grid item lg={6}>
                                  <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={this.handleCancel}
                                  >
                                    Cancel
                                  </Button>
                                </Grid>

                                <Grid item lg={6}>
                                  {isCustomActive}
                                  <Button
                                    fullWidth
                                    disabled={
                                      !isCustomActive &&
                                      (this.areAllValuesFalse(
                                        selectedCheckboxes
                                      ) ||
                                        tipOptions.length === 0)
                                    }
                                    variant="contained"
                                    onClick={this.handleTipSubmit}
                                  >
                                    Save tip Settings
                                  </Button>
                                </Grid>
                              </Grid>
                              <br /> <br />
                            </React.Fragment>
                          )}
                        </Paper>

                        <Paper
                          elevation={3}
                          sx={{ mb: 0, mt: 6, pl: 4, pr: 4, ml: 5, mr: 5 }}
                        >
                          <Grid spacing={3} container alignItems="center">
                            {/* Image */}
                            <Grid item lg={1}>
                              <img
                                src={screenSaver}
                                style={{
                                  width: "100px",
                                  margin: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </Grid>

                            {/* Text and Buttons */}
                            <Grid item lg={6} md={12}>
                              <Grid container>
                                {/* Text */}
                                <Grid item style={{ marginBottom: "8px" }}>
                                  <Typography variant="h6">
                                    <b>KIOSK SCREENSAVER</b>
                                  </Typography>
                                  <Typography>
                                    From this setting, you can enable and
                                    customise screensavers for your KIOSK
                                  </Typography>
                                  {screenSaverPlan?.subscription_status ===
                                  "CANCELLED" ? (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        marginLeft: "10px",
                                        backgroundColor: "lightgrey",
                                        padding: "5px",
                                        width: "150px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      Valid till{" "}
                                      {screenSaverPlan?.subscription_end_date?.substring(
                                        0,
                                        10
                                      )}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Buttons */}
                            <Grid item lg={4} md={12}>
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end"
                              >
                                <Grid item lg={12}>
                                  {screenSaverPlan.subscription_status ==
                                    "ACTIVE" && (
                                    <Box sx={{ ml: 2, my: 4, float: "right" }}>
                                      <Button
                                        variant="contained"
                                        onClick={this.handleCancelSubscription}
                                        color="primary"
                                        sx={{ width: 170, padding: 1 }}
                                        aria-label="settings"
                                      >
                                        Cancel Plan
                                      </Button>
                                    </Box>
                                  )}

                                  {(screenSaverPlan?.subscription_status ==
                                    "ACTIVE" ||
                                    screenSaverPlan?.subscription_status ==
                                      "CANCELLED") && (
                                    <Box sx={{ my: 4, float: "right" }}>
                                      <Switch
                                        checked={is_kiosk_screen_saver}
                                        onChange={this.handleScreenSaverChange}
                                      />
                                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                      &nbsp;&nbsp;
                                      {is_kiosk_screen_saver && (
                                        <IconButton
                                          onClick={this.handleScreenSaver}
                                          color="primary"
                                          aria-label="settings"
                                        >
                                          <SettingsIcon
                                            style={{ fontSize: "32px" }}
                                          />
                                        </IconButton>
                                      )}
                                    </Box>
                                  )}

                                  {Object.keys(screenSaverPlan).length == 0 && (
                                    <Box sx={{ my: 4, float: "right" }}>
                                      <Button
                                        variant="outlined"
                                        onClick={
                                          this.handleScreenSaverSubscribe
                                        }
                                        color="primary"
                                        sx={{ width: 200, padding: 1 }}
                                        aria-label="settings"
                                      >
                                        Subscribe
                                      </Button>
                                    </Box>
                                  )}

                                  {screenSaverPlan?.subscription_status ===
                                    "CANCELLED" && (
                                    <Box
                                      sx={{
                                        marginTop: "34px",
                                        float: "right",
                                        mr: 3,
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Button
                                        size="large"
                                        variant="contained"
                                        className="white-text"
                                        sx={{ width: 200 }}
                                        disabled={true}
                                      >
                                        Cancelled
                                      </Button>
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Divider />
                            {false ? (
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginBottom: "40px",
                                }}
                              >
                                {" "}
                                <Loader size={50} />{" "}
                              </div>
                            ) : (
                              <React.Fragment>
                                {showKioskScreenSaver && (
                                  <React.Fragment>
                                    <Grid item xs={6} lg={6} sx={{ pb: 5 }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        align="left"
                                        onClick={this.handleUploadClick}
                                        startIcon={
                                          <AddIcon style={{ color: "white" }} />
                                        }
                                      >
                                        Upload Files
                                      </Button>
                                    </Grid>
                                    {/*   <Grid item xs={6} lg={6} container justifyContent="flex-end" sx={{ pb: 5 }}>
                                  <Link href="#" variant="body1" align="right">
                                    Clear Files
                                  </Link>
                                </Grid> */}
                                    <TableContainer component={Paper}>
                                      <Table aria-label="file table">
                                        <TableHead sx={{ padding: "10px" }}>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              File Name
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Type
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Action
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {files.length === 0 ? (
                                            <TableRow>
                                              <TableCell
                                                colSpan={3}
                                                align="center"
                                              >
                                                <Typography variant="body1">
                                                  No items found
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            files.map((file, index) => (
                                              <TableRow key={index}>
                                                <TableCell>
                                                  {file.title || file.name}
                                                </TableCell>
                                                <TableCell>
                                                  <VideocamIcon
                                                    color="primary"
                                                    sx={{ fontSize: "32px" }}
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <Button
                                                    variant="outlined"
                                                    startIcon={
                                                      <DeleteIcon color="error" />
                                                    }
                                                    onClick={() =>
                                                      this.handleSaverDelete(
                                                        file
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </Button>
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <Grid item lg={12} sx={{ pt: 3 }}>
                                      <Typography variant="h6">
                                        <b>Audio Settings</b>
                                      </Typography>
                                    </Grid>
                                    <Divider />
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      sx={{ pb: 6, pl: 1 }}
                                    >
                                      <Grid
                                        item
                                        xs={4}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography variant="h6" sx={{ pl: 2 }}>
                                          Mute all videos
                                        </Typography>
                                        <Checkbox
                                          sx={{ ml: 2 }}
                                          checked={muteCheckbox}
                                          onChange={
                                            this.handleMuteCheckboxChange
                                          }
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xs={4}
                                        sx={{ display: "flex",alignItems:"center",justifyContent:"space-around" }}
                                      >
                                        <TextField
                                          label="Screen timeout"
                                          placeholder="Enter time in seconds"
                                          type="text"
                                          inputProps={{
                                            min: 1, 
                                            max: 999,
                                            step: 1, 
                                            maxLength: 3,
                                          }}
                                          value={screenTime}
                                          fullWidth
                                          onChange={this.handlescreenTimeChange}
                                          onInput={(event) => {
                                            event.target.value = isNaN(event.target.value) ? "" : event.target.value;
                                          }}
                                        />
                                          </Grid>
                                        <Grid item xs={3}>

                                        <Typography sx={{
                                          display:"flex",
                                          alignItems:"center"
                                        }}>
                                            <Tooltip title="Set the idle time in seconds before the screensaver activates." arrow>
        <InfoIcon style={{ marginLeft: "8px" }} color="primary" fontSize="large" />
      </Tooltip>
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={12} md={12}>
                                        <Button
                                          fullWidth
                                          sx={{ p: 2, fontWeight: "bold" }}
                                          variant="contained"
                                          color="primary"
                                          align="left"
                                          onClick={this.saveScreenSaver}
                                        >
                                          Save Settings
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    <Modal
                                      title=""
                                      open={isModalVisible}
                                      onOk={this.handleModalOk}
                                      onCancel={this.handleModalCancel}
                                    >
                                      {/* Add your upload file form or logic here */}
                                      {/*   <p>Upload your files here.</p> */}
                                      <Container>
                                        <Grid container sx={{ mb: 3, mt: 2 }}>
                                          <Grid item xs={6}>
                                            <Typography
                                              variant="h5"
                                              align="left"
                                            >
                                              Attach
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{ textAlign: "right" }}
                                          >
                                            <Typography
                                              variant="h5"
                                              align="right"
                                            >
                                              <button
                                                style={{
                                                  color: "#CC0000",
                                                  fontSize: "16px", // Adjust the font size here
                                                  border: "none",
                                                  background: "none",
                                                  cursor: "pointer",
                                                }}
                                                onClick={this.handleDeleteFile}
                                              >
                                                Delete File
                                              </button>
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            sx={{ mt: 3, mb: 2 }}
                                          >
                                            <FormControl fullWidth>
                                              <InputLabel>Title</InputLabel>
                                              <Input
                                                name="title"
                                                value={formData.title}
                                                onChange={
                                                  this.handleTitleChange
                                                }
                                              />
                                              <Typography
                                                variant="body2"
                                                color="error"
                                              >
                                                {errors.title}
                                              </Typography>
                                            </FormControl>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          spacing={2}
                                          justifyContent="center"
                                        >
                                          <Grid item xs={12} md={12}>
                                            <div
                                              onDrop={this.handleDrop}
                                              onDragOver={this.handleDragOver}
                                              onClick={() => {
                                                if (!file) {
                                                  document
                                                    .getElementById("fileInput")
                                                    .click();
                                                }
                                              }}
                                              style={{
                                                border: "2px dashed #ccc",
                                                padding: "20px",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                height: "300px",
                                                width: "100%", // Set the width to 100%
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center", // Vertically center content
                                                alignItems: "center", // Horizontally center content
                                              }}
                                            >
                                              {uploading ? (
                                                <React.Fragment>
                                                  <IconButton
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <Videocam
                                                      style={{ fontSize: 60 }}
                                                    />
                                                  </IconButton>
                                                  <Progress
                                                    strokeLinecap="butt"
                                                    percent={Math.round(
                                                      progress
                                                    )}
                                                  />
                                                  <Typography
                                                    variant="body1"
                                                    align="center"
                                                  >
                                                    {Math.round(progress)}%
                                                    Uploaded
                                                  </Typography>
                                                </React.Fragment>
                                              ) : !file ? (
                                                <React.Fragment>
                                                  <IconButton
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <FileUploadIcon
                                                      style={{
                                                        fontSize: 60,
                                                        color: "#5773ff",
                                                      }}
                                                    />
                                                  </IconButton>
                                                  <Typography variant="body1">
                                                    <b>
                                                      {" "}
                                                      Drag and drop files here{" "}
                                                    </b>
                                                  </Typography>
                                                  <Typography>Or</Typography>
                                                  <Typography
                                                    color={"rgb(87, 115, 255)"}
                                                    variant="body1"
                                                  >
                                                    <b>Browse Files</b>
                                                  </Typography>
                                                  <input
                                                    type="file"
                                                    id="fileInput"
                                                    multiple
                                                    accept="video/*,image/*,.gif" // Specify accepted file types as video, image, and GIF
                                                    style={{ display: "none" }}
                                                    onChange={
                                                      this.handleFileChange
                                                    }
                                                  />
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <IconButton
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <Videocam
                                                      style={{ fontSize: 60 }}
                                                    />
                                                  </IconButton>
                                                  <Typography
                                                    variant="body1"
                                                    sx={{
                                                      mb: 1,
                                                      padding: "10px",
                                                      maxWidth: "300px",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <b> {file[0]?.name} </b>
                                                  </Typography>
                                                  <Typography variant="body1">
                                                    <b>
                                                      {" "}
                                                      <CheckCircleIcon
                                                        style={{
                                                          color: "#5773ff",
                                                        }}
                                                      />{" "}
                                                      Upload Complete{" "}
                                                    </b>
                                                  </Typography>
                                                </React.Fragment>
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} md={12}>
                                            <Typography
                                              variant="body1"
                                              sx={{ mb: 2 }}
                                            >
                                              Supported formats: (e.g., .mp4,
                                              .jpeg, .png, .gif)
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              sx={{ mb: 2 }}
                                            >
                                              Recommended resolution:{" "}
                                              <i>1080 x 1920</i>. To resize
                                              videos, visit{" "}
                                              <a
                                                href="https://video-converter.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Video Converter
                                              </a>
                                              .
                                            </Typography>

                                            <Button
                                              disabled={
                                                filus?.length == 0 ||
                                                uploading ||
                                                formData.title === ""
                                              }
                                              fullWidth
                                              variant="contained"
                                              onClick={this.handleSubmit}
                                            >
                                              Upload File
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Container>
                                    </Modal>
                                    <br /> <br />
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </Grid>
                        </Paper>
                        <Dialog
                          open={this.state.saverOpen}
                          onClose={this.handleSaverClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Delete?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Do you really want to proceed?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleSaverClose}>No</Button>
                            <Button
                              onClick={() =>
                                this.handleDeleteScreenSaver(deleteFile)
                              }
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Paper
                          elevation={3}
                          sx={{ mb: 0, mt: 6, pl: 4, pr: 4, ml: 5, mr: 5 }}
                        >
                          <Grid spacing={3} container alignItems="center">
                            {/* Image */}
                            <Grid item lg={1} sx={{ ml: 1 }}>
                              <img
                                src={vatSvg}
                                style={{
                                  width: "100px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </Grid>

                            {/* Text and Buttons */}
                            <Grid item lg={6}>
                              <Grid container>
                                {/* Text */}
                                <Grid item style={{ marginBottom: "8px" }}>
                                  <Typography variant="h6">
                                    {showVat ? (
                                      <b> Enabled VAT</b>
                                    ) : (
                                      <b> Enable VAT</b>
                                    )}
                                  </Typography>
                                  <Typography>
                                    <InfoIcon /> From this setting, you can
                                    enable and customise VAT amount on orders
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Buttons */}
                            <Grid item lg={4}>
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end"
                              >
                                <Grid item lg={12}>
                                  <Box sx={{ my: 4, float: "right" }}>
                                    <Switch
                                      checked={showVat}
                                      onChange={this.handleVatActiveChange}
                                    />
                                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;
                                    {showVat && (
                                      <IconButton
                                        onClick={this.handleVatSettingsToggle}
                                        color="primary"
                                        aria-label="settings"
                                      >
                                        <SettingsIcon
                                          style={{ fontSize: "32px" }}
                                        />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Divider />

                            <React.Fragment>
                              {showVat && enableVat && (
                                <React.Fragment>
                                  <Grid item xs={12}>
                                    <Typography variant="h6">
                                      Please enter your VAT number to start
                                      entering and applying VAT to transactions.
                                    </Typography>
                                    {showVatNumber ? (
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                          alignItems: "center",
                                          padding: "none",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            outline: "1px solid #2196f3",
                                            borderRadius: "5px",
                                            padding: "12px",
                                            width: "50%",
                                            height: "53px",
                                          }}
                                        >
                                          {vatNumber}
                                        </Typography>
                                        <Button
                                          variant="outlined"
                                          startIcon={
                                            <EditIcon color="primary" />
                                          }
                                          sx={{
                                            height: "53px",
                                            padding: "10px",
                                            width: "180px",
                                          }}
                                          onClick={this.handleEditClick}
                                        >
                                          Edit
                                        </Button>
                                      </Stack>
                                    ) : (
                                      <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                          alignItems: "center",
                                          padding: "none",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <TextField
                                          fullWidth
                                          label="VAT Number"
                                          sx={{ margin: "5px", width: "50%" }}
                                          value={vatNumber}
                                          onChange={this.handleVatNumberChange}
                                          inputProps={{
                                            maxLength: 20,
                                            minLength: 9,
                                          }}
                                          error={vatNumber?.length < 9}
                                        />
                                        <Button
                                          variant="contained"
                                          sx={{
                                            height: "53px",
                                            padding: "10px",
                                            width: "180px",
                                          }}
                                          onClick={this.handleEnterClick}
                                          disabled={
                                            vatNumber?.length < 9 || !vatNumber
                                          }
                                        >
                                          Enter
                                        </Button>
                                      </Stack>
                                    )}
                                  </Grid>
                                  <Divider />
                                  {showVatNumber && (
                                    <>
                                      {/*     <Grid item xs={12}>
                                      <Typography
                                        variant="h4"
                                        sx={{ textAlign: "center" }}
                                      >
                                        Choose the type of VAT
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <ToggleButtonGroup
                                          sx={{
                                            ml: 4,
                                            border: "2px solid grey",
                                            borderRadius: "12px",
                                            padding: "4px",
                                          }}
                                          color="primary"
                                          exclusive
                                          value={
                                            vatType
                                          }
                                          onChange={
                                            this.handleVatTypeChangeDialog
                                          }
                                          aria-label="VAT Type"
                                        >
                                          {toggleButtons.map((button) => (
                                            <ToggleButton
                                              key={button.value}
                                              value={button.value}
                                              variant="contained"
                                              sx={{
                                                width: "180px",
                                                height: "50px",
                                                padding: "6px",
                                                fontWeight: 600,
                                                borderRadius: "10px !important",
                                                border: "none",
                                                "&.Mui-selected": {
                                                  pointerEvents: "none",
                                                  backgroundColor: "#1976d2",
                                                  borderRadius: "10px !important",
                                                  color: "white",
                                                  "&:hover": {
                                                    backgroundColor: "#1976d2",
                                                  },
                                                },
                                              }}
                                            >
                                              {button.label}
                                            </ToggleButton>
                                          ))}
                                        </ToggleButtonGroup>
                                      </Stack>
                                        </Grid>  */}
                                      <Grid item xs={12}>
                                        <Typography
                                          variant="body1"
                                          color="primary"
                                          sx={{ textAlign: "center" }}
                                        >
                                          <i>
                                            {" "}
                                            Note:
                                            {
                                              /* vatType === "order"
                                          ? "Order level VAT is calculated on total order amount"
                                          :  */ "Item level VAT will be applied to each item seperately and calculated as a single total"
                                            }{" "}
                                          </i>
                                        </Typography>
                                      </Grid>
                                      {vatType === "item" && (
                                        <>
                                          <Grid item xs={12} sx={{ pb: 5 }}>
                                            <Stack
                                              direction="row"
                                              sx={{
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                align="left"
                                                onClick={this.handleAddVatClick}
                                                startIcon={
                                                  <AddIcon
                                                    style={{ color: "white" }}
                                                  />
                                                }
                                              >
                                                Add Item Vat
                                              </Button>
                                              <Stack
                                                direction="row"
                                                sx={{
                                                  alignItems: "center",
                                                  paddingRight: "30px",
                                                }}
                                              >
                                                <Tooltip
                                                  title="Item prices can be tax inclusive(taxes included) or tax exclusive(taxes added at checkout).This clarifies your total meal cost."
                                                  arrow
                                                  sx={{
                                                    '.MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip':
                                                      {
                                                        transformOrigin:
                                                          "center top",
                                                        marginTop: "14px",
                                                        backgroundColor:
                                                          "lightgrey",
                                                        color: "black",
                                                        padding: "15px",
                                                        fontSize: "14px",
                                                      },
                                                  }}
                                                >
                                                  <InfoIcon
                                                    sx={{ cursor: "pointer" }}
                                                  />
                                                </Tooltip>
                                                <Typography
                                                  variant="h5"
                                                  sx={{ padding: "8px" }}
                                                >
                                                  Pricing preferences
                                                </Typography>
                                                <NativeSelect
                                                  defaultValue={taxPreference}
                                                  onChange={this.handleChange}
                                                  sx={{
                                                    width: "150px",
                                                    outline:
                                                      "2px solid #2196f3",
                                                    padding: "5px",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <option value="isTaxInclusive">
                                                    Tax Inclusive
                                                  </option>
                                                  <option value="isTaxExclusive">
                                                    Tax Exclusive
                                                  </option>
                                                </NativeSelect>
                                              </Stack>
                                            </Stack>
                                          </Grid>
                                          <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                              <TableHead
                                                sx={{ padding: "10px" }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      fontSize: "1.3em",
                                                      color: "grey",
                                                    }}
                                                  >
                                                    Default
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      fontSize: "1.3em",
                                                      color: "grey",
                                                    }}
                                                  >
                                                    Value
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      fontSize: "1.3em",
                                                      color: "grey",
                                                    }}
                                                  >
                                                    Descritpion
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      fontSize: "1.3em",
                                                      color: "grey",
                                                    }}
                                                  >
                                                    Action
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {vats?.length === 0 || !vats ? (
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={4}
                                                      align="center"
                                                    >
                                                      <Typography variant="body1">
                                                        No items found
                                                      </Typography>
                                                    </TableCell>
                                                  </TableRow>
                                                ) : (
                                                  vats?.map((vat, index) => (
                                                    <TableRow key={index}>
                                                      <TableCell>
                                                        {/* <Switch checked={vat?.is_default} onChange={this.handleScreenSaverStatus}/> */}
                                                        <FormControl>
                                                          <FormControlLabel
                                                            control={
                                                              <Radio
                                                                checked={
                                                                  vat?.is_default
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  this.handleCheckboxVat(
                                                                    index
                                                                  );
                                                                }}
                                                                name="is_default"
                                                                color="primary"
                                                              />
                                                            }
                                                            label="Default"
                                                          />
                                                        </FormControl>
                                                      </TableCell>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                      >
                                                        {vat && vat.value
                                                          ? parseFloat(
                                                              vat.value
                                                            ) + "%"
                                                          : ""}
                                                      </TableCell>
                                                      <TableCell>
                                                        {vat?.description}{" "}
                                                      </TableCell>
                                                      <TableCell>
                                                        {/* <IconButton
                                                  aria-label="edit"
                                                  color="primary"
                                                  onClick={() =>
                                                    this.handleEditVat(index)
                                                  }
                                                >
                                                  <EditIcon />
                                                </IconButton> */}
                                                        <Button
                                                          variant="outlined"
                                                          startIcon={
                                                            <EditIcon color="primary" />
                                                          }
                                                          onClick={() =>
                                                            this.handleEditVat(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </Button>
                                                        &nbsp; &nbsp;
                                                        {/* <IconButton
                                                  aria-label="delete"
                                                  color="error"
                                                  onClick={() =>
                                                    this.handleOpen(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton> */}
                                                        <Button
                                                          variant="outlined"
                                                          startIcon={
                                                            <DeleteIcon color="error" />
                                                          }
                                                          onClick={() =>
                                                            this.handleOpen(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </Button>
                                                      </TableCell>
                                                    </TableRow>
                                                  ))
                                                )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                          <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                            sx={{ pb: 6, pl: 1, pt: 5 }}
                                          >
                                            <Grid item xs={12} md={6}>
                                              <Button
                                                fullWidth
                                                sx={{
                                                  p: 2,
                                                  fontWeight: "bold",
                                                }}
                                                variant="outlined"
                                                color="primary"
                                                align="left"
                                                onClick={this.handleVatCancel}
                                              >
                                                Cancel
                                              </Button>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                              <Button
                                                fullWidth
                                                sx={{
                                                  p: 2,
                                                  fontWeight: "bold",
                                                }}
                                                variant="contained"
                                                color="primary"
                                                align="left"
                                                onClick={this.saveVat}
                                                disabled={vats.length === 0}
                                              >
                                                Save Settings
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                      {/*  <Divider />
                                  <Grid container spacing={2} alignItems="center" sx={{ pb: 6, pl: 1 }}>
                                    <Grid item xs={12} md={6}>
                                      <Button fullWidth sx={{ p: 2, fontWeight: "bold" }} variant="outlined" color="primary" align="left" onClick={this.handleVatCancel} >
                                        Cancel
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={6}>

                                      <Button disabled={this.arraysAreEqual(vats, ...this.props.settings?.vat_details?.item_vat_details)} fullWidth sx={{ p: 2, fontWeight: "bold" }} variant="contained" color="primary" align="left" onClick={this.saveVat} >
                                        Save Settings
                                      </Button>
                                    </Grid>

                                  </Grid> */}
                                      <br /> <br />
                                    </>
                                  )}
                                </React.Fragment>
                              )}

                              {(addVat || enableAdd) && (
                                <React.Fragment>
                                  <Grid container spacing={3} sx={{ ml: 2 }}>
                                    {/* VAT Amount Section */}
                                    <Grid item xs={12}>
                                      <Typography variant="h6">
                                        <b>VAT Value (Percentage)</b>
                                      </Typography>
                                      {/*  <Typography>
                                        The selected percentage will be applied to all orders.
                                      </Typography> */}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="vatPercentageInput">
                                          Enter Percentage
                                        </InputLabel>
                                        <Input
                                          id="vatPercentageInput"
                                          name="value"
                                          variant="outlined"
                                          value={vatFormData.value}
                                          onChange={this.handleVatChange}
                                          type="text"
                                          disabled={this.state.vat}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "E"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          inputProps={{ min: 0, max: 100 }}
                                        />
                                        <FormHelperText error>
                                          {errors.value}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="descriptionInput">
                                          Enter Description
                                        </InputLabel>
                                        <Input
                                          id="descriptionInput"
                                          name="description"
                                          value={vatFormData.description}
                                          onChange={this.handleVatDescChange}
                                          variant="outlined"
                                        />
                                        <FormHelperText error>
                                          {errors.description}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={vatFormData.is_default}
                                            onChange={this.handleDefaultVat}
                                          />
                                        }
                                        label="Set as default"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ pb: 6, pl: 1 }}
                                  >
                                    <Grid item xs={12} md={6}>
                                      <Button
                                        fullWidth
                                        sx={{ p: 2, fontWeight: "bold" }}
                                        variant="outlined"
                                        color="primary"
                                        align="left"
                                        onClick={this.handleVatCancel}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Button
                                        fullWidth
                                        sx={{ p: 2, fontWeight: "bold" }}
                                        variant="contained"
                                        color="primary"
                                        align="left"
                                        onClick={this.handleVatAdd}
                                      >
                                        Add Value
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <br /> <br />
                                </React.Fragment>
                              )}

                              <Dialog
                                open={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {"Delete?"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    Do you really want to proceed?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={this.handleClose}>No</Button>
                                  <Button
                                    onClick={() =>
                                      orderVatDelete
                                        ? this.deleteOrderVat(
                                            this.state.deleteOrderIndex
                                          )
                                        : this.deleteVat(this.state.deleteIndex)
                                    }
                                  >
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>

                              {/*        <React.Fragment>
                                {vatType === "order" && enableVat && (
                                  <>
                                    {orderVats?.length === 0 && (
                                      <Grid item xs={6} lg={6} sx={{ pb: 5 }}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          align="left"
                                          onClick={this.handleAddOrderVatClick}
                                          startIcon={
                                            <AddIcon
                                              style={{ color: "white" }}
                                            />
                                          }
                                        >
                                          Add Order Vat
                                        </Button>
                                      </Grid>
                                    )}
                                    <TableContainer component={Paper}>
                                      <Table aria-label="simple table">
                                        <TableHead sx={{ padding: "10px" }}>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Required
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Value
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Descritpion
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontSize: "1.3em",
                                                color: "grey",
                                              }}
                                            >
                                              Action
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {orderVats?.length === 0 ||
                                            !orderVats ? (
                                            <TableRow>
                                              <TableCell
                                                colSpan={4}
                                                align="center"
                                              >
                                                <Typography variant="body1">
                                                  No items found
                                                </Typography>
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            orderVats?.map((vat, index) => (
                                              <TableRow key={index}>
                                                <TableCell>
                                                  <Switch
                                                    key={index}
                                                    checked={vat?.is_required}
                                                    onChange={() =>
                                                      this.handleOrderCheckboxVat(
                                                        index
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {vat && vat.value
                                                    ? parseFloat(vat.value) +
                                                    "%"
                                                    : ""}
                                                </TableCell>
                                                <TableCell>
                                                  {vat?.description}{" "}
                                                </TableCell>
                                                <TableCell>
                                                 
                                                  <Button
                                                    variant="outlined"
                                                    startIcon={
                                                      <EditIcon color="primary" />
                                                    }
                                                    onClick={() =>
                                                      this.handleOrderEditVat(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </Button>
                                                  &nbsp; &nbsp;
                             
                                                  <Button
                                                    variant="outlined"
                                                    startIcon={
                                                      <DeleteIcon color="error" />
                                                    }
                                                    onClick={() =>
                                                      this.handleOrderOpen(
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </Button>
                                                </TableCell>
                                              </TableRow>
                                            ))
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="center"
                                      sx={{ pb: 6, pl: 1, pt: 5 }}
                                    >
                                      <Grid item xs={12} md={6}>
                                        <Button
                                          fullWidth
                                          sx={{ p: 2, fontWeight: "bold" }}
                                          variant="outlined"
                                          color="primary"
                                          align="left"
                                          onClick={this.handleVatCancel}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>

                                      <Grid item xs={12} md={6}>
                                        <Button
                                          fullWidth
                                          sx={{ p: 2, fontWeight: "bold" }}
                                          variant="contained"
                                          color="primary"
                                          align="left"
                                          onClick={this.saveVat}
                                          disabled={orderVats.length === 0}
                                        >
                                          Save Settings
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                           
                                <Divider />
                                
                                <br /> <br />
                              </React.Fragment>

                              {(addOrderVat || enableOrderAdd) && (
                                <React.Fragment>
                                  <Grid container spacing={3} sx={{ ml: 2 }}>
                                    <Grid item xs={12}>
                                      <Typography variant="h6">
                                        <b>VAT Order Value (Percentage)</b>
                                      </Typography>
                      
                                    </Grid>
                    
                                    <Grid item xs={12} md={6}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="vatPercentageInput">
                                          Enter Percentage
                                        </InputLabel>
                                        <Input
                                          id="vatPercentageInput"
                                          name="value"
                                          value={orderVatFormData.value}
                                          onChange={this.handleOrderVatChange}
                                          type="text"
                                          disabled={this.state.vatOrder}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "e" ||
                                              e.key === "E"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          inputProps={{ min: 0, max: 100 }} 
                                        />
                                        <FormHelperText error>
                                          {errors.value}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <FormControl fullWidth>
                                        <InputLabel htmlFor="descriptionInput">
                                          Enter Description
                                        </InputLabel>
                                        <Input
                                          id="descriptionInput"
                                          name="description"
                                          value={orderVatFormData.description}
                                          onChange={
                                            this.handleVatOrderDescChange
                                          }
                                        />
                                        <FormHelperText error>
                                          {errors.description}
                                        </FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Divider />
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ pb: 6, pl: 1 }}
                                  >
                                    <Grid item xs={12} md={6}>
                                      <Button
                                        fullWidth
                                        sx={{ p: 2, fontWeight: "bold" }}
                                        variant="outlined"
                                        color="primary"
                                        align="left"
                                        onClick={this.handleVatCancel}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Button
                                        fullWidth
                                        sx={{ p: 2, fontWeight: "bold" }}
                                        variant="contained"
                                        color="primary"
                                        align="left"
                                        onClick={this.handleVatOrderAdd}
                                      >
                                        Add Value
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <br /> <br />
                                </React.Fragment>
                              )}

                              <Dialog
                                open={this.state.vatOpen}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{ padding: "20px" }}
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {vatType === "order"
                                    ? "Switching to Item Level VAT will disable Order Level VAT, as only one type can be active at a time.This change ensures VAT is applied per item rather than on entire order.Do you want to proceed?"
                                    : "Switching to Order Level VAT will disable Item Level VAT, as only one type can be active at a time.This change ensures VAT is applied per order rather than per item.Do you want to proceed?"}
                                </DialogTitle>
                                <Typography variant="body1" color="error" sx={{ textAlign: "center", fontStyle: "italic" }}>Note:Ensure you click save settings button to save the changes</Typography>
                                <Stack
                                  spacing={2}
                                  sx={{ alignItems: "center", padding: "20px" }}
                                >
                                  <Button
                                    fullWidth
                                    sx={{
                                      height: "50px",
                                    }}
                                    variant="contained"
                                    onClick={this.handleVatTypeChange}
                                  >
                                    Proceed & Continue
                                  </Button>
                                  <Button
                                    fullWidth
                                    sx={{
                                      height: "50px",
                                    }}
                                    variant="contained"
                                    onClick={this.handleClose}
                                  >
                                    No
                                  </Button>
                                </Stack>
                              </Dialog> */}
                            </React.Fragment>
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  settings: state.user.settings,
});

const mapDispatchToProps = {
  setWebOrderUserInfo,
  setSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
