import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import * as schema from "../../../utils/Schema";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlinedInput from "@mui/material/OutlinedInput";
import MultipleImageCropper from "../../../shared/Cropper/MultipleImageCropper";
import Avatar from "@mui/material/Avatar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Swal from "sweetalert2";
import {
  addItem,
  updateItem,
  fetchItem,
  addExtrasOptions,
  deleteExtrasOptions,
  updateExtrasOptions,
  fetchAddonByAddonId,
  fetchUpsellItems,
  addComboItems,
  updateComboItems,
  fetchComboItemById,
  deleteComboItem,
  fetchComboStatus,
  fetchItemTags,
  deleteBarCode
} from "../../../services/product/item/ItemServiceAPI";
import {
  fetchCategoryDetails,
  fetchItemFilters,
  updateItemFilter,
} from "../../../services/product/filter-category/FilterCategoryAPI";

import {
  fetchCategoryNames,
  deleteCategory,
  fetchCategory,
} from "../../../services/product/category/CategoryServiceAPI";
import {
  setCurrentItemExtras,
  setCurrentItemOptions,
  setCategoriesForExtras,
  setCategoriesForOptions,
  setSelectedCategory,
} from "../../../redux/menu/menuSlice";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../../../shared/Loader";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { Divider } from "@mui/material";
import Chip from "@mui/material/Chip";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import {
  addAddonCategory,
  deleteAddonCategory,
  updateAddonCategory,
  fetchAllAddonCategory,
  addCombo,
  updateCombo,
  deleteComboGroup,
} from "../../../services/product/addonCategory/AddonCategoryServiceAPI";
import * as InventoryrServiceAPI from '../../../services/inventory/inventoryService';
import "./menu.css";
import { deleteConfirm } from "../../../shared/DeleteConfirm";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import CopyExtrasOptions from "./dialogs/CopyExtrasOptions";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import coming from "../../../assets/img/default.jpg";
import { getCurrencySymbol } from "../../../config";
import ItemBarcode from "./ItemBarcode";
class AddEditExtrasModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      imagesPreviewUrl: null,
      categories: this.props.categoriesForExtras,
      fetchingData: false,
      categoryNames: [],
      selectedCategory: null,
      selectedItem: null,
      selectedItems: [],
      menuNames: this.props.menuNames,
      selectedMenu: this.props.selectedMenu,
      selectedCategory: "",
      categoryNames: [],
      item: null,
      checked: false,
      selectedAddonCategory: this.props.selectedAddonCategory
        ? this.props.selectedAddonCategory
        : null,
      initialValues: {},
      extraToEdit: this.props.extraToEdit ? this.props.extraToEdit : null,
      itemNames: this.props.itemNames,
    };
  }

  componentDidMount() {
    this.setInitalValues();
  }

  setInitalValues = async () => {
    let addInitialValues = {
      addon_identifier: this.state.extraToEdit
        ? this.state.extraToEdit.addon_identifier
        : "",
      addon_cost: this.state.extraToEdit
        ? this.state.extraToEdit.addon_cost
        : "",
      linked_item_id: this.state.extraToEdit
        ? this.state.extraToEdit.linked_item_id
        : "",
      quantity: this.state.extraToEdit ? this.state.extraToEdit.quantity : "",
      addon_description: this.state.extraToEdit
        ? this.state.extraToEdit.addon_description
        : "",
      addon_sort_order: this.state.extraToEdit
        ? this.state.extraToEdit.addon_sort_order
        : "",
      addon_category_id: this.state.extraToEdit
        ? this.state.extraToEdit.addon_category_id
        : this.state.selectedAddonCategory,
      maximum: this.state.extraToEdit ? this.state.extraToEdit.maximum : "",
      minimum: this.state.extraToEdit ? this.state.extraToEdit.minimum : "",
    };

    if (this.state?.extraToEdit?.menu_id) {
      this.fetchCategoryNames(this.state?.extraToEdit?.menu_id);
      this.fetchItems(this.state?.extraToEdit?.category_id);
    }

    this.setState(
      {
        initialValues: addInitialValues,
        checked: this.state.extraToEdit?.linked_item_id ? true : false,
        selectedAddonCategory: this.state.extraToEdit
          ? this.state.extraToEdit.addon_category_id
          : this.state.selectedAddonCategory,
        selectedItem: this.state.extraToEdit
          ? this.state.extraToEdit.linked_item_id
          : "",
        selectedMenu: this.state.extraToEdit
          ? this.state.extraToEdit.menu_id
          : "",
        selectedCategory: this.state.extraToEdit
          ? this.state.extraToEdit.category_id
          : "",
        itemNames: this.state.extraToEdit?.linked_item_id
          ? [
            {
              item_id: this.state.extraToEdit.linked_item_id,
              item_identifier: this.state.extraToEdit.addon_identifier,
            },
          ]
          : null,
        imagesPreviewUrl: this.state.extraToEdit
          ? this.state.extraToEdit.addon_image
          : null,
      },

    );
    if (!this.state.extraToEdit) {
      try {
        const response = await fetch(coming);

        if (!response.ok) {
          throw new Error("Failed to fetch the image.");
        }

        const blob = await response.blob();
        const reader = new FileReader();
        const file = new File([blob], "coming.jpg", { type: blob.type });

        reader.onloadend = () => {
          // Convert the image to a data URL
          const base64Data = reader.result;
          this.setState({
            imagesPreviewUrl: base64Data,
            file: file,
          });
        };

        reader.onerror = (error) => {
          console.error("Error occurred while reading the image:", error);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error occurred while fetching the image:", error);
      }
    }
  };

  setLinkedItemInitialValues = (setFieldValue) => {
    if (this.props.itemId != this.state.item.item_id) {
      let addInitialValues = {
        addon_identifier: this.state.item
          ? this.state.item.item_identifier
          : "",
        addon_cost: this.state.item ? this.state.item.item_cost : "",
        quantity: this.state.item ? this.state.item.total_items : "",
        addon_sort_order: this.state.extraToEdit
          ? this.state.extraToEdit.addon_sort_order
          : "",
        linked_item_id:
          this.state.extraToEdit?.linked_item_id || this.state.selectedItem,
        addon_category_id: this.state.extraToEdit
          ? this.state.extraToEdit.addon_category_id
          : this.state.selectedAddonCategory,
        maximum: this.state.extraToEdit ? this.state.extraToEdit.maximum : "",
        minimum: this.state.extraToEdit ? this.state.extraToEdit.minimum : "",
        addon_description: this.state.item
          ? this.state.item.item_description
          : "",
      };

      this.setState(
        {
          initialValues: addInitialValues,
          imagesPreviewUrl: this.state.item ? this.state.item.item_image : null,
        },

      );
    } else {
      toast.warn("you can't link item to itself.");
    }
  };

  handleViewOptionsDialog = () => {
    this.setState({ openViewExtrasDialog: !this.state.openViewExtrasDialog });
  };

  handleAddonCategoryChange = (e, setFieldValue) => {
    setFieldValue("addon_category_id", e.target.value);
    this.setState({ selectedAddonCategory: e.target.value });
  };

  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
    }
  };

  handleMenuChange = (e, setFieldValue) => {
    this.setState({ selectedMenu: e.target.value });
    setFieldValue("menu_id", e.target.value);
    this.setState({ itemNames: null });
    this.fetchCategoryNames(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  fetchCategoryNames = async (selectedMenu) => {
    try {
      let categories = await fetchCategory(selectedMenu);

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        // setLoading(false);
        this.setState({ categoryNames: [] });
        this.setState({ selectedCategory: "" });
        return;
      } else {
        this.setState({
          categoryNames: categories.data.data.category_response_bean_list,
        });
      }
    } catch (e) {
    }
  };

  handleCategoryChange = (e, setFieldValue) => {
    this.setState({ selectedCategory: e.target.value });
    setFieldValue("category_id", e.target.value);
    this.fetchItems(e.target.value);

    // this.props.setSelectedMenu(event.target.value);
  };

  fetchItems = async (value) => {
    try {
      let items = await fetchItem(value);


      if (
        items.data.status == false ||
        items.data.message == "No details found."
      ) {
        this.setState({
          itemNames: [],
        });
        return;
      } else {
        const filteredItems = items.data.data.item_response_bean_list.filter(
          (item) => !item.is_combo
        );
        this.setState({
          itemNames: filteredItems,
        });
      }
    } catch (e) {
    }
  };

  handleItemChange = async (e, setFieldValue) => {
    const found_item = this.state.itemNames.find(
      (item) => item.item_id === e.target.value
    );
    setFieldValue("linked_item_id", e.target.value);
    this.setState(
      {
        item: found_item,
        selectedItem: e.target.value,
      },
      () => {
        this.setLinkedItemInitialValues(setFieldValue);
      }
    );

  };

  handleLinkedItems = (event) => {

    this.setState({ checked: event.target.checked, selectedItem: "" });
  };

  render() {
    const {
      initialValues,
      extraToEdit,
      selectedAddonCategory,
      fetchingData,
      itemNames,
    } = this.state;

    return (
      <>
        <Dialog
          open={this.props.openExtrasModel}
          onClose={() => {
            this.props.handleExtrasModal();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Manage Extras</DialogTitle>
          <DialogContent>
            {fetchingData ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={
                  this.state.extraToEdit?.linked_item_id || this.state?.checked
                    ? schema.extrasItemSchema
                    : schema.extrasSchema
                }
                onSubmit={(values) => {
                  if (extraToEdit) {
                    values["addon_id"] = extraToEdit.addon_id;
                  }
                  if (this.state.file) {
                    values["addon_image"] = this.state.file;
                  }
                  if (this.state.selectedItem) {
                    values["linked_item_id"] = this.state.selectedItem;
                  } else {
                    values["linked_item_id"] = "";
                  }
                  this.props.handleAddons(values, "EXTRAS");
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid style={{ marginTop: "20px" }} item xs={6} md={6}>
                        <FormControl
                          fullWidth
                          error={
                            touched.addon_category_id &&
                              errors.addon_category_id
                              ? true
                              : false
                          }
                        >
                          <InputLabel id="addon_category_id">
                            Extras Category
                          </InputLabel>
                          <Select
                            labelId="addon_category_id"
                            id="addon_category_id"
                            name="addon_category_id"
                            input={
                              <OutlinedInput
                                id="addon_category_id"
                                label=" Extras Category"
                              />
                            }
                            value={selectedAddonCategory}
                            defaultValue={selectedAddonCategory}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            className="formControl"
                            disabled={!this.state.categories}
                            onChange={(e, val) =>
                              this.handleAddonCategoryChange(e, setFieldValue)
                            }
                          >
                            {this.state.categories &&
                              this.state.categories.map((item) => {
                                return (
                                  <MenuItem
                                    key={item.addon_category_id}
                                    value={item.addon_category_id}
                                  >
                                    <span>
                                      {item.addon_category_identifier}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText error>
                            {touched.addon_category_id &&
                              errors.addon_category_id
                              ? errors.addon_category_id
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            checked={this.state.checked}
                            onChange={this.handleLinkedItems}
                            control={<Checkbox />}
                            label="Do you want to link an Item? "
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </Grid>

                      {this.state?.checked && (
                        <React.Fragment>
                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="menu_id">Menu</InputLabel>
                              <Select
                                error={
                                  touched.menu_id && errors.menu_id
                                    ? true
                                    : false
                                }
                                labelId="menu_id"
                                id="menu_id"
                                name="menu_id"
                                value={this.state.selectedMenu}
                                defaultValue={this.state.selectedMenu}
                                input={
                                  <OutlinedInput id="menu_id" label="Menu" />
                                }
                                className="formControl"
                                disabled={!this.props.menuNames}
                                MenuProps={{
                                  disableScrollLock: true,
                                }}
                                onChange={(e, val) =>
                                  this.handleMenuChange(e, setFieldValue)
                                }
                              >
                                {this.state.menuNames &&
                                  this.state.menuNames.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.menu_id}
                                        value={item.menu_id}
                                      >
                                        <span>{item.menu_identifier}</span>
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.menu_id && errors.menu_id
                                  ? errors.menu_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-dialog-select-label">
                                Category
                              </InputLabel>

                              <Select
                                labelId="select-category"
                                id="category_id"
                                error={
                                  touched.category_id && errors.category_id
                                    ? true
                                    : false
                                }
                                name="category_id"
                                value={this.state.selectedCategory}
                                onChange={(e, val) =>
                                  this.handleCategoryChange(e, setFieldValue)
                                }
                                input={
                                  <OutlinedInput
                                    id="category_id"
                                    label="Category"
                                  />
                                }
                                defaultValue={this.state.selectedCategory}
                                className="formControl"
                              >
                                {this.state.categoryNames &&
                                  this.state.categoryNames.map((item) => {
                                    return (
                                      <MenuItem
                                        fullWidth
                                        key={item.category_id}
                                        value={item.category_id}
                                      >
                                        {item.category_identifier}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.category_id && errors.category_id
                                  ? errors.category_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="linked_item_id">Item</InputLabel>

                              <Select
                                labelId="linked_item_id"
                                id="linked_item_id"
                                error={
                                  touched.linked_item_id &&
                                    errors.linked_item_id
                                    ? true
                                    : false
                                }
                                name="linked_item_id"
                                value={this.state.selectedItem}
                                onChange={(e, val) =>
                                  this.handleItemChange(e, setFieldValue)
                                }
                                input={
                                  <OutlinedInput
                                    id="linked_item_id"
                                    label="Item"
                                  />
                                }
                                defaultValue={this.state.selectedItem}
                                className="formControl"
                              >
                                {itemNames &&
                                  itemNames.map((item) => {
                                    return (
                                      <MenuItem
                                        fullWidth
                                        key={item.item_id}
                                        value={item.item_id}
                                      >
                                        {item.item_identifier}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.linked_item_id && errors.linked_item_id
                                  ? errors.linked_item_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      )}

                      <Grid item xs={4} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            error={
                              touched.addon_identifier &&
                                errors.addon_identifier
                                ? true
                                : false
                            }
                            margin="normal"
                            type="text"
                            id="addon_identifier"
                            label="Name"
                            name="addon_identifier"
                            autoComplete="addon_identifier"
                            pattern="\s*\S+.*"
                            size="small"
                            variant="standard"
                            multiline
                            maxRows={3}
                            value={values.addon_identifier}
                            onBlur={(e) => {
                              setFieldValue(
                                "addon_identifier",
                                e.target.value.trim()
                              );
                            }}
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                            disabled={this.state?.checked ? true : false}
                            helperText={
                              touched.addon_identifier &&
                                errors.addon_identifier
                                ? errors.addon_identifier
                                : null
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.addon_cost && errors.addon_cost
                              ? true
                              : false
                          }
                          margin="normal"
                          id="addon_cost"
                          label="Price"
                          name="addon_cost"
                          autoComplete="addon_cost"
                          size="small"
                          variant="standard"
                          disabled={this.state?.checked ? true : false}
                          onInput={(event) => {
                            if (!isNaN(event.target.value)) {
                              event.target.value = !isNaN(event.target.value)
                                ? event.target.value
                                : "";
                            } else {
                              event.target.value = "";
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.addon_cost}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "any",
                            },
                          }}
                          helperText={
                            touched.addon_cost && errors.addon_cost
                              ? errors.addon_cost
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.quantity && errors.quantity ? true : false
                          }
                          margin="normal"
                          type="number"
                          id="quantity"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          label="Quantity"
                          name="quantity"
                          autoComplete="quantity"
                          size="small"
                          disabled={this.state?.checked ? true : false}
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.quantity}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputLabelProps={{
                            shrink:
                              values.quantity || values.quantity >= 0
                                ? true
                                : false,
                          }}
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.quantity && errors.quantity
                              ? errors.quantity
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.addon_sort_order && errors.addon_sort_order
                              ? true
                              : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          id="addon_sort_order"
                          label="Sort order"
                          name="addon_sort_order"
                          autoComplete="addon_sort_order"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.addon_sort_order}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.addon_sort_order && errors.addon_sort_order
                              ? errors.addon_sort_order
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.minimum && errors.minimum ? true : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          id="minimum"
                          label="Minimum"
                          name="minimum"
                          autoComplete="minimum"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.minimum}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.minimum && errors.minimum
                              ? errors.minimum
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.maximum && errors.maximum ? true : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          id="maximum"
                          label="Maximum"
                          name="maximum"
                          autoComplete="maximum"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.maximum}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.maximum && errors.maximum
                              ? errors.maximum
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          error={
                            touched.addon_description &&
                              errors.addon_description
                              ? true
                              : false
                          }
                          margin="normal"
                          type="text"
                          fullWidth
                          multiline
                          rows={5}
                          id="addon_description"
                          label="Description"
                          name="addon_description"
                          autoComplete="addon_description"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.addon_description}
                          onChange={handleChange}
                          disabled={this.state?.checked ? true : false}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          helperText={
                            touched.addon_description &&
                              errors.addon_description
                              ? errors.addon_description
                              : null
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={8}
                        md={8}
                        sx={{ visiblity: !this.state?.checked ? true : false }}
                      >
                        {!this.state?.checked && (
                          <MultipleImageCropper
                            data={{
                              key: "menu",
                              isMultiple: false,
                              getCroppedImages: this.getCroppedImages,
                            }}
                            height={300}
                            width={300}
                          />
                        )}
                        <br></br>
                        <br></br>
                        <img
                          className="cover"
                          src={
                            this.state.imagesPreviewUrl
                              ? this.state.imagesPreviewUrl
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.props.track}
                        >
                          {extraToEdit ? "Update Extra" : "Add Extra"}
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => {
                            this.props.handleExtrasModal();
                            this.setState({
                              itemNames: [],
                              selectedItem: "",
                              checked: false,
                              selectedMenu: "",
                              item: null,
                              selectedCategory: "",
                              categoryNames: [],
                            });
                          }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {Array.isArray(errors) ||
                        (Object.values(errors).toString() != "" && (
                          <Alert severity="warning">
                            {Object.values(errors).toString()}
                          </Alert>
                        ))}
                      {/*   <Button
                        onClick={() => {
                          this.props.handleExtrasModal();
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button type="submit" color="primary">
                        {extraToEdit ? "Update Extra" : "Add Extra"}
                      </Button> */}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </>
    );
  }
}

class ManageAddonCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: {},
      submittingForm: false,
      addonCategoryToEdit: this.props.addonCategoryToEdit
        ? this.props.addonCategoryToEdit
        : null,
    };
  }
  componentDidMount() {
    this.setInitalValues();
  }

  setInitalValues = () => {
    let addInitialValues = {
      addon_category_identifier: this.state.addonCategoryToEdit
        ? this.state.addonCategoryToEdit.addon_category_identifier
        : "",
      addon_category_sort_order: this.state.addonCategoryToEdit
        ? this.state.addonCategoryToEdit.addon_category_sort_order
        : "",

      addon_category_description: this.state.addonCategoryToEdit
        ? this.state.addonCategoryToEdit.addon_category_description
        : "",
      maximum: this.state.addonCategoryToEdit
        ? this.state.addonCategoryToEdit.maximum
        : "",
      minimum: this.state.addonCategoryToEdit
        ? this.state.addonCategoryToEdit.minimum
        : "",
    };

    this.setState({
      initialValues: addInitialValues,
    });
  };

  addAddonCategory = async (values) => {
    try {
      this.setState({ submittingForm: true });

      let res = await addAddonCategory(values);
      if (res.data.status) {
        toast.success("Addon Category Added");

        this.setState(
          {
            submittingForm: false,
            addonCategoryToEdit: null,
          },
          () => {
            this.props.resetAddonCategoryToEdit();
            this.props.fetchAllAddons();
            this.props.handleAddonCategoryModal(null);
          }
        );
      } else {
        this.setState({
          submittingForm: false,
        });
        toast.error(res.data.message);
      }
    } catch (e) {
      this.setState({
        submittingForm: false,
      });
      toast.error("Something went wrong, Please try again");
    }
  };

  updateAddonCategory = async (values, resetForm) => {
    try {
      this.setState({ submittingForm: true });
      let res = await updateAddonCategory(values);
      if (res.data.status) {
        toast.success("Addon Category updated");
        resetForm();
        this.setState(
          {
            submittingForm: false,
            addonCategoryToEdit: null,
          },
          () => {
            this.props.resetAddonCategoryToEdit();
            this.props.handleAddonCategoryModal(null);
            this.props.fetchAllAddons();
          }
        );
      } else {
        this.setState({
          submittingForm: false,
          addonCategoryToEdit: null,
        });
        toast.error("Something went wrong, Please try again");
      }
    } catch (e) {
      this.setState(
        {
          submittingForm: false,
          addonCategoryToEdit: null,
        },
        () => {
          this.setInitalValues();
        }
      );
      toast.error("Something went wrong, Please try again");
    }
  };

  handleAddonCategory = (values, resetForm) => {
    values["category_type"] = this.props.categoryType;
    values["item_id"] = this.props.itemID;

    if (this.state.addonCategoryToEdit) {
      values["addon_category_id"] =
        this.state.addonCategoryToEdit.addon_category_id;
    }

    if (!this.state.addonCategoryToEdit) {
      this.addAddonCategory(values);
    } else {
      this.updateAddonCategory(values, resetForm);
    }
  };

  render() {
    const { initialValues, addonCategoryToEdit, submittingForm } = this.state;
    return (
      <Dialog
        open={this.props.openAddonCategoryModel}
        onClose={() => {
          this.props.handleAddonCategoryModal(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Manage {this.props.categoryType} Addon Categories
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema.addonCategorySchema}
            onSubmit={(values, { resetForm }) => {
              this.handleAddonCategory(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              resetForm,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={
                        touched.addon_category_identifier &&
                          errors.addon_category_identifier
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      id="addon_category_identifier"
                      label="Name"
                      name="addon_category_identifier"
                      autoComplete="addon_category_identifier"
                      size="small"
                      multiline
                      maxRows={3}
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.addon_category_identifier}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      helperText={
                        touched.addon_category_identifier &&
                          errors.addon_category_identifier
                          ? errors.addon_category_identifier
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={
                        touched.addon_category_sort_order &&
                          errors.addon_category_sort_order
                          ? true
                          : false
                      }
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      id="addon_category_sort_order"
                      label="Sort order"
                      name="addon_category_sort_order"
                      autoComplete="addon_category_sort_order"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.addon_category_sort_order}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.addon_category_sort_order &&
                          errors.addon_category_sort_order
                          ? errors.addon_category_sort_order
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={touched.minimum && errors.minimum ? true : false}
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      id="minimum"
                      label="Minimum"
                      name="minimum"
                      autoComplete="minimum"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.minimum}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.minimum && errors.minimum
                          ? errors.minimum
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={touched.maximum && errors.maximum ? true : false}
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      id="maximum"
                      label="Maximum"
                      name="maximum"
                      autoComplete="maximum"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.maximum}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.maximum && errors.maximum
                          ? errors.maximum
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      error={
                        touched.addon_category_description &&
                          errors.addon_category_description
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      fullWidth
                      rows={5}
                      id="addon_category_description"
                      label="Description"
                      name="addon_category_description"
                      autoComplete="addon_category_description"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.addon_category_description}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      helperText={
                        touched.addon_category_description &&
                          errors.addon_category_description
                          ? errors.addon_category_description
                          : null
                      }
                    />
                  </Grid>

                  <Grid>
                    {Array.isArray(errors) ||
                      (Object.values(errors).toString() != "" && (
                        <Alert severity="warning">
                          {Object.values(errors).toString()}
                        </Alert>
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  {submittingForm ? (
                    <Loader size={30} />
                  ) : (
                    <>
                      <Button
                        type="button"
                        onClick={() => {
                          this.props.handleAddonCategoryModal();
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button type="submit" color="primary">
                        {addonCategoryToEdit
                          ? "Update Category"
                          : "Add Category"}
                      </Button>
                    </>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  }
}

class ManageCombos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      submittingForm: false,
      comboGroupToEdit: this.props.comboGroupToEdit
        ? this.props.comboGroupToEdit
        : null,
    };
  }
  componentDidMount() {
    this.setInitalValues();
  }

  setInitalValues = () => {
    let addInitialValues = {
      combo_group_identifier: this.state.comboGroupToEdit
        ? this.state.comboGroupToEdit.combo_group_identifier
        : "",
      combo_group_sort_order: this.state.comboGroupToEdit
        ? this.state.comboGroupToEdit.combo_group_sort_order
        : "",

      combo_group_description: this.state.comboGroupToEdit
        ? this.state.comboGroupToEdit.combo_group_description
        : "",
      maximum: this.state.comboGroupToEdit
        ? this.state.comboGroupToEdit.maximum
        : "",
      minimum: this.state.comboGroupToEdit
        ? this.state.comboGroupToEdit.minimum
        : "",
    };

    this.setState({
      initialValues: addInitialValues,
    });
  };

  addCombo = async (values) => {
    try {
      this.setState({ submittingForm: true });

      let res = await addCombo(values);
      if (res.data.status) {
        toast.success("Combo Added");

        this.setState(
          {
            submittingForm: false,
            comboGroupToEdit: null,
          },
          () => {
            this.props.resetComboGroupToEdit();
            this.props.fetchAllCombos();
            this.props.handleComboGroupModal(null);
          }
        );
      } else {
        this.setState({
          submittingForm: false,
        });
        toast.error(res.data.message);
      }
    } catch (e) {
      this.setState({
        submittingForm: false,
      });
      toast.error("Something went wrong, Please try again");
    }
  };

  updateCombo = async (values) => {
    try {
      this.setState({ submittingForm: true });
      let res = await updateCombo(values);
      if (res.data.status) {
        toast.success("Combo updated");

        this.setState(
          {
            submittingForm: false,
            comboGroupToEdit: null,
          },
          () => {
            this.props.resetComboGroupToEdit();
            this.props.handleComboGroupModal(null);
            this.props.fetchAllCombos();
          }
        );
      } else {
        this.setState({
          submittingForm: false,
        });
        let err =
          res?.data?.message || "Something went wrong, Please try again";
        toast.error(err);
      }
    } catch (e) {
      this.setState(
        {
          submittingForm: false,
          comboGroupToEdit: null,
        },
        () => {
          this.setInitalValues();
        }
      );
      toast.error("Something went wrong, Please try again");
    }
  };

  handleCombo = (values) => {
    // values["category_type"] = this.props.categoryType;
    values["item_id"] = this.props.itemID;

    if (this.state.comboGroupToEdit) {
      values["combo_group_id"] = this.state.comboGroupToEdit.combo_group_id;
    }

    if (!this.state.comboGroupToEdit) {
      this.addCombo(values);
    } else {
      this.updateCombo(values);
    }
  };

  render() {
    const { initialValues, comboGroupToEdit, submittingForm } = this.state;
    return (
      <Dialog
        open={this.props.openCombosModel}
        onClose={() => {
          this.props.handleComboGroupModal(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Manage {this.props.categoryType}
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema.comboSchema}
            onSubmit={(values, { resetForm }) => {
              this.handleCombo(values);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              resetForm,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={
                        touched.combo_group_identifier &&
                          errors.combo_group_identifier
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      id="combo_group_identifier"
                      label="Name"
                      name="combo_group_identifier"
                      autoComplete="combo_group_identifier"
                      size="small"
                      multiline
                      maxRows={3}
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.combo_group_identifier}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      helperText={
                        touched.combo_group_identifier &&
                          errors.combo_group_identifier
                          ? errors.combo_group_identifier
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={
                        touched.combo_group_sort_order &&
                          errors.combo_group_sort_order
                          ? true
                          : false
                      }
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      id="combo_group_sort_order"
                      label="Sort order"
                      name="combo_group_sort_order"
                      autoComplete="combo_group_sort_order"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.combo_group_sort_order}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.combo_group_sort_order &&
                          errors.combo_group_sort_order
                          ? errors.combo_group_sort_order
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={touched.minimum && errors.minimum ? true : false}
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      id="minimum"
                      label="Minimum"
                      name="minimum"
                      autoComplete="minimum"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.minimum}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.minimum && errors.minimum
                          ? errors.minimum
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      error={touched.maximum && errors.maximum ? true : false}
                      margin="normal"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      id="maximum"
                      label="Maximum"
                      name="maximum"
                      autoComplete="maximum"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.maximum}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: "1",
                        },
                      }}
                      helperText={
                        touched.maximum && errors.maximum
                          ? errors.maximum
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      error={
                        touched.combo_group_description &&
                          errors.combo_group_description
                          ? true
                          : false
                      }
                      margin="normal"
                      type="text"
                      fullWidth
                      rows={5}
                      id="combo_group_description"
                      label="Description"
                      name="combo_group_description"
                      autoComplete="combo_group_description"
                      size="small"
                      variant="standard"
                      onBlur={handleBlur}
                      value={values.combo_group_description}
                      onChange={handleChange}
                      aria-describedby="component-error-text"
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      helperText={
                        touched.combo_group_description &&
                          errors.combo_group_description
                          ? errors.combo_group_description
                          : null
                      }
                    />
                  </Grid>

                  <Grid>
                    {Array.isArray(errors) ||
                      (Object.values(errors).toString() != "" && (
                        <Alert severity="warning">
                          {Object.values(errors).toString()}
                        </Alert>
                      ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  {submittingForm ? (
                    <Loader size={30} />
                  ) : (
                    <>
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.handleComboGroupModal();
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button type="submit" color="primary">
                        {comboGroupToEdit ? "Update Combo" : "Add Combo"}
                      </Button>
                    </>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
  }
}

class AddEditOptionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      imagesPreviewUrl: null,
      categories: this.props.categoriesForOptions,
      selectedAddonCategory: this.props.selectedAddonCategory
        ? this.props.selectedAddonCategory
        : null,
      initialValues: {},
      fetchingData: false,
      optionToEdit: this.props.optionToEdit ? this.props.optionToEdit : null,
      categoryNames: [],
      selectedCategory: "",
      selectedItem: "",
      selectedItems: [],
      menuNames: this.props.menuNames,
      selectedMenu: this.props.selectedMenu,
      selectedCategory: "",
      categoryNames: [],
      item: null,
      checked: false,
      itemNames: this.props.itemNames,
    };
  }

  componentDidMount() {
    this.setInitalValues();
    // this.fetchAllAddonCategories();
  }

  setInitalValues = async () => {
    let addInitialValues = {
      addon_identifier: this.state.optionToEdit
        ? this.state.optionToEdit.addon_identifier
        : "",
      addon_cost: this.state.optionToEdit
        ? this.state.optionToEdit.addon_cost
        : "",
      linked_item_id: this.state.optionToEdit
        ? this.state.optionToEdit.linked_item_id
        : "",
      quantity: this.state.optionToEdit ? this.state.optionToEdit.quantity : "",
      addon_description: this.state.optionToEdit
        ? this.state.optionToEdit.addon_description
        : "",
      addon_sort_order: this.state.optionToEdit
        ? this.state.optionToEdit.addon_sort_order
        : "",
      is_addon_available: this.state.optionToEdit
        ? this.state.optionToEdit.is_addon_available
        : false,
      addon_category_id: this.state.optionToEdit
        ? this.state.optionToEdit.addon_category_id
        : this.state.selectedAddonCategory,
      total_addons: this.state.optionToEdit
        ? this.state.optionToEdit.total_addons
        : false,
    };

    if (this.state?.optionToEdit?.menu_id) {
      this.fetchCategoryNames(this.state?.optionToEdit?.menu_id);
      this.fetchItems(this.state?.optionToEdit?.category_id);
    }

    this.setState(
      {
        selectedAddonCategory: this.state.optionToEdit
          ? this.state.optionToEdit.addon_category_id
          : this.state.selectedAddonCategory,
        imagesPreviewUrl: this.state.optionToEdit
          ? this.state.optionToEdit.addon_image
          : null,
        initialValues: addInitialValues,
        checked: this.state.optionToEdit?.linked_item_id ? true : false,
        selectedItem: this.state.optionToEdit
          ? this.state.optionToEdit.linked_item_id
          : "",
        selectedMenu: this.state.optionToEdit
          ? this.state.optionToEdit.menu_id
          : "",
        selectedCategory: this.state.optionToEdit
          ? this.state.optionToEdit.category_id
          : "",
        itemNames: this.state.optionToEdit?.linked_item_id
          ? [
            {
              item_id: this.state.optionToEdit.linked_item_id,
              item_identifier: this.state.optionToEdit.addon_identifier,
            },
          ]
          : null,
      },

    );
    if (!this.state.optionToEdit) {
      try {
        const response = await fetch(coming);

        if (!response.ok) {
          throw new Error("Failed to fetch the image.");
        }

        const blob = await response.blob();
        const reader = new FileReader();
        const file = new File([blob], "coming.jpg", { type: blob.type });

        reader.onloadend = () => {
          // Convert the image to a data URL
          const base64Data = reader.result;
          this.setState({
            imagesPreviewUrl: base64Data,
            file: file,
          });
        };

        reader.onerror = (error) => {
          console.error("Error occurred while reading the image:", error);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error occurred while fetching the image:", error);
      }
    }
  };

  setLinkedItemInitialValues = (setFieldValue) => {
    if (this.props.itemId != this.state.item?.item_id) {
      let addInitialValues = {
        addon_identifier: this.state.item
          ? this.state.item.item_identifier
          : "",
        addon_cost: this.state.item ? this.state.item.item_cost : "",
        quantity: this.state.item ? this.state.item.total_items : "",
        addon_sort_order: this.state.optionToEdit
          ? this.state.optionToEdit.addon_sort_order
          : "",
        linked_item_id: this.state.optionToEdit
          ? this.state.optionToEdit.linked_item_id || this.state.selectedItem
          : this.state.selectedItem,
        addon_category_id: this.state.optionToEdit
          ? this.state.optionToEdit.addon_category_id
          : this.state.selectedAddonCategory,
        maximum: this.state.optionToEdit ? this.state.optionToEdit.maximum : "",
        minimum: this.state.optionToEdit ? this.state.optionToEdit.minimum : "",
        addon_description: this.state.item
          ? this.state.item.item_description
          : "",
      };

      this.setState(
        {
          initialValues: addInitialValues,
          imagesPreviewUrl: this.state.item ? this.state.item.item_image : null,
        },

      );
    } else {
      toast.warn("you can't link an item with itself.");
    }
  };

  fetchAllAddonCategories = async () => {
    this.setState({ fetchingData: true });
    let res = await fetchAllAddonCategory(this.props.propertyId);
    if (res.data.status) {
      this.setState({ categories: res.data.data, fetchingData: false });
    } else {
      toast.warn("Could not fetch addon categories");
      this.setState({ fetchingData: false });
    }
  };

  handleViewOptionsDialog = () => {
    this.setState({ openViewOptionsDialog: !this.state.openViewOptionsDialog });
  };

  handleAddonCategoryChange = (e, setFieldValue) => {
    setFieldValue("addon_category_id", e.target.value);
    this.setState({ selectedAddonCategory: e.target.value });
  };

  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
    }
  };

  handleMenuChange = (e, setFieldValue) => {
    this.setState({ selectedMenu: e.target.value });
    setFieldValue("menu_id", e.target.value);
    this.setState({ itemNames: null });
    this.fetchCategoryNames(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  fetchCategoryNames = async (selectedMenu) => {
    try {
      let categories = await fetchCategory(selectedMenu);

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        // setLoading(false);
        this.setState({ categoryNames: [] });
        this.setState({ selectedCategory: "" });
        return;
      } else {
        this.setState({
          categoryNames: categories.data.data.category_response_bean_list,
        });
      }
    } catch (e) {
    }
  };

  handleCategoryChange = (e, setFieldValue) => {
    this.setState({ selectedCategory: e.target.value });
    setFieldValue("category_id", e.target.value);
    this.fetchItems(e.target.value);

    // this.props.setSelectedMenu(event.target.value);
  };

  fetchItems = async (value) => {
    try {
      let items = await fetchItem(value);


      if (
        items.data.status == false ||
        items.data.message == "No details found."
      ) {
        this.setState({
          itemNames: [],
        });
        return;
      } else {
        const filteredItems = items.data.data.item_response_bean_list.filter(
          (item) => !item.is_combo
        );
        this.setState({
          itemNames: filteredItems,
        });
      }
    } catch (e) {
    }
  };

  handleItemChange = async (e, setFieldValue) => {
    this.setState({ selectedItem: e.target.value });
    setFieldValue("linked_item_id", e.target.value);
    const found_item = this.state.itemNames.find(
      (item) => item.item_id === e.target.value
    );
    this.setState({ item: found_item });
    this.setState(
      {
        item: found_item,
      },
      () => {
        this.setLinkedItemInitialValues(setFieldValue);
      }
    );

  };

  handleLinkedItems = (event) => {

    this.setState({ checked: event.target.checked, selectedItem: "" });
  };

  render() {
    const {
      initialValues,
      optionToEdit,
      selectedAddonCategory,
      fetchingData,
      itemNames,
    } = this.state;
    return (
      <>
        <Dialog
          open={this.props.openOptionsModel}
          onClose={() => {
            this.props.handleOptionsModal();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Manage Options</DialogTitle>
          <DialogContent>
            {fetchingData ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  this.state.optionToEdit?.linked_item_id || this.state?.checked
                    ? schema.optionsItemSchema
                    : schema.optionsSchema
                }
                onSubmit={(values) => {
                  if (optionToEdit) {
                    values["addon_id"] = optionToEdit.addon_id;
                  }
                  if (this.state.file) {
                    values["addon_image"] = this.state.file;
                  }
                  if (this.state.selectedItem) {
                    values["linked_item_id"] = this.state.selectedItem;
                  } else {
                    values["linked_item_id"] = "";
                  }
                  this.props.handleAddons(values, "OPTIONS");
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid style={{ marginTop: "20px" }} item xs={6} md={6}>
                        <FormControl
                          fullWidth
                          error={
                            touched.addon_category_id &&
                              errors.addon_category_id
                              ? true
                              : false
                          }
                        >
                          <InputLabel id="addon_category_id">
                            Options Category
                          </InputLabel>
                          <Select
                            labelId="addon_category_id"
                            id="addon_category_id"
                            name="addon_category_id"
                            value={selectedAddonCategory}
                            input={
                              <OutlinedInput
                                id="addon_category_id"
                                label="Options  Category"
                              />
                            }
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            defaultValue={selectedAddonCategory}
                            className="formControl"
                            disabled={!this.state.categories}
                            onChange={(e, val) =>
                              this.handleAddonCategoryChange(e, setFieldValue)
                            }
                          >
                            {this.state.categories &&
                              this.state.categories.map((item) => {
                                return (
                                  <MenuItem
                                    key={item.addon_category_id}
                                    value={item.addon_category_id}
                                  >
                                    <span>
                                      {item.addon_category_identifier}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText error>
                            {touched.addon_category_id &&
                              errors.addon_category_id
                              ? errors.addon_category_id
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            checked={this.state.checked}
                            onChange={this.handleLinkedItems}
                            control={<Checkbox />}
                            label="Do you want to link an Item? "
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </Grid>

                      {this.state?.checked && (
                        <React.Fragment>
                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="menu_id">Menu</InputLabel>
                              <Select
                                error={
                                  touched.menu_id && errors.menu_id
                                    ? true
                                    : false
                                }
                                labelId="menu_id"
                                id="menu_id"
                                name="menu_id"
                                value={this.state.selectedMenu}
                                defaultValue={this.state.selectedMenu}
                                input={
                                  <OutlinedInput id="menu_id" label="Menu" />
                                }
                                className="formControl"
                                disabled={!this.props.menuNames}
                                MenuProps={{
                                  disableScrollLock: true,
                                }}
                                onChange={(e, val) =>
                                  this.handleMenuChange(e, setFieldValue)
                                }
                              >
                                {this.state.menuNames &&
                                  this.state.menuNames.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.menu_id}
                                        value={item.menu_id}
                                      >
                                        <span>{item.menu_identifier}</span>
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.menu_id && errors.menu_id
                                  ? errors.menu_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-dialog-select-label">
                                Category
                              </InputLabel>

                              <Select
                                labelId="select-category"
                                id="category_id"
                                error={
                                  touched.category_id && errors.category_id
                                    ? true
                                    : false
                                }
                                name="category_id"
                                value={this.state.selectedCategory}
                                onChange={(e, val) =>
                                  this.handleCategoryChange(e, setFieldValue)
                                }
                                input={
                                  <OutlinedInput
                                    id="category_id"
                                    label="Category"
                                  />
                                }
                                defaultValue={this.state.selectedCategory}
                                className="formControl"
                              >
                                {this.state.categoryNames &&
                                  this.state.categoryNames.map((item) => {
                                    return (
                                      <MenuItem
                                        fullWidth
                                        key={item.category_id}
                                        value={item.category_id}
                                      >
                                        {item.category_identifier}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.category_id && errors.category_id
                                  ? errors.category_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="linked_item_id">Item</InputLabel>

                              <Select
                                labelId="linked_item_id"
                                id="linked_item_id"
                                error={
                                  touched.linked_item_id &&
                                    errors.linked_item_id
                                    ? true
                                    : false
                                }
                                name="linked_item_id"
                                value={this.state.selectedItem}
                                onChange={(e, val) =>
                                  this.handleItemChange(e, setFieldValue)
                                }
                                input={
                                  <OutlinedInput
                                    id="linked_item_id"
                                    label="Item"
                                  />
                                }
                                defaultValue={this.state.selectedItem}
                                className="formControl"
                              >
                                {itemNames &&
                                  itemNames.map((item) => {
                                    return (
                                      <MenuItem
                                        fullWidth
                                        key={item.item_id}
                                        value={item.item_id}
                                      >
                                        {item.item_identifier}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              <FormHelperText error>
                                {touched.linked_item_id && errors.linked_item_id
                                  ? errors.linked_item_id
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      )}

                      <Grid item xs={6} md={6}>
                        <FormControl fullWidth>
                          <TextField
                            error={
                              touched.addon_identifier &&
                                errors.addon_identifier
                                ? true
                                : false
                            }
                            margin="normal"
                            type="text"
                            id="addon_identifier"
                            label="Name"
                            name="addon_identifier"
                            autoComplete="addon_identifier"
                            size="small"
                            multiline
                            maxRows={3}
                            pattern="\s*\S+.*"
                            variant="standard"
                            disabled={this.state?.checked ? true : false}
                            onBlur={(e) => {
                              setFieldValue(
                                "addon_identifier",
                                e.target.value.trim()
                              );
                            }}
                            value={values.addon_identifier}
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                            helperText={
                              touched.addon_identifier &&
                                errors.addon_identifier
                                ? errors.addon_identifier
                                : null
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <FormControl fullWidth>
                          <TextField
                            error={
                              touched.quantity && errors.quantity ? true : false
                            }
                            margin="normal"
                            type="number"
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 12);
                            }}
                            id="quantity"
                            label="Quantity"
                            disabled={this.state?.checked ? true : false}
                            name="quantity"
                            autoComplete="quantity"
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            value={values.quantity}
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: "1",
                              },
                            }}
                            InputLabelProps={{
                              shrink:
                                values.quantity || values.quantity >= 0
                                  ? true
                                  : false,
                            }}
                            helperText={
                              touched.quantity && errors.quantity
                                ? errors.quantity
                                : null
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormControl fullWidth>
                          <TextField
                            error={
                              touched.addon_sort_order &&
                                errors.addon_sort_order
                                ? true
                                : false
                            }
                            margin="normal"
                            type="number"
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 3);
                            }}
                            id="addon_sort_order"
                            label="Sort order"
                            name="addon_sort_order"
                            autoComplete="addon_sort_order"
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            value={values.addon_sort_order}
                            onChange={handleChange}
                            aria-describedby="component-error-text"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                step: "1",
                              },
                            }}
                            helperText={
                              touched.addon_sort_order &&
                                errors.addon_sort_order
                                ? errors.addon_sort_order
                                : null
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <TextField
                            error={
                              touched.addon_description &&
                                errors.addon_description
                                ? true
                                : false
                            }
                            margin="normal"
                            type="text"
                            fullWidth
                            multiline
                            rows={5}
                            id="addon_description"
                            label="Description"
                            name="addon_description"
                            autoComplete="addon_description"
                            size="small"
                            variant="standard"
                            onBlur={handleBlur}
                            value={values.addon_description}
                            onChange={handleChange}
                            disabled={this.state?.checked ? true : false}
                            aria-describedby="component-error-text"
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                            }}
                            helperText={
                              touched.addon_description &&
                                errors.addon_description
                                ? errors.addon_description
                                : null
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        {!this.state?.checked && (
                          <MultipleImageCropper
                            data={{
                              key: "menu",
                              isMultiple: false,
                              getCroppedImages: this.getCroppedImages,
                            }}
                            height={300}
                            width={300}
                          />
                        )}
                        <br></br> <br></br>
                        <img
                          className="cover"
                          src={
                            this.state.imagesPreviewUrl
                              ? this.state.imagesPreviewUrl
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={this.props.track}
                        >
                          {optionToEdit ? "Update Option" : "Add Option"}
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => {
                            this.props.handleOptionsModal();
                          }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {Array.isArray(errors) ||
                        (Object.values(errors).toString() != "" && (
                          <Alert severity="warning">
                            {Object.values(errors).toString()}
                          </Alert>
                        ))}
                      {/*  <Button
                        onClick={() => {
                          this.props.handleOptionsModal();
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button type="submit" color="primary">
                        {optionToEdit ? "Update Option" : "Add Option"}
                      </Button> */}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </>
    );
  }
}

class AddEditComboItemsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "",
      selectedItem: this.props.itemToEdit.item_id
        ? this.props.itemToEdit.item_id
        : null,
      selectedCategory: "",
      initialValues: {},
      fetchingData: false,
      comboItemToEdit: this.props.comboItemToEdit
        ? this.props.comboItemToEdit
        : null,
      itemName: this.props.comboItemToEdit
        ? this.props.comboItemToEdit.item_identifier
        : "",
      categoryNames: [],
      itemNames: this.props.itemNames,
      item: null,
    };
  }

  componentDidMount() {
    this.setInitalValues();
    // this.fetchAllAddonCategories();
  }

  setInitalValues = () => {
    let addInitialValues = {
      item_id: this.props.comboItemToEdit
        ? this.props.comboItemToEdit.item_id
        : "",
      category_id: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.category_id
        : this.state.selectedCategory,
      menu_id: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.menu_id
        : this.state.selectedMenu,
      extra_price: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.extra_price
        : "",
      maximum: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.maximum
        : "",
      minimum: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.minimum
        : "",
      quantity: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.quantity
        : "",
      combo_item_sort_order: this.state.comboItemToEdit
        ? this.state.comboItemToEdit.combo_item_sort_order
        : "",
    };

    if (this.state?.comboItemToEdit?.menu_id) {
      this.fetchCategoryNames(this.state?.comboItemToEdit?.menu_id);
    }

    this.setState(
      {
        initialValues: addInitialValues,
        item_id: this.state.comboItemToEdit
          ? this.state.comboItemToEdit.item_id
          : "",
        menu_id: this.state.comboItemToEdit
          ? this.state.comboItemToEdit.menu_id
          : "",
        category_id: this.state.comboItemToEdit
          ? this.state.comboItemToEdit.category_id
          : "",
        itemNames: this.state.comboItemToEdit
          ? [
            {
              item_id: this.state.comboItemToEdit.item_id,
              item_identifier: this.state.comboItemToEdit.item_identifier,
            },
          ]
          : null,
      },
      () => { }
    );
  };

  setLinktedItemValues = () => {
    let addInitialValues = {
      quantity: this.state.item ? this.state.item.total_items : "",
      item_id: this.state.selectedItem,
      menu_id: this.state.selectedMenu,
      category_id: this.state.selectedCategory,
      extra_price: "",
      combo_item_sort_order: "",
      maximum: "",
      minimum: "",
    };

    this.setState(
      {
        initialValues: addInitialValues,
      },
      () => { }
    );
  };

  fetchCategoryNames = async (selectedMenu) => {
    try {
      let categories = await fetchCategory(selectedMenu);

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        // setLoading(false);
        this.setState({ categoryNames: [] });
        this.setState({ selectedCategory: "" });
        return;
      } else {
        this.setState({
          categoryNames: categories.data.data.category_response_bean_list,
        });
      }
    } catch (e) {
    }
  };

  handleAddonCategoryChange = (e, setFieldValue) => {
    setFieldValue("addon_category_id", e.target.value);
    this.setState({ selectedAddonCategory: e.target.value });
  };

  handleMenuChange = (e, setFieldValue) => {
    this.setState({ selectedMenu: e.target.value });
    setFieldValue("menu_id", e.target.value);
    this.setState({ itemNames: null });
    this.fetchCategoryNames(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  handleCategoryChange = (e, setFieldValue) => {
    this.setState({ selectedCategory: e.target.value });
    setFieldValue("category_id", e.target.value);
    this.fetchItems(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  handleItemChange = (e, setFieldValue) => {
    const found_item = this.state.itemNames.find(
      (item) => item.item_id === e.target.value
    );
    setFieldValue("item_id", e.target.value);
    this.setState(
      {
        item: found_item,
        selectedItem: e.target.value,
      },
      () => {
        this.setLinktedItemValues();
      }
    );

  };

  fetchItems = async (value) => {
    try {
      let items = await fetchItem(value);


      if (
        items.data.status == false ||
        items.data.message == "No details found."
      ) {
        this.setState({
          itemNames: [],
        });
        return;
      } else {
        const comboItemToEditItemId = this.props.itemToEdit?.item_id; // Get the item_id from props or set to null if not available

        const filteredItems = items.data.data.item_response_bean_list.filter(
          (item) => !item.is_combo && item.item_id !== comboItemToEditItemId
        );
        const furtherFilteredItems = filteredItems.filter((item1) => {
          // Check if an object with the same item_id exists in arrayOfObjects2
          return !this.props.comboItems?.some(
            (item2) =>
              item2?.item_id === item1?.item_id &&
              this.props.comboItemToEdit?.item_id !== item2?.item_id
          );
        });
        this.setState({
          itemNames: furtherFilteredItems,
        });
      }
    } catch (e) {
    }
  };

  render() {
    const { initialValues, comboItemToEdit, fetchingData } = this.state;

    return (
      <>
        <Dialog
          open={this.props.openComboGroupItemsModel}
          onClose={() => {
            this.props.handleComboItemModal();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Manage Combo Items</DialogTitle>
          <DialogContent>
            {fetchingData ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={schema.comboItemSchema}
                onSubmit={(values) => {
                  if (comboItemToEdit) {
                    values["combo_id"] = comboItemToEdit.combo_id;
                  }
                  this.props.handleComboItems(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6} md={6}>
                        <FormControl
                          fullWidth
                          sx={{ marginTop: "10px" }}
                          error={
                            touched.menu_id && errors.menu_id ? true : false
                          }
                        >
                          <FormControl fullWidth>
                            <InputLabel id="menu_id">Menu</InputLabel>
                            <Select
                              error={
                                touched.menu_id && errors.menu_id ? true : false
                              }
                              labelId="menu_id"
                              id="menu_id"
                              name="menu_id"
                              value={values.menu_id}
                              defaultValue={values.menu_id}
                              input={
                                <OutlinedInput id="menu_id" label="Menu" />
                              }
                              className="formControl"
                              disabled={!this.props.menuNames}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={(e, val) =>
                                this.handleMenuChange(e, setFieldValue)
                              }
                            >
                              {this.props.menuNames &&
                                this.props.menuNames.map((item) => {
                                  return (
                                    <MenuItem
                                      key={item.menu_id}
                                      value={item.menu_id}
                                    >
                                      <span>{item.menu_identifier}</span>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText error>
                              {touched.menu_id && errors.menu_id
                                ? errors.menu_id
                                : null}
                            </FormHelperText>
                          </FormControl>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <FormControl fullWidth sx={{ marginTop: "10px" }}>
                          <InputLabel id="demo-dialog-select-label">
                            Category
                          </InputLabel>

                          <Select
                            labelId="select-category"
                            id="category_id"
                            error={
                              touched.category_id && errors.category_id
                                ? true
                                : false
                            }
                            name="category_id"
                            value={values.category_id}
                            onChange={(e, val) =>
                              this.handleCategoryChange(e, setFieldValue)
                            }
                            input={
                              <OutlinedInput
                                id="category_id"
                                label="Category"
                              />
                            }
                            defaultValue={values.category_id}
                            className="formControl"
                          >
                            {this.state.categoryNames &&
                              this.state.categoryNames.map((category) => {
                                return (
                                  <MenuItem
                                    fullWidth
                                    key={category.category_id}
                                    value={category.category_id}
                                  >
                                    {category.category_identifier}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText error>
                            {touched.category_id && errors.category_id
                              ? errors.category_id
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid style={{ marginTop: "20px" }} item xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="item_id">Item</InputLabel>

                          <Select
                            labelId="item_id"
                            id="item_id"
                            error={
                              touched.item_id && errors.item_id ? true : false
                            }
                            name="item_id"
                            value={values.item_id}
                            onChange={(e, val) =>
                              this.handleItemChange(e, setFieldValue)
                            }
                            input={<OutlinedInput id="item_id" label="Item" />}
                            defaultValue={values.item_id}
                            className="formControl"
                          >
                            {this.state.itemNames &&
                              this.state.itemNames.map((item) => {
                                return (
                                  <MenuItem
                                    fullWidth
                                    key={item.item_id}
                                    value={item.item_id}
                                  >
                                    {item.item_identifier}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText error>
                            {touched.item_id && errors.item_id
                              ? errors.item_id
                              : null}
                          </FormHelperText>
                        </FormControl>

                        {/*     {comboItemToEdit &&
                          <TextField
                            id="outlined-read-only-input"
                            label="Item Name"
                            defaultValue={this.state.itemName}
                            InputProps={{
                              readOnly: true,
                            }} />} */}
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.extra_price && errors.extra_price
                              ? true
                              : false
                          }
                          margin="normal"
                          id="extra_price"
                          label="Price"
                          name="extra_price"
                          autoComplete="extra_price"
                          size="small"
                          variant="standard"
                          onInput={(event) => {
                            if (!isNaN(event.target.value)) {
                              event.target.value = !isNaN(event.target.value)
                                ? event.target.value
                                : "";
                            } else {
                              event.target.value = "";
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.extra_price}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "any",
                            },
                          }}
                          helperText={
                            touched.extra_price && errors.extra_price
                              ? errors.extra_price
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.minimum && errors.minimum ? true : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          id="minimum"
                          label="Minimum"
                          name="minimum"
                          autoComplete="minimum"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.minimum}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.minimum && errors.minimum
                              ? errors.minimum
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.maximum && errors.maximum ? true : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          id="maximum"
                          label="Maximum"
                          name="maximum"
                          autoComplete="maximum"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.maximum}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.maximum && errors.maximum
                              ? errors.maximum
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.quantity && errors.quantity ? true : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 12);
                          }}
                          id="quantity"
                          label="Quantity"
                          name="quantity"
                          autoComplete="quantity"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.quantity}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink:
                              values.quantity || values.quantity >= 0
                                ? true
                                : false,
                          }}
                          disabled={true}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.quantity && errors.quantity
                              ? errors.quantity
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <TextField
                          error={
                            touched.combo_item_sort_order &&
                              errors.combo_item_sort_order
                              ? true
                              : false
                          }
                          margin="normal"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          id="combo_item_sort_order"
                          label="Sort order"
                          name="combo_item_sort_order"
                          autoComplete="combo_item_sort_order"
                          size="small"
                          variant="standard"
                          onBlur={handleBlur}
                          value={values.combo_item_sort_order}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              step: "1",
                            },
                          }}
                          helperText={
                            touched.combo_item_sort_order &&
                              errors.combo_item_sort_order
                              ? errors.combo_item_sort_order
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Button type="submit" color="primary">
                          {comboItemToEdit
                            ? "Update Combo Item"
                            : "Add Combo Item"}
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => {
                            this.props.handleComboItemModal();
                          }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {Array.isArray(errors) ||
                        (Object.values(errors).toString() != "" && (
                          <Alert severity="warning">
                            {Object.values(errors).toString()}
                          </Alert>
                        ))}
                      {/*  <Button
                        onClick={() => {
                          this.props.handleOptionsModal();
                        }}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button type="submit" color="primary">
                        {optionToEdit ? "Update Option" : "Add Option"}
                      </Button> */}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </>
    );
  }
}

function ViewExtrasDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setViewExtrasDialog(false, null)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="extras info">
          {props.extraToShow.addon_identifier}
        </DialogTitle>
        <DialogContent>
          <List component="nav">
            {Object.entries(props.extraToShow).map(([key, value]) => {
              return (
                <>
                  <ListItem key={key}>
                    <span>
                      <b>{key}:&nbsp;</b>
                    </span>
                    <span>{value}</span>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setViewExtrasDialog(false, null)}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
  disableScrollLock: true,
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    color:
      personName.indexOf(name) === -1
        ? theme.palette.action.main
        : theme.palette.primary.main,
  };
}

const theme = createTheme({});

var upSellItemMap;

class AddEditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagesPreviewUrl: null,
      submitting: false,
      itemToEdit: this.props.itemToEdit ? this.props.itemToEdit : null,
      addonCategoryToEdit: null,
      comboGroupToEdit: null,
      initialValues: {},
      openExtrasModel: false,
      openOptionsModel: false,
      openAddonCategoryModel: false,
      openCombosModel: false,
      extraToEdit: null,
      optionToEdit: null,
      comboItemToEdit: null,
      selectedCategory: "",
      upSellSelectedCategory: "",
      upSellSelectedMenu: "",
      upSellCategoryNames: "",
      upSellItemNames: [],
      upSellItemIds: [],
      upSellSelectedItemNames: [],
      upSellSelectedItem: "",
      expanded: false,
      subExpanded: false,
      categoryFilters: null,
      selectedCategoryFilters: [],
      selectedDietryFilters: [],
      selectedAddonCategory: null,
      selectedComboGroup: null,
      categoryType: null,
      openViewExtrasDialog: false,
      extraToShow: null,
      descCount: this.props.itemToEdit
        ? this.props.itemToEdit.item_description.length
        : 0,
      itemIdentifier: this.props.itemToEdit
        ? this.props.itemToEdit.item_identifier.length
        : 0,
      is_combo: this.props.itemToEdit ? this.props.itemToEdit?.is_combo : false,
      names: [],
      /*   personName: [],
        personNames: [], */
      upSellItemMap: {},
      canOpen: false,
      track: false,
      vatValues: [],
      tags: [],
      isTaxInclusive: false,
      showBarCardField: false,
      inventoryPlan: {},
      isEditable: false,
      defaultItem: "",
      dialogOpen: false,
      barCodeDialog:false,
      itemId:"",
    };
  }

  componentDidMount() {
    this.setInitalValues();
    this.fetchCategoryFIlters();
    this.fetchInventoryPlan();

    //  this.fetchUpsellItems(this.props.propertyId);

    if (this.props.itemToEdit?.upsell_item_ids) {
      // this.setState({ personName: [...this.props.itemToEdit.upsell_item_ids] }, () => {

      this.handleExistingUpsellItems();

      // });
    }

    this.setState({
      // upSellSelectedMenu: this.props.upSellSelectedMenu,
      upSellCategoryNames: this.props.categoryNames,
      vatValues: this.props.settings?.vat_details?.item_vat_details, isTaxInclusive: this.props?.settings?.vat_details?.is_tax_inclusive
      // upSellSelectedCategory: this.props.categoryNames[0].category_id
    });

    if (this.state.itemToEdit) {
      this.fetchItemFilters(this.state.itemToEdit.item_id);
    }
    this.fetchItemTags();
  }
  handleExistingUpsellItems = () => {
    let upsellItemNames = [];
  
    this.props.itemToEdit.upsell_item_ids.forEach((item) => {
      const upSellItemName = this.props.upSellItemMap.get(item);
      if (!upsellItemNames.includes(upSellItemName)) {
        upsellItemNames.push(upSellItemName);
      }
    });
  
  
    this.setState({
      upSellSelectedItemNames: [...upsellItemNames],
      upSellItemIds: this.props.itemToEdit.upsell_item_ids,
    });
  };
  

  fetchInventoryPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await InventoryrServiceAPI.fetchInventoryPlan(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              inventoryPlan: response.data.data,
            },
            () => {
              console.log(this.state.inventoryPlan)
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ inventoryPlan: {} });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ inventoryPlan: {} });
      toast.warn(err);
      throw error;
    }
  };

  setInitalValues = async () => {
    const defaultVatDetail = this.props.settings?.vat_details?.item_vat_details.find(detail => detail.is_default);
    let addInitialValues = {
      category_id: this.props.selectedCategory
        ? this.props.selectedCategory
        : "",
      item_identifier: this.state.itemToEdit
        ? this.state.itemToEdit.item_identifier
        : "",
      item_cost: this.state.itemToEdit ? this.state.itemToEdit.item_cost : "",
      item_sort_order: this.state.itemToEdit
        ? this.state.itemToEdit.item_sort_order
        : "",
      item_preparation_time: this.state.itemToEdit ? this.state.itemToEdit.item_preparation_time : "",
      is_combo: this.state.itemToEdit ? this.state.itemToEdit?.is_combo : false,
      vat: this.state.itemToEdit ? this.state.itemToEdit.vat_percentage : "",
      tag_ids: this.state.itemToEdit ? this.state.itemToEdit?.tag_ids?.[0] ?? '' : " ",
      item_status: this.state.itemToEdit
        ? this.state.itemToEdit.item_status
        : "AVAILABLE",
      dietaryfilters: this.state.itemToEdit
        ? this.state.itemToEdit.dietaryfilters
        : [],
      // categoryfilters: this.state.itemToEdit
      //   ? this.state.itemToEdit.categoryfilters
      //   : "",
      is_take_away_active: this.state.itemToEdit
        ? this.state.itemToEdit.is_take_away_active
        : true,
      is_dine_in_active: this.state.itemToEdit
        ? this.state.itemToEdit.is_dine_in_active
        : true,
      is_delivery_active: this.state.itemToEdit
        ? this.state.itemToEdit.is_delivery_active
        : true,
      is_delivery_to_park_active: this.state.itemToEdit
        ? this.state.itemToEdit.is_delivery_to_park_active
        : false,
      is_kiosk_active: this.state.itemToEdit
        ? this.state.itemToEdit.is_kiosk_active
        : true,
      item_description: this.state.itemToEdit
        ? this.state.itemToEdit.item_description
        : "",
      mobile_availability: this.state.itemToEdit
        ? this.state.itemToEdit?.item_availability?.mobile_availability
        : true,
      kiosk_availability: this.state.itemToEdit
        ? this.state.itemToEdit?.item_availability?.kiosk_availability
        : true,
      epos_availability: this.state.itemToEdit
        ? this.state.itemToEdit?.item_availability?.epos_availability
        : true,
      web_availability: this.state.itemToEdit
        ? this.state.itemToEdit?.item_availability?.web_availability
        : true,
      business_app_availability: this.state.itemToEdit
        ? this.state.itemToEdit?.item_availability?.business_app_availability
        : true,
      is_negative_sale: this.state.itemToEdit
        ? this.state.itemToEdit?.is_negative_sale
        : false,
      is_item_label_print: this.state.itemToEdit
        ? this.state.itemToEdit.is_item_label_print
        : false,
      is_inventory: this.state.itemToEdit
        ? this.state.itemToEdit.is_inventory
        : false,
      label: this.state.itemToEdit ? this.state.itemToEdit.label : "",
      cost_max: this.state.itemToEdit ? this.state.itemToEdit.cost_max : "",
      quantity: this.state.itemToEdit ? this.state.itemToEdit.quantity : 99999,
      max_extras: this.state.itemToEdit ? this.state.itemToEdit.max_extras : "",
      /*   total_selectable_extras: this.state.itemToEdit
          ? this.state.itemToEdit.total_selectable_extras
          : "", */
      max_options: this.state.itemToEdit
        ? this.state.itemToEdit.max_options
        : "",
      single_option_limit: this.state.itemToEdit
        ? this.state.itemToEdit.single_option_limit
        : "",
      dietary_info: this.state.itemToEdit
        ? this.state.itemToEdit.dietary_info
        : {
          calorie: "",
          protein: "",
          transFat: "",
        },
      item_bar_code: this.state.itemToEdit ? this.state.itemToEdit.item_bar_code : ""
    };

    this.setState({
      initialValues: addInitialValues,
      selectedCategory: this.props.selectedCategory
        ? this.props.selectedCategory
        : "",
      selectedDietryFilters: this.state.itemToEdit ? [] : [],
      imagesPreviewUrl: this.state.itemToEdit
        ? this.state.itemToEdit.item_image
        : null,
      defaultItem: defaultVatDetail?.value
    });

    if (!this.state.itemToEdit) {
      try {
        const response = await fetch(coming);

        if (!response.ok) {
          throw new Error("Failed to fetch the image.");
        }

        const blob = await response.blob();
        const reader = new FileReader();
        const file = new File([blob], "coming.jpg", { type: blob.type });

        reader.onloadend = () => {
          // Convert the image to a data URL
          const base64Data = reader.result;
          this.setState({
            imagesPreviewUrl: base64Data,
            file: file,
          });
        };

        reader.onerror = (error) => {
          console.error("Error occurred while reading the image:", error);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error occurred while fetching the image:", error);
      }
    }
  };
  submitItem = (values, resetForm) => {
    if (this.state.file == null) {
      toast.warn("Image is required. ");
      return false;
    }

    const emojiRegex = /([\u231A-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF][\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|[\u2600-\u26FF])/g;
    values['item_identifier'] = values['item_identifier']?.replace(emojiRegex, '');
    values['item_description'] = values['item_description']?.replace(emojiRegex, '');

    /*  if (values.quantity == 0 && values.item_status == "AVAILABLE") {
       toast.warn("When quanity is zero, status can't be available.");
       this.setState({ submitting: false });
       return false;
     }
 
     if (values.quantity >= 1 && values.item_status == "SOLD OUT") {
       toast.warn("When quanity is greater then 0, status can't be sold out.");
       this.setState({ submitting: false });
       return false;
     } */

    this.setState({ submitting: true });
    let formData = new FormData();

    if (values.dietary_info?.calorie) {
      values.dietary_info.calorie = values?.dietary_info?.calorie
        .trim()
        .replace(/\s+/g, "");
    }

    if (values.dietary_info?.protein) {
      values.dietary_info.protein = values?.dietary_info?.protein
        .trim()
        .replace(/\s+/g, "");
    }

    if (values.dietary_info?.transFat) {
      values.dietary_info.transFat = values?.dietary_info?.transFat
        .trim()
        .replace(/\s+/g, "");
    }

    let item_availability = {
      kiosk_availability: values.kiosk_availability,
      epos_availability: values.epos_availability,
      mobile_availability: values.mobile_availability,
      web_availability: values.web_availability,
      business_app_availability: values.business_app_availability,
    };

    delete values.kiosk_availability;
    delete values.epos_availability;
    delete values.mobile_availability;
    delete values.web_availability;
    delete values.business_app_availability;

    values.item_availability = item_availability;

    values['tag_ids'] = [values.tag_ids];

    if(!this.state.showBarCardField){
      delete values.item_bar_code;
    }

    formData.append("item_details", JSON.stringify(values));
    formData.append("item_image", this.state.file);

    addItem(formData)
      .then((response) => {
        this.setState({ submitting: false });
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {
              this.props.fetchMenuNames();
              this.props.fetchItemsIndividually(values.category_id);
              this.props.setSelectedCategory(values.category_id);
              let propId = this.props.propertyId;
              if (propId) this.props.fetchUpsellItems(propId);
            }
          );
          resetForm({ values: "" });
          this.setState({ imagesPreviewUrl: "" });
          toast.success("Item Added");
          if (typeof this.props.handleQuickAdd === "function")
            this.props.handleQuickAdd("Close");
        } else {
          this.setState({
            submitting: false,
          });
        }
        toast.error(response.data.message);
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("something went wrong");
      });
  };

  updateItemDetails = async (values) => {
    this.setState({ submitting: true });
    if (
      this.state.is_combo &&
      (!this.props.comboItems || this.props.comboItems?.length < 1)
    ) {
      toast.warn("Please add at least one combo group and its item.");
      this.setState({ submitting: false });
      return false;
    }

    const emojiRegex = /([\u231A-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF][\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|[\u2600-\u26FF])/g;
    values['item_identifier'] = values['item_identifier']?.replace(emojiRegex, '');
    values['item_description'] = values['item_description']?.replace(emojiRegex, '');
    
    values.item_id = this.state.itemToEdit.item_id;

    values['tag_ids'] = [values.tag_ids]


    if (values.quantity == 0 && values.item_status == "AVAILABLE") {
      toast.warn("Item quantity cannot be zero. Please increase the quantity to update the item.");
      this.setState({ submitting: false });
      return false;
    }



    /*   if (values.quantity == 0 && values.item_status == "AVAILABLE") {
        toast.warn("When quanity is zero, status can't be available.");
        this.setState({ submitting: false });
        return false;
      }
  
      if (values.quantity >= 1 && values.item_status == "SOLD OUT") {
        toast.warn("When quanity is greater then 0, status can't be sold out.");
        this.setState({ submitting: false });
        return false;
      } */

    if (values.dietary_info?.calorie) {
      values.dietary_info.calorie = values?.dietary_info?.calorie
        .trim()
        .replace(/\s+/g, "");
    }

    if (values.dietary_info?.protein) {
      values.dietary_info.protein = values?.dietary_info?.protein
        .trim()
        .replace(/\s+/g, "");
    }

    if (values.dietary_info?.transFat) {
      values.dietary_info.transFat = values?.dietary_info?.transFat
        .trim()
        .replace(/\s+/g, "");
    }

    let item_availability = {
      kiosk_availability: values.kiosk_availability,
      epos_availability: values.epos_availability,
      mobile_availability: values.mobile_availability,
      web_availability: values.web_availability,
      business_app_availability: values.business_app_availability,
    };

    delete values.kiosk_availability;
    delete values.epos_availability;
    delete values.mobile_availability;
    delete values.web_availability;
    delete values.business_app_availability;

    values.item_availability = item_availability;

    if(!this.state.showBarCardField && values.item_bar_code ){
      delete values.item_bar_code;
    }

    values.total_selectable_extras = 100;

    let formData = new FormData();

    formData.append("item_details", JSON.stringify(values));

    if (this.state.file) {
      formData.append("item_image", this.state.file);
    }

    const { selectedCategoryFilters, categoryFilters, itemToEdit } = this.state;

    const dietaryItems =
      categoryFilters
        ?.find((category) => category.category_label === "Dietary")
        ?.sub_categories.flatMap(
          (subCategory) => subCategory.sub_category_id
        ) ?? [];

    const matches = selectedCategoryFilters
      .filter((item) => dietaryItems.includes(item))
      .slice(0, 6);
    const nonMatches = selectedCategoryFilters.filter(
      (item) => !dietaryItems.includes(item)
    );

    const filteredItems = [...matches, ...nonMatches];

    const updatedCategoryFilters = {
      filter_ids: filteredItems,
      item_id: itemToEdit.item_id,
    };

    // if (this.state.selectedCategoryFilters.length > 0) {
    let res = await updateItemFilter(updatedCategoryFilters);
    // }

    updateItem(formData)
      .then((response) => {
        this.setState({ submitting: false });
        if (response.data.status) {
          this.setState(
            {
              submitting: false,
            },
            () => {
              this.props.fetchItems();
              this.props.setSelectedCategory(values.category_id);
            }
          );
          toast.success("Item Updated");
          this.props.handleQuickAdd("Close");
        } else {
          this.setState({
            submitting: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
        toast.error("something went wrong");
      });
  };

  handleItem = (values, resetForm) => {
    values["dietaryfilters"] = this.state.selectedDietryFilters;
    values["upsell_item_ids"] = this.state.upSellItemIds;
    if (this.state.itemToEdit) {
      this.updateItemDetails(values);
    } else {
      this.submitItem(values, resetForm);
    }
  };

  // Invoked from Multi Cropper Child Component, returns cropped form data
  getCroppedImages = (croppedImagesFormData, key, imageArray, file) => {
    if (key === "menu") {
      this.setState({
        file: file[0],
        imagesPreviewUrl: imageArray[0],
      });
    } else {
    }
  };

  handleExtrasModal = (selectedAddonCategory) => {
    if (this.state.openExtrasModel) {
      this.setState({
        selectedAddonCategory: selectedAddonCategory,
        openExtrasModel: false,
        extraToEdit: null,
      });
    } else {
      this.setState({
        selectedAddonCategory: selectedAddonCategory,
        openExtrasModel: true,
      });
    }
  };

  handleOptionsModal = (selectedAddonCategory) => {
    if (this.state.openOptionsModel) {
      this.setState({
        selectedAddonCategory: selectedAddonCategory,
        openOptionsModel: false,
        optionToEdit: null,
      });
    } else {
      this.setState({
        selectedAddonCategory: selectedAddonCategory,
        openOptionsModel: true,
      });
    }
  };

  handleAddonCategoryModal = (type) => {
    if (this.state.openAddonCategoryModel) {
      this.setState({
        categoryType: type,
        openAddonCategoryModel: false,
        addonCategoryToEdit: null,
      });
    } else {
      this.setState({
        categoryType: type,
        openAddonCategoryModel: true,
      });
    }
  };

  handleComboGroupModal = (type) => {
    if (this.state.openCombosModel) {
      this.setState({
        openCombosModel: false,
        comboGroupToEdit: null,
      });
    } else {
      this.setState({
        openCombosModel: true,
        categoryType: type,
      });
    }
  };

  handleComboItemModal = (selectedComboGroup) => {
    if (this.state.openComboGroupItemsModel) {
      this.setState({
        selectedComboGroup: selectedComboGroup,
        openComboGroupItemsModel: false,
        comboItemToEdit: null,
      });
    } else {
      this.setState({
        selectedComboGroup: selectedComboGroup,
        openComboGroupItemsModel: true,
      });
    }
  };

  handleComboItems = (values) => {
    values["combo_group_id"] = this.state.selectedComboGroup;
    if (this.state.comboItemToEdit) {
      this.updateComboItem(values);
    } else {
      this.addComboItem(values);
    }
  };

  addComboItem = (values) => {
    let payload_data = {
      combo_group_id: values.combo_group_id ? values.combo_group_id : null,
      item_id: values.item_id ? values.item_id : null,
      menu_id: values.menu_id ? values.menu_id : null,
      category_id: values.category_id ? values.category_id : null,
      extra_price: values.extra_price ? values.extra_price : null,
      combo_item_sort_order: values.combo_item_sort_order
        ? values.combo_item_sort_order
        : null,
      maximum: values.maximum ? values.maximum : null,
      minimum: values.minimum ? values.minimum : null,
    };

    addComboItems(payload_data)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              openExtrasModel: false,
              openOptionsModel: false,
              extraToEdit: null,
              optionToEdit: null,
              openComboGroupItemsModel: false,
            },
            () => {
              this.props.fetchAllCombos();
            }
          );
          toast.success("Combo Item Added");
        } else {
          this.setState({
            openExtrasModel: false,
            openOptionsModel: false,
            extraToEdit: null,
            optionToEdit: null,
            openComboGroupItemsModel: true,
          });
          toast.warn(response.data?.message);
        }
      })
      .catch((error) => {

        this.setState({
          openExtrasModel: false,
          openOptionsModel: false,
          extraToEdit: null,
          optionToEdit: null,
          openComboGroupItemsModel: false,
        });
        toast.error("Could not add");
      });
  };

  updateComboItem = (values) => {
    let payload = {
      combo_group_id: values.combo_group_id ? values.combo_group_id : null,
      item_id: values.item_id ? values.item_id : null,
      menu_id: values.menu_id ? values.menu_id : null,
      category_id: values.category_id ? values.category_id : null,
      extra_price: values.extra_price ? values.extra_price : null,
      combo_item_sort_order: values.combo_item_sort_order
        ? values.combo_item_sort_order
        : null,
      combo_id: values.combo_id,
      maximum: values.maximum ? values.maximum : null,
      minimum: values.minimum ? values.minimum : null,
      /*   total_selectable_extras: values.total_selectable_extras
          ? values.total_selectable_extras
          : null, */
    };

    updateComboItems(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              openCombosModel: false,
              openComboGroupItemsModel: false,
              comboItemToEdit: null,
            },
            () => {
              this.props.fetchAllCombos();
            }
          );
          toast.success("Combo Item Updated.");
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          openExtrasModel: false,
          openOptionsModel: false,
          extraToEdit: null,
          optionToEdit: null,
        });
        toast.error("Could not update");
      });
  };

  handleAddons = (values, type) => {
    if (this.state.optionToEdit || this.state.extraToEdit) {
      this.updateAddons(values, type);
    } else {
      this.setState({ track: true });
      this.addAddons(values, type);
    }
  };

  addAddons = (values, type) => {
    let payload_data = {
      item_id: this.state.itemToEdit.item_id,
      linked_item_id: values.linked_item_id ? values.linked_item_id : null,
      addon_type: type,
      addon_identifier: values.addon_identifier
        ? values.addon_identifier
        : null,
      addon_category_id: values.addon_category_id
        ? values.addon_category_id
        : null,
      addon_description: values.addon_description
        ? values.addon_description
        : null,
      addon_cost: values.addon_cost ? values.addon_cost : null,
      quantity: values.quantity ? values.quantity : null,
      addon_status: values.addon_status ? values.addon_status : false,
      addon_sort_order: values.addon_sort_order
        ? values.addon_sort_order
        : null,
      total_addons: values.total_addons ? values.total_addons : null,
      /*    total_selectable_extras: values.total_selectable_extras
           ? values.total_selectable_extras
           : null, */
      maximum: values.maximum ? values.maximum : null,
      minimum: values.minimum ? values.minimum : null,
      is_addon_available: true,
    };

    let formData = new FormData();
    formData.append("addon_details", JSON.stringify(payload_data));
    if (values.addon_image) {
      formData.append("addon_image", values.addon_image);
    }

    addExtrasOptions(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              openExtrasModel: false,
              openOptionsModel: false,
              extraToEdit: null,
              optionToEdit: null,
              track: false,
            },
            () => {
              this.props.fetchAllAddons();
            }
          );
          toast.success("Addon Added");
        } else {
          this.setState({
            openExtrasModel: false,
            openOptionsModel: false,
            extraToEdit: null,
            optionToEdit: null,
            track: false,
          });
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.warn(
          "Oops! Something went wrong, Please try again later.",
          error
        );
        this.setState({
          openExtrasModel: false,
          openOptionsModel: false,
          extraToEdit: null,
          optionToEdit: null,
          track: false,
        });
        toast.error("Could not add.");
      });
  };

  updateAddons = (values, type) => {
    let payload_data = {
      item_id: this.state.itemToEdit.item_id,
      addon_id: values.addon_id,
      linked_item_id: values.linked_item_id ? values.linked_item_id : null,
      addon_type: type,
      addon_identifier: values.addon_identifier
        ? values.addon_identifier
        : null,
      addon_description: values.addon_description
        ? values.addon_description
        : null,
      addon_cost: values.addon_cost ? values.addon_cost : null,
      quantity: values.quantity ? values.quantity : 0,
      addon_status: values.addon_status ? values.addon_status : false,
      addon_sort_order: values.addon_sort_order
        ? values.addon_sort_order
        : null,
      total_addons: values.total_addons ? values.total_addons : null,
      maximum: values.maximum ? values.maximum : null,
      minimum: values.minimum ? values.minimum : null,
      /*   total_selectable_extras: values.total_selectable_extras
          ? values.total_selectable_extras
          : null, */
      is_addon_available: true,
      addon_category_id: values.addon_category_id
        ? values.addon_category_id
        : null,
    };

    let formData = new FormData();
    formData.append("addon_details", JSON.stringify(payload_data));
    if (values.addon_image) {
      formData.append("addon_image", values.addon_image);
    }
    updateExtrasOptions(formData)
      .then((response) => {
        if (response.data.status) {
          this.setState(
            {
              openExtrasModel: false,
              openOptionsModel: false,
              extraToEdit: null,
              optionToEdit: null,
            },
            () => {
              this.props.fetchAllAddons();
            }
          );
          toast.success("Addon Updated");
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({
          openExtrasModel: false,
          openOptionsModel: false,
          extraToEdit: null,
          optionToEdit: null,
        });
        toast.error("Could not update");
      });
  };

  deleteAddons = async (addonId) => {
    try {
      let res = await deleteExtrasOptions(addonId);
      if (res.data.status) {
        this.props.fetchAllAddons();
        toast.success("Addon deleted.");
      }
    } catch (e) {
      toast.error("Could not delete addon");
    }
  };

  deleteComboItems = async (payload) => {
    try {
      const matchingComboItemsInGroup = this.props.comboItems.filter(
        (comboItem) => comboItem.combo_group_id === payload.combo_group_id
      );
      if (matchingComboItemsInGroup.length > 1) {
        let res = await deleteComboItem(payload);
        if (res.data.status) {
          this.props.fetchAllCombos();
          toast.success("Combo Item deleted.");
        }
      } else {
        toast.warn(
          "Can't delete the last remaining combo item from the combo group. Please delete combo group instead."
        );
      }
    } catch (e) {
      toast.error("Could not delete Combo Item ");
    }
  };

  setExtraToEdit = async (extraToEditID) => {
    let extra = await fetchAddonByAddonId({ addon_id: extraToEditID });
    this.setState({ extraToEdit: extra.data.data }, () => {
      this.handleExtrasModal();
    });
  };

  setComboItemsToEdit = async (payload) => {
    let extra = await fetchComboItemById(payload);
    this.setState({ comboItemToEdit: extra.data.data }, () => {
      this.handleComboItemModal(payload.combo_group_id);
    });
  };

  setOptionsToEdit = async (optionToEditId) => {
    let extra = await fetchAddonByAddonId({ addon_id: optionToEditId });
    this.setState({ optionToEdit: extra.data.data }, () => {
      this.handleOptionsModal();
    });
  };

  handleCategoryChange = (e, setFieldValue) => {
    setFieldValue("category_id", e.target.value);
    this.setState({ selectedCategory: e.target.value });
  };

  handleAccordianChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
  };

  handleSubAccordianChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ subExpanded: panel });
    } else {
      this.setState({ subExpanded: false });
    }
  };

  handleUpseellingChange = (event) => {
    const personNames = [];

    const {
      target: { value },
    } = event;

    value.map((item) => {
      let i;
      if (item) {
        i = item.item_id ? item.item_id : item;
        if (!personNames.includes(i)) {
          personNames.push(i);
        } else {
          const index = personNames.indexOf(i);
          if (index > -1) {
            personNames.splice(index, 1);
          }
        }
      }
    });

    this.setState({ personName: [...personNames] });
  };

  fetchCategoryFIlters = async () => {
    try {
      let categoryFilters = await fetchCategoryDetails();
      if (categoryFilters) {
        let filters = categoryFilters.data.data;
        this.setState({ categoryFilters: filters });
      } else {
        toast.warn("Error fetching category filters");
      }
    } catch (e) {
      toast.warn(e);
    }
  };

  fetchUpsellItems = async (propId) => {
    try {
      let res = await fetchUpsellItems(propId);
      let responseUpsellItems = [];
      if (res.status && res.data.data) {
        this.setState({ names: res.data.data.item_response_bean_list });

        upSellItemMap = new Map();

        // setting the values

        this.state.names.map((item) => {
          /*   this.setState({
              personNames: item.item_identifier,
            }
            ); */
          upSellItemMap.set(item.item_id, item.item_identifier);

        });

        /* let data = res.data.data.sub_category_filters;
        for (let filter of data) {
          responseFilters.push(filter.sub_category_id);
        }
        this.setState({ selectedCategoryFilters: responseFilters }); */
      }

      if (!res.status) {
        toast.warn("Could not get item filters");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  handleCategoryFilter = (event, id) => {
    if (event.target.checked) {
      this.setState(
        {
          selectedCategoryFilters: [...this.state.selectedCategoryFilters, id],
        },
        () => { }
      );
    } else if (!event.target.checked) {
      let filtered = this.state.selectedCategoryFilters.filter(function (
        filterID
      ) {
        return filterID !== id;
      });
      this.setState({ selectedCategoryFilters: filtered }, () => { });
    }
  };

  fetchItemFilters = async (itemID) => {
    try {
      let res = await fetchItemFilters(itemID);
      let responseFilters = [];
      if (res.status && res.data.data) {
        let data = res.data.data.sub_category_filters;
        for (let filter of data) {
          responseFilters.push(filter.sub_category_id);
        }
        this.setState({ selectedCategoryFilters: responseFilters });
      }

      if (!res.status) {
        toast.warn("Could not get item filters");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  fetchItemTags = async () => {
    const property_id = this.props.propertyId
    try {
      let res = await fetchItemTags(property_id);
      let responseFilters = [];
      if (res.status && res.data.data) {
        this.setState({ tags: res.data.data });
      }

      if (!res.status) {
        toast.warn("Could not get item tags");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  resetAddonCategoryToEdit = () => {
    this.setState({ addonCategoryToEdit: null, categoryType: null });
  };

  resetComboGroupToEdit = () => {
    this.setState({ comboGroupToEdit: null });
  };

  editAddonCategory = (category, type) => {
    this.setState({ addonCategoryToEdit: category }, () => {
      this.handleAddonCategoryModal(type);
    });
  };

  editCombo = (comboGroup, type) => {
    this.setState({ comboGroupToEdit: comboGroup }, () => {
      this.handleComboGroupModal(type);
    });
  };

  handleViewExtraDialog = (status, extra) => {
    this.setState({
      openViewExtrasDialog: status,
      extraToShow: extra,
    });
  };

  handleDietryFilters = (event, id) => {
    if (event.target.checked) {
      this.setState(
        {
          selectedDietryFilters: [...this.state.selectedDietryFilters, id],
        },
        () => { }
      );
    } else if (!event.target.checked) {
      let filtered = this.state.selectedDietryFilters.filter(function (
        filterID
      ) {
        return filterID !== id;
      });
      this.setState({ selectedDietryFilters: filtered }, () => { });
    }
  };

  handleUpsellMenuChange = (e) => {
    this.setState({ upSellSelectedMenu: e.target.value });
    this.fetchCategoryNames(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  handleUppsellCategoryChange = (e) => {
    this.setState({ upSellSelectedCategory: e.target.value });
    this.fetchItems(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  handleUpsellItemChange = (e) => {
    this.setState({ upSellSelectedItem: e.target.value });
    //  this.fetchItems(e.target.value);
    // this.props.setSelectedMenu(event.target.value);
  };

  handleMultipleChange = (event) => {
    event.preventDefault();
    const {
      target: { value },
    } = event;
    let itemNames = [];

    value.map((item) => {
      let val = item?.item_identifier ? item?.item_identifier : item;
      if (!itemNames.includes(val)) {
        itemNames.push(val);
      } else {
        const index = itemNames.indexOf(val);
        if (index > -1) {
          // only splice array when item is found
          itemNames.splice(index, 1); // 2nd parameter means remove one item only
        }
        const ind = this.state.upSellSelectedItemNames.indexOf(val);
        let temp = this.state.upSellSelectedItemNames;
        if (index > -1) {
          // only splice array when item is found
          temp.splice(ind, 1); // 2nd parameter means remove one item only
          this.setState({ upSellSelectedItemNames: temp });
        }
      }
    });



    let itemIds = [];
    itemNames.map((item) => {
      const key = [...this.props.upSellItemMap.entries()]
        .filter(({ 1: v }) => v === item)
        .map(([k]) => k)[0];
      itemIds.push(key);
    });

    /*     const result = this.state.upSellItemNames.filter((item) => itemNames.indexOf(item.item_identifier) > -1);
    
    
        const results = this.state.upSellItemNames.filter((item) => this.props.itemToEdit.upsell_item_ids.indexOf(item.item_id) > -1);
    
    
    
        let final =[...result, ...results];
    
    
        let itemIds = [...this.props.itemToEdit.upsell_item_ids];
        final.map(item => {
          let val = item?.item_id;
          if (!itemIds.includes(val)) {
            itemIds.push(val);
          } else {
            const index = itemIds.indexOf(val);
            if (index > -1) { // only splice array when item is found
              itemIds.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
    
        });
    
    
        
    
        let itmNames = [...this.state.upSellSelectedItemNames, ...itemNames];
    
        // removing duplicate
        let uniqueItemNames = [...new Set(itmNames)];
    
        let itmIds = [...itemIds];
    
        
    
        // removing duplicate
        let uniqueIds = [...new Set(itmIds)]; */

    let itmNames = [...this.state.upSellSelectedItemNames, ...itemNames];

    // removing duplicate
    let uniqueItemNames = [...new Set(itmNames)];
    this.setState({
      upSellSelectedItemNames: uniqueItemNames,
      upSellItemIds: itemIds,
    });
  };

  handleDelete = (chipToDelete) => () => {
    let selChips = this.state.upSellSelectedItemNames;
    let s = selChips.filter((chip) => chip !== chipToDelete);

    let itemIds = [];
    s.map((item) => {
      const key = [...this.props.upSellItemMap.entries()]
        .filter(({ 1: v }) => v === item)
        .map(([k]) => k)[0];
      itemIds.push(key);
    });

    this.setState({
      upSellSelectedItemNames: s,
      upSellItemIds: itemIds,
    });
  };

  handleChipClick = (e) => {
    e.preventDefault();
  };

  selectHandleOnOpen = (e) => {
    e.preventDefault();
    this.setState({
      canOpen: !this.state.canOpen,
    });
  };

  fetchItems = async (value) => {
    try {
      let items = await fetchItem(value);


      const filteredItems = items.data?.data?.item_response_bean_list?.filter(
        (obj) => obj.item_id !== this.state.itemToEdit?.item_id
      );

      if (
        items.data.status == false ||
        items.data.message == "No details found."
      ) {
        this.setState({
          isLoading: false,
          upSellItemNames: null,
          upSellSelectedItem: "",
        });
        return;
      } else {
        /*    let sorted_categories =
             categories.data.data.category_response_bean_list.sort(
               (a, b) => a.item_sort_order - b.item_sort_order
             ); */

        let itemNames = [];
        filteredItems.map((item) => {
          //  upSellItemMap.set(item.item_id, item.item_identifier);
          itemNames.push(item.item_identifier);
        });

        this.setState({
          upSellItemNames: filteredItems,
          //    upSellSelectedItem: items.data.data.item_response_bean_list[0].item_id,
        });



        // this.props.setCategoryNames(sorted_categories);
        //  this.fetchItems();
      }
    } catch (e) {
    }
  };

  fetchCategoryNames = async (selectedUpsellMenu) => {
    try {
      this.setState({ isLoading: true });
      let categories = await fetchCategory(selectedUpsellMenu);

      if (
        categories.data.status == false ||
        categories.data.message == "No details found."
      ) {
        this.setState({
          isLoading: false,
          upSellCategoryNames: null,
          upSellSelectedCategory: "",
        });
        return;
      } else {
        /*    let sorted_categories =
             categories.data.data.category_response_bean_list.sort(
               (a, b) => a.item_sort_order - b.item_sort_order
             ); */
        this.setState({
          upSellCategoryNames: categories.data.data.category_response_bean_list,
          /*   upSellSelectedCategory: categories.data.data.category_response_bean_list[0].category_id */
        });
        // this.props.setCategoryNames(sorted_categories);
        //  this.fetchItems();
      }
    } catch (e) {
    }
  };

  /*   fetchComboStatus = async (setFieldValue) => {
      try {
  
  
        let combo = await fetchComboStatus(value);
  
  
        if ( combo.data.status == false ) {
         
        } else {
          
    
        }
  
  
      } catch (e) {
      }
    }; */

  charOnChange = (e) => {
    this.setState({
      count: e.target.value.length,
    });
  };

  handleVat = (event) => {
    this.setState({ selectedVatValue: event.target.value });
  };

  showBarCodeTextField = (bool) => {
    if(!bool){
      if (!this.state.showBarCardField) {
        this.setState({ showBarCardField: true, isEditable: true });
      } else {
        this.setState({ showBarCardField: false, });
      }
    }else{
      this.setState({ showBarCardField: false, isEditable: false });
    }
  }

  handleEditClick = () => {
    this.setState({ isEditable: true });
  }
  handleBarcodeClick=()=>{
    this.setState({isEditable:false})
  }
  handleDeleteClick = async () => {
    const {itemId}= this.state
    this.setState({ isEditable: false, showBarCardField: false,submitting:true });
    try {
      let res = await deleteBarCode(itemId);
      if (res.data.status) {
        this.props.fetchMenuNames();
        this.props.handleQuickAdd("Close");
        toast.success("BarCode deleted.");
        this.setState({submitting:false})
      }
    } catch (e) {
      toast.error("Could not delete addon");
      this.setState({submitting:false})

    }

  }
  handleDeleteBarCodeClick =(Id)=>{

    this.setState({ barCodeDialog:true ,dialogOpen: true, itemId:Id });

  }


  handleDialogOpen = () => {
    this.setState({ dialogOpen: true, });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  setInventoryValue = (setFieldValue) => {
   setFieldValue("is_inventory", true);
   this.handleDialogClose();
  };

  render() {
    const {
      submitting,
      itemToEdit,
      addonCategoryToEdit,
      comboGroupToEdit,
      optionToEdit,
      comboItemToEdit,
      initialValues,
      openExtrasModel,
      openOptionsModel,
      openAddonCategoryModel,
      openCombosModel,
      openComboGroupItemsModel,
      extraToEdit,
      selectedCategory,
      expanded,
      subExpanded,
      categoryFilters,
      categoryType,
      selectedCategoryFilters,
      selectedDietryFilters,
      selectedAddonCategory,
      selectedComboGroup,
      openViewExtrasDialog,
      extraToShow,
      vatValues,
      tags,
      isTaxInclusive,
      showBarCardField,
      inventoryPlan,
      isEditable,
      defaultItem,
      dialogOpen,barCodeDialog,
    } = this.state;
    const { is_item_level, is_vat, item_vat_details } = this.props.settings?.vat_details || {};
    return (
      <>
        <Grid container spacing={3} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={"title"}>
              {this.props.title}

              {this.props.showClose && (
                <span style={{ float: "right" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => this.props.handleQuickAdd("Close")}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema.itemSchema}
              onSubmit={(values, { resetForm }) => {
                this.handleItem(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} md={12}>
                      <FormControl>
                        <InputLabel id="select-category">Category</InputLabel>
                        <Select
                          labelId="select-category"
                          id="category_id"
                          value={selectedCategory}
                          defaultValue={selectedCategory}
                          input={
                            <OutlinedInput id="category_id" label="Category" />
                          }
                          name="category_id"
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          className="formControl"
                          disabled={!this.props.categoryNames || this.props.settings?.is_eposnow_enabled}
                          onChange={(e, val) =>
                            this.handleCategoryChange(e, setFieldValue)
                          }
                        >
                          {this.props.categoryNames &&
                            this.props.categoryNames.map((item) => {
                              return (
                                <MenuItem
                                  key={item.category_id}
                                  value={item.category_id}
                                >
                                  {item.category_identifier}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText error>
                          {touched.category_id && errors.category_id
                            ? errors.category_id
                            : null}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          touched.item_identifier && errors.item_identifier
                            ? true
                            : false
                        }
                        margin="normal"
                        type="text"
                        id="item_identifier"
                        label="Name"
                        name="item_identifier"
                        autoComplete="item_identifier"
                        autoFocus
                        fullWidth
                        size="small"
                        variant="standard"
                        disabled={this.props.settings?.is_eposnow_enabled}
                        onBlur={handleBlur}
                        style={{ marginTop: "37px" }}
                        value={values.item_identifier}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            itemIdentifier: e.target.value.length,
                          });
                        }}
                        aria-describedby="component-error-text"
                        helperText={
                          touched.item_identifier && errors.item_identifier
                            ? errors.item_identifier
                            : null
                        }
                      />
                      <span style={{ color: "red" }}>
                        {40 - this.state.itemIdentifier}
                      </span>{" "}
                      / 40
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          touched.quantity && errors.quantity ? true : false
                        }
                        margin="normal"
                        fullWidth
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        id="quantity"
                        label="Quantity"
                        name="quantity"
                        
                        autoComplete="quantity"
                        size="small"
                        variant="standard"
                        onBlur={handleBlur}
                        value={values.quantity}
                        onChange={(e) => {
                          setFieldValue("quantity", e.target.value);
                          if (e.target.value > 0) {
                            setFieldValue("item_status", "AVAILABLE");
                          }
                          if (e.target.value == 0) {
                            setFieldValue("item_status", "SOLD OUT");
                          }
                        }}
                        disabled={itemToEdit?.is_inventory   || this.props.settings?.is_eposnow_enabled }
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "1",
                          },
                        }}
                        helperText={
                          touched.quantity && errors.quantity
                            ? errors.quantity
                            : null
                        }
                      />
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          touched.item_cost && errors.item_cost ? true : false
                        }
                        margin="normal"
                        fullWidth
                        type="text"
                        id="item_cost"
                        label={(is_vat && is_item_level && item_vat_details.length > 0) ? (
                          <Typography>
                            {isTaxInclusive ? "Price (Including Taxes)" : "Price (Excluding Taxes)"}</Typography>) : "Price"}
                        name="item_cost"
                        autoComplete="item_cost"
                        size="small"
                        variant="standard"
                        disabled={this.props.settings?.is_eposnow_enabled}
                        onBlur={handleBlur}
                        value={values.item_cost}
                        onInput={(event) => {
                          if (!isNaN(event.target.value)) {
                            event.target.value = !isNaN(event.target.value)
                              ? event.target.value
                              : "";
                          } else {
                            event.target.value = "";
                          }
                        }}
                        onChange={handleChange}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "any",
                          },
                        }}
                        helperText={
                          touched.item_cost && errors.item_cost
                            ? errors.item_cost
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          touched.item_sort_order && errors.item_sort_order
                            ? true
                            : false
                        }
                        margin="normal"
                        fullWidth
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        id="item_sort_order"
                        label="Sort order"
                        name="item_sort_order"
                        autoComplete="item_sort_order"
                        size="small"
                        variant="standard"
                        onBlur={handleBlur}
                        disabled={this.props.settings?.is_eposnow_enabled}
                        value={values.item_sort_order}
                        onChange={handleChange}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "1",
                          },
                        }}
                        helperText={
                          touched.item_sort_order && errors.item_sort_order
                            ? errors.item_sort_order
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={
                          touched.item_preparation_time && errors.item_preparation_time
                            ? true
                            : false
                        }
                        margin="normal"
                        fullWidth
                        type="number"
                        disabled={this.props.settings?.is_eposnow_enabled}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        id="item_preparation_time"
                        label="Preparation Time(In Minutes)"
                        name="item_preparation_time"
                        autoComplete="item_preparation_time"
                        size="small"
                        variant="standard"
                        onBlur={handleBlur}
                        value={values.item_preparation_time}
                        onChange={handleChange}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "1",
                          },
                        }}
                        helperText={
                          touched.item_preparation_time && errors.item_preparation_time
                            ? errors.item_preparation_time
                            : null
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      padding="20px"
                    >
                      {(is_vat && is_item_level && item_vat_details.length > 0) && (
                        <Grid item xs={12} md={5}>
                          <FormControl fullWidth sx={{ mr: 2 }}>
                            <InputLabel id="select-vat">
                              Select Vat
                            </InputLabel>
                            <Select fullWidth
                              labelId="select-vat"
                              id="select-vat"
                              value={values.vat || defaultItem}
                              defaultValue={itemToEdit?.vat_percentage || defaultItem}
                              input={
                                <OutlinedInput
                                  fullWidth
                                  id="select-va"
                                  label="Select Vat"
                                />
                              }
                              name="vat"
                              className="formControl"
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              disabled={!this.state.vatValues ||   this.props.settings?.is_eposnow_enabled }
                              onChange={handleChange}
                            >
                              {vatValues &&
                                vatValues.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.value}% ({option.description})
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>)}
                      <Grid item xs={12} md={5}>
                        <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                          <InputLabel id="select-tag">
                            Select Tags
                          </InputLabel>
                          <Select fullWidth
                            labelId="select-tag"
                            id="select-tag"
                            value={values.tag_ids || ''}
                            defaultValue={itemToEdit?.tag_ids?.[0] ?? ''}
                            input={
                              <OutlinedInput
                                fullWidth
                                id="select-tag"
                                label="select-tag"
                              />
                            }
                            name="tag_ids"
                            className="formControl"
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            disabled={!this.state.tags}
                            onChange={handleChange}
                          >
                            {tags &&
                              tags.map(option => (
                                <MenuItem key={option.tag_id} value={option.tag_id}>
                                  {option.tag_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.is_item_label_print}
                            onChange={handleChange}
                            disabled={this.props.settings?.is_eposnow_enabled}
                            name="is_item_label_print"
                            color="primary"
                            inputProps={{
                              "aria-label": "is_item_label_print checkbox",
                            }}
                          />
                        }
                        labelPlacement="start"
                        label="Print Label"
                      />
                    </Grid>
                    {(inventoryPlan?.subscription_status == "ACTIVE" || inventoryPlan?.subscription_status == "CANCELLED") 
                      && !itemToEdit?.is_combo && !values?.is_combo && !values?.is_negative_sale && !itemToEdit?.is_negative_sale &&
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={itemToEdit?.is_inventory || this.props.settings?.is_eposnow_enabled}
                              checked={values.is_inventory}
                              onChange={(event) => {
                                if (event.target?.checked) {
                                  this.handleDialogOpen();
                                } else {
                                  setFieldValue("is_inventory", event.target?.checked);
                                }
                              }
                              }
                              name="is_inventory"
                              color="primary"
                              inputProps={{
                                "aria-label": "is_inventory checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Is Inventory"
                        />
                      </Grid>}

                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.mobile_availability}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={handleChange}
                              name="mobile_availability"
                              color="primary"
                              inputProps={{
                                "aria-label": "mobile_availability checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Enable Mobile "
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.kiosk_availability}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={handleChange}
                              name="kiosk_availability"
                              color="primary"
                              inputProps={{
                                "aria-label": "kiosk_availability checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Enable Kiosk"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.epos_availability}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={handleChange}
                              name="epos_availability"
                              color="primary"
                              inputProps={{
                                "aria-label": "epos_availability checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Enable Epos"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.web_availability}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={handleChange}
                              name="web_availability"
                              color="primary"
                              inputProps={{
                                "aria-label": "web_availability checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Enable web"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.business_app_availability}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={handleChange}
                              name="business_app_availability"
                              color="primary"
                              inputProps={{
                                "aria-label":
                                  "business_app_availability checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Enable Business App"
                        />
                      </Grid>
                      {!(itemToEdit?.is_inventory || values?.is_inventory) &&
                        < Grid item xs={12} md={6} lg={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={this.props.settings?.is_eposnow_enabled}
                                checked={!!values.is_negative_sale}
                                onChange={(e) => {
                                  setFieldValue('is_negative_sale', e.target.checked);
                                  if (e.target.checked) {
                                    setFieldValue('item_status', "AVAILABLE")
                                  }
                                }
                                } // Properly update the form state
                                name="is_negative_sale"
                                color="primary"
                                inputProps={{
                                  "aria-label":
                                    "is_negative_sale checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Make Unlimited"
                          />
                        </Grid>
  }
                    </Grid>
                    {/*                <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          touched.total_selectable_extras &&
                            errors.total_selectable_extras
                            ? true
                            : false
                        }
                        margin="normal"
                        type="number"
                        fullWidth
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                        }}
                        id="total_selectable_extras"
                        label="Total selectable extras"
                        name="total_selectable_extras"
                        autoComplete="total_selectable_extras"
                        size="small"
                        variant="standard"
                        onBlur={handleBlur}
                        value={values.total_selectable_extras}
                        onChange={handleChange}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            step: "1",
                          },
                        }}
                        helperText={
                          touched.total_selectable_extras &&
                            errors.total_selectable_extras
                            ? errors.total_selectable_extras
                            : null
                        }
                      />
                    </Grid> */}

                    <Grid item xs={12} md={12}>
                      <TextField
                        error={
                          touched.item_description && errors.item_description
                            ? true
                            : false
                        }
                        margin="normal"
                        type="text"
                        fullWidth
                        multiline
                        disabled={this.props.settings?.is_eposnow_enabled}
                        rows={4}
                        id="item_description"
                        label="Description"
                        name="item_description"
                        autoComplete="item_description"
                        size="small"
                        variant="standard"
                        onBlur={(e) => {
                          const trimmedValue = e.target.value.replace(/\s+/g, ' ').trim(); // Replace multiple spaces with a single space and trim leading and trailing spaces
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: trimmedValue,
                            },
                          });
                          this.setState({
                            descCount: trimmedValue.length,
                          });
                        }}
                        value={values.item_description}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({
                            descCount: e.target.value.length,
                          });
                        }}
                        aria-describedby="component-error-text"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        helperText={
                          touched.item_description && errors.item_description
                            ? errors.item_description
                            : null
                        }
                      />
                      <span style={{ color: "red" }}>
                        {300 - this.state.descCount}
                      </span>{" "}
                      / 300
                    </Grid>
                  </Grid>

                  {itemToEdit && !itemToEdit?.is_inventory && !values?.is_inventory && (
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={4} md={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.is_combo}
                              disabled={this.props.settings?.is_eposnow_enabled}
                              onChange={async (e) => {
                                const is_combo = e.target.checked;
                                try {
                                  let combo = await fetchComboStatus(
                                    this.state.itemToEdit?.item_id
                                  );
                                  if (combo.data?.status) {
                                    if (
                                      combo.data?.data &&
                                      combo.data.data?.length > 0
                                    ) {
                                      toast.warn(
                                        "Item is already linked to  " +
                                        combo.data.data
                                      );
                                    } else {
                                      setFieldValue("is_combo", is_combo);
                                      this.setState({ is_combo });
                                    }
                                  } else {
                                  }
                                } catch (e) {
                                }
                              }}
                              name="is_combo"
                              color="primary"
                              inputProps={{
                                "aria-label": "is_combo checkbox",
                              }}
                            />
                          }
                          labelPlacement="start"
                          label="Is combo"
                        />
                      </Grid>
                    </Grid>
                  )}

                  {itemToEdit && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        expanded={expanded === "manage-upselling"}
                        onChange={this.handleAccordianChange(
                          "manage-upselling"
                        )}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Manage Upselling</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="row" spacing={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                              sx={{ mx: "auto" }}
                            >
                              <Grid item xs={6} md={6}>
                                <FormControl
                                  fullWidth
                                  error={
                                    touched.menu_id && errors.menu_id
                                      ? true
                                      : false
                                  }
                                >
                                  <InputLabel id="menu_id">Menu</InputLabel>
                                  <Select
                                    labelId="menuUpsell_id"
                                    id="menuUpsell_id"
                                    name="menuUpsell_id"
                                    value={this.state.upSellSelectedMenu}
                                    defaultValue={this.state.upSellSelectedMenu}
                                    input={
                                      <OutlinedInput
                                        id="menuUpsell_id"
                                        label="Menu"
                                      />
                                    }
                                    className="formControl"
                                    disabled={!this.props.upSellMenuNames}
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    onChange={(e, val) =>
                                      this.handleUpsellMenuChange(e)
                                    }
                                  >
                                    {this.props.upSellMenuNames &&
                                      this.props.upSellMenuNames.map((item) => {
                                        return (
                                          <MenuItem
                                            key={item.menu_id}
                                            value={item.menu_id}
                                          >
                                            <span>{item.menu_identifier}</span>
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText error>
                                    {touched.menu_id && errors.menu_id
                                      ? errors.menu_id
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="select-category">
                                    Category
                                  </InputLabel>
                                  <Select
                                    labelId="select-category"
                                    id="upSell_category_id"
                                    value={this.state.upSellSelectedCategory}
                                    defaultValue={
                                      this.state.upSellSelectedCategory
                                    }
                                    input={
                                      <OutlinedInput
                                        id="upSell_category_id"
                                        label="Category"
                                      />
                                    }
                                    name="upSell_category_id"
                                    className="formControl"
                                    MenuProps={{
                                      disableScrollLock: true,
                                    }}
                                    disabled={!this.state.upSellCategoryNames}
                                    onChange={(e, val) =>
                                      this.handleUppsellCategoryChange(e)
                                    }
                                  >
                                    {this.state.upSellCategoryNames &&
                                      this.state.upSellCategoryNames.map(
                                        (item) => {
                                          return (
                                            <MenuItem
                                              key={item.category_id}
                                              value={item.category_id}
                                            >
                                              {item.category_identifier}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                  <FormHelperText error>
                                    {touched.category_id && errors.category_id
                                      ? errors.category_id
                                      : null}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                {/*  <FormControl>
                                  <InputLabel id="select-category">Upsell Items</InputLabel>
                                  <Select
                                    labelId="select-category"
                                    id="upSell_item_id"
                                    value={this.state.upSellSelectedItem}
                                    defaultValue={this.state.upSellSelectedItem}
                                    name="upSell_item_id"
                                    fullWidth
                                    className="formControl"
                                    disabled={!this.state.upSellItemNames}
                                    onChange={(e) =>
                                      this.handleUpsellItemChange(e)
                                    }
                                  >
                                    {this.state.upSellItemNames &&
                                      this.state.upSellItemNames.map((item) => {
                                        return (
                                          <MenuItem
                                            key={item.item_id}
                                            value={item.item_id}
                                          >
                                            {item.item_identifier}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText error>
                                    {touched.upSell_item_id && errors.upSell_item_id
                                      ? errors.upSell_item_id
                                      : null}
                                  </FormHelperText>
                                </FormControl> */}

                                <FormControl sx={{ m: 1, width: 1 }}>
                                  <InputLabel id="demo-multiple-chip-label">
                                    Upsell Items
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={this.state.upSellSelectedItemNames}
                                    onChange={this.handleMultipleChange}
                                    disabled={!this.state.upSellItemNames}
                                    onClick={this.selectHandleOnOpen}
                                    open={this.state.canOpen}
                                    input={
                                      <OutlinedInput
                                        id="select-multiple-chip"
                                        label="Upsell Items"
                                      />
                                    }
                                    renderValue={(selected) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 0.5,
                                        }}
                                      >
                                        {selected.map((value) => (
                                          <Chip
                                            clickable={true}
                                            key={value}
                                            label={value}
                                            style={{ margin: "5px" }}
                                            onDelete={this.handleDelete(value)}
                                          />
                                        ))}
                                      </Box>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    {this.state.upSellItemNames &&
                                      this.state.upSellItemNames.map((name) => (
                                        <MenuItem
                                          key={name.item_id}
                                          value={name}
                                          style={getStyles(
                                            name.item_identifier,
                                            this.state.upSellSelectedItemNames,
                                            theme
                                          )}
                                        >
                                          {name.item_identifier}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/*                      <Grid item xs={12} md={12}>

                                <Grid container direction="row">
                                  <FormControl sx={{ m: 1, width: 1 }}>
                                    <InputLabel id="demo-multiple-checkbox-label">Upsell Items</InputLabel>
                                    <Select
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      name="upselItems"
                                      value={this.state.personName}
                                      onChange={this.handleUpseellingChange}
                                      input={<OutlinedInput label="Upsell Items" />}
                                      renderValue={(selected) => selected.map((name) => name ? (this.props.upSellItemMap.get(name) + ",") : "")}
                                      MenuProps={MenuProps}
                                    >
                                      {this.props.personNames.map((name) => (
                                        <MenuItem key={name.item_id} value={name}>
                                          <Checkbox checked={this.state.personName.indexOf(name.item_id) > -1} />
                                          <ListItemText primary={name.item_identifier} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid> */}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  <Grid item style={{ paddingTop: 5 }}>
                    <Accordion
                      expanded={expanded === "dietary_info"}
                      onChange={this.handleAccordianChange("dietary_info")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Dietary Info</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          direction="row"
                          justify="space-around"
                          spacing={2}
                        >
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              error={
                                touched.dietary_info?.calorie &&
                                  errors.dietary_info?.calorie
                                  ? true
                                  : false
                              }
                              margin="normal"
                              type="text"
                              id="calorie"
                              label="Calorie"
                              name="dietary_info.calorie"
                              autoComplete="calorie"
                              size="small"
                              fullWidth
                              variant="standard"
                              onBlur={handleBlur}
                              value={
                                values["dietary_info"]
                                  ? values["dietary_info"]["calorie"]
                                  : values.calorie
                              }
                              onChange={handleChange}
                              aria-describedby="component-error-text"
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  step: "any",
                                },
                              }}
                              helperText={
                                touched.dietary_info?.calorie &&
                                  errors.dietary_info?.calorie
                                  ? errors.dietary_info?.calorie
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              error={
                                touched.dietary_info?.protein &&
                                  errors.dietary_info?.protein
                                  ? true
                                  : false
                              }
                              margin="normal"
                              type="text"
                              id="protein"
                              label="Protein"
                              name="dietary_info.protein"
                              fullWidth
                              autoComplete="protein"
                              size="small"
                              variant="standard"
                              onBlur={handleBlur}
                              value={
                                values["dietary_info"]
                                  ? values["dietary_info"]["protein"]
                                  : values.protein
                              }
                              onChange={handleChange}
                              aria-describedby="component-error-text"
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  step: "1",
                                },
                              }}
                              helperText={
                                touched.dietary_info?.protein &&
                                  errors.dietary_info?.protein
                                  ? errors.dietary_info?.protein
                                  : null
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={4}>
                            <TextField
                              error={
                                touched.dietary_info?.transFat &&
                                  errors.dietary_info?.transFat
                                  ? true
                                  : false
                              }
                              margin="normal"
                              type="text"
                              id="transFat"
                              label="Trans Fat"
                              name="dietary_info.transFat"
                              fullWidth
                              autoComplete="transFat"
                              size="small"
                              variant="standard"
                              onBlur={handleBlur}
                              value={
                                values["dietary_info"]
                                  ? values["dietary_info"]["transFat"]
                                  : values.transFat
                              }
                              onChange={handleChange}
                              aria-describedby="component-error-text"
                              helperText={
                                touched.dietary_info?.transFat &&
                                  errors.dietary_info?.transFat
                                  ? errors.dietary_info?.transFat
                                  : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item style={{ paddingTop: 5 }}>
                    <Accordion
                      disabled={this.props.settings?.is_eposnow_enabled}
                      expanded={expanded === "status"}
                      onChange={this.handleAccordianChange("status")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Status</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="item_status"
                            name="item_status"
                            value={values.item_status || "AVAILABLE"}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="AVAILABLE"
                              control={
                                <Radio
                                  checked={values.item_status === "AVAILABLE"}
                                />
                              }
                              label="AVAILABLE"
                            />
                            <FormControlLabel
                              value="SOLD OUT FOR TODAY"
                              control={
                                <Radio
                                  checked={
                                    values.item_status === "SOLD OUT FOR TODAY"
                                  }
                                />
                              }
                              label="SOLD OUT FOR TODAY"
                            />
                            <FormControlLabel
                              value="AVAILABLE FOR TOMORROW"
                              control={
                                <Radio
                                  checked={
                                    values.item_status ===
                                    "AVAILABLE FOR TOMORROW"
                                  }
                                />
                              }
                              label="AVAILABLE FOR TOMORROW"
                            />
                            <FormControlLabel
                              value="SOLD OUT"
                              control={
                                <Radio
                                  checked={values.item_status === "SOLD OUT"}
                                />
                              }
                              label="SOLD OUT"
                            />
                          </RadioGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {/*              <Grid item style={{ paddingTop: 5 }}>
                    <Accordion
                      expanded={expanded === "availablity"}
                      onChange={this.handleAccordianChange("availablity")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Availablity</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.is_take_away_active === true}
                                onChange={handleChange}
                                name="is_take_away_active"
                                color="primary"
                                inputProps={{
                                  "aria-label": "take away checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Take away"
                          /> */}
                  {/* <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  (values.is_delivery_to_park_active === true) 
                                }
                                onChange={handleChange}
                                name="is_delivery_to_park_active"
                                color="primary"
                                inputProps={{
                                  "aria-label": "park  checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Delivery to park"
                          /> */}

                  {/*                    <FormControlLabel
                            control={
                              <Switch
                                checked={values.is_delivery_active === true}
                                onChange={handleChange}
                                name="is_delivery_active"
                                color="primary"
                                inputProps={{
                                  "aria-label": "tdelivery checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Delivery"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.is_dine_in_active === true}
                                onChange={handleChange}
                                name="is_dine_in_active"
                                color="primary"
                                inputProps={{
                                  "aria-label": "t checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Dine in"
                          /> */}
                  {/*   <FormControlLabel
                            control={
                              <Switch
                                // checked={values.is_kiosk_active === true}
                                checked={true}
                                onChange={handleChange}
                                name="is_kiosk_active"
                                color="primary"
                                inputProps={{
                                  "aria-label": "park  checkbox",
                                }}
                              />
                            }
                            labelPlacement="start"
                            label="Kiosk"
                          /> */}
                  {/*    </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid> */}
                  {/*   <Grid item style={{ paddingTop: 5 }}>
                    <Accordion
                      expanded={expanded === "dietary-filters"}
                      onChange={this.handleAccordianChange("dietary-filters")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Dietary Filters</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset"> */}
                  {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Gluten free"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Gluten free")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Gluten free"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Halal"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Halal")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Halal"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Kosher"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Kosher")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Kosher"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Organic"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Organic")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Organic"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Vegan"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Vegan")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Vegan"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDietryFilters.includes(
                                  "Vegetarian"
                                )}
                                onChange={(e) =>
                                  this.handleDietryFilters(e, "Vegetarian")
                                }
                                name="selectedDietryFilters"
                                color="primary"
                              />
                            }
                            label={"Vegetarian"}
                          /> */}
                  {/*    <RadioGroup
                            aria-label="dietaryfilters"
                            name="dietaryfilters"
                            value={values.dietaryfilters}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="Gluten free"
                              control={
                                <Radio
                                  checked={
                                    values.dietaryfilters === "Gluten free"
                                  }
                                />
                              }
                              label="Gluten free"
                            />
                            <FormControlLabel
                              value="Halal"
                              control={
                                <Radio
                                  checked={values.dietaryfilters === "Halal"}
                                />
                              }
                              label="Halal"
                            />
                            <FormControlLabel
                              value="Kosher"
                              control={
                                <Radio
                                  checked={values.dietaryfilters === "Kosher"}
                                />
                              }
                              label="Kosher"
                            />
                            <FormControlLabel
                              value="Organic"
                              control={
                                <Radio
                                  checked={values.dietaryfilters === "Organic"}
                                />
                              }
                              label="Organic"
                            />
                            <FormControlLabel
                              value="Vegan"
                              control={
                                <Radio
                                  checked={values.dietaryfilters === "Vegan"}
                                />
                              }
                              label="Vegan"
                            />
                            <FormControlLabel
                              value="Vegetarian"
                              control={
                                <Radio
                                  checked={
                                    values.dietaryfilters === "Vegetarian"
                                  }
                                />
                              }
                              label="Vegetarian"
                            />
                          </RadioGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Grid> */}

                  {itemToEdit && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        expanded={expanded === "categories"}
                        onChange={this.handleAccordianChange("categories")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Category Filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="column">
                            {categoryFilters ? (
                              categoryFilters.map((filter) => {
                                return (
                                  <Grid
                                    item
                                    style={{ paddingTop: 5 }}
                                    key={filter.category_id}
                                  >
                                    <Accordion
                                      expanded={
                                        subExpanded === filter.category_id
                                      }
                                      onChange={this.handleSubAccordianChange(
                                        filter.category_id
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography>
                                          {filter.category_label}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container direction="column">
                                          {filter.sub_categories ? (
                                            filter.sub_categories.map(
                                              (subcategory) => {
                                                return (
                                                  <Grid
                                                    item
                                                    key={
                                                      subcategory.sub_category_id
                                                    }
                                                    style={{ paddingTop: 2 }}
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={selectedCategoryFilters.includes(
                                                            subcategory.sub_category_id
                                                          )}
                                                          onChange={(e) =>
                                                            this.handleCategoryFilter(
                                                              e,
                                                              subcategory.sub_category_id
                                                            )
                                                          }
                                                          name="selectedCategoryFilters"
                                                          color="primary"
                                                        />
                                                      }
                                                      label={
                                                        subcategory.sub_category_label
                                                      }
                                                    />
                                                  </Grid>
                                                );
                                              }
                                            )
                                          ) : (
                                            <Alert severity="warning">
                                              No Sub-category found
                                            </Alert>
                                          )}
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                );
                              })
                            ) : (
                              <Alert severity="warning">No filters found</Alert>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  {itemToEdit && !this.state.is_combo && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        disabled={this.props.settings?.is_eposnow_enabled}
                        expanded={expanded === "manage-extras"}
                        onChange={this.handleAccordianChange("manage-extras")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Manage Extras</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="row" spacing={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs={12} md={12}>
                                <Grid container direction="row">
                                  <Button
                                    style={{ marginLeft: "14px" }}
                                    onClick={() =>
                                      this.handleAddonCategoryModal("Extras")
                                    }
                                  >
                                    Add Extra Categories
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Grid container direction="column">
                                {this.props.categoriesForExtras ? (
                                  this.props.categoriesForExtras.map(
                                    (category) => {
                                      return (
                                        <Grid
                                          key={category.addon_category_id}
                                          item
                                          style={{ paddingTop: 5 }}
                                        >
                                          <Accordion
                                            key={category.addon_category_id}
                                            expanded={
                                              subExpanded ===
                                              category.addon_category_id
                                            }
                                            onChange={this.handleSubAccordianChange(
                                              category.addon_category_id
                                            )}
                                          >
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                <Grid item xs={6} md={6}>
                                                  <ButtonGroup
                                                    size="small"
                                                    orientation="horizontal"
                                                    color="primary"
                                                    variant="text"
                                                    aria-label="horizontal outlined primary button group"
                                                  >
                                                    <Tooltip
                                                      title="Delete addon catagory"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          if (
                                                            this.props
                                                              .currentItemExtras
                                                          ) {
                                                            let addonExtras =
                                                              this.props.currentItemExtras.filter(
                                                                (addon) => {
                                                                  return (
                                                                    addon.addon_category_id ==
                                                                    category.addon_category_id
                                                                  );
                                                                }
                                                              );
                                                            if (
                                                              addonExtras.length >
                                                              0
                                                            ) {
                                                              toast.warn(
                                                                "Please remove the addons inside this category first"
                                                              );
                                                              return;
                                                            }
                                                          }
                                                          deleteConfirm({
                                                            name: category.addon_category_identifier,
                                                            id: category.addon_category_id,
                                                            message:
                                                              "Addon Category deleted.",
                                                            deleteFunc:
                                                              deleteAddonCategory,
                                                            func: [
                                                              this.props
                                                                .fetchAllAddons,
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Edit addon catagory"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          this.editAddonCategory(
                                                            category,
                                                            "Extras"
                                                          );
                                                        }}
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Add addon"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          this.handleExtrasModal(
                                                            category.addon_category_id
                                                          );
                                                        }}
                                                      >
                                                        <AddBoxIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                  <Typography
                                                    className="heading"
                                                    style={{
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {
                                                      category.addon_category_identifier
                                                    }
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                direction="column"
                                              >
                                                {this.props
                                                  .currentItemExtras ? (
                                                  this.props.currentItemExtras.map(
                                                    (value) => {
                                                      if (
                                                        value.addon_category_id ===
                                                        category.addon_category_id
                                                      ) {
                                                        const labelId = `checkbox-list-label-${value.addon_id}`;

                                                        return (
                                                          <React.Fragment
                                                            key={value.addon_id}
                                                          >
                                                            <Paper
                                                              className="paper"
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              <Grid
                                                                container
                                                                alignItems="center"
                                                                spacing={1}
                                                              >
                                                                <Grid
                                                                  item
                                                                  xs={2}
                                                                  md={2}
                                                                  lg={1}
                                                                >
                                                                  <Avatar
                                                                    sx={{
                                                                      width: 32,
                                                                      height: 32,
                                                                      fontSize:
                                                                        "1rem",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value.addon_sort_order
                                                                    }
                                                                  </Avatar>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={2}
                                                                  md={2}
                                                                  lg={1}
                                                                >
                                                                  <img
                                                                    src={
                                                                      value.addon_image
                                                                        ? value.addon_image
                                                                        : null
                                                                    }
                                                                    style={{
                                                                      height: 50,
                                                                      width: 50,
                                                                    }}
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={8}
                                                                  md={4}
                                                                  lg={6}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      wordBreak:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value.addon_identifier
                                                                    }
                                                                  </span>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={6}
                                                                  md={2}
                                                                  lg={2}
                                                                >
                                                                  <Chip
                                                                    label={
                                                                      getCurrencySymbol(
                                                                        localStorage.getItem(
                                                                          "currency_code"
                                                                        )
                                                                      ) +
                                                                      value.addon_cost
                                                                    }
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={6}
                                                                  md={2}
                                                                  lg={2}
                                                                  container
                                                                  justifyContent="flex-end"
                                                                >
                                                                  <ButtonGroup
                                                                    size="small"
                                                                    orientation="horizontal"
                                                                    color="primary"
                                                                    variant="text"
                                                                  >
                                                                    <Tooltip
                                                                      title="Delete Addon"
                                                                      placement="top"
                                                                    >
                                                                      <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          Swal.fire(
                                                                            {
                                                                              title: `Delete ${value.addon_identifier}`,
                                                                              text: "You won't be able to revert this!",
                                                                              icon: "warning",
                                                                              showCancelButton: true,
                                                                              confirmButtonColor:
                                                                                "#3085d6",
                                                                              cancelButtonColor:
                                                                                "#d33",
                                                                              confirmButtonText:
                                                                                "Delete",
                                                                              customClass:
                                                                              {
                                                                                container:
                                                                                  "my-swal",
                                                                              },
                                                                            }
                                                                          ).then(
                                                                            (
                                                                              result
                                                                            ) => {
                                                                              if (
                                                                                result.isConfirmed
                                                                              ) {
                                                                                this.deleteAddons(
                                                                                  value.addon_id
                                                                                );
                                                                              }
                                                                            }
                                                                          );
                                                                        }}
                                                                      >
                                                                        <DeleteIcon />
                                                                      </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Edit Addon"
                                                                      placement="top"
                                                                    >
                                                                      <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          this.setExtraToEdit(
                                                                            value.addon_id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <EditIcon />
                                                                      </IconButton>
                                                                    </Tooltip>
                                                                    <CopyExtrasOptions
                                                                      key={
                                                                        value.addon_id
                                                                      }
                                                                      menuNames={
                                                                        this
                                                                          .props
                                                                          .upSellMenuNames
                                                                      }
                                                                      menuName={
                                                                        this
                                                                          .props
                                                                          .upSellSelectedMenu
                                                                      }
                                                                      fetchItems={
                                                                        this
                                                                          .fetchItems
                                                                      }
                                                                      addOnType="EXTRAS"
                                                                      fetchCategoryNames={
                                                                        this
                                                                          .fetchCategoryNames
                                                                      }
                                                                      itemNames={
                                                                        this
                                                                          .state
                                                                          .upSellItemNames
                                                                      }
                                                                      categoryNames={
                                                                        this
                                                                          .state
                                                                          .upSellCategoryNames
                                                                      }
                                                                      addonId={
                                                                        value.addon_id
                                                                      }
                                                                      fetchAllItems={
                                                                        this
                                                                          .props
                                                                          .fetchItems
                                                                      }
                                                                    />
                                                                  </ButtonGroup>
                                                                </Grid>
                                                              </Grid>
                                                              <Divider />
                                                            </Paper>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <Alert severity="warning">
                                                    No addon found
                                                  </Alert>
                                                )}
                                              </Grid>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Grid>
                                      );
                                    }
                                  )
                                ) : (
                                  <Alert severity="warning">
                                    No addon category found
                                  </Alert>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  {itemToEdit && this.state.is_combo && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        disabled={this.props.settings?.is_eposnow_enabled}
                        expanded={expanded === "manage-combos"}
                        onChange={this.handleAccordianChange("manage-combos")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Manage Combos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="row" spacing={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs={12} md={12}>
                                <Grid container direction="row">
                                  <Button
                                    style={{ marginLeft: "14px" }}
                                    onClick={() =>
                                      this.handleComboGroupModal("Combos")
                                    }
                                  >
                                    Add Combos
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Grid container direction="column">
                                {this.props.combos ? (
                                  this.props.combos.map((combo) => {
                                    return (
                                      <Grid item style={{ paddingTop: 5 }}>
                                        <Accordion
                                          key={combo.combo_group_id}
                                          expanded={
                                            subExpanded === combo.combo_group_id
                                          }
                                          onChange={this.handleSubAccordianChange(
                                            combo.combo_group_id
                                          )}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Grid item xs={6} md={6}>
                                                <ButtonGroup
                                                  size="small"
                                                  orientation="horizontal"
                                                  color="primary"
                                                  variant="text"
                                                  aria-label="horizontal outlined primary button group"
                                                >
                                                  <Tooltip
                                                    title="Delete Combo Group"
                                                    placement="top"
                                                  >
                                                    <IconButton
                                                      color="action"
                                                      size="small"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        /*  if (this.props.comboItems) {
                                                             let combos =
                                                               this.props.comboItems.filter(
                                                                 (comb) => {
                                                                   return (
                                                                     comb.combo_group_id ==
                                                                     combo.combo_group_id
                                                                   );
                                                                 }
                                                               );
                                                             if (combos.length > 0) {
                                                               toast.warn("Please remove the combo items inside combo group.");
                                                               return;
                                                             }
                                                           }
  */
                                                        if (
                                                          this.props.combos &&
                                                          this.props.combos
                                                            ?.length == 1
                                                        ) {
                                                          toast.warn(
                                                            "Deleting the last combo group is not allowed. At least one combo group must be present."
                                                          );
                                                          return;
                                                        } else if (
                                                          this.props.combos &&
                                                          this.props.combos
                                                            ?.length > 1
                                                        ) {
                                                          toast.warn(
                                                            "Deleting Combo group will delete all the items in the Combo"
                                                          );
                                                        }

                                                        deleteConfirm({
                                                          name: combo.combo_group_identifier,
                                                          id: combo.combo_group_id,
                                                          message:
                                                            "Combo group deleted.",
                                                          deleteFunc:
                                                            deleteComboGroup,
                                                          func: [
                                                            this.props
                                                              .fetchAllCombos,
                                                          ],
                                                        });
                                                      }}
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title="Edit Combo Group"
                                                    placement="top"
                                                  >
                                                    <IconButton
                                                      color="action"
                                                      size="small"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.editCombo(
                                                          combo,
                                                          "Combos"
                                                        );
                                                      }}
                                                    >
                                                      <EditIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title="Add Combo"
                                                    placement="top"
                                                  >
                                                    <IconButton
                                                      color="action"
                                                      size="small"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleComboItemModal(
                                                          combo.combo_group_id
                                                        );
                                                      }}
                                                    >
                                                      <AddBoxIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </ButtonGroup>
                                              </Grid>
                                              <Grid item xs={6} md={6}>
                                                <Typography
                                                  className="heading"
                                                  style={{
                                                    wordWrap: "break-word",
                                                  }}
                                                >
                                                  {combo.combo_group_identifier}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Grid container direction="column">
                                              {this.props.comboItems ? (
                                                <List>
                                                  {this.props.comboItems.map(
                                                    (value) => {
                                                      if (
                                                        value.combo_group_id ===
                                                        combo.combo_group_id
                                                      ) {
                                                        const labelId = `checkbox-list-label-${value.combo_group_id}`;

                                                        return (
                                                          <>
                                                            <React.Fragment
                                                              key={
                                                                value.combo_group_id
                                                              }
                                                            >
                                                              <Paper
                                                                className="paper"
                                                                style={{
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              >
                                                                <Grid
                                                                  container
                                                                  alignItems="center"
                                                                  spacing={1}
                                                                >
                                                                  <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    lg={1}
                                                                  >
                                                                    <Avatar
                                                                      sx={{
                                                                        width: 32,
                                                                        height: 32,
                                                                        fontSize:
                                                                          "1rem",
                                                                      }}
                                                                    >
                                                                      {
                                                                        value.combo_item_sort_order
                                                                      }
                                                                    </Avatar>
                                                                  </Grid>

                                                                  <Grid
                                                                    item
                                                                    xs={8}
                                                                    md={4}
                                                                    lg={6}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        wordBreak:
                                                                          "break-word",
                                                                      }}
                                                                    >
                                                                      {
                                                                        value.item_identifier
                                                                      }
                                                                    </span>
                                                                  </Grid>

                                                                  <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={2}
                                                                    lg={2}
                                                                  >
                                                                    <Chip
                                                                      label={
                                                                        getCurrencySymbol(
                                                                          localStorage.getItem(
                                                                            "currency_code"
                                                                          )
                                                                        ) +
                                                                        value.extra_price?.toFixed(2)
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={2}
                                                                    lg={2}
                                                                    container
                                                                    justifyContent="flex-end"
                                                                  >
                                                                    <ButtonGroup
                                                                      size="small"
                                                                      orientation="horizontal"
                                                                      color="primary"
                                                                      variant="text"
                                                                    >
                                                                      <Tooltip
                                                                        title="Delete Combo Item"
                                                                        placement="top"
                                                                      >
                                                                        <IconButton
                                                                          color="primary"
                                                                          size="small"
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.stopPropagation();
                                                                            Swal.fire(
                                                                              {
                                                                                title: `Delete ${value.item_identifier}`, // Name you want to display on pop up
                                                                                text: "You won't be able to revert this!",
                                                                                icon: "warning",
                                                                                showCancelButton: true,
                                                                                confirmButtonColor:
                                                                                  "#3085d6",
                                                                                cancelButtonColor:
                                                                                  "#d33",
                                                                                confirmButtonText:
                                                                                  "Delete",
                                                                                customClass:
                                                                                {
                                                                                  container:
                                                                                    "my-swal",
                                                                                },
                                                                              }
                                                                            ).then(
                                                                              (
                                                                                result
                                                                              ) => {
                                                                                if (
                                                                                  result.isConfirmed
                                                                                ) {
                                                                                  this.deleteComboItems(
                                                                                    value
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <DeleteIcon />
                                                                        </IconButton>
                                                                      </Tooltip>
                                                                      <Tooltip
                                                                        title="Edit Combo Item"
                                                                        placement="top"
                                                                      >
                                                                        <IconButton
                                                                          color="primary"
                                                                          size="small"
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.stopPropagation();
                                                                            this.setComboItemsToEdit(
                                                                              value
                                                                            );
                                                                          }}
                                                                        >
                                                                          <EditIcon />
                                                                        </IconButton>
                                                                      </Tooltip>
                                                                    </ButtonGroup>
                                                                  </Grid>

                                                                  <Divider />
                                                                </Grid>
                                                              </Paper>
                                                            </React.Fragment>
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </List>
                                              ) : (
                                                <Alert severity="warning">
                                                  No combo item found
                                                </Alert>
                                              )}
                                            </Grid>
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    );
                                  })
                                ) : (
                                  <Alert severity="warning">
                                    No combo found
                                  </Alert>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  {itemToEdit && !this.state.is_combo && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        disabled={this.props.settings?.is_eposnow_enabled}
                        expanded={expanded === "manage-options"}
                        onChange={this.handleAccordianChange("manage-options")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Manage Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="row" spacing={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs={12} md={12}>
                                <Grid container direction="row">
                                  <Button
                                    style={{ marginLeft: "14px" }}
                                    onClick={() =>
                                      this.handleAddonCategoryModal("Options")
                                    }
                                  >
                                    Add Option Categories
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Grid container direction="column">
                                {this.props.categoriesForOptions ? (
                                  this.props.categoriesForOptions.map(
                                    (category) => {
                                      return (
                                        <Grid item style={{ paddingTop: 5 }} key={category.addon_category_id}>
                                          <Accordion
                                            expanded={
                                              subExpanded ===
                                              category.addon_category_id
                                            }
                                            onChange={this.handleSubAccordianChange(
                                              category.addon_category_id
                                            )}
                                          >
                                            <AccordionSummary
                                              key={category.addon_category_id}
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                <Grid item xs={6} md={6}>
                                                  <ButtonGroup
                                                    size="small"
                                                    orientation="horizontal"
                                                    color="primary"
                                                    variant="text"
                                                    aria-label="horizontal outlined primary button group"
                                                  >
                                                    <Tooltip
                                                      title="Delete addon catagory"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          if (
                                                            this.props
                                                              .currentItemOptions
                                                          ) {
                                                            let addonExtras =
                                                              this.props.currentItemOptions.filter(
                                                                (addon) => {
                                                                  return (
                                                                    addon.addon_category_id ==
                                                                    category.addon_category_id
                                                                  );
                                                                }
                                                              );
                                                            if (
                                                              addonExtras.length >
                                                              0
                                                            ) {
                                                              toast.warn(
                                                                "Please remove the addons inside this category first"
                                                              );
                                                              return;
                                                            }
                                                          }
                                                          deleteConfirm({
                                                            name: category.addon_category_identifier,
                                                            id: category.addon_category_id,
                                                            message:
                                                              "Option Category deleted.",
                                                            deleteFunc:
                                                              deleteAddonCategory,
                                                            func: [
                                                              this.props
                                                                .fetchAllAddons,
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <DeleteIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Edit addon catagory"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();

                                                          this.editAddonCategory(
                                                            category,
                                                            "Options"
                                                          );
                                                        }}
                                                      >
                                                        <EditIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                      title="Add addon"
                                                      placement="top"
                                                    >
                                                      <IconButton
                                                        color="action"
                                                        size="small"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          this.handleOptionsModal(
                                                            category.addon_category_id
                                                          );
                                                        }}
                                                      >
                                                        <AddBoxIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                  <Typography
                                                    className="heading"
                                                    style={{
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {
                                                      category.addon_category_identifier
                                                    }
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails key={category.addon_category_id}>
                                              <Grid
                                                container
                                                direction="column"
                                              >
                                                {this.props
                                                  .currentItemOptions ? (
                                                  this.props.currentItemOptions.map(
                                                    (value) => {
                                                      if (
                                                        value.addon_category_id ===
                                                        category.addon_category_id
                                                      ) {
                                                        const labelId = `checkbox-list-label-${value.addon_id}`;

                                                        return (
                                                          <React.Fragment key={value.addon_id}>
                                                            <Paper
                                                              className="paper"
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              <Grid
                                                                key={
                                                                  value.addon_id
                                                                }
                                                                item
                                                                container
                                                                alignItems="center"
                                                                spacing={2}
                                                              >
                                                                <Grid
                                                                  item
                                                                  xs={2}
                                                                  md={2}
                                                                  lg={1}
                                                                >
                                                                  <Avatar
                                                                    sx={{
                                                                      width: 32,
                                                                      height: 32,
                                                                      fontSize:
                                                                        "1rem",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value.addon_sort_order
                                                                    }
                                                                  </Avatar>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={2}
                                                                  md={2}
                                                                  lg={1}
                                                                >
                                                                  <img
                                                                    src={
                                                                      value.addon_image
                                                                        ? value.addon_image
                                                                        : null
                                                                    }
                                                                    style={{
                                                                      height: 50,
                                                                      width: 50,
                                                                    }}
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={8}
                                                                  md={4}
                                                                  lg={8}
                                                                  container
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component="span"
                                                                    variant="body1"
                                                                    style={{
                                                                      wordBreak:
                                                                        "break-word",
                                                                      overflow:
                                                                        "hidden",
                                                                      textOverflow:
                                                                        "ellipsis",
                                                                    }}
                                                                  >
                                                                    {
                                                                      value.addon_identifier
                                                                    }
                                                                  </Typography>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  md={4}
                                                                  lg={2}
                                                                  container
                                                                  justifyContent="flex-end"
                                                                >
                                                                  <ButtonGroup
                                                                    size="small"
                                                                    orientation="horizontal"
                                                                    color="primary"
                                                                    variant="text"
                                                                  >
                                                                    <Tooltip
                                                                      title="Delete Addon"
                                                                      placement="top"
                                                                    >
                                                                      <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          Swal.fire(
                                                                            {
                                                                              title: `Delete ${value.addon_identifier}`,
                                                                              text: "You won't be able to revert this!",
                                                                              icon: "warning",
                                                                              showCancelButton: true,
                                                                              confirmButtonColor:
                                                                                "#3085d6",
                                                                              cancelButtonColor:
                                                                                "#d33",
                                                                              confirmButtonText:
                                                                                "Delete",
                                                                              customClass:
                                                                              {
                                                                                container:
                                                                                  "my-swal",
                                                                              },
                                                                            }
                                                                          ).then(
                                                                            (
                                                                              result
                                                                            ) => {
                                                                              if (
                                                                                result.isConfirmed
                                                                              ) {
                                                                                this.deleteAddons(
                                                                                  value.addon_id
                                                                                );
                                                                              }
                                                                            }
                                                                          );
                                                                        }}
                                                                      >
                                                                        <DeleteIcon />
                                                                      </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Edit Addon"
                                                                      placement="top"
                                                                    >
                                                                      <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.stopPropagation();
                                                                          this.setOptionsToEdit(
                                                                            value.addon_id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <EditIcon />
                                                                      </IconButton>
                                                                    </Tooltip>
                                                                    <CopyExtrasOptions
                                                                      key={
                                                                        value.addon_id
                                                                      }
                                                                      menuNames={
                                                                        this
                                                                          .props
                                                                          .upSellMenuNames
                                                                      }
                                                                      menuName={
                                                                        this
                                                                          .props
                                                                          .upSellSelectedMenu
                                                                      }
                                                                      fetchItems={
                                                                        this
                                                                          .fetchItems
                                                                      }
                                                                      addOnType="OPTIONS"
                                                                      fetchCategoryNames={
                                                                        this
                                                                          .fetchCategoryNames
                                                                      }
                                                                      itemNames={
                                                                        this
                                                                          .state
                                                                          .upSellItemNames
                                                                      }
                                                                      categoryNames={
                                                                        this
                                                                          .state
                                                                          .upSellCategoryNames
                                                                      }
                                                                      addonId={
                                                                        value.addon_id
                                                                      }
                                                                      fetchAllItems={
                                                                        this
                                                                          .props
                                                                          .fetchItems
                                                                      }
                                                                    />
                                                                  </ButtonGroup>
                                                                </Grid>
                                                              </Grid>
                                                            </Paper>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <Alert severity="warning">
                                                    No addon found
                                                  </Alert>
                                                )}
                                              </Grid>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Grid>
                                      );
                                    }
                                  )
                                ) : (
                                  <Alert severity="warning">
                                    No addon found
                                  </Alert>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}

                  <Grid item style={{ paddingTop: 5 }}>
                    <Accordion
                      disabled={this.props.settings?.is_eposnow_enabled}
                      expanded={expanded === "image"}
                      onChange={this.handleAccordianChange("image")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Image</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction={"column"}>
                          <Grid item>
                            <MultipleImageCropper
                              data={{
                                key: "menu",
                                isMultiple: false,
                                getCroppedImages: this.getCroppedImages,
                              }}
                              height={300}
                              width={300}
                            />
                            <br></br> <br></br>
                          </Grid>
                          <Grid item>
                            <img
                              className="cover"
                              src={
                                this.state.imagesPreviewUrl
                                  ? this.state.imagesPreviewUrl
                                  : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {itemToEdit && (
                    <Grid item style={{ paddingTop: 5 }}>
                      <Accordion
                        expanded={expanded === "barcode-image"}
                        onChange={this.handleAccordianChange("barcode-image")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Barcode</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ItemBarcode
                            item={itemToEdit}
                            propertyId={this.props.propertyId}
                            showBarCodeTextField={this.showBarCodeTextField}
                            showBarCardField={showBarCardField}
                            itemId={this.state?.itemToEdit?.item_id}
                          />
                          {(showBarCardField || itemToEdit?.item_bar_code) &&
                            <Grid item xs={12} md={12} container alignItems="center">
                              <Grid item xs={10} sx={{display:"flex",alignItems:"center"}}>
                                {isEditable ? (
                                  <>
                                  <TextField
                                    error={touched.item_bar_code && errors.item_bar_code ? true : false}
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onKeyDown={(e) => {
                                      const key = e.key;
                                      if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
                                        return;
                                      }
                                      if ((e.ctrlKey || e.metaKey) && key === 'v') {
                                        return;
                                      }
                                      if (!/^[0-9]$/.test(key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    id="item_bar_code"
                                    label="Item Bar Code"
                                    name="item_bar_code"
                                    autoComplete="item_bar_code"
                                    size="small"
                                    variant="standard"
                                    onBlur={handleBlur}
                                    onPaste={(e) => {
                                      const paste = (e.clipboardData || window.clipboardData).getData('text');
                                      if (!/^[0-9]+$/.test(paste)) {
                                        e.preventDefault();
                                      }
                                      setTimeout(() => {
                                        const value = e.target.value;
                                        if (!/^[0-9]+$/.test(value)) {
                                          setFieldValue("item_bar_code", value.replace(/[^0-9]/g, ''));
                                        }
                                      }, 0);
                                    }}
                                    value={values.item_bar_code}
                                    onChange={(e) => {
                                      setFieldValue("item_bar_code", e.target.value.replace(/[^0-9]/g, ''));
                                    }}
                                    aria-describedby="component-error-text"
                                    helperText={
                                      touched.item_bar_code && errors.item_bar_code ? errors.item_bar_code : null
                                    }
                                  />
                                  <Button color="primary" style={{height:"40px",marginLeft:"8px"}} onClick={this.handleBarcodeClick}>Create</Button>
                                  </>
                                ) : (
                                  <Typography variant="body1">
                                    {values.item_bar_code}
                                  </Typography>
                                )}
                              </Grid>
                              {!isEditable &&
                                <Grid item xs={2} container justifyContent="flex-end">
                                  { !this.props.settings?.is_eposnow_enabled &&
                                    <IconButton onClick={this.handleEditClick}>
                                      <EditIcon />
                                    </IconButton>
                                  }
                                  <IconButton
                                  //  onClick={() => this.handleDeleteClick(itemToEdit.item_id, setFieldValue)}
                                   onClick={()=>this.handleDeleteBarCodeClick(itemToEdit.item_id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              }
                            </Grid>
                          }
                        </AccordionDetails>
                      </Accordion>
                    </Grid>)}

                  <Grid item xs={12} md={12} style={{ paddingTop: 5 }}>
                    {Array.isArray(errors) ||
                      ((Object.values(errors).toString() != "" ||
                        this.state.imagesPreviewUrl === null) && (
                          <Alert severity="warning">
                            {JSON.stringify(Object.values(errors)).replace(
                              /[\[\]'"]+/g,
                              ""
                            )}
                            {this.state.imagesPreviewUrl === null
                              ? " Image is required"
                              : ""}
                          </Alert>
                        ))}

                    {submitting ? (
                      <Loader size={30} />
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        block
                        sx={{ mt: 2, mb: 2 }}
                      >
                        {this.props.title === "Add Item"
                          ? "Add Item"
                          : "Update Item"}
                      </Button>
                    )}
                  </Grid>
                  
              <Dialog
                open={dialogOpen}
                onClose={this.handleDialogClose}
              >
                <DialogTitle>{barCodeDialog?"Delete Barcode?":"Enable  Inventory?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  {barCodeDialog?"Do you really want to proceed?":"Are you sure you want to enable inventory for this item? Once inventory is enabled, you won't be allowed to turn it off."}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="primary">
                    No
                  </Button>
                  <Button onClick={ () => 
                    barCodeDialog?this.handleDeleteClick() : this.setInventoryValue(setFieldValue) } color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
                </Form>
              )}
            </Formik>

            
          </Grid>
        </Grid>
        {openViewExtrasDialog && (
          <ViewExtrasDialog
            open={openViewExtrasDialog}
            setViewExtrasDialog={this.handleViewExtraDialog}
            extraToShow={extraToShow}
          />
        )}
        {openExtrasModel && (
          <AddEditExtrasModal
            propertyId={this.props.propertyId}
            selectedAddonCategory={selectedAddonCategory}
            openExtrasModel={openExtrasModel}
            handleExtrasModal={this.handleExtrasModal}
            handleAddons={this.handleAddons}
            extraToEdit={extraToEdit}
            track={this.state.track}
            menuNames={this.props.upSellMenuNames}
            itemNames={this.state.upSellItemNames}
            menuName={this.props.upSellSelectedMenu}
            itemId={this.state.itemToEdit.item_id}
            fetchItems={this.fetchItems}
            categoriesForExtras={this.props.categoriesForExtras}
          />
        )}
        {openOptionsModel && (
          <AddEditOptionsModal
            selectedAddonCategory={selectedAddonCategory}
            propertyId={this.props.propertyId}
            openOptionsModel={openOptionsModel}
            handleOptionsModal={this.handleOptionsModal}
            handleAddons={this.handleAddons}
            optionToEdit={optionToEdit}
            track={this.state.track}
            menuNames={this.props.upSellMenuNames}
            itemNames={this.state.upSellItemNames}
            menuName={this.props.upSellSelectedMenu}
            fetchItems={this.fetchItems}
            itemId={this.state.itemToEdit.item_id}
            categoriesForOptions={this.props.categoriesForOptions}
          />
        )}
        {openAddonCategoryModel && (
          <ManageAddonCategories
            addonCategoryToEdit={addonCategoryToEdit}
            resetAddonCategoryToEdit={this.resetAddonCategoryToEdit}
            itemID={itemToEdit.item_id}
            categoryType={categoryType}
            propertyId={this.props.propertyId}
            openAddonCategoryModel={openAddonCategoryModel}
            handleAddonCategoryModal={this.handleAddonCategoryModal}
            fetchAllAddons={this.props.fetchAllAddons}
          />
        )}
        {openCombosModel && (
          <ManageCombos
            comboGroupToEdit={comboGroupToEdit}
            resetComboGroupToEdit={this.resetComboGroupToEdit}
            itemID={itemToEdit.item_id}
            categoryType={categoryType}
            propertyId={this.props.propertyId}
            openCombosModel={openCombosModel}
            handleComboGroupModal={this.handleComboGroupModal}
            fetchAllAddons={this.props.fetchAllAddons}
            fetchAllCombos={this.props.fetchAllCombos}
          />
        )}
        {openComboGroupItemsModel && (
          <AddEditComboItemsModal
            selectedCategory={selectedCategory}
            selectedMenu={this.props.selectedMenu}
            itemToEdit={itemToEdit}
            propertyId={this.props.propertyId}
            openComboGroupItemsModel={openComboGroupItemsModel}
            handleComboItemModal={this.handleComboItemModal}
            handleAddons={this.handleAddons}
            handleComboItems={this.handleComboItems}
            comboItemToEdit={comboItemToEdit}
            fetchAllCombos={this.props.fetchAllCombos}
            track={this.state.track}
            categoriesForOptions={this.props.categoriesForOptions}
            menuNames={this.props.upSellMenuNames}
            menuName={this.props.upSellSelectedMenu}
            fetchItems={this.fetchItems}
            fetchCategoryNames={this.fetchCategoryNames}
            items={this.state.upSellItemNames}
            categoryNames={this.state.upSellCategoryNames}
            comboItems={this.props.comboItems}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryNames: state.menu.categoryNames,
  currentItemExtras: state.menu.currentItemExtras,
  currentItemOptions: state.menu.currentItemOptions,
  propertyId: state.user.propertyId,
  categoriesForExtras: state.menu.categoriesForExtras,
  categoriesForOptions: state.menu.categoriesForOptions,
  combos: state.menu.combos,
  comboItems: state.menu.comboItems,
  selectedMenu: state.menu.selectedMenu,
  settings: state.user.settings
});

const mapDispatchToProps = {
  setCurrentItemExtras,
  setCurrentItemOptions,
  setSelectedCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditItem);
