import React, { Component } from 'react';
import ItemForm from './components/ItemForm';
import StockList from './components/StockList';
import BatchItemList from './components/BatchItemList';
import { Container, Typography, Box, Paper, Grid, Button, Divider, Card, CardContent } from '@mui/material';
import { AppBar, Tabs, Tab } from "@mui/material";
import Breadcrumb from "./Breadcrumb";
import * as InventoryrServiceAPI from '../../services/inventory/inventoryService';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Modal } from "antd";
import { Result } from "antd";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}


class Inventory extends Component {
  state = {
    stock: null,
    batch: null,
    showFormDialog: false,
    showForm: false,
    showBatchFormDialog: false,
    showBatchForm: false,
    tab: 0,
    inventoryPlan: {},
    isModalOpen: false
  };

  componentDidMount = () => {
    this.fetchInventoryPlan();
  };

  handleEdit = (item) => {
    console.log(item)
    this.setState({ stock: item, showForm: true });
  };

  handleFormSubmit = () => {
    this.setState({ stock: null, showForm: false });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          stock: null,
        },
        () => {
          //this.setInitalValues();
        }
      );
    }
  };

  showBatchForm = () => {
    if (!this.state.showBatchForm) {
      this.setState({
        showBatchForm: true,
      });
    } else {
      this.setState(
        {
          showBatchForm: false,
          batch: null,
        },
        () => {
          //this.setInitalValues();
        }
      );
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  fetchInventoryPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await InventoryrServiceAPI.fetchInventoryPlan(dump);
      if (response.data.status) {
        if (response.data?.data) {
          this.setState(
            {
              inventoryPlan: response.data.data,
            },
            () => {
              if (response.data.data.subscription_status === "EXPIRED") {
                this.props.history.push({ pathname: "/inventory-accept" });
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ inventoryPlan: {} });
        this.props.history.push({ pathname: "/inventory-accept" });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ inventoryPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
      this.props.history.push({ pathname: "/inventory-accept" });
    }
  };

  handleCancelClick = () => {
    this.setState({ isModalOpen: true });
  };

  cancelInventoryPlan = async () => {
    const dump = this.state.inventoryPlan.integration_Id;
    try {
      const response = await InventoryrServiceAPI.cancelInventoryPlan(dump);

      if (response.data.status) {
        this.fetchInventoryPlan();
        this.setState({ isModalOpen: false });
      } else {
        const err = response.data?.message || "Something went wrong.";
        this.setState({ inventoryPlan: {}, isModalOpen: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ inventoryPlan: {}, isModalOpen: false });
      toast.warn(err);
      throw error;
    }
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };


  render() {
    const { stock, showForm, tab, showBatchForm, inventoryPlan, isModalOpen } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb />
        </div>
        <div className="col-md-12">
          <div className="ms-panel">
            {/*       <AppBar position="static" color="default">
              <Tabs
                value={tab}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#5773ff" } }}
              >
                <Tab label="Stock" />
              </Tabs>
              <TabPanel value={tab} index={0}> */}
            <div className="ms-panel">
              <div className="ms-panel-body">
                <div className="col-md-12">
                  {/*  <Typography variant="h5" gutterBottom style={{}}>
                      </Typography> */}

                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Typography variant="h6"  >
                            <InfoOutlinedIcon /> &nbsp;
                            <b> Manage Inventory</b>
                          </Typography>
                          <Typography variant="subtitle1" sx={{ color: 'grey' }} >
                            Inventory can be added, deleted and edited from this section
                          </Typography>
                        </Grid>
                        {/* <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ textAlign: "right" }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.showForm}
                                >
                                  {this.state.stock ? "Update" : "Add"} Stock
                                </Button>
                              </Grid> */}

                        {(inventoryPlan?.subscription_status === "ACTIVE" || inventoryPlan?.subscription_status === "CANCELLED") && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            lg={6}
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              size="large"
                              variant="contained"
                              className="white-text"
                              sx={{
                                mt: { xs: 1, md: 0 },
                                width: "150px",
                                height: "50px",
                              }}
                              disabled={inventoryPlan?.subscription_status === "CANCELLED"}
                              onClick={this.handleCancelClick}
                            >
                              Cancel Plan
                            </Button>
                          </Grid>
                        )}

                        {inventoryPlan?.subscription_status === "CANCELLED" && (
                          <Typography
                            variant="body2"
                            sx={{
                              marginLeft: "10px",
                              backgroundColor: "lightgrey",
                              padding: "5px",
                              width: "150px",
                              float: "right",
                              paddingLeft: "10px",
                              marginTop: "10px"
                            }}
                          >
                            Valid till{" "}
                            {inventoryPlan?.subscription_end_date?.substring(0, 10)}
                          </Typography>
                        )}


                        
                      </Grid>
                      <Divider sx={{ mb: 4 }} />
                    </React.Fragment>
                  )}

                  {showForm && (
                    <React.Fragment>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ margin: "10px" }}
                      >
                        <div style={{}}>
                          Batch
                          <Button
                            variant="contained"
                            style={{ float: "right" }}
                            color="primary"
                            onClick={this.showForm}
                          >
                            Back
                          </Button>
                        </div>
                      </Typography>
                      {/* <p style={{ margin: "10px" }}>
                        Complete the following details.
                      </p> */}
                      <Divider sx={{ mb: 4 }} />
                      <Grid
                        container
                        spacing={2}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            mb: 2,
                            ml: "20px",
                            width: "220px",
                            height: "40px",
                            display: { xs: "block", md: "none" },
                          }}
                          onClick={this.handleViewInstructions}
                        >
                          View Instructions
                        </Button>{" "}
                        <Grid item xs={12} md={8} lg={8}>
                          <ItemForm initialValues={stock || {
                            stock_name: '',
                            quantity: 0,
                            min_quantity: 0,
                            max_quantity: 0,

                          }} onSubmit={this.handleFormSubmit} stockId={stock?.stock_id} />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Box sx={{}}>
                              <Paper
                                elevation={3}
                                sx={{
                                  width: "auto",
                                  marginBottom: "20px",
                                }}
                              >
                                <Card>
                                  <Typography
                                    variant="h5"
                                    gutterBottom
                                    style={{
                                      background: "#5773ff",
                                      textAlign: "center",
                                      color: "white",
                                      margin: "0px",
                                      padding: "6px 0px",
                                    }}
                                  >
                                    Instructions
                                  </Typography>
                                  <CardContent>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography color="textPrimary" paragraph>
                                        <b>
                                         Instuctions.
                                        </b>
                                      </Typography>
                                    </Box>
                                    <Grid
                                      container
                                      spacing={1}
                                      alignItems="center"
                                      sx={{ my: 1 }}
                                    >
                                    
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <b>Quantity.</b>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                          }}
                                        >
                                          Quanitity of the item being increased or decreased.{" "}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <b>Description.</b>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                          }}
                                        >
                                         Description of the item added in stock. {" "}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <b>Reason.</b>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                          }}
                                        >
                                          Reason of the batch being added.{" "}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <b>Minimum & Maximum Stock Level </b>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                          }}
                                        >
                                          The minimum and maximum stock levels are the lowest and highest amounts of an item you should have in inventory.
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          <b>  Batch</b>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            padding: "5px",
                                          }}
                                        >
                                          Batch system organises items by production or acquisition date. You can use the batch feature to add batches, and adjustments help you modify or discard batches, accounting for waste and other losses.
                                        </Typography>
                                      </Grid>

                                      {/*    <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b>Unit</b>
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                }}
                                              >
                                               Unit size of the stock for e.g. KILIOGRAM.
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b>Min Quantity</b>
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                }}
                                              >
                                               Min quanity of the stock.
                                              </Typography>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b>Max Quantity</b>
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                }}
                                              >
                                               Max Quanity of the stock.
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              lg={12}
                                              style={{ textAlign: "left" }}
                                            >
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                <b>Description</b>
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  padding: "5px",
                                                }}
                                              >
                                               Description of the stock.
                                              </Typography>
                                            </Grid> */}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Paper>
                            </Box>
                          </Grid>
                        </Grid>

                      </Grid>
                    </React.Fragment>

                  )}

                  {!showForm && (
                    <Box>
                      <StockList onEdit={this.handleEdit} />
                    </Box>
                  )}

                </div>
                <Grid container>
                        <Grid item lg={12}>
                          <Modal
                            title=""
                            open={isModalOpen}
                            onCancel={this.handleCancel}
                          >
                            <Result
                              style={{
                                color: "#5773ff",
                                marginBottom: "0px",
                                marginTop: "5px",
                              }}
                              title={"Cancel Subscription!"}
                              subTitle={
                                <Typography style={{ fontSize: "20px" }}>
                                  Are you sure you want to cancel your Inventory subscription, your subscription is
                                  valid till{" "}
                                  {inventoryPlan?.subscription_end_date?.substring(
                                    0,
                                    10
                                  )}
                                </Typography>
                              }
                            />
                            <div
                              style={{
                                marginTop: "0px",
                                justifyContent: "space-around",
                                display: "flex",
                              }}
                            >
                              <Button
                                size="large"
                                variant="contained"
                                className="white-text"
                                sx={{ height: "40px", width: "150px" }}
                                onClick={this.handleCancel}
                              >
                                No
                              </Button>
                              <Button
                                size="large"
                                variant="contained"
                                className="white-text"
                                sx={{
                                  height: "40px",
                                  width: "150px",
                                  marginLeft: "10px",
                                }}
                                onClick={this.cancelInventoryPlan}
                              >
                                Yes
                              </Button>
                            </div>
                          </Modal>
                        </Grid>
                      </Grid>
              </div>
            </div>
            {/*  </TabPanel>
              </AppBar> */}
          </div> </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(Inventory));
