import React, { Component } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { Stack, Typography } from "@mui/material";
import { Card } from "antd";
import { Steps } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Result } from "antd";
import home from "../../../../assets/img/collect/home.png";
import pound from "../../../../assets/img/collect/pound.png";
import setting from "../../../../assets/img/collect/settings.png";
import * as CollectInfoServiceAPI from "../../../../services/collectUserRequriement/CollectInfoServiceAPI";
import {  message } from 'antd';
import { Link } from 'react-router-dom'
import {getCurrencySymbol} from "../../../../config";
const steps = [
  {
    title: "",
  },
  {
    title: "",
  },
  {
    title: "",
  },
];
const businessNames = [
  "Restaurant",
  "Hotel",
  "Cafeteria",
  "Hospitality",
  "Bakery",
  "Shopping Malls",
  "Cinema",
  "Cafe",
  "Franchise",
  "Retail",
  "Takeaway",
  "Fast Food Service",
  "Car Rental",
  "Taxi",
  "Food Court",
  "EV Charging",
  "Spectator Stadium",
  "None",
];
const revenueNames = [
  "100K",
  "100 -200K ",
  "200 - 500K",
  "500K- 1M",
  "1-3M",
  "3M+",
  "None",

];
const orderProcessingNames = [
  "KIOSK",
  "QR ORDER ",
  "EPOS",
  "MPOS",
  "WEB ORDER",
  "BUSINESS APP",
  "None",

];
const orderFulfillmentNames = [
  "KITCHEN DISPLAY SYSTEM",
  "CUSTOMER DISPLAY SYSTEM",
  "None",

];
const paymentProcessingNames = [
  "PAY BY LINK",
  "MPOS",
  "TAP TO PAY",
  "CARD TERMINAL",
  "None",

];
class Collect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fullWidth: true,
      maxWidth: "md",
      selectedBusiness: null,
      selectedRevenue: null,
      selectedOrderProcessing: [],
      selectedOrderFulfillment: [],
      selectedPaymentProcessing: [],
      current: 0,
    };
  }

  componentDidMount = () => {
    if (this.props.status !== "ACTIVE") {
      this.checkUserInfo();
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMaxWidthChange = (event) => {
    this.setState({
      maxWidth: event.target.value,
    });
  };

  handleFullWidthChange = (event) => {
    this.setState({
      fullWidth: event.target.checked,
    });
  };

  handleBusinessClick = (buttonId) => {
    this.setState({ selectedBusiness: buttonId });
  };
  handleRevenueClick = (buttonId) => {
    this.setState({ selectedRevenue: buttonId });
  };
  handleOrderProcessingClick = (buttonId) => {
    if (buttonId === orderProcessingNames.length) {
      // If "None" button is clicked, set selectedOrderProcessing to contain only "None"
      this.setState({
        selectedOrderProcessing: [buttonId],
      });
    } else {
      // For other buttons, toggle selection as before and remove "None" if present
      const isSelected = this.state.selectedOrderProcessing.includes(buttonId);
      this.setState((prevState) => ({
        selectedOrderProcessing: isSelected
          ? prevState.selectedOrderProcessing.filter((id) => id !== buttonId && id !== orderProcessingNames.length)
          : [...prevState.selectedOrderProcessing.filter((id) => id !== orderProcessingNames.length), buttonId],
      }));
    }
  };
  

  handleOrderFulfillmentClick = (buttonId) => {
    if (buttonId === orderFulfillmentNames.length) {
      // If "None" button is clicked, set selectedOrderFulfillment to contain only "None"
      this.setState({
        selectedOrderFulfillment: [buttonId],
      });
    } else {
      // For other buttons, toggle selection as before and remove "None" if present
      const isSelected = this.state.selectedOrderFulfillment.includes(buttonId);
      this.setState((prevState) => ({
        selectedOrderFulfillment: isSelected
          ? prevState.selectedOrderFulfillment.filter((id) => id !== buttonId && id !== orderFulfillmentNames.length)
          : [...prevState.selectedOrderFulfillment.filter((id) => id !== orderFulfillmentNames.length), buttonId],
      }));
    }
  };
  
  handlePaymentProcessingClick = (buttonId) => {
    if (buttonId === paymentProcessingNames.length) {
      // If "None" button is clicked, set selectedPaymentProcessing to contain only "None"
      this.setState({
        selectedPaymentProcessing: [buttonId],
      });
    } else {
      // For other buttons, toggle selection as before and remove "None" if present
      const isSelected = this.state.selectedPaymentProcessing.includes(buttonId);
      this.setState((prevState) => ({
        selectedPaymentProcessing: isSelected
          ? prevState.selectedPaymentProcessing.filter((id) => id !== buttonId && id !== paymentProcessingNames.length)
          : [...prevState.selectedPaymentProcessing.filter((id) => id !== paymentProcessingNames.length), buttonId],
      }));
    }
  };

  next = () => {
    const c = this.state.current + 1;
    this.setState({ current: c });
  };
  prev = () => {
    const c = this.state.current - 1;
    if (c >= 0) {
      this.setState({ current: c });
    }
  };
  items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  renderBusinesses = () => {
    return businessNames.map((buttonName, index) => {
      const buttonId = index + 1;
      const isBusinessSelected = this.state.selectedBusiness === buttonId;

      return (
        <Grid item xs={6} lg={3} key={buttonId}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => this.handleBusinessClick(buttonId)}
            style={
              isBusinessSelected
                ? { margin: "8px" }
                : {
                    margin: "8px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
            }
          >
            {buttonName}
          </Button>
        </Grid>
      );
    });
  };
  renderRevenue = () => {
    return revenueNames.map((buttonName, index) => {
      const buttonId = index + 1;
      const isRevenueSelected = this.state.selectedRevenue === buttonId;

      return (
        <Grid item xs={6} lg={3} key={buttonId}>
          <Button
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={() => this.handleRevenueClick(buttonId)}
            style={
              isRevenueSelected
                ? { margin: "8px" }
                : {
                    margin: "8px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
            }
          >
            {buttonName}
          </Button>
        </Grid>
      );
    });
  };

  renderOrderProcessing = () => {
    return orderProcessingNames.map((buttonName, index) => {
      const buttonId = index + 1;
      const isOrderProcessingSelected =
        this.state.selectedOrderProcessing.includes(buttonId);

      return (
        <Grid item xs={12} md={6} lg={6} key={buttonId}>
          <Button
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={() => this.handleOrderProcessingClick(buttonId)}
            style={
              isOrderProcessingSelected
                ? { margin: "5px" }
                : {
                    margin: "5px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
            }
          >
            {buttonName}
          </Button>
        </Grid>
      );
    });
  };
  renderOrderFulfillment = () => {
    return orderFulfillmentNames.map((buttonName, index) => {
      const buttonId = index + 1;
      const isOrderFulfilmentSelected =
        this.state.selectedOrderFulfillment.includes(buttonId);

      return (
        <Grid item xs={12} md={6} lg={12} key={buttonId}>
          <Button
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={() => this.handleOrderFulfillmentClick(buttonId)}
            style={
              isOrderFulfilmentSelected
                ? { margin: "8px" }
                : {
                    margin: "8px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
            }
          >
            {buttonName}
          </Button>
        </Grid>
      );
    });
  };
  renderPaymentProcessing = () => {
    return paymentProcessingNames.map((buttonName, index) => {
      const buttonId = index + 1;
      const isPaymentProcessingSelected =
        this.state.selectedPaymentProcessing.includes(buttonId);

      return (
        <Grid item xs={12} md={6} lg={6} key={buttonId}>
          <Button
            fullWidth
            variant={"contained"}
            color="primary"
            onClick={() => this.handlePaymentProcessingClick(buttonId)}
            style={
              isPaymentProcessingSelected
                ? { margin: "8px" }
                : {
                    margin: "8px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }
            }
          >
            {buttonName}
          </Button>
        </Grid>
      );
    });
  };

  collectUserInfo = (bool) => {
    const username = this.props.user.username
      ? this.props.user.username
      : localStorage.getItem("username");
    const allOrderValues = this.state.selectedOrderProcessing.map(
      (index) => orderProcessingNames[index - 1]
    );
    const allOrderFulfillment = this.state.selectedOrderFulfillment.map(
      (index) => orderFulfillmentNames[index - 1]
    );
    const allProcessingValues = this.state.selectedPaymentProcessing.map(
      (index) => paymentProcessingNames[index - 1]
    );
    console.log(allOrderValues);
    const payload = {
      business_details: [
        ...allOrderValues,
        ...allOrderFulfillment,
        ...allProcessingValues,
      ],
      business_type: businessNames[this.state.selectedBusiness - 1],
      is_guide_to_onboarding: bool,
      revenue: revenueNames[this.state.selectedRevenue - 1],
      username: username,
    };

    console.log(payload);

    CollectInfoServiceAPI.collectUserRequirement(payload)
      .then((response) => {
        if (response.data.status) {
         // toast.success("User information has been recorded.");
         this.success();
          this.setState({ open: false });
          if(bool){
            setTimeout(() => { this.props.history.push('/guide');  }, 2000);
          }        
        } else {
          this.warning();
         /*  toast.success(
            "There was an issue while recording the user information."
          ); */
        }
      })
      .catch((error) => {
       // toast.error("Something went wrong.");
       this.error();
        // throw new Error(error)
      });
  };

  checkUserInfo = () => {
    const username = this.props.user.username
      ? this.props.user.username
      : localStorage.getItem("username");
    const accountStatus = localStorage.getItem("account status");
    CollectInfoServiceAPI.checkIfUserInfoIsCollected(username)
      .then((response) => {
        if (response.data.status) {
          if ( this.props.status !== "ACTIVE" && !response.data.data) {
            this.setState({
              open: true,
            });
          }
        }
      })
      .catch((error) => {
        // throw new Error(error)
      });
  };
  checkIfDisable = () => {
    const {
      current,
      selectedBusiness,
      selectedRevenue,
      selectedOrderProcessing,
      selectedOrderFulfillment,
      selectedPaymentProcessing,
    } = this.state;

    if (current === 0) {
      return !selectedBusiness;
    }

    if (current === 1) {
      return !selectedRevenue;
    }

    if (current === 2) {
      return (
        selectedOrderProcessing.length == 0 ||
        selectedOrderFulfillment.length == 0 ||
        selectedPaymentProcessing.length == 0
      );
    }

    return false; // Default case, in case 'current' is neither 0 nor 1
  };

  success = () => {
    message.open({
      type: 'success',
      content: 'User information has been recorded.',
    });
  };
  error = () => {
    message.open({
      type: 'error',
      content: 'An error occured while trying to save the user information.',
    });
  };
  warning = () => {
    message.open({
      type: 'warning',
      content: 'Could not save the user information.',
    });
  };
  

  render() {
    const { open, fullWidth, maxWidth, current } = this.state;
    const accountStatus = localStorage.getItem("account status");

    return (
      <>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open &&  this.props.status !== "ACTIVE"}
          disablebackdropClick
          PaperProps={{ sx: { background: "#F0F0F9" } }}
        >
          <DialogContent sx={{ px: 4 }}>
            <Stack alignItems="center">
              {current === 0 && (
                <img
                  src={home}
                  style={{
                    width: "50px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  alt="Home"
                />
              )}
              {current === 1 && (
                <img
                  src={pound}
                  style={{
                    width: "50px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  alt="Pound"
                />
              )}
              {current === 2 && (
                <img
                  src={setting}
                  style={{
                    width: "50px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  alt="Setting"
                />
              )}
            </Stack>
            <Box mx="auto" my={4} width="40%">
              {current < 3 && <Steps current={current} items={this.items} />}
            </Box>
            <Typography textAlign="center" variant="h5" gutterBottom>
              {current == 0 && <b>What kind of business do you own? </b>}
              {current == 1 && <b>What is your annual revenue ? </b>}
              {current == 2 && (
                <b> Which of the services are you interested in? </b>
              )}
            </Typography>
            <Typography
              textAlign="center"
              sx={{ fontSize: "18px" }}
              gutterBottom
            >
              {current == 1 && <span> in GBP {/* ( {getCurrencySymbol(localStorage.getItem('currency_code'))} ) */} </span>}
            </Typography>

            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "auto",
                mt: 4,
              }}
            >
              <Grid container spacing={2}>
                {current == 0 && this.renderBusinesses()}
                {current == 1 && this.renderRevenue()}
              </Grid>
            </Box>
            <Grid container spacing={2}>
              {current == 2 && (
                <React.Fragment>
                  <Grid item lg={4}>
                    <Card title="Order Processing" bordered={false}>
                      <Grid container spacing={1}>
                        {this.renderOrderProcessing()}
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item lg={4}>
                    <Card title="Order Fulfillment" bordered={false}>
                      <Grid container spacing={1}>
                        {this.renderOrderFulfillment()}
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item lg={4}>
                    <Card title="Payment Processing" bordered={false}>
                      <Grid container spacing={1}>
                        {this.renderPaymentProcessing()}
                      </Grid>
                    </Card>
                  </Grid>
                </React.Fragment>
              )}
              {current == 3 && (
                <Grid item lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Result
                      status="success"
                      title="Would you prefer our guidance or would you like to explore independently?"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <Box mt={4} textAlign="center">
              {current > 0 && current < 3 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={() => this.prev()}
                >
                  Back
                </Button>
              )}
              {current <= 2 && (
                <Button
                  variant="contained"
                  disabled={this.checkIfDisable()}
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={() => this.next()}
                >
                  Next
                </Button>
              )}

              {current == 3 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={() => this.collectUserInfo(true)}
                >
                  Guide me
                </Button>
              )}
              {current == 3 && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "8px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                  }}
                  onClick={() => this.collectUserInfo(false)}
                >
                  I will explore myself
                </Button>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  mainPropertyId: state.user.mainPropertyId,
  isFranchise: state.user.isFranchise,
  isVendor: state.user.isVendor,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Collect));
