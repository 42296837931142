import { createSlice } from "@reduxjs/toolkit";

export const miscSlice = createSlice({
  name: "misc", // Change the name to match the slice name
  initialState: {
    transactionDetails: null,
    webOrderUser: null
  },
  reducers: {
    setTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
    setWebOrderUserInfo : (state, action) => {
      state.webOrderUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTransactionDetails,
  setWebOrderUserInfo
} = miscSlice.actions;

export default miscSlice.reducer; // Fix the export statement to use the correct name
