import React, { Component } from "react";
import { QRCode } from "antd";
import { toast } from "react-toastify";
import * as QRServiceAPI from "../../services/qr/QRServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import "@arco-design/web-react/dist/css/arco.css";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import * as WebOrderServiceAPI from "../../services/webOrder/WebOrderServiceAPI";

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: true,
    discounts: [],
    discount: null,
    rooms: [],
    room: null,
    selectedDiscount: null,
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortDirection: "asc",
    open: false,
    dId: "",
    roomId: "",
    menuNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedMenu: "",
    itemNames: /* [{ "menu_id": "1", "menu_identifier": "One" }] || */ [],
    selectedItem: [],
    selectedItems: [],
    categoryNames: [],
    itemIdentifiers: [],
    itemIds: [],
    itemMap: {},
    selectedCategory: "",
    item_list: [],
    treeCheckStrictly: false,
    value: [],
    treeData: [],
    error: "",
    deliveryDynamicLink: "",
    propertyDynamicLink: "",
    takeAwayDynamicLink: "",
    diningDynamicLink: "",
    openDineIn: false,
    openTakeaway: false,
    openProperty: false,
    openDineIn: false,
    showForm: false,
    qr: {},
    webPlan: {},
    webOrderStatus: false,

    //defaultRange: [moment(new Date(), 'HH:mm:ss'), moment(new Date(), 'HH:mm:ss')]
  };

  componentDidMount = () => {
    const username = this.props.user.username;
    this.fetchBusinessPlace(username);

    this.fetchPlansStatus();
  };

  fetchBusinessPlace = (username) => {
    this.setState({ isLoading: true });
    SecurityServiceAPI.fetchBusinessPlace(username)
      .then((response) => {
        if (response.data.status) {
     
          this.setState({
            propertyDynamicLink:
              response.data?.data?.property_details[0]?.property_dynamic_link,
            takeAwayDynamicLink:
              response.data?.data?.property_details[0]?.takeaway_dynamic_link,
            deliveryDynamicLink:
              response.data?.data?.property_details[0]?.delivery_dynamic_link,
            diningDynamicLink:
              response.data?.data?.property_details[0]?.dinein_dynamic_link,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        // Handle network errors or other exceptions
        console.error("Fetch Error:", error.message);
        this.setState({ isLoading: false });
      });
  };

  generateDelivery = () => {
    if (this.state.webOrderStatus) {
      const payload = {
        property_id: this.props.propertyId,
      };

      QRServiceAPI.generateDeliveryDynamicLink(payload)
        .then((response) => {
          if (response.data.status) {
            //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
            toast.success("Delivery link generated. ");

            this.setState({
              deliveryDynamicLink:
                response.data.data.property_delivery_dynamic_link,
            });
          } else {
            this.setState({ deliveryDynamicLink: null }, () => {});
            toast.warn(response.data.message);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.warn("Could not generate delivery link. ");
        });
    } else {
      toast.warn("Please enable web ordering.");
    }
  };

  generateProperty = () => {
    const payload = {
      property_id: this.props.propertyId,
    };

    QRServiceAPI.generatePropertyDynamicLink(payload)
      .then((response) => {
        if (response.data.status) {
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Property Link Generated. ");

          this.setState({
            propertyDynamicLink: response.data.data.property_dynamic_link,
          });
        } else {
          this.setState({ propertyDynamicLink: null }, () => {});
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not generate property link. ");
      });
  };

  downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector("canvas");

    if (canvas) {
      // Get the current size of the QR code
      const qrCodeSize = canvas.width;

      // Create a new canvas with a larger size to accommodate the border
      const borderedCanvas = document.createElement("canvas");
      const borderedCtx = borderedCanvas.getContext("2d");

      // Set the size of the new canvas including the border
      borderedCanvas.width = qrCodeSize + 20; // 20 is the width of the white border
      borderedCanvas.height = qrCodeSize + 20; // 20 is the height of the white border

      // Draw a white border
      borderedCtx.fillStyle = "#ffffff"; // Set the color of the white border
      borderedCtx.fillRect(0, 0, borderedCanvas.width, borderedCanvas.height);

      // Draw the original QR code on top of the white border
      borderedCtx.drawImage(canvas, 10, 10, qrCodeSize, qrCodeSize); // 10 is the border width

      // Create a download link
      const url = borderedCanvas.toDataURL();
      const a = document.createElement("a");
      a.download = id + ".jpg";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.handleClose();
    }
  };

  handleClose = () => {
    this.setState({
      openDineIn: false,
      openTakeaway: false,
      openProperty: false,
      openDelivery: false,
    });
  };

  generateTakeAway = () => {
    const payload = {
      property_id: this.props.propertyId,
    };

    QRServiceAPI.generateTakeAway(payload)
      .then((response) => {
        if (response.data.status) {
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Takeaway link generated.");

          this.setState({
            takeAwayDynamicLink:
              response.data.data.property_takeaway_dynamic_link,
          });
        } else {
          this.setState({ takeAwayDynamicLink: null }, () => {});
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not generate take away link. ");
      });
  };

  generateDining = () => {
    const payload = {
      property_id: this.props.propertyId,
    };

    QRServiceAPI.generateDining(payload)
      .then((response) => {
        if (response.data.status) {
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Dine in link generated.");

          this.setState({
            diningDynamicLink: response.data.data.property_dinein_dynamic_link,
          });
        } else {
          this.setState({ diningDynamicLink: null }, () => {});
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not generate dining link. ");
      });
  };

  handleDineInClickOpen = () => {
    this.setState({ openDineIn: true });
  };

  handleDineInClose = () => {
    this.setState({ openDineIn: false });
  };

  handleTakeawayOpen = () => {
    this.setState({ openTakeaway: true });
  };

  handleTakeawayClose = () => {
    this.setState({ openTakeaway: false });
  };

  handlePropertyOpen = () => {
    this.setState({ openProperty: true });
  };

  handlePropertyClose = () => {
    this.setState({ openProperty: false });
  };

  handleDeliveryOpen = () => {
    this.setState({ openDelivery: true });
  };

  handleDeliveryClose = () => {
    this.setState({ openDelivery: false });
  };

  fetchPlansStatus = async () => {
    try {
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchPlansStatus(propertId);

      if (response.data.status) {
        this.setState({ qr: response.data.data });
        if (!response.data.data) {
          /*  this.props.history.push({
             pathname: "/manage-qr-flow",
           }); */
        }
        if (
          response.data?.data?.subscription_status == "ACTIVE" ||
          response.data?.data?.subscription_status == "CANCELLED"
        ) {
          this.fetchWebPlan();
        }
      } else {
        this.setState({ qr: response.data.data });
        // this.setState({ isActive: false });
      }
    } catch (error) {
      //  this.setState({ isActive: false });
      throw error;
    }
  };

  fetchWebPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await WebOrderServiceAPI.fetchWebPlan(dump);

      if (response.data.status) {
        if (response.data?.data) {
          this.setState({
            webPlan: response.data.data,
          });
          if (
            response.data?.data?.subscription_status == "ACTIVE" ||
            response.data?.data?.subscription_status == "CANCELLED"
          ) {
            this.setState({ webOrderStatus: true });
          }
        }
      } else {
        this.setState({ webOrderStatus: false });
        const err = response.data?.message || "Something went wrong.";
        this.setState({ webPlan: {} });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({ webOrderStatus: false });
      toast.warn(err);
      throw error;
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      error,
      defaultRange,
      start_time,
      end_time,
      rooms,
      qr,
      openDineIn,
      openTakeaway,
      openProperty,
      openDelivery,
      showForm,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <React.Fragment>
            <div
              className="row"
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="col">
                <Breadcrumb />
              </div>
              <div className="col"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6> Generate Delivery QR</h6>
                  </div>
                  <div className="ms-panel-body"></div>

                  <Box sx={{ flexGrow: 1, margin: "15px" }}>
                    {this.state.deliveryDynamicLink && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={this.handleDeliveryOpen}
                          >
                            VIEW QR
                          </Button>
                          <Dialog
                            open={openDelivery}
                            onClose={this.handleDeliveryClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"DELIVERY QR"}
                            </DialogTitle>
                            <DialogContent>
                              <Typography variant="body2" color="primary">
                                <a
                                  href={this.state.deliveryDynamicLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.deliveryDynamicLink}
                                </a>
                              </Typography>
                              <DialogContentText id="alert-dialog-description">
                                <div id="delivery">
                                  <QRCode
                                    size={350}
                                    value={this.state.deliveryDynamicLink}
                                    /* color="#5773ff" */ errorLevel="H"
                                    bgColor="#fff"
                                    bordered="true"
                                    style={{ marginBottom: 16 }}
                                  />
                                </div>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleDeliveryClose}>
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                autoFocus
                                color="primary"
                                onClick={() => {
                                  this.downloadQRCode("delivery");
                                }}
                              >
                                Download
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    )}

                    {!this.state.deliveryDynamicLink && (
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={this.generateDelivery}
                        sx={{ mt: 1, mb: 2, marginLeft: "20px" }}
                      >
                        Generate Delivery QR
                      </Button>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6> Generate Property QR</h6>
                  </div>
                  <div className="ms-panel-body"></div>

                  <Box sx={{ flexGrow: 1, margin: "15px" }}>
                    {this.state.propertyDynamicLink && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={this.handlePropertyOpen}
                          >
                            VIEW QR
                          </Button>
                          <Dialog
                            open={openProperty}
                            onClose={this.handlePropertyClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"PROPERTY QR"}
                            </DialogTitle>
                            <DialogContent>
                            <Typography variant="body2" color="primary">
                                <a
                                  href={this.state.propertyDynamicLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.propertyDynamicLink}
                                </a>
                              </Typography>
                              <DialogContentText id="alert-dialog-description">
                                <div id="property">
                                  <QRCode
                                    size={350}
                                    value={this.state.propertyDynamicLink}
                                    /* color="#5773ff" */ errorLevel="H"
                                    bgColor="#fff"
                                    style={{ marginBottom: 16 }}
                                  />
                                </div>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handlePropertyClose}>
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                autoFocus
                                color="primary"
                                onClick={() => {
                                  this.downloadQRCode("property");
                                }}
                              >
                                Download
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    )}
                    {!this.state.propertyDynamicLink && (
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => this.generateProperty()}
                        sx={{ mt: 1, mb: 2, marginLeft: "20px" }}
                      >
                        Generate Property QR
                      </Button>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6> Generate Takeaway QR</h6>
                  </div>
                  <div className="ms-panel-body"></div>

                  <Box sx={{ flexGrow: 1, margin: "15px" }}>
                    {this.state.takeAwayDynamicLink && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={this.handleTakeawayOpen}
                          >
                            VIEW QR
                          </Button>
                          <Dialog
                            open={openTakeaway}
                            onClose={this.handleTakeawayClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"TAKEAWAY QR"}
                            </DialogTitle>
                            <DialogContent>
                            <Typography variant="body2" color="primary">
                                <a
                                  href={this.state.takeAwayDynamicLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.takeAwayDynamicLink}
                                </a>
                              </Typography>
                              <DialogContentText id="alert-dialog-description">
                                <div id="takeaway">
                                  <QRCode
                                    size={350}
                                    value={this.state.takeAwayDynamicLink}
                                    /* color="#5773ff" */ errorLevel="H"
                                    bgColor="#fff"
                                    style={{ marginBottom: 16 }}
                                  />
                                </div>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleTakeawayClose}>
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                autoFocus
                                color="primary"
                                onClick={() => {
                                  this.downloadQRCode("takeaway");
                                }}
                              >
                                Download
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    )}
                    {!this.state.takeAwayDynamicLink && (
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => this.generateTakeAway()}
                        sx={{ mt: 0, mb: 2, marginLeft: "20px" }}
                      >
                        Generate QR
                      </Button>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6> Generate Dine in QR</h6>
                  </div>
                  <div className="ms-panel-body"></div>

                  <Box sx={{ flexGrow: 1, margin: "15px" }}>
                    {this.state.diningDynamicLink && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={this.handleDineInClickOpen}
                          >
                            VIEW QR
                          </Button>
                          <Dialog
                            open={openDineIn}
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"DINE IN QR"}
                            </DialogTitle>
                            <DialogContent>
                            <Typography variant="body2" color="primary">
                                <a
                                  href={this.state.diningDynamicLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.diningDynamicLink}
                                </a>
                              </Typography>
                              <DialogContentText id="alert-dialog-description">
                                <div id="dinein">
                                  <QRCode
                                    size={350}
                                    value={this.state.diningDynamicLink}
                                    /* color="#5773ff" */ errorLevel="H"
                                    bgColor="#fff"
                                    style={{ marginBottom: 16 }}
                                  />
                                </div>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleDineInClose}>
                                Close
                              </Button>
                              <Button
                                variant="contained"
                                autoFocus
                                color="primary"
                                onClick={() => {
                                  this.downloadQRCode("dinein");
                                }}
                              >
                                Download
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    )}

                    {!this.state.diningDynamicLink && (
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => this.generateDining()}
                        sx={{ mt: 0, mb: 2, marginLeft: "20px" }}
                      >
                        Generate Dine QR
                      </Button>
                    )}
                  </Box>
                </div>
              </Grid>
            </Grid>{" "}
          </React.Fragment>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
