import React, { Component } from "react";
import { toast } from "react-toastify";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  Grid,
  Typography,
  Link,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withStyles } from "@mui/styles";
import { CheckCircleOutline } from "@mui/icons-material";
import * as APIUrl from "../../../services/APIUrl";
import { withRouter } from "react-router-dom";
import adyen from "../../../assets/img/adyen.png";
import $ from "jquery";
const styles = (theme) => ({
  root: {
    //  height: '100vh',

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    //  maxWidth: 800,
    padding: theme.spacing(2),
    //  textAlign: 'left',
  },
});

class index extends Component {
  state = {
    isLoading: false,
    checked: ["wifi"],
    terms: {},
  };

  componentDidMount = () => {
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#capitalMenu").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#capitalMenu").addClass("active");
    }
    const queryParams = new URLSearchParams(this.props.location.search);
    const paramValue = queryParams.get("id");
    if (paramValue) {
      this.fetchAccountDetails(paramValue);
    } else {
      console.log("No Id retrieved.");
    }
  };

  fetchAccountDetails = async (payload) => {
    let url =
      process.env.REACT_APP_API_BASE_URL +
      APIUrl.FETCH_TERMS_CONDITIONS +
      `${payload}`;

    try {
      const response = await fetch(url); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      if (result?.status) {
        console.log(result.data);
        this.setState({ terms: result?.data });
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  render() {
    const { classes } = this.props;
    const { terms } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <Paper elevation={3} style={{ width: "100%", overflow: "auto" }}>
            <Grid container className={classes.contentContainer}>
              <Typography variant="h6" gutterBottom>
                <b> Terms and Conditions for Capital</b>
              </Typography>

              <Grid container>
                <br />
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Definitions</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography variant="h6">
                        In these Terms & Conditions, the following definitions
                        shall apply:
                      </Typography>
                      <br />
                      <Typography>
                        <Grid container spacing={1}>
                          <Grid item lg={3}>
                            <b> 3D Secure</b>
                          </Grid>
                          <Grid item lg={9}>
                            The “Three-Domain Secure” protocol developed by Visa
                            International Inc. ("Visa") branded as “Verified by
                            Visa” and “Mastercard SecureCode” developed by
                            Mastercard International Inc. ("MasterCard"),
                            including successive versions thereof and any
                            amendments thereto.
                          </Grid>
                          <Grid item lg={3}>
                            <b>Acquirer / Acquiring Bank </b>
                          </Grid>
                          <Grid item lg={9}>
                            A financial institution that is authorised by a
                            Scheme Owner to enable the use of a Payment Methods
                            by accepting Transactions from Merchants on behalf
                            of the Scheme Owners, routing these to the Scheme
                            Owners or Issuing Banks and collecting and settling
                            the resulting funds to the Merchant.
                          </Grid>
                          <Grid item lg={3}>
                            <b>Acquiring Services </b>
                          </Grid>
                          <Grid item lg={9}>
                            Use of a Payment Method via Processor where
                            Processor acts as the Acquirer or Processor
                            contracts with the relevant third party Acquirer to
                            enable the use by Merchant of such Payment Method.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Authorisation</b>
                          </Grid>
                          <Grid item lg={9}>
                            The process whereby a shopper (or Merchant on
                            shopper’s behalf) requests permission for a Payment
                            Method to be used for a particular purchase of
                            Merchant’s Service or Product.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Capture Period</b>
                          </Grid>
                          <Grid item lg={9}>
                            The period in which an Authorised Transaction can be
                            Captured. The Capture Period varies per Payment
                            Method.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Capturing</b>
                          </Grid>
                          <Grid item lg={9}>
                            The confirmation by the Merchant to the Acquirer
                            that a Transaction for which it received an
                            Authorisation is to be executed and the Account of
                            the shopper is to be actually charged for the
                            Transaction. “Capture” and “Captured” shall have the
                            corresponding meanings.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Card</b>
                          </Grid>
                          <Grid item lg={9}>
                            Any form of Credit Card or Debit Card, which may be
                            used by a shopper to carry out a Transaction on such
                            shopper’s Card account.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Card Scheme</b>
                          </Grid>
                          <Grid item lg={9}>
                            Visa, Mastercard or comparable parties providing
                            Cards.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Card Verification Method / CVM Code</b>
                          </Grid>
                          <Grid item lg={9}>
                            Vhe 3- or 4-digit numeric code that is printed on a
                            Card. This code is known as: for Visa: CVV2; for
                            Mastercard: CVC2; for American Express: CID.
                            Collectively referred to as CVM Code.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Cardholder</b>
                          </Grid>
                          <Grid item lg={9}>
                            Any person who is issued a Card and possesses and
                            uses a Card and, where required on the Card, whose
                            signature appears on the Card as an authorised user.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Chargeback </b>
                          </Grid>
                          <Grid item lg={9}>
                            A Transaction which is successfully charged back on
                            request of the shopper or the Issuer pursuant to the
                            relevant Scheme Rules resulting in a cancellation of
                            a Transaction in respect of which a Merchant has
                            been paid or was due to be paid. If a Chargeback
                            occurs for a Transaction in respect of which
                            Merchant already received Settlement of the related
                            funds, this results in the unconditional obligation
                            for the Merchant to immediately return the Settled
                            funds to Processor, to enable Processor to return
                            such funds to the Scheme Owner or Acquirer
                          </Grid>
                          <Grid item lg={3}>
                            <b> Delivery Date</b>
                          </Grid>
                          <Grid item lg={9}>
                            The date on which the complete Merchant Products and
                            Merchant Services is delivered to the shopper who
                            paid for the corresponding Transaction.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Fine</b>
                          </Grid>
                          <Grid item lg={9}>
                            Any fine, uplifted service fee or other additional
                            payment as imposed by the Scheme Owners and/or
                            Acquirers to the Merchant and/or Processor, as a
                            result of situations such as, but not limited to,
                            breach of Scheme Rules by the Merchant or caused by
                            the Merchant, excessive Fraud levels or excessive
                            Chargeback levels.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Fraud Control Tool</b>
                          </Grid>
                          <Grid item lg={9}>
                            The fraud control tool set made available by the
                            Processor as part of the Services, which assigns a
                            fraud score to Transactions indicating the
                            likelihood of the Transaction being fraudulent and
                            enables Marketplace (on behalf of the Merchant) to
                            automatically reject Transaction based on threshold
                            values set by Marketplace on behalf of the Merchant.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Issuer / Issuing Bank</b>
                          </Grid>
                          <Grid item lg={9}>
                            An institution that issues Payment Methods to the
                            shopper and whose name appears on the Card or bank
                            account statement as the Issuer or who enters into a
                            contractual relationship with the shopper with
                            respect to the Payment Method.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Marketplace</b>
                          </Grid>
                          <Grid item lg={9}>
                            The marketplace offering the Marketplace Platform
                            through which the Merchant connects with shoppers.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Marketplace Platform</b>
                          </Grid>
                          <Grid item lg={9}>
                            The e-commerce platform made available by
                            Marketplace for use by Merchant.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Merchant</b>
                          </Grid>
                          <Grid item lg={9}>
                            The party entering into a Merchant Agreement with
                            Processor and for which Processor processes
                            Transactions related to the Merchant Service and
                            Merchant Products of the Merchant.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Merchant Agreement</b>
                          </Grid>
                          <Grid item lg={9}>
                            These Terms and Conditions as agreed to in the
                            application process as well as the terms of any
                            other documents agreed or referred to in the
                            application process (including Processor’s
                            Prohibited and Restricted Products and Services List
                            (available on{" "}
                            <a
                              href="https://www.adyen.com/legal/list-restricted-prohibited"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.adyen.com/legal/list-restricted-prohibited
                            </a>{" "}
                            ) that comprise the agreement entered into between
                            Processor and the Merchant for the provision of the
                            Services to Merchant, including all Schedules and
                            other documents appended thereto by reference.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Merchant Products and Services</b>
                          </Grid>
                          <Grid item lg={9}>
                            The products/services which the Merchant is selling
                            and for which Transactions are processed.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Order Currency </b>
                          </Grid>
                          <Grid item lg={9}>
                            The Currency in which the Transaction is originally
                            offered to the shopper.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Payment Currency</b>
                          </Grid>
                          <Grid item lg={9}>
                            The Currency in which a Transaction is processed
                          </Grid>
                          <Grid item lg={3}>
                            <b> Payment Details</b>
                          </Grid>
                          <Grid item lg={9}>
                            The information which makes up a Transaction message
                            which needs to be submitted to the Payment Interface
                            to enable the processing of the Transaction by
                            Processor and to perform fraud checks, including
                            details regarding the Card, the shopper, relevant
                            authentication details and the payment amount.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Payment Interface</b>
                          </Grid>
                          <Grid item lg={9}>
                            An electronic connection method provided by
                            Processor to Marketplace and/or the Merchant for
                            providing the Payment Details for individual
                            Transactions allowing Processor to provide its
                            Services with respect thereto.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Payment Method</b>
                          </Grid>
                          <Grid item lg={9}>
                            A method of enabling payments by shoppers to
                            Merchants such as Cards, online and offline bank
                            transfers and direct debits offered by the Scheme
                            Owners.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Processor</b>
                          </Grid>
                          <Grid item lg={9}>
                            Adyen N.V., a company registered in Amsterdam under
                            number 34259528 and having its seat at Simon
                            Carmiggeltstraat 6-50, 1011 DJ in Amsterdam, the
                            Netherlands and/or any of its local affiliates (each
                            separately and together referred to as “Adyen”)
                            where required for the provision of Services in
                            accordance with local laws and Scheme Rules as
                            further provided below. Processor does not require
                            local affiliates in the United States or Europe. In
                            those jurisdictions where Adyen requires a local
                            Adyen entity to be included as an additional party
                            to the relevant Merchant Agreement, Processor shall
                            also mean the relevant local Adyen entity below, in
                            each case together with Adyen N.V.: For Merchants
                            whose legal entity is registered in Canada, Adyen
                            Canada Ltd. (“Adyen CAN”) a company registered under
                            company number C1239120 whose registered office is
                            at Suite 2600, Three Bentall Centre, 595 Burrard
                            Street, P.O. BOX 49314 Vancouver BC, V7X 1L3,
                            Canada. For Merchants whose legal entity is
                            registered in Australia, Adyen Australia Pty Limited
                            (“Adyen AUS”) a company registered in Australia
                            under company number ABN 55 162 682 411 whose
                            registered office is at 10-14 Waterloo Street, Level
                            1, Surry Hills, NSW 2010, Sydney, Australia. For
                            Merchants whose legal entity is registered in New
                            Zealand, Adyen New Zealand Limited (“Adyen NZ”) a
                            company registered in New Zealand under company
                            number NZBN 9429042218128 whose registered office is
                            at Simpson Grierson, 88 Shortland Street, Auckland,
                            1010, New Zealand. For Merchants whose legal entity
                            is registered in Singapore, Adyen Singapore Pte Ltd.
                            (“Adyen SG”) a company registered in Singapore under
                            company number 201210678M whose registered office is
                            at 109 North Bridge Road #10-22, Funan, 179097
                            Singapore. When an Adyen local entity is
                            additionally included as Processor, pursuant to the
                            above, the local Adyen entity shall be responsible
                            to fulfill all obligations locally under these Terms
                            and Conditions unless Adyen N.V. is required by law
                            to fulfill the obligation. Additional local terms
                            are included at the end of these Terms and
                            Conditions.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Refund</b>
                          </Grid>
                          <Grid item lg={9}>
                            A (partial) reversal of a particular Transaction on
                            the initiative or request of the Merchant, whereby
                            the funds are reimbursed to the shopper.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Scheme Owner</b>
                          </Grid>
                          <Grid item lg={9}>
                            The party offering and/or regulating the relevant
                            Payment Method
                          </Grid>
                          <Grid item lg={3}>
                            <b> Scheme Rule</b>
                          </Grid>
                          <Grid item lg={9}>
                            The collective set of bylaws, rules, regulations,
                            operating regulations, procedures and/or waivers
                            issued by the Scheme Owners as may be amended or
                            supplemented over time and with which Merchant must
                            comply when using the relevant Payment Method.
                            Processor may via Marketplace as service make
                            extracts and summaries of the Scheme Rules available
                            to Merchant, but only the then current applicable
                            Scheme Rules as issued by the relevant Scheme Owner
                            are binding on Merchant
                          </Grid>
                          <Grid item lg={3}>
                            <b> Service(s)</b>
                          </Grid>
                          <Grid item lg={9}>
                            The collective set of payment processing, fraud
                            control, reconciliation, reporting, Settlement and
                            other services as provided by Processor to the
                            Merchant, via Marketplace, to enable the Merchant to
                            use Payment Methods to receive payment from its
                            shoppers.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Settlement</b>
                          </Grid>
                          <Grid item lg={9}>
                            The payment of amounts owed by Processor to the
                            Merchant, owed with respect to settlements received
                            by the Processor from Acquirers or Scheme Owners for
                            Transactions validly processed for the Merchant,
                            minus the amounts for Refund and Chargebacks, fees
                            and the amounts needed to keep the Merchant Deposit
                            on the then current Deposit Level. “Settle” and
                            “Settled” shall have the corresponding meanings.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Software</b>
                          </Grid>
                          <Grid item lg={9}>
                            The collective set of programs and data developed
                            and/or operated by Processor as needed to provide
                            the Service to its Merchants, including the Payment
                            Interface.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Terms and Conditions </b>
                          </Grid>
                          <Grid item lg={9}>
                            The current version of these terms and conditions of
                            Processor.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Transaction </b>
                          </Grid>
                          <Grid item lg={9}>
                            An Authorisation request of a shopper for a payment
                            from the shopper to the Merchant submitted by
                            Merchant to Processor.
                          </Grid>
                          <Grid item lg={3}>
                            <b> Uncompleted Order Amount </b>
                          </Grid>
                          <Grid item lg={9}>
                            The total amount of Authorised, Captured and/or
                            Settled Transactions for the Merchant on any point
                            in time, for which the Merchant Products and/or
                            Merchant Services have not been delivered to the
                            relevant shoppers at that time and/or for which the
                            return rights or order cancellation rights of the
                            shopper under the terms and conditions of the
                            Merchant and/or applicable law have not yet lapsed.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Terms of use of the Service
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Permitted Merchant Products and Services
                          </Typography>
                          <Grid item lg={12}>
                            Merchant wishes to obtain the Services of Processor
                            via Platform with respect to payments for the
                            Merchant Products and Services, as agreed between
                            Processor and Platform. Merchant must ask prior
                            written approval via Platform for any change or
                            addition to the Merchant Products and Services prior
                            to submitting payment requests therefor. Merchant is
                            and remains responsible to ensure the Merchant
                            Products and Services sold are compliant with the
                            applicable Scheme Rules and applicable laws in the
                            countries it provides its products and services from
                            and the countries its customers are based in.
                            Merchant shall not use the Services for the payment
                            of Merchant Products and Services which are stated
                            in Processor’s Restricted Services and Products List
                            as shared by Platform and as available on &nbsp;
                            <a
                              href="https://www.adyen.com/legal/list-restricted-prohibited"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.adyen.com/legal/list-restricted-prohibited
                            </a>
                            . If Processor in its reasonable discretion
                            determines that there is a significant risk that the
                            Merchant Products and Services are not, or are no
                            longer, compliant with applicable laws and/or are in
                            violation of applicable Scheme Rules, Processor has
                            the right to terminate its Services.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Description of the Services
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Payment Processing
                          </Typography>
                          <Grid item lg={12}>
                            Merchant will be using the Services via the
                            Marketplace Platform. Marketplace is representing
                            Merchant towards Processor with respect to
                            Merchant’s use of the Services and Processor may
                            assume that Marketplace is authorised and mandated
                            by Merchant to use the Services, submit Transactions
                            on behalf of the Merchant and in accordance with the
                            Merchants’ permissions and instructions which
                            Marketplace will obtain from the Merchants. The
                            Merchant is obliged to ensure all data that
                            Processor requests to be provided for a Transaction,
                            including those needed for fraud checks, are
                            provided. If the Merchant fails to provide the
                            requested data with each Transaction, Processor may
                            be forced under the Scheme Rules to suspend
                            Transaction processing and/or Transactions may be
                            refused by the relevant Scheme Owners or Acquirers.
                            Processor may revise the required data needed to
                            process Transactions from time to time by informing
                            Merchant via Marketplace as needed to be able to
                            process such Transaction and conduct fraud checks
                            under applicable Scheme Rules. Marketplace is
                            required to (i) access Transaction level data to
                            provide support to Merchant at the level of
                            individual Transactions and (ii) technically manage
                            user settings of the Merchant with respect to the
                            Services on Merchant’s behalf (e.g. 3D Secure, fraud
                            control settings).
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Merchant Registration and KYC Check
                          </Typography>
                          <Grid item lg={12}>
                            In order to enable Processor to comply with
                            anti-terrorism, financial services and other
                            applicable laws and regulations and KYC (‘Know Your
                            Customer’) requirements imposed by the Scheme Owners
                            and Acquirers, Merchant must when entering into the
                            Merchant Agreement and thereafter on Processor’s
                            first request provide certain information about
                            itself and its activities (the “Registration
                            Information”). Merchant warrants unconditionally
                            that all Registration Information it provides is
                            correct and up to date. Merchant will provide
                            Processor with at least 3 business days prior
                            written notice via Marketplace of any change of the
                            Registration Information. Merchant will on first
                            request from Processor provide such additional
                            information and supporting documentation as
                            Processor may reasonably determine to need to ensure
                            compliance with applicable laws and regulations and
                            Scheme Owner and Acquirer KYC requirements. Merchant
                            agrees that Processor may run further checks on
                            Merchant’s identity, creditworthiness (including
                            consulting credit reference agencies) and background
                            by contacting and consulting relevant registries,
                            (third party) identification verification services,
                            government authorities and Merchant relations.
                            Processor will process Merchant’s Registration
                            Information in accordance with its Privacy Statement
                            (available on{" "}
                            <a
                              href="www.adyen.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              www.adyen.com
                            </a>{" "}
                            ). Processor’s acceptance of Merchant as user of the
                            Services and the relevant Payment Methods is
                            strictly personal and limited to the use by Merchant
                            of the Services for payment of Merchant’s own
                            products and services. Merchant may not use the
                            Services to facilitate the payment for products or
                            services sold by third parties and therefor may not
                            resell the Services to third parties. Merchant shall
                            only use the Services in the pursuit of its trade,
                            business, craft or profession. Support for each
                            Payment Method is subject to acceptance by the
                            relevant Scheme Owner or Acquirer used by the Scheme
                            Owner, which such Acquirer or Scheme Owner may
                            withhold or withdraw in its discretion at any time.
                            Merchant hereby authorizes Processor to submit
                            Registration Information received from Merchant to
                            the relevant Scheme Owners and Acquirers to obtain
                            permission for providing access to their Payment
                            Methods for Merchant.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Payment Method Support
                          </Typography>
                          <Grid item lg={12}>
                            Supported Payment Methods as part of the Services
                            may change from time to time. Processor will give at
                            least 1 month notice (through Marketplace) of any
                            discontinued or changed support of any Payment
                            Method, unless this is not reasonably possible given
                            the cause for this decision. Processor will use its
                            reasonable endeavours to offer an alternative for
                            any discontinued Payment Method to the Merchant.
                            Merchant understands that Acquirers and/or Scheme
                            Owners might cancel certain Payment Methods, change
                            the characteristics thereof or change the acceptance
                            criteria under which they make them available. As a
                            consequence, Processor may be forced to block
                            Merchant from further use of a Payment Method or
                            impose additional restrictions or conditions on its
                            continued use as a consequence of such decisions of
                            the relevant Acquirer and/or Scheme Owner. Where
                            possible Processor will use its reasonable efforts
                            to give Merchant prior notice (through Marketplace)
                            of any such change or cancellation with respect to a
                            Payment Method.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            3D Secure
                          </Typography>
                          <Grid item lg={12}>
                            Processor will as part of the Services support 3D
                            Secure authentication where supported by the
                            relevant Scheme Owner and/or third party Acquirer.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Payment Currency
                          </Typography>
                          <Grid item lg={12}>
                            Processor will have the right to offer the shopper
                            the option to use a different currency than the
                            Order Currency, in which case the Merchant will
                            still always receive Settlement of the Transaction
                            amount in the Order Currency (except in case another
                            currency is agreed or the Merchant does not make a
                            bank account available for Settlement in the Order
                            Currency)
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Fraud Control
                          </Typography>
                          <Grid item lg={12}>
                            All Transactions processed as part of the Services
                            will be screened by Processor’s Fraud Control Tool,
                            which performs a number of checks on a Transaction
                            and attaches a resulting total score to the
                            Transaction, which represents the likelihood of the
                            Transaction being fraudulent. The Fraud Control Tool
                            does not guarantee the prevention of fraudulent
                            Transactions, nor against resulting Chargebacks or
                            Fines. Regardless of the resulting total score,
                            Transactions may be fraudulent or non-fraudulent.
                            Platform will technically manage the settings of the
                            Fraud Control Tool on behalf of the Merchant. In
                            addition, Processor reserves the right to cancel
                            Transactions that it has reasonable grounds to
                            suspect to be fraudulent or involving other criminal
                            activities, even if the Fraud Control Tool failed to
                            block the Transaction.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Additional Services
                          </Typography>
                          <Grid item lg={12}>
                            Processor uses network analysis and machine learning
                            models for the purpose of determining whether
                            Merchant is eligible for additional services and
                            products offered by Processor. In accordance with
                            applicable laws, Processor may use automated
                            decision-making, including profiling, when Processor
                            enters into, or for the performance of, a contract
                            with Merchant for the additional services described
                            above. If such a decision would produce legal
                            effects or otherwise similarly significantly affect
                            Merchant, Merchant has the right to obtain human
                            intervention, express its point of view, opt-out of
                            the automated decision-making, or to contest the
                            ultimate decision based solely on automated
                            processing.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Settlements
                          </Typography>
                          <Grid item lg={12}>
                            Funds from the shopper charged for the validly
                            processed Transactions of the Merchant (which are
                            Acquired via Processor) are settled by the relevant
                            Acquirer or the Scheme Owner to the account(s) held
                            by Processor. The funds Processor receives from
                            payment service users or via another payment service
                            provider and held in the payment processing
                            account(s) by Processor are safeguarded in
                            accordance with the safeguarding requirements of The
                            Dutch Central Bank (De Nederlandsche Bank).
                            Processor will subsequently settle received funds
                            directly to Merchant - withholding from the received
                            funds the fees agreed between Marketplace and
                            Merchant – who is the creditor of the received funds
                            and holds sole power of disposition over such funds.
                            The settlement is based on the binding Merchants’
                            settlement instructions which are submitted by
                            Marketplace on behalf of the Merchant via
                            Marketplace Platform. Marketplace is under no
                            circumstances entitled to make any individual
                            instructions towards Processor regarding the
                            settlement of the funds. Processor is only obliged
                            to provide Settlement of Transactions for which it
                            has received settlement(s) by the Acquirer or the
                            Scheme Owner. It is Merchant's responsibility to
                            evaluate if the conditions imposed by the Payment
                            Methods for settlement (as communicated from time to
                            time by through Marketplace) are acceptable to the
                            Merchant. This is specifically relevant for certain
                            Payment Methods that are not monitored and regulated
                            by governmental financial services authorities, such
                            as but not limited to non-Card Scheme related
                            prepaid cards and SMS and IVR payments. Merchant
                            understands and agrees that Processor will not
                            compensate Merchant for late or non-performance,
                            insolvency or bankruptcy of the Acquirer or Scheme
                            Owner due to which Merchant receives late Settlement
                            or no Settlement at all for processed Transactions.
                            Processor reserves the right to withhold Settlement
                            of Transactions if they are Captured, but suspected
                            to be fraudulent, related to illegal activities, are
                            otherwise anomalous, or likely to become subject to
                            a Chargeback by Processor and/or the relevant
                            Acquirer and/or Scheme Owner, until satisfactory
                            completion of Processor's investigation, that of the
                            relevant Acquirer or Scheme Owner or that of a third
                            party nominated by any of these parties. Merchant
                            will give its full co-operation to any such
                            investigation. No interest will be due over amounts
                            held by Processor prior to Settlement of such funds
                            to the Merchant, except in case Settlement is
                            delayed for more than 30 days due to the intent or
                            gross negligence of Processor. In such case interest
                            will be due by Processor over the late Settled
                            amount at the rate of the 3 month EURIBOR rate +2%.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Required Data
                          </Typography>
                          <Grid item lg={12}>
                            Merchant shall from time to time on the first
                            request of Processor (via Marketplace) provide all
                            required information regarding the then current
                            actual or expected Delivery Dates for processed
                            Transactions and estimates for the average time
                            between Transaction Authorisation and the related
                            Delivery Date. Further, Merchant shall provide
                            Processor on its first request (via Marketplace)
                            with all requested information on Merchant’s then
                            current ability to provide the Merchant Products and
                            Services it sells by means of the Services, its
                            financial status, solvability and liquidity.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Merchant Obligations and Restrictions
                          </Typography>
                          <Grid item lg={12}>
                            Merchant may only use the Services for payment of
                            those Merchant Products and Services which Merchant
                            agreed with Marketplace to be offered through the
                            Marketplace Platform. The Merchant shall not use the
                            Services for the payment of Merchant Products and
                            Services (i) where it is illegal to offer or provide
                            these to or from the relevant country and/or (ii)
                            which are stated in Processor's Prohibited and
                            Restricted Products and Services List (available on
                            &nbsp;
                            <a
                              href="https://www.adyen.com/legal/list-restricted-prohibited"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.adyen.com/legal/list-restricted-prohibited
                            </a>{" "}
                            ). This list may be updated in Processor’s
                            discretion where needed to ensure legal compliance,
                            compliance to Scheme Rules, prevent high levels of
                            Chargebacks and/or to reduce exposure to potentially
                            fraudulent or illegal transactions. Merchant will be
                            informed of such updates by Marketplace. Where a
                            published change affects a significant portion of
                            the Merchant’s Product or Services, Merchant may
                            terminate the Merchant Agreement by giving written
                            notice to Processor (via Marketplace). Processors ́
                            acceptance of Merchant as customer should not be
                            interpreted as an advice or opinion of Processor as
                            to the legality of Merchant’s Products and Services
                            and/or of Merchant’s intended use of the Services
                            therefore. The Services of Processor should not be
                            used (and Transactions may not be submitted for
                            processing) for prepaying Merchant Products and
                            Services for which the Delivery Date is in part or
                            in whole more than 12 months after the date the
                            Transaction is submitted for processing. This can
                            make Transactions more likely to be subject to
                            Chargebacks.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            WARNING – Fines Scheme Owners
                          </Typography>
                          <Grid item lg={12}>
                            Merchant may only use the Services for payment of
                            those Merchant Products and Services which For
                            violations of certain key requirements under the
                            Scheme Rules by Merchants, some Scheme Owners (and
                            in particular the Card Schemes) can levy significant
                            Fines. The Scheme Owners do this to protect the
                            shoppers, Merchants and providers of the Payment
                            Methods collectively against misuse, fraud, illegal
                            activities, breach of applicable laws, reputational
                            damage and excessive costs. Key examples of Scheme
                            Rules which are subject to such Fines: (i) using the
                            Payment Method for other Merchant Products and
                            Services than for which the Merchant received
                            express authorisation to use it for; (ii) using the
                            Payment Method for Merchant Products and Services
                            which are violating applicable laws; (iii) using the
                            Payment Method for selling Merchant Products and
                            Services for which the Scheme Owner explicitly
                            prohibited its use (e.g. adult content, drugs, arms,
                            gambling); (iv) using the Payment Method for the
                            benefit of a third party / reselling the use of the
                            Payment Method to a third party (the authorisation
                            for Merchant to use a Payment Method is strictly
                            personal); (v) percentage of Transactions of a
                            Merchant which is subject to a Chargeback is above
                            the applicable acceptable level (vi) breaches of
                            security and confidentiality obligations with
                            respect to Payment Details (see clauses Integration
                            via Platform and Security of Payment Details for
                            more information on this); (vii) fraudulent,
                            misleading activities of which shoppers are the
                            victim. Merchant is strongly advised to regularly
                            review the then current Scheme Rules (made available
                            via Marketplace) and relevant changes to applicable
                            laws as applicable to its Merchant Products and
                            Services and business practices to ensure compliance
                            to applicable Scheme Rules. Where Merchant finds the
                            Scheme Rules (including Fine possibility) to be
                            unacceptable, Merchant is free at any point in time
                            to stop using the relevant Payment Method (the
                            Scheme Rules and these terms remain applicable to
                            previously processed Transactions for Merchant).
                            Where Processor becomes aware of and/or receives any
                            notice of a potential exposure to a Fine related to
                            any Merchant behaviour, Merchant will on first
                            request provide (via Marketplace) all reasonable
                            co-operation to help investigate the relevant
                            circumstances and remedy the relevant violation,
                            notwithstanding all other rights and remedies of
                            Processor in such situation as per these Terms and
                            Conditions. Where possible, Processor will share
                            relevant feedback received from Merchant (via
                            Marketplace) with the Acquirer/Scheme Owner handling
                            the potential Fine so it can be taken into
                            consideration by the Acquirer / Scheme Owner.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Taxes
                          </Typography>
                          <Grid item lg={12}>
                            Merchant agrees to defend, hold harmless and
                            indemnify Processor from and against any taxes,
                            including penalties, interests, surcharges due on
                            any product or service of Merchant or Marketplace
                            (including but not limited to any Transactions,
                            Merchant Products and Services) and costs or damages
                            related to such taxes. If withholding of any type of
                            taxes or levies is, or was, legally due on any
                            product or service of Marketplace or the Merchant,
                            Adyen will be entitled to withhold such taxes at the
                            expense of the Merchant. Merchant shall (i) apply
                            all reasonable efforts to ensure that Processor
                            cannot be held liable for any taxes and costs or
                            damages related to such taxes, (ii) shall promptly
                            inform Processor of any such liability and (iii)
                            shall provide Processor with all relevant
                            information and documentation in that respect.
                            Furthermore, Merchant and Marketplace shall be
                            jointly and severally liable towards Processor for
                            any such taxes and costs or damages related to such
                            taxes.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Set-Off</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Set-Off
                          </Typography>
                          <Grid item lg={12}>
                            Without prejudice to any right to set-off which
                            Processor may be entitled to as a matter of law,
                            Processor may set-off any amounts due to the
                            Merchant against any amounts owed or other
                            liabilities of the Merchant, now or at any time
                            hereafter due, owing or incurred by the Merchant to
                            Processor under, in connection to, or pursuant to
                            the Terms and Conditions and/or the Merchant
                            Agreement.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Integeration</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Integration via Marketplace
                          </Typography>
                          <Grid item lg={12}>
                            Merchant will integrate with the systems of
                            Marketplace to connect to the Payment Interface of
                            Processor. Processor is not responsible for such
                            integration but will ensure the Payment Interface is
                            available for use by Marketplace for such purpose
                            and will where needed provide third line support for
                            such integration via Marketplace.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Changes to Software
                          </Typography>
                          <Grid item lg={12}>
                            Processor reserves the right to change or amend the
                            Software and the interface to it at any time, to
                            provide the Merchant with a new version thereof,
                            and/or to change the functionalities and
                            characteristics of the Software. No changes will be
                            implemented by Processor which materially reduce
                            functionality of the Services which was explicitly
                            committed to be provided under the Merchant
                            Agreement, except where this is made necessary by:
                            (i) the need to follow generally accepted changes in
                            industry standards, (ii) changes in applicable laws
                            or Scheme Rules, (iii) need for increased security
                            due to security risks identified by Processor or
                            (iv) other reasonable grounds which warrant the
                            reduction of functionality. If Merchant is
                            significantly impacted by a material reduction of
                            functionality due to a change in the Software, it
                            may terminate the Merchant Agreement by giving
                            written notice to Processor within 1 month after
                            Processor announced the change.
                          </Grid>

                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Security of Payment Details
                          </Typography>
                          <Grid item lg={12}>
                            Merchant guarantees not to copy, capture or
                            intercept Payment Details such as credit card
                            numbers, CVM Codes, ‘PIN’ codes that are provided by
                            the shopper for payments to be processed via the
                            Services. This rule is imposed by the Scheme Owners
                            to protect shoppers against misuse of their Payment
                            Details (like credit card numbers) and is strictly
                            enforced by the Scheme Owners, and a violation of
                            this rule can lead to the application of high Fines
                            by the Schemes Owners. If Processor has reason to
                            believe that Merchant is copying, capturing or
                            intercepting Payment Details, Processor has the
                            right to suspend processing of Transactions and
                            Settlement. Merchant shall fully indemnify and hold
                            Processor harmless from any losses, claims
                            (including applied Fines by the Scheme Owners),
                            costs or damage Processor incurs as a result of
                            Merchant’s breach of this obligation.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Accordion variant="h6">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Merchant Communications-
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            All first line communications with the Merchant
                            regarding the use of the Services will run through
                            Marketplace. Via the same communication channel,
                            Processor will also inform Merchants of relevant
                            changes in applicable Scheme Rules, financial laws
                            and regulations.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        {" "}
                        Service Level Agreement
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Uptime Commitment Payment Interface
                          </Typography>
                          <Grid item lg={12}>
                            The Payment Interface is the part of Processor’s
                            payment platform which handles the processing of
                            Transactions on the moment the shopper initiates a
                            payment to Merchant. Marketplace will connect
                            Merchant to Payment Interface and Merchant will
                            submit Transactions via this Payment Interface.
                            Processor commits to use all commercially reasonable
                            efforts and to setup its systems in a manner to
                            enable an average minimum uptime of 99.9% (measured
                            on a monthly basis) of the Payment Interface, to
                            receive Transaction requests, excluding from uptime
                            calculation downtime of the Payment Interface caused
                            by acts or omissions of Merchant, Acquirers,
                            Marketplace or Scheme Owners, changes implemented on
                            specific Merchant request, general internet
                            failures, failures of individual Payment Methods or
                            force majeure. Merchant is obliged to immediately
                            notify Processor via Marketplace of any downtime of
                            the Payment Interface which it experiences and to
                            provide all reasonably requested co-operation in
                            investigating and resolving any such downtime.
                            Processor uses all reasonable efforts to avoid
                            having to take the Payment Interface offline for
                            executing planned maintenance. Should under
                            exceptional circumstances such maintenance
                            nevertheless proves necessary, Processor will
                            provide as much notice as practically possible and
                            plan such maintenance in a manner and on a date and
                            time to minimize the potential number of affected
                            potential Transactions for all its Merchants. Should
                            under emergency situations (e.g. in case of force
                            majeure event or terrorist attack) unplanned
                            maintenance be necessary to the Payment Interface
                            necessitating it to be taken offline, Processor will
                            use all available resources to keep the required
                            downtime to the absolute minimum.
                          </Grid>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Security and Compliance
                          </Typography>
                          <Grid item lg={12}>
                            Processor shall take reasonable measures to provide
                            a secure payment system and shall keep its systems
                            used to provide the Services certified in accordance
                            with the PCI-DSS security standards
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Chargebacks and Refunds
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Chargebacks
                          </Typography>
                          <Grid item lg={12}>
                            Merchant shall take all reasonable steps to ensure
                            that the Merchant Products and Services are actually
                            delivered in accordance with applicable laws and
                            orders placed by the shopper, to avoid any
                            Chargebacks. In case Processor has reasons to
                            suspect that Merchant is not delivering its Merchant
                            Products and Services on or prior to the Delivery
                            Dates used to calculate the Uncompleted Order Amount
                            and/or if Processor has reason to suspect that the
                            Merchant Products and Services for which Processor
                            processes Transactions are based on fraud, likely to
                            cause high Chargeback volumes and/or illegal,
                            Processor has the right to suspend Settlement of all
                            related Transactions and/or block Authorisations
                            therefor until Processor has been given assurances
                            to its satisfaction that the relevant Merchant
                            Products and Services are actually delivered in
                            accordance with applicable laws and orders placed by
                            the shopper.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Refunds
                          </Typography>
                          <Grid item lg={12}>
                            Refunds will be charged as a Transaction by
                            Processor and a Refund fee can be applied by
                            Processor as agreed between Processor and
                            Marketplace, if manual intervention is needed or
                            additional costs are incurred by Processor to
                            process such Refund. Processor will not execute a
                            Refund (meaning the relevant sum will not be
                            returned by Processor to the relevant shopper,
                            directly or via the relevant Acquirer / Scheme
                            Owner) in case the funds for this cannot be
                            subtracted from the next Settlement or are not
                            funded otherwise. Refunds are not funded by
                            Processor from its own means. 9-Property Rights
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Property Rights</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Property Rights
                          </Typography>
                          <Grid item lg={12}>
                            The property rights in the Software and other
                            materials and all other intellectual property rights
                            related to the Services are owned by Processor and
                            its licensors. The Merchant Agreement does not
                            transfer any intellectual property rights with
                            respect thereto and only provides Merchant a
                            limited, non-exclusive and non-transferable license
                            to use the Software and all other materials made
                            available by Processor solely for the purpose of
                            using the Services in accordance with these terms
                            and the applicable usage instructions communicated
                            to Merchant via Processor website from time to time.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            User of name and logo
                          </Typography>
                          <Grid item lg={12}>
                            Merchant may on its website in the section
                            “frequently asked questions”, or in a similar
                            informational section on its website, refer to Adyen
                            as its payment service provider, explaining that
                            this is the reason why the name Adyen may appear on
                            bank statements of the customer. Merchant may also
                            include an internet link to the website of Adyen in
                            such context. Merchant may not mention Adyen on the
                            home page of its website in any situation. Merchant
                            must in each case also clearly state that customers
                            of Merchant should not contact Adyen for support or
                            questions regarding payments processed by Adyen for
                            Merchant. Merchant may not use the logo of Adyen
                            anywhere on its website without the prior express
                            written approval of Adyen, which Adyen may refuse or
                            withdraw in its discretion.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Confidentiality, Privacy and Compliance
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Confidentiality
                          </Typography>
                          <Grid item lg={12}>
                            All information relating to the Merchant or to
                            Processor and designated as being confidential, and
                            all information not expressly designated as
                            confidential but which should reasonably be deemed
                            confidential by reason of its nature or content is
                            considered “Confidential Information”. Each party
                            remains the owner of all data made available to the
                            other party. Each party undertakes to take all
                            necessary steps to protect the confidential nature
                            of all Confidential Information of the other party,
                            agreeing, in particular:
                            <ol>
                              <li>
                                to share Confidential Information solely with
                                personnel and representatives of the parties
                                (including Marketplace) which have a need to
                                have access to such information in order to
                                exercise rights and obligations under the
                                Merchant Agreement; and
                              </li>
                              <li>
                                to refrain from making any Confidential
                                Information available to any third party without
                                the prior written consent of the other party
                                except for Processor where necessary to perform
                                the Services
                              </li>
                            </ol>
                            <Typography variant="h6">
                              The obligation to maintain confidentiality does
                              not apply to information:
                            </Typography>
                            <ol>
                              <li>available to the general public;</li>
                              <li>
                                disclosed to one of the parties by a third party
                                without any obligation of confidentiality;
                              </li>
                              <li>
                                already in the possession of or known to one of
                                the parties at the time of disclosure;
                              </li>
                              <li>
                                developed independently of the Confidential
                                Information by the other party; or
                              </li>
                              <li>
                                disclosed to one of the parties by a third party
                                without any obligation of confidentiality;
                              </li>
                              <li>
                                f and to the extent to one of the parties and/or
                                their employees are obliged under an act or by
                                decision of a court or administrative authority
                                to disclose such information
                              </li>
                            </ol>
                            <Typography variant="h6">
                              The obligation of confidentiality as described in
                              this clause shall remain in effect also following
                              the termination of the Merchant Agreement,
                              regardless of the grounds for termination. The
                              following data is to be considered confidential,
                              without need for special mention:
                            </Typography>
                            <ol>
                              <li>all financial data;</li>
                              <li>
                                the any agreed Merchant specific terms and
                                conditions in the Merchant Agreement, if
                                applicable; and
                              </li>
                              <li>
                                all user manuals, guides and any Software
                                relating to Processor’s products and services.
                              </li>
                            </ol>
                          </Grid>

                          <Grid item lg={12}>
                            The recipient of the Confidential Information may
                            only use the Confidential Information for the
                            purposes of performing activities related to the
                            Merchant Agreement. Processor, Marketplace and
                            Merchant may use de-identified and/or aggregate
                            transaction-related data for various purposes,
                            including but not limited to analysing, tracking,
                            and comparing transaction and other data to develop
                            and provide insights for Marketplace, Merchant
                            and/or others as well as for developing, marketing,
                            maintaining and/or improving such party’s products
                            and services
                          </Grid>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Privacy
                          </Typography>
                          <Grid item lg={12}>
                            Where Processor acts under the instructions of the
                            Merchant and/or Marketplace, Processor processes
                            personal data while performing the Services as data
                            processor or sub-processor under the direction and
                            responsibility of the Merchant and/or Marketplace in
                            accordance with applicable data protection laws,
                            including the EU General Data Protection Regulation
                            2016/679, and any successor thereof. Processor acts
                            as a data controller where Processor sets its own
                            purpose and means of processing, such as the
                            Acquiring Services and processing personal data for
                            KYC purposes, in accordance with the Adyen Privacy
                            Statement (available on{" "}
                            <a
                              href="https://www.adyen.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              www.adyen.com
                            </a>
                            ), as amended from time to time. Merchant will
                            comply with the personal data protection laws of the
                            Merchant’s country of origin and of those countries
                            in which the Merchant offers its goods and/or
                            services from time to time, in particular when
                            processing and sending personal data to Processor in
                            the context of using the Services and submitting
                            transactions. Both Processor and Merchant shall
                            implement appropriate measures to protect personal
                            data against misuse. Processor shall use personal
                            data processed by Processor for Merchant under the
                            Merchant Agreement for the purpose of providing the
                            Services, including any additional ancillary
                            services, and complying with applicable laws and
                            Scheme Rules. Processor may use de-identified and/or
                            aggregate transaction-related data for various
                            purposes, including but not limited to analysing,
                            tracking, and comparing transaction and other data
                            to develop and provide insights, for fraud
                            prevention, risk assessment, payment performance, as
                            well as for developing, marketing, maintaining
                            and/or improving Processor’s products and services.
                            <Typography variant="h6">
                              Use of Services
                            </Typography>
                            In accordance with Dutch laws on hacking and
                            computer crime, the Merchant shall only use the
                            Services for the purposes as agreed herein and shall
                            specifically not perform or allow to be performed
                            any actions detrimental to the security or
                            performance of the Services without Processor’s
                            prior written consent.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Duration and Cancellation
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Duration
                          </Typography>
                          <Grid item lg={12}>
                            Except where explicitly agreed otherwise in the
                            Merchant Agreement, the Merchant Agreement shall be
                            entered into for an indefinite period until it is
                            terminated by either party by giving at least 2
                            months’ written notice to the other party. Such
                            notices may be given via Marketplace by either
                            party.
                          </Grid>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Termination
                          </Typography>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Merchant has the right to terminate the Merchant
                            Agreement immediately if:
                          </Typography>
                          <ol>
                            <li>
                              The Service availability in any given calendar
                              month is less than 95%; and/or{" "}
                            </li>
                            <li>
                              The SLA commitments in clause Service Level
                              Agreement are not met during 3 consecutive months.
                            </li>
                          </ol>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Processor has the right to terminate the Merchant
                            Agreement and/or stop processing or Settlement of
                            Transactions for Merchant immediately in part or in
                            whole if:
                          </Typography>
                          <ol>
                            <li>
                              TThe provision of Merchant''s Services/Products
                              for which Processor provides the Service is
                              reasonable suspected by Processor to be in breach
                              with legislation in the country where the Merchant
                              Services/Products are offered from or to.{" "}
                            </li>
                            <li>
                              Merchant has materially changed the type of
                              Merchant Services/Products without obtaining
                              Processor’s prior written permission to use the
                              Services for the new or changed types of Merchant
                              Services/Products;
                            </li>
                            <li>
                              Merchant materially breaches any of the terms of
                              the Merchant Agreement, the Scheme Rules and/or
                              applicable laws in the context of using the
                              Services
                            </li>
                            <li>
                              An Acquirer or Scheme Owner demands Processor to
                              stop or suspend providing Services to Merchant
                              with respect to Payment Methods made available by
                              such Acquirer or Scheme Owner to Processor.
                            </li>
                            <li>
                              Processor finds there are clear indications that
                              Merchant is or is likely to become insolvent
                              and/or unable to provide a material part of the
                              Merchant Products and Services. or.
                            </li>
                            <li>
                              The agreement the Merchant has entered into with
                              Marketplace for the use of the services of
                              Marketplace has been terminated or has otherwise
                              expired and/or an event permitting a termination
                              by Marketplace under any such agreement occurs.
                            </li>
                          </ol>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Liability</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            No Liability for Third Parties
                          </Typography>
                          <Grid item lg={12}>
                            Processor shall only be liable for its own acts or
                            omissions and not for acts or omissions of third
                            parties. This exclusion expressly applies to acts or
                            omissions of Scheme Owners and Acquirers or for
                            events or activities originating outside the systems
                            of Processor (such as internet disturbances or
                            malfunctions in third party systems), except in case
                            such events were caused by the intent or gross
                            negligence of Processor.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Limitation of Liability
                          </Typography>
                          <Grid item lg={12}>
                            The total liability of Processor under the Merchant
                            Agreement towards Merchant for breach of contract,
                            tort or under any other legal theory in any calendar
                            year is limited to an amount equal to 10,000 euro.
                            Processor shall not be liable for any special,
                            indirect, or consequential damages (including any
                            loss of profit, business, contracts, revenues or
                            anticipated savings, or damage to good name) as a
                            result of breach of contract, tort or under any
                            other legal theory. Neither Processor, Merchant nor
                            any other party to the Merchant Agreement excludes
                            or limits its liability under the Merchant Agreement
                            for intent, gross negligence, death, fraud or
                            personal injury.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">Indemnification</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            If any claims for damages, costs and expenses are
                            asserted against Merchant by third parties asserting
                            that these third parties are the owner of any rights
                            regarding the Software and/or systems of Processor,
                            Processor shall indemnify Merchant without delay
                            from these third-party claims, including Merchants
                            reasonable costs of its legal defence, and offer
                            Merchant the necessary assistance in its legal
                            defence. Merchant shall indemnify and hold Processor
                            harmless from any claim (including legal fees)
                            brought against Processor by any third party
                            (expressly including Scheme Owners and Acquirers and
                            their claims for payments of Fines) as a result of
                            Merchant’s breach of the terms of the Merchant
                            Agreement, applicable laws and/or the Scheme Rules
                            applying to the Payment Methods used by Merchant.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">General Provisions</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Transfer
                          </Typography>
                          <Grid item lg={12}>
                            Processor shall be entitled, at any time, to assign,
                            novate or otherwise transfer the Merchant Agreement
                            to another company in the Processor Group, i.e., a
                            company with at least 50% the same shareholders,
                            without the prior consent of the Merchant by
                            providing written notice to Merchant of such
                            transfer.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Null Provisions
                          </Typography>
                          <Grid item lg={12}>
                            In the event that any provision in the Merchant
                            Agreement (including these Terms and Conditions) is
                            declared null and void or inapplicable, said
                            provision shall be deemed non- existent, and all
                            other provisions of the Merchant Agreement
                            (including these Terms and Conditions) shall remain
                            applicable. The parties undertake to take all steps
                            to eliminate the provision declared null and void
                            and/or inapplicable and to replace the same with a
                            provision approaching, insofar as possible, the
                            economic objective of the provision declared null
                            and/or inapplicable.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Entire Agreement
                          </Typography>
                          <Grid item lg={12}>
                            The Merchant Agreement, including any amendments
                            thereto, contains all the commitments between the
                            parties and replaces all other prior contractual
                            commitments between the parties. No representation,
                            warranty or undertaking given by any of the parties
                            to any of the other parties under the Merchant
                            Agreement shall be of any force or effect unless
                            expressly reduced to writing and repeated in the
                            Merchant Agreement, and all implied or prior
                            representations, warranties and undertakings are,
                            save to the extent expressly set out in the Merchant
                            Agreement, expressly excluded to the fullest extent
                            permitted by law.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Changes to the Terms and Conditions
                          </Typography>
                          <Grid item lg={12}>
                            Processor may revise these Terms and Conditions from
                            time to time by giving at least 30 days written
                            notice to the Merchant via Platform. If the change
                            has a material adverse impact on Merchant and
                            Merchant does not agree to the change, Merchant may
                            terminate the Merchant Agreement by giving at least
                            one month written notice to Processor (such
                            termination notice to be sent at the latest 60 days
                            after Merchant received notice of the change).
                            Merchant is not entitled to object to and shall not
                            have the rights set out in this clause for any
                            change which Processor implements in order to comply
                            with applicable law, regulations or requirements
                            imposed by the relevant Acquirers and/or Scheme
                            Owners or financial regulators. For such imposed
                            changes shorter notice periods may be applied by
                            Processor as is need to comply with the relevant
                            requirement.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Online Contracting – written confirmation
                          </Typography>
                          <Grid item lg={12}>
                            In case Merchant has concluded the Merchant
                            Agreement with Processor via Marketplace's website
                            or via any other online means, Processor may at any
                            time request that Merchant re- confirms its
                            acceptance of the terms of the Merchant Agreement
                            (including these Terms and Conditions) by means of a
                            written document signed by an authorised
                            representative of the Merchant. If Merchant does not
                            comply with such request within 5 working days after
                            receiving a request by Processor to do so (which
                            request may be issued to Merchant via the contact
                            email address submitted by Merchant when concluding
                            the Merchant Agreement), Processor reserves the
                            right to suspend part or all of the Services until
                            Merchant has complied with such request.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            {" "}
                            Translations
                          </Typography>
                          <Grid item lg={12}>
                            The terms of the Merchant Agreement, including these
                            Terms and Conditions are made available to you in
                            English. Any other terms or disclosures made
                            available to you regarding this Merchant Agreement
                            in any non-English language do not form part of our
                            agreement and are for informational purposes only.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Settlement of Disputes
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Dispute Handling
                          </Typography>
                          <Grid item lg={12}>
                            The parties undertake to take all steps to reach an
                            amicable agreement to any dispute arising in
                            relation to the validity, interpretation or
                            fulfilment of the Merchant Agreement. This Article
                            is without prejudice to a party's right to seek
                            interim relief against any other party (such as an
                            injunction) through the competent courts to protect
                            its rights and interests, or to enforce the
                            obligations of any of the other parties.
                          </Grid>
                          <Typography sx={{ pl: 1, pt: 1 }} variant="h6">
                            Applicable Law and Jurisdiction
                          </Typography>
                          <Grid item lg={12}>
                            The Merchant Agreement and these Terms and
                            Conditions are solely governed by Dutch law,
                            excluding the Convention on Contracts for the
                            International Sale of Goods. In the absence of an
                            amicable agreement, any dispute relating to the
                            validity, interpretation or fulfilment of the
                            Merchant Agreement shall be submitted to the
                            exclusive jurisdiction of the competent courts of
                            Amsterdam the Netherlands.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Europe Specific terms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Applicability of Payment Services Directive
                          </Typography>
                          <Grid item lg={12}>
                            itle 7B of Book 7 of the Dutch Civil Code
                            (Burgerlijk Wetboek) and other laws and regulations
                            implementing Directive (EU) 2015/2366 (“PSD2”) or
                            its predecessor, Directive 2007/64/EC ("PSD"), are
                            not applicable to the extent it is permitted to
                            deviate from relevant provisions in relationships
                            with non-consumers, in accordance with Article 38
                            and 61 PSD2 (or Article 30 and 41 PSD).Where
                            Processor provides payment services for the Merchant
                            within the European Economic Area ("EEA") and where
                            the shopper''s payment service provider is located
                            in the EEA, the parties hereby agree and confirm in
                            accordance with article 62(2) of PSD2 that the
                            Merchant shall pay the charges levied by Processor
                            and the shopper shall pay the charges levied by his
                            payment service provider (i.e. the ‘SHA’ (shared)
                            principle).
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Canada Specific Terms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            {" "}
                            Canadian regulatory disclosures
                          </Typography>
                          <Grid item lg={12}>
                            The mandatory Canadian regulatory disclosures under
                            this agreement are available at &nbsp;&nbsp;
                            <a
                              href="https://www.adyen.com/platform/licenses/canada-information-summary-box"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.adyen.com/platform/licenses/canada-information-summary-box
                            </a>
                            <p>
                              <b>English Language </b>
                            </p>
                            It is the express will of the parties that this
                            Agreement and all related documents, including
                            notices and other communications, be drawn up in the
                            English language only. Il est la volunté expresse
                            des parties que cette convention et tousles
                            documents s’y rattachant, y conpris les avis et les
                            autre communications, soient rédigés et signés en
                            anglais seulement.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Australia Specific Terms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Australian disclosures
                          </Typography>
                          <Grid item lg={12}>
                            Merchant is advised to read and consider the{" "}
                            <a
                              href="https://www.adyen.com/legal/combined-financial-services-guide"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Combined Financial Services Guide and Product
                              Disclosure Statement
                            </a>{" "}
                            before acquiring or using the Service. The
                            safeguarding requirements of The Dutch Central Bank
                            (De Nederlandsche Bank) referred to in clause above
                            shall not apply to Transactions processed in
                            Australia.Surcharging on Payment Methods in
                            Australia Merchant in Australia will not impose a
                            surcharge or any other fee on the relevant Payment
                            Methods that exceeds the amount the Merchant pays
                            for that Payment Method as a percentage of the total
                            price.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="h6">
                        Singapore Specific Terms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#EAF7FF" }}
                      sx={{ pt: 3 }}
                    >
                      <Typography>
                        <Grid container spacing={1}>
                          <Typography sx={{ pl: 1 }} variant="h6">
                            Singapore Safeguarding
                          </Typography>
                          <Grid item lg={12}>
                            Services that are regulated under the Singapore
                            Payment Services Act 2019 shall be provided by Adyen
                            SG only. Funds which Adyen SG has received as
                            settlement for processed payment transactions for
                            Merchant from the relevant third party Acquirers or
                            Scheme Owners in respect of the Service(s) that are
                            not Settled to Merchant or any other party by the
                            end of the same business day (“Relevant Funds”),
                            will be deposited in bank accounts maintained with a
                            licensed bank in Singapore held in the name of Adyen
                            SG on trust for and on behalf of Merchant. Relevant
                            Funds held in such trust accounts are segregated
                            from Adyen SG''s own funds and will be commingled
                            with similar funds belonging to other merchants and
                            held on an omnibus basis. The risks of such
                            commingling include a shortfall attributable to such
                            trust account (if any) being shared rateably among
                            the merchants. Nevertheless, Adyen SG will in each
                            case remain responsible to settle the Relevant Funds
                            to the Merchant in accordance with the Merchant
                            Agreement. If the licensed bank were to become
                            insolvent, in accordance with Singapore banking
                            laws, Merchant would rank as general unsecured
                            creditors for any amounts owing to them. For the
                            avoidance of doubt, the safeguarding requirements of
                            The Dutch Central Bank (De Nederlandsche Bank)
                            referred to in clause above shall not apply to
                            Transactions processed in Singapore.
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              {/* <Typography variant="body2" gutterBottom>
                To view the full terms and conditions, please visit the website.

              </Typography> */}
            </Grid>
            <Typography
              variant="body2"
              style={{
                position: "fixed",
                bottom: "10px",
                right: "10px",
                display: "flex",
                alignItems: "center",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              Powered by{" "}
              <img
                style={{ width: "100px", marginLeft: "5px" }}
                src={adyen}
                alt="Adyen Logo"
              />
            </Typography>
          </Paper>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(index));
