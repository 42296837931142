import React, { useRef, useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Box, Stack, Button } from "@mui/material";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import { getCurrencySymbol } from "../../../config";
import { generateBarcode } from "../../../services/product/item/ItemServiceAPI";
import { Select, MenuItem, Grid, FormControl, InputLabel } from '@mui/material';
import "./printStyles.css";
import { propsToClassKey } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
function ItemBarcode({ item, itemId, propertyId, showBarCodeTextField, showBarCardField }) {
  const [barcodeId, setBarcodeId] = useState(item?.item_bar_code);
  const [selectedAction, setSelectedAction] = useState('');
  const [open, setOpen] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const genBarcode = () => {
    generateBarcode({
      property_id: propertyId,
      item_id: itemId,
    }).then((res) => {
      if (res?.data?.status) {
        setBarcodeId(res?.data?.data?.barcode);
        showBarCodeTextField(true);
      }
    });
  };
  const addBarcode = () => {
    console.log("add Barcode.");
    showBarCodeTextField(false);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setOpen(false);
    setSelectedAction(value);

    if (value === 'add') {
      addBarcode();
    } else if (value === 'generate') {
      genBarcode();
    }
  };

  return (
    <>
      {barcodeId ? (
        <>
          <Box ref={componentRef} maxWidth={"384px"}>
            <Stack p={1} spacing={0.2}>
              <Typography lineHeight={1} variant="h6">
                {item?.item_identifier}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Barcode margin={2} width={3} value={barcodeId} />
                <Box p={1} border="1px solid #000000" borderRadius="5px">
                  <Typography noWrap variant="h6" fontSize="28px">
                    {getCurrencySymbol(localStorage.getItem("currency_code")) +
                      item?.item_cost}{" "}
                  </Typography>
                </Box>
              </Stack>
              <Typography lineHeight={1} variant="h6">
                {item?.item_description}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            sx={{ float: "right", m: 2 }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </>
      ) : (
        <>
          <React.Fragment>
            <Grid item xs={12} md={8}>
              <FormControl
                fullWidth
              >
                <InputLabel id="barcode-action-label">
                  Barcode Action
                </InputLabel>
                <Select
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  labelId="barcode-action-label"
                  id="barcode-action-label"
                  name="abarcode-action-label"
                  input={
                    <OutlinedInput
                      id="abarcode-action-label"
                      label="arcode Action"
                    />
                  }
                  value={selectedAction}
                  defaultValue={selectedAction}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust the height as needed
                      },
                    },
                    disableScrollLock: true,
                  }}
                  className="formControl"
                  onChange={handleChange}
                >
                  <MenuItem value="add">Add Barcode</MenuItem>
                  <MenuItem value="generate">Generate Barcode</MenuItem>
                </Select>

              </FormControl>
            </Grid>
          </React.Fragment>

        </>
      )}
    </>
  );
}

export default ItemBarcode;
