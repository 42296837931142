import React, { Component, Fragment } from "react";
import { Grid, Typography, Box } from "@mui/material";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { connect } from "react-redux";
import { Card } from "antd";
import PricingCard from "./PricingCard";
import { withRouter } from "react-router-dom";
import Loader from "../../shared/Loader";
import * as PlanServiceAPI from "../../services/plans/PlanServiceAPI";
import AntSwitch from "../../muiComponents/Switch/AntSwith";


const { Meta } = Card;
class Content extends Component {
  state = {
    isAnnually: false,
    plans: [],
    isActive: false,
    activePlan: {},
  };

  label = { inputProps: { "aria-label": "Switch demo" } };
  componentDidMount = () => {
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
    this.fetchPlansStatus();
  };

  fetchPlansStatus = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchPlansStatus(propertId);

      if (response.data.status) {
        this.setState({ activePlan: response.data.data });
        this.fetchAllPlans()
        if (response.data.data?.subscription_status == "ACTIVE") {
        /*   this.props.history.push({
            pathname: "/manage-qr-flow",
          }); */
        }
        ;
      } else {
        this.fetchAllPlans();
        this.setState({ isActive: false });
      }
    } catch (error) {
      this.setState({ isActive: false, isLoading: false });
      throw error;
    }
  };

  fetchAllPlans = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await PlanServiceAPI.fetchAllPlans(propertId);

      if (response.data.status) {
        const sortedPlans = response.data?.data.sort((a, b) => a.sort_order - b.sort_order);

        const targetIndex = sortedPlans.findIndex(item => item.qr_plan_id === this.state.activePlan.qr_plan_id);

        // Check if the item is found
        if (targetIndex !== -1) {
          // Get the sort_order of the target item
          const sortOrder = sortedPlans[targetIndex].sort_order;

          // Update the other objects based on sort_order
          sortedPlans.forEach(item => {
            if (item.sort_order < sortOrder) {
              item.buttonText = "Downgrade";
            } else if (item.sort_order > sortOrder) {
              item.buttonText = "Upgrade";
            } else  {
              item.buttonText = "Active";
            }
          });
        } else {
          sortedPlans.forEach(item => {

            item.buttonText = "Buy";

          });
        }
        console.log(sortedPlans)
        this.setState({ plans: sortedPlans, isLoading: false });
      } else {
        this.setState({ plans: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ plans: [], isLoading: false });
      throw error;
    }
  };

  handleChange = (e) => {
    this.setState({ isAnnually: e.target.checked });
  };

  render() {
    const { plans, isAnnually, isActive, activePlan } = this.state;
    return (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          {this.state.isLoading ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "40px",
              }}
            >
              {" "}
              <Loader size={50} />{" "}
            </div>
          ) : (
            // activePlan?.subscription_status == "ACTIVE" ||
            // (Object.keys(activePlan).size !== 0 && (
              <React.Fragment>
                <div className="col-md-12">
                  <Breadcrumb />
                </div>
                <div className="">
                  <div className="">
                    <Typography
                      color="text.primary"
                      sx={{
                        textAlign: "center",
                        fontSize: "32px",
                        fontWeight: "600",
                      }}
                    >
                      Plans & Pricing
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      Find a plan that suits your needs.
                    </Typography>
                    <Box sx={{ my: 4 }}>
                      <AntSwitch
                        offLabel="Monthly"
                        onLabel="Annually"
                        checked={this.state.isAnnually}
                        onChange={this.handleChange}
                      />
                    </Box>
                    <Grid container spacing={2} style={{ textAlign: "center",justifyContent:"center" }}>
                      {plans.map((plan, index) => (
                        <Grid item key={index} xs={12} sm={6} lg={3}>
                          <PricingCard
                            {...plan}
                            isAnnually={isAnnually}
                            propertyId={this.props.propertyId}
                            activePlan={activePlan}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </React.Fragment>
            // ))
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(Content));
