import axios from 'axios';
import * as APIUrl from '../APIUrl'

export const signup = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.SIGNUP,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const addUser = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_USER,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchUsers = (propertyId) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_USERS + propertyId,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const updateUsers = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.UPDATE_USER + `${payload.property_id}&username=${payload.username}&user_pin=${payload.user_pin}&status=${payload.status}&name=${payload.name}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateUser = (payload) => {

    try {
        return axios({
            method: 'put',
            url: APIUrl.UPDATE_USER_STATUS + `${payload.property_id}&username=${payload.username}&user_pin=${payload.user_pin}&status=${payload.status}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}
export const recreateUser = (email) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.RECREATE_USER + `${email}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const confirmSignup = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.CONFIRM_SIGNUP,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const addBusinessPlace = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_BUSINESS_PROPERTY,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const createLegalEntity = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_LEGAL_ENTITY,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateLegalEntity = (payload) => {
    try {
        return axios({
            method: 'patch',
            url: APIUrl.UPDATE_LEGAL_ENTITY,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const createStore = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.ADD_STORE + payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const createBusinessLine = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.CREATE_BUSINESS_LINE,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateBusinessLine = (payload) => {
    try {
        return axios({
            method: 'patch',
            url: APIUrl.UPDATE_BUSINESS_LINE,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const createAccountHolder = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.ADD_ACCOUNT_HOLDER,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateAccountHolder = (payload) => {
    try {
        return axios({
            method: 'patch',
            url: APIUrl.UPDATE_ACCOUNT_HOLDER,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}



export const createBalanceAccount = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.CREATE_BALANCE_ACCOUNT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateBalanceAccount = (payload) => {
    try {
        return axios({
            method: 'patch',
            url: APIUrl.UPDATE_BALANCE_ACCOUNT,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchLegalEntity = (propertyId) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_LEGAL_ENTITY + propertyId,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const generateOnboardingLink = (legalEntityId) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.CREATE_ADYEN_ONBOARDING_LINK + legalEntityId,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAydenStatus = (legalEntityId) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_AYDEN_STATUS + legalEntityId,
        })
    } catch (error) {
        throw new Error(error)
    }
}



export const updateBusinessPlace = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_BUSINESS_PROPERTY,
            data: payload,
        })
    } catch (error) {
        console.log('error loggin in:', error)
        throw new Error(error)
    }
}

export const fetchBusinessPlace = (username) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_BUSINESS_PROPERTY + username,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const signin = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.SIGNIN,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const checkEmail = (payload) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.CHECKEMAIL + payload,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchAllCurrencies = () => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_ALL_CURRENCIES,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const forgotPassword = (email) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.FORGOT_PASSWORD + email,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchGoogleAPIKey = () => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_GOOGLE_API_KEY,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchLatitudeAndLongitudeByAddress = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_LATITUDE_AND_LONGITUDE_BY_ADDRESS + `${payload.property_address}&city=${payload.property_city}&country=${payload.property_country}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchBusinessType = () => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_BUSINESS_TYPE,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const confirmForgotPassword = (payload) => {

    try {
        return axios({
            method: 'post',
            url: APIUrl.CONFIRM_FORGOT_PASSWORD,
            data: payload,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const resendOTP = (username) => {

    try {
        return axios({
            method: 'get',
            url: APIUrl.RESEND_OTP + username
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const isMFAActivated = (email) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_MFA_DETAILS_BY_EMAIL + email,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const needPasswordChange = (email) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.NEED_PASSWORD_CHANGE_BY_EMAIL + email,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const needPasswordChangeUpdate = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.NEED_PASSWORD_CHANGE_UPDATE,
            data: payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchTermsAndConditionAcceptance = (payload) => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_TERMS_AND_CONDITION_ACCEPTANCE+`${payload.property_id}`
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const fetchTermsAndCondition = () => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.FETCH_TERMS_AND_CONDITION,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateTermsAndConditionStatus = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.UPDATE_TERMS_AND_CONDITION_STATUS + `${payload.tnc_status}&tnc_id=${payload.tnc_id}&username=${payload.username}&property_id=${payload.property_id}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const verifyToken = () => {
    try {
        return axios({
            method: 'get',
            url: APIUrl.VERIFY_TOKEN,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const resetPassword = (payload) => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.RESET_PASSWORD,
            data: payload
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const signout = () => {
    try {
        return axios({
            method: 'post',
            url: APIUrl.SIGNOUT,
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteCoverImage = (payload) => {
    try {
        return axios({
            method: 'delete',
            url: APIUrl.DELETE_COVER_IMAGE+ `${payload.property_id}&cover_image_url=${payload.cover_image_url}`,
        })
    } catch (error) {
        throw new Error(error)
    }
}