import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Breadcrumb extends React.Component {
    render() {
        const { platformName } = this.props;
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/dashboard"><i className="material-icons">home</i> Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/setting">Setting</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/all-discounts">Discounts</Link>
                    </li>
                    {platformName && (
                        <li className="breadcrumb-item active" aria-current="page">{platformName}</li>
                    )}
                </ol>
            </nav>
        );
    }
}

Breadcrumb.propTypes = {
    platformName: PropTypes.string
};

export default Breadcrumb;
