import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import $ from "jquery";
import Breadcrumb from "./Breadcrumb";
import { Jumbotron, Container } from "react-bootstrap";
import ApiIcon from "@mui/icons-material/Api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Typography from "@mui/material/Typography";
import { settings } from "../../config";
import step_1 from "../../assets/img/guide/step_1.png";
import step_2 from "../../assets/img/guide/step_2.png";
import step_3 from "../../assets/img/guide/step_3.png";
import step_4 from "../../assets/img/guide/step_4.png";
import step_5 from "../../assets/img/guide/step_5.png";
import step_6 from "../../assets/img/guide/step_6.png";
import step_7 from "../../assets/img/guide/step_7.png";
import step_8 from "../../assets/img/guide/step_8.png";
import step_9 from "../../assets/img/guide/step_9.png";
import step_10 from "../../assets/img/guide/step_10.png";
import step_11 from "../../assets/img/guide/step_11.png";
import step_12 from "../../assets/img/guide/step_12.png";
import step_13 from "../../assets/img/guide/step_13.png";
import step_14 from "../../assets/img/guide/step_14.png";
import step_15 from "../../assets/img/guide/step_15.png";
import step_16 from "../../assets/img/guide/step_16.png";
import step_17 from "../../assets/img/guide/step_17.png";
import step_18 from "../../assets/img/guide/step_18.png";
import step_19 from "../../assets/img/guide/step_19.png";
import step_20 from "../../assets/img/guide/step_20.png";
import step_21 from "../../assets/img/guide/step_21.png";
import step_22 from "../../assets/img/guide/step_22.png";
import step_23 from "../../assets/img/guide/step_23.png";
import step_24 from "../../assets/img/guide/step_24.png";
import step_25 from "../../assets/img/guide/step_25.png";
import step_26 from "../../assets/img/guide/step_26.png";
import step_27 from "../../assets/img/guide/step_27.png";
import step_28 from "../../assets/img/guide/step_28.png";
import step_29 from "../../assets/img/guide/step_29.png";
import step_30 from "../../assets/img/guide/step_30.png";

class Content extends Component {
  componentDidMount = () => {
    function setActiveMenuItem() {
      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#guides").addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
      $("#guides").addClass("active");
    }
  };

  render() {
    const tabs = [
      {
        title: "General",
        link: "/general",
        description: "View and update your store details",
        icon_name: "ac_unit",
      },
      {
        title: "Timing",
        link: "/app-timing",
        description: "Update your business timings here.",
        icon_name: "access_time",
      },
      {
        title: "Payments",
        link: "/payment",
        description: "Payment methods that are active. ",
        icon_name: "account_balance_wallet",
      },
      /*     {
               /*  {
                    title: 'Taxes',
                    link: '/tax',
                    description: 'Manage how your store charges tax',
                    icon_name: 'text_snippet'
                }, */
      {
        title: "Notification",
        link: "/notification-setting",
        description: "Manage Notification sent to you & your customers",
        icon_name: "announcement",
      },
      {
        title: "Web Ordering",
        link: "/web-order-accept",
        description: "Receive orders from the web.",
        icon_name: "add_to_queue",
      },
      {
        title: "Receipt Note",
        link: "/receipt",
        description: "Add Receipt Note.",
        icon_name: "branding_watermark",
      },
      /*     {
                    title: 'Discount',
                    link: '/discount',
                    description: 'Manage Discount.',
                    icon_name: 'attach_money'
                },
                {
                    title: 'Happy Hour Discount',
                    link: '/happy-hour-discount',
                    description: 'Manage happy hour discount.',
                    icon_name: 'autorenew'
                }, */
      /* cl */
      {
        title: "Onboarding",
        link: "/setup",
        description: "ADYEN Onboarding Setup",
        icon_name: "add_box",
      },
      /*   {
                  title: 'Manage Users',
                  link: '/manage-user',
                  description: 'Add, Update users.',
                  icon_name: 'account_box'
              }, */
      /* 
                        {
                            title: 'Manage Room',
                            link: '/room',
                            description: 'Add, Update, Delete Room.',
                            icon_name: 'building'
                        },
                        {
                            title: 'Manage Table',
                            link: '/table',
                            description: 'Add, Update, Delete Table.',
                            icon_name: 'circle'
                        }, */
      /*  {
                 title: 'Manage QR.',
                 link: '/manage-qr',
                 description: 'Manage Table, Room etc.',
                 icon_name: 'center_focus_weak'
             }, */

      /*  {
                 title: 'Theme',
                 link: '/theme',
                 description: 'Change Kiosk theme.',
                 icon_name: 'grain'
             }, */
      /*   {
                  title: 'Split Payment',
                  link: '/splitpayment',
                  description: 'Update payments for vendors. ',
                  icon_name: 'account_balance'
              }, */
    ];

    return (
      <Fragment>
        <div className="ms-content-wrapper Dashboard">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="ms-panel">
            <div className="ms-panel-body">
              <div
                className="row col-md-10"
                style={{
                  padding: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignItems: "center",
                }}
              >
                <div className="guide">
                  <Typography variant="h4" align="center" gutterBottom>
                    Kayana Onboarding
                  </Typography>
                  <p>
                  <Typography variant="body1" paragraph>
                    Welcome to <b>Kayana</b>! Upon registering, your journey
                    begins on this initial page. On the left, you'll find
                    various features like Dashboard, Menu, Orders, Discounts,
                    and more. In the main panel, there's a distinct red field
                    labelled 'Not initiated Account Status,' signalling that
                    onboarding has yet to begin. Step 1 is as simple as clicking
                    on this red field to kickstart your onboarding process.
                  </Typography></p>
                  <Typography variant="h5" gutterBottom>
                    <b> STEP 1</b> (Click on the Red field)
                  </Typography>
                  <img className="image_guide" src={step_1} />
                  <Typography variant="h5" gutterBottom>
                    <b>STEP 2-</b> KAYANA SET-UP (Input Business Details for
                    Kayana)
                  </Typography>
                  <Typography variant="body1" paragraph>
                    After clicking on the red field you will land on the Kayana
                    Set Up page where you will have to enter your business
                    details for the Kayana Set up which includes-
                  </Typography>
                  <p>
                    {" "}
                    <b>CONTACT NAME -</b> Name of the Owner,This will be
                    prefilled from the Kayana Registration if you want to change
                    it you can do it here{" "}
                  </p>
                  <p>
                    {" "}
                    <b> CONTACT NUMBER-</b> This will be prefilled from the
                    Kayana Registration if you want to change it you can do it
                    here.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> Business Legal Name-</b> Legal name of the Business with
                    which your business is registered e.g Kayana World Ltd.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> BUSINESS TRADING NAME-</b> You need to enter the Trading
                    name of the Business e.g KAYANA.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> BUSINESS TYPE-</b> Select the type of Business from the
                    dropdown that you own from the selections like Restaurant,
                    Night Club, Hotel, Construction, Charity etc.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> BUSINESS ADDRESS-</b> You need to enter the Street
                    Address of the Business.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> CITY-</b> Name of the City Business is registered in.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> POST CODE-</b> Postal Code of the Business.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> COUNTRY-</b> The Country In which the Business is
                    registered.{" "}
                  </p>
                  <img className="image_guide" src={step_2} />
                  <Typography variant="body1" paragraph>
                    <b> STEP 3-</b> PAYMENT ACCOUNT (After filling the details
                    in the Kayana Set up (Step-2) and clicking on the submit
                    button you will land on this Payment Account Screen)
                  </Typography>
                 <p> <b>The details that need to be filled include-</b></p>
                  <p>
                    {" "}
                    <b> COMPANY TYPE-</b> You need to specify the type of the
                    company you are registering as from the dropdown either
                    Organisation or an Individual.{" "}
                  </p>
                  <p> For Organisation </p>
                  <p>
                    {" "}
                    <b> ORGANISATION STRUCTURE-</b> When you choose
                    'Organisation' as the company type, you'll be prompted to
                    select the ORGANISATION STRUCTURE. Simply pick from the
                    dropdown options such as Private, Government, Non-Profit,
                    Partnership, etc.
                  </p>
                  <p>
                    {" "}
                    <b> REGISTRATION NUMBER- </b> Again if you have selected the
                    Company Type as Organisation. You will have to enter the
                    Registration Number of your Company.{" "}
                  </p>
                  <p>
                    You'll find a convenient CHECKBOX labelled 'Same as Kayana
                    Set Up.' By selecting this option, the system will
                    automatically populate the required details, including Legal
                    Name of the Company, Contact Number, Email, Company Street,
                    City, Postal Code, and Country, based on the information you
                    provided in Step 1. If needed, you can modify any of these
                    details on this step, and rest assured, the updated
                    information will be saved for the Payment Gateway."
                  </p>
                  <p>
                    {" "}
                    <b> For Individual-</b>
                    If you select the Company type as Individual then you will
                    have to enter the following details-
                  </p>
                  <p>
                    {" "}
                    <b> FIrst Name- </b> Enter your First Name.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> Last Name- </b> Enter your Last Name.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> Email- </b> Enter your email address.{" "}
                  </p>
                  <p>
                    {" "}
                    <b> Date Of Birth-</b> Enter your Date of Birth
                  </p>
                  <p>
                    {" "}
                    Again you will find the convenient CHECKBOX "Same as Kayana
                    Set Up'.By selecting this the system will automatically
                    Populate the required details which are Company Street,
                    City, Postal Code, and Country from your Step 1 submission.
                    If you wish to make changes, feel free to do so in this step
                    and the modifications will be saved for the Payment Gateway.
                  </p>
                  <img className="image_guide" src={step_3} />
                  <Typography variant="body1" paragraph>
                    <b> STEP 4 (KYC) -</b> After completing Step 3 and clicking
                    the 'Submit' button, you'll be directed to the KYC Screen,
                    depicted below. To proceed further, simply click on the
                    'CLICK HERE' button, and it will seamlessly redirect you to
                    the Payment Gateway for the next steps Or if the “CLICK
                    HERE” button doesnt work We have a LINK in the left which
                    works the same way as “CLICK HERE” button
                  </Typography>
                  <img className="image_guide" src={step_4} />
                  <Typography variant="body1" paragraph>
                    <b> STEP 5-</b> PAYMENT GATEWAY SET UP-After you click on
                    the “CLICK HERE” button in STEP 4. We will land on the
                    Payment Gateway Platform.
                  </Typography>
                  <p>
                    {" "}
                    <b>Name and country-</b> (We can change the Name of the
                    company and the Country of the Business){" "}
                  </p>
                  <p>
                    {" "}
                    <b>Company structure-</b> (Here we can change whether the
                    Business is Private, Public, Partnership or Govt
                    Organisation ){" "}
                  </p>
                  <p>
                    {" "}
                    <b> Address-</b> (Here we can change the Street and City
                    Address, postal code and we can also provide an additional
                    address if a business is operating from somewhere else.){" "}
                  </p>
                  <p>
                    {" "}
                    <b>Registration details-</b> (Here we can change the Trading
                    name and the registration number of the company ){" "}
                  </p>
                  <p>
                    {" "}
                    <b>Summary-</b> This will show all the details that you have
                    entered.{" "}
                  </p>
                  <img className="image_guide" src={step_5} />
                  <Typography variant="body1" paragraph>
                    <b> DECISION MAKERS-</b> This is the second step in
                    onboarding on the Payment Gateway- On this step we have to
                    add the decision makers So after clicking on the ADD
                    DECISION MAKERS for the payment account we will be shown
                    three options with a checkbox.
                  </Typography>
                  <img className="image_guide" src={step_6} />
                  <p>
                    {" "}
                    <b> Controlling Person-</b> (Authorised to make major
                    business decisions (Can or cannot be the owner))
                  </p>
                  <p>
                    {" "}
                    <b> Signatory-</b> (Authorised to sign the contracts on
                    behalf of the company)
                  </p>
                  <Typography variant="body1" paragraph>
                    When the user clicks on the checkboxes (Click all the three
                    boxes if you hold all the positions) and has to enter the
                    details Which include.
                  </Typography>
                  <p>
                    <ul>
                      <li>
                        <b> First Name -</b> First Name of the Decision Maker.
                      </li>
                      <li>
                        <b> Last Name- </b> Last name of the Decision Maker.
                      </li>
                      <li>
                        <b> Date of Birth-</b> Date of birth of the Decision
                        Maker
                      </li>
                      <li>
                        <b> Country- </b> Country of the residence
                      </li>
                      <li>
                        <b> Email Address-</b> Email address of the Decision
                        Maker,
                      </li>
                      <li>
                        <b> First Name - </b> First Name of the Decision Maker.
                      </li>

                      <li>
                        <b> Phone Number-</b> Phone Number of the Decision
                        Maker.
                      </li>
                      <li>
                        <b> Job Title-</b> Job Title of the Decision Maker
                      </li>
                    </ul>
                  </p>
                  <p>
                    Upon clicking the <b>"Next"</b> button, the user will be
                    directed to the Personal Address screen where they can input
                    their address details. The system will provide suggested
                    addresses, and the user has the option to either select from
                    the suggestions or manually add a new address by clicking on
                    the "Add Manually" option.
                  </p>
                  <img className="image_guide" src={step_7} />
                  <p>
                    Upon clicking the<b> "Next" </b>button following the
                    completion of personal address verification, the user will
                    be directed to the Summary of Personal Details. This screen
                    will provide a comprehensive overview, including the
                    address, for the user's review. An <b>"Edit"</b> button will
                    be available, allowing users to make any necessary changes
                    if needed. As shown in the given image.
                  </p>
                  <img className="image_guide" src={step_8} />
                  <p>
                    Upon clicking the <b>"Submit" </b>button, the user will be
                    presented with a screen featuring an "Unsuccessful" button,
                    which the user must click. This action is necessary to
                    initiate the verification process, ensuring that the
                    provided details in this step are acknowledged with the
                    submission of the required supporting documents.
                  </p>
                  <img className="image_guide" src={step_9} />
                  <p>
                    Upon selecting the <b>"Unsuccessful"</b>button, the user
                    will be redirected to the Summary Screen, providing them
                    with an option to upload the necessary documents for
                    verification.
                  </p>
                  <p>
                    {" "}
                    <b>
                      On verificaion method page We will have two options for
                      that:
                    </b>
                  </p>
                  <p>
                    {" "}
                    <b>A- Instant verification:</b> Clicking on instant
                    Verification we need to have both a physical ID document and
                    a phone in hand. It grants you the ability to undergo
                    instant verification!{" "}
                  </p>
                  <p>
                    {" "}
                    <b>B- Manual Upload :</b> Selecting this option prompts the
                    user to encounter a dropdown menu, offering a choice among
                    document types, including Driver's License, Passport, and
                    Identity Card.
                  </p>
                  <img className="image_guide" src={step_10} />
                  {/* <p> Upon selecting <b>"ID Document,"</b> the user will be directed to the verification method, prompting them to upload a Government-issued ID. Accepted forms of identification include Passport, Identity Card, and Driving License. </p> */}
                  <p>
                    Upon selecting a verification type, the user will seamlessly
                    transition to the document upload screen, where they can
                    conveniently upload the pertinent documents. After
                    completion, the user proceeds by clicking on <b>"NEXT."</b>
                  </p>
                  <img className="image_guide" src={step_11} />
                  <p>
                    {" "}
                    Upon clicking the <b>"Next"</b> button, the user will be
                    directed to the Summary Screen, offering a comprehensive
                    overview of the uploaded information. This screen provides
                    an <b>"Edit"</b> option, allowing users to review and make
                    any necessary changes to the entered details.
                  </p>
                  {/* <img className='image_guide' src={step_12} 

 /> */}
                  <img className="image_guide" src={step_13} />
                  <p>
                    {" "}
                    Upon clicking the <b>"Submit"</b> button, if the documents
                    uploaded by the user receive approval from the Payment
                    Gateway, the system will display the Decision Makers Screen
                    with the status <b>"Verified."</b>
                  </p>
                  <img className="image_guide" src={step_14} />
                  <p>
                    Following the verification, the user is required to click on
                    the <b>"Save and Go to Overview"</b> button. This action
                    will redirect the user to the initial Payment Gateway
                    Screen, facilitating the completion of details for the{" "}
                    <b>next step</b> which is <b>Payout Details-</b>
                  </p>
                  <img className="image_guide" src={step_15} />
                  <p>
                    <b>NEXT STEP</b>
                  </p>
                  <p>
                    <b>Payout Details:</b> Upon clicking on the{" "}
                    <b>'Add Payout Details'</b> button, users will be presented
                    with the option to either-
                  </p>
                  <p>
                    <b>
                      Verify the account via a mobile banking app or bank
                      website-
                    </b>{" "}
                    Users can securely and swiftly complete the process by
                    accessing their banking environment.-
                  </p>
                  <p>
                    <b>
                      Provide Account details and upload a scan of a bank
                      statement-
                    </b>{" "}
                    A user can provide the bank details and upload a bank
                    statement
                  </p>
                  <img className="image_guide" src={step_16} />
                  <p>
                    If a user clicks on{" "}
                    <b>
                      Provide Account details and upload a scan of a bank
                      statement option-
                    </b>{" "}
                    The user will be redirected to Payout Account Screen where
                    user will have to enter the-
                  </p>
                  <p>
                    {" "}
                    <b> 1. Account Number-</b> User will have to enter the
                    account number
                  </p>
                  <p>
                    {" "}
                    <b> 2.Sort Code-</b> User will have to enter the six-digit
                    sort code
                  </p>
                  <img className="image_guide" src={step_17} />
                  <p>
                    "After entering the <b>Account Number</b> and{" "}
                    <b>Sort Code</b> details and clicking <b>'Next,'</b> the
                    user will be redirected to the Bank Statement Screen. Here,
                    the user is required to upload one of the provided
                    documents, which may include a{" "}
                    <b>
                      Bank Statement, Deposit Ticket/Deposit Form, Screenshot of
                      Online Banking Environment, or an official email or letter
                      from their bank.
                    </b>{" "}
                    And after uploading the relevant document the user will have
                    to click on the <b>Next</b> button.
                  </p>
                  <img className="image_guide" src={step_18} />
                  <p>
                    "After clicking the <b>'Next'</b> button following the
                    upload of the bank statement, the user will be directed to a
                    summary screen to review the bank details. The screen
                    includes an <b>'Edit' </b>button, allowing users to rectify
                    any mistakes if identified during the overview."
                  </p>
                  <img className="image_guide" src={step_19} />
                  <p>
                    After clicking the <b>'Submit'</b> button, the user will be
                    redirected to the initial Payment Gateway Screen, where
                    Payout Details will now be displayed as <b>'Verified'.</b>"
                  </p>
                  <img className="image_guide" src={step_21} />
                  <p>
                    <b>NEXT STEP</b>
                  </p>
                  <p>
                    <b>Sign Services document-</b> For this user needs to click
                    on the Sign Services agreement button{" "}
                  </p>
                  <img className="image_guide" src={step_21} />
                  <p>
                    After selecting the <b>'Sign Services Agreement,'</b> the
                    user will be directed to a screen where they need to choose
                    the signer, mark the checkbox, and then proceed by clicking
                    the<b> 'Sign'</b> button located at the bottom
                  </p>
                  <img className="image_guide" src={step_22} />
                  <p>
                    "After clicking the <b>'Sign'</b> button, the user will be
                    redirected to the initial screen where all options will be
                    marked as <b>verified</b>. At the bottom, there will be a
                    link allowing the user to access the Kayana Platform
                    seamlessly."
                  </p>
                  <img className="image_guide" src={step_23} />
                  <p>
                    After clicking on the link, the user will land on the kayana
                    Platform where you will see the <b>Account Status</b> as{" "}
                    <b>Review failed </b>and the reason for that is <b>PCI</b>{" "}
                    form is not signed for that user will have to click on the
                    red button.{" "}
                  </p>
                  <img className="image_guide" src={step_24} />
                  <p>
                    After The user clicks on the red button. He will land again
                    on the <b>KYC</b> Screen.
                  </p>
                  <img className="image_guide" src={step_25} />
                  <p>
                    {" "}
                    "Upon clicking the <b>'Click here'</b> button, the user will
                    be redirected back to the Payment Gateway Screen to sign the
                    <b> PCI </b>form, thereby completing the account creation
                    process.
                  </p>
                  <img className="image_guide" src={step_26} />
                  <p>
                    {" "}
                    After selecting the <b>PCI DSS Questionnaire,</b> the user
                    will need to input the name of the signer, check the
                    designated checkbox, and subsequently click on the{" "}
                    <b>'Sign'</b> button located at the bottom and it will show{" "}
                    <b>PCI</b> form signed successfully
                  </p>
                  <img className="image_guide" src={step_27} />
                  <p>
                    "After successfully signing the <b>PCI </b>form, the user
                    will be directed to the initial screen, where a <b>link</b>{" "}
                    at the bottom allows them to seamlessly return to the Kayana
                    Platform."
                  </p>
                  <img className="image_guide" src={step_28} />
                  <p>
                    {" "}
                    Finally, Your account onboarding is complete and you will
                    land on this screen
                  </p>
                  <img className="image_guide" src={step_29} />
                  <p>
                    And now the <b>Account review Status</b> will turn Green and
                    the user will be good to go.
                  </p>
                  <img className="image_guide" src={step_30} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Content;
