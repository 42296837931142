import * as React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    CardActions,
    TextField,
} from '@mui/material';
import {  makeStyles } from "@mui/styles";
import Jumbo from '../../../assets/img/apex.jpg'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    },
    bronzeCard: {
        borderRadius: '10px', // Adjust the value to achieve the desired shape
        //   border: '2px solid #5773ff', // Bronze color
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5)',
    },
    model: {
        color: "grey",
        fontSize: "14px"
    },
    media: {
        width: '350px',
        height: '350px',
        backgroundSize: "contain",
        padding: "5px"
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    quantityField: {
        // marginBottom: theme.spacing(1),
        width: "100px"
    },
    addButton: {
        marginTop: 'auto',
        //  marginLeft: theme.spacing(5),
        //  color: "white",
        borderRadius: '10px'
    },
    price: {
        marginTop: '20px;',
        padding: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "20px",
        fontWeight: 600,
        marginTop: "40px"
    },
    block_price__currency: {
        padding: 0,
        margin: 0,
        verticalAlign: "top",
        color: "#5773ff",
        fontSize: "26px",
        fontWeight: 600
    },
    title: {
        position: "relative", // Set the parent element to a relative position
        padding: "10px", // Add some padding to the element

        "&::before": {
            content: "''", // Add content to the pseudo-element
            position: "absolute",
            bottom: "0",
            width: "40%", // Adjust the width as needed
            borderBottom: "5px solid #5773ff",
        }
    }

}));
const ShopItem = ({ product }) => {
    const classes = useStyles();
    return (

        <Card className={classes.bronzeCard}>
            <CardMedia
                sx={{ height: 240, padding: 5, backgroundSize: "contain" }}
                image={product.imageUrl}
                title="green iguana"
            />
            <CardContent>
                {/*  <Typography variant="h6" className={classes.model}>
                    {product.model}
                </Typography> */}
                <Typography gutterBottom variant="h4" component="div" className={classes.title}>
                    {product.name}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                {product.description}
                </Typography> */}
                <Typography variant="body2" color="text.primary">
                    <Box component="ul" sx={{ pl: 2, '& li': { fontSize: '1.1rem', marginBottom: '6px' } }}>
                        {product.info.map((point, index) => (
                            <li key={index} style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                                <svg width="10" height="10" style={{ marginRight: '6px' }}>
                                    <circle cx="5" cy="5" r="5" fill="black" />
                                </svg>
                                {point}
                            </li>
                        ))}
                    </Box>
                </Typography>
                <Typography className={classes.price} variant="body1" color="textSecondary">
                    £ 1000  +  <span>  £ 29 (Software)</span>
                </Typography>
               {/*  <div className={classes.price}>
                    <TextField
                        size='small'
                        label="Quantity"
                        variant="outlined"
                        type="number"
                        defaultValue={1}
                        InputProps={{
                            inputProps: {
                                min: 1, // minimum value
                            },
                        }}
                        className={classes.quantityField}
                    />
                </div> */}

            </CardContent>
            <CardActions sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
                
            <Stack spacing={2}>
                <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 1 }}
                >
                    Discover more
                </Button>
               
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    Add to Cart
                </Button>
                </Stack>
            </CardActions>
        </Card>

    );
}
export default ShopItem;