import React, { Component } from "react";

import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import * as SecurityServiceAPI from "../../../services/user/SecurityServiceAPI";
import * as MenuServiceAPI from "../../../services/product/menu/MenuServiceAPI";
import * as PaymentServiceAPI from "../../../services/payment/PaymentServiceAPI";
import Recentorder from "./Recentorder";
import Trendingorder from "./Trendingorder";
import SoldOut from "./SoldOut";
import Chart from "./Chart";
import DemoLine from "./chart/dashboardChart";
import BusinessPlaceForm from "./BusinessPlaceForm";
import SimpleModal from "../../../shared/Modal/SimpleModal";
import countryList from "react-select-country-list";
import MenuMaster from "./MenuMaster";
import TermsAndConditions from "../../../container/TermsAndConditions/TermsAndConditions";
import Index from "../../../container/payment/index";
import { setProperty, setPropertyId } from "../../../redux/property/index";
import { setVendor, setFranchise, setCountry, setCountryCode } from "../../../redux/user/userSlice";
import MaterialUIPicker from './datepicker/datepick';
import Loader from "../../../shared/Loader";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom'
import { Line as LineChart } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from "@mui/material/Grid";
import { red } from '@mui/material/colors';
import happy from '../../../assets/img/feedback/happy.png';
import angry from '../../../assets/img/feedback/angry.png';
import normal from '../../../assets/img/feedback/normal.png';
import kiosk from '../../../assets/img/kiosk.png';
import kiask from '../../../assets/img/kiask.png';
import pos from '../../../assets/img/pos.png';
import mpos from '../../../assets/img/mpos.png';
import terminal from '../../../assets/img/terminal.png';
import terminl from '../../../assets/img/terminl.png';
import qrCode from '../../../assets/img/qrCode.png';
import term from '../../../assets/img/term.png';
import payLink from '../../../assets/img/payLink.svg';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Slide from '@mui/material/Slide';
import $ from "jquery";
import { Tooltip } from 'antd';
import { Avatar as Avat } from 'antd';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ReactGA from 'react-ga';
import Collect from './collect-user-info/Collect'
import { settings, getCurrencySymbol } from "../../../config";
import Slider from "react-slick";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const style = {
  display: 'flex',
  flexDirection: 'row',
};

const slide = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 835,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],
  appendDots: dots => (
    <div
      style={{
        backgroundColor: "none",
        borderRadius: "10px",
        padding: "10px",
        position: "relative",
        top: "10px"
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: i => (
    <div
      style={{
        width: "30px",
        color: "blue",
        border: "1px blue solid"
      }}
    >
      {i + 1}
    </div>
  )
};

const tableStyle = {
  backgroundColor: "#5773ff",
  color: "white",
  textAlign: "left"
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

class Homecontent extends Component {
  state = {
    display_name: "",
    name: "",
    user: "",
    page: 0,
    order_details: [],
    soldOutItems: [],
    current_page: 0,
    number_of_elements: 10,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    // property_id: "",
    property_details: false,
    business_place_form: true,
    property_country: "",
    country: "",
    show: false,
    country_options: "",
    business_types: [],
    activePage: 0,

    loading: false,

    // For Modal Popup Status Check
    property_id: null,
    property_setup_flag: false,
    menu_setup_flag: true,
    merchant_setup_flag: true,
    tnc_status_flag: true,
    start: dayjs().subtract(1, 'day'),
    end: dayjs().subtract(1, 'day'),
    ayden: {},
    statusToShow: "NOT INITIATED",
    color: "#ec6b77",
    descrption: "Start onboarding process",
    errors: "",
    isLoading: true,
    order_details: [],
    summary: {},
    happy: {},
    angry: {},
    normal: {},
    processingTime: 0,
    ayden: {},
    limit: 10,
    tab: 0,
    filter: "2",
    open: false,
    totalOrders: 0,
    totalSales: 0,
    orderSource: {},
    isMobile: window.innerWidth <= 768,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.fetchFrequentOrders();
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount = () => {
    this.fetchProperty();
    window.addEventListener('resize', this.handleResize);
    ReactGA.pageview(window.location.pathname + window.location.search);
    let pathArray = window.location.pathname.split("/");
    let currentPage = pathArray[1];
    if (currentPage !== "payout" || currentPage !== "transactions") {
      $(".collapse").slideUp();
    }


    function setActiveMenuItem() {

      // get elements that are active and remove their active class
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      if (currentPage !== "payout" || currentPage !== "transactions") {
        $(".collapse").slideUp();
      }

    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }

    const username = this.props.user.username ? this.props.user.username : localStorage.getItem("username");
    const business_trading_name = localStorage.getItem("business_trading_name");

    let lastYear = dayjs().subtract(1, 'week');
    this.setState({ display_name: business_trading_name });
    this.fetchAccountInformation();
    //this.fetchRequiredData(username);
    this.fetchFrequentOrders();
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }


  fetchAccountInformation() {
    const dump = {
      property_id: this.props.propertyId,
      username: this.props.user.username ? this.props.user.username : localStorage.getItem("username")
    }


    PaymentServiceAPI.fetchAccountStatus(dump)
      .then((response) => {
        if (response.data.status) {
          //     this.setState({ loading: false, show: false });
          //    toast.success("Message Sent Successfully");
          localStorage.setItem('account status', response.data.data.account_status);

          this.setState({ ayden: response.data.data });

          //      this.props.setFranchise(response.data.data.is_master_franchise);
          //     this.props.setFranchise(response.data.data.is_master_franchise);
          //    this.props.setVendor(response.data.data.is_multi_vendor);

          if (this.state.ayden?.account_status == "ACCOUNT NOT INITIATED") {
            this.setState({ statusToShow: "NOT INITIATED", descrption: "Start Onboarding Process", color: "#ec6b77" });

          } else if (this.state.ayden?.account_status == "ACCOUNT CREATED") {
            this.setState({ statusToShow: "PAYMENT ACCOUNT PENDING", descrption: "Start Payment Account Creation", color: "#ec6b77" });
          }
          else if (this.state.ayden?.account_status == "ACCOUNT HOLDER PARTIAL UPDATE") {
            this.setState({ statusToShow: "IN REVIEW", descrption: "Account Under Review.", color: "#d59829" });
          } else if (this.state.ayden?.account_status == "LEGAL ENTITY UPDATED") {
            this.setState({ statusToShow: "Onboarding Incomplete", descrption: "Please complete Onboarding.", color: "#d59829" });
          }
          else if (this.state.ayden?.account_status == "ACCOUNT COMPLETED") {
            this.setState({ statusToShow: "ACTIVE", descrption: "Account is active.", color: "#4db24d" });
          }
          else if (this.state.ayden?.account_status == "ACCOUNT REVIEW FAILED") {
            this.setState({ statusToShow: "REVIEW FAILED", descrption: "We will get back to you.", color: "#ec6b77", errors: this.state.ayden?.adyen_info?.errors });
          } else if (this.state.ayden?.account_status == "ACCOUNT SUSPENDED") {
            this.setState({ statusToShow: "ACCOUNT SUSPENDED", descrption: "Account Suspended. ", color: "#ec6b77" });
          } else if (this.state.ayden?.account_status == "ACCOUNT IN REVIEW") {
            this.setState({ statusToShow: "ACCOUNT IN REVIEW", descrption: "ACCOUNT IN REVIEW. ", color: "#d59829" });
          } else if (this.state.ayden?.account_status == "BANKING DETAILS REQUIRED") {
            this.setState({ statusToShow: "BANKING DETAILS REQUIRED", descrption: "BANKING DETAILS REQUIRED. ", color: "#d59829" });
          }

          this.setState({ isLoading: false });
          //     this.props.setFranchise(response.data.data.is_master_franchise);
          //    this.props.setVendor(response.data.data.is_multi_vendor);

          //   this.setState({ summary: response[0].data.data });
        } else {
          //     toast.error(response.data.message);
          //     this.setState({ loading: false });
          this.setState({ isLoading: false });
        }


      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  fetchProperty = () => {
    const payload = this.props.username || localStorage.getItem("username");
    SecurityServiceAPI.fetchBusinessPlace(payload).then((response) => {
      if (response.data && response.data.status) {
        const property_details = response.data?.data?.property_details[0];
        this.props.setCountry(property_details?.country);
        this.props.setCountryCode(property_details?.country_code);
      }
    })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
      });
  }


  handleTabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };


  fnCallback = (e) => {

    this.setState({ filter: e?.target?.value });
    if (!e?.target?.value) {

    } else if (e?.target?.value == 1) { // Today
      var today = dayjs(new Date());
      this.setState({ start: today, end: today }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 2) { // Yesterday
      // var today = dayjs(new Date()).subtract(1, 'day');
      var yesterday = dayjs(new Date()).subtract(1, 'day');
      this.setState({ start: yesterday, end: yesterday }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 3) { // Last 7 Days
      var today = dayjs(new Date()).subtract(1, 'week').startOf('week');
      var last7 = dayjs(new Date()).subtract(1, 'week').endOf('week');
      this.setState({ start: today, end: last7 }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 4) { // Last 30 Days
      var today = dayjs(new Date()).subtract(1, 'month').startOf('month');
      var last30 = dayjs(new Date()).subtract(1, 'month').endOf('month');
      this.setState({ start: today, end: last30 }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 6) { // Last 30 Days
      var today = dayjs(new Date());
      var firstDayOfMonth = dayjs(new Date()).startOf('month');
      this.setState({ start: firstDayOfMonth, end: today }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 5) { // Last 30 Days
      this.handleClickOpen();
    }
  }

  limitOrders = (e) => {
    if (!e?.target?.value) {
      this.fetchFrequentOrder();
    } else if (e?.target?.value == 1) {
      this.setState({ limit: 10 }, this.fetchFrequentOrders);
    } else if (e?.target?.value == 2) {
      // var today = dayjs(new Date()).subtract(1, 'day');
      this.setState({ limit: 100000000 }, this.fetchFrequentOrders);
    }
  }

  fetchDashboardSummary = () => {


    const to = dayjs(this.state.end).format('DD-MM-YYYY')

    const payload = {
      from: dayjs(this.state.start).format('DD-MM-YYYY'),
      to: to,
      property_id: this.props.propertyId,
    };


    /*     const from = dayjs(this.props.state.start).format('DD-MM-YYYY');
        const to = dayjs(this.props.state.end).format('DD-MM-YYYY')
        const payload = {
          from: from,
          to: to,
          property_id: this.props.user.user.property_id ? this.props.user.user.property_id : localStorage.getItem("property_id"),
        }; */

    /*     (async () => {
            const rawResponse = await fetch('http://18.133.81.78:8086/business/report/fetch-transactions-summary', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
            });
            const content = await rawResponse.json();
          
          })();
  */

    DashboardServiceAPI.fetchDashboardSummary(payload)
      .then((response) => {
        if (response[0].data.status) {
          //     this.setState({ loading: false, show: false });
          //    toast.success("Message Sent Successfully");
          this.setState({
            totalSales: response[0].data.data?.totalSales,
            totalOrders: response[0].data.data?.totalOrders,
            angry: response[0].data.data?.todaysFeedBack && response[0].data.data?.todaysFeedBack.hasOwnProperty("1") ? response[0].data.data?.todaysFeedBack["1"] : 0,
            normal: response[0].data.data?.todaysFeedBack && response[0].data.data?.todaysFeedBack.hasOwnProperty("3") ? response[0].data.data?.todaysFeedBack["3"] : 0,
            happy: response[0].data.data?.todaysFeedBack && response[0].data.data?.todaysFeedBack.hasOwnProperty("5") ? response[0].data.data?.todaysFeedBack["5"] : 0,
            orderSource: response[0].data.data?.orderSource
          });

        } else {
          this.setState({ totalSales: 0, totalOrders: 0, angry: 0, happy: 0, normal: 0 });
        }

        if (response[1].data.status) {
          this.setState({ processingTime: response[1].data.data?.processing_time });
        } else {
          this.setState({ processingTime: 0 });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
      });



  }


  fetchFrequentOrders = () => {


    this.fetchDashboardSummary();

    this.fetchSoldOutItems();

    const from = dayjs(this.state.start).format('DD-MM-YYYY')
    const to = dayjs(this.state.end).format('DD-MM-YYYY')
    const payload = {
      from: from,
      to: to,
      property_id: this.props.propertyId,
    };



    this.setState({ isLoading: true });



    DashboardServiceAPI.fetchFrequentOrders(payload)
      .then((response) => {
        if (response.data.status) {
          let frequentOrders = response.data.data.sort((a, b) => b.sold_out_quantity - a.sold_out_quantity);
          this.setState({ order_details: frequentOrders });
          this.setState({ isLoading: false });
        } else {
          //     toast.error(response.data.message);
          this.setState({ isLoading: false, order_details: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false });
        console.log(error);
      });

  }

  fetchSoldOutItems = () => {


    const payload = {
      property_id: this.props.propertyId,
    };


    DashboardServiceAPI.fetchSoldOutItems(payload)
      .then((response) => {
        if (response.data.status) {
          this.setState({ soldOutItems: response.data.data });
          this.setState({ isLoading: false });
        } else {
          //     toast.error(response.data.message);
          this.setState({ isLoading: false, soldOutItems: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        this.setState({ isLoading: false });
        console.log(error);
      });

  }

  handleToDate = (e) => {

    let toDat = dayjs(e);
    this.setState({ end: toDat, filter: "5" }, () => { this.handleClose() });
  }

  handleFromDate = (e) => {
    //    const ctrl_name = e.target?.name;

    let toDat = dayjs(e);
    this.setState({ start: toDat, filter: "5" });
  }

  fetchRequiredData = (username) => {
    this.setState({ loading: true });

    const allPromises = [
      SecurityServiceAPI.fetchBusinessType(), // Populate Dropdown values for Business Types
      SecurityServiceAPI.fetchBusinessPlace(username), // Step #1
    ];

    let property_id = null;
    let business_types = [];
    let property_setup_flag = false;

    Promise.all(allPromises)
      .then((responses) => {
        return this.simplifyBusinessPlacesResponse(responses);
      })
      .then((values) => {
        //   assign the values in outerscope for accesss in next steps
        property_id = values.property_id;
        business_types = values.business_types;
        property_setup_flag = values.property_setup_flag;

        if (!property_id || property_setup_flag == false) {
          return false; // we will skip the api call
        }

        // Require Property Id from Response
        const promiseStep2 = [
          MenuServiceAPI.fetchMenu(property_id), // Step #2
          PaymentServiceAPI.fetchAccountStatus({ property_id, username }), // Step #3
          SecurityServiceAPI.fetchTermsAndConditionAcceptance({
            property_id: property_id,
          }), // Step #4 fetch tnc status api here
        ];

        return Promise.all(promiseStep2);
      })
      .then((responses) => {
        if (!responses) {
          return {
            activePage: 0,
            showModal: true,
            menu_setup_flag: true,
            merchant_setup_flag: true,
            tnc_status_flag: true,
          };
        }
        return this.simplifyMenuAndAccountResponse(
          property_setup_flag,
          responses
        );
      })
      .then((values) => {
        const {
          activePage,
          showModal,
          menu_setup_flag,
          merchant_setup_flag,
          tnc_status_flag,
        } = values;

        this.setState(
          {
            // Status Information
            loading: false, // Loading Status
            property_setup_flag, // Step #1 Status
            // menu_setup_flag, //Step #2 Status
            //merchant_setup_flag, // Step #3 Status
            //tnc_status_flag, //step #4 status

            // Modal Information
            show: showModal,
            activePage,

            // User Information
            username: username,
            property_id,

            // Dropdown Values
            country_options: countryList().getData(),
            business_types,
          }
          // this.printState
        ); // Print current state after State in Updated
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, username: username });
      });
  };

  simplifyMenuAndAccountResponse = (property_setup_flag, responses) => {
    let activePage = 0;
    let showModal = false;
    let menu_setup_flag = true;
    let merchant_setup_flag = true;
    let tnc_status_flag = true; // for step #4

    const menuResponce = responses[0];
    const accountResponse = responses[1];
    const termResponse = responses[2];

    if (
      menuResponce.data.status &&
      menuResponce.data.data &&
      menuResponce.data.data.menu_response_bean_list.length !== 0
    ) {
      menu_setup_flag = true; // Step #2 is Complete
    }

    if (accountResponse.data.data.account_status === "TOS ACCEPTANCE UPDATED") {
      // can we use this?
      merchant_setup_flag = true; // Step #3 Complete
    }

    if (termResponse.data.data.property_details[0].tnc_accepted === true) {
      tnc_status_flag = true; // Step #4 Complete
    }

    // any condition is false show the modal and select the step
    if (property_setup_flag !== true) {
      activePage = 0;
      showModal = true;
    } else if (merchant_setup_flag !== true) {
      activePage = 1;
      showModal = true;
    } else if (tnc_status_flag !== true) {
      activePage = 2;
      showModal = true;
      // } else if (tnc_status_flag !== true) {
      //   activePage = 3;
      //   showModal = true;
      // }
    }

    return {
      activePage,
      showModal,
      menu_setup_flag,
      merchant_setup_flag,
      tnc_status_flag,
    };
  };


  simplifyBusinessPlacesResponse = (responses) => {
    let property_setup_flag = false;
    let business_types = [];
    let property_id = "";

    const business_types_response = responses[0];

    if (business_types_response.data.status) {
      business_types = business_types_response.data.data.business_types;
    }

    const response = responses[1];

    if (response.data.status) {
      const resp_len = response.data.data.property_details.length;
      const property_details = response.data.data.property_details[0];

      if (property_details.property_id) {
        property_setup_flag = true; // Step #1 is Complete
      }

      if (resp_len !== 0) {
        let tmp_property_id = property_details.property_id;
        localStorage.setItem(
          "property_details",
          JSON.stringify(property_details)
        );

        localStorage.setItem("property_id", tmp_property_id);

        // Using redux to save property_details and id, use of localstorage will be removed in future
        this.props.setProperty(property_details);
        this.props.setPropertyId(tmp_property_id);

        property_id = tmp_property_id;
      }
    } else {
      if (
        response.data &&
        response.data.data &&
        response.data.data.property_details.length !== 0
      ) {
        let property_details = response.data.data.property_details[0];
        let tmp_property_id = property_details.property_id;
        localStorage.setItem("property_id", tmp_property_id);
        this.props.setPropertyId(tmp_property_id);
        property_id = tmp_property_id;
      }
    }

    return {
      property_setup_flag,
      business_types,
      property_id,
    };
  };

  fetchOrderDetailsByOrderId = (order) => {
    this.props.history.push({
      pathname: "/menu",
      state: {
        order: order,
      },
    });
  };



  render() {

    const { tab, isMobile } = this.state;

    return (
      <>
        <div className="ms-content-wrapper pt-1">
          <div className="row">
            {/*   <div className="col-md-12">
              <h1 className="db-header-title" style={{ fontWeight: "bold" }}>
                Welcome,{" "}
                {this.state.display_name ? this.state.display_name : ""}
              </h1>
            </div> */}



            <div className="col-xl-12">

              <div className="">


                {this.state.errors ?
                  <div className="col-xl-12 col-md-12">
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="warning">{this.state.errors?.replaceAll('"', '').includes("ownership") ? "Ownership details incomplete. " : this.state.errors?.replaceAll('"', '')}</Alert>
                    </Stack>

                    <br />
                  </div> : ""}

                {this.state.isLoading ? (
                  <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }} >   <Loader size={50} /> </div>

                ) : (
                  <React.Fragment>



                    <Grid container spacing={1} >
                      <Grid item xs={12} sm={12}>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={4} lg={2}>
                            <Link to="/setup">
                              <Card style={{ background: this.state.color }}  >
                                <CardHeader
                                  avatar={
                                    <Avatar sx={{ bgcolor: "#5773ff" }} aria-label="recipe">
                                      <i className="">  < FlipCameraAndroidIcon /></i>
                                    </Avatar>
                                  }

                                  title={this.state.statusToShow}
                                  subheader="Account Status"
                                />


                              </Card>
                            </Link>
                          </Grid>

                          <Grid item xs={12} md={4} lg={2}>


                            <Card style={{ padding: "2px 0px" }}>

                              <CardHeader

                                title={<React.Fragment>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label" style={{ height: 40 }}
                                      id="demo-simple-select"
                                      value={this.state.filter}
                                      label="Filter By"
                                      onChange={this.fnCallback}
                                    >
                                      {/* <MenuItem value={0}>None</MenuItem> */}
                                      <MenuItem value={1}>Today</MenuItem>
                                      <MenuItem value={2}>Yesterday</MenuItem>
                                      <MenuItem value={3}>Last Week</MenuItem>
                                      <MenuItem value={6}>Current Month</MenuItem>
                                      <MenuItem value={4}>Last Month</MenuItem>
                                      <MenuItem value={5}>Custom</MenuItem>

                                    </Select>
                                  </FormControl>
                                  {/*   <Button fullWidth variant="outlined" onClick={this.handleClickOpen}>
                                    Filter By
                                  </Button> */}
                                  <Dialog
                                    open={this.state.open} TransitionComponent={Transition}
                                    keepMounted
                                    onClose={this.handleClose} maxWidth="xs"
                                    scroll="body"
                                    PaperProps={{ sx: { mt: "170px", verticalAlign: "top" } }}>
                                    {/*   <DialogTitle id="alert-dialog-title">
                                      Custom Filters
                                    </DialogTitle> */}
                                    <DialogContent>
                                      <DialogContentText>

                                      </DialogContentText>

                                      <br />
                                      <Box
                                        noValidate
                                        component="form"
                                      /*      sx={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             m: 'auto',
                                             width: 'fit-content',
                                           }} */
                                      >




                                        <Typography variant="h6" gutterBottom>
                                          Filter By Date
                                        </Typography>

                                        <Divider></Divider>


                                        <br></br>

                                        <FormControl fullWidth>

                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                              <MobileDatePicker
                                                label="From"
                                                value={this.state.start || dayjs(new Date())}
                                                inputFormat="DD-MM-YYYY"
                                                InputProps={{
                                                  endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                }}
                                                maxDate={new Date()}
                                                onChange={this.handleFromDate}
                                                renderInput={(params) => <TextField {...params} />}
                                              />
                                            </Stack>
                                          </LocalizationProvider>
                                        </FormControl>

                                        <br></br>   <br></br>

                                        <FormControl fullWidth>

                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3}>
                                              <MobileDatePicker
                                                label="To"
                                                value={this.state.end || dayjs(new Date())}
                                                inputFormat="DD-MM-YYYY"
                                                maxDate={new Date()}
                                                onChange={this.handleToDate}
                                                InputProps={{
                                                  endAdornment: <InputAdornment position="start"><CalendarMonthIcon /></InputAdornment>,
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                              />
                                            </Stack>
                                          </LocalizationProvider>
                                        </FormControl>
                                      </Box>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={this.handleClose}>CLOSE</Button>
                                    </DialogActions>
                                  </Dialog>
                                </React.Fragment>
                                }

                              />






                            </Card>

                          </Grid>



                          <Grid item xs={12} md={4} lg={2}>
                            <Link to="/orders-delivery/delivery">
                              <Card  >
                                <CardHeader
                                  avatar={
                                    <Avatar sx={{ bgcolor: "#5773ff" }} aria-label="recipe">
                                      <i className="flaticon-stats"></i>
                                    </Avatar>
                                  }

                                  title={this.state.totalOrders ? this.state.totalOrders : 0}
                                  subheader="Orders"
                                />


                              </Card>
                            </Link>
                          </Grid>




                          <Grid item xs={12} md={4} lg={2}>
                            <Link to="/orders-delivery/delivery">
                              <Card  >
                                <CardHeader
                                  avatar={
                                    <Avatar sx={{ bgcolor: "#5773ff" }} aria-label="recipe">
                                      <i className="material-icons">account_balance_wallet</i>
                                    </Avatar>
                                  }

                                  title={
                                    this.state.totalSales
                                      ? getCurrencySymbol(localStorage.getItem('currency_code')) + this.state.totalSales
                                      : getCurrencySymbol(localStorage.getItem('currency_code')) + "0"
                                  }
                                  subheader="Sales"
                                />


                              </Card>
                            </Link>
                          </Grid>

                          <Grid item xs={12} md={4} lg={2}>
                            <Link to="/orders-delivery/delivery">
                              <Card  >
                                <CardHeader
                                  avatar={
                                    <Avatar sx={{ bgcolor: "#5773ff" }} aria-label="recipe">
                                      <i className="material-icons">access_time</i>
                                    </Avatar>
                                  }

                                  title={this.state.processingTime ? this.state.processingTime + " min" : 0 + " min"}
                                  subheader="Order time"
                                />


                              </Card>
                            </Link>
                          </Grid>

                          <Grid item xs={12} md={4} lg={2}>
                            <Link to="/orders-delivery/delivery">
                              <Card style={{ padding: "8px 0px" }}>
                                <CardHeader
                                  avatar={

                                    <React.Fragment>

                                      <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={angry} /> &nbsp;&nbsp; {Number(this.state.angry) > 999 ? "999+" : Number(this.state.angry) ? Number(this.state.angry) : 0} &nbsp;&nbsp;
                                      <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={normal} /> &nbsp;&nbsp; {Number(this.state.normal) > 999 ? "999+" : Number(this.state.normal) ? Number(this.state.normal) : 0}  &nbsp;&nbsp;
                                      <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={happy} /> &nbsp;&nbsp;{Number(this.state.happy) > 999 ? "999+" : Number(this.state.happy) ? Number(this.state.happy) : 0}

                                    </React.Fragment>


                                  }

                                  title=""
                                  subheader=""
                                />


                              </Card>
                            </Link>
                          </Grid>

                          {/*    <div className="col-md-12" >
                            <Slider {...slide}> */}
                          {/*       <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '20px 0px' }}>

                              <Grid container spacing={1} >


                                <Grid item xs={4} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">    <Avat src={kiask} className="dashboardAvatarCards" /> KIOSK</Typography>
                                  </div>
                                </Grid>

                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={0} >
                                    <Grid xs={6} item lg={6}>
                                      <p>CARD :  &nbsp;  {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.KIOSK?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.card?.count || 0})</p>
                                    </Grid>

                                    <Grid xs={6} item lg={6}>
                                      <p> CASH  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.KIOSK?.cash?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.KIOSK?.cash?.count || 0}) </p>
                                    </Grid>

                                    <Grid container spacing={2} >
                                      <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                        <p>
                                          INVOICE:
                                        </p>
                                      </Grid>
                                    </Grid>


                                    <React.Fragment>

                                      <Grid container spacing={2} >
                                        <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                          <p>
                                            INVOICE:
                                          </p>
                                        </Grid>
                                      </Grid>

                                    </React.Fragment>




                                  </Grid>

                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid>

                          <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '20px 0px' }}>

                              <Grid container spacing={1} >


                                <Grid item xs={4} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">       <Avat src={terminl} className="dashboardAvatarCards" /> EPOS</Typography>

                                  </div>
                                </Grid>



                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={0} >
                                    <Grid xs={6} item lg={6}>
                                      <p> CARD  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.card?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.card?.count || 0})</p>
                                    </Grid>
                                    {this.props.settings?.is_pay_by_invoice_enabled &&

                                      <Grid xs={6} item lg={6}>
                                        <p> INVOICE  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.invoice?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.invoice?.count || 0}) </p>
                                      </Grid>
                                    }

                                    <Grid xs={6} item lg={6}>
                                      <p>CASH  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.cash?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.cash?.count || 0}) </p>
                                    </Grid>
                                    {this.props.settings?.is_dog_dollar_enabled &&
                                      <React.Fragment >

                                        <Grid xs={6} item lg={6}>
                                          <p> DOG DOLLAR  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.dogDollar?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.dogDollar?.count || 0}) </p>
                                        </Grid>
                                      </React.Fragment >

                                    }

                                    <React.Fragment  >

                                      <Grid xs={6} item lg={6}>
                                        <p> SPLIT  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.split?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.split?.count || 0}) </p>
                                      </Grid>
                                    </React.Fragment >

                                    {!this.props.settings?.is_pay_by_invoice_enabled &&

                                      <Grid xs={6} item lg={6} style={{ visibility: "hidden" }} >
                                        <p> INVOICE  :   </p>
                                      </Grid>
                                    }
                                    {!this.props.settings?.is_dog_dollar_enabled &&

                                      <Grid xs={6} item lg={6} style={{ visibility: "hidden" }} >
                                        <p> INVOICE  :   </p>
                                      </Grid>
                                    }
                                  </Grid>

                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid>

                          <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '20px 0px' }} >

                              <Grid container spacing={1} >


                                <Grid item xs={4} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">       <Avat src={mpos} className="dashboardAvatarCards" /> MPOS</Typography>

                                  </div>
                                </Grid>



                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={0} >
                                    <Grid xs={6} item lg={6}>
                                      <p>CARD  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))}  {this.state.orderSource?.MPOS?.card?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.MPOS?.card?.count || 0}) </p>
                                    </Grid>





                                    <Grid xs={6} item lg={6}>
                                      <p> CASH  :  &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.cash?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.MPOS?.cash?.count || 0})  </p>
                                    </Grid>



                                    <React.Fragment>

                                      <Grid container spacing={2} >
                                        <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                          <p>
                                            INVOICE:
                                          </p>
                                          <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                            <p>
                                              INVOICE:
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                    </React.Fragment>


                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid>

                          <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '20px 0px' }}>

                              <Grid container spacing={2} >


                                <Grid item xs={4} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">       <Avat src={term} className="dashboardAvatarCards" /> TERMINAL</Typography>

                                  </div>
                                </Grid>



                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={1} >

                                    <Grid xs={6} item lg={12}>
                                      <p> CARD  :   &nbsp; {getCurrencySymbol(localStorage.getItem('currency_code'))}  {this.state.orderSource?.TERMINAL?.card?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.TERMINAL?.card?.count || 0}) </p>
                                    </Grid>


                                  </Grid>



                                  <Grid container spacing={1} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p> CASH :  </p>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p> CASH :  </p>
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid>



                          <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '20px 0px' }}>

                              <Grid container spacing={2} >


                                <Grid item xs={6} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">       <Avat src={payLink} className="dashboardAvatarCards" /> PAY BY LINK</Typography>

                                  </div>
                                </Grid>



                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={2} >
                                    <Grid xs={6} item lg={12}>
                                      <p>
                                        CARD:   {getCurrencySymbol(localStorage.getItem('currency_code'))} {(
                                          this.state?.orderSource !== undefined &&
                                            this.state?.orderSource["PAY BY LINK"] !== undefined &&
                                            this.state?.orderSource["PAY BY LINK"]?.card?.sale !== undefined &&
                                            this.state?.orderSource["PAY BY LINK"]?.card?.sale !== null
                                            ? parseFloat(this.state?.orderSource["PAY BY LINK"].card?.sale)?.toFixed(2)
                                            : (0.00).toFixed(2)
                                        )} ({this.state?.orderSource !== undefined && this.state?.orderSource["PAY BY LINK"] !== undefined ? this.state?.orderSource["PAY BY LINK"]?.card?.count || 0 : 0})





                                      </p>

                                    </Grid>


                                  </Grid>

                                  <Grid container spacing={1} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p> CASH :  </p>
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={1} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p> CASH :  </p>
                                    </Grid>
                                  </Grid>

                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid>

                          <Grid item xs={12} md={12} lg={4}>
                            <Paper elevation={3} style={{ padding: '10px', margin: '0px' }}>

                              <Grid container spacing={2} >


                                <Grid item xs={4} lg={12} style={{ textAlign: "center" }}>
                                  <div>
                                    <Typography variant="h6">       <Avat src={qrCode} className="dashboardAvatarCards" /> QR/WEB</Typography>

                                  </div>
                                </Grid>

                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                  <Divider />
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <Grid container spacing={2} >
                                    <Grid xs={6} item>
                                      <p>
                                        CARD(QR): {getCurrencySymbol(localStorage.getItem('currency_code'))} {(
                                          this.state.orderSource?.QR?.card !== undefined &&
                                            this.state.orderSource?.QR?.card !== null
                                            ? parseFloat(this.state.orderSource?.QR?.card?.sale)?.toFixed(2)
                                            : Number(0.00).toFixed(2)
                                        )} ({this.state.orderSource?.QR?.card?.count || 0})

                                      </p>
                                    </Grid>
                                    <Grid xs={6} item>
                                      <p>
                                        {getCurrencySymbol(localStorage.getItem('currency_code'))} {(
                                          this.state.orderSource?.WEB?.card !== undefined &&
                                            this.state.orderSource?.WEB?.card !== null
                                            ? parseFloat(this.state.orderSource?.WEB?.card?.sale)?.toFixed(2)
                                            : Number(0.00).toFixed(2)
                                        )} ({this.state.orderSource?.WEB?.card?.count || 0})

                                      </p>
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p>
                                        INVOICE:
                                      </p>
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} >
                                    <Grid xs={6} item lg={12} style={{ visibility: "hidden" }}>
                                      <p>
                                        INVOICE:
                                      </p>
                                    </Grid>
                                  </Grid>




                                </Grid>
                              </Grid>
                            </Paper>

                          </Grid> */}
                          {/*   </Slider>

                          </div> */}
                          {/*         */}

                          {isMobile ? (
                            <React.Fragment>
                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>
                                      <TableCell style={tableStyle} >Kiosk</TableCell>

                                      {/*    <TableCell>Mobile</TableCell> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow >
                                      <TableCell>Cash</TableCell>
                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.KIOSK?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.cash?.count || 0})</TableCell>



                                    </TableRow>
                                    <TableRow >
                                      <TableCell>Card</TableCell>
                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.KIOSK?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.card?.count || 0})</TableCell>
                                    </TableRow>
                                  
                              
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>

                                      <TableCell style={tableStyle} >Epos</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow >
                                      <TableCell>Cash</TableCell>

                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.EPOS?.cash?.count || 0})</TableCell>


                                    </TableRow>
                                    <TableRow >
                                      <TableCell>Card</TableCell>

                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.EPOS?.card?.count || 0})</TableCell>


                                    </TableRow>
                                      {this.props.settings?.is_dog_dollar_enabled && <TableRow >
                                        <TableCell>Dog Dollar</TableCell>

                                        <TableCell>

                                          <p>
                                            {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.dogDollar?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.dogDollar?.count || 0})
                                          </p>

                                        </TableCell>

                                      </TableRow>
                                      }
                                    <TableRow>
                                      <TableCell>Split</TableCell>

                                      <TableCell>
                                        <p>
                                          {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.split?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.split?.count || 0})
                                        </p>

                                      </TableCell>


                                    </TableRow>
                                      {this.props.settings?.is_pay_by_invoice_enabled &&
                                        <TableRow >
                                          <TableCell>Invoice</TableCell>

                                          <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.invoice?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.invoice?.count || 0})</TableCell>


                                        </TableRow>
                                      }

                                      {this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== undefined &&
                                        this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== null && (
                                          <TableRow >
                                            <TableCell>Uber Eats</TableCell>

                                            <TableCell>
                                              {getCurrencySymbol(localStorage.getItem('currency_code'))} {(
                                                this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== undefined &&
                                                  this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== null
                                                  ? parseFloat(this.state?.orderSource?.EPOS?.["uber eats"]?.sale)?.toFixed(2)
                                                  : (0.00).toFixed(2)
                                              )} ({this.state?.orderSource?.EPOS?.["uber eats"]?.count || 0})
                                            </TableCell>
                                          </TableRow>)
                                      }
                                      {this.state?.orderSource?.EPOS?.deliveroo?.sale !== undefined &&
                                        this.state?.orderSource?.EPOS?.deliveroo?.sale !== null && (
                                          <TableRow >
                                            <TableCell>Deliviroo</TableCell>

                                            <TableCell>
                                              {getCurrencySymbol(localStorage.getItem('currency_code'))}
                                              {(this.state.orderSource?.EPOS?.deliveroo?.sale !== undefined && this.state.orderSource?.EPOS?.deliveroo?.sale !== null
                                                ? parseFloat(this.state.orderSource?.EPOS?.deliveroo?.sale).toFixed(2)
                                                : (0.00).toFixed(2)
                                              )}
                                              ({this.state.orderSource?.EPOS?.deliveroo?.count || 0})
                                            </TableCell>


                                          </TableRow>)}

                                      {this.state?.orderSource?.EPOS?.["just eats"]?.sale !== undefined &&
                                        this.state?.orderSource?.EPOS?.["just eats"]?.sale !== null && (
                                          <TableRow>
                                            <TableCell>Just Eat</TableCell>

                                            <TableCell>
                                              {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                                this.state?.orderSource?.EPOS?.["just eats"]?.sale !== undefined &&
                                                  this.state?.orderSource?.EPOS?.["just eats"]?.sale !== null
                                                  ? parseFloat(this.state?.orderSource?.EPOS?.["just eats"]?.sale)?.toFixed(2)
                                                  : (0.00).toFixed(2)
                                              )} ({this.state?.orderSource?.EPOS?.["just eats"]?.count || 0})
                                            </TableCell>

                                          </TableRow>)}

                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>

                                      <TableCell style={tableStyle} >Mpos</TableCell>


                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow >
                                      <TableCell>Cash</TableCell>

                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.MPOS?.cash?.count || 0})</TableCell>


                                    </TableRow>
                                    <TableRow >
                                      <TableCell>Card</TableCell>

                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.MPOS?.card?.count || 0})</TableCell>


                                    </TableRow>
                                   
                                    <TableRow>
                                      <TableCell>Split</TableCell>

                                      <TableCell>
                                        <p>
                                          {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.split?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.MPOS?.split?.count || 0})
                                        </p>

                                      </TableCell>


                                    </TableRow>
                                   

                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>

                                      <TableCell style={tableStyle} >Terminal</TableCell>

                                    </TableRow>
                                    </TableHead>
                                    <TableBody>

                                      <TableRow >
                                        <TableCell>Card</TableCell>

                                        <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.TERMINAL?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.TERMINAL?.card?.count || 0})</TableCell>


                                      </TableRow>


                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>

                                      <TableCell style={tableStyle} >Pay by link</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                   
                                    <TableRow >
                                      <TableCell>Card</TableCell>

                                      <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                        this.state?.orderSource !== undefined &&
                                          this.state?.orderSource["PAY BY LINK"] !== undefined &&
                                          this.state?.orderSource["PAY BY LINK"]?.card?.sale !== undefined &&
                                          this.state?.orderSource["PAY BY LINK"]?.card?.sale !== null
                                          ? parseFloat(this.state?.orderSource["PAY BY LINK"].card?.sale)?.toFixed(2)
                                          : (0.00).toFixed(2)
                                      )} ({this.state?.orderSource !== undefined && this.state?.orderSource["PAY BY LINK"] !== undefined ? this.state?.orderSource["PAY BY LINK"]?.card?.count || 0 : 0}))</TableCell>


                                    </TableRow>
                                
                                 
                         

                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>

                                      <TableCell style={tableStyle} >QR</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  
                                    <TableRow >
                                      <TableCell>Card</TableCell>

                                      <TableCell> {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                        this.state.orderSource?.QR?.card !== undefined &&
                                          this.state.orderSource?.QR?.card !== null
                                          ? parseFloat(this.state.orderSource?.QR?.card?.sale)?.toFixed(2)
                                          : Number(0.00).toFixed(2)
                                      )} ({this.state.orderSource?.QR?.card?.count || 0})
                                      </TableCell>

                                    </TableRow>
                              
                                  
           

                                  </TableBody>
                                </Table>
                              </TableContainer>


                              <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell style={tableStyle} >Type</TableCell>
                                      <TableCell style={tableStyle} >Web</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                 
                                    <TableRow >
                                      <TableCell>Card</TableCell>

                                      <TableCell> {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                        this.state.orderSource?.WEB?.card !== undefined &&
                                          this.state.orderSource?.WEB?.card !== null
                                          ? parseFloat(this.state.orderSource?.WEB?.card?.sale)?.toFixed(2)
                                          : Number(0.00).toFixed(2)
                                      )} ({this.state.orderSource?.WEB?.card?.count || 0})</TableCell>

                                    </TableRow>
                               
                                  
                                  
                           

                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </React.Fragment>
                          ) : (
                            <TableContainer component={Paper} sx={{ mt: 3 }}>
                              <Table>
                                <TableHead>
                                  <TableRow >
                                    <TableCell style={tableStyle} >Type</TableCell>
                                    <TableCell style={tableStyle} >Kiosk</TableCell>
                                    <TableCell style={tableStyle} >Epos</TableCell>
                                    <TableCell style={tableStyle} >Mpos</TableCell>
                                    <TableCell style={tableStyle} >Terminal</TableCell>
                                    <TableCell style={tableStyle} >Pay by link</TableCell>
                                    <TableCell style={tableStyle} >QR</TableCell>
                                    <TableCell style={tableStyle} >Web</TableCell>
                                    {/*    <TableCell>Mobile</TableCell> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow >
                                    <TableCell>Cash</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.KIOSK?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.cash?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.EPOS?.cash?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.cash?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.MPOS?.cash?.count || 0})</TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>{""}</TableCell>

                                  </TableRow>
                                  <TableRow >
                                    <TableCell>Card</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.KIOSK?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.card?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.EPOS?.card?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.MPOS?.card?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.TERMINAL?.card?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.TERMINAL?.card?.count || 0})</TableCell>
                                    <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                      this.state?.orderSource !== undefined &&
                                        this.state?.orderSource["PAY BY LINK"] !== undefined &&
                                        this.state?.orderSource["PAY BY LINK"]?.card?.sale !== undefined &&
                                        this.state?.orderSource["PAY BY LINK"]?.card?.sale !== null
                                        ? parseFloat(this.state?.orderSource["PAY BY LINK"].card?.sale)?.toFixed(2)
                                        : (0.00).toFixed(2)
                                    )} ({this.state?.orderSource !== undefined && this.state?.orderSource["PAY BY LINK"] !== undefined ? this.state?.orderSource["PAY BY LINK"]?.card?.count || 0 : 0})</TableCell>
                                    <TableCell> {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                      this.state.orderSource?.QR?.card !== undefined &&
                                        this.state.orderSource?.QR?.card !== null
                                        ? parseFloat(this.state.orderSource?.QR?.card?.sale)?.toFixed(2)
                                        : Number(0.00).toFixed(2)
                                    )} ({this.state.orderSource?.QR?.card?.count || 0})
                                    </TableCell>
                                    <TableCell> {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                      this.state.orderSource?.WEB?.card !== undefined &&
                                        this.state.orderSource?.WEB?.card !== null
                                        ? parseFloat(this.state.orderSource?.WEB?.card?.sale)?.toFixed(2)
                                        : Number(0.00).toFixed(2)
                                    )} ({this.state.orderSource?.WEB?.card?.count || 0})</TableCell>

                                  </TableRow>
                                      {this.props.settings?.is_dog_dollar_enabled && <TableRow >
                                        <TableCell>Dog Dollar</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>
                                          {this.props.settings?.is_dog_dollar_enabled &&
                                            <p>
                                              {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.orderSource?.EPOS?.dogDollar?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.dogDollar?.count || 0})
                                            </p>
                                          }
                                        </TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>

                                      </TableRow>}
                                  <TableRow>
                                    <TableCell>Split</TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>
                                      <p>
                                        {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.split?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.split?.count || 0})
                                      </p>

                                    </TableCell>
                                    <TableCell>
                                      <p>
                                        {getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.split?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.MPOS?.split?.count || 0})
                                      </p>

                                    </TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>{""}</TableCell>
                                    <TableCell>{""}</TableCell>

                                  </TableRow>
                                      {this.props.settings?.is_pay_by_invoice_enabled &&
                                        <TableRow >
                                          <TableCell>Invoice</TableCell>
                                          <TableCell>{""}</TableCell>
                                          <TableCell>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.invoice?.sale || Number(0.00).toFixed(2)} ({this.state.orderSource?.EPOS?.invoice?.count || 0})</TableCell>
                                          <TableCell>{""}</TableCell>
                                          <TableCell>{""}</TableCell>
                                          <TableCell>{""}</TableCell>
                                          <TableCell>{""}</TableCell>
                                          <TableCell>{""}</TableCell>

                                        </TableRow>}
                                  {this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== undefined &&
                                    this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== null && (<TableRow >
                                      <TableCell>Uber Eats</TableCell>
                                      <TableCell></TableCell>
                                      <TableCell>
                                        {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                          this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== undefined &&
                                            this.state?.orderSource?.EPOS?.["uber eats"]?.sale !== null
                                            ? parseFloat(this.state?.orderSource?.EPOS?.["uber eats"]?.sale)?.toFixed(2)
                                            : (0.00).toFixed(2)
                                        )} ({this.state?.orderSource?.EPOS?.["uber eats"]?.count || 0})
                                      </TableCell>
                                      <TableCell>{""}</TableCell>
                                      <TableCell>{""}</TableCell>
                                      <TableCell>{""}</TableCell>

                                    </TableRow>
                                    )}
                                  {this.state?.orderSource?.EPOS?.deliveroo?.sale !== undefined &&
                                    this.state?.orderSource?.EPOS?.deliveroo?.sale !== null && (
                                      <TableRow >
                                        <TableCell>Deliviroo</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                          {getCurrencySymbol(localStorage.getItem('currency_code'))}
                                          {(this.state.orderSource?.EPOS?.deliveroo?.sale !== undefined && this.state.orderSource?.EPOS?.deliveroo?.sale !== null
                                            ? parseFloat(this.state.orderSource?.EPOS?.deliveroo?.sale).toFixed(2)
                                            : (0.00).toFixed(2)
                                          )}
                                          ({this.state.orderSource?.EPOS?.deliveroo?.count || 0})
                                        </TableCell>

                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>
                                        <TableCell>{""}</TableCell>

                                      </TableRow>
                                    )}
                                      {this.state?.orderSource?.EPOS?.["just eats"]?.sale !== undefined &&
                                        this.state?.orderSource?.EPOS?.["just eats"]?.sale !== null && (
                                          <TableRow>
                                            <TableCell>Just Eat</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                              {getCurrencySymbol(localStorage.getItem('currency_code'))}{(
                                                this.state?.orderSource?.EPOS?.["just eats"]?.sale !== undefined &&
                                                  this.state?.orderSource?.EPOS?.["just eats"]?.sale !== null
                                                  ? parseFloat(this.state?.orderSource?.EPOS?.["just eats"]?.sale)?.toFixed(2)
                                                  : (0.00).toFixed(2)
                                              )} ({this.state?.orderSource?.EPOS?.["just eats"]?.count || 0})
                                            </TableCell>
                                            <TableCell>{""}</TableCell>
                                            <TableCell>{""}</TableCell>
                                            <TableCell>{""}</TableCell>

                                          </TableRow>
                                        )}

                                      <TableRow style={{ backgroundColor: '#edf0ff' }}>
                                        <TableCell><b>Total </b></TableCell>
                                        <TableCell><b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.KIOSK?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.KIOSK?.total?.count || 0})</b></TableCell>
                                        <TableCell>
                                          <b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.EPOS?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.EPOS?.total?.count || 0})</b>
                                        </TableCell>
                                        <TableCell> <b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.MPOS?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.MPOS?.total?.count || 0})</b></TableCell>
                                        <TableCell>    <TableCell> <b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.TERMINAL?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.TERMINAL?.total?.count || 0})</b></TableCell></TableCell>
                                        <TableCell>
                                         <b> {getCurrencySymbol(localStorage.getItem('currency_code'))}{
                                            this.state?.orderSource?.["PAY BY LINK"]?.card?.sale !== undefined
                                              ? parseFloat(this.state.orderSource["PAY BY LINK"].total.sale).toFixed(2)
                                              : (0.00).toFixed(2)
                                          }
                                          ({this.state?.orderSource?.["PAY BY LINK"]?.total?.count ?? 0}) </b>
                                        </TableCell>

                                        <TableCell>   <b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.QR?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.QR?.total?.count || 0})</b></TableCell>
                                        <TableCell>   <b>{getCurrencySymbol(localStorage.getItem('currency_code'))}{this.state.orderSource?.WEB?.total?.sale || Number(0.00).toFixed(2)}  ({this.state.orderSource?.WEB?.total?.count || 0})</b></TableCell>
                                      </TableRow>
                                      
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}






                        </Grid>
                      </Grid>

                    </Grid>
                    {this.state.statusToShow !== "ACTIVE" && <Collect status={this.state.statusToShow} />}


                  </React.Fragment>)}










              </div>
            </div>





            {/*        <div className="col-12">
              <div className="ms-panel">
                <div className="ms-panel-body">


                  <div className="col-md-6 input-group margintop-30">
                   

                    <MaterialUIPicker text="From" d={this.state.start} />


                    <MaterialUIPicker text="To" d={this.state.end} />
                    <br></br> <br></br> <br></br> <br></br>
         


                  </div>

                </div>
              </div>
            </div>
 */}


            {/*          <div className="col-12">
              <div className="ms-panel">
                <div className="ms-panel-body">


                  <DemoLine />



                </div>
              </div>
            </div> */}


            {/* 

            <div class="col-lg-6 col-md-12 col-sm-6">
              <div class="ms-card"  style={{ padding: "35px" }} >
              <div class="media fs-14"><div class="media-body"><h6>John Doe </h6></div></div>
                <div class="ms-card-body">
                  <DemoLine />
                </div>
              </div>
            </div>


            <div class="col-lg-6 col-md-12 col-sm-6">
              <div class="ms-card"  style={{ padding: "35px" }} >
              <div class="media fs-14"><div class="media-body"><h6>John Doe </h6></div></div>
                <div class="ms-card-body">
                  <DemoLine />
                </div>
              </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="ms-card"  style={{ padding: "35px" }} >
              <div class="media fs-14"><div class="media-body"><h6>John Doe </h6></div></div>
                <div class="ms-card-body">
                  <DemoLine />
                </div>
              </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="ms-card"  style={{ padding: "35px" }} >
              <div class="media fs-14"><div class="media-body"><h6>John Doe </h6></div></div>
                <div class="ms-card-body">
                  <DemoLine />
                </div>
              </div>
            </div>

           */}





            {/* Recent Placed Orders< */}
            <div className="col-12" style={{ marginTop: "30px" }}>
              {/*    <Box sx={{ width: '100%' }}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#5773ff" } }}
                  >
                    <Tab label="Top 10 Items Sold" />
                    <Tab label="ITEMS Sold Out" />
                  </Tabs>
                  <TabPanel value={tab} index={0} className="tabPanel"> */}

              <div className="ms-panel" >
                <div className="ms-panel-body">



                  <div className="row">


                    <React.Fragment >
                      <Recentorder style={{ marginTop: "20px;" }}
                        state={this.state}
                        fetchOrderDetailsByOrderId={this.fetchOrderDetailsByOrderId}
                        fetchFrequentOrders={this.fetchFrequentOrders}
                        changeCurrentPage={this.changeCurrentPage}
                      />
                      <Divider orientation="vertical" flexItem />
                    </React.Fragment>


                    <SoldOut
                      state={this.state} fetchOrderDetailsByOrderId={this.fetchOrderDetailsByOrderId}
                    />

                  </div>

                </div>
              </div>
              {/* </TabPanel> */}
              {/* <TabPanel value={tab} index={1}> */}
              {/*    <div className="ms-panel">
                      <div className="ms-panel-body">

                        <SoldOut
                          state={this.state}
                        />
                      </div>
                    </div> */}
              {/* </TabPanel>

                </AppBar>  </Box> */}

            </div>

            {/* Food Orders */}
            <div
              className="col-md-12"
              style={{ marginTop: "5px", marginBottom: "25px" }}
            >
              {/*    <Trendingorder
                showInitialSetupModal={this.showInitialSetupModal} state={this.state}
              /> */}
            </div>

            {/* Property Details */}
            {this.state.property_details ? (
              <div className="col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-body">
                    <div className="row">
                      <div className="col-md-9">
                        <h4>Pending Table Request</h4>
                        <h6>
                          You can accept or delete table request based on your
                          availablity.
                        </h6>
                      </div>
                      <div className="col-md-3">
                        <a
                          href="/table-management"
                          style={{ float: "right" }}
                          className="btn btn-primary"
                        >
                          Manage Table
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}


          </div>
        </div>

        <div style={{ position: "fixed", bottom: "20px", right: "10px" }}>
          <div> <b>V {settings.APP_VERSION} </b></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  username: state.user.username,
  settings: state.user.settings,
});


const mapDispatchToProps = { setProperty, setPropertyId, setFranchise, setVendor, setCountry, setCountryCode };

export default connect(mapStateToProps, mapDispatchToProps)(Homecontent);
