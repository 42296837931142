import React, { Component } from "react";
import { toast } from "react-toastify";
import * as suiteServiceAPI from "../../services/suite/suiteServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, FormControl, Paper, Stack } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import TimePicker from "../../components/commonComps/TimePicker";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    shifts: [],
    shift: null,
    discount: null,
    businessTypes: [],
    costType: "",
    selectedBusinessType: "",
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortColumn: "discount_code",
    sortDirection: "asc",
    open: false,
    dId: "",
    discount_code: "",
    discountList: [],
    showForm: false,
    showFormDialog: false,
    is_editable: true,
    suiteId: "",
  };

  columns = [
    {
      name: "Suite Name",
      selector: "suite_name",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {row.suite_name}</div>,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>{row.description}</div>
      ),
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>{row.status}</div>
      ),
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        // <div>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.editSuite(row)}
        //     aria-label="Edit"
        //   >
        //     <EditIcon />
        //   </IconButton>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.handleClickOpen(row.suite_id)}
        //     aria-label="delete"
        //     size="large"
        //   >
        //     <DeleteIcon fontSize="inherit" />
        //   </IconButton>
        // </div>
          <Stack direction="row" spacing={2}>
          <Button variant="outlined" startIcon={<EditIcon color="primary" />} onClick={() => this.editSuite(row)}>Edit</Button>
          <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={() => this.handleClickOpen(row.suite_id)}>Delete</Button>

        </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    this.setInitalValues();
    this.fetchAllSuites();
  };

  handleClickOpen = (suiteId) => {
    this.setState({ open: true, suiteId: suiteId, showFormDialog: true });
  };

  handleCancel = () => {
    this.setState(
      {
        shift: null,
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  handleDescription = () => {
    console.log("handle desc.");
  };

  setInitalValues = () => {
    const { shift } = this.state;

    let initialValues = {};

    initialValues = {
      suite_name: shift?.suite_name || "",
      description: shift?.description || "",
    };

    this.setState({ initialValues });
  };

  fetchAllSuites = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await suiteServiceAPI.fetchAllSuite(propertId);

      if (response && response.data.status) {
        this.setState({
          shifts: response?.data?.data,
          isLoading: false,
        });
        // toast.success("shifts fetched")
      } else {
        this.setState({ shifts: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ shifts: [], isLoading: false });
      throw error;
    }
  };

  addSuite = (values, resetForm) => {
    suiteServiceAPI
      .addSuite(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Suite added.");
          resetForm({ values: "" });
          this.setState(
            { shift: null, isLoading: false, showForm: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllSuites();
        } else {
          this.setState(
            { shift: null, showForm: false, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add Shift.. ");
      });
  };

  updateSuite = (values, resetForm) => {
    suiteServiceAPI
      .updateSuite(values)
      .then((response) => {
        if (response.data.status) {
          toast.success("Suite updated successfully. ");
          resetForm({ values: "" });
          this.setState(
            { shift: null, showForm: false, isLoading: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllSuites();
        } else {
          this.setState({ showForm: false, isLoading: false });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error)
        toast.warn("Could not update suite.. ");
      });
  };
  editSuite = (shift) => {
    this.setState({ shift, showForm: true }, () => {
      this.setInitalValues();
    });
  };
  handleShiftSubmit = (values, resetForm) => {
    if (!this.state.shift) {
      this.setState({ isLoading: true });
      values["property_id"] = this.props.propertyId;
      this.addSuite(values, resetForm);
    } else {
      values["suite_id"] = this.state.shift.suite_id;
      this.updateSuite(values, resetForm);
    }
  };

  deleteSuite = (suiteId) => {
    if (suiteId) {
      suiteServiceAPI
        .deleteSuite(suiteId)
        .then((response) => {
          if (response.data.status) {
            toast.success("Suite deleted.");
            this.setState({ open: false, isLoading: false });
            this.fetchAllSuites();
          } else {
            toast.warn(response.data.message);
            this.setState({ isLoading: false, open: false, showForm: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, open: false });
          toast.error("Something went wrong. ");
        });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleViewInstructions = () => {
    window.scroll({
      top:  document.documentElement.scrollHeight, 
      behavior: "smooth", 
    });
  };
  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          shift: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      showForm,
      showFormDialog,
      businessTypes,
    } = this.state;

    return (
      <>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  {showFormDialog && (
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Suite?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Do you really want to proceed?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button
                          onClick={() => this.deleteSuite(this.state.suiteId)}
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Suite Details</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.discount ? "Update" : "Add"} Suite
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ margin: "10px" }}
                    >
                      <div style={{}}>
                        Suite
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details.
                    </p>
                    <Divider sx={{ mb: 4 }} />
                    <Grid
                      container
                      sx={{display:{xs:"block",md:"flex"}}}
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mb: 2,
                          ml: "20px",
                          width: "220px",
                          height: "40px",
                          display: { xs: "block", md: "none" },
                        }}
                        onClick={this.handleViewInstructions}
                      >
                        View Instructions
                      </Button>{" "}
                      <Grid item xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={schema.suiteFormSchema}
                          onSubmit={(values, { resetForm }) => {

                            this.handleShiftSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "30px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={12} md={10} lg={10}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.suite_name &&
                                          errors.suite_name
                                            ? true
                                            : false
                                        }
                                        name="suite_name"
                                        label="Suite Name"
                                        autoComplete="shift_name"
                                        type="text"
                                        variant="standard"
                                        value={values.suite_name || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.suite_name && errors.suite_name
                                          ? errors.suite_name
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={10} lg={10}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={
                                            touched.description &&
                                              errors.description
                                              ? true
                                              : false
                                          }
                                          name="description"
                                          label="Description"
                                          autoComplete="description"
                                          type="text"
                                          variant="standard"
                                          value={values.description || ""}
                                          onChange={handleChange}
                                          aria-describedby="component-error-text"
                                        />

                                        <FormHelperText error>
                                          {touched.description && errors.description
                                            ? errors.description
                                            : null}
                                        </FormHelperText>
                                      </FormControl>
                                  </Grid>

                                
                                  <Grid item xs={12} md={5} lg={5}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      block
                                      sx={{
                                        mt: 2,
                                        mb: 2,
                                        width: "300px",
                                        height: "40px",
                                      }}
                                    >
                                      {this.state?.shift ? "Update" : "Submit"}
                                    </Button>
                                    </Grid>
                                    {this.state?.shift && (
                                      <Grid item xs={12} md={5} lg={5}>
                                      <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        block
                                        onClick={() => this.handleCancel()}
                                        sx={{
                                          mt: 2,
                                          mb: 2,
                                          width: "300px",
                                          height: "40px",
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                  </Grid>
                                    )}
                                </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>{" "}
                      </Grid>
                        <Grid item  xs={12} md={4} lg={4}>
                          <Box sx={{ margin: "20px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                                marginBottom: "20px",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>
                                        You can create a suite in this section.
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Suite Name.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Name of the Suite{" "}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Description.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Description of the suite.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                  </React.Fragment>
                )}

                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-2">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.shifts}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
