import React, { Component } from "react";
import { toast } from "react-toastify";
import * as ChargeServiceAPI from "../../services/deliveryCharges/ChargeServiceAPI";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, FormControl, Paper, Stack } from "@mui/material";
import * as schema from "../../utils/Schema";
import { Form, Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loader from "../../shared/Loader";
import Typography from "@mui/material/Typography";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import {getCurrencySymbol} from "../../config";


/* const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px', // Adjust the padding as needed
        },
      },
    },
  },
}); */
const styles = {
  table: {
    "& .MuiTableCell-root": {
      padding: "4px 8px", // Adjust the padding as needed
    },
  },
};
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const discountTypeOptions = [
  { value: "FLAT", label: "FLAT" },
  { value: "INCREMENTAL", label: "INCREMENTAL" },
];

class index extends Component {
  state = {
    isLoading: false,
    initialValues: {},
    isLoading: false,
    charges: [],
    charge: null,
    discount: null,
    businessTypes: [],
    costType: "",
    selectedBusinessType: "",
    selectedDiscountVoucher: "",
    page: 0,
    rowsPerPage: 10,
    sortColumn: "discount_code",
    sortDirection: "asc",
    open: false,
    dId: "",
    discount_code: "",
    discountList: [],
    showForm: false,
    showFormDialog: false,
    is_editable: true,
    chargeId: "",
  };

  columns = [
    {
      name: "From Distance (kms)",
      selector: "from_distance",
      sortable: true,
      justifyContent: "center",
      cell: (row) => <div> {Number(row.from_distance)?.toFixed(2)} Kms</div>,
    },
    {
      name: "To Distance (kms)",
      selector: "to_distance",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>{Number(row.to_distance)?.toFixed(2)} Kms</div>
        </div>
      ),
    },

    {
      name: "Charge",
      selector: "charge",
      sortable: true,
      justifyContent: "center",
      cell: (row) => (
        <div>
          <div>  {getCurrencySymbol(localStorage.getItem('currency_code')) + Number(row.charge)?.toFixed(2)}</div>
        </div>
      ),
    },

    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        // <div>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.editDiscount(row)}
        //     aria-label="Edit"
        //   >
        //     <EditIcon />
        //   </IconButton>
        //   <IconButton
        //     color="primary"
        //     onClick={() => this.handleClickOpen(row.charge_id)}
        //     aria-label="delete"
        //     size="large"
        //   >
        //     <DeleteIcon fontSize="inherit" />
        //   </IconButton>
        // </div>
         <Stack direction="row" spacing={2}>
         <Button variant="outlined" startIcon={<EditIcon color="primary" />} onClick={() => this.editDiscount(row)}>Edit</Button>
         <Button variant="outlined" startIcon={<DeleteIcon color="primary" />} onClick={() => this.handleClickOpen(row.charge_id)}>Delete</Button>

       </Stack>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        //          minHeight: '70px', // override the row height
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "auto",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        justifyContent: "center",
        "&:hover div": {
          backgroundColor: "transparent",
          color: "white !important",
        },
        div: {
          color: "white !important",
        },
        span: {
          color: "white !important",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "2px", // override the cell padding for data cells
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  componentDidMount = () => {
    console.log("MOUNT");
    this.setInitalValues();
    this.fetchAllDiscounts();
    this.fetchBusinessTypes();
  };

  fetchBusinessTypes = () => {
    SecurityServiceAPI.fetchBusinessType()
      .then((response) => {
        console.log(response.data.status);
        if (response.data.status) {
          const keysArray = Object.keys(response.data.data.business_type_map);

          this.setState({ businessTypes: keysArray });
        } else {
          this.setState({ businessTypes: [] });
        }
      })
      .catch((error) => {
        //  this.setState({ loading: false, show: false });
        console.log(error);
      });
  };

  downloadQRCode = (id) => {
    console.log(document.getElementById(id));
    const canvas = document.getElementById(id)?.querySelector("canvas");
    console.log(canvas);
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = id + ".png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  handleClickOpen = (chargeId) => {
    this.setState({ open: true, chargeId: chargeId, showFormDialog: true });
  };

  handleCancel = () => {
    this.setState(
      {
        charge: null,
        showForm: false,
      },
      () => {
        this.setInitalValues();
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false, dId: "" });
  };

  handleDescription = () => {
    console.log("handle desc.");
  };

  setInitalValues = () => {
    const { charge } = this.state;
    console.log(charge, ">>>>>charge<<<<<<<");

    let initialValues = {};

    initialValues = {
      charge: charge?.charge?.toString() || "",
      currency_code: charge?.currency_code || "",
      from_distance: charge?.from_distance?.toString() || "",
      to_distance: charge?.to_distance?.toString() || "",
      sort_order: charge?.sort_order || "",
      type: charge?.type || "",
    };

    this.setState({ initialValues });
  };

  fetchAllDiscounts = async () => {
    try {
      this.setState({ isLoading: true });
      let propertId = this.props.propertyId;
      const response = await ChargeServiceAPI.fetchAllCharges(propertId);

      if (response && response.data.status) {
        this.setState({
          charges: response?.data?.data,
          discountList: response?.data?.data,
          isLoading: false,
        });
        // this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
        // toast.success("Discount added. ")
      } else {
        this.setState({ charges: [], isLoading: false });
      }
    } catch (error) {
      this.setState({ charges: [], isLoading: false });
      throw error;
    }
  };

  addCharge = (values, resetForm) => {
    values["currency_code"] = "GBP";
    ChargeServiceAPI.addCharge(values)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data, "<<<<<<<addcharge>>>>>>>>");
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Charge added. ");
          resetForm({ values: "" });
          //      this.setState({ discount: null, selectedDiscount: null, selectedDiscountVoucher: null, discount_code: "" });
          this.setState(
            { charge: null, selectedDiscount: null, showForm: false },
            () => {
              this.setInitalValues();
            }
          );
          this.fetchAllDiscounts();
        } else {
          this.setState(
            { charge: null, selectedDiscount: null, showForm: true },
            () => {
              this.setInitalValues();
            }
          );
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add delivery charge.. ");
      });
  };

  updateDiscount = (values, resetForm) => {
    ChargeServiceAPI.updateCharge(values)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          toast.success("Delivery charge updated. ");
          resetForm({ values: "" });
          this.setState({ charge: null, showForm: false }, () => {
            this.setInitalValues();
          });
          this.fetchAllDiscounts();
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not update delivery charge.. ");
      });
  };

  editDiscount = (charge) => {
    console.log(charge);

    this.setState({ charge, showForm: true }, () => {
      this.setInitalValues();
      console.log(this.state);
    });

    /*  DiscountServiceAPI.fetchDiscount(discountId)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.data);
          const discount = response.data.data;
          //    this.setState({ payment_method_type: response.data.data.adyen_info.payment_method_types, isLoading: false });
          //    if (discount?.is_editable) {
          this.setState({ discount, is_editable: discount?.is_editable, selectedDiscount: discount.discount_type, selectedDiscountVoucher: discount?.discount_voucher_type || "", discount_code: "", showForm: true }, () => {
            this.setInitalValues();
            console.log(this.state);
            toast.success("Discount details retrieved.");
          });
          //   } else {
          //  toast.warn("This discount code that is already in use can't be modified.");
          //  }
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.warn("Could not add discount.. ")
      }); */
  };

  handleChargeSubmit = (values, resetForm) => {
    if (!this.state.charge) {
      this.addCharge(values, resetForm);
    } else {
      values["charge_id"] = this.state.charge.charge_id;
      this.updateDiscount(values, resetForm);
    }
  };

  deleteDiscount = (chargeId) => {
    console.log(chargeId);
    if (chargeId) {
      ChargeServiceAPI.deleteCharge(chargeId)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.data);
            toast.success("Delivery charge deleted. ");
            this.setState({ open: false });
            this.fetchAllDiscounts();
          } else {
            toast.warn("Couldn't delete. ");
            this.setState({ isLoading: false, open: false, showForm: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, open: false });
          toast.error("Something went wrong. ");
        });
    }
  };

  filterOrders = (event) => {
    event.preventDefault();
    let filteredData = event.target.value
      ? this.state.discounts.filter((item) =>
          item.discount_code
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
      : this.state.discountList;

    if (filteredData?.length == 0) {
      filteredData = event.target.value
        ? this.state.discounts.filter((item) =>
            item.discount_name
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          )
        : this.state.discountList;
    }

    this.setState({ discounts: filteredData || this.state.discountList });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleViewInstructions = () => {
    window.scroll({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  handleSort = (column) => {
    const { discounts, sortDirection, sortColumn } = this.state;
    let direction = "asc";

    if (column === sortColumn) {
      direction = sortDirection === "asc" ? "desc" : "asc";
    }

    const sortedDiscounts = [...discounts].sort((a, b) => {
      if (direction === "asc") {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });

    this.setState({
      discounts: sortedDiscounts,
      sortColumn: column,
      sortDirection: direction,
    });
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.setState({
        showForm: true,
      });
    } else {
      this.setState(
        {
          showForm: false,
          charge: null,
          is_editable: true,
        },
        () => {
          this.setInitalValues();
        }
      );
    }
  };

  render() {
    const {
      submitting,
      initialValues,
      showForm,
      showFormDialog,
      businessTypes,
    } = this.state;
    const { cl } = this.props;

    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb />
            </div>
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  {showFormDialog && (
                    <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete Delivery Charge?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Do you really want to proceed?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button
                          onClick={() =>
                            this.deleteDiscount(this.state.chargeId)
                          }
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {!showForm && (
                    <React.Fragment>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                          <h5>Delivery Charges</h5>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.showForm}
                          >
                            {this.state.discount ? "Update" : "Add"} Delivery
                            Charge
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
                <div className="ms-panel-body"></div>
                {showForm && (
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ margin: "10px" }}
                    >
                      <div style={{}}>
                        Create Delivery Charge
                        <Button
                          variant="contained"
                          style={{ float: "right" }}
                          color="primary"
                          onClick={this.showForm}
                        >
                          Back
                        </Button>
                      </div>
                    </Typography>
                    <p style={{ margin: "10px" }}>
                      Complete the following details to create a Delivery
                      Charge.
                    </p>
                    <Divider />
                    <br /> <br />
                    <Grid
                      container
                      sx={{display:{xs:"block",md:"flex"}}}
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mb: 2,
                          ml: "20px",
                          width: "220px",
                          height: "40px",
                          display: { xs: "block", md: "none" },
                        }}
                        onClick={this.handleViewInstructions}
                      >
                        View Instructions
                      </Button>{" "}
                      <Grid container xs={12} md={8} lg={8}>
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={schema.chargeSchema}
                          onSubmit={(values, { resetForm }) => {
                            values["property_id"] = this.props.propertyId;
                            //this.handleDiscountSubmit(values, resetForm);
                            this.handleChargeSubmit(values, resetForm);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Box sx={{ margin: "20px", paddingTop: "50px" }}>
                                <Grid container spacing={3} direction="row">
                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.from_distance &&
                                          errors.from_distance
                                            ? true
                                            : false
                                        }
                                        name="from_distance"
                                        label="From Distance (Kms)"
                                        autoComplete="from_distance"
                                        type="text"
                                        variant="standard"
                                        onInput={(event) => {
                                          if (!isNaN(event.target.value)) {
                                            event.target.value = !isNaN(
                                              event.target.value
                                            )
                                              ? event.target.value
                                              : "";
                                          } else {
                                            event.target.value = "";
                                          }
                                        }}
                                        value={values.from_distance || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.from_distance &&
                                        errors.from_distance
                                          ? errors.from_distance
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.to_distance &&
                                          errors.to_distance
                                            ? true
                                            : false
                                        }
                                        name="to_distance"
                                        label="To Distance (Kms)"
                                        autoComplete="to_distance"
                                        type="text"
                                        variant="standard"
                                        onInput={(event) => {
                                          if (!isNaN(event.target.value)) {
                                            event.target.value = !isNaN(
                                              event.target.value
                                            )
                                              ? event.target.value
                                              : "";
                                          } else {
                                            event.target.value = "";
                                          }
                                        }}
                                        value={values.to_distance || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.to_distance &&
                                        errors.to_distance
                                          ? errors.to_distance
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                          
                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.charge && errors.charge
                                            ? true
                                            : false
                                        }
                                        name="charge"
                                        label="Charge"
                                        autoComplete="charge"
                                        type="text"
                                        variant="standard"
                                        value={values.charge || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                        onInput={(event) => {
                                          if (!isNaN(event.target.value)) {
                                            event.target.value = !isNaN(
                                              event.target.value
                                            )
                                              ? event.target.value
                                              : "";
                                          } else {
                                            event.target.value = "";
                                          }
                                        }}
                                      />

                                      <FormHelperText error>
                                        {touched.charge && errors.charge
                                          ? errors.charge
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={5} lg={5}>
                                    <FormControl fullWidth>
                                      <TextField
                                        error={
                                          touched.sort_order &&
                                          errors.sort_order
                                            ? true
                                            : false
                                        }
                                        name="sort_order"
                                        label="Sort Order"
                                        autoComplete="sort_order"
                                        type="number"
                                        variant="standard"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={values.sort_order || ""}
                                        onChange={handleChange}
                                        aria-describedby="component-error-text"
                                      />

                                      <FormHelperText error>
                                        {touched.sort_order && errors.sort_order
                                          ? errors.sort_order
                                          : null}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>

                            
                                
                                <Grid item xs={12} md={5} lg={5}>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    block
                                    sx={{
                                      mt: 2,
                                      mb: 2,
                                      width: "300px",
                                      height: "40px",
                                    }}
                                  >
                                    {this.state?.charge ? "Update" : "Submit"}
                                  </Button>
                                </Grid>

                                {this.state?.charge && (
                                  <Grid item xs={12} md={5} lg={5}>
                                    <Button
                                      type="button"
                                      color="primary"
                                      variant="contained"
                                      block
                                      onClick={() => this.handleCancel()}
                                      sx={{
                                        mt: 2,
                                        mb: 2,
                                        width: "300px",
                                        height: "40px",
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                )}
                          </Grid>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                      <Grid container xs={12} md={4} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Box sx={{ margin: "20px" }}>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "auto",
                                marginBottom: "20px",
                              }}
                            >
                              <Card>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  style={{
                                    background: "#5773ff",
                                    textAlign: "center",
                                    color: "white",
                                    margin: "0px",
                                    padding: "6px 0px",
                                  }}
                                >
                                  Instructions
                                </Typography>
                                <CardContent>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography color="textPrimary" paragraph>
                                      <b>
                                        You can create a delivery charge in this
                                        section.
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ my: 1 }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> From and To Distance .</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        The start and End value for distance
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b> Charge.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Total amount/charge for selected
                                        distance.
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Sort Order.</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        To control the sequence in which they
                                        are presented or applied.Smaller numbers
                                        typically represent higher priority or
                                        an earlier position in the sequence.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}

                {!showForm && (
                  <div className="ms-invoice-table table-responsive mt-2">
                    <DataTable
                      columns={this.columns}
                      customStyles={this.customStyles}
                      pagination
                      paginationRowsPerPageOptions={[
                        10, 25, 50, 100, 500, 1000,
                      ]}
                      data={this.state.charges}
                    />
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(index);
