import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as PaymentLinksServiceAPI from "../../../../services/paymentLinks/PaymentLinksServiceAPI";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import { connect } from "react-redux";
import { setTransactionDetails } from "../../../../redux/misc/miscSlice";
import {getCurrencySymbol} from "../../../../config";


const styles = (theme) => ({
  banner: {
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5", // Background color of the banner
  },
  status: {
    color: "#fff", // Text color for status label
    backgroundColor: "#3f51b5", // Background color for status label
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    fontWeight: "bold",
  },
  amount: {
    fontSize: "1.5rem", // Font size for the amount
    marginTop: theme.spacing(1),
  },
  preference: {
    marginTop: theme.spacing(1),
  },
});
class Detailcontent extends Component {
  state = {
    transactionDetails: [],
    payout_summary: "",
    row: "",
    total: 0,
    payout_id: "",
    origin: "",
    type: "",
    currency: "",
    today_date: new Date().toLocaleDateString(),
    open: false,
    openPreAuth: false,
    openPreAuthCancel: false,
    status: "",
    amount: "",
    lab: "",
    updated_value: "",
  };

  componentDidMount = () => {
    const state = this.props.history.location.state;
    console.log(this.props.transaction);
    if (this.props?.history?.location?.state?.row) {
      this.props.setTransactionDetails(
        this.props?.history?.location?.state?.row
      );
      this.setState({
        row: this.props?.history?.location?.state?.row,
        status: this.props?.history?.location?.state?.row?.transaction_status,
        is_pre_auth: this.props?.history?.location?.state?.row?.is_pre_auth,
        updated_value: this.props?.history?.location?.state?.row?.value,
      });

      console.log(this.props.history.location.state);
      const payload = { transaction_id: state?.row?.transaction_id };
    } else {
      console.log(this.props?.transactionDetails);
      this.setState({
        row: this.props?.transactionDetails,
        status: this.props?.transactionDetails?.transaction_status,
        is_pre_auth: this.props?.transactionDetails?.is_pre_auth,
        updated_value: this.props?.transactionDetails?.value,
      });
      const payload = {
        transaction_id: this.props?.transactionDetails?.transaction_id,
      };
    }

    //  this.fetchPayoutDetails(payload)
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handlePreauthOpen = (text) => {
    this.setState({ openPreAuth: true, amount: "", lab: text });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCancelTrasactionClose = () => {
    this.setState({ openPreAuthCancel: false });
  };

  handleClosePreAuth = () => {
    this.setState({ openPreAuth: false, amount: "" });
  };

  handlePreAuthCancel = () => {
    this.setState({ openPreAuthCancel: true });
  };

  updateOrderStatus = () => {
    this.setState({
      /*  isLoading: true, */ open: false,
    });
    console.log("Filter");

    const payload = {
      transaction_id: this.props?.history?.location?.state?.row?.transaction_id,
      reference: this.props?.history?.location?.state?.row?.reference,
    };

    PaymentLinksServiceAPI.refundTransactionOrder(payload)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          console.log(response);
          toast.success("Payment refund intitiated.");
          this.setState({ open: false, isLoading: false });
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          this.setState({
            data: [],
            open: false,
            isLoading: false,
          });
          toast.warn("Couldn't refund payment.");
        }
      })
      .catch((err) => {
        toast.warning("Error : ", err);
        console.log("Error: ", err);
        toast.success("Something has gone wrong. ");
        this.setState({
          isLoading: false,
          open: false,
        });
      });
  };

  cancelTransaction = () => {
    this.setState({
      /*  isLoading: true, */ openPreAuthCancel: false,
    });

    const payload = {
      transaction_id: this.props?.history?.location?.state?.row?.transaction_id,
    };

    PaymentLinksServiceAPI.cancelTransaction(payload)
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          console.log(response);
          toast.success("Transaction cancelled.");
          this.setState({ openPreAuthCancel: false, isLoading: false });
          setTimeout(() => {
            window.history.back();
          }, 2000);
        } else {
          this.setState({
            openPreAuthCancel: false,
            isLoading: false,
          });
          toast.warn("Couldn't cancel transaction.");
        }
      })
      .catch((err) => {
        toast.warning("Error : ", err);
        console.log("Error: ", err);
        toast.success("Something has gone wrong.");
        this.setState({
          isLoading: false,
          openPreAuthCancel: false,
        });
      });
  };

  render() {
    const { classes, amount, preference } = this.props;
    const { status, is_pre_auth, updated_value, lab, row } = this.state;
    console.log(row, "old row ");
    return (
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
            <div className="ms-panel">
              <div className="ms-panel-header header-mini">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="mt-3 d-inline">
                      {" "}
                      {getCurrencySymbol(localStorage.getItem('currency_code')) + this.state.row?.value?.toFixed(2) ||
                        Number(0).toFixed(2)}{" "}
                    </h4>{" "}
                    {this.state.row?.transaction_status && (
                      <Chip
                        style={{ color: "white" }}
                        className="d-inline"
                        label={this.state.row?.transaction_status}
                      />
                    )}
                  </div>
                  {/*    <div className="invoice-buttons text-right"> */}{" "}
                  {/* <Link to="#" className="btn btn-primary mr-2">Print</Link> */}
                  {/* <Button variant="contained" onClick={() => this.fetchPayoutReport()} >Download </Button> */}
                  {/* 
                                </div> */}
                </div>
              </div>
              <br /> <br />
              <Divider variant="middle" />
              <div className="ms-panel-body">
                <Dialog
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minWidth: "350px", // Set your desired width
                    },
                  }}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Refund Amount ?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to proceed?
                      <br />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>No</Button>
                    <Button onClick={() => this.updateOrderStatus()}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>

                <React.Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Grid
                        container
                        spacing={4}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={4} lg={2}>
                          <div>Transaction date</div>
                          <div> {this.state.row?.transaction_date}</div>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} md={4} lg={2} sx={{ ml: 2 }}>
                          <div>Payment method</div>
                          <div>
                            {" "}
                            {this.state.row?.card_number
                              ? "*******" + this.state.row?.card_number
                              : ""}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4} lg={6}>
                          {status === "COMPLETED" && (
                            <React.Fragment>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ float: "right" }}
                                  color="primary"
                                  onClick={this.handleClickOpen}
                                >
                                  REFUND
                                </Button>
                              </div>
                            </React.Fragment>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>

                <div className="row align-items-center">
                  <div className="col-md-6 ">
                    <div className=" table-heading">
                      <h4>Transaction details</h4>
                    </div>
                    <Divider variant="middle" />

                    <br></br>
                    <div className="ms-invoice-table table-responsive">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Card number
                              </TableCell>
                              <TableCell>
                                {" "}
                                {this.state.row?.card_number
                                  ? "*******" + this.state.row?.card_number
                                  : ""}{" "}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Card Expiry
                              </TableCell>
                              <TableCell>
                                {this.state.row?.card_expiry}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Payment Mode
                              </TableCell>
                              <TableCell>
                                {this.state.row?.payment_mode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Transaction Date
                              </TableCell>
                              <TableCell>
                                {this.state.row?.transaction_date}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="th" scope="row">
                                Payment method
                              </TableCell>
                              <TableCell>
                                {" "}
                                {this.state.row?.payment_method == "mc" &&
                                  "Mastercard"}
                                {this.state.row?.payment_method == "visa" &&
                                  "Visa"}
                                {this.state.row?.payment_method == "amex" &&
                                  "American Express"}
                              </TableCell>
                            </TableRow>

                            {this.state.row.is_pre_auth && (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  Captured Amount
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  {getCurrencySymbol(localStorage.getItem('currency_code')) + this.state.row?.captured_value?.toFixed(2) ||
                                    Number(0).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>

                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ paddingTop: 5, marginTop: 10 }}
                >
                  <br></br>
                  <br></br>
                  <Typography variant="h5" gutterBottom>
                    Metadata
                  </Typography>
                  <Divider variant="middle" />
                </Grid>

                <div className="col-md-6 ">
                  <div className="ms-invoice-table table-responsive">
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="a dense table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Name
                            </TableCell>
                            <TableCell> {this.state.row?.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Email
                            </TableCell>
                            <TableCell> {this.state.row?.email}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Phone No
                            </TableCell>
                            <TableCell>
                              {" "}
                              {this.state.row?.phone_number}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Transaction Id
                            </TableCell>
                            <TableCell>
                              {this.state.row?.transaction_id}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              PSP Refrence
                            </TableCell>
                            <TableCell>
                              {this.state.row?.psp_reference}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" scope="row">
                              Reference
                            </TableCell>
                            <TableCell>{this.state.row?.reference}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionDetails: state.misc?.transactionDetails,
});

const mapDispatchToProps = { setTransactionDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Detailcontent));
