import * as Yup from "yup";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { parse, isValid, isAfter, isBefore } from "date-fns";
dayjs.extend(dayjsPluginUTC);


export const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required."),
});

export const loginSchema = Yup.object().shape({
  password: Yup.string()
    //  .min(8, 'Password must be minimum 8 characters')
    .required("Password is required."),
});

export const forgotPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must contain 8 characters, one uppercase, one number and one special case character"
    )
    .max(25, "You have reached the maximum character limit (25)")
    .required("Invalid password"),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Password does not match")
    .required("Invalid password"),
});

export const createNewPasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Please provide old password"),
  new_password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must contain 8 characters, one uppercase, one number and one special case character"
    )
    .max(25, "You have reached the maximum character limit (25)")
    .required("Invalid password"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Password does not match")
    .required("Invalid password"),
});

// .min(8, 'Password must be of minimum 8 characters')
export const signupSchema = Yup.object().shape({
  businessName: Yup.string()
    .min(2, "Add least 2 characters")
    .max(50, "Only 50 char allowed")
    .required("Name is required"),
  phone: Yup.string()
    .test(
      "dialCode-validation",
      "Phone must contain dialCode",
      function (value) {
        const { dialCode } = this.parent; // Access dialCode from the parent object
        const phoneNumberWithoutDialCode = value
          ? value.replace(dialCode, "")
          : ""; // Remove dial code from phone number
        return Yup.string()
          .matches(/^[0-9]+$/, "Must contain only digits")
          .isValidSync(phoneNumberWithoutDialCode);
      }
    )
    .test(
      "is-ten-digits",
      "Phone number must be 10 digits excluding the dial code",
      function (value) {
        const { dialCode } = this.parent; // Access dialCode from the parent object
        const phoneNumberWithoutDialCode = value
          ? value.replace(dialCode, "")
          : ""; // Remove dial code from phone number
        return phoneNumberWithoutDialCode.length === 10;
      }
    )
    .matches(/^[0-9]+$/, "Must contain only digits"),
  email: Yup.string()
    .min(6)
    .max(100)
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string()
    .max(20)
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must contain 8 characters, one uppercase, one number and one special case character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password does not match")
    .required("Password is required"),
  acceptTerms: Yup.bool().oneOf([true], "Please agree to our Terms & Conditions by clicking on the link below"),
});

export const businessPlaceSchema = Yup.object().shape({
  contact_name: Yup.string().required("Invalid Contact Name"),

  contact_number: Yup.string()
    .min(10, "Contact Number should be of min 10 digits")
    .required("Invalid Contact Number"),

  // email: Yup.string()
  //     .required('email is required')
  //     .email('Invalid email'),

  property_name: Yup.string().required("Invalid Property Name"),

  property_trading_name: Yup.string().required("Invalid Property Trading Name"),

  business_type: Yup.string().required("Invalid Business Type"),

  property_address: Yup.string().required("Invalid Business Address"),

  property_country: Yup.string().required("Invalid Country"),

  property_city: Yup.string().required("Invalid City"),

  postal_code: Yup.string().required("Invalid Post Code"),

  // second_line_address
});

export const itemSchema = Yup.object().shape({
  category_id: Yup.string().required("Category is required"),
  item_identifier: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Name is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be lower than 0")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  item_cost: Yup.number()
    .typeError("Price must be a number")
    .min(0, "Price cannot be less than 0.")
    .max(99999, "Enter a lower number")
    .required("Price is required"),
  item_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  item_preparation_time: Yup.number()
    .typeError("Preparation time must be a number")
    .min(0, "Preparation time cannot be less than 0.")
    .max(999, "Enter a lower number")
    .required("Preparation time is required"),
  /*  item_description: Yup.string()
     .trim()
     .min(2, "Add at least 2 char")
     .max(300, "we recommend max 300 characters.")
     .required("Description is required"),
   item_status: Yup.string().required("Status is required"), */
  /*  total_selectable_extras: Yup.number()
     .min(0, "Total extras cannot be negative")
     .max(100, "Enter a lower number")
     .integer("Total extras must not be decimal")
     .required("Total extras is required"), */
  dietary_info: Yup.object().shape({
    calorie: Yup.number()
      .typeError("Calorie must be a number")
      .min(0, "Calorie cannot be negative")
      .max(9999, "Enter a lower number"),
    protein: Yup.number()
      .typeError("Protien must be a number")
      .min(0, "Protein cannot be negative")
      .max(999, "Enter a lower number"),
    transFat: Yup.number()
      .typeError("Fat  must be a number")
      .min(0, "Fat cannot be negative")
      .max(999, "Enter a lower number"),
  }),
});

export const extrasSchema = Yup.object().shape({
  addon_category_id: Yup.string().required("Category is required"),
  addon_identifier: Yup.string()
    .trim()
    .min(1, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Name is required"),
  addon_cost: Yup.number()
    .typeError("Price  must be a number")
    .min(0, "Price cannot be lower than 0")
    .max(99999, "Enter a lower number")
    .required("Price is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  /*   addon_description: Yup.string()
      .trim()
      .min(1, "Add at least 1 char")
      .max(300, "we recommend max 300 characters.")
      .required("Description is required"), */
  addon_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Maximum must not be decimal")
    .required("Maximum is required"),
  minimum: Yup.number()
    .min(0, "Enter a smaller number")
    .max(99, "Enter a lower number")
    .integer("Minimum must not be decimal")
    .required("Minimum is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
});

export const extrasItemSchema = Yup.object().shape({
  addon_category_id: Yup.string().required("Category is required"),
  linked_item_id: Yup.string().required(
    "Item is required. Choose menu and category first if not selected."
  ),
  addon_identifier: Yup.string()
    .trim()
    .min(1, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Name is required"),
  addon_cost: Yup.number()
    .typeError("Price  must be a number")
    .min(0, "Price cannot be lower than 0")
    .max(99999, "Enter a lower number")
    .required("Price is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  /*  addon_description: Yup.string()
     .trim()
     .min(1, "Add at least 1 char")
     .max(300, "we recommend max 300 characters.")
     .required("Description is required"), */
  addon_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Maximum must not be decimal")
    .required("Maximum is required"),
  minimum: Yup.number()
    .min(0, "Enter a smaller number")
    .max(99, "Enter a lower number")
    .integer("Minimum must not be decimal")
    .required("Minimum is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
});

export const optionsSchema = Yup.object().shape({
  addon_category_id: Yup.string().required("Category is required"),
  addon_identifier: Yup.string()
    .trim()
    .min(1, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Name is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  addon_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /*  addon_description: Yup.string()
     .trim()
     .min(1, "Add at least 1 char")
     .max(300, "we recommend max 300 characters.")
     .required("Description is required"), */
});

export const comboItemSchema = Yup.object().shape({
  item_id: Yup.string().required("Item is required"),
  menu_id: Yup.string().required("Menu is required"),
  category_id: Yup.string().required("Category is required"),
  combo_item_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  extra_price: Yup.number()
    .typeError("Price  must be a number")
    .min(0, "Price cannot be lower than 0")
    .max(99999, "Enter a lower number")
    .required("Price is required"),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Maximum must not be decimal")
    .required("Maximum is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  minimum: Yup.number()
    .min(0, "Enter a smaller number")
    .max(99, "Enter a lower number")
    .integer("Minimum must not be decimal")
    .required("Minimum is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
});

export const comboUpdateItemSchema = Yup.object().shape({
  item_id: Yup.string().required("Item is required"),
  menu_id: Yup.string().required("Menu is required"),
  category_id: Yup.string().required("Category is required"),
  combo_item_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  extra_price: Yup.number()
    .typeError("Price  must be a number")
    .min(0, "Price cannot be lower than 0")
    .max(99999, "Enter a lower number")
    .required("Price is required"),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Maximum must not be decimal")
    .required("Maximum is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  minimum: Yup.number()
    .min(0, "Enter a smaller number")
    .max(99, "Enter a lower number")
    .integer("Minimum must not be decimal")
    .required("Minimum is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
});

export const optionsItemSchema = Yup.object().shape({
  addon_category_id: Yup.string().required("Category is required"),
  linked_item_id: Yup.string().required(
    "Item is required. Choose menu and category first if not selected."
  ),
  addon_identifier: Yup.string()
    .trim()
    .min(1, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Name is required"),
  quantity: Yup.number()
    .min(0, "Quantity cannot be negative")
    .max(999999, "Enter a lower number")
    .integer("Quantity must not be decimal")
    .required("Quantity is required"),
  addon_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /*  addon_description: Yup.string()
     .trim()
     .min(1, "Add at least 1 char")
     .max(300, "we recommend max 300 characters.")
     .required("Description is required"), */
});

const timeRegex = /^(0\d|1\d|2[0-3]):[0-5]\d:[0-5]\d$|^00:00:00$/;
const timeValidation = (value) => {
  let v = new Date(value);

  var hours = v.getHours();
  var minutes = v.getMinutes();
  var seconds = v.getSeconds();

  // Add leading zeros if necessary
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  var timeString = hours + ":" + minutes + ":" + seconds;

  return timeRegex.test(dayjs(timeString, "HH:mm:ss").format("HH:mm:ss"));
};
Yup.addMethod(Yup.string, "time", function () {
  return this.test("time", "Invalid time format", timeValidation);
});

export const menuSchema = Yup.object().shape({
  menu_identifier: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Menu name is required"),
  menu_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /*   menu_description: Yup.string()
      .trim()
      .min(2, "Add at least 2 char")
      .max(300, "we recommend max 300 characters.")
      .required("Description is required"), */
  /*  start_time: Yup.string().time().required(),
   end_time: Yup.string().time().required(), */
  is_buffet: Yup.bool().required("Buffet value is required"),
});

export const paymentLinkSchema = Yup.object().shape({
  merchant_reference: Yup.string()
    .trim()
    .min(3, "Add at least 3 characters")
    .max(40, "we recommend max 40 characters.")
    .required("Merchant reference  is required"),
  value: Yup.number()
    .typeError("Amount must be a number")
    .positive("Value must be greater than zero")
    .max(99999999, "Enter a lower number")
    .required("Amount is required"),
  link_validity: Yup.date()
    .typeError("Please enter correct date time.")
    .nullable()
    .required("Link validity is required"),
  /*   menu_description: Yup.string()
      .trim()
      .min(2, "Add at least 2 char")
      .max(300, "we recommend max 300 characters.")
      .required("Description is required"), */
  /*  start_time: Yup.string().time().required(),
   end_time: Yup.string().time().required(), */
  //is_buffet: Yup.bool().required("Buffet value is required"),
});

export const addonCategorySchema = Yup.object().shape({
  addon_category_identifier: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Category name is required"),
  addon_category_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /*  addon_category_description: Yup.string()
     .trim()
     .min(2, "Add at least 2 char")
     .max(300, "we recommend max 300 characters.")
     .required("Description is required"), */
  minimum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Min must not be decimal")
    .required("Min is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Min must not be decimal")
    .required("Max is required"),
});

export const comboSchema = Yup.object().shape({
  combo_group_identifier: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Category name is required"),
  combo_group_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /*   combo_group_description: Yup.string()
      .trim()
      .min(2, "Add at least 2 char")
      .max(300, "we recommend max 300 characters.")
      .required("Description is required"), */
  minimum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Min must not be decimal")
    .required("Min is required")
    .test(
      "superiorEqual",
      "Min must be smaller or equal than max",
      function (f) {
        const ref = Yup.ref("maximum");
        return f <= this.resolve(ref);
      }
    ),
  maximum: Yup.number()
    .min(0, "Enter a bigger number")
    .max(99, "Enter a lower number")
    .integer("Min must not be decimal")
    .required("Max is required"),
});

export const categorySchema = Yup.object().shape({
  menu_id: Yup.string()
    .required("Menu is required")
    .typeError("Menu is required"),
  category_identifier: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Category name is required"),
  category_sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
  /* category_description: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(300, "we recommend max 300 characters.")
    .required("Description is required"), */
});

export const copyOptionsSchema = Yup.object().shape({
  menu_id: Yup.string()
    .required("Menu is required.")
    .typeError("Menu is required."),
  category_id: Yup.string()
    .required("Category is required.")
    .typeError("Category is required."),
  item_id: Yup.string()
    .required("Item is required.")
    .typeError("Item is required."),
  addon_id: Yup.string()
    .required("Add On Id is required.")
    .typeError("Add On Id is required."),
  sort_order: Yup.number()
    .min(1, "Sort order cannot be lower than 1")
    .max(999, "Enter a lower number")
    .integer("Sort order must not be decimal")
    .required("Sort order is required"),
});

export const receiptSchema = Yup.object().shape({
  receipt_note: Yup.string()
    .required("Receipt Note is required.")
    .typeError("Receipt Note is required."),
});

export const discountSchema = Yup.object().shape({
  discount_code: Yup.string()
    .required("Discount code is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_end_date: Yup.string()
    .required("End date is required.")
    .typeError("End date is required."),
  discount_name: Yup.string()
    .required("Discount Name is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_start_date: Yup.date()
    .required("Discount start date is required")
    .max(
      Yup.ref("discount_end_date"),
      "Discount start date must be on or before the discount end date"
    ),
  discount_end_date: Yup.date().required("Discount end date is required"),
  discount_type: Yup.string()
    .required("Disount type is required.")
    .typeError("Disount type  is required."),
  discount_value: Yup.number()
    .typeError("Discount must be a number")
    .when(["discount_type", "minimum_order_amount"], {
      is: (discountType, minimumOrderAmount) =>
        discountType === "FIXED" && minimumOrderAmount,
      then: Yup.number()
        .min(0, "Fixed discount must be 0 or greater.")
        .max(999, "Fixed discount must be less than or equal to 999.")
        .test(
          "is-less-than-or-equal-to-minimum",
          "Discount value must be lower than or equal to the minimum order amount.",
          function (value) {
            const { minimum_order_amount } = this.parent;
            return value <= minimum_order_amount; // Change this line to include equality check
          }
        )
        .required("Fixed discount is required."),
      otherwise: Yup.number()
        .min(0, "Percentage discount must be 0 or greater.")
        .max(100, "Percentage discount must be 100 or less.")
        .required("Percentage discount is required."),
    }),
  minimum_order_amount: Yup.number()
    .typeError("Amount must be a number")
    .moreThan(0, "Amount cannot be 0 or less.")
    .required("Minimum order amount is required."),
});

export const eposDiscountSchema = Yup.object().shape({
  discount_code: Yup.string()
    .required("Discount code is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_end_date: Yup.string()
    .required("End date is required.")
    .typeError("End date is required."),
  discount_name: Yup.string()
    .required("Discount Name is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_start_date: Yup.date()
    .required("Discount start date is required")
    .max(
      Yup.ref("discount_end_date"),
      "Discount start date must be on or before the discount end date"
    ),
  discount_end_date: Yup.date().required("Discount end date is required"),
  discount_type: Yup.string()
    .required("Disount type is required.")
    .typeError("Disount type  is required."),

  discount_value: Yup.number()
    .typeError("Discount must be a number")
    .when(["discount_type", "minimum_order_amount"], {
      is: (discountType, minimumOrderAmount) =>
        discountType === "FIXED" && minimumOrderAmount,
      then: Yup.number()
        .min(0, "Fixed discount must be 0 or greater.")
        .max(999, "Fixed discount must be less than or equal to 999.")
        .test(
          "is-less-than-or-equal-to-minimum",
          "Discount value must be lower than or equal to the minimum order amount.",
          function (value) {
            const { minimum_order_amount } = this.parent;
            return value <= minimum_order_amount; // Change this line to include equality check
          }
        )
        .required("Fixed discount is required."),
      otherwise: Yup.number()
        .min(0, "Percentage discount must be 0 or greater.")
        .max(100, "Percentage discount must be 100 or less.")
        .required("Percentage discount is required."),
    }),

  minimum_order_amount: Yup.number()
    .typeError("Amount must be a number")
    .moreThan(0, "Amount cannot be 0 or less.")
    .required("Minimum order amount is required."),
});

export const discounVoucherSchema = Yup.object().shape({
  discount_code: Yup.string()
    .trim()
    .required("Discount code is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_name: Yup.string()
    .trim()
    .required("Discount Name is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  discount_start_date: Yup.date()
    .required("Discount start date is required")
    .max(
      Yup.ref("discount_end_date"),
      "Discount start date must be on or before the discount end date"
    ),
  discount_end_date: Yup.date().required("Discount end date is required"),
  discount_type: Yup.string()
    .required("Discount type is required.")
    .typeError("Disount type  is required."),
  discount_voucher_type: Yup.string()
    .required("Disount Voucher type is required.")
    .typeError("Disount Voucher type  is required."),
  discount_voucher_value: Yup.number()
    .typeError("Discount Voucher value must be a number")
    .moreThan(0, "Discount Voucher value cannot be 0 or less.")
    .required("Discount Voucher value is required"),
  discount_value: Yup.number()
    .typeError("Discount must be a number")
    .when(["discount_type", "minimum_order_amount"], {
      is: (discountType, minimumOrderAmount) =>
        discountType === "FIXED" && minimumOrderAmount,
      then: Yup.number()
        .min(0, "Fixed discount must be 0 or greater.")
        .max(999, "Fixed discount must be less than or equal to 999.")
        .test(
          "is-less-than-or-equal-to-minimum",
          "Discount value must be lower than or equal to the minimum order amount.",
          function (value) {
            const { minimum_order_amount } = this.parent;
            return value <= minimum_order_amount; // Change this line to include equality check
          }
        )
        .required("Fixed discount is required."),
      otherwise: Yup.number()
        .min(0, "Percentage discount must be 0 or greater.")
        .max(100, "Percentage discount must be 100 or less.")
        .required("Percentage discount is required."),
    }),
  minimum_order_amount: Yup.number()
    .typeError("Amount must be a number")
    .moreThan(0, "Amount cannot be 0 or less.")
    .required("Minimum order amount is required."),
});

export const discountHappyHourSchema = Yup.object().shape({
  activity_name: Yup.string()
    .required("Activity Name is required.")
    .matches(/^[^\s].*$/, "Leading spaces are not allowed")
    .matches(/^.*[^\s]$/, "Trailing spaces are not allowed"),
  item_list: Yup.string().required("Item is required."),
  /*  activity_start_date: Yup.date()
     .typeError('Please enter correct date time.')
     .required('Activity start date is required')
     .min(dayjs(), "Can't select Activity Start date lower than current date time.")
     .max(Yup.ref('activity_end_date'), 'Activity start date must be on or before the discount end date')
     .test('is-not-in-past', 'Activity start date cannot be older than today', function (value) {
       const today = dayjs().subtract(1, 'day');
       return !value || dayjs(value).isAfter(today);
     }),
   activity_end_date: Yup.date()
     .typeError('Please enter correct date time.')
     .required('Activity end date is required')
     .min(dayjs(), "Can't select Activity End date lower than current date time.")
     .test('is-not-in-past', 'Activity end date  cannot be older than today', function (value) {
       const today = dayjs().subtract(1, 'day');
       return !value || dayjs(value).isAfter(today);
     }),  */
  /*   discount_type: Yup.string().required("Disount type is required.").typeError('Disount type  is required.'), */
  discount_value: Yup.number()
    .typeError("Discount must be a number")
    .moreThan(0, "Discount cannot be 0 or less.")
    .lessThan(101, "Discount cannot be greater than 100.")
    .required("Discount value (percentage) is required"),
});

export const userSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(20, "Max 20 characters allowed")
    .min(2, "Min 2 characters required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  user_pin: Yup.string()
    .required("Pin is required")
    .matches(/^[0-9]{4}$/, "Pin must be 4-digit number"),
});
export const organisationSchema = Yup.object().shape({
  entityType: Yup.string().required("Menu is required"),
});

export const tableReservationSettingSchema = Yup.object().shape({
  table_email: Yup.string().email("Invalid Email").required("Invalid Email"),
  table_phone_number: Yup.string()
    .required("Phone number is required.")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(12, "Length must be 10 digits")
    .max(12, "Length must be 10 digits"),
});

export const tableReservationSettingWithougNoSchema = Yup.object().shape({
  table_email: Yup.string().email("Invalid Email").required("Invalid Email"),
});

export const orderSettingSchema = Yup.object().shape({
  order_email: Yup.string().email("Invalid Email").required("Invalid Email"),
  order_phone_number: Yup.string()
    .required("Phone number is required.")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(12, "Length must be 10 digits")
    .max(12, "Length must be 10 digits"),
});

export const orderSettingNoPhoneSchema = Yup.object().shape({
  order_email: Yup.string().email("Invalid Email").required("Invalid Email"),
});

export const serviceSettingSchema = Yup.object().shape({
  service_email_setting: Yup.string()
    .email("Invalid Email")
    .required("Invalid Email"),
  service_phone_number: Yup.string()
    .required("Phone number is required.")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(12, "Length must be 10 digits")
    .max(12, "Length must be 10 digits"),
});

export const serviceSettingWithougNoSchema = Yup.object().shape({
  service_email_setting: Yup.string()
    .email("Invalid Email")
    .required("Invalid Email"),
});

export const roomSchema = Yup.object().shape({
  room_names: Yup.string()
    .required("Room name is required.")
    .max(5, "Can't enter more than five characters."),
  /* start_time: Yup.string().required("Start time is required."),
  end_time: Yup.string().required("End time is required."),
  reserve_time_slot: Yup.number()
    .min(1, "Time slot can't be lower than 1")
    .integer("Time slot  must not be decimal")
    .required("Time slot can't is required"),
  reserve_time_slot_unit: Yup.string().required("Time slot unit type is required."),
  room_count: Yup.number()
    .min(1, "Room count can't be lower than 1")
    .integer("Room count must not be decimal")
    .required("Room count  is required"),
  minimum_spend_on_room_reservation: Yup.number()
    .min(1, "Min spend on room reservation can't be lower than 1")
    .required("Min spend on room is required"),
  number_of_person_per_room: Yup.number()
    .min(1, "No of person cannot be lower than 1")
    .integer("No of person not be decimal")
    .required("No of person is required"),
 */
});
export const roomBulkSchema = Yup.object().shape({
  room_count: Yup.number()
    .min(1, "Room count can't be lower than 1")
    .integer("Room count must not be decimal")
    .required("Room count  is required"),
});

export const tableSchema = Yup.object().shape({
  start_time: Yup.string().required("Start time is required."),
  end_time: Yup.string().required("End time is required."),
  reserve_time_slot: Yup.number()
    .min(1, "Time slot can't be lower than 1.")
    .integer("Time slot  must not be decimal.")
    .required("Time slot is required."),
  reserve_time_slot_unit: Yup.string().required(
    "Time slot unit type is required."
  ),
  table_count: Yup.number()
    .min(1, "Table count can't be lower than 1")
    .integer("Table count must not be decimal")
    .required("Table count  is required"),
  minimum_spend_on_table_reservation: Yup.number()
    .min(0, "Min spend on table reservation can't be lower than 0")
    .required("Min spend on table is required"),
  number_of_person_per_table: Yup.number()
    .min(1, "No of person cannot be lower than 1")
    .integer("No of person not be decimal")
    .required("No of person is required"),
});

export const tableBulkSchema = Yup.object().shape({
  start_time: Yup.string().required("Start time is required."),
  end_time: Yup.string().required("End time is required."),
  reserve_time_slot: Yup.number()
    .min(1, "Time slot can't be lower than 1.")
    .integer("Time slot  must not be decimal.")
    .required("Time slot is required."),
  reserve_time_slot_unit: Yup.string().required(
    "Time slot unit type is required."
  ),
  table_count: Yup.number()
    .min(1, "Table count can't be lower than 1")
    .integer("Table count must not be decimal")
    .required("Table count  is required"),
  minimum_spend_on_table_reservation: Yup.number()
    .min(0, "Min spend on table reservation can't be lower than 0")
    .required("Min spend on table is required"),
  number_of_person_per_table: Yup.number()
    .min(1, "No of person cannot be lower than 1")
    .integer("No of person not be decimal")
    .required("No of person is required"),
  table_names: Yup.string().required("Table name is required."),
});

export const chargeSchema = Yup.object().shape({
  from_distance: Yup.number()
    .min(0, "From distance  can't be lower than 0")
    .required("From distance  is required"),
  to_distance: Yup.number()
    .min(0, "To distance can't be lower than 0")
    .required("To distance is required")
    .when("from_distance", (fromDistance, schema) => {
      return schema.min(
        fromDistance,
        "To distance must be greater than or equal to From distance"
      );
    }),
  charge: Yup.number()
    .min(0, "Charge  can't be lower than 0")
    .required("Charge is required"),
  sort_order: Yup.number()
    .min(0, "Sord order  can't be lower than 0")
    .integer("Sord order   must not be decimal")
    .required("Sord order  is required"),
});

export const shiftFormSchema = Yup.object().shape({
  shift_name: Yup.string()
    .trim()
    .min(2, "Add at least 2 char")
    .max(40, "we recommend max 40 characters.")
    .required("Shift Name is required"),

  start_time: Yup.string().required("Start Time is required"),

  end_time: Yup.string().required("End Time is required"),
});

export const suiteFormSchema = Yup.object().shape({
  suite_name: Yup.string()
    .trim()
    .min(2, "Add at least 2 character")
    .max(18, "we recommend max 18 characters.")
    .required("Suite Name is required"),
});

export const bookSuiteFormSchema = Yup.object().shape({
  customer_email: Yup.string().email().required("Email is required"),
  suite_id: Yup.string().required("Suite is required"),
  customer_phone_number: Yup.string()
    .test(
      "dialCode-validation",
      "Please enter phone number.",
      function (value) {
        const { dialCode } = this.parent; // Access dialCode from the parent object
        const phoneNumberWithoutDialCode = value
          ? value.replace(dialCode, "")
          : ""; // Remove dial code from phone number
        return Yup.string()
          .matches(/^[0-9]+$/, "Must contain only digits")
          .isValidSync(phoneNumberWithoutDialCode);
      }
    )
    .test(
      "is-ten-digits",
      "Phone number must be 10 digits excluding the dial code",
      function (value) {
        const { dialCode } = this.parent; // Access dialCode from the parent object
        const phoneNumberWithoutDialCode = value
          ? value.replace(dialCode, "")
          : ""; // Remove dial code from phone number
        return phoneNumberWithoutDialCode.length === 10;
      }
    )
    .matches(/^[0-9]+$/, "Must contain only digits"),
  confirmation_email: Yup.string()
    //.min(10, "Phone no should be 10 digits")
    .required("Payment confirmation email is required"),
  customer_username: Yup.string().required("Username is required"),
});

export const stockSchema = Yup.object({
  stock_name: Yup.string().required("Stock Name is required"),
  quantity: Yup.number()
    .required("Current Quantity is required")
    .min(0, "Current Quantity must be greater than or equal to 0"),
  min_quantity: Yup.number()
    .required("Minimum Quantity is required")
    .min(0, "Minimum Quantity must be greater than or equal to 0")
    .test(
      "is-less-than-max-quantity",
      "Minimum Quantity must be less than or equal to Maximum Quantity",
      function (value) {
        return value <= this.parent.max_quantity;
      }
    ),
  max_quantity: Yup.number()
    .required("Maximum Quantity is required")
    .min(0, "Maximum Quantity must be greater than or equal to 0"),
});
