import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as message from "../../utils/Message";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as SecurityServiceAPI from "../../services/user/SecurityServiceAPI";
import CommonModal from "../../shared/Modal/CommonModal";
import ForgotPassword from "./ForgotPassword";
import Password from "../../shared/Password/Password";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { Form, Formik } from "formik";
import * as schema from "../../utils/Schema";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FlapperSpinner } from "react-spinners-kit";
import { connect } from "react-redux";
import { setUsername, setEmail, signIn, signOut, setPropertyId, setMainPropertyId } from "../../redux/user/userSlice";
//import { logout } from "../../redux/user/userSlice";

class Login extends Component {
  state = {
    show: false,
    showOTPBoxes: true,
    loading: false,
    username: "",
    otp: null,
    new_password: "",
    confirm_new_password: null,
    old_password: null,
    oldPasswordShow: false,
    need_password_change: false,
    passwordShow: false,
    confirmPasswordShow: false,
    toggle_password: false,
    email: "",
    password: "",
    property_id: "",
    emailCheck: false
  };

  componentDidMount() {
    //Remove any residual data in localstorage
    localStorage.clear();
    this.props.signOut();
  }

  loginHandler = (data) => {
    this.setState({ loading: true });
    this.props.setEmail(data.email);
    SecurityServiceAPI.signin(data).then((response) => {
      try {
        if (response.data.status) {
          this.setState({ loading: false });
          localStorage.setItem("access_token", response.data.data.access_token);
          localStorage.setItem(
            "refresh_token",
            response.data.data.refresh_token
          );
          this.props.setUsername(response.data.data.username);
          this.props.setPropertyId(response.data.data.property_id);
          this.props.setMainPropertyId(response.data.data.property_id);
          this.props.signIn(response.data.data);
          localStorage.setItem("username", response.data.data.username);
          localStorage.setItem("currency_code", response.data.data.currency_code);

          if (response.data.data && response.data.data.business_trading_name) {
            localStorage.setItem(
              "business_trading_name",
              response.data.data.business_trading_name
            );
          } else {
            localStorage.setItem("business_trading_name", "");
          }

          if (response.data.data && response.data.data.property_id) {
            localStorage.setItem(
              "property_id",
              response.data.data.property_id
            );
          } else {
            localStorage.setItem("property_id", "");
          }
          // this.sweetalertLoginSuccess();
          this.props.history.push("/dashboard");
        } else {
          if (
            response.data.message ===
            "This account is FORCE PASSWORD CHANGE, Please contact Administrator"
          ) {
            this.props.history.push("/create-new-password");
          } else {
            toast.error(response.data.message);
            this.setState({ loading: false });
          }
        }
      } catch (e) {
        toast.error("Couldn't log you in, Please try again");
        this.setState({ loading: false });
      }
    });
  };

  checkEmail = (data) => {
    this.setState({ loading: true });
    this.props.setEmail(data.email);
    SecurityServiceAPI.checkEmail(data.email).then((response) => {
      try {
        if (response.data.status) {
          console.log( response?.data?.data?.need_password_change)
       //   this.setState({ loading: false });
          if (!response?.data?.data?.need_password_change) {
            this.setState({ loading: false, emailCheck: true });
          } else if (response?.data?.data?.need_password_change) {
            this.setState({ loading: false, emailCheck: false });
            this.props.history.push("/create-new-password");
          }
          //this.props.history.push("/dashboard");
        } else {
            this.setState({ loading: false, emailCheck: false });  
        }
      } catch (e) {
        toast.error("Couldn't log you in, Please try again");
        this.setState({ loading: false, emailCheck: false });
      }
    });
  };


  forgotPasswordModalHandler = (e) => {
    if(e)
    e.preventDefault()
    this.setState({ show: !this.state.show, showOTPBoxes: true, email: "", otp: "", new_password: "",  confirm_new_password: null  });
  };

  forgotPasswordHandler = (e) => {
    e.preventDefault();


    const validRegex =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.state.email.match(validRegex)) {
      toast.warn("Please enter correct email Id. ");
      return false;
    }


    SecurityServiceAPI.forgotPassword(this.state.email)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            username: response.data.data.username,
            showOTPBoxes: false,
            email: "",
          });
        } else {
          this.setState({ showOTPBoxes: true, email: "" });
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(message.SOMETHING_WENT_WRONG);
        this.setState({ showOTPBoxes: true, email: "" });
      });
  };

  needPasswordChange = (value) => {
    this.setState({ loading: true, email: value.email });
    SecurityServiceAPI.needPasswordChange(value.email)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.need_password_change) {
            toast.success(message.NEED_PASSWORD_CHANGE_SUCCESS);
            this.setState({ need_password_change: true, loading: false });
          } else {
            this.loginHandler(value);
          }
        } else {
          toast.warn(response.data.message);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  confirmForgotPasswordHandler = (e) => {
    e.preventDefault();
    const payload = {
      new_password: this.state.new_password,
      otp: this.state.otp,
      username: this.state.username,
    };


    if (!this.state.otp) {
      toast.warn("Otp is required. ");
      return false;
    } else if (this.state.otp.toString().length < 6) {
      toast.warn("Otp 6 digits required. ");
      return false;
    }

    SecurityServiceAPI.confirmForgotPassword(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(message.PASSWORD_CHANGE_SUCCESS);
          this.setState({
            show: false,
            email: "",
            username: "",
            otp: null,
            new_password: "",
            confirm_new_password: null,
            showOTPBoxes: true,
          });
        } else {
          toast.warn(response.data.message);
          this.setState({
            show: false,
            email: "",
            username: "",
            otp: null,
            new_password: "",
            confirm_new_password: null,
            showOTPBoxes: false,
          });
        }
      })
      .catch((error) => {
        let mess = `${message.CATCH_ERROR_MESSAGE}`;
        toast.warn(mess, { autoClose: 20000 }); // autoClose is optional, it closes the toast after 5 seconds
        this.setState({ show: true, email: "",  showOTPBoxes: false });
      });
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  handleCommonModal = () => {
    this.setState({ need_password_change: !this.state.need_password_change });
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  togglePasswordHide = () => {
    this.setState({ toggle_password: !this.state.toggle_password });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { loading, toggle_password, emailCheck } = this.state;

    return (
      <div className="ms-body ms-primary-theme ms-logged-out">
        <main className="body-content">
          <div className="ms-content-wrapper ms-auth">
            <Grid container  spacing={0} alignItems="center" justifyContent="center">
              <Grid item lg={6}>
                <div className="ms-auth-bg" alignItems="center" justifyContent="center"/>
              </Grid>
              <Grid item  xs={12} lg={6}>
                <div className="ms-auth-bg" />
                <div className="ms-auth-form">
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={emailCheck ? schema.loginSchema : schema?.emailSchema}
                    onSubmit={(values) => {
                      values['password'] =  values['password']?.trim();
                      if(this.state.emailCheck){
                        this.loginHandler(values);
                      }else{
                        this.checkEmail(values);
                      }             
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            my: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h1" variant="h5">
                            Login
                          </Typography>
                        </Box>
                        { !emailCheck && <TextField
                          error={errors.email && touched.email ? true : false}
                          margin="normal"
                          required
                          fullWidth
                          type="email"
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          size="small"
                          onBlur={handleBlur}
                          value={values.email || null}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                          helperText={
                            errors.email && touched.email ? errors.email : null
                          }
                        /> }
                        {emailCheck &&
                          <>
                            <TextField
                              margin="normal"
                              error={errors.password && touched.password ? true : false}
                              helperText={
                                errors.password && touched.password ? errors.password : null
                              }
                              fullWidth
                              name="password"
                              label="Password *"
                              type={toggle_password ? "text" : "password"}
                              id="password"
                              size="small"
                              autoComplete="current-password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password || null}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {toggle_password ? (
                                      <Visibility
                                        onClick={this.togglePasswordHide}
                                      />
                                    ) : (
                                      <VisibilityOff
                                        onClick={this.togglePasswordHide}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox value="remember" color="primary" />
                              }
                              label="Remember me"
                            />
                          </>
                        }
                        
                        <Grid container>
                          <Grid item>
                            <Link
                              to="#"
                              className="btn-link"
                              onClick={this.forgotPasswordModalHandler}
                            >
                              Forgot Password?
                            </Link>
                          </Grid>
                        </Grid>
                        {loading ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid>
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        ) : (
                          <div>
                            <Button
                              type="submit"
                              fullWidth
                              color="primary"
                              variant="contained"
                              sx={{ mt: 2, mb: 2 }}
                              disabled={errors?.email?.length > 0}
                            >
                              {emailCheck ?   "SIGN IN" : "NEXT"}
                            </Button>
                          </div>
                        )}
                        <Grid container>
                          <Grid item>
                            <Link to="/sign-up" variant="body2">
                              {"Don't have an account? Sign Up"}
                            </Link>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
                </Grid>
            </Grid>
            <ForgotPassword
              state={this.state}
              handleClose={this.forgotPasswordModalHandler}
              forgotPasswordHandler={this.forgotPasswordHandler}
              confirmForgotPasswordHandler={this.confirmForgotPasswordHandler}
              onChangeHandler={this.onChangeHandler}
            />
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setEmail,
  setUsername,
  signIn,
  signOut,
  setPropertyId,
  setMainPropertyId
};


export default connect(null, mapDispatchToProps)(Login);
