import React, { Component, Fragment } from "react";
import { useHistory } from "react-router-dom";
import * as OrderServiceAPI from "../../../services/order/OrderServiceAPI";
import DataTableComponet from "react-data-table-component";
import Breadcrumb from "./Breadcrumb";
import "../../../index.css";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import Loader from "../../../shared/Loader";
import $ from "jquery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactGA from "react-ga";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { getCurrencySymbol } from "../../../config";
class OrderTable extends Component {
  table_columns = [
    {
      name: "ORDER",
      selector: "order_number",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: "bold",
            cursor:"pointer",
            color:"#5773ff"
          }}
          onClick={() => this.fetchOrderDetailsByOrderId(row)}        >
          {row.order_number}
        </div>
      ),
    },
    {
      name: "DISPUTED REASON",
      selector: "reason",
      sortable: false,
      width: "300px",
      cell: (row) => (
        <div>
          <div>{row.reason}</div>
        </div>
      ),
    },

    {
      name: "DISPUTED DATE",
      selector: "dispute_date",
      sortable: false,
      width: "200px",
      cell: (row) => (
        <div>
          <div>{dayjs(row.dispute_date).format("DD MMM YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      name: "PAYMENT DATE",
      selector: "payment_DATE",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <div>{dayjs(row.order_date_time).format("DD MMM YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      name: "DISPUTED STATUS ",
      selector: "disputed_status",
      sortable: false,
      width: "250px",
      cell: (row) => (
        <div>
          <div>
            <b> {row.disputed_status}</b>
          </div>
        </div>
      ),
    },

    {
      name: "DISPUTED AMOUNT",
      selector: "disputed_amount",
      sortable: false,
      cell: (row) => (
        <div>
          <div>
            {getCurrencySymbol(localStorage.getItem("currency_code")) +
              row.disputed_amount}
          </div>
        </div>
      ),
    },
    {
      name: "ACTIONS",
      selector: "actions",
      cell: (row) => (
        <div>
          {/* <IconButton
              color="primary"
              aria-label="view"
                onClick={() => this.handleViewDetails(row)}
            >
              <VisibilityIcon />
            </IconButton> */}
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon color="primary" />}
            onClick={() => this.handleViewDetails(row)}
            sx={{ alignItems: "flex-start" }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  customStyles = {
    rows: {
      style: {
        minHeight: "100px",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px",
        paddingRight: "auto",
      },
    },
    cells: {
      style: {
        minHeight: "50px",
        paddingLeft: "2px",
        paddingRight: "2px",
        pointer: "cursor",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  state = {
    page: 0,
    data: [],
    current_page: 0,
    current_orders_page: 0,
    number_of_elements: 15,
    page_size: 10,
    total_elements: 0,
    total_pages: 0,
    total_order_pages: 0,
    property_id: "",
    search_text: "",
    changeBool: null,
    from_date: null,
    to_date: null,
    date_options_dd: null,
    isLoading: true,
    count: 0,
    isLoadingExcel: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    function setActiveMenuItem() {
      var activeItem = $(".active");
      activeItem.removeClass("active");

      $(this).removeAttr("href");
      var listitems = $(this).parent("li");
      var element = listitems.children();
      localStorage.setItem("activeNavItem", element.attr("id"));
      if (element.hasClass("active")) {
        element.removeClass("active");
        element.find("li").removeClass("active");
        listitems.find(".collapse").slideUp();
      } else {
        element.addClass("active");
        listitems.children(".collapse").slideDown();
        listitems.siblings("li").children(".collapse").slideUp();
        element.siblings("li").removeClass("active");
        element.siblings("li").find("li").removeClass("active");
        listitems.siblings("li").find(".collapse").slideUp();
      }
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      if (currentPage !== "payout" || currentPage !== "transactions") {
      }
    }
    setActiveMenuItem();
    if (!localStorage.getItem("activeNavItem")) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    } else {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[1];
      $("#" + currentPage).addClass("active");
    }
    let property_id = this.props.user.propertyId;
    this.fetchAllOrders(property_id);
  };

  handleViewDetails = (row) => {
    const { history } = this.props;
    history.push("/dispute-details", { rowData: row });
  };
  fetchAllOrders = (payload) => {
    OrderServiceAPI.fetchDisputedOrders(payload).then((response) => {
      if (response.data.status) {
        this.setState({
          data: response.data.data,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  fetchOrderDetailsByOrderId = (order) => {
    this.props.history.push({
      pathname: "/orderdetails",
      state: {
        order: order,
      },
      
    });
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb />
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-body">
                  <div className="row">
                    <div className="col-xl-12 col-md-12 col-sm-12">
                      {this.state.isLoading ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "40px",
                          }}
                        >
                          {" "}
                          <Loader size={50} />{" "}
                        </div>
                      ) : (
                        <React.Fragment>
                          <DataTableComponet
                            searchOrder={this.searchOrder}
                            state={this.state.data}
                            data={this.state.data}
                            columns={this.table_columns}
                            customStyles={this.customStyles}
                            is_expandable_row={false}
                            onSort={this.sortByColumn}
                            handleChangeRowsPerPage={
                              this.handleChangeRowsPerPage
                            }
                            handleChange={this.changeCurrentPage}
                            dense
                            pagination
                            paginationRowsPerPageOptions={[
                              10, 25, 50, 100, 500, 1000,
                            ]}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default connect(mapStateToProps, {})(OrderTable);
