import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment } from "react";
import "./style.css";
import PhoneInputField from "../SignUp/PhoneInputField";
import Alert from "@mui/material/Alert";
import {
  composeValidators,
  minLength,
  validateEmail,
  validateRequired,
} from "../../utils/functions";
const setPhone = (val) => {};

const OrderSetting = (props) => {
  const {
    order_email,
    is_order_email_active,
    is_order_in_software_active,
    is_order_push_notification_active,
    is_order_sms_active,
    order_phone_number,
  } = props.state;

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Order Settings</h6>
            </div>
            <div className="ms-panel-body">
              <Formik
                initialValues={{
                  order_email: order_email,
                  order_phone_number: order_phone_number,
                }}
                enableReinitialize
                onSubmit={(values, errors, status, touched) => {
                  const property = JSON.parse(
                    localStorage.getItem("property_details")
                  );
                  const payload = {
                    email: values.order_email,
                    is_email_active: is_order_email_active,
                    is_in_software_active: is_order_in_software_active,
                    is_push_notification_active:
                      is_order_push_notification_active,
                    is_sms_active: is_order_sms_active,
                    phone_number: values.order_phone_number?.startsWith("+")
                      ? values.order_phone_number
                      : "+" + values.order_phone_number,
                    type: "ORDER SETTINGS",
                    property_id: props.propertyId,
                  };
                  props.updateNotificationSetting(payload);
                }}
              >
                {({ values, errors, status, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="validationCustom14">Email</label>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          {/* <label htmlFor="validationCustom14">Email</label><br /> */}
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              name="is_order_email_active"
                              onChange={() =>
                                props.toggleHandler("is_order_email_active")
                              }
                              checked={is_order_email_active}
                            />
                            <span className="ms-switch-slider round" />
                          </label>
                        </div>
                      </div>

                      {is_order_email_active && (
                        <div className="col-md-4">
                          <div className="form-group">
                            <Field
                              name="order_email"
                              type="text"
                              placeholder="Email"
                              className={
                                "form-control" +
                                (errors.order_email && touched.order_email
                                  ? " is-invalid"
                                  : "")
                              }
                              validate={composeValidators(
                                validateRequired,
                                validateEmail
                              )}
                            />
                            <ErrorMessage
                              name="order_email"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="validationCustom14">SMS</label>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              name="is_order_sms_active"
                              onChange={() =>
                                props.toggleHandler("is_order_sms_active")
                              }
                              checked={is_order_sms_active}
                            />
                            <span className="ms-switch-slider round" />
                          </label>
                        </div>
                      </div>

                      {is_order_sms_active && (
                        <div className="col-md-4">
                          {/* <label htmlFor="phone" > Number</label> */}
                          <div className="form-group">
                            <Field
                              name="order_phone_number"
                              country="gb"
                              inputStyle={{ width: "100%" }}
                              component={PhoneInputField}
                              setPhone={setPhone}
                              className={
                                "form-control" +
                                (errors.order_phone_number &&
                                touched.order_phone_number
                                  ? " is-invalid"
                                  : "")
                              }
                              validate={composeValidators(
                                validateRequired,
                                minLength(11, "Must be 9 characters or more")
                              )}
                            />
                            <ErrorMessage
                              name="order_phone_number"
                              className="invalid-feedback"
                            />
                          </div>
                        
                        </div>
                      ) }
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="validationCustom14">In Software</label>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          {/* <label htmlFor="validationCustom14">Email</label><br /> */}
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              name="is_order_in_software_active"
                              onChange={() =>
                                props.toggleHandler(
                                  "is_order_in_software_active"
                                )
                              }
                              checked={is_order_in_software_active}
                            />
                            <span className="ms-switch-slider round" />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="validationCustom14">
                          Push Notification
                        </label>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          {/* <label htmlFor="validationCustom14">Email</label><br /> */}
                          <label className="ms-switch">
                            <input
                              type="checkbox"
                              name="is_order_push_notification_active"
                              onChange={() =>
                                props.toggleHandler(
                                  "is_order_push_notification_active"
                                )
                              }
                              checked={is_order_push_notification_active}
                            />
                            <span className="ms-switch-slider round" />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        {Array.isArray(errors) ||
                          (Object.values(errors).toString() != "" && (
                            <Alert severity="warning">
                              {Object.values(errors).toString()}
                            </Alert>
                          ))}
                        <div className="form-group">
                          <button
                            className="btn btn-primary d-block float-right"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderSetting;
