import React, { Component } from "react";
import { toast } from "react-toastify";
import * as DelevirictServiceAPI from "../../../../services/integrations/DelevirictServiceAPI";
import { Fragment } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { connect } from "react-redux";
import FolderIcon from "@mui/icons-material/Folder";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../../../shared/Loader";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CheckCircleOutline } from "@mui/icons-material";
import Breadcrumb from "./Breadcrumb";
import { Stack } from "@mui/material";
import { Divider } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Modal } from "antd";
import { Result } from "antd";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

class index extends Component {
  state = {
    isLoading: false,
    payment_method_type: [],
    checked: ["wifi"],
    selectedCard: false,
    grants: [],
    dense: false,
    isChecked: false,
    plan: {},
    terms: {},
    url: {},
    nash: {},
    isModalOpen: false,
    isNashModalOpen: false,
    delevirictPlan: {},
  };

  componentDidMount = () => {
    const dump = this.props.propertyId;
    //this.fetchWebURL();
    this.fetchDeliverictPlan();
    this.fetchDeliverictTerms(dump);
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };


  handleCancelClick = () => {
    this.setState({ isModalOpen: true });
  };
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };



  fetchDeliverictPlan = async () => {
    const dump = this.props.propertyId;
    try {
      const response = await DelevirictServiceAPI.fetchDelevirictPlan(dump);
      if (response.data.status) {
        //this.fetchEposInfo();
        if (response.data?.data) {
          this.setState(
            {
              deliverictPlan: response.data.data,
            },
            () => {
              if (response.data.data.subscription_status === "EXPIRED") {
              }
            }
          );
        }
      } else {
        const err = response.data?.message || "Something went wrong.";
        //   toast.warn(err);
        this.setState({ deliverictPlan: {}, isLoading: false });
      }
    } catch (error) {
      const err = "Something went wrong.";
      this.setState({deliverictPlan: {}, isLoading: false });
      //    toast.error(err);
      throw error;
    }
  };

 

  fetchDeliverictTerms = (payload) => {
    this.setState({ isLoading: true });
    if (payload) {
      DelevirictServiceAPI.fetchDeliverectTerms(payload)
        .then((response) => {
          if (response.data.status) {
            this.setState({ terms: response.data.data, isLoading: false });
          } else {
            this.setState({ terms: {}, isLoading: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, terms: {} });
        });
    } else {
      this.setState({ isLoading: false, terms: {} });
    }
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleSignRequest = () => {
    this.setState({ isLoading: true });
    this.props.history.push({
      pathname: "/delevirict-activate",
      state: {
        terms: this.state.terms,
      },
    });

  };



  render() {
    const {
      selectedCard,
      grants,
      dense,
      isChecked,
      plan,
      isLoading,
      terms,
      url,
      nash,
      isModalOpen,
      isNashModalOpen,
      delevirictPlan,
    } = this.state;
    return (
      <Fragment>
        {this.state.isLoading ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Loader size={50} />{" "}
          </div>
        ) : (
          <div className="">
            <div className="">
              <div className="">
                <br />
                <div className="col-md-12">
                  <Breadcrumb />
                </div>

                <br />
                <Grid
                  container
                  justifyContent={
                    delevirictPlan?.subscription_status == "ACTIVE" ||
                    delevirictPlan?.subscription_status == "CANCELLED"
                      ? "left"
                      : "center"
                  }
                  alignItems={
                    delevirictPlan?.subscription_status == "ACTIVE" ||
                    delevirictPlan?.subscription_status == "CANCELLED"
                      ? "left"
                      : " center"
                  }
                >
                  <Grid item lg={9}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, borderRadius: 2, mb: 2, padding: 5 }}
                    >
                  

                      <hr />
                  



                      {!(
                        delevirictPlan?.subscription_status === "ACTIVE" ||
                        delevirictPlan?.subscription_status === "CANCELLED"
                      ) ? (
                        <Grid container spacing={1}>
                          <React.Fragment>
                            <Grid item xs={12} md={12}>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  <b> Terms and Conditions</b>
                                </Typography>
                              </div>

                              <Typography
                                variant="body1"
                                style={{ padding: "5px" }}
                              >
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    padding: "15px",
                                    maxHeight: "300px", // Corrected property name
                                    overflow: "auto",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: terms?.terms_and_conditions,
                                  }}
                                />
                              </Typography>
                            </Grid>
                          </React.Fragment>{" "}
                        </Grid>
                      ) : (
                        ""
                      )}
                      {!(
                        delevirictPlan?.subscription_status === "ACTIVE" ||
                        delevirictPlan?.subscription_status === "CANCELLED"
                      ) ? (
                        <React.Fragment>
                          <br /> <br /> <br />
                          <Grid
                            container
                            spacing={3}
                            justifyContent="space-between"
                          >
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="outlined"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                              >
                                Back
                              </Button>
                            </Grid>
                            <Grid item lg={6}>
                              <Button
                                fullWidth
                                style={{
                                  borderRadius: "10px",
                                  padding: "10px 0px",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.handleSignRequest();
                                }}
                              >
                                Agree & Continue to Pay
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        ""
                      )}

                      <br /> <br /> <br />
                    </Paper>
                  </Grid>


                </Grid>
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
});

export default withRouter(connect(mapStateToProps, {})(index));
