import React, { Component } from 'react';

import Box from '@mui/material//Box';
export default function StepperControl({ handleClick, currentStep, steps }) {
    return (
        <React.Fragment>



            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}> */}

                <button className="btn btn-primary"
                    onClick={() => handleClick()}
                    color="primary"
                    style= {{ display :  currentStep === 0 ?  "none": "block" }}
                 //   disabled={currentStep === 0}
                >
                    Back
                </button>
              {/*   <Box sx={{ flex: '1 1 auto' }} />
                <button className="btn btn-primary" type='submit'
                    onClick={() => handleClick("next")} color="primary" variant="contained"  >
                    {currentStep === steps.length - 2 ? "Confirm" : "Next"}
                </button> */}
            {/* </Box> */}
        </React.Fragment>
    );
}