import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import Typography from '@mui/material/Typography';


import Grid from "@mui/material/Grid";
import { toast } from 'react-toastify'
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import * as PaymentServiceAPI from '../../../services/payment/PaymentServiceAPI'
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {getCurrencySymbol} from "../../../config";
/* import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

 */
class Detailcontent extends Component {

    state = {
        transactionDetails: [],
        payout_summary: '',
        row: '',
        total: 0,
        payout_id: '',
        origin: '',
        type: '',
        currency: '',
        today_date: new Date().toLocaleDateString(),

    }

    componentDidMount = () => {
        const state = this.props.history.location.state
        this.setState({ row: this.props?.history?.location?.state?.row })

        const payload = { transaction_id: state.row.transaction_id }
        this.fetchPayoutDetails(payload)
    }

    fetchPayoutDetails = (payload) => {
        PaymentServiceAPI.fetchTransactionDetails(payload).then(response => {
            if (response.data.status) {
                this.setState({
                    transactionDetails: response.data.data
                })
            } else {
                toast.error(response.data.message)
            }
        })
    }

    /*     fetchPayoutReport = () => {
    
            const payload = {
                payout_id: this.state.row.transaction_id 
    
            }
    
    
            PaymentServiceAPI.fetchPayoutReports(payload).then((response) => {
                const file = new Blob([response.data], { type: 'application/pdf' });
               const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                this.downloadFile(fileURL);
                //   window.open(fileURL);
            }).catch(error => {
                console.log(error);
            });;
    
    
    
        };
     */
    downloadFile(filePath) {
        var link = document.createElement('a');
        link.href = filePath;
        link.download = "invoice";
        link.click();
    }

    showOrderDetails = (value) => {

        const payload = {
            property_id: this.state.property_id,
            payment_id: value
        }
        PaymentServiceAPI.fetchOrderDetailsByPayoutId(payload).then(response => {
            if (response.data.status) {
                const order = response.data.data;
                this.props.history.push({
                    pathname: '/orderdetails',
                    state: {
                        order: order
                    }
                })
            }
        })
    }


    calculateGrandTotal = () => {
        let subTotal = this.state.transactionDetails?.grand_total;
        let tax = this.state.transactionDetails?.tax_details?.tax_on_order;
        let applicationFee = this.state.transactionDetails?.tax_details?.application_fee_amount;
        let merchantFee = this.state.transactionDetails?.tax_details?.merchant_fee_amount;
        //    let merchantFee = this.state.data?.tax_details?.merchant_fee_amount;
        let additionalCharges = 0;


        if (this.state.transactionDetails?.tax_details?.additional_charges) {
            this.state.transactionDetails.tax_details.additional_charges.forEach((item) => {
                // Convert the additional_charge_value to a float using parseFloat()
                const chargeValue = parseFloat(item?.additional_charge_value);

                // Check if the parsed value is a valid number
                if (!isNaN(chargeValue)) {
                    additionalCharges += chargeValue;
                }
            });
        }


        // Convert all variables to numbers and handle NaN values
        subTotal = Number(subTotal) || 0;
        applicationFee = Number(applicationFee) || 0;
        merchantFee = Number(merchantFee) || 0;
        //     merchantFee = Number(merchantFee) || 0;
        tax = Number(tax) || 0;

        let total = 0;
        if (this.state.transactionDetails.payment_mode !== "cash") {
            total = subTotal + applicationFee + /* merchantFee + */ tax + additionalCharges + merchantFee;
        } else {
            total = subTotal + tax + additionalCharges;
        }



        return total.toFixed(2); // Convert the calculated total to a string with 2 decimal places
    };
    calculateNetAmount = () => {
        let subTotal = this.state.transactionDetails?.grand_total;
        let tax = this.state.transactionDetails?.tax_details?.tax_on_order;
        let applicationFee = this.state.transactionDetails?.tax_details?.application_fee_amount;
        let merchantFee = this.state.transactionDetails?.tax_details?.merchant_fee_amount;
        let totalSplitPayment = 0;
        let discount = this.state.transactionDetails?.discounted_amount;
        let refund = this.state.transactionDetails?.partial_refund_amount;
        let additionalCharges = 0;
        let total = 0;

        if (this.state.transactionDetails.payment_mode !== "cash") {
            if (this.state.transactionDetails?.split_property_fee_map) {
                totalSplitPayment = Object.values(this.state.transactionDetails.split_property_fee_map).reduce(
                    (acc, value) => acc + value,
                    0
                );
            }
        }

        if (this.state.transactionDetails?.tax_details?.additional_charges) {
            this.state.transactionDetails.tax_details.additional_charges.forEach((item) => {
                // Convert the additional_charge_value to a float using parseFloat()
                const chargeValue = parseFloat(item?.additional_charge_value);
        
                // Check if the parsed value is a valid number
                if (!isNaN(chargeValue)) {
                    additionalCharges += chargeValue;
                }
            });
        }

      
        // Convert all variables to numbers and handle NaN values
        subTotal = Number(subTotal) || 0;
        applicationFee = Number(applicationFee) || 0;
        merchantFee = Number(merchantFee) || 0;
        totalSplitPayment = Number(totalSplitPayment) || 0;
        discount = Number(discount) || 0;
        refund = Number(refund) || 0;
        tax = Number(tax) || 0;

        if (this.state.transactionDetails?.payment_mode !== "cash") {
            total = this.calculateGrandTotal() - applicationFee - merchantFee - totalSplitPayment - discount - refund /* + tax */ /* + additionalCharges */;
        } else if (this.state.transactionDetails?.payment_mode == "cash") {
            total = this.calculateGrandTotal() - discount - refund /* + tax */ /* + additionalCharges */;
        }


   //     let total = subTotal - applicationFee - merchantFee - totalSplitPayment - discount - refund + tax + additionalCharges;

        return total.toFixed(2); // Convert the calculated total to a string with 2 decimal places
    };


    render() {

        const columns = [


            {
                name: "CREATED",
                selector: "created",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div ><div>{dayjs(row.created).format('DD-MM-YYYY')}</div></div>
            },

            {
                name: "GROSS AMOUNT",
                selector: "total",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >   {getCurrencySymbol(localStorage.getItem('currency_code'))  + row.total}</div>
            },


            {
                name: "FEE",
                selector: "fee",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >   {getCurrencySymbol(localStorage.getItem('currency_code')) + row.fee}</div>
            },

            {
                name: "TOTAL",
                selector: "amount",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div >  {getCurrencySymbol(localStorage.getItem('currency_code')) + row.amount}</div>
            },




            {
                name: "ORDER NO",
                selector: "description",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div ><div>{getCurrencySymbol(localStorage.getItem('currency_code')) + row.description}</div></div>
            },

            {
                name: "TRANSACTION ID",
                selector: "payout_id",
                sortable: true,
                justifyContent: 'center',
                cell: row => <div><div>{getCurrencySymbol(localStorage.getItem('currency_code')) + row.payout_id}</div></div>
            },



        ]

        const customStyles = {
            rows: {
                style: {
                    //          minHeight: '70px', // override the row height
                    justifyContent: 'center',
                    textAlign: 'center',
                }
            },
            headCells: {
                style: {
                    minHeight: '65px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: '#5773ff',
                    fontSize: '14px',
                    color: 'white',
                    paddingLeft: '16px', // override the cell padding for data cells
                    paddingRight: 'auto',

                },
            },
            cells: {
                style: {
                    paddingLeft: '2px', // override the cell padding for data cells
                    paddingRight: '2px',
                    pointer: 'cursor',
                    justifyContent: 'center',
                    textAlign: 'center',
                },
            },
        };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="ms-panel">
                            <div className="ms-panel-header header-mini">
                                <div className="d-flex justify-content-between">

                                    <div>
                                        <h4 className='mt-3 d-inline'> {getCurrencySymbol(localStorage.getItem('currency_code'))} {this.state.transactionDetails.grand_total ? Number(this.state?.transactionDetails?.grand_total).toFixed(2) : Number(0).toFixed(2)}   </h4>   {this.state.transactionDetails?.status && <Chip style={{ color: "white" }} className="d-inline" label={this.state.transactionDetails.status} />}

                                    </div>

                                    {/*    <div className="invoice-buttons text-right"> */} {/* <Link to="#" className="btn btn-primary mr-2">Print</Link> */}
                                    {/* <Button variant="contained" onClick={() => this.fetchPayoutReport()} >Download </Button> */}
                                    {/* 
                                    </div> */}


                                </div>

                            </div>
                            <br />    <br />
                            <Divider variant="middle" />




                            <div className="ms-panel-body">

                                <React.Fragment>



                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sm={12}>
                                            <Grid
                                                container
                                                spacing={4}
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Grid item xs={12} md={4} lg={2}>
                                                    <div>Transaction date</div>
                                                    <div>  {this.state.transactionDetails.transaction_date}</div>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={12} md={4} lg={2} sx={{ ml: 2 }}>
                                                    <div>Payment method</div>
                                                    <div> {this.state.transactionDetails.card_number ? "*******" + this.state.transactionDetails.card_number : ""}</div>
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                    </Grid>

                                </React.Fragment>


                                {/*              <div className="row align-items-center">

                                    <div className="col-md-6 ">
                                        <div className=" table-heading">
                                            <h4>Timeline</h4>
                                        </div>
                                        <Divider variant="middle" />

                                        <br></br>
                                        <div className="ms-invoice-table table-responsive">

                                            <Timeline
                                                sx={{
                                                    [`& .${timelineOppositeContentClasses.root}`]: {
                                                        flex: 0.2,
                                                    },
                                                }}
                                            >

                                                <TimelineItem>
                                                    <TimelineOppositeContent color="textSecondary">
                                                        09:30 am
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent>INITIATED</TimelineContent>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineOppositeContent color="textSecondary">
                                                        10:00 am
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot color={this.state.transactionDetails?.status == "FAILED" || this.state.transactionDetails?.status == "CANCELLED" ? "secondary" : "primary"} />
                                                    </TimelineSeparator>
                                                    <TimelineContent> {this.state.transactionDetails?.status == "INITIATED" ? "" : this.state.transactionDetails?.status}</TimelineContent>
                                                </TimelineItem>
                                            </Timeline>

                                        </div>
                                    </div>
                                </div>



 */}








                                <div className="row align-items-center">

                                    <div className="col-md-6 ">
                                        <div className=" table-heading">
                                            <h4>Transaction details</h4>
                                        </div>
                                        <Divider variant="middle" />

                                        <br></br>
                                        <div className="ms-invoice-table table-responsive">


                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table" >

                                                    <TableBody >


                                                        <TableRow >
                                                            <TableCell component="th" scope="row"  >Description</TableCell>
                                                            <TableCell> {this.state.transactionDetails.description}</TableCell>

                                                        </TableRow>



                                                        <TableRow >
                                                            <TableCell component="th" scope="row"  >Grand total</TableCell>
                                                            <TableCell  >{getCurrencySymbol(localStorage.getItem('currency_code'))} {Number (this.state?.transactionDetails?.grand_total || Number(0)).toFixed(2)}</TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Card number</TableCell>
                                                            <TableCell> {this.state.transactionDetails.card_number ? "*******" + this.state.transactionDetails.card_number : ""} </TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Card Expiry</TableCell>
                                                            <TableCell >{this.state.transactionDetails.card_expiry_date}</TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Payment Mode</TableCell>
                                                            <TableCell >{this.state.transactionDetails.payment_mode}</TableCell>

                                                        </TableRow>
                                                        <TableRow >
                                                            <TableCell component="th" scope="row">Payment method</TableCell>
                                                            <TableCell> {this.state.transactionDetails.payment_method == "mc" && "Mastercard"}
                                                                {this.state.transactionDetails.payment_method == "visa" && "Visa"}
                                                                {this.state.transactionDetails.payment_method == "amex" && "American Express"}
                                                            </TableCell>

                                                        </TableRow>
                                                       { this.state.transactionDetails.name?(<TableRow >
                                                            <TableCell component="th" scope="row">Name</TableCell>
                                                            <TableCell >{this.state.transactionDetails.name}</TableCell>

                                                        </TableRow>):""}
                                                       { this.state.transactionDetails.email?(<TableRow >
                                                            <TableCell component="th" scope="row">Email</TableCell>
                                                            <TableCell >{this.state.transactionDetails.email}</TableCell>

                                                        </TableRow>):""}
                                                       { this.state.transactionDetails.phoneNumber ?(<TableRow >
                                                            <TableCell component="th" scope="row">Phone Number</TableCell>
                                                            <TableCell >{this.state.transactionDetails.phoneNumber}</TableCell>

                                                        </TableRow>):""}
      



                                                    </TableBody>


                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>


                          {/*       <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }} >
                                    <br></br>
                                    <br></br>
                                    <Typography variant="h5" gutterBottom>
                                        Split Payment Breakdown
                                    </Typography>
                                    <Divider variant="middle" />
                                </Grid> */}
                            {/*     <div className="col-xl-6 col-md-6">
                                    <table className="table">
                                        <tbody>

                                            <tr>  <th>SUB TOTAL: </th><td> {this.state.transactionDetails?.grand_total?.toFixed(2)}</td></tr>
                                            <tr> <th>TAX: </th><td>{this.state.transactionDetails?.tax_details?.tax_on_order?.toFixed(2)}</td></tr>
                                            {this.state.transactionDetails?.payment_mode != "cash" && <tr> <th>FEE: </th><td>{Number(this.state.transactionDetails.tax_details?.application_fee_amount + this.state.transactionDetails.tax_details?.merchant_fee_amount).toFixed(2)}</td></tr>}
                                            {this.state.transactionDetails?.tax_details?.additional_charges?.length > 0 && <React.Fragment>
                                                <tr className="text-center"> <th coslpas="2">Additional Charges </th></tr>
                                                {(this.state.transactionDetails.tax_details?.additional_charges
                                                    ? this.state.transactionDetails?.tax_details?.additional_charges.map((item) => (
                                                        <tr >
                                                            <td>{item?.additional_charge_name}</td>
                                                            <td>{item?.additional_charge_value}</td>
                                                        </tr>
                                                    ))
                                                    : <tr><td colSpan={2}>No data</td></tr>
                                                )}
                                            </React.Fragment>
                                            }
                                            <tr> <th>GRANT TOTAL: </th><td>{this.calculateGrandTotal()}</td></tr>
                                            {this.state.transactionDetails.payment_mode != "cash" && <React.Fragment>
                                                <tr className="text-center"> <th coslpas="2">SPLIT PAYMENT </th></tr>
                                                {(this.state.transactionDetails?.split_property_fee_map
                                                    ? Object.entries(this.state.transactionDetails.split_property_fee_map).map(([key, value]) => (
                                                        <tr key={key}>
                                                            <td>{key}</td>
                                                            <td>{value?.toFixed(2)}</td>
                                                        </tr>
                                                    ))
                                                    : <tr><td colSpan={2}>No data</td></tr>
                                                )}
                                            </React.Fragment>
                                            } */}
                                     
                                    {/*         <tr> <th>DISCOUNT </th><td>{this.state.transactionDetails?.discounted_amount?.toFixed(2)}</td></tr>
                                            <tr> <th>REFUND </th><td>{this.state.transactionDetails?.partial_refund_amount?.toFixed(2)}</td></tr>
                                            <tr> <th>NET AMOUNT </th><td>{this.calculateNetAmount()}</td></tr> */}
                                            {/*  //   <td>{JSON.stringify(this.state.data?.split_property_fee_map)}</td> */}

                                    {/*     </tbody>
                                    </table>
                                </div> */}
                                <Grid item xs={12} md={12} style={{ paddingTop: 5, marginTop: 10 }} >
                                    <br></br>
                                    <br></br>
                                    <Typography variant="h5" gutterBottom>
                                        Metadata
                                    </Typography>
                                    <Divider variant="middle" />
                                </Grid>


                                <div className="col-md-6 ">

                                    <div className="ms-invoice-table table-responsive">


                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table" >

                                                <TableBody >

                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Username</TableCell>
                                                        <TableCell> {this.state.transactionDetails.username}</TableCell>

                                                    </TableRow>



                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >Order Id</TableCell>
                                                        <TableCell  >{this.state.transactionDetails.order_id}</TableCell>

                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >Order number</TableCell>
                                                        <TableCell  >{this.state.transactionDetails.order_number}</TableCell>

                                                    </TableRow>

                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >Transaction Id</TableCell>
                                                        <TableCell  >{this.state.transactionDetails.transaction_id}</TableCell>

                                                    </TableRow>

                                                    <TableRow >
                                                        <TableCell component="th" scope="row"  >PSP Refrence</TableCell>
                                                        <TableCell  >{this.state.transactionDetails.psp_reference}</TableCell>

                                                    </TableRow>


                                                    <TableRow >
                                                        <TableCell component="th" scope="row">Terminal Id</TableCell>
                                                        <TableCell  >{this.state.transactionDetails.terminal_id}</TableCell>

                                                    </TableRow>


                                                </TableBody>


                                            </Table>
                                        </TableContainer>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Detailcontent;