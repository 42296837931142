import React, { Component } from 'react';
import Pagination from '../../../shared/Pagination/Pagination';
import DataTable from 'react-data-table-component';
import * as DashboardServiceAPI from "../../../services/dashboard/DashboardServiceAPI";
import Loader from "../../../shared/Loader";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { connect } from "react-redux";
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';
import soon from '../../../assets/img/icons8-coming-soon-96.png';
class SoldOut extends Component {

    state = {

        page: 0,
        order_details: [],
        current_page: 0,
        number_of_elements: 10,
        page_size: 10,
        total_elements: 0,
        total_pages: 0,
        property_id: null,
        isLoading: true

    };

    constructor(props) {
        super(props)
    };


    componentDidMount = () => {


        /*     (async () => {
                const rawResponse = await fetch('http://18.133.81.78:8086/business/report/fetch-transactions-summary', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(payload)
                });
                const content = await rawResponse.json();
              
                console.log(content);
              })();
     */


    };


    render() {

        const customStyles = {
            rows: {
                style: {
                    //              minHeight: '70px', // override the row height
                }
            },
            headCells: {
                style: {
                    //                   minHeight: '65px',
                    justifyContent: 'left',
                    backgroundColor: '#5773ff',
                    //                   justifyContent: 'center',
                    fontSize: '14px',
                    color: 'white',
                    paddingLeft: '5px',

                    '&:hover div': {
                        backgroundColor: 'transparent',
                        color: 'white !important'
                    },
                    'div': {
                        color: 'white !important'
                    },
                    'span': {
                        color: 'white !important'
                    },

                },

            },
            cells: {
                style: {
                    paddingLeft: '5px', // override the cell padding for data cells
                    paddingRight: '5px',
                    pointer: 'cursor',
                    justifyContent: 'left',
                },
            },
            header: {
                // disable hover and pointer events on header cells
                '&:hover': {
                    pointerEvents: 'none',
                },
                '&:hover div': {
                    backgroundColor: 'transparent',
                    color: 'white'
                },
            },
        };


        const order_list_columns = [

            {
                name: 'Image',
                selector: 'item_image',
                cell: (row) => (
                    <div>
                        {row.item_image ? <Avatar src={row.item_image} /> : <img style={{ width: "45px" }} src={soon} />}
                    </div>
                ),
                sortable: false,
            },

            {
                name: 'Item Name',
                selector: 'item_identifier',
                sortable: true,
                cell: (row) => (
                    <div style={{ cursor: "pointer" }} >
                        <div onClick={() => this.props.fetchOrderDetailsByOrderId(row)}>
                            {row.item_identifier}
                        </div>
                    </div>
                ),
            },
        ];

        return (

            <div className="col-xl-5 col-md-12 col-sm-12  col-xs-12" style={{ overflow: "hidden" }} >
                <div className="table-responsive" style={{ overflow: "hidden" }}>

                    {this.props.state.isLoading ? (
                        <Loader size={30} />
                    ) : (
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Sold Out Items
                            </Typography>

                            <Divider variant="middle" />

                            <DataTable
                                // title="Today's Order"
                                columns={order_list_columns}
                                pagination
                                paginationRowsPerPageOptions={[10]}
                                data={this.props.state.soldOutItems}
                                customStyles={customStyles}
                            />
                        </div>

                    )
                    }
                    {/*     <div className="row" style={{ float: 'right', margin: '25px' }}>
                        <Pagination currentPage={this.props.state.current_page} total_pages={this.props.state.total_pages} handleChange={this.props.changeCurrentPage} />
                    </div>
                    <div style={{ float: 'left', margin: '15px' }}>Total Records : {this.props.state.total_elements}</div> */}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, null)(SoldOut);