import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';

import {
    duplicateItem
} from "../../../../services/product/item/ItemServiceAPI";





export default function FormDialog(props) {



    const [open, setOpen] = React.useState(false);

    const intialValues = { name: "", category: props.item.category_id };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const categoryNames = useSelector((state) => state.menu.categoryNames);

    const selectedCategory = useSelector((state) => state.menu.categoryNames);


    /*
        useEffect(() => {
            // Update the document title using the browser API
          
        
          }); */


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        submitCopy(formValues);

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);

    };


    const submitCopy = (copy) => {

        const payload = {
            name: copy.name,
            category: copy.category,
            itemId: props.itemId,
            sort_order: copy.copy_sort_order
        }



        duplicateItem(payload)
            .then((response) => {
                if (response.data.status) {


                    props.fetchItems();

                    //  props.fetchCategoryNames();
                    toast.success("Item copied!");
                    setOpen(false);

                    //          props.handleQuickAdd("Close");
                } else {
                    /*    setState({
                         submitting: false,
                       }); */
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                /*    this.setState({
                     submitting: false,
                   }); */

                console.log(error);
                toast.error("something went wrong");
            });






    }

    const validate = (values) => {
        let errors = {};

        if (!values.name || /^\s*$/.test(values.name)) {
            errors.name = "Cannot be empty or have blank spaces. ";
        }

        if (!values.copy_sort_order) {
            errors.copy_sort_order = "Sort order is required."
        }



        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);

  
    return (
        <div>


            <Tooltip title="Copy Item" placement="top">
                <Button style={{ color: '#333333' }} size="small" onClick={handleClickOpen} >
                    <FileCopyIcon />
                </Button>
            </Tooltip>


            {/*       {Object.keys(formErrors).length === 0 && isSubmitting && (
                <span className="success-msg">Signed in successfully</span>
            )} */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Copy</DialogTitle>
                <form onSubmit={handleSubmit} noValidate >
                    <DialogContent>
                        <DialogContentText>
                            Copy within a category or a different Category
                            {/* <br></br>  {props.itemId} */}

                            <br></br> <br></br>
                            {/* {props.item.category_id} */}
                        </DialogContentText>

                        <FormControl fullWidth>

                            <InputLabel id="demo-dialog-select-label">Category</InputLabel>

                            <Select
                                labelId="select-category"
                                id="category"

                                name="category"
                                value={formValues.category}
                                onChange={handleChange}
                                input={<OutlinedInput id="category" label="Category" />}
                                defaultValue={props.item.category_id}
                                className="formControl" >

                                {categoryNames &&
                                    categoryNames.map((item) => {
                                        return (
                                            <MenuItem
                                                fullWidth
                                                key={item.category_id}
                                                value={item.category_id}
                                            >
                                                {item.category_identifier}
                                            </MenuItem>
                                        );
                                    })}

                            </Select>

                        </FormControl>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Item Name"
                            type="text"
                            name="name"
                            fullWidth
                            variant="standard"
                            value={formValues.name}
                            onChange={handleChange}
                            className={formErrors.name && "input-error"}
                        />



                        {formErrors.name && (
                            <p className="alert alert-danger alert-outline">{formErrors.name} </p>
                        )}

                        <FormControl fullWidth>

                            <TextField
                                margin="normal"
                                type="number"
                                id="copy_sort_order"
                                label="Sort order"
                                name="copy_sort_order"
                                autoComplete="copy_sort_order"
                                size="small"
                                variant="standard"
                                style={{ position: "relative", bottom: "11px" }}
                                value={formValues.copy_sort_order}
                                onChange={handleChange}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                }}
                                aria-describedby="component-error-text"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        step: "1",
                                    },
                                }}

                            />

                            {formErrors.copy_sort_order && (
                                <p className="alert alert-danger alert-outline">{formErrors.copy_sort_order} </p>
                            )}

                        </FormControl>



                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="Submit" > Copy</Button>
                    </DialogActions>

                </form>
            </Dialog>
        </div>
    );
}
