import React, { useEffect, useState } from "react";
import * as InventoryrServiceAPI from "../../services/inventory/inventoryService";
import Loader from "../../shared/Loader";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumb from "./Breadcrumb";

const Tag = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagId, setTagId] = useState("");
  const [tagName, setTagName] = useState("");
  const [editTag, setEditTag] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchTags();
  }, []);
  const fetchTags = async () => {
    setLoading(true);
    const property_id =
      localStorage.getItem("prop_id") || localStorage.getItem("property_id");
    try {
      const response = await InventoryrServiceAPI.fetchTags(property_id);

      if (response && response.data.status) {
        setTags(response.data.data);
      } else {
        setTags([]);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      setTags([]);
    } finally {
      setLoading(false);
    }
  };
  const handleClickOpen = (tag_id) => {
    setOpen(true);
    setTagId(tag_id);
  };
  const handleClose = () => {
    setOpen(false);
    setTagId("");
  };
  const deleteTag = () => {
    setLoading(true);
    const payload = {
      property_id:
        localStorage.getItem("prop_id") || localStorage.getItem("property_id"),
      tag_id: tagId,
    };
    InventoryrServiceAPI.deleteTag(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message || "Item Tag deleted. ");
          setOpen(false);
          setLoading(false);
          fetchTags();
        } else {
          toast.warn(response.data.message || "Couldn't delete. ");
          setOpen(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setOpen(false);
        setLoading(false);
        toast.error("Something went wrong. ");
      });
  };

  const handleEditTag = (row) => {
    setEditTag(true);
    setTagName(row.tag_name);
    setTagId(row?.tag_id);
    setShowForm(true);
  };
  const handleShowForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
    setEditTag(false);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setTagName(value);
  };

  const addTag = () => {
    const payload = {
      tag_name: tagName,
      property_id:
        localStorage.getItem("prop_id") || localStorage.getItem("property_id"),
    };
    InventoryrServiceAPI.addTag(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message || "Item Tag added. ");
          setLoading(false);
          setTagName("");
          fetchTags();
          setShowForm(false);
        } else {
          toast.warn(response.data.message || "Couldn't add. ");
          setLoading(false);
        }
      })
      .catch((error) => {
        setOpen(false);
        setLoading(false);
        toast.error("Something went wrong. ");
      });
  };
  const updateTag = () => {
    const payload = {
      tag_name: tagName,
      tag_id: tagId,
      property_id:
        localStorage.getItem("prop_id") || localStorage.getItem("property_id"),
    };
    InventoryrServiceAPI.updateTag(payload)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message || "Item Tag updated. ");
          setLoading(false);
          setTagName("");
          setShowForm(false);
          fetchTags();
          setEditTag(false);
          setTagId("");
        } else {
          toast.warn(response.data.message || "Couldn't update. ");
          setLoading(false);
        }
      })
      .catch((error) => {
        setOpen(false);
        setLoading(false);
        toast.error("Something went wrong. ");
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!editTag) {
      addTag();
    } else {
      updateTag();
    }
    setTagName("");
  };
  const handleViewInstructions = () => {
    window.scroll({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      maxWidth: "100px",
    },
    {
      name: "Tag Name",
      selector: "tag_name",
    },
    {
      name: "Tag Status",
      selector: "tag_status",
    },
    {
      name: "Action",
      selector: "action",
      justifyContent: "center",
      cell: (row) => (
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<EditIcon color="primary" />}
            onClick={() => handleEditTag(row)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon color="primary" />}
            onClick={() => handleClickOpen(row.tag_id)}
          >
            Delete
          </Button>
        </Stack>
      ),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        minHeight: "65px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#5773ff",
        fontSize: "14px",
        color: "white",
        paddingLeft: "16px",
        paddingRight: "auto",
      },
    },
    cells: {
      style: {
        paddingLeft: "2px",
        paddingRight: "2px",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "40px",
          }}
        >
          <Loader size={50} />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb />
          </div>
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                {!showForm && (
                  <React.Fragment>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6} lg={6}>
                        <h5>Item Tag Details</h5>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleShowForm}
                        >
                          Add Item Tag
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </div>
              <div className="ms-panel-body"></div>
              {showForm && (
                <React.Fragment>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ margin: "10px" }}
                  >
                    <div style={{}}>
                      Item Tag
                      <Button
                        variant="contained"
                        style={{ float: "right" }}
                        color="primary"
                        onClick={handleShowForm}
                      >
                        Back
                      </Button>
                    </div>
                  </Typography>
                  <p style={{ margin: "10px" }}>
                    Complete the following details.
                  </p>
                  <Divider sx={{ mb: 4 }} />
                  <Grid
                    container
                    sx={{ display: { xs: "block", md: "flex" } }}
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mb: 2,
                        ml: "20px",
                        width: "220px",
                        height: "40px",
                        display: { xs: "block", md: "none" },
                      }}
                      onClick={handleViewInstructions}
                    >
                      View Instructions
                    </Button>{" "}
                    <Grid item xs={12} md={8} lg={8}>
                      <form onSubmit={handleSubmit}>
                        <Box sx={{ margin: "30px" }}>
                          <Grid container spacing={3} direction="row">
                            <Grid item xs={12} md={10} lg={10}>
                              <FormControl fullWidth>
                                <TextField
                                  required
                                  error={error}
                                  name="tag_name"
                                  label="Tag Name"
                                  autoComplete="tag_name"
                                  type="text"
                                  variant="standard"
                                  value={tagName || ""}
                                  onChange={handleChange}
                                  aria-describedby="component-error-text"
                                  inputProps={{ maxLength: 18 }}
                                  helperText="Maximum of 18 characters only"
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={5} lg={5}>
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                block
                                sx={{
                                  mt: 2,
                                  mb: 2,
                                  width: "300px",
                                  height: "40px",
                                }}
                              >
                                {editTag ? "Update" : "Submit"}
                              </Button>
                            </Grid>
                            {editTag && (
                              <Grid item xs={12} md={5} lg={5}>
                                <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  block
                                  onClick={() => handleShowForm()}
                                  sx={{
                                    mt: 2,
                                    mb: 2,
                                    width: "300px",
                                    height: "40px",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </form>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Box sx={{ margin: "20px" }}>
                        <Paper
                          elevation={3}
                          sx={{
                            width: "auto",
                            marginBottom: "20px",
                          }}
                        >
                          <Card>
                            <Typography
                              variant="h5"
                              gutterBottom
                              style={{
                                background: "#5773ff",
                                textAlign: "center",
                                color: "white",
                                margin: "0px",
                                padding: "6px 0px",
                              }}
                            >
                              Instructions
                            </Typography>
                            <CardContent>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography color="textPrimary" paragraph>
                                  <b>
                                    You can create a Item Tags in this section.
                                  </b>
                                </Typography>
                              </Box>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                sx={{ my: 1 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <Typography
                                    sx={{
                                      padding: "5px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    <b>Tag Name.</b>
                                  </Typography>
                                  <Typography
                                    sx={{
                                      padding: "5px",
                                    }}
                                  >
                                    Name of the Item Tag{" "}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {!showForm && (
                <div className="ms-invoice-table table-responsive mt-2">
                  <DataTable
                    columns={columns}
                    customStyles={customStyles}
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    data={tags}
                  />
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Item Tag?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to proceed?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>No</Button>
                      <Button onClick={() => deleteTag()}>Yes</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tag;
