import $ from 'jquery';
import React, { Component } from 'react';
import * as ThemeServiceAPI from '../../services/theme/ThemeServiceAPI';
import Breadcrumb from './Breadcrumb';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Carousel } from 'react-bootstrap'
import Loader from "../../shared/Loader";
import './theme.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
class index extends Component {

    state = {
        themes: [],
        show: false,
        age: '',
        isLoading: false,
        theme: '',
        color: '',
        theme_images: [],
        propId: ''
    }

    componentDidMount = () => {
        const username = localStorage.getItem('username');
        let propId = localStorage.getItem('property_id')
        if (propId) {
            this.setState({ isLoading: true, propId: propId });
            this.getAllThemes();
            this.fetchTheme();
        } else {
            this.setState({ isLoading: false, propId: "" });
        }


    }


    getAllThemes = () => {
        ThemeServiceAPI.getAllThemes().then((response) => {
            if (response.data.status) {
                console.log(response)
                if (response.data.data) {
                    this.setState({ themes: response.data.data, isLoading: false });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    }

    fetchTheme = () => {
        let propId = localStorage.getItem('property_id')
        ThemeServiceAPI.fetchTheme(propId).then((response) => {
            if (response.data.status) {
                console.log(response)
                if (response.data.data) {
                    this.setState({ theme: response.data.data.kiosk_theme_id, isLoading: false });
                }
            } else {
                this.setState({
                    isLoading: false
                });
            }
        }).catch(error => {
            this.setState({
                isLoading: false
            });
        });;
    }

    assignTheme = () => {
        this.setState({
            isLoading: true
        });
        let propId = localStorage.getItem('property_id')
        let kiosk_theme_id = this.state.theme;

        let payload = {
            property_id: propId,
            kiosk_theme_id: this.state.theme
        }
        ThemeServiceAPI.asignTheme(payload).then((response) => {
            if (response.data.status) {
                console.log(response)
                toast.success("Theme assigned. ");
                this.setState({
                    isLoading: false,
                    theme_images: []
                });

            } else {
                this.setState({
                    isLoading: false,
                    theme_images: []
                });
            }
        }).catch(error => {
            this.setState({
                isLoading: false,
                theme_images: []
            });
        });
    }


    handleChange = (event) => {
        this.setState({ theme: event.target.value });
        console.log(this.state.theme);
        const found = this.state.themes.find(element => element.kiosktheme_id === event.target.value);
        console.log(found);
        this.setState({ theme_images: found });
        console.log(this.state);
    };

    render() {

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            appendDots: dots => (
                <div
                  style={{
                    backgroundColor: "none",
                    borderRadius: "10px",
                    padding: "10px",
                    position: "relative",
                    top: "10px"
                  }}
                >
                  <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
              ),
              customPaging: i => (
                <div
                  style={{
                    width: "30px",
                    color: "blue",
                    border: "1px blue solid"
                  }}
                >
                  {i + 1}
                </div>
              )
        };

        const images = [
            {
                src: "https://via.placeholder.com/500x300.png",
                height: "300px"
            },
            {
                src: "https://via.placeholder.com/500x200.png",
                height: "200px"
            },
            {
                src: "https://via.placeholder.com/500x400.png",
                height: "400px"
            }
        ];


        return (
            <React.Fragment>

                <Breadcrumb />

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body">
                                    <Typography variant="h5" gutterBottom>
                                        Set Kiosk Theme
                                    </Typography>
                                    <Divider variant="middle" />

                                    <br></br>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12">
                                            {this.state.isLoading ? <div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}>   <Loader size={50} /> </div>
                                                : <div className="row m-2">


                                                    <div className="col-md-2" style={{ display: "flex", alignItems: "center" }}>


                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Theme</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={this.state.theme}
                                                                label="Theme"
                                                                onChange={this.handleChange}
                                                            >
                                                                {this.state.themes &&
                                                                    this.state.themes.map((item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={item.kiosktheme_id}
                                                                                value={item.kiosktheme_id}
                                                                            >
                                                                                <span style={{ background: item.base_colour, width: "10px", height: "10px" }} > </span>
                                                                                &nbsp; &nbsp; <span>{item.theme_label ? item.theme_label : "NO LABEL"}</span>
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                            </Select>
                                                        </FormControl>
                                                        <br></br><br></br>

                                                    </div>

                                                    <div className="col-md-2" style={{ display: "flex", alignItems: "center" }}>
                                                        <Button
                                                            className="mt-0"
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={this.state.propId == ""}
                                                            onClick={this.assignTheme}
                                                        >
                                                            Assign Theme
                                                        </Button>
                                                    </div>
                                                    <div className="col-md-8" style={{ display: "flex", alignItems: "center" }}>

                                                    </div>

                                                    <div className="row m-2">
                                                        {/* this.state.theme_images?.theme_images &&
                                                            < div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>

                                                                {this.state.theme_images?.theme_images &&
                                                                    <React.Fragment>
                                                                        <Carousel indicators={true}>
                                                                            {this.state.theme_images?.theme_images.map((url, i) => (
                                                                                <Carousel.Item key={i}>
                                                                                    <img className="d-block w-100" src={url} />
                                                                                </Carousel.Item>
                                                                            ))}

                                                                        </Carousel>

                                                                    </React.Fragment>
                                                                }


                                                            </div> */
                                                        }



                                                    </div>


                                                </div>

                                            }




                                        </div>
                                    </div>

                                    {this.state.theme_images?.theme_images &&

                                        < div className="col-md-3" >
                                            <Slider {...settings}>
                                                {this.state.theme_images?.theme_images.map((image, index) => (
                                                    <div key={index}>
                                                        <img src={image} />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>

                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment >
        );
    }
}

export default index;