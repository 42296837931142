import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  TextField, Button, Box, FormControl,
  Grid, Select, MenuItem, InputLabel, FormHelperText,
  Dialog, DialogTitle, DialogContent, FormControlLabel, Switch, Typography
} from '@mui/material';
import { createItem, updateItem, getUnits } from '../../../services/inventory/inventoryService';
import { createBatch, updateBatch, getItems, getReasons, createReason } from '../../../services/inventory/batchService';
import BatchItemList from './BatchItemList';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import * as schema from "../../../utils/Schema";
import RemoveIcon from '@mui/icons-material/Remove';

const styles = {
  label: {
    fontWeight: 'bold',
    marginBottom: '8px',
    display: 'block',
  },
  value: {
    marginBottom: '16px',
  },
};

class ItemForm extends Component {

  state = {
    units: [],
    reasons: [],
    open: false,
    openAdjustments: false,
    initialBatchValues: { quantity: '', batch_name: '', reason: '', },
    initialReasonValues: { reason: '' },
    batchItems: [],
    adjustementReasons: [],
  }


  componentDidMount() {
    this.fetchUnits();
    this.fetchReasons();
    this.fetchAdjustmentReasons();
  }

  fetchAdjustmentReasons = async () => {
    try {
      let payload = {
        property_id: this.props.propertyId,
        type: "ADJUSTMENT"
      };
      const response = await getReasons(payload);
      if (response && response.status) {
        this.setState({ adjustementReasons: response.data?.data });
      } else {
        this.setState({ adjustementReasons: [] });
      }
    } catch (error) {
      console.error('Error fetching reasons:', error);
      this.setState({ adjustementReasons: [] });
    }
  };

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (values?.stock_id) {
      values.propertyId = this.props.propertyId;
      updateItem(values).then(() => {
        toast.success('Stock updated successfully');
        this.props.onSubmit();
        setSubmitting(false);
      });
    } else {
      values.property_id = this.props.propertyId;

      createItem(values).then(response => {
        if (response && response.data && response.data.status) {
          toast.success('Stock created successfully');
          this.props.onSubmit();
          resetForm();
        } else {
          toast.warn(`Failed to update stock: ${response.error}`);
        }
        setSubmitting(false);
      }).catch((error) => {
        console.error(error);
        setSubmitting(false);
        toast.error(`Failed to update stock: ${error}`);
      });
    }
  };

  handleBatchSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (values?.batch_id) {
      //values.propertyId = this.props.propertyId;
      updateBatch(values).then((response) => {
        if (response && response.data && response.data.status) {
          toast.success('Batch updated successfully.');
          //this.props.onSubmit();
          { this.props?.stockId && this.fetchBatchItems(this.props.stockId); }
          resetForm();
          this.handleClose();
        } else {
          toast.warn(`Failed to update item: ${response.error}`);
        }
        setSubmitting(false);
      }).catch((error) => {
        console.error(error);
        setSubmitting(false);
        toast.error(`Failed to create item: ${error}`);
      });
    } else {
      createBatch(values).then(response => {
        if (response && response.data && response.data.status) {
          toast.success('Batch updated successfully.');
          { this.props?.stockId && this.fetchBatchItems(this.props.stockId); }
          //this.props.onSubmit();
          resetForm();
          this.handleClose();
        } else {
          toast.warn(`Failed to create item: ${response.error}`);
        }
        setSubmitting(false);
      }).catch((error) => {
        console.error(error);
        setSubmitting(false);
        toast.error(`Failed to create item: ${error}`);
      });
    }
  };

  handleReasonSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    //values.propertyId = this.props.propertyId;
    const payload = { reasons: values.reason, property_id: this.props.propertyId };
    createReason(payload).then((response) => {
      if (response && response.data && response.data.status) {
        toast.success('Reason created successfully.');
        resetForm();
        this.handleClose();
      } else {
        toast.warn(`Failed to create reason: ${response.error}`);
      }
      setSubmitting(false);
    }).catch((error) => {
      console.error(error);
      setSubmitting(false);
      toast.error(`Failed to create ireason: ${error}`);
    });
  }


  fetchUnits = async () => {
    try {
      const response = await getUnits(this.props.propertyId); // Ensure getUnits returns a Promise
      if (response && response.status) {
        this.setState({ units: response.data?.data });
      } else {
        this.setState({ units: [] });
      }
    } catch (error) {
      console.error('Error fetching units:', error);
      this.setState({ units: [] });
    }
  };
  fetchReasons = async () => {
    try {
      let payload = {
        property_id: this.props.propertyId,
        type: "BATCH"
      };
      const response = await getReasons(payload);
      if (response && response.status) {
        this.setState({ reasons: response.data?.data });
      } else {
        this.setState({ reasons: [] });
      }
    } catch (error) {
      console.error('Error fetching reasons:', error);
      this.setState({ reasons: [] });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true, initialBatchValues: { quantity: '', batch_name: '', reason: '' } });
  };

  handleClickReason = () => {
    this.setState({ openReason: true, initialReasonValues: { reason: '', } });
  };


  handleAdjustmentsClickOpen = () => {
    this.setState({ openAdjustments: true, initialBatchValues: { quantity: '', batch_name: '', reason: ''} });
  };


  handleClose = () => {
    this.setState({ open: false, openReason: false, initialBatchValues: { quantity: '', batch_name: '' } });
  };

  handleBatchEdit = (item) => {
    this.setState({ initialBatchValues: {quantity: item.quantity, batch_name: item.description, reason: item.item_reason_id }, open: true });
  };

  fetchBatchItems = (stockId) => {
    getItems(stockId).then(response => {
      this.setState({ batchItems: response.data ? response.data.data : [] });
    });
  };


  render() {
    const { initialValues } = this.props;
    const { units, initialBatchValues, batchItems, initialBatchReasonValues, reasons, initialReasonValues, adjustementReasons } = this.state;

    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={schema.stockSchema}
          onSubmit={this.handleSubmit}
        >
          {({ handleChange, handleBlur, values, touched, errors, isSubmitting, resetForm }) => (
            <Form>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography style={styles.label}>Stock Name</Typography>
                    <Typography style={styles.value}><b>{values.stock_name}</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography style={styles.label}>Quantity</Typography>
                    <Typography style={styles.value}><b>{values.quantity}</b></Typography>
                  </Grid>
                  {/*  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={touched.unit && Boolean(errors.unit)}>
                      <InputLabel id="unit-label">Unit</InputLabel>
                      <Select
                        labelId="unit-label"
                        id="unit"
                        name="unit"
                        value={values.unit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Unit"
                      >
                        {units.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error>
                        {touched.unit && errors.unit
                          ? errors.unit
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        name="min_quantity"
                        type="number"
                        label="Minimum Quantity"
                        value={values.min_quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.min_quantity && errors.min_quantity ? errors.min_quantity : ''}
                        error={touched.min_quantity && Boolean(errors.min_quantity)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        name="max_quantity"
                        type="number"
                        label="Maximum Quantity"
                        value={values.max_quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.max_quantity && errors.max_quantity ? errors.max_quantity : ''}
                        error={touched.max_quantity && Boolean(errors.max_quantity)}
                      />
                    </FormControl>
                  </Grid>


                  {/*  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        name="selling_price"
                        type="number"
                        label="Selling Price"
                        value={values.selling_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.selling_price && errors.selling_price ? errors.sselling_price : ''}
                        error={touched.selling_price && Boolean(errors.selling_price)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        name="buying_price"
                        type="number"
                        label="Buying Price"
                        value={values.buying_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.buying_price && errors.buying_price ? errors.buying_price : ''}
                        error={touched.buying_price && Boolean(errors.buying_price)}
                      />
                    </FormControl>
                  </Grid> */}

                  {/*   <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <TextField
                        name="description"
                        label="Description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.description && errors.description ? errors.description : ''}
                        error={touched.description && Boolean(errors.description)}
                        multiline
                        rows={4} 
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                      {initialValues.stock_id ? 'Update' : 'Create'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>

        {/*       <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Typography style={styles.label}>Stock Name</Typography>
                      <Typography style={styles.value}><b>{values.stock_name}</b></Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Typography style={styles.label}>Quantity</Typography>
                      <Typography style={styles.value}><b>{values.quantity}</b></Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Typography style={styles.label}>Minimum Quantity</Typography>
                      <Typography style={styles.value}><b>{values.min_quantity}</b></Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Typography style={styles.label}>Maximum Quantity</Typography>
                      <Typography style={styles.value}><b>{values.max_quantity}</b></Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik> */}

        {/*        <div style={{ float: "right" }}>
          {initialValues.stock_id && <Button variant="contained" sx={{ mb: 2 }} color="primary" onClick={this.handleClickOpen}>
            {"Add Batch"}
          </Button>
          }
        </div> */}
        <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            //padding: '10px 20px',
            //backgroundColor: '#3f51b5',
            //color: 'white',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
            <InfoOutlinedIcon /> &nbsp; Batch
          </Typography>

          {initialValues.stock_id &&
            <div>
              <Button variant="contained" sx={{ mb: 2 }} color="primary" onClick={this.handleClickReason} startIcon={<RemoveIcon sx={{ color: "white" }} />}>
                {"Adjustments"}
              </Button>
              &nbsp;&nbsp;
              <Button variant="contained" sx={{ mb: 2 }} color="primary" onClick={this.handleClickOpen} startIcon={<AddIcon sx={{ color: "white" }} />}>
                {"Add Batch"}
              </Button>
            </div>
          }
        </Box>

        <Typography variant="subtitle1" sx={{ color: 'grey' }} >
          Manage or edit a batch item.
        </Typography>

        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true}
          maxWidth={"sm"}>
          <DialogTitle>
            <Typography variant="h6"  >
              <InfoOutlinedIcon /> &nbsp;
              <b>  {initialBatchValues?.quantity ? "View Batch" : "Add Batch"}</b>
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'grey' }} >
            {initialBatchValues?.quantity && "This batch will be added to pre-exisiting stock. "}
            </Typography>

          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialBatchValues}
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
              validationSchema={Yup.object({
                quantity: Yup.number()
                  .required('Required')
                  .positive('Must be positive')
                  .integer('Must be an integer'),
                batch_name: Yup.string()
                  .required('Required')
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!'),
                reason: Yup.string()
                  .required('Required')
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const modifiedValues = {
                  ...values,
                  stock_id: initialValues.stock_id,
                  description: values.batch_name,
                  has_flag: true
                };
                delete modifiedValues.batch_name;


                this.handleBatchSubmit(modifiedValues, { setSubmitting, resetForm });
              }}
            >
              {({ errors, touched, isSubmitting, setFieldValue, values, handleBlur, handleChange }) => (
                <Form id="batch">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field name="quantity">
                        {({ field }) => (
                          <TextField
                            {...field}
                            id="quantity"
                            error={touched.quantity && !!errors.quantity}
                            helperText={touched.quantity && errors.quantity}
                            label="Quantity"
                            fullWidth
                            disabled={initialBatchValues?.quantity }
                            margin="normal"
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal" error={touched.reason && !!errors.reason}>
                        <InputLabel id="reason-label">Reason</InputLabel>
                        <Select
                          labelId="reason-label"
                          id="reason"
                          name="reason"
                          value={values.reason}
                          defaultValue={reasons?.[0]?.reason_id ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={initialBatchValues?.quantity }
                          label="Reason"
                        >
                          {reasons && reasons.map((option) => (
                            <MenuItem key={option.reason_id} value={option.reason_id}>
                              {option.reason}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.reason && errors.reason}</FormHelperText>
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <Field name="batch_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            id="batch_name"
                            error={touched.batch_name && !!errors.batch_name}
                            helperText={touched.batch_name && errors.batch_name}
                            label="Description"
                            fullWidth
                            rows={3}
                            multiline
                            disabled={initialBatchValues?.quantity }
                            margin="normal"
                          />
                        )}
                      </Field>
                    </Grid>

                    {!initialBatchValues?.quantity && <Button fullWidth type="submit" variant="contained" color="primary" >
                      Submit
                    </Button>}
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.openReason} onClose={this.handleClose} fullWidth={true}
          maxWidth={"sm"}>
          <DialogTitle>
            <Typography variant="h6"  >
              <InfoOutlinedIcon /> &nbsp;
              <b>  {initialBatchValues?.batch_id ? "Adjust Batch" : "Addjust Batch"}</b>
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'grey' }} >
              This will adjust the batch stock.
            </Typography>

          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialBatchValues}
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
              validationSchema={Yup.object({
                quantity: Yup.number()
                  .required('Required')
                  .positive('Must be positive')
                  .integer('Must be an integer'),
                batch_name: Yup.string()
                  .required('Required')
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!'),
                reason: Yup.string()
                  .required('Required')
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const modifiedValues = {
                  ...values,
                  stock_id: initialValues.stock_id,
                  description: values.batch_name,
                  has_flag: false,
                };
                delete modifiedValues.batch_name;


                this.handleBatchSubmit(modifiedValues, { setSubmitting, resetForm });
              }}
            >
              {({ errors, touched, isSubmitting, setFieldValue, values, handleBlur, handleChange }) => (
                <Form id="adjust">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field name="quantity">
                        {({ field }) => (
                          <TextField
                            {...field}
                            id="quantity"
                            error={touched.quantity && !!errors.quantity}
                            helperText={touched.quantity && errors.quantity}
                            label="Quantity"
                            fullWidth
                            margin="normal"
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal" error={touched.reason && !!errors.reason}>
                        <InputLabel id="reason-label">Reason</InputLabel>
                        <Select
                          labelId="reason-label"
                          id="reason"
                          name="reason"
                          value={values.reason}
                          defaultValue={adjustementReasons?.[0]?.reason_id ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Reason"
                        >
                          {adjustementReasons && adjustementReasons.map((option) => (
                            <MenuItem key={option.reason_id} value={option.reason_id}>
                              {option.reason}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.reason && errors.reason}</FormHelperText>
                      </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                      <Field name="batch_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            id="batch_name"
                            error={touched.batch_name && !!errors.batch_name}
                            helperText={touched.batch_name && errors.batch_name}
                            label="Description"
                            fullWidth
                            rows={3}
                            multiline
                            margin="normal"
                          />
                        )}
                      </Field>
                    </Grid>

      
                    <Button fullWidth type="submit" variant="contained" color="primary" >
                      Submit
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>



        {initialValues?.stock_id && <BatchItemList onEdit={this.handleBatchEdit} fetchBatchItems={this.fetchBatchItems} batchItems={batchItems} stockId={initialValues?.stock_id} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  username: state.user.username,
  settings: state.user.settings,
});


const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
