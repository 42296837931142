import React, { Component } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles'; // Import withStyles
import { connect } from 'react-redux';
import { syncMenu } from "../../../services/product/menu/MenuServiceAPI";
import CustomProgressBar from './CustomProgressBar'; // Ensure this is correctly implemented
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CachedIcon from '@mui/icons-material/Cached';
import { toast } from "react-toastify";
import { Padding } from '@mui/icons-material';
const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    button: {
        margin: theme.spacing(3),
        background: '#5773ff',
        width: "200px",
        height: "50px",
        color: theme.palette.common.white,
        '&:hover': {
            background: '#5773ff',
        },
    },
});

class MenuSyncComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            success: null,
            progress: 0, // Track progress from 0 to 100
        };
    }

    handleSyncMenu = async () => {
        this.setState({ loading: true, error: null, success: null, progress: 0 });
    
        let progressInterval; // Declare progressInterval outside the try block
        try {
            const updateProgress = (progress) => {
                this.setState({ progress });
            };
    
            // Simulate incremental progress
            let simulatedProgress = 0;
            progressInterval = setInterval(() => {
                if (simulatedProgress < 90) { // Cap progress at 90% before the API call completes
                    simulatedProgress += 10;
                    updateProgress(simulatedProgress);
                }
            }, 300);
    
            // Perform the API call
            const response = await syncMenu(this.props.property_id);
            console.log('Menu synchronized successfully:', response);
    
            // Complete progress and update success state
            clearInterval(progressInterval);
            this.setState({ progress: 100, success: 'Menu synchronized successfully.', loading: false });
            toast.success("Menu synchronized successfully!");
    
            // Optionally reload after success
            setTimeout(() => { window.location.reload() }, 1000);
    
        } catch (error) {
            console.error('Failed to synchronize menu:', error);
    
            // Clear progress and display error message
            clearInterval(progressInterval); // Clear interval even in case of an error
            this.setState({
                error: error.message || 'An error occurred during synchronization',
                loading: false,
                progress: 0 // Reset progress on failure
            });
    
            toast.error("Failed to synchronize menu. Please try again.");
        }
    };
    
    


    render() {
        const { classes } = this.props; // Access the styles
        const { loading, error, success, progress } = this.state;

        return (
            <Paper className={classes.root}>
                {!loading && <Typography variant="h6">
                    Currently, there is no existing menu
                </Typography>}

                {loading && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50%',      // Adjust width as needed
                        height: '100vh',   // Full viewport height
                        margin: 'auto',
                        marginTop: 16
                    }}>
                        <FastfoodIcon sx={{ color: "grey", fontSize: "58px", mb: 2 }} />

                        <CustomProgressBar progress={progress} />
                       {/*  <Typography variant="body2" align="center" style={{ marginTop: 8 }}>{`${progress}%`}</Typography> */}
                    </div>
                )}

                {error && <Typography variant="body2" color="error">{error}</Typography>}
                {success && <Typography variant="body2" color="primary">{success}</Typography>}

                <Button
                    className={classes.button}
                    onClick={this.handleSyncMenu}
                    disabled={loading}
                >
                    <CachedIcon/> &nbsp;&nbsp;
                    {loading ? 'Syncing...' : 'Sync menu'}
                </Button>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => ({
    property_id: state.user.propertyId,
});

// Wrap the component with connect and then withStyles
export default connect(mapStateToProps)(withStyles(styles)(MenuSyncComponent));
